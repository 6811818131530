import { isInteger, isNumber, roundNumber } from "../helpers"
import { get, post, put, remove, patch } from "../api"
import { showError } from "../toast"

export const getOrders = (
  params = {
    includeApprovers: true,
  }
) => get("/orders", params)

export const getOrder = (
  orderId,
  params = {
    includeApprovers: true,
  }
) => get(`/orders/${orderId}`, params)

export const createOrder = (params, options) => {
  return post(
    `/orders?userFirstName=${options.firstName}&userLastName=${options.lastName}&skipSendingEmail=${options.skipSendingEmail}`,
    params
  )
}

export const cancelOrder = (orderId) => {
  return post(`/orders/${orderId}/cancel`)
}

export const resendOrder = (orderId) => {
  return post(`/orders/${orderId}/resend`)
}

export const updateOrder = (orderId, params, options) => {
  return put(
    `/orders/${orderId}?userFirstName=${options.firstName}&userLastName=${options.lastName}&skipSendingEmail=${options.skipSendingEmail}`,
    params
  )
}

export const removeOrder = (orderId) => {
  return remove(`/orders/${orderId}`)
}

export const completeOrder = (po: string, supplierId: string, params) => {
  if (!po) {
    return showError("Missing PO number")
  } else if (!supplierId) {
    return showError("Missing supplier ID")
  }

  return patch(`/orders/po-number/${po}?supplierId=${supplierId}`, params)
}

export const approveOrder = (params) => {
  return post("/orders/approve", params)
}

export const calculateTotalCost = (order: any) => {
  return order.products.reduce((acc, current) => {
    const { _basketQuantity, _selectedCase } = current
    const price =
      _selectedCase === "multiple" ? current.productCase.price : current.price
    const isNumberOrInteger = isNumber(price) || isInteger(price)
    const totalCost = isNumberOrInteger ? price * _basketQuantity : 0
    return roundNumber(acc + totalCost)
  }, 0)
}

export const itemIsOrderedAsSingle = (rowData) =>
  !rowData.productCase || rowData.orderedIn?.single
/* 
  Can only be ordered as single. 
*/

export const itemIsOrderedAsCase = (rowData) =>
  rowData.productCase && rowData.orderedIn?.pack
/* 
  Can only be ordered as case. 
*/

export const itemIsOrderedAsSingleAndCase = (rowData) =>
  rowData.productCase && (rowData.orderedIn?.both || !rowData.orderedIn)
/* 
  Can be ordered as single or case. The product has a case config and the user has either set the orderedIn
  config to 'both' or the ordereIn config has not been set (null)
*/

export const itemIsInCase = (caseOnly, singleAndCase, selectedPackaging) =>
  caseOnly ||
  (singleAndCase &&
    (selectedPackaging === undefined ||
      selectedPackaging?.packaging === "multiple"))
/* 
  Either item can only be ordered as case or item can be ordered as both single or case but the user
  has not selected the single option
*/

export const selectedPackagingMatchesOrderedInConfig = (rowData) => {
  const orderedAsSingle = itemIsOrderedAsSingle(rowData)
  const orderedAsCase = itemIsOrderedAsCase(rowData)
  const orderedAsSingleAndCase = itemIsOrderedAsSingleAndCase(rowData)

  return rowData._selectedCase === "multiple"
    ? orderedAsCase || orderedAsSingleAndCase
    : orderedAsSingle || orderedAsSingleAndCase
}
