import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import InitialContext from "context/global/InitialContext"
import React, { useContext, useEffect, useState } from "react"
import Select from "react-select"
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons"
import {
  AccountingProvider,
  useAccountingRelationsRetriever,
} from "services/accounting"
import { customStyles } from "components/forms/CustomSelect"

interface AccountingProductFormProps {
  provider: AccountingProvider
  product: any
  setProductAccountingParams: (
    accountingParams: any,
    provider: AccountingProvider
  ) => void
}

const AccountingProductForm = (props: AccountingProductFormProps) => {
  const { product, provider, setProductAccountingParams } = props
  const [loading, setLoading] = useState<boolean>(false)

  const values = product?.accounting?.[provider.key + "Accounting"] || null
  const [AccountingParams, setAccountingParams] = useState({
    taxRate: values?.taxRate || null,
    account: values?.account || null,
  })

  const { accounting } = useContext(GlobalStateContext)

  // also available
  // const { suppliers,trackingCategories, taxRateZero }
  const { accounts, taxRates } =
    accounting[provider.key] || InitialContext.accounting

  const retriever = useAccountingRelationsRetriever(provider)

  useEffect(() => {
    setLoading(true)

    // retriever will fill accounting[provider.key] array with needed values
    retriever.get(["taxRates", "accounts"]).then((success) => {
      setLoading(!success)
    })

    return () => {
      setLoading(false)
      retriever.abort()
    }
  }, [])

  useEffect(() => {
    setProductAccountingParams(AccountingParams, provider)
  }, [AccountingParams])

  return (
    <div className="form-group form-group--flex-nowrap justify-items-stretch mt-4 mb-4">
      <div className="form-group-label self-stretch input-container--1-4 font-semibold">
        <FontAwesomeIcon icon={faClipboardCheck} size="xs" className="mr-2" />
        Defaults
      </div>
      <div className="form-group input-container--3-4">
        <div className="form-group form-group--flex justify-items-center">
          <label className="form-label first w-1/4">Tax rate</label>
          <Select
            name="taxRate"
            className="form-control w-3/4 form-control--topright"
            styles={customStyles}
            options={taxRates}
            placeholder={loading ? "Loading..." : "Select a default tax rate"}
            maxMenuHeight={200}
            isDisabled={loading}
            getOptionLabel={provider.taxOptionLabelRenderFn}
            getOptionValue={(opt) => opt}
            menuPosition="fixed"
            onChange={(value) => {
              setAccountingParams({
                ...AccountingParams,
                taxRate: value ? value : null,
              })
            }}
            value={AccountingParams.taxRate || null}
          />
        </div>
        <div className="form-group form-group--flex">
          <label className="form-label last w-1/4">Account </label>
          <Select
            name="account"
            className="form-control w-3/4 form-control--bottomright "
            styles={customStyles}
            options={accounts}
            placeholder={loading ? "Loading..." : "Select a default Account"}
            maxMenuHeight={200}
            isDisabled={loading}
            getOptionLabel={(opt) => `${opt.code} - ${opt.name}`}
            getOptionValue={(opt) => opt}
            menuPosition="fixed"
            onChange={(value) => {
              setAccountingParams({
                ...AccountingParams,
                account: value ? value : null,
              })
            }}
            value={AccountingParams.account || null}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountingProductForm
