// extracted by mini-css-extract-plugin
export var active = "InvoiceDetails-module--active--tYq4B";
export var backIcon = "InvoiceDetails-module--backIcon--PtFpq";
export var btn = "InvoiceDetails-module--btn--RiHX0";
export var btnCapture = "InvoiceDetails-module--btnCapture--hfppb";
export var btnConfirm = "InvoiceDetails-module--btnConfirm---WR5r";
export var btnConfirmScanning = "InvoiceDetails-module--btnConfirmScanning--nEKok";
export var camera = "InvoiceDetails-module--camera--UqAgq";
export var clickArea = "InvoiceDetails-module--clickArea--rqjj2";
export var confirm = "InvoiceDetails-module--confirm--6QOCZ";
export var confirmContainer = "InvoiceDetails-module--confirmContainer--zPC15";
export var confirmCtas = "InvoiceDetails-module--confirmCtas--867T2";
export var confirmImg = "InvoiceDetails-module--confirmImg--d+wR+";
export var container = "InvoiceDetails-module--container--KDedh";
export var content = "InvoiceDetails-module--content--FKyHM";
export var contentConfirm = "InvoiceDetails-module--content--confirm--jECMY";
export var displayIntro = "InvoiceDetails-module--displayIntro--QYmUV";
export var displayIntroContainer = "InvoiceDetails-module--displayIntroContainer--YlvnE";
export var flash = "InvoiceDetails-module--flash--9xBQs";
export var header = "InvoiceDetails-module--header--qMeMA";
export var headerMain = "InvoiceDetails-module--headerMain--hIWmZ";
export var inputBtn = "InvoiceDetails-module--inputBtn--bs7YB";
export var intro = "InvoiceDetails-module--intro--fVttl";
export var introContainer = "InvoiceDetails-module--introContainer--DFD6o";
export var introIcon = "InvoiceDetails-module--introIcon--nyPvd";
export var moveCamera = "InvoiceDetails-module--moveCamera--PeFBl";
export var navBar = "InvoiceDetails-module--navBar--fntF-";
export var notSupported = "InvoiceDetails-module--notSupported--Uo+Hh";
export var notSupportedBtn = "InvoiceDetails-module--notSupportedBtn--LbRLs";
export var orderSelect = "InvoiceDetails-module--orderSelect--gtE8k";
export var orderSelectContainer = "InvoiceDetails-module--orderSelectContainer--iwDpV";
export var placeHolder = "InvoiceDetails-module--placeHolder--0W36R";
export var retake = "InvoiceDetails-module--retake--eRtk9";
export var rotate = "InvoiceDetails-module--rotate--aOTgg";
export var scanAction = "InvoiceDetails-module--scanAction--m2TtJ";
export var scanActions = "InvoiceDetails-module--scanActions--Uo2Yh";
export var scanBtn = "InvoiceDetails-module--scanBtn--3ZjhN";
export var scanPending = "InvoiceDetails-module--scanPending--OK9Iq";
export var scanPendingAnimation = "InvoiceDetails-module--scanPendingAnimation--DoNzq";
export var scanPendingButton = "InvoiceDetails-module--scanPendingButton--aTH08";
export var scanPendingText = "InvoiceDetails-module--scanPendingText--bRmuU";
export var scanning = "InvoiceDetails-module--scanning--ifTqF";
export var spinning = "InvoiceDetails-module--spinning--Qo5n0";
export var step2 = "InvoiceDetails-module--step2--Tdc2Z";
export var step3 = "InvoiceDetails-module--step3--bYYsw";
export var step4 = "InvoiceDetails-module--step4--EJt2N";
export var title = "InvoiceDetails-module--title--eiwLq";
export var transparent = "InvoiceDetails-module--transparent--verDg";