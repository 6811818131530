import React, { useContext } from "react"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import usePermissions from "hooks/usePermissions"
import { navigate } from "gatsby"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { getExportFileName } from "services/export"
import { getOrgNameById } from "services/user"
import {
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/pro-duotone-svg-icons"
import { DiscrepancyReportProps } from "./DiscrepancyReports"
import {
  deleteDiscrepancyReport,
  downloadDiscrepancyReport,
} from "services/stock-take/stock-take"
import { Permission } from "services/types"
import ReportNameCell from "components/baseList/cells/ReportName"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/modal/ModalContext"
import CornerLabel from "components/common/CornerLabel/CornerLabel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHashtag } from "@fortawesome/pro-light-svg-icons"

interface DiscrepancyReportItemProps {
  item: DiscrepancyReportProps
  onDelete: () => void
}

const DiscrepancyReportItem: React.FC<DiscrepancyReportItemProps> = ({
  item,
  onDelete,
}: DiscrepancyReportItemProps) => {
  // @ts-ignore
  const {
    organizations,
    organizationPermissions,
  }: { organizations: any[]; organizationPermissions: any } =
    useContext(GlobalStateContext)
  const permissionObj = usePermissions("Sales") as Permission
  const modal = useContext(ModalContext)

  const actionOptions = [
    {
      key: "view",
      title: "View report",
      // @ts-ignore
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "export.csv",
      title: "Export report (.csv)",
      // @ts-ignore
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "export.xlsx",
      title: "Export report (.xlsx)",
      // @ts-ignore
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "delete",
      title: "Delete report",
      type: "danger",
      // @ts-ignore
      disabled: !permissionObj?.permissions.delete,
    },
  ]

  const handleDelete = (cogs) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete Discrepancy report",
      text: "Are you sure you want to delete this discrepancy report?",
      confirmButtonText: "Delete",
      onConfirm: async () => {
        await deleteDiscrepancyReport(cogs.id)
        onDelete()
      },
    })
  }

  const handleActionClick = (action) => {
    const { type } = action

    switch (type) {
      case "view":
        navigate(`/dashboard/stock/stocktake/discrepancy-report/${item.id}`)
        break
      case "export.csv":
        downloadDiscrepancyReport(
          item.id,
          "csv",
          getExportFileName("stock-discrepancy", "", "csv")
        )
        break
      case "export.xlsx":
        downloadDiscrepancyReport(
          item.id,
          "xlsx",
          getExportFileName("stock-discrepancy", "", "xlsx")
        )
        break
      case "delete":
        handleDelete(item)
        break
      default:
        break
    }
  }

  const numberField = (value, isPercentage = false) => {
    return (
      <span className="font-sansSemiBold font-semibold text-sm">
        {value === null ? "-" : value.toFixed(2)}
        {value && isPercentage ? "%" : ""}
      </span>
    )
  }

  const columns = [
    {
      name: "Discrepancy total",
      component: numberField(item.totalDiscrepancyAmount),
    },
  ]

  return (
    <div className="flex items-center justify-between pl-3 pr-1 py-1 border-t first:rounded-t-md last:rounded-b-md border border-b-0 last:border-b gap-4 flex-wrap relative">
      {item.theoretical && (
        <CornerLabel colorClassName="text-red-600">
          <FontAwesomeIcon icon={faHashtag} className="mr-1" /> Theoretical
        </CornerLabel>
      )}
      <ReportNameCell
        data={item}
        iconStyles={{
          mainIcon: {
            backgroundColor: "#feefdd",
            color: "#f2691c",
          },
          subIcon: {
            color: "#35ccc3",
          },
        }}
        disabled={!permissionObj?.permissions.read}
        mainIcon={faExclamationTriangle}
        subIcon={faSyncAlt}
        onClick={() =>
          navigate(`/dashboard/stock/stocktake/discrepancy-report/${item.id}`, {
            state: {
              organizationId:
                item?.organizations.length && item?.organizations[0],
            },
          })
        }
      >
        {organizationPermissions?.general?.isMain && (
          <span className="font-sansSemiBold font-semibold text-sm">
            {item.organizations.length &&
              getOrgNameById(item.organizations[0], organizations)}
          </span>
        )}
      </ReportNameCell>

      {columns.map((column, i) => (
        <div className="flex flex-col" key={i}>
          <span className="text-gray-700 text-sm">{column.name}</span>
          {column.component}
        </div>
      ))}

      <div className="flex items-center">
        <ActionsButton
          options={actionOptions}
          onActionClick={(action) => handleActionClick(action)}
        />
      </div>
    </div>
  )
}

export default DiscrepancyReportItem
