import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { contactSupport } from "services/user"
import Modal from "react-modal"
import PropTypes from "prop-types"
import TextInput from "../../forms/TextInput"
import TextArea from "../../forms/TextArea"
import * as styles from "./ContactModal.module.css"

Modal.setAppElement("body")

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  message: Yup.string().required("Message is required"),
})

const ContactModal = ({ onRequestClose, ...otherProps }) => {
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = (params) => {
    contactSupport(params)
      .then(setSubmitted(true))
      .catch((err) => {
        console.log(err)
        setError("Something went wrong. Please try again later.")
      })
  }

  return (
    <Modal
      isOpen
      portalClassName="contact"
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form className={styles.form}>
            <div className={styles.welcomeContent}>
              <h1 className="text-4xl mb-4">Contact support</h1>
              <p className="text-lg text-gray-700">
                We will help you as soon as possible
              </p>
            </div>

            {error && <div className={styles.error}>{error}</div>}

            {!isSubmitting && !submitted && (
              <>
                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      name="name"
                      type="text"
                      className="form-control form-control--first"
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      name="email"
                      autoComplete="username"
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      label="Email address"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-container">
                    <TextArea
                      name="message"
                      className="form-control form-control--last"
                      placeholder="Message"
                      label="Message"
                    />
                  </div>
                </div>

                <button
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  className="button mt-5 mb-4"
                >
                  Help me out, please
                </button>

                <p className="text-xs text-gray-700 text-center mb-5 px-6">
                  By contacting support you agree to our{" "}
                  <a
                    href="/docs/Growyze_Terms.pdf"
                    target="_blank"
                    className="text-primaryPink underline font-sans font-normal"
                  >
                    Terms
                  </a>{" "}
                  &amp;{" "}
                  <a
                    href="/docs/Growyze_PrivacyPolicy.pdf"
                    target="_blank"
                    className="text-primaryPink underline font-sans font-normal"
                  >
                    Privacy policy
                  </a>
                </p>
              </>
            )}

            {isSubmitting && !submitted && (
              <div className="text-center text-3xl text-primaryPink">
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              </div>
            )}

            {submitted && (
              <div className="text-center text-lg text-primaryPink">
                <p>Thanks for your message. We&aps;ll respond soon!</p>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

ContactModal.propTypes = {
  onRequestClose: PropTypes.func,
}

export default ContactModal
