import { useEffect, useState } from "react"
import { camelCaseToWords } from "services/helpers"
import { getExtendedOrgRequestCounter } from "services/organization"

const useFreemiumCounter = (): {
  freemiumData: any
  formattedFreemiumData: any
  additionalData: any
} => {
  const [freemiumData, setFreemiumData] = useState({})
  const [formattedFreemiumData, setFormattedFreemiumData] =
    useState(freemiumData)
  const [additionalDataObject, setAdditionalDataObject] = useState({})

  const additionalDataKeys = ["id", "orgId", "version", "lastResetDate"]
  const textReplacements = {
    "Stock Takes": "Stocktakes",
    "Finalised Stock Takes": "Finalised Stocktakes",
    Wastes: "Waste",
    "Cogs Reports": "COGs Reports",
    "Gp Reports": "GP Reports",
  }

  //Mount
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!Object.keys(freemiumData).length) return

    const keys = Object.keys(freemiumData).filter(
      (a) => !a.includes("Remaining")
    )

    const obj = {}
    const additionalDataObject = {}

    for (const key of keys) {
      const target = additionalDataKeys.find((a) => key === a)
        ? additionalDataObject
        : obj
      target[key] = {
        current: freemiumData[key],
        remaining: freemiumData[key + "Remaining"] ?? "",
        _slug: key,
        _displayName: getDisplayName(key),
      }
    }

    setFormattedFreemiumData(obj)
    setAdditionalDataObject(additionalDataObject)
  }, [freemiumData])

  const getDisplayName = (key) => {
    let displayName = camelCaseToWords(key).replace("Count", "").trim()
    if (textReplacements[displayName]) {
      displayName = textReplacements[displayName]
    }
    return displayName
  }

  const fetchData = async () => {
    await getExtendedOrgRequestCounter().then((data) => setFreemiumData(data))
  }

  return {
    freemiumData: freemiumData, // Original response object
    formattedFreemiumData: formattedFreemiumData, //Formatted response object
    additionalData: additionalDataObject,
  }
}

export default useFreemiumCounter
