import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useField } from "formik"
import { truncateFileName, uuidv4 } from "services/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCloudUpload,
  faFile,
  faFileCsv,
  faExclamationTriangle,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons"

export const Thumbnail = ({ file }) => {
  const [thumb, setThumb] = useState(undefined)
  const [icon, setIcon] = useState(faCloudUpload)

  const setImageThumb = (file) => {
    let reader = new FileReader()

    reader.onloadend = () => {
      setThumb(reader.result)
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (file) {
      const fileTypeProps = file.type ? file.type.split("/") : null
      const type = fileTypeProps ? fileTypeProps[0] : null
      const format = fileTypeProps ? fileTypeProps[1] : null

      switch (type) {
        case "text":
          switch (format) {
            case "csv":
              setThumb(undefined)
              setIcon(faFileCsv)
              break
            default:
              setThumb(undefined)
              setIcon(faFile)
              break
          }
          break
        case "image":
          setImageThumb(file)
          break
        default:
          setThumb(undefined)
          setIcon(faFile)
      }
    }
  }, [file])

  return (
    <div className="w-16 h-16 overflow-hidden relative rounded-full border bg-gray-100 hover:bg-gray-200 text-center flex items-center justify-center">
      {thumb ? (
        <img
          src={thumb}
          alt={file.name}
          className="object-cover block absolute left-0 top-0 right-0 bottom-0 w-full h-full"
        />
      ) : (
        <FontAwesomeIcon icon={icon} size="lg" className="text-gray-600" />
      )}
    </div>
  )
}

Thumbnail.propTypes = {
  file: PropTypes.any,
}

const FileInput = ({
  label,
  placeholder,
  isMultipleFiles,
  isSingleFile,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props)

  const { name, onBlur } = field

  const handleChange = (event) => {
    const fileList = event.currentTarget.files

    if (fileList.length === 0) return

    if (isMultipleFiles) {
      return helpers.setValue([...field.value, ...fileList])
    }

    helpers.setValue(fileList[0])
  }

  const removeFile = (event, fileName) => {
    event.preventDefault()

    const newFileList = field.value.filter((file) => {
      return file.name !== fileName
    })

    helpers.setValue(newFileList)
  }

  return (
    <>
      <input
        className="hidden"
        type="file"
        onChange={handleChange}
        {...props}
        name={name}
        onBlur={onBlur}
        multiple
      />
      <label
        htmlFor={props.id || props.name}
        className={`flex items-center cursor-pointer ${
          props.disabled ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        <Thumbnail file={meta.value} />
        {isSingleFile && (
          <div
            className="text-primaryPink text-sm block ml-3 text-sansBold font-bold"
            style={{ maxWidth: "200px" }}
          >
            <span className="block w-full">
              {(meta.value && meta.value.name
                ? truncateFileName(meta.value.name, 10)
                : null) ||
                placeholder ||
                label ||
                "Upload a file"}
            </span>
          </div>
        )}

        {isMultipleFiles && (
          <div className="flex flex-col text-primaryPink text-sm ml-3 text-sansBold font-bold w-full">
            {meta.value.length > 0 ? (
              meta.value.map((file) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={uuidv4()}
                  >
                    <span>{truncateFileName(file.name, 10)}</span>
                    <button type="button">
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-gray-600 mr-2 hover:text-primaryPink"
                        onClick={(event) => removeFile(event, file.name)}
                      />
                    </button>
                  </div>
                )
              })
            ) : (
              <span>{placeholder || label || "Upload a file"}</span>
            )}
          </div>
        )}
      </label>
      {meta.error && (
        <span className="w-full text-center mt-4 block text-primaryPink">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          {meta.error}
        </span>
      )}
    </>
  )
}

FileInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  isMultipleFiles: PropTypes.bool,
  isSingleFile: PropTypes.bool,
}

export default FileInput
