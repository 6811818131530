import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { showError, showSuccess } from "services/toast"
import ConfirmDetails from "components/delivery-notes/ConfirmDetails/ConfirmDetails"

//@ts-ignore
import * as styles from "./DeliveryNoteDetails.module.css"

const DeliveryNoteDetails = ({ onRequestClose, onUpdate, shouldShowCalendarImmediately = false, ...otherProps }) => {
  const [disableClose, setDisableClose] = useState(false)

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0 } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!disableClose}
      shouldCloseOnEsc={!disableClose}
      portalClassName="deliveryNoteDetails"
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.confirmContainer}>
          <div className={styles.confirm}>
            <ConfirmDetails
              shouldShowCalendarImmediately={shouldShowCalendarImmediately}
              onCancel={() => {
                onRequestClose()
                onUpdate()
              }}
              onConfirm={() => {
                onRequestClose()
                onUpdate()
                showSuccess("Delivery approved!")
              }}
              onReject={() => {
                onRequestClose()
                onUpdate()
                showError("Delivery note rejected!")
              }}
              onQuery={() => {
                onRequestClose()
                onUpdate()
              }}
              onSave={() => {
                onUpdate()
              }}
              onLoadingChange={(loading) => {
                setDisableClose(loading)
              }}
              onFilesChange={() => {
                onUpdate()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

DeliveryNoteDetails.propTypes = {
  onRequestClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default DeliveryNoteDetails
