import React from "react"

//@ts-ignore
import * as styles from "./PrintRecipe.module.css"
import { Recipe } from "../../../services/dishes"
import SimpleTable from "components/orders/SimpleTable/SimpleTable"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import ServerImage from "components/common/ServerImage/ServerImage"
import { allergens } from "services/constants"
import { findValueLabel } from "../../../services/helpers"

export default ({ values, image }: { values: Recipe; image: any }) => (
  <div className={styles.container}>
    <h1>{values.name}</h1>

    <div className="my-7">
      <CostProfitChart
        cost={values.totalCost}
        profit={values.profit}
        costPercentage={values.totalCostPercent}
        profitPercentage={values.profitPercent}
      />
    </div>
    <div className={styles.ingredientsContainer}>
      {image != undefined ? (
        <div className={styles.insideImageWrapper}>
          <ServerImage fileId={image.fileId}></ServerImage>
        </div>
      ) : undefined}
      {(values.allergens?.length > 0 ||
        values.mayContainAllergens?.length > 0) && (
        <div>
          <h2>Allergens</h2>
          <h4>Contains</h4>
          {values.allergens
            ?.map((allergen) => findValueLabel(allergens, allergen))
            .join(", ")}
          {values.mayContainAllergens?.length > 0 && (
            <>
              <h4>May contain</h4>
              {values.mayContainAllergens
                .map((allergen) => findValueLabel(allergens, allergen))
                .join(", ")}
            </>
          )}
        </div>
      )}
      {values.ingredients && values.ingredients?.length > 0 && (
        <div>
          <h2>Ingredients</h2>
          <SimpleTable
            style={{ tableLayout: "auto", borderRadius: "0.375rem" }}
            props={values.ingredients.map((ingr) => ({
              Name: ingr.product.name,
              Qty: `${ingr.usedQty} ${ingr.measure}`,
            }))}
          ></SimpleTable>
        </div>
      )}
      {values.description && (
        <div>
          <h2>Method</h2>
          <div className={styles.textField}>{values.description}</div>
        </div>
      )}
    </div>

    {values.notes && (
      <div>
        <h2>Notes</h2>
        <div className={styles.textField}>{values.notes}</div>
      </div>
    )}
  </div>
)
