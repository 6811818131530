// extracted by mini-css-extract-plugin
export var box = "BelowMinQtyReport-module--box--2rALE";
export var container = "BelowMinQtyReport-module--container--kldzB";
export var content = "BelowMinQtyReport-module--content--YdNQQ";
export var headerProducts = "BelowMinQtyReport-module--headerProducts--3mLpS";
export var productItem = "BelowMinQtyReport-module--productItem--jtxcS";
export var qtyLabel = "BelowMinQtyReport-module--qtyLabel--D-Md2";
export var sidebar = "BelowMinQtyReport-module--sidebar--hrRJs";
export var sidebarContent = "BelowMinQtyReport-module--sidebarContent--O-TNe";
export var stockLabel = "BelowMinQtyReport-module--stockLabel--KR1BR";
export var thumbnail = "BelowMinQtyReport-module--thumbnail--pUOTL";
export var thumbnailBadge = "BelowMinQtyReport-module--thumbnailBadge--8ju9b";