import CornerLabel from "components/common/CornerLabel/CornerLabel"
import React from "react"
import {
  AccountingProvider,
  publishableInvoiceStates,
} from "services/accounting"
import { Invoice } from "services/invoices"
import AccountingStatusLabel from "./AccountingStatusLabel"

interface AccountingStatusLabelsProps {
  invoice: Invoice
  providers: AccountingProvider
}

const AccountingStatusLabels = (props: AccountingStatusLabelsProps) => {
  const { invoice, providers } = props

  let numLabels = 0
  const labels = Object.values(providers).map(
    (provider: AccountingProvider, i) => {
      if (provider?.id && !publishableInvoiceStates.includes(invoice.status)) {
        const published = invoice[provider.key + "Invoice"] || null
        numLabels++
        return (
          <AccountingStatusLabel
            key={i}
            provider={provider}
            published={published}
          />
        )
      }
    }
  )
  return numLabels !== 0 && <CornerLabel>{labels}</CornerLabel>
}

export default AccountingStatusLabels
