import RequestCounter from "components/common/RequestCounter/RequestCounter"
import EventBus from "./events"
import { kebabToCamelCase } from "./helpers"

export const handleRateLimit = (response) => {
  EventBus.dispatch("showModal", {
    component: RequestCounter,
    props: getLimitDetails(response),
    key: "rateLimitModal",
  })
}

const getLimitDetails = (response) => {
  const row = getRowByMessage(response.message) || getRowByPath(response.path)
  return {
    limitReachedMessage: response.message,
    limitReachedRow: row,
  }
}

const messageToRow = {
  STOCK_TAKE_FINALIZATION: "finalisedStockTakesCount",
  STOCK_TAKE_CREATION: "stockTakesCount",
  DISCREPANCY_REPORT_CREATION: "discrepancyReportsCount",
  COGS_REPORT_CREATION: "cogsReportsCount",
}

const getRowByMessage = (message) => {
  for (const [key, value] of Object.entries(messageToRow)) {
    if (message.includes(key)) {
      return value
    }
  }
  return false
}

const getRowByPath = (path) => {
  const pathStr = new String(path).split("/")[2]
  return kebabToCamelCase(pathStr) + "Count"
}
