import React, { useContext, useState } from "react"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartBar, faDollarSign } from "@fortawesome/pro-duotone-svg-icons"
import AsyncSelect from "components/forms/AsyncSelect"
import { getSales } from "services/sales"
import { formatReportSelectOptionLabel } from "services/helpers"
import { format } from "date-fns"
import { AsideContext, AsideContextType } from "context/aside/AsideContext"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import { showError, showSuccess } from "services/toast"
import {
  createMenuProfitabilityReport,
  updateMenuProfitabilityReport,
} from "services/menu-profitability-report"

interface MenuProfitabilityReportModalProps {
  onUpdated: () => void
  initialValues?: {
    periodFrom: string
    periodTo: string
    sales: { id: string }
  } | null
  reportId?: string | null
  onRequestClose?: () => void
}

const MenuProfitabilityReportModal: React.FC<
  MenuProfitabilityReportModalProps
> = ({
  onUpdated,
  initialValues = null,
  reportId = null,
  onRequestClose,
}: MenuProfitabilityReportModalProps) => {
  const aside = useContext<AsideContextType>(AsideContext)

  const [salesReport, setSalesReport] = useState<{
    label: string
    value: { id: string }
  } | null>(null)
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: initialValues ? new Date(initialValues.periodFrom) : "",
    to: initialValues ? new Date(initialValues.periodTo) : "",
  })
  const [loading, setLoading] = useState<boolean>(false)

  const createReport = async (update = false) => {
    const params = {
      periodFrom: selectedPeriod.from,
      periodTo: selectedPeriod.to,
    }

    if (salesReport) {
      params["sales"] = { id: salesReport.value.id }
    }

    const res = await (update
      ? updateMenuProfitabilityReport(reportId, params)
      : createMenuProfitabilityReport(params))

    if (res?.message) {
      showError(res?.message)
    } else {
      showSuccess(
        "Menu profitability report " + (update ? "updated" : "created")
      )
    }

    onRequestClose?.()
    onUpdated()
  }

  const toggleDatePicker = () => {
    aside.showAside(AsideCalendar, {
      headerText: "Date picker",
      zIndex: 2000,
      selectedPeriod,
      onConfirm: (data) => {
        setSelectedPeriod(data)
        aside.hideAside()
      },
      onClose: () => aside.hideAside(),
    })
  }

  const fetchSalesReports = async () => {
    setLoading(true)
    const params = {
      size: 999,
      sort: "from,desc",
      live: false,
    }

    const result = await getSales(params)

    const salesFile = result.content.find(
      (sale) => sale.id === initialValues?.sales?.id
    )

    if (salesFile) {
      setSalesReport({
        label: formatReportSelectOptionLabel(salesFile, "sales") || "",
        value: salesFile,
      })
    }

    setLoading(false)
    return result
  }

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      portalClassName="menuProfitabilityReportCreate"
      // {...otherProps}
    >
      <div className="flex items-center px-6 py-2">
        <div
          className="w-10 h-10 mr-4 my-2 relative border flex items-center justify-center rounded-full"
          style={{
            backgroundColor: "#E0FFFF",
            color: "#0D847D",
          }}
        >
          <FontAwesomeIcon icon={faChartBar} />

          <span
            className="w-6 h-6 absolute border flex items-center justify-center bg-white rounded-full -right-1.5 -bottom-1.5"
            style={{
              color: "#0D847D",
            }}
          >
            <FontAwesomeIcon size="xs" icon={faDollarSign} />
          </span>
        </div>
        <h2 className="text-lg">
          {reportId ? "Update" : "Create"} menu profitability report
        </h2>
      </div>
      <div className="p-6 border-t flex-grow overflow-auto flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <label className="font-semibold">Select period</label>
          <div className="flex flex-row relative w-full md:w-10/12">
            <div className="input-container mr-4">
              <input
                type="text"
                value={formatDate(selectedPeriod.from) || ""}
                className="cursor-pointer form-control rounded bg-paleBlue"
                placeholder="From"
                readOnly
                onClick={toggleDatePicker}
              />
            </div>
            <div className="input-container mr-2">
              <input
                type="text"
                className="cursor-pointer form-control rounded bg-paleBlue"
                value={formatDate(selectedPeriod.to) || ""}
                placeholder="To"
                readOnly
                onClick={toggleDatePicker}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-semibold">Select sales file (optional)</label>
          <AsyncSelect
            label="Sales file"
            name="Sales file"
            className="w-full"
            promise={fetchSalesReports}
            placeholder="Sales file"
            value={salesReport}
            // @ts-ignore
            optionLabel={(option) =>
              formatReportSelectOptionLabel(option, "sales")
            }
            isClearable={true}
            onChange={(val) => {
              setSalesReport(val)
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-between px-6 py-4 w-full border-t">
        <button
          type="button"
          className="font-semibold font-sansSemiBold text-gray-700"
          onClick={onRequestClose}
        >
          Close
        </button>
        <button
          type="button"
          className="button button--autoWidth button--primaryGreen"
          onClick={() => createReport(reportId !== null)}
          disabled={!selectedPeriod.from || !selectedPeriod.to || loading}
        >
          <span className="hidden lg:inline">
            {reportId ? "Update" : "Create"} report
          </span>
        </button>
      </div>
    </Modal>
  )
}

export default MenuProfitabilityReportModal
