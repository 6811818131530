import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ModalContext } from "context/modal/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"

import { formatCurrencyValue, roundNumber } from "services/helpers"
import { deleteWasteGroupById } from "services/waste/waste"
import { showSuccess, showError } from "services/toast"

import { isToday, format } from "date-fns"

import { faBan } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

import WasteModal from "../WasteModal/WasteModal"

import * as styles from "./WasteItem.module.css"
import usePermissions from "hooks/usePermissions"

const WasteItem = ({ waste, onDeleted, onCreated }) => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { organization, organizationPermissions } =
    useContext(GlobalStateContext)

  const today = isToday(new Date(waste.date))
  const currency = null
  const permissionObj = usePermissions("Waste")

  const onDelete = (waste) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete waste of ${waste.date || "Stocktake"}`,
      text: "Are you sure you want to delete this waste?",
      confirmButtonText: "Delete",
      onConfirm: () => removeWasteGroup(waste),
    })
  }

  const removeWasteGroup = async (waste) => {
    const deleted = await deleteWasteGroupById(waste.id)
    if (deleted && !deleted.message) {
      showSuccess("Waste deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
  }

  const onEdit = (waste) => {
    dispatch({
      type: "SELECT_WASTE_GROUP",
      payload: {
        selectedWasteGroup: waste,
      },
    })

    modal.showModal(WasteModal, {
      wasteGroupId: waste.id,
      getDataWasteGroups: onCreated,
      allowDateSelect: false,
    })
  }

  const handleActionClick = (action, waste) => {
    const { type } = action

    switch (type) {
      case "waste.edit":
        onEdit(waste)
        break
      case "waste.delete":
        onDelete(waste)
        break
      default:
        break
    }
  }

  const actions = (rowData) => {
    const options = [
      {
        key: "waste.edit",
        title: "Edit",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "waste.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]

    return (
      <div className="px-3 flex-shrink-0" style={{ textAlign: "right" }}>
        {rowData?.organizations.length &&
          rowData?.organizations[0] === organization?.id && (
            <ActionsButton
              options={options}
              onActionClick={(action) => handleActionClick(action, rowData)}
            />
          )}
      </div>
    )
  }

  return (
    <div className={`${styles.wasteItem} border -mb-px`}>
      <div
        className={`flex flex-wrap items-center content-center justify-between h-full`}
      >
        <div className="flex flex-col my-2 md:flex-row flex-1 justify-between">
          <button
            type="button"
            className="pl-3 flex items-center cursor-pointer"
            style={{ minWidth: "200px" }}
            onClick={() => onEdit(waste)}
            disabled={!permissionObj?.permissions.read}
          >
            <div className={`${styles.imageWrapper} mr-4`}>
              <div
                className={styles.iconWrapper}
                style={{
                  backgroundColor: "#FFE5DB",
                  color: "#F52525",
                }}
              >
                <FontAwesomeIcon icon={faBan} />
              </div>
            </div>
            <span className="text-md font-semibold text-primaryBlue">
              {today ? "Today" : format(new Date(waste.date), "dd/MM/yyyy")}
            </span>
          </button>

          {organizationPermissions?.general?.isMain && (
            <div className="p-3" style={{ minWidth: "200px" }}>
              <div className="font-sansSemiBold font-semibold text-sm text-gray-700">
                Location/Area
              </div>
              <div className="text-sm text-gray-700">
                {waste.organizationsNames[0]}
              </div>
            </div>
          )}

          <div className="flex items-center p-3" style={{ minWidth: "120px" }}>
            <span className="font-sansSemiBold font-semibold text-sm text-gray-700">
              {waste.products.length}
            </span>
            <span className="text-sm text-gray-700">
              &nbsp; {waste.products.length === 1 ? "item" : "items"}
            </span>
          </div>

          <div className="flex items-center p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Total value: </span>
            <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
              &nbsp;
              {`${
                currency
                  ? formatCurrencyValue(waste.totalCost)
                  : roundNumber(waste.totalCost)
              }`}
            </span>
          </div>

          <div className="flex flex-1 justify-end md:items-center">
            {actions(waste)}
          </div>
        </div>
      </div>
    </div>
  )
}

WasteItem.propTypes = {
  waste: PropTypes.object,
  onDeleted: PropTypes.func,
  onCreated: PropTypes.func,
}

export default WasteItem
