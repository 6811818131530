import React from "react"
import * as styles from "./AuthFormWrapper.module.css"
import classNames from "classnames"

type AuthFormWrapperProps = {
  className: string
  children: React.ReactNode
  header?: React.ReactNode
}

const AuthFormWrapper = (props: AuthFormWrapperProps) => {
  const { header = "", className, children } = props
  return (
    <div className={classNames(styles.formContainer, className)}>
      {header}
      <div className={styles.formContent}>{children}</div>
    </div>
  )
}

export default AuthFormWrapper
