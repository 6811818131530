import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { AccountingProvider } from "services/accounting"

interface AccountingStatusLabelProps {
  provider: AccountingProvider
  published: boolean
}

const AccountingStatusLabel: React.FunctionComponent<
  AccountingStatusLabelProps
> = ({ published, provider }: AccountingStatusLabelProps) => {
  return (
    <div className="relative flex items-center">
      <span className="relative z-0">
        <img
          src={provider.logo}
          className="w-5 h-auto mr-2"
          alt={`${provider.title} details`}
        />
      </span>
      <span className="-ml-2.5 pl-1 bg-white relative rounded-full block w-4 h-4 z-10 shadow">
        <FontAwesomeIcon
          icon={published ? faCheck : faTimes}
          className={published ? "text-green-500 -ml-0.5" : "text-gray-400"}
        />
      </span>
    </div>
  )
}

export default AccountingStatusLabel
