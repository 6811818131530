import React from "react"

interface SkuCellProps {
  rowData: {
    code: string
    _selectedCase: "single" | "multiple"
    productCase: {
      code: string
    }
  }
}

const SkuCell = ({ rowData }: SkuCellProps) => {
  const hasCase = !!rowData.productCase
  const _selectedCase = rowData._selectedCase
  const _selectedCaseIsMultiple = _selectedCase && _selectedCase !== "single"

  const sku =
    hasCase && _selectedCaseIsMultiple ? rowData.productCase.code : rowData.code

  return <>{sku}</>
}

export default React.memo(SkuCell)
