import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import TextInput from "components/forms/TextInput"
import CustomSelect from "components/forms/CustomSelect"
import ReactTooltip from "react-tooltip"
import { recipeMeasures } from "services/constants"
import {
  formatCurrencyValue,
  uuidv4,
  formatQuantityValue,
} from "services/helpers"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

interface RecipePortionsFormProps {
  recipeValues: {
    totalCost?: number
    totalCostPercent?: number
    portionCount?: number
    portion?: {
      size?: number
      measure?: string
      cost: number
    }
  }
}

const RecipePortionsForm = ({ recipeValues }: RecipePortionsFormProps) => {
  const uniqueId = uuidv4()
  const permissionObj = usePermissions("Recipes & Dishes") as Permission
  return (
    <>
      <div className="flex flex-wrap flex-row w-full">
        <div className="flex flex-col w-full md:w-2/5 mb-4">
          <span className="inline-flex items-baseline justify-start">
            <h4 className="mr-2 font-sansSemiBold font-semibold h-10 text-gray-800">
              Yield
            </h4>
            <ReactTooltip
              id={`infoBox-${uniqueId}`}
              type="light"
              place="right"
              effect="solid"
              border={true}
              borderColor="#e2e8f0"
            />
            <span
              data-tip="Enter the total volume this recipe produces"
              data-for={`infoBox-${uniqueId}`}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </span>
          <div className="flex flex-row">
            <div className="input-container input-container--2-3">
              <TextInput
                showValidations={true}
                label={"Yield"}
                type="number"
                placeholder="Yield"
                disabled={!permissionObj?.permissions.modify}
                className="form-control form-control--topleft form-control--bottomleft"
                name="yield.size"
              />
            </div>
            <div className="input-container input-container--1-3">
              <CustomSelect
                showValidations={false}
                required={true}
                isClearable={true}
                name="yield.measure"
                id="measure"
                label="Measure"
                type="select"
                isDisabled={!permissionObj?.permissions.modify}
                placeholder="Measure"
                className="form-control form-control--topright form-control--bottomright"
                options={recipeMeasures}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-2/5 md:px-4 mb-4">
          <h4 className="font-sansSemiBold font-semibold h-10 text-gray-800">
            Portions
          </h4>
          <div className="input-container">
            <TextInput
              type="text"
              placeholder="Number of portions"
              disabled={!permissionObj?.permissions.modify}
              className="form-control form-control--first form-control--last"
              name="portionCount"
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/5 mb-4">
          <div className="rounded border border-solid border-gray-300 p-4">
            <h5 className="text-lg mb-2 font-semibold font-sansSemiBold text-gray-800">
              Portion summary
            </h5>
            {recipeValues.portionCount &&
            recipeValues.portion?.size &&
            recipeValues.portion?.measure ? (
              <>
                <h6 className="text-primaryBlue mb-3 font-sansSemiBold font-semibold">
                  {recipeValues.portionCount} x{" "}
                  {formatQuantityValue(recipeValues.portion.size)}{" "}
                  {recipeValues.portion.measure}
                </h6>
                <p className="text-xs mb-2 font-semibold font-sansSemiBold text-gray-700 uppercase">
                  Portion Cost
                </p>
                <span className="text-gray-800">
                  {recipeValues.totalCost
                    ? formatCurrencyValue(recipeValues?.portion?.cost)
                    : 0}
                  <small className="ml-1">
                    ({recipeValues.totalCostPercent || 0}%)
                  </small>
                </span>
              </>
            ) : (
              <p className="text-xs mb-2 font-semibold font-sansSemiBold text-gray-700 uppercase">
                N/A
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RecipePortionsForm
