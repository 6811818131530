import { faCube, faExclamationTriangle, faWineBottle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react'
import Modal from "react-modal"
import Select from "react-select"
import { useMediaQuery } from "react-responsive"
import { roundNumber } from 'services/helpers';
import { toast } from 'react-toastify';


function ModalProductMarkGroup(config: any) {

    const [visible, setvisible] = useState(true)
    const isTabletOrMobile = useMediaQuery({ maxWidth: 639 })

    const {onSubmit} = config;

    const [selectedPreferredProduct, setSelectedPreferredProduct] = useState<any | null>(null);

    const [items, setitems] = useState(config.items.map(a => {
        return {
            ...a,
            _isPackSelected: false,
        }
    }))

    const suppliers = items.map((item) => {
        return {
            preferred: item.preferred,
            supplierName: item.supplierName,
            supplierId: item.supplierId,
            label: item.supplierName,
            value: item.supplierId,
        }
    })
    .sort(a => a.preferred)

    const preferredSuppliers = suppliers
        .filter(a => a.preferred)
        .filter((a, index, self) => self.findIndex(b => b.supplierId === a.supplierId) === index) //Filter same options out;

    const [supplier, setSupplier] = useState(preferredSuppliers[0]);

    const formattedProducts = useMemo(() => items.filter(a => a.supplierId === supplier.supplierId).map(a => ({...a, value: a.id, label: a.name})), [items, supplier])

    useEffect(() => {
        const preferredProductsFromSelectedSupplier = formattedProducts.filter(a => a.preferred);
        setSupplierProducts(preferredProductsFromSelectedSupplier)
        setSelectedPreferredProduct(() => preferredProductsFromSelectedSupplier?.length ? preferredProductsFromSelectedSupplier[0] : null)
    }, [supplier])
    
    const [supplierProducts, setSupplierProducts] = useState<any[]>(formattedProducts.filter(a => a.preferred));

    const handleItemCaseChange = (item) => {
        setitems((prevState) => {
            const temp = [...prevState];
            const obj = temp.find(a => a.id === item.id)
            if (obj)
                obj._isPackSelected = obj.productCase ? !obj._isPackSelected : obj._isPackSelected;
            return temp;
        })
    }

    const handleSubmit = () => {
        const preferredProduct = selectedPreferredProduct;

        if (!preferredProduct)
            return toast.error("You must select a preferred product for this group");
        const childrenIds = items.filter(a => !(a.id === preferredProduct.id)).map(b => b.groupId)
        onSubmit(preferredProduct.groupId, childrenIds)
            .then((response) => {
                if (response?.status?.toString().startsWith('4')) {
                    toast.error(response.message || "Something went wrong during merging of groups")
                } else if (!response?.status?.toString().startsWith('4')) {
                    toast.success(response.message || "Successfully merged groups");
                    closeModal()
                } else {
                    closeModal()
                }
            })
    }

    const closeModal = () => {
        setvisible(false);
    }

    const checkWarnings = () => {
        const messages: string[] = [];
        const firstItem = items.length ? items[0] : null;

        if (suppliers.length < 2)
            messages.push("Selected products must have at least 2 different suppliers");

        //Check if all products have unique suppliers
            // const parsedSuppliers = items.map(p => p.supplierId)
            // if (parsedSuppliers.some((item, idx) => {
            //     return parsedSuppliers.indexOf(item) !== idx;
            // })) {
            //     messages.push("Selected products have overlapping suppliers. A group must consist of unique suppliers.")
            // }
        
        //Check if all products have the same individual quantity
        if (!items.every((a) => {
            return a.size === firstItem.size && a.measure === firstItem.measure;
        })) {
            messages.push("Products have different size and/or measure.")
        }

        //Check if user has to select a preferred product
        if (supplierProducts.length > 1  && !selectedPreferredProduct) {
            messages.push("Select your preferred product")
        }

        return messages
    }

    const warnings = useMemo(checkWarnings, [items, suppliers, supplierProducts])
    
    return (
        visible && <Modal isOpen onRequestClose={closeModal} style={{
            overlay: {
                display: "flex !important",
                alignItems: "start !important"
            },
            content: {
                display: "flex",
                width: "100%",
                height: "auto !important",
                // maxHeight: isTabletOrMobile ? "auto" : "80%",
                maxWidth: 800,
                bottom: "none !important",
                top: "none !important",  
            }
        }}>
            <div className='flex flex-col h-full'>
                <header className='flex flex-col p-6 border-b'>
                    <h3 className='text-xl sm:text-xl mb-1'>Mark as same item</h3>
                    <p className='text-gray-600'>The following items will be marked as same</p>
                </header>
                <div className='flex justify-between flex-col sm:flex-row flex-grow flex-1 overflow-auto'>
                    <div className='sm:flex-1 bg-gray-100 border-r flex flex-col space-y-2 overflow-auto'>
                        {/* Item List */}
                        { 
                            items.map((item: any, idx: number) => {

                                const hasCase = item.productCase;
                                const isPackSelected = Boolean(hasCase && item._isPackSelected);
                                const activeClasses = " bg-white border-gray-200 border rounded-md shadow-sm text-opacity-100"

                                return (
                                    <div key={item.id + idx} className="border-b last:border-b-0 group flex flex-row items-center">
                                        <div className={"flex flex-col flex-shrink-0 w-14 self-stretch relative p-2 bg-paleBlue border-r"} onClick={() => hasCase && handleItemCaseChange(item)}>
                                            <button
                                                className={`h-1/2 py-2 text-opacity-50 text-primaryBlue ${isPackSelected === false ? activeClasses : "bg-paleBlue"}`}
                                                role="button"
                                            >
                                                <FontAwesomeIcon icon={faWineBottle} />
                                            </button>
                                            {hasCase && (
                                                <button
                                                    className={`h-1/2 py-2 text-opacity-50 text-primaryBlue ${isPackSelected === true ? activeClasses : "bg-paleBlue"}`}
                                                    role="button"
                                                >
                                                    <FontAwesomeIcon icon={faCube} />
                                                </button>
                                            )}
                                        </div>

                                        <div className='flex flex-col m-4 w-full'>
                                            <div className='flex flex-col text-sm sm:text-base'>
                                                <span>{item.name}</span>
                                                <div className='mt-2'>
                                                    <span className='mr-2 font-semibold'> {item.size} {item.measure} {item._isPackSelected && "x " + item.productCase.size} {item.unit}</span>
                                                    <span className='text-sm text-gray-600'>{item.barcode}</span>
                                                </div>
                                            </div>

                                            <div className='flex mt-2 justify-between items-center'>
                                                {/* <div className={`${hasCase && "cursor-pointer"} text-primaryBlueLighter font-semibold`} onClick={() => handleItemCaseChange(item)}>
                                                    <ProductIcon product={item} inCase={isPackSelected} />
                                                    <span className={`${hasCase && "underline"}`}>{(isPackSelected) ? "pack" : "single"}</span>
                                                    <span className="text-sm font-thin text-transparent group-hover:text-primaryPink ml-2 transition-colors">switch case</span>
                                                </div> */}
                                                <span className='text-sm'>{item.supplierName}</span>
                                                <span className='inline-block font-semibold text-primaryPink'>{roundNumber(isPackSelected ? item.productCase.price : item.price)}</span>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='sm:flex-1 flex flex-col space-y-3 p-4 sticky top-10'>
                        <div>
                            <h3>Preferred supplier</h3>
                            <p className='text-gray-600'>Set the preferred supplier for this group of items</p>
                            <div className='flex flex-col space-y-4'>
                                <Select classNames="bg-gray-300 font-semibold" menuPlacement={isTabletOrMobile ? "top" : 'auto'} options={preferredSuppliers} value={supplier} defaultValue={preferredSuppliers[0]} onChange={(val) => setSupplier(val)} menuPosition="fixed" />
                            </div>
                        </div>

                        {
                            <div>
                                <h3>Preferred product</h3>
                                <p className='text-gray-600'>Set the preferred product for this group of items</p>
                                <div className='flex flex-col space-y-4'>
                                    <Select classNames="bg-gray-300 font-semibold" value={selectedPreferredProduct} onChange={(value) => setSelectedPreferredProduct(value)} menuPlacement={isTabletOrMobile ? "top" : 'auto'} options={supplierProducts} menuPosition="fixed" />
                                </div>
                            </div>
                        }

                        {/* Warning */}
                        {
                            warnings.map((warning:string, idx) => {
                                return <div key={warning + idx} className='flex items-center bg-orange-200 text-orange-700 rounded-xl p-3'>
                                    <FontAwesomeIcon className='ml-4 mr-6' icon={faExclamationTriangle} />
                                    <p>
                                        {warning}
                                    </p>
                                </div>
                            })
                        }
                    </div>
                </div>
                <footer className='flex justify-end space-x-4 p-3 border-t font-semibold'>
                    <button onClick={closeModal}>Cancel</button>
                    <button 
                        disabled={warnings.length > 0}
                        onClick={handleSubmit}
                        className='bg-primaryGreen text-white p-3 px-4 rounded-lg'>Mark as same</button>
                </footer>
            </div>
        </Modal>
    )
}

export default ModalProductMarkGroup