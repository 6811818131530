// extracted by mini-css-extract-plugin
export var cancelButton = "StockTake-module--cancelButton--19vJp";
export var editButton = "StockTake-module--editButton--lvxRT";
export var footer = "StockTake-module--footer--TbxlL";
export var message = "StockTake-module--message--YbM5N";
export var messageTitle = "StockTake-module--messageTitle--wqxJx";
export var overviewContainer = "StockTake-module--overviewContainer--u2Iqn";
export var primary = "StockTake-module--primary--WQbK3";
export var sheetButton = "StockTake-module--sheetButton--0oaSV";
export var success = "StockTake-module--success--SZGun";
export var thumbnail = "StockTake-module--thumbnail--qGG1h";
export var thumbnailBadge = "StockTake-module--thumbnailBadge--SEMnj";
export var warning = "StockTake-module--warning--TOQQ4";
export var wrapper = "StockTake-module--wrapper--fhWXZ";