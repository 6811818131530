import "react-toastify/dist/ReactToastify.css"
import "react-base-table/styles.css"
import "styling/nprogress.css"

import "styling/base.css"
import "styling/forms.css"
import "styling/buttons.css"
import "styling/labels.css"
import "styling/loader.css"
import "styling/aside.css"
import "styling/table.css"
import "styling/tabs.css"
import "styling/reports.css"
import "styling/calendar.css"

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import * as styles from "./layout.module.css"
import { ToastContainer } from "react-toastify"
import GlobalContextProvider from "../context/global/GlobalContextProvider"
import { ModalProvider } from "../context/modal/ModalContext"
import { AsideProvider } from "../context/aside/AsideContext"
import ModalRoot from "../context/modal/ModalRoot"
import AsideRoot from "../context/aside/AsideRoot"

const Layout = ({ children }) => {
  const canduClientToken = process.env.GATSBY_CANDU_CLIENT_TOKEN
  const stripeKey = process.env.GATSBY_STRIPE_PUBLIC_KEY
  const stripePromise = loadStripe(stripeKey)

  return (
    <>
      <Helmet
        titleTemplate="%s - growyze"
        defaultTitle="growyze - modern stock control software for hospitality"
      >
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#000055" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {canduClientToken != "" && (
          <script
            src="/scripts/candu.js"
            id="candu"
            data-client-token={canduClientToken}
            defer
            type="text/javascript"
          />
        )}
      </Helmet>
      <Elements stripe={stripePromise}>
        <GlobalContextProvider>
          <ModalProvider>
            <AsideProvider>
              <main className={styles.content}>{children}</main>
              <ModalRoot />
              <AsideRoot />
              <ToastContainer
                position="bottom-right"
                autoClose={3500}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </AsideProvider>
          </ModalProvider>
        </GlobalContextProvider>
      </Elements>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
