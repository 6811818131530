import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import classNames from "classnames"
import { useField } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faCheck } from "@fortawesome/pro-regular-svg-icons"

const TextInput = ({
  label = "Enter a value",
  className = "",
  showValidations = true,
  displayLabel = false,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props)

  const { setValue } = helpers

  useEffect(() => {
    if (meta.touched && meta.error && showValidations) {
      setTimeout(() => {
        ReactTooltip.rebuild()
      }, 100)
    }
  }, [meta.touched, meta.error, showValidations])

  const hideInitialValidation = useCallback(() => {
    return !props.required && meta.value === ""
  }, [props.required, meta.value])

  const handleBlur = (e) => {
    // trim leading & trailing spaces
    setValue(e.target.value.trim(), true)
    // Call the Formik onBlur event before your custom stuff
    field.onBlur(e)
  }

  return (
    <>
      <label
        className={
          !displayLabel
            ? "sr-only"
            : "pb-5 block font-semibold text-primaryBlue"
        }
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        style={{
          paddingRight: showValidations && meta.touched ? "2.75rem" : "1rem",
        }}
        className={className}
        {...field}
        {...props}
        onBlur={handleBlur}
      />

      <ReactTooltip
        id={`textInputTooltip-${props.id || props.name}`}
        type="light"
        place="left"
        effect="solid"
        border={true}
        borderColor="#e2e8f0"
      />

      {meta.touched && showValidations && !hideInitialValidation() && (
        <>
          <span
            className={classNames("validation-mark", {
              "validation-mark--success": !meta.error,
              "validation-mark--error": meta.error,
              "validation-mark--offset": displayLabel,
            })}
            data-tip={meta.error}
            data-for={`textInputTooltip-${props.id || props.name}`}
          >
            <FontAwesomeIcon icon={meta.error ? faTimes : faCheck} />
          </span>
        </>
      )}
    </>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  showValidations: PropTypes.bool,
  className: PropTypes.string,
  displayLabel: PropTypes.any,
  required: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
}

export default TextInput
