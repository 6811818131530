import React, { useState, useRef, useEffect } from "react"
import { useDebounce, usePrevious } from "react-use"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type SearchInputProps = {
  label: string | null
  onSearchChange: (val: string) => void
  initialValue?: string
  id?: string
  name?: string
  className?: string
  resetVal?: string | null | any
  placeholder?: string
}

const SearchInput = ({
  id,
  name,
  label = "",
  className = "",
  onSearchChange,
  resetVal = "",
  initialValue = "",
  ...props
}: SearchInputProps) => {
  const [val, setVal] = useState(initialValue)
  const previousVal = usePrevious(val)
  const inputRef = useRef<HTMLInputElement>(null)

  const [, cancel] = useDebounce(
    () => {
      if (val !== previousVal) {
        onSearchChange(val)
      }
    },
    500,
    [val]
  )

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault()
      setVal("")
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    if (resetVal) {
      setVal("")
    }
  }, [resetVal])

  return (
    <div className="input-container w-full relative">
      {label != "" && (
        <label className="sr-only" htmlFor={id || name}>
          {label}
        </label>
      )}
      <span
        className="absolute left-0 h-full w-10 flex items-center justify-center top-0 bottom-0"
        style={{ zIndex: 3 }}
      >
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <input
        type="search"
        ref={inputRef}
        className={className}
        value={val}
        onKeyDown={handleKeyDown}
        onBlur={cancel}
        onChange={({ currentTarget }) => {
          setVal(currentTarget.value)
        }}
        {...props}
      />
    </div>
  )
}

export default SearchInput
