import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faCircle } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./ConfirmCheckbox.module.css"

const ConfirmCheckbox = ({ isChecked, clickHandler }) => {
  const cx = classNames.bind(styles)

  return (
    <button
      className={cx("itemConfirm", { checked: isChecked })}
      onClick={clickHandler}
    >
      <FontAwesomeIcon icon={isChecked ? faCheckCircle : faCircle} />
      <FontAwesomeIcon
        className={styles.confirmButtonHover}
        icon={faCheckCircle}
      />
    </button>
  )
}

ConfirmCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  clickHandler: PropTypes.func,
}

export default ConfirmCheckbox
