import React, { useEffect, useState } from "react"
import { getSageIntegration } from "services/sage"
import { showError } from "services/toast"

interface SageDetailsProps {
  id: string
  onClose?(): void
}
const SageDetails = ({ id }: SageDetailsProps) => {
  const [details, setDetails] = useState<any>(null)

  useEffect(() => {
    const abortController = new AbortController()

    const getDetails = async () => {
      try {
        const res = await getSageIntegration(id, {
          signal: abortController.signal,
        })
        setDetails(res)
      } catch (error) {
        console.log(error)
        showError("Something went wrong")
      }
    }

    getDetails()
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="w-full flex-grow flex flex-col overflow-auto">
      {details && (
        <div className="flex-grow flex flex-col px-4 md:px-6 py-3">
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Integration ID:
          </span>
          <span className="text-gray-800 flex items-center pb-2 border-b">
            {/* <img
              src={"/images/integrations/sage.svg"}
              className="w-8 h-auto mr-2"
              alt="sage details"
            /> */}
            <span>{details?.id}</span>
          </span>
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Organization ID:
          </span>
          <span className="text-gray-800 pb-2 border-b">
            {details?.organizations[0]}
          </span>
        </div>
      )}
    </div>
  )
}

export default SageDetails
