import { RepetitionTypeEnum } from "./types"
import { DayOfWeekEnum } from "services/types"

export const repetitionTypes: Array<{
  label: string
  value: RepetitionTypeEnum
}> = [
  {
    label: "Day(s)",
    value: RepetitionTypeEnum["DAILY"],
  },
  {
    label: "Week(s)",
    value: RepetitionTypeEnum["WEEKLY"],
  },
]

export const daysOfTheWeek: Array<{
  label: string
  value: DayOfWeekEnum
}> = [
  { label: "Monday", value: DayOfWeekEnum["MONDAY"] },
  { label: "Tuesday", value: DayOfWeekEnum["TUESDAY"] },
  {
    label: "Wednesday",
    value: DayOfWeekEnum["WEDNESDAY"],
  },
  { label: "Thursday", value: DayOfWeekEnum["THURSDAY"] },
  { label: "Friday", value: DayOfWeekEnum["FRIDAY"] },
  { label: "Saturday", value: DayOfWeekEnum["SATURDAY"] },
  { label: "Sunday", value: DayOfWeekEnum["SUNDAY"] },
]
