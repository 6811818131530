import Loader from "components/common/Loader/Loader"
import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import PropTypes from "prop-types"
import {
  getSquareLocations,
  setSquareIntegrationLocation,
} from "services/square"

Modal.setAppElement("body")

const SquareLocationSelect = ({
  connectionId,
  onLocationSelected,
  onRequestClose,
  ...otherProps
}) => {
  const [locations, setLocations] = useState([])
  const [error, setError] = useState("")
  const [loading, setLoading] = useState("")

  const loadLocations = async () => {
    setLoading("Loading Square locations..")
    setError("")
    try {
      const { locationIdToLocationName } = await getSquareLocations(
        connectionId
      )
      const locations = Object.keys(locationIdToLocationName).map((k) => {
        return {
          id: k,
          name: locationIdToLocationName[k],
        }
      })
      setLocations(locations)
      setLoading("")
    } catch (e) {
      setError(
        "There was a problem getting locations from Square, please try again later."
      )
      setLoading("")
    }
  }

  const onSelect = async (location) => {
    setError("")
    setLoading("Updating Square location..")
    try {
      await setSquareIntegrationLocation(connectionId, location.id)
      onRequestClose()
      onLocationSelected(location)
      setLoading("")
    } catch (e) {
      setError(
        "There was a problem getting locations from Square, please try again later."
      )
      setLoading("")
    }
  }

  useEffect(() => {
    if (!locations.length) {
      loadLocations()
    }
  }, [])

  return (
    <Modal
      isOpen
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
      portalClassName="squareLocationSelect"
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className="flex flex-col items-center">
        {loading ? (
          <Loader isLoading={true}>{loading}</Loader>
        ) : (
          <div className="overflow-hidden flex-grow flex flex-col">
            <header className="px-4 py-3 text-center border-b flex-shrink-0">
              <h2 className="text-primaryBlue text-2xl mb-2">
                Select your location from Square
              </h2>
              <p className="text-gray-700">
                To make a proper connection, please select your location in
                Square
              </p>
            </header>
            {locations.length ? (
              <ul className="flex-grow overflow-auto">
                {locations.map((l) => {
                  return (
                    <li key={l.id} className="flex flex-col w-full">
                      <button
                        type="button"
                        role="select"
                        className="border-b py-3 px-4 hover:bg-gray-100 text-xl"
                        onClick={() => onSelect(l)}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="text-xl mr-2 text-gray-300"
                        />
                        {l.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <p className="text-primaryPink">No locations found in Square.</p>
            )}
          </div>
        )}
        {error && <p className="text-primaryPink">{error}</p>}
      </div>
    </Modal>
  )
}

SquareLocationSelect.propTypes = {
  onRequestClose: PropTypes.func,
  connectionId: PropTypes.string.isRequired,
  onLocationSelected: PropTypes.func.isRequired,
}

export default SquareLocationSelect
