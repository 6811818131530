import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons"
export default class ErrorContainer extends React.Component {
  state: { hasError: boolean }
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    // TODO log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="growyze-centered-text">
          <div className="inline-flex justify-center items-center bg-secondaryOrangeLighter text-secondaryOrangeDarker p-2 rounded-md">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-4" />
            <p>
              Sorry things aren&apos;t as they should be right now - we&apos;re
              working on it. Hold tight and check back soon.
            </p>
          </div>
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}
