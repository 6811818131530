import { ListItem, PageableData } from "services/types"

type Getter<A> = (params: Object) => Promise<PageableData<A>>

type Processor = (Object) => ListItem

export const processResponse =
  <A>(get: Getter<A>, process: Processor): Getter<ListItem> =>
  (params) =>
    get(params).then((response) => ({
      ...response,
      content: response?.content?.map(process),
    }))
