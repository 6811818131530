import React from "react"
// @ts-ignore
import * as styles from "./ItemStatus.module.css"

interface ItemStatusProps {
  status: { color: string; label: string }
  style?: React.CSSProperties
  children?: React.ReactElement
}

const ItemStatus = ({ status, style = {}, children }: ItemStatusProps) => {
  return (
    <div className={styles.container} style={{ minWidth: "120px", ...style }}>
      <span
        className={styles.bulb}
        style={{
          backgroundColor: status.color,
        }}
      ></span>
      <div className={styles.textWrapper}>
        <span className={styles.label}>{status.label}</span>
        {children}
      </div>
    </div>
  )
}
export default ItemStatus
