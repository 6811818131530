import React, { SetStateAction, useState } from "react"
import {
  calculateNumberInputStep,
  changeNumberInputValue,
  formatNumberInputValue,
} from "services/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
//@ts-ignore
import * as styles from "./UpdateItem.module.css"

interface UpdateItemProps {
  item: any
  onSave(params: {
    dnQty: number | string
    receivedQty: number | string
    receivedQtyInCase: boolean
  }): void
  isSaving: boolean
}

const UpdateItem = ({ item, onSave, isSaving }: UpdateItemProps) => {
  const [isConverted, setIsConverted] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<number | string>(
    item ? item.dnQty : 0
  )
  const [receivedQuantity, setReceivedQuantity] = useState<number | string>(
    item ? item.receivedQty : 0
  )
  const [converted, setConverted] = useState<boolean>(
    item.orderQtyInCase && !item.receivedQtyInCase ? true : false
  )

  //
  // Handlers
  //

  const decreaseQuantity = () => {
    const newQuantity = changeNumberInputValue(quantity, "decr")
    setQuantity(newQuantity)
  }

  const increaseQuantity = () => {
    const newQuantity = changeNumberInputValue(quantity, "incr")
    setQuantity(newQuantity)
  }

  const decreaseReceivedQuantity = () => {
    const newQuantity = changeNumberInputValue(receivedQuantity, "decr")
    setReceivedQuantity(newQuantity)
  }

  const increaseReceivedQuantity = () => {
    const newQuantity = changeNumberInputValue(receivedQuantity, "incr")
    setReceivedQuantity(newQuantity)
  }

  const confirmQuantity = () => {
    let receivedQtyInCase = item.receivedQtyInCase

    if (isConverted) {
      receivedQtyInCase = converted ? false : true
    }

    onSave({
      dnQty: quantity !== "" ? quantity : 0,
      receivedQty: receivedQuantity !== "" ? receivedQuantity : 0,
      receivedQtyInCase: receivedQtyInCase,
    })
  }

  const handleInputChange = (event): SetStateAction<any> => {
    const target = event.target
    const value = formatNumberInputValue(target.value)

    if (target.name === "quantity") {
      return setQuantity(value)
    }

    if (target.name === "receivedQuantity") {
      return setReceivedQuantity(value)
    }
  }

  /**
   * Converts pack to single bottle
   *
   * @return  {Function}
   */
  const convertToSingle = () => {
    setQuantity(formatNumberInputValue(item.orderCaseSize * Number(quantity)))
    setReceivedQuantity(
      formatNumberInputValue(item.orderCaseSize * Number(receivedQuantity))
    )
    setConverted(true)
    setIsConverted(true)
  }

  /**
   * Converts single back to pack
   * checks if quantity is divisible by the amount of bottles per pack and returns pack size
   *
   * @return  {Function}
   */
  const convertToPack = () => {
    setQuantity(
      formatNumberInputValue(
        item.orderCaseSize
          ? Number(quantity) / item.orderCaseSize <= 1
            ? 1
            : Math.floor(Number(quantity) / item.orderCaseSize)
          : 1
      )
    )
    setReceivedQuantity(
      formatNumberInputValue(
        item.orderCaseSize
          ? Number(receivedQuantity) / item.orderCaseSize <= 1
            ? 1
            : Math.floor(Number(receivedQuantity) / item.orderCaseSize)
          : 1
      )
    )
    setConverted(false)
    setIsConverted(true)
  }

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      {(item.receivedQtyInCase || item.orderQtyInCase) && !converted && (
        <div className={styles.top}>
          <span>
            <FontAwesomeIcon className="mr-2" icon={faCube} /> Pack (
            {item.orderCaseSize} x {item.size}
            {item.measure})
          </span>
          <button
            className="button button--paleBlue button--autoWidth"
            onClick={convertToSingle}
          >
            Convert to single {item.unit}{" "}
            <FontAwesomeIcon className="ml-2" icon={faWineBottle} />
          </button>
        </div>
      )}
      {converted && (
        <div className={styles.top}>
          <span>
            <FontAwesomeIcon className="mr-2" icon={faWineBottle} /> {item.unit}{" "}
            ({item.size}
            {item.measure})
          </span>
          <button
            className="button button--paleBlue button--autoWidth"
            onClick={convertToPack}
          >
            Convert to pack ({item.orderCaseSize} x {item.size}
            {item.measure}) <FontAwesomeIcon className="ml-2" icon={faCube} />
          </button>
        </div>
      )}
      <div className={styles.updateQuantity}>
        <h5 className={styles.title}>DN Qty</h5>
        <button
          onClick={decreaseQuantity}
          disabled={isSaving}
        >
          -
        </button>
        <input
          disabled={isSaving}
          name="quantity"
          className={styles.quantityInput}
          onChange={handleInputChange}
          type="number"
          step={calculateNumberInputStep(quantity)}
          value={quantity}
        />
        <button onClick={increaseQuantity} disabled={isSaving}>
          +
        </button>
      </div>
      <div className={styles.updateQuantity}>
        <h5 className={styles.title}>Received</h5>
        <button
          onClick={decreaseReceivedQuantity}
          disabled={isSaving}
        >
          -
        </button>
        <input
          disabled={isSaving}
          name="receivedQuantity"
          className={styles.quantityInput}
          onChange={handleInputChange}
          type="number"
          step={calculateNumberInputStep(receivedQuantity)}
          value={receivedQuantity}
        />
        <button onClick={increaseReceivedQuantity} disabled={isSaving}>
          +
        </button>
      </div>
      <button
        onClick={confirmQuantity}
        className="button button--primaryBlue"
        disabled={isSaving}
      >
        {isSaving && (
          <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
        )}
        Confirm Quantity
      </button>
    </div>
  )
}

export default UpdateItem
