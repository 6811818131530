import React, { useState, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { faTimes, faPlus } from "@fortawesome/pro-light-svg-icons"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import usePermissions from "hooks/usePermissions"
import CustomSelect from "components/forms/CustomSelect"
import { normalizeNullValues } from "services/helpers"
import {
  createStockTransferConnection,
  deleteStockTransferConnectionById,
} from "services/stock-transfer"
import { showSuccess, showError } from "services/toast"
import { Organization, Permission } from "services/types"

import * as styles from "./StockTransferConnection.module.css"

interface StockTransferConnection {
  transfer: any
  organization: Organization
  organizations: [Organization]
  refreshData: Function
}

const StockTransferConnection = ({
  transfer,
  organization,
  organizations,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false)
  const permissionObj = usePermissions("Company settings") as Permission

  const initialValues = transfer.id
    ? { stockTransferConnectionId: transfer.id }
    : {
        activeOrgId: organization.id,
        connectToOrgId: "",
      }

  const filteredOrgs = organizations.filter((org) => {
    return org.value !== organization.id
  })

  const onCreate = async (data) => {
    setLoading(true)
    const result = await createStockTransferConnection(data)
    await refreshData()
    setLoading(false)

    if (result.status === 409) {
      return showError(result.message)
    }

    if (!result) {
      return showError("There was an issue.")
    }

    return showSuccess("Connection added successfully")
  }

  const onDelete = async (data) => {
    setLoading(true)
    const result = await deleteStockTransferConnectionById(data)
    await refreshData()
    setLoading(false)

    if (result.status === 409) {
      return showError(result.message)
    }

    if (!result) {
      return showError("There was an issue.")
    }

    return showSuccess("Connection removed successfully")
  }

  useEffect(() => {
    return () => setLoading(false) //cleanup to suppress "state update on an unmounted component" warning
  }, [])

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  return (
    <Formik
      enableReinitialize
      initialValues={normalizeNullValues(initialValues)}
      onSubmit={transfer.id ? onDelete : onCreate}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form className={styles.container}>
          {transfer.id ? (
            <div className="flex items-center">
              <div className={styles.details}>
                {transfer.connectedOrg.companyName}
                <span className="text-gray-600">
                  {transfer.connectedOrg.address.city}
                </span>
              </div>
              <Field name="stockTransferConnectionId" className="hidden" />
              <button type="submit" className={styles.deleteButton}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ) : (
            <>
              <CustomSelect
                showValidations={false}
                required={true}
                name="connectToOrgId"
                id="connectToOrgId"
                label="Venue"
                isSearchable={!isTabletOrMobile}
                placeholder="Venue"
                className={classNames(
                  "form-control form-control--first form-control--last flex-grow mr-3",
                  styles.select
                )}
                options={filteredOrgs}
                blurInputOnSelect={false}
              />
              {permissionObj?.permissions.delete && (
                <button
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  className={"button button--autoWidth button--primaryGreen"}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
                </button>
              )}
            </>
          )}
          {loading && (
            <div className="text-center text-3xl text-primaryPink ml-2">
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default StockTransferConnection
