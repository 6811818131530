import React from "react"
import { Link } from "@reach/router"

import * as styles from "./IntegrationsButton.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"

const IntegrationsButton = () => {
  return (
    <Link
      to={"/dashboard/company-settings?tab=integrations"}
      className={styles.button}
    >
      <img
        src="/images/integrations/square-icon.svg"
        className="w-5 block mr-2 absolute -left-1 -top-1"
      />
      <img
        src="/images/integrations/lightspeed-icon.svg"
        className="w-3 block mr-2 absolute left-4 top-3"
      />
      <img
        src="/images/integrations/clover-icon.svg"
        className="w-5 block mr-2 absolute -left-1 -bottom-1"
      />
      <span className="font-sansSemiBold text-sm font-semibold z-10 relative flex items-center text-right text-primaryBlue">
        View our POS integrations
        <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
      </span>
    </Link>
  )
}

export default IntegrationsButton
