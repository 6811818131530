// extracted by mini-css-extract-plugin
export var additionalQtyInput = "AddDishWasteForm-module--additionalQtyInput--N4Lx2";
export var content = "AddDishWasteForm-module--content--hPtjl";
export var dateField = "AddDishWasteForm-module--dateField--NJi3O";
export var formBottom = "AddDishWasteForm-module--formBottom--cSoXK";
export var header = "AddDishWasteForm-module--header--yqBtH";
export var inputCol = "AddDishWasteForm-module--inputCol--FtZEZ";
export var productMeta = "AddDishWasteForm-module--productMeta--shYHa";
export var productName = "AddDishWasteForm-module--productName--OLKQ+";
export var quantityInput = "AddDishWasteForm-module--quantityInput--RB3Uf";
export var quantityInputSecond = "AddDishWasteForm-module--quantityInputSecond--twtk7";
export var quantityLabel = "AddDishWasteForm-module--quantityLabel--CnTgy";
export var subValueContainer = "AddDishWasteForm-module--subValueContainer--pXZUc";
export var subValueLabel = "AddDishWasteForm-module--subValueLabel--gGxLX";
export var summary = "AddDishWasteForm-module--summary--tTNaP";
export var summaryItem = "AddDishWasteForm-module--summaryItem--M9U5f";
export var summaryItemList = "AddDishWasteForm-module--summaryItemList--87Am8";