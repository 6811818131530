import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"

const styles = {
  invoiceDeliveryStatus: "rounded py-1 px-3 text-sm block w-auto",
  found: "bg-secondaryGreenVeryLight text-primaryGreenDarker",
  expected: "bg-gray-300",
  notFound: "bg-secondaryOrangeLighter text-secondaryOrangeDarker",
  notFoundTransparent: "bg-transparent text-secondaryOrangeDarker",
  notFoundContainer: "flex items-center",
}

const InvoiceDeliveryInfo = ({
  invoice,
  permissionObj,
  onSearchDeliveryAction,
}) => {
  return (
    <>
      {invoice.po ? (
        <span className={`${styles.invoiceDeliveryStatus} ${styles.found}`}>
          Order found: {`#${invoice.po}`}
        </span>
      ) : (
        <span className={`${styles.invoiceDeliveryStatus} ${styles.notFound}`}>
          No order found
        </span>
      )}
      {invoice.deliveryNoteId ? (
        <span
          className={`${styles.invoiceDeliveryStatus} ${styles.found} transparent`}
        >
          {invoice.deliveryNoteNumber
            ? `DN: ${invoice.deliveryNoteNumber}`
            : "Delivery found"}
        </span>
      ) : (
        <div className={styles.notFoundContainer}>
          <span
            className={`${styles.invoiceDeliveryStatus} ${styles.notFoundTransparent}`}
          >
            No delivery found{" "}
          </span>
          <button
            onClick={onSearchDeliveryAction}
            disabled={!permissionObj?.permissions.modify}
            className="button button--autoWidth button--orange text-sm"
          >
            <FontAwesomeIcon icon={faSearch} className="lg:mr-2" />
            <span className="hidden lg:inline">Search delivery</span>
          </button>
        </div>
      )}
    </>
  )
}

const ReviewInvoiceDeliveryInfo = ({ invoice }) => {
  return (
    <>
      {invoice.expectedPo ? (
        <span className={`${styles.invoiceDeliveryStatus} ${styles.expected}`}>
          Order number: {invoice.expectedPo}
        </span>
      ) : (
        <span className={`${styles.invoiceDeliveryStatus} ${styles.notFound}`}>
          No order number
        </span>
      )}
      {invoice.expectedDeliveryNoteNumber ? (
        <span className={`${styles.invoiceDeliveryStatus} ${styles.expected}`}>
          Delivery Note: {invoice.expectedDeliveryNoteNumber}
        </span>
      ) : (
        <div className={styles.notFoundContainer}>
          <span
            className={`${styles.invoiceDeliveryStatus} ${styles.notFoundTransparent}`}
          >
            No delivery note
          </span>
        </div>
      )}
    </>
  )
}

export { InvoiceDeliveryInfo, ReviewInvoiceDeliveryInfo }
