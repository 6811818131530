import React, { useState } from "react"
import {
  // calculateNumberInputStep,
  changeNumberInputValue,
  formatNumberInputValue,
} from "services/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
// @ts-ignore
// import * as styles from "./UpdateItem.module.css"
import { Form, Formik } from "formik"
import TextInput from "components/forms/TextInput"
import { normalizeNullValues } from "../../../services/helpers"
interface UpdateItemProps {
  item: any
  onSave(params: {
    invoiceQty: number | string
    invoiceQtyInCase: boolean
    receivedQtyInCase: boolean
    invoicePrice: number | string
  }): void
  newItem: boolean
  isSaving: boolean
}

const UpdateItem = ({ item, onSave, newItem }: UpdateItemProps) => {
  const [inCase, setInCase] = useState<boolean>(item.invoiceQtyInCase)

  const caseSize = newItem ? item.productCase?.size : item.orderCaseSize

  const computeDefaultPrice = (inCase: boolean, price?: number | string) => {
    if (newItem) {
      if (inCase) {
        return item.productCase.price
      } else {
        return item.price
      }
    } else {
      if (!price) {
        return item.invoicePrice
      } else {
        if (inCase) {
          return Number(price) / caseSize
        } else {
          return Number(price) * caseSize
        }
      }
    }
  }

  const confirmQuantity = (data) => {
    const { quantity, price } = data
    onSave({
      invoiceQty: quantity ? quantity : 0,
      invoiceQtyInCase: inCase,
      receivedQtyInCase: inCase,
      invoicePrice: price,
    })
  }

  return (
    <div className="">
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={normalizeNullValues({
          price: computeDefaultPrice(inCase),
          quantity: item ? item.invoiceQty : 0,
        })}
        onSubmit={confirmQuantity}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const { price, quantity } = values

          const decreaseQuantity = (e) => {
            e.preventDefault()
            const newQuantity = changeNumberInputValue(quantity, "decr")
            setFieldValue("quantity", newQuantity)
          }

          const increaseQuantity = (e) => {
            e.preventDefault()
            const newQuantity = changeNumberInputValue(quantity, "incr")
            setFieldValue("quantity", newQuantity)
          }

          /**
           * Converts pack to single bottle
           *
           * @return  {Function}
           */
          const convertToSingle = (e) => {
            e.preventDefault()
            setFieldValue(
              "quantity",
              formatNumberInputValue(caseSize * Number(quantity))
            )
            setFieldValue("price", Number(price) / caseSize)
            setInCase(false)
          }

          /**
           * Converts single back to pack
           * checks if quantity is divisible by the amount of bottles per pack and returns pack size
           *
           * @return  {Function}
           */

          const convertToPack = (e) => {
            e.preventDefault()
            setFieldValue(
              "quantity",
              formatNumberInputValue(
                Math.floor(Number(quantity) / caseSize) || 1
              )
            )
            setFieldValue("price", Number(price) * caseSize)
            setInCase(true)
          }

          return (
            <Form className="p-2">
              {caseSize != undefined &&
                (inCase ? (
                  <div className="flex flex-wrap justify-center sm:justify-between p-4 items-center border-b space-x-2 gap-y-2">
                    <span>
                      <FontAwesomeIcon className="mr-2" icon={faCube} /> Pack{" "}
                      {caseSize &&
                        item.size &&
                        item.measure &&
                        `(${caseSize} x ${item.size} ${item.measure})`}
                    </span>
                    <button
                      type="button"
                      className="button button--paleBlue button--autoWidth"
                      onClick={convertToSingle}
                    >
                      Convert to single {item.unit}{" "}
                      <FontAwesomeIcon className="ml-2" icon={faWineBottle} />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-wrap justify-center sm:justify-between p-4 items-center border-b space-x-2 gap-y-2">
                    <span>
                      <FontAwesomeIcon className="mr-2" icon={faWineBottle} />{" "}
                      {item.unit} ({item.size} {item.measure})
                    </span>
                    <button
                      type="button"
                      className="button button--paleBlue button--autoWidth"
                      onClick={convertToPack}
                    >
                      Convert to pack ({caseSize} x {item.size}
                      {item.measure}){" "}
                      <FontAwesomeIcon className="ml-2" icon={faCube} />
                    </button>
                  </div>
                ))}
              <div className="p-4 flex flex-wrap justify-center gap-x-20 gap-y-6">
                <div className="space-y-4">
                  <h5 className="uppercase opacity-50 text-center">
                    Invoice price
                  </h5>
                  <TextInput
                    name="price"
                    type="number"
                    step="any"
                    className="border-gray-400 rounded-md text-center"
                    autoFocus={price === 0}
                    showValidations={false}
                  />
                </div>

                <div className="space-y-4">
                  <h5 className="uppercase opacity-50 text-center">
                    Invoice qty
                  </h5>
                  <button
                    className="w-10 h-10 text-2xl items-center justify-center rounded-full border leading-none"
                    type="button"
                    onClick={decreaseQuantity}
                    disabled={isSubmitting}
                  >
                    -
                  </button>
                  <TextInput
                    disabled={isSubmitting}
                    name="quantity"
                    className="w-16 text-center border-0"
                    type="number"
                    step="any"
                    autoFocus={quantity === 0}
                    showValidations={false}
                  />
                  <button
                    className="w-10 h-10 text-2xl items-center justify-center rounded-full border leading-none"
                    type="button"
                    onClick={increaseQuantity}
                    disabled={isSubmitting}
                  >
                    +
                  </button>
                </div>
              </div>

              <button
                className="button button--primaryBlue"
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    className="mr-2"
                    spin
                  />
                )}
                Confirm Quantity &amp; Price
              </button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default UpdateItem
