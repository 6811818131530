import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  RefObject,
} from "react"
import Modal from "react-modal"
import { getProductByBarcode } from "services/products/products"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"

import AddWasteForm from "components/waste/AddWasteForm/AddWasteForm"
import AddRecipeWasteForm from "components/waste/AddRecipeWasteForm/AddRecipeWasteForm"
import BarcodeScanner from "components/scanner/BarcodeScanner/BarcodeScanner"
import RecipeSelect from "components/common/RecipeSelect/RecipeSelect"
import ProductNotFound from "components/stock/ProductNotFound/ProductNotFound"
import classNames from "classnames/bind"
import NewProductSelect from "components/common/NewProductSelect/NewProductSelect"
import DishSelect from "components/common/DishSelect/DishSelect"
import AddDishWasteForm from "../AddDishWasteForm/AddDishWasteForm"

//@ts-ignore
import * as styles from "./WasteInput.module.css"
import Loader from "components/common/Loader/Loader"
import { formatAsYearMonthDay } from "services/helpers"
import { WasteTab } from "services/waste/types"

const cx = classNames.bind(styles)

const newProduct = {
  barcode: null,
  category: null,
  description: null,
  measure: null,
  size: null,
  subCategory: null,
  id: null,
}

interface WasteInputProps {
  onRequestClose(): Function
  onSave(product: any, waste: any): Function
  onSaveRecipe(recipe: any, waste: any): Function
  onSaveDish(dish: any, waste: any): Function
  waste: {}
  allowDateSelect: boolean
  manually?: boolean
  initialTab?: WasteTab
}

interface Tab {
  key: WasteTab
  label: string
}

const WasteInput = ({
  onRequestClose,
  onSave,
  onSaveRecipe,
  onSaveDish,
  waste,
  allowDateSelect,
  manually = false,
  initialTab = "products",
  ...otherProps
}: WasteInputProps) => {
  const scannerRef: RefObject<BarcodeScanner> = useRef(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [, setBarcode] = useState<string | null>("")
  const [notFound, setNotFound] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(manually ? 4 : 1)
  const [currentProduct, setCurrentProduct] = useState(null)
  const [currentRecipe, setCurrentRecipe] = useState(null)
  const [currentDish, setCurrentDish] = useState(null)
  const [tab, setTab] = useState<WasteTab>(initialTab)

  const [globalDate, setGlobalDate] = useState<string>(
    formatAsYearMonthDay(new Date())
  )

  useEffect(() => {
    if (currentProduct) {
      setCurrentStep(3)
    }
  }, [currentProduct, setCurrentStep])

  const setStep2 = useCallback(async (barcodeResult) => {
    const { barcodeText } = barcodeResult

    pauseScanning()

    if (barcodeText) {
      setBarcode(barcodeText)
      setCurrentStep(2)
      setLoading(true)

      const result = await getProductByBarcode(barcodeText)

      if (result.status === 404) {
        setNotFound(true)
        setLoading(false)
        return
      }

      // Product found
      if (result) {
        setCurrentProduct({ ...newProduct, ...result })
        setLoading(false)
        setCurrentStep(3)
      }
    } else {
      setNotFound(true)
      return
    }
  }, [])

  const tabs: Tab[] = [
    {
      label: "Items",
      key: "products",
    },
    {
      label: "Recipes",
      key: "recipes",
    },
    {
      label: "Dishes",
      key: "dishes",
    },
  ]

  const resetState = () => {
    setNotFound(false)
    setCurrentProduct(null)
    setCurrentRecipe(null)
    setCurrentDish(null)
    setBarcode(null)
  }

  const resumeScanning = () => {
    resetState()
    setCurrentStep(manually ? 4 : 1)

    if (!manually && scannerRef.current) {
      scannerRef.current.resume()
    }
  }

  const pauseScanning = () => {
    if (scannerRef.current) {
      scannerRef.current.pause()
    }
  }

  const handleClose = () => {
    resetState()
    onRequestClose()
  }

  const handleAfterSave = (next: boolean) => {
    // if 'save & next' resume scanning, otherwise close modal
    if (next) {
      resumeScanning()
    } else {
      handleClose()
    }
  }

  /**
   * Save action for regular product (item)
   */
  const onWasteSave = async (next: boolean, product, waste) => {
    setLoading(true)
    try {
      if (onSave) {
        if (product.packaging === "single" || product.packaging == null) {
          await onSave(product, waste)
          // If the user selected multiple packaging, just multiply the
          // amount of items with the case size in order to get the quantity
          // in single items
        } else if (product.packaging === "multiple") {
          const newWaste = {
            ...waste,
            fullQty: waste.fullQty * product.productCase.size,
          }

          await onSave(product, newWaste)
        } else {
          throw new TypeError(`Invalid packaging - ${product.packaging}`)
        }
      }
      handleAfterSave(next)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  /**
   * Save action recipes
   */
  const onRecipeWasteSave = async (next: boolean, recipe, waste) => {
    setLoading(true)
    try {
      if (onSaveRecipe) {
        await onSaveRecipe(recipe, waste)
      }
      handleAfterSave(next)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  /**
   * Save action for dishes
   */
  const onDishWasteSave = async (next: boolean, dish, waste) => {
    setLoading(true)
    try {
      if (onSaveDish) {
        await onSaveDish(dish, waste)
      }
      handleAfterSave(next)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  const goToStep3 = () => {
    setLoading(false)
    setCurrentStep(3)
  }

  const handleProductSelect = async (product) => {
    const { packaging, price, productCase, code } = product
    const isMulti = packaging === "multiple"
    const formattedProduct = {
      ...newProduct,
      ...product,
      price: isMulti ? productCase.price : price,
      code: isMulti ? productCase.code : code,
    }
    setCurrentProduct({ ...newProduct, ...formattedProduct })
    goToStep3()
  }

  const handleRecipeSelect = async (recipe) => {
    setCurrentRecipe(recipe)
    goToStep3()
  }

  const handleDishSelect = async (dish) => {
    setCurrentDish(dish)
    goToStep3()
  }

  const content = manually ? null : (
    <BarcodeScanner
      ref={scannerRef}
      onInit={() => setLoading(false)}
      onReadSuccess={setStep2}
      blur={currentStep === 3 || currentStep === 4}
      onAddManually={() => setCurrentStep(4)}
    />
  )

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading && currentStep !== 3 && currentStep !== 4}
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.navBar}>
          {!manually && (
            <nav className={cx("header", "transparent")}>
              <button onClick={onRequestClose}>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className={styles.backIcon}
                />
              </button>

              <div className={styles.headerMain}>
                <h2 className={styles.title}>Add waste</h2>
              </div>

              <div className={styles.placeHolder}></div>
            </nav>
          )}
        </div>

        <div className={cx("content", "step" + currentStep)}>
          {content}

          {(currentStep === 3 || currentStep === 4 || notFound) && (
            <div className={styles.clickArea} onClick={resumeScanning}></div>
          )}

          <div className={cx("notFound", { show: notFound })}>
            <ProductNotFound
              onResume={resumeScanning}
              onAddManually={() => {
                setNotFound(false)
                setCurrentStep(4)
              }}
            />
          </div>

          <div className={styles.confirmContainer}>
            <div className={styles.confirm}>
              <Loader isLoading={loading}>
                <span>Saving..</span>
              </Loader>
              {currentProduct && (
                // @ts-ignore
                <AddWasteForm
                  newProduct={currentProduct}
                  onCancel={resumeScanning}
                  onSave={onWasteSave}
                  waste={waste}
                  allowDateSelect={allowDateSelect}
                  globalDate={globalDate}
                  setGlobalDate={setGlobalDate}
                />
              )}

              {currentRecipe && (
                <AddRecipeWasteForm
                  newRecipe={currentRecipe}
                  onCancel={resumeScanning}
                  onSave={onRecipeWasteSave}
                  waste={waste}
                  allowDateSelect={allowDateSelect}
                />
              )}

              {currentDish && (
                <AddDishWasteForm
                  newDish={currentDish}
                  onCancel={resumeScanning}
                  onSave={onDishWasteSave}
                  waste={waste}
                  allowDateSelect={allowDateSelect}
                />
              )}
            </div>
          </div>

          <div className={styles.productSelectContainer}>
            <div className={styles.productSelect}>
              <div className="h-full overflow-hidden flex flex-col bg-white">
                <div className="pt-4 pb-0 flex flex-col">
                  <div className="flex px-6 py-2">
                    <button
                      className="text-primaryBlue mr-4"
                      onClick={manually ? onRequestClose : resumeScanning}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <h2 className="text-xl text-primaryBlue">
                      Search {tab === "products" ? "products" : tab}
                    </h2>
                  </div>
                  <ul className={styles.tabs}>
                    {tabs.map((t: Tab) => (
                      <li key={t.key}>
                        <button
                          className={cx("tabButton", {
                            active: tab === t.key,
                          })}
                          onClick={() => setTab(t.key)}
                        >
                          {t.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex-grow flex flex-col overflow-hidden relative">
                  {tab === "products" && (
                    // @ts-ignore
                    <NewProductSelect
                      searchByCategories={true}
                      onSelect={handleProductSelect}
                      multiSelect={false}
                    />
                  )}

                  {tab === "recipes" && (
                    <RecipeSelect onSelect={handleRecipeSelect} />
                  )}

                  {tab === "dishes" && (
                    <DishSelect onSelect={handleDishSelect} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WasteInput
