import React, { useState } from "react"
import FormGroup from "./FormGroup"

interface FormGroupProps {
  title: string
  label: string
  id?: string
  sameContact?: boolean
  initialChecked?: boolean
  onChange?(value): void
  children?: any
}

const FormGroupToggle = ({
  title,
  label,
  id = "",
  initialChecked = true,
  onChange = () => {},
  children = null,
}: FormGroupProps) => {
  const [checked, setChecked] = useState<boolean>(initialChecked)

  const onCheckedChange = () => {
    setChecked(!checked)
    onChange(!checked)
  }

  return (
    <FormGroup title={title} labelClass={checked ? "disabled" : ""}>
      <div className="form-group form-group--flex w-full">
        <div
          className={
            "input-container input-container--1 flex items-center relative border border-gray-300 rounded-r " +
            (checked ? "" : "border-b-0")
          }
          style={{ marginBottom: "-1px" }}
        >
          <div className="space-x-2 p-3 w-full">
            <input
              type="checkbox"
              value={""}
              onChange={(evt) => onCheckedChange(evt)}
              name="hasCase"
              id={id}
              className="form-checkbox text-primaryPink"
              checked={checked}
            />
            <label htmlFor={id}>{label}</label>
          </div>
        </div>
      </div>
      {!checked && children}
    </FormGroup>
  )
}

export default FormGroupToggle
