import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useField } from "formik"
import React, { useEffect } from "react"
import TextInput from "./TextInput"

interface FormFieldRepeaterProps {
  name: string
  label: string
  placeholder: string
  type?: string
  required?: boolean
  showValidations?: boolean
  defaultValue?: string
  roundedTopRight?: string
  roundedBottomRight?: string
}

const FormFieldRepeater = ({
  name,
  label,
  placeholder,
  type = "text",
  required = true,
  showValidations = false,
  defaultValue = "",
  roundedTopRight = "rounded-tr",
  roundedBottomRight = "rounded-br",
}: FormFieldRepeaterProps) => {
  const [field, , helpers] = useField(name)
  const { value } = field
  const { setValue } = helpers

  useEffect(() => {
    if (!value) {
      setValue([defaultValue])
    }
  })

  const onAdd = () => {
    const newValue = value ? value.concat([defaultValue]) : [defaultValue]
    setValue(newValue)
  }

  const onDelete = (index) => {
    if (value.length === 1) {
      setValue([defaultValue])
    } else {
      value.splice(index, 1)
      setValue(value)
    }
  }

  return (
    <div className="form-group form-group--flex w-full">
      {value?.map((_, index) => {
        const isFirst = index === 0
        const isLast = index === value.length - 1
        return (
          <div className="flex flex-row w-full" key={index}>
            <div
              key={index}
              className="input-container input-container--1 flex-1"
            >
              <TextInput
                required={required}
                name={`${name}[${index}]`}
                label={label}
                type={type}
                placeholder={placeholder + (required ? "*" : "")}
                className="form-control"
                showValidations={showValidations}
              />
            </div>
            <div
              className={
                "items-center border-gray-300 border-b border-r px-2.5 flex" +
                (isFirst ? " border-t " + roundedTopRight : "") +
                (isLast ? " border-b-0 " + roundedBottomRight : "")
              }
              style={{ marginBottom: "-1px" }}
            >
              {isLast && (
                <>
                  <button
                    type="button"
                    onClick={onAdd}
                    className="btn bg-primaryBlueVeryLight px-1.5 mr-2.5 rounded text-gray-700 font-sansBold self-center"
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </button>
                  <div className="h-full border-r w-0 mr-2.5 border-gray-300"></div>
                </>
              )}

              <button
                type="button"
                onClick={() => onDelete(index)}
                className="btn bg-primaryPink px-1.5 rounded text-gray-700 font-sansBold self-center"
                disabled={value.length === 1 && isFirst}
              >
                <FontAwesomeIcon
                  icon={faMinus}
                  size="sm"
                  className="text-white"
                />
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FormFieldRepeater
