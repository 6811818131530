import React, { useContext, useCallback } from "react"
import * as styles from "./QuickActions.module.css"
import { format } from "date-fns"
import { uuidv4 } from "services/helpers"
import QuickAction from "./QuickAction"
import {
  faBoxOpen,
  faFileAlt,
  faShoppingCart,
  faSyncAlt,
  faBan,
} from "@fortawesome/pro-duotone-svg-icons"
import DeliveryNoteModal from "components/delivery-notes/DeliveryNote/DeliveryNote"
import InvoiceModal from "components/invoices/Invoice/Invoice"
import StockTakeModal from "components/stock/StockTake/StockTake"
import StockTakesInProgressModal from "components/stock/StockTakesInProgressModal/StockTakesInProgressModal"
import WasteModal from "components/waste/WasteModal/WasteModal"
import PropTypes from "prop-types"
import { ModalContext } from "context/modal/ModalContext"
import { GlobalDispatchContext } from "context/global/GlobalContextProvider"
import usePermissions from "hooks/usePermissions"

const QuickActions = ({ wrap = false, onActionClicked, disabled = false }) => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions()

  const deliveryNoteAction = useCallback(() => {
    dispatch({
      type: "RESET_NEW_DELIVERY_NOTE",
      options: { resetSupplier: true },
    })
    modal.showModal(DeliveryNoteModal)
    if (onActionClicked) {
      onActionClicked()
    }
  }, [modal, onActionClicked])

  const invoiceAction = useCallback(() => {
    dispatch({ type: "RESET_NEW_INVOICE" })
    modal.showModal(InvoiceModal)
    if (onActionClicked) {
      onActionClicked()
    }
  }, [modal, onActionClicked])

  const hasPermission = (name) => {
    return permissionObj.find((p) => p.name === name)?.permissions.modify
  }

  const stockTakeAction = useCallback(() => {
    modal.showModal(StockTakesInProgressModal, {
      onCreate: (e) => {
        e.preventDefault()
        dispatch({ type: "RESET_NEW_STOCKTAKE" })
        dispatch({
          type: "CREATE_NEW_STOCKTAKE",
          payload: {
            stockTakeData: {
              name: `Stocktake ${format(new Date(), "dd-MM-yy HH:mm")}`,
              status: "IN_PROGRESS",
              key: uuidv4(),
              unsynced: true,
              subStockTakeReports: [],
              products: [],
              recipes: [],
            },
          },
        })
        dispatch({
          type: "CREATE_NEW_STOCKTAKE_SUBREPORT",
          payload: {
            stockTakeData: {
              name: `Main bar`,
              status: "IN_PROGRESS",
              key: uuidv4(),
              unsynced: true,
              products: [],
              recipes: [],
            },
          },
        })
        modal.showModal(StockTakeModal)
      },
    })
    if (onActionClicked) {
      onActionClicked()
    }
  }, [modal, onActionClicked])

  const wasteAction = useCallback(() => {
    modal.showModal(WasteModal, {
      getDataWasteGroups: () => {},
      onDirectScan: false,
    })
    if (onActionClicked) {
      onActionClicked()
    }
  }, [modal, onActionClicked])

  return (
    <div
      className={`relative z-10 ${
        disabled ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <div className={styles.content}>
        <QuickAction
          wrap={wrap}
          disabled={disabled || !hasPermission("Orders")}
          to="/dashboard/purchases/orders/new"
          icon={faShoppingCart}
          backgroundColor="#FFE5F0"
          color="#FC3762"
          name="Order"
        />
        <QuickAction
          wrap={wrap}
          disabled={disabled || !hasPermission("Delivery notes")}
          icon={faBoxOpen}
          backgroundColor="#FAEAFF"
          color="#B91DB3"
          name="Delivery Note"
          callback={deliveryNoteAction}
        />
        <QuickAction
          wrap={wrap}
          disabled={disabled || !hasPermission("Invoices")}
          icon={faFileAlt}
          backgroundColor="#E7EDFF"
          color="#1B377D"
          name="Invoice"
          callback={invoiceAction}
        />
        <QuickAction
          wrap={wrap}
          disabled={disabled || !hasPermission("Waste")}
          icon={faBan}
          backgroundColor="#FFE5DB"
          color="#F52525"
          name="Waste"
          callback={wasteAction}
        />
        <QuickAction
          wrap={wrap}
          disabled={disabled || !hasPermission("Stocktakes")}
          icon={faSyncAlt}
          backgroundColor="#E0FFFF"
          color="#35CCC3"
          name="Stocktake"
          callback={stockTakeAction}
        />
      </div>
    </div>
  )
}

QuickActions.propTypes = {
  wrap: PropTypes.bool,
  onActionClicked: PropTypes.func,
  disabled: PropTypes.bool,
}

export default QuickActions
