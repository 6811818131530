import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons"
import { faCamera } from "@fortawesome/pro-duotone-svg-icons"
import PropTypes from "prop-types"

import * as styles from "./ProductNotFound.module.css"

const ProductNotFound = ({ onResume, onAddManually, onAddItem }) => {
  return (
    <>
      <div className={styles.notFoundHeader}>
        <h3 className={styles.notFoundTitle}>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-4" />
          Product not found
        </h3>
      </div>
      <div className={styles.notFoundContent}>
        <button
          type="button"
          className="button--autoWidth p-4 text-gray-700 text-semibold font-sansSemiBold my-2"
          onClick={onResume}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onAddItem ? onAddItem : onAddManually}
          className="
              button button--autoWidth button--primaryGreen
              addManualButton
            "
        >
          <FontAwesomeIcon icon={faPlus} className="mr-3" />
          {onAddItem ? "Add item" : "Add manually"}
        </button>
        <button
          type="button"
          className="button--autoWidth text-primaryGreen text-semibold font-sansSemiBold p-4"
          onClick={onResume}
        >
          <FontAwesomeIcon icon={faCamera} className="mr-3" />
          Retry
        </button>
      </div>
    </>
  )
}

ProductNotFound.propTypes = {
  onResume: PropTypes.func,
  onAddManually: PropTypes.func,
  onAddItem: PropTypes.func,
}

export default ProductNotFound
