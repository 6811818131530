import React from "react"
import BaseTable, { AutoResizer } from "react-base-table"
import { useMediaQuery } from "react-responsive"
import ProductIcon from "components/common/ProductIcon/ProductIcon"

const StandingOrderItemContent = ({ orderTemplate }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col my-3">
          <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue">
            {rowData.name}
          </span>
        </div>
      ),
    },
    {
      key: "packaging",
      title: "Packaging",
      width: 150,
      dataKey: "packaging",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const hasCase = !!rowData.productCase
        const isOrderedAsCase = rowData.orderInCase

        return rowData.totalPrice ? null : (
          <>
            <ProductIcon product={rowData} inCase={isOrderedAsCase} />
            {isOrderedAsCase && hasCase ? (
              <span className="text-sm text-gray-700 px-2">
                {rowData.productCase.size} x {rowData.size} {rowData.measure}
              </span>
            ) : (
              <span className="text-sm text-gray-700 px-2">
                {rowData.size} {rowData.measure}
              </span>
            )}
          </>
        )
      },
    },
    {
      key: "quantity",
      width: 80,
      title: "Qty",
      dataKey: "quantity",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span className="text-sm">x {rowData.quantity}</span>
      ),
    },
  ]

  return (
    <AutoResizer height={Infinity}>
      {({ width, height }) => (
        <BaseTable
          fixed={isTabletOrMobile ? true : false}
          style={{ backgroundColor: "transparent" }}
          width={width}
          maxHeight={orderTemplate.items.length === 0 ? undefined : Infinity}
          height={orderTemplate.items.length === 0 ? 80 : height}
          data={orderTemplate.items}
          rowKey="productId"
          columns={columns}
          emptyRenderer={
            <div className="flex items-center font-sansSemiBold font-semibold justify-center h-full text-xl text-center">
              No items found
            </div>
          }
          ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
        />
      )}
    </AutoResizer>
  )
}
export default StandingOrderItemContent
