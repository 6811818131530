import React from "react"
import { Link } from "gatsby"

interface SupplierCellRowData {
  supplierName: string
  supplierId?: string
  preferred: boolean
  countOfProductsInGroup: number
}

interface SupplierCellProps {
  rowData: SupplierCellRowData
}

const SupplierCell = ({ rowData }: SupplierCellProps) => {
  const hasNoSupplier: boolean = rowData?.supplierId === "no-supplier-id"
  return (
    <div>
      {rowData.preferred && rowData?.countOfProductsInGroup > 1 && (
        <span className="text-xs text-gray-600 inline-block mb-1">
          Preferred supplier
        </span>
      )}
      <div className="text-primaryBlue">
        {hasNoSupplier ? (
          <span>{rowData.supplierName}</span>
        ) : (
          <Link
            to={`/dashboard/purchases/suppliers/${rowData.supplierId}`}
            className={`font-sansSemiBold`}
          >
            {rowData.supplierName}
          </Link>
        )}
      </div>
    </div>
  )
}

export default React.memo(SupplierCell)
