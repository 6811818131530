/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import BaseTable, { AutoResizer } from "react-base-table"
import { formatCurrencyValue, roundNumber } from "services/helpers"
import { orderStates } from "services/constants"
import { format } from "date-fns"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { faCube, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import * as styles from "./OrderSelectItem.module.css"

const OrderSelectItem = ({ order, onSelect }) => {
  const [collapse, setCollapse] = useState(false)

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const hasCase = !!rowData.productCase
        const isOrderedAsCase = rowData.orderInCase
        const icon =
          hasCase && isOrderedAsCase && rowData.orderInCase
            ? faCube
            : faWineBottle

        return (
          <div className="flex flex-col my-3">
            <span className="font-sansSemiBold font-semibold text-primaryBlue text-sm">
              <span className="text-gray-700">{rowData.quantity} x </span>{" "}
              {rowData.name}
            </span>
            <span className="flex items-center py-1 text-xs text-gray-700">
              <FontAwesomeIcon icon={icon} />
              {isOrderedAsCase && hasCase ? (
                <span className="px-1">
                  multiple {rowData.productCase.size} x {rowData.size}{" "}
                  {rowData.measure}
                </span>
              ) : (
                <span className="px-1">
                  {"single"} {rowData.size} {rowData.measure}
                </span>
              )}
            </span>
          </div>
        )
      },
    },
    {
      key: "price",
      width: 80,
      title: "Price",
      dataKey: "price",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span className="text-sm">
          {rowData.orderInCase
            ? rowData.productCase.price
              ? order.supplier.currency
                ? formatCurrencyValue(rowData.productCase.price)
                : roundNumber(rowData.productCase.price)
              : ""
            : rowData.price
            ? order.supplier.currency
              ? formatCurrencyValue(rowData.price)
              : roundNumber(rowData.price)
            : ""}
          {rowData.orderInCase && rowData.productCase.price
            ? " / pack"
            : rowData.unit
            ? ` / ${rowData.unit.toLowerCase()}`
            : ""}
        </span>
      ),
    },
    {
      key: "estimatedCost",
      width: 80,
      title: "Total",
      dataKey: "estimatedCost",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span className="text-sm">
          {order.supplier.currency
            ? formatCurrencyValue(rowData.estimatedCost)
            : roundNumber(rowData.estimatedCost)}
        </span>
      ),
    },
  ]

  const nameButton = (rowData) => {
    const orderState = orderStates.find((oS) => oS.value === rowData.status)

    return (
      <button
        onClick={(e) => {
          e.preventDefault()
          setCollapse(!collapse)
        }}
        style={{ minWidth: "150px", textAlign: "left" }}
        className="p-4 flex flex-grow outline-none focus:outline-none flex-col"
      >
        <span className="font-sansSemiBold focus:outline-none relative font-semibold text-primaryBlue">
          <span
            className="rounded-full absolute"
            style={{
              backgroundColor: orderState.color,
              left: "-18px",
              top: "8px",
              width: "10px",
              height: "10px",
            }}
          ></span>
          {order.status !== "DRAFT"
            ? `#${order.po}`
            : `[Draft] ${format(
                new Date(order.createdDate),
                "dd-MM-yy HH:mm"
              )}`}
        </span>
        {orderState.value === "PLACED" && (
          <span className="text-xs text-gray-700">
            {format(new Date(rowData.placedDate), "dd-MM-yy HH:mm")}
          </span>
        )}
        {orderState.value === "DRAFT" && (
          <span className="text-xs text-gray-700">
            {format(new Date(rowData.createdDate), "dd-MM-yy HH:mm")}
          </span>
        )}
        {orderState.value === "COMPLETED" && (
          <span className="text-xs text-gray-700">
            {format(new Date(rowData.completedDate), "dd-MM-yy HH:mm")}
          </span>
        )}
        <span className="text-sm font-sansSemiBold focus:outline-none font-semibold text-gray-700">
          {order.supplier.name}
        </span>
      </button>
    )
  }

  /**
   * Action used for both Edit and Re-order
   * @param  {Object} rowData current order
   * @return {Function}         shows action buttons
   */
  const actions = (rowData) => {
    const { isDelivered } = rowData
    return (
      <div className="px-3" style={{ minWidth: "125px", textAlign: "right" }}>
        <button
          type="button"
          className={classNames(
            "button whitespace-nowrap button--smaller button--autoWidth mr-2",
            {
              "button--paleBlue": !collapse,
              "button--paleBlueDarker": collapse,
            }
          )}
          onClick={(e) => {
            e.preventDefault()
            onSelect(rowData)
          }}
          disabled={isDelivered}
        >
          Select
        </button>
      </div>
    )
  }

  return (
    <div
      className="border -mb-px"
      style={{ backgroundColor: collapse ? "#F8F8FF" : "#FFFFFF" }}
    >
      <div className="flex flex-wrap items-center content-center justify-start">
        <div className="lg:pl-2 mr-3">
          <button
            type="button"
            className="button--smaller button--autoWidth"
            onClick={(e) => {
              e.preventDefault()
              setCollapse(!collapse)
            }}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={collapse ? 180 : 0}
            />
          </button>
        </div>
        {nameButton(order)}
        <div className="flex flex-col p-4 ml-auto">
          <span className="text-xs text-uppercase text-gray-700">
            Estimate cost (exc VAT)
          </span>
          <span>
            {order.supplier.currency
              ? formatCurrencyValue(order.totalCost)
              : roundNumber(order.totalCost)}
          </span>
        </div>
        {actions(order)}
      </div>
      {collapse && (
        <div className="w-full px-4 pb-4 orderDetails">
          <AutoResizer height={Infinity}>
            {({ width }) => (
              <BaseTable
                fixed={false}
                style={{ backgroundColor: "transparent" }}
                estimatedRowHeight={50}
                width={width}
                maxHeight={order.items.length === 0 ? undefined : Infinity}
                height={order.items.length === 0 ? 150 : undefined}
                data={order.items}
                rowKey="productId"
                columns={columns}
                emptyRenderer={
                  <div className={styles.noItems}>No items found</div>
                }
              />
            )}
          </AutoResizer>
        </div>
      )}
    </div>
  )
}

OrderSelectItem.propTypes = {
  order: PropTypes.object,
  onSelect: PropTypes.func,
}

export default OrderSelectItem
