import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface IconToggleProps {
  icons: any
  onChange?: any
  value?: any
  isBoolean?: boolean
  disabled?: boolean
  noBackground?: boolean
}

const IconToggle = (props: IconToggleProps) => {
  const {
    icons,
    value,
    onChange,
    isBoolean = false,
    disabled = false,
    noBackground = false,
  } = props

  const change = (setVal) => {
    if (isBoolean && setVal != value) {
      onChange(!setVal)
    } else {
      onChange(setVal)
    }
  }

  return (
    <div
      className={
        (noBackground ? "" : "bg-gray-300 border border-gray-300 ") +
        "text-white rounded-lg flex items-center justify-center " +
        (disabled ? " opacity-50 pointer-events-none " : "")
      }
    >
      {icons.map((icon, i) => (
        <div
          onClick={() => {
            change(icon.value)
          }}
          key={i}
          className={
            "w-8 h-8 cursor-pointer hover:bg-opacity-80 transition-colors rounded-xs first:rounded-l-md last:rounded-r-md flex items-center justify-center " +
            (icon.value == value
              ? "bg-primaryBlue"
              : "bg-gray-100 shadow-inner text-primaryBlue")
          }
        >
          <FontAwesomeIcon icon={icon.icon} className="text-md" />
        </div>
      ))}
    </div>
  )
}

export default IconToggle
