import tailwindConfig from "../../tailwind.config"

const colors = tailwindConfig.theme.extend.colors

export type PrimaryColor = "blue" | "pink" | "green" | "purple" | "orange"

export const useColors = () => {
  return colors
}
export const useColor = (key) => {
  return colors[key]
}
