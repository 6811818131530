import React, { useState, useContext } from "react"
import { ModalContext } from "context/modal/ModalContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showError, showWarn } from "services/toast"
import {
  faSpinnerThird,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import NewbridgeSettingsModal from "../NewbridgeSettingsModal/NewbridgeSettingsModal"

import { deleteNewbridgeIntegration } from "services/newbridge"

const NewbridgeIntegrationButton = ({
  status = "INACTIVE",
  id = null,
  onConnectionChange,
  canConnect,
  canDisconnect,
}) => {
  const modal = useContext(ModalContext)

  const [error, setError] = useState("")
  const loading = false

  const startNewbridgeAuthorization = async () => {
    modal.showModal(NewbridgeSettingsModal, {
      onConnectionCreated: () => onConnectionChange(),
    })
  }

  const confirmRemove = () => {
    setError("")
    if (id) {
      modal.showModal(ConfirmModal, {
        type: "danger",
        title: `Disconnect Newbridge integration?`,
        text: "Are you sure you want to disconnect this integration?",
        confirmButtonText: "Disconnect",
        onConfirm: async () => {
          const res = await deleteNewbridgeIntegration(id)
          if (res) {
            if (res.status && res.status > 201) {
              showError(res.message)
            } else {
              showWarn("Newbridge disconnected!")

              onConnectionChange()
            }
          }
        },
      })
    }
  }

  return status === "ACTIVE" ? (
    canDisconnect ? (
      <button
        className="text-primaryPink font-semibold font-sansSemiBold hover:bg-gray-50 px-3 bg-white border rounded border-gray-200 text-sm self-start text-left mr-3"
        style={{ height: "40px " }}
        onClick={confirmRemove}
      >
        Disconnect
      </button>
    ) : null
  ) : (
    <div className="inline-flex flex-col items-end mr-3">
      {canConnect && (
        <button
          className={`text-primaryGreen font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left`}
          disabled={loading}
          style={{ height: "40px" }}
          onClick={() => {
            startNewbridgeAuthorization()
          }}
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
              Connecting..
            </>
          ) : (
            <>
              Connect
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </>
          )}
        </button>
      )}
      {error && <span className="text-primaryPink text-xs">{error}</span>}
    </div>
  )
}

export default NewbridgeIntegrationButton
