import { get, post, remove } from "./api"

export const createGoEposIntegration = (params) => {
  return post("/goepos-pos-integrations", params)
}

export const getGoEposIntegrations = () => {
  return get("/goepos-pos-integrations")
}

export const getGoEposIntegration = (
  goEposIntegrationId: string,
  config?: {}
) => {
  return get(`/goepos-pos-integrations/${goEposIntegrationId}`, config)
}

export const deleteGoEposIntegration = (goEposIntegrationId: string) => {
  return remove(`/goepos-pos-integrations/${goEposIntegrationId}`)
}
