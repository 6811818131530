import React, { useState, useEffect, useContext } from "react"
import { exportObject, downloadFormatLabels } from "services/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons"
import { searchSuppliers } from "services/suppliers/suppliers"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import FilterSelect from "components/forms/FilterSelect"
import AsyncSelect from "components/forms/AsyncSelect"
import { onlyUnique } from "services/helpers"
import { useMediaQuery } from "react-responsive"
import * as styles from "../ExportForm/ExportForm.module.css"
import { ModalContext } from "context/modal/ModalContext"
import DateSelection from "components/forms/DateSelection"
import ExportProductSelector from "./ExportProductSelector/ExportProductSelector"
import {
  exportDetailed,
  exportProductsOfInvoicesAsXlsx,
  ExportReportTypeInterface,
  exportSummary,
  getExportFileName,
} from "services/export"
import { exportStockTransfersDetailedAsXlsx } from "services/stock-transfer"
import { Supplier } from "services/suppliers/types"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"

const ExportForm = ({
  type,
}: {
  type: "orders" | "deliveries" | "invoices" | "transfers"
}) => {
  const modal = useContext(ModalContext)

  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)

  const isMainAccount = organizationPermissions?.general?.isMain

  const [downloadFormat, setDownloadFormat] = useState("")
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
    inUserTimezone: {
      from: undefined,
      to: undefined,
      enteredTo: undefined,
    },
  } as any)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const [reportType, setReportType] = useState<ExportReportTypeInterface>({
    value: "",
    label: "",
    subUrl: "",
    status: false,
    supplier: false,
    transfers: false,
    period: false,
    productSelector: false,
    downloadFormats: [],
    organizations: null,
  })
  const [status, setStatus] = useState<any>({})
  const [suppliers, setSuppliers] = useState<Array<Supplier>>([])
  const [transfer, setTransfer] = useState<string | null>(null)
  const [products, setProducts] = useState([])

  const downloadFormatOptions = reportType?.downloadFormats.map((value) => {
    return { value: value, label: downloadFormatLabels[value] }
  })

  useEffect(() => {
    if (products.length) {
      setProducts((previousProducts) => {
        return previousProducts.filter((p: any) =>
          suppliers?.find((sup) => sup.id === p.supplierId)
        )
      })
    }
  }, [suppliers])

  useEffect(() => {
    setStatus({})
    setSuppliers([])
    setProducts([])

    if (downloadFormatOptions?.length === 1) {
      setDownloadFormat(downloadFormatOptions[0].value)
    } else {
      setDownloadFormat("")
    }
  }, [reportType])

  useEffect(() => {
    window.addEventListener("beforeunload", confirmLeave)
    return () => {
      window.removeEventListener("beforeunload", confirmLeave)
    }
  }, [])

  const getExportParams = () => {
    return {
      from: selectedPeriod.inUserTimezone.from,
      to: selectedPeriod.inUserTimezone.enteredTo,

      status: status && status.value,
      suppliersIds:
        suppliers.length > 0 && suppliers.map((supplier) => supplier.id),
      ...(products?.length
        ? {
            groupsIds: products.map((p: any) => p.groupId).filter(onlyUnique),
          }
        : {}),
      organizations: selectedOrganizations,
    }
  }

  const handleActionClick = () => {
    const fileReportType = reportType?.value.toLowerCase()
    const fileName = getExportFileName(type, fileReportType, downloadFormat)
    const exportParams = getExportParams()
    // Remove empty params
    Object.keys(exportParams).forEach((key) => {
      if (!exportParams[key] || exportParams[key].length === 0) {
        delete exportParams[key]
      }
    })

    if (type === "transfers") {
      exportParams["type"] = transfer ?? "ALL"
      exportParams["fileName"] = fileName
    }

    const urlType = exportObject[type]?.urlType ?? type
    const url = `/${urlType}${reportType.subUrl}/export-as-${downloadFormat}`

    switch (reportType?.value) {
      case "SUMMARY":
        if (type === "invoices" && products?.length) {
          exportProductsOfInvoicesAsXlsx(exportParams, fileName)
        } else {
          exportSummary(url, exportParams, fileName)
        }
        break
      case "DETAILED":
        if (type === "transfers") {
          exportStockTransfersDetailedAsXlsx(exportParams)
        } else {
          exportDetailed(url, exportParams, fileName)
        }
        break
      case "SAGE":
        exportSummary(url, exportParams, fileName)
        break
    }
  }

  const confirmLeave = (e) => {
    e.preventDefault()
    e.returnValue = "Are you sure you want to leave?"
  }

  return (
    <>
      <Helmet>
        <title>Export {type}</title>
      </Helmet>
      <div className={styles.container}>
        <Header title={`Export ${type}`} back={true} />
        <div className={styles.content}>
          <div className="flex flex-col w-auto lg:w-1/2">
            <>
              <label className={styles.label}>Select report type</label>
              <FilterSelect
                options={exportObject[type].reportTypes}
                value={reportType}
                onChange={(val) => {
                  setReportType(val)
                }}
                placeholder={isTabletOrMobile ? "Type" : "Report type"}
                isClearable={true}
                className={`${styles.selectFields}`}
              />
            </>
            {reportType.value == "SUMMARY" && isMainAccount && (
              <>
                <label className={styles.label}>Select organization(s)</label>{" "}
                <LocationsSelector className={`${styles.selectFields}`} />
              </>
            )}
            {reportType?.status && (
              <>
                <label className={styles.label}>
                  Select {exportObject[type].singular} status
                </label>
                <FilterSelect
                  options={exportObject[type].statusOptions}
                  value={status}
                  onChange={(val) => {
                    setStatus(val)
                  }}
                  placeholder={isTabletOrMobile ? "Status" : "Status: all"}
                  isClearable={true}
                  className={`${styles.selectFields}`}
                />
              </>
            )}
            {reportType?.supplier && (
              <div>
                <label className={styles.label}>Select supplier</label>
                <AsyncSelect
                  name=""
                  label=""
                  promise={searchSuppliers}
                  placeholder={isTabletOrMobile ? "Supplier" : "All suppliers"}
                  isClearable={true}
                  optionLabel="name"
                  onChange={(val) => {
                    setSuppliers(val.map((val) => val.value))
                  }}
                  className={`${styles.selectFields} mt-3`}
                  isMulti
                />
              </div>
            )}
          </div>

          {reportType?.transfers && (
            <div className="flex flex-col w-auto lg:w-1/2">
              <label className={styles.label}>Select transfers</label>
              <FilterSelect
                options={exportObject[type].transferOptions}
                value={transfer}
                onChange={(val) => {
                  setTransfer(val ? val.value : null)
                }}
                placeholder={isTabletOrMobile ? "Selection" : "Selection: all"}
                isClearable={true}
                className={`${styles.selectFields}`}
              />
            </div>
          )}
          <div className="flex flex-col w-full lg:w-1/2 items-start pb-16 xl:pb-0">
            {reportType?.period && (
              <>
                <label className={styles.label}>Select period</label>
                <DateSelection
                  value={selectedPeriod}
                  onChange={setSelectedPeriod}
                />
                {(!selectedPeriod.from || !selectedPeriod.to) && (
                  <span className="text-red-600 w-full block">
                    Please select a period
                  </span>
                )}
              </>
            )}
            {reportType?.productSelector && reportType.value !== "SUMMARY" && (
              <ExportProductSelector
                products={products}
                setProducts={setProducts}
                downloadFormat={downloadFormat}
                modal={modal}
                suppliers={suppliers}
                selectAllOption={type === "orders"}
              />
            )}
            {reportType?.downloadFormats.length > 1 && (
              <div className="flex flex-col input-container mt-4">
                <label className={`${styles.label}`}>
                  Select download format
                </label>
                <FilterSelect
                  onChange={(e) => setDownloadFormat(e.value)}
                  className="cursor-pointer form-control rounded input-container"
                  options={
                    products.length
                      ? downloadFormatOptions?.filter(
                          (option) => option.value !== "csv"
                        )
                      : downloadFormatOptions
                  }
                />
              </div>
            )}
            <button
              type="button"
              onClick={handleActionClick}
              className={`button button--autoWidth button--primaryGreen mt-6`}
              disabled={
                !downloadFormat ||
                !selectedPeriod.from ||
                !selectedPeriod.enteredTo ||
                (downloadFormat.includes("csv") && Boolean(products.length))
              }
            >
              <FontAwesomeIcon icon={faFileDownload} className="mr-2" />
              {reportType?.downloadFormats.length === 1
                ? `Export to .${downloadFormat}`
                : "Export"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExportForm
