import { navigate } from "gatsby"
import { isBrowser } from "./helpers"
import { getMyOrganization } from "./organization"
import { post, get, patch, setToken, removeToken, getToken } from "./api"
import EventBus from "./events"
import { showError } from "./toast"
import jwtDecode from "jwt-decode"

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT

export const getUser = () => {
  return new Promise((resolve) => {
    if (!isBrowser()) {
      resolve(null)
    }
    const user = window.localStorage.getItem(`growyzeUser_${ENVIRONMENT}`)
    if (user) {
      resolve(JSON.parse(user))
    } else {
      resolve(null)
    }
  })
}

export const getOrganization = () => {
  return new Promise((resolve) => {
    if (!isBrowser()) {
      resolve(null)
    }
    const organization = window.localStorage.getItem(
      `growyzeOrganization_${ENVIRONMENT}`
    )
    if (organization) {
      resolve(JSON.parse(organization))
    } else {
      resolve(null)
    }
  })
}

export const setUser = (user) => {
  return new Promise((resolve) => {
    window.localStorage.setItem(
      `growyzeUser_${ENVIRONMENT}`,
      JSON.stringify(user)
    )
    resolve(true)
  })
}

export const setOrganization = (organization) => {
  return new Promise((resolve) => {
    window.localStorage.setItem(
      `growyzeOrganization_${ENVIRONMENT}`,
      JSON.stringify(organization)
    )
    resolve(true)
  })
}

const removeUser = () =>
  window.localStorage.removeItem(`growyzeUser_${ENVIRONMENT}`)

const removeOrganization = () =>
  window.localStorage.removeItem(`growyzeOrganization_${ENVIRONMENT}`)

export const getDecodedtoken = async () => {
  const token = (await getToken()) as string
  if (!token) {
    return null
  }
  const tokenArray = token.split(" ")
  return jwtDecode(tokenArray[1])
}

export const getUserTimeZone = async () => {
  const tokenInfo = (await getDecodedtoken()) as any
  return tokenInfo.zoneId ? tokenInfo.zoneId : "Europe/London"
}

export const getUserPermissions = async () => {
  const tokenInfo = (await getDecodedtoken()) as any
  return tokenInfo ? tokenInfo.permissionsPerFeatures : null
}

const updateUserAfterLogin = async (loginResult) => {
  let userResult = {} as any

  if (loginResult.error || loginResult.status === 404) {
    if (loginResult.message) {
      showError(loginResult.message)
    }
    return false
  }

  // Set token
  if (loginResult.token) {
    await setToken(loginResult.token)
  }

  // Get current user timezone (comes from JWT token)
  const zoneId = await getUserTimeZone()

  // Get current user
  const user = await getCurrentUser(true)
  const permissions = await getUserPermissions()

  if (!user.error) {
    userResult = { ...user, zoneId: zoneId, permissions: permissions }
    await setUser(userResult)
  }

  // Set organization if is set
  if (
    userResult.organizations &&
    userResult.organizations[0] !== "growyze-default"
  ) {
    const organizationResult = await getMyOrganization(true)

    if (!organizationResult.error) {
      await setOrganization(organizationResult)
    }
  }

  return userResult
}

export const authenticateUser = (params) => {
  return post("/authenticate-user", params, { authenticate: false })
}

// Deprecated login function
export const login = async ({ username, password = "" }) => {
  const loginResult = await post(
    "/login",
    {
      username,
      password,
    },
    { authenticate: false }
  )

  const userResult = await updateUserAfterLogin(loginResult)

  return userResult
}

export const loginV2 = async ({ activeOrgId, sessionId = "" }) => {
  const loginResult = await post(
    "/login",
    {
      activeOrgId,
      sessionId,
    },
    { authenticate: false },
    "/v2"
  )

  const userResult = await updateUserAfterLogin(loginResult)

  return userResult
}

export const refreshLogin = async (orgId = null) => {
  const loginRefreshResult = await post(
    `/login/refresh${orgId ? "/?orgId=" + orgId : ""}`
  )

  const userResult = await updateUserAfterLogin(loginRefreshResult)

  return userResult
}

export const signup = (params) => {
  return post("/register", params, { authenticate: false })
}

export const resetPassword = ({ username }) => {
  return post(
    `/users/reset-password?username=${username}`,
    {},
    {
      authenticate: false,
    }
  )
}

export const getCurrentUser = async (force = false) => {
  if (!force && !isLoggedIn()) {
    return Promise.resolve(null)
  }
  const user = await getUser()

  if (user && !force) {
    return Promise.resolve(user)
  } else {
    return get(`/users/me`)
  }
}

export const updateCurrentUser = async (params) => {
  const updateResult = await patch("/users/me", params)

  if (updateResult.error) {
    return updateResult
  }

  const userResult = await updateUserAfterLogin(updateResult)

  return userResult
}

export const resetPasswordConfirm = (params) => {
  return post("/users/reset-password-confirm", params, { authenticate: false })
}

export const isLoggedIn = () => {
  const user = window.localStorage.getItem(`growyzeUser_${ENVIRONMENT}`)
  return user && Object.keys(user).length > 0
}

export const logout = async (callback) => {
  removeUser()
  removeOrganization()
  await removeToken()

  EventBus.dispatch("logout")

  if (typeof callback == "function") {
    callback()
  } else {
    navigate("/")
  }
}

export const authenticateAdmin = (params) => {
  return post("/authenticate-admin", params)
}

export const loginAdmin = async ({ activeOrgId, sessionId = "" }) => {
  const loginResult = await post(
    "/login/admin",
    {
      activeOrgId,
      sessionId,
    },
    { authenticate: false }
  )

  const userResult = await updateUserAfterLogin(loginResult)

  return userResult
}

export const userIsAdmin = async () => {
  const user = await getCurrentUser()
  return user.roles.includes("admin")
}
