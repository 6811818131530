import React, { useContext } from "react"
import { format } from "date-fns"
import intervalToDuration from "date-fns/intervalToDuration"

import ActionIcon from "components/dashboard/Widgets/ListWidget/ActionIcon/ActionIcon"
import { ModalContext } from "context/modal/ModalContext"
import Modal from "react-modal"
import PropTypes from "prop-types"
//@ts-ignore
import * as styles from "./ActionItem.module.css"

Modal.setAppElement("body")

const ActionItemModal = ({ onRequestClose, text, ...otherProps }) => {
  return (
    <Modal
      isOpen
      portalClassName="actionItem"
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <div className="p-4">{text}</div>
    </Modal>
  )
}

const ActionItem = ({ action }) => {
  const modal = useContext(ModalContext)

  if (!action.date || action.date.toString() === "Invalid Date") {
    throw `Invalid date ${JSON.stringify(action)}`
  }
  const timeAgo: Duration = intervalToDuration({
    start: action.date,
    end: new Date(),
  })

  const time =
    timeAgo.days && timeAgo.days > 0
      ? format(action.date, "dd/MM/yyyy")
      : timeAgo.hours && timeAgo.hours > 0
      ? timeAgo.hours + "h ago"
      : timeAgo.minutes && timeAgo.minutes > 0
      ? timeAgo.minutes + "m ago"
      : "now"

  return (
    <tr
      className={styles.container}
      onClick={() =>
        //@ts-ignore
        modal.showModal(ActionItemModal, {
          text: action.text,
        })
      }
    >
      <td className={styles.columnZero}>
        {action.status && (
          <div
            className={styles.status}
            style={{ backgroundColor: action.status }}
          ></div>
        )}
        <ActionIcon type={action.type}></ActionIcon>
      </td>
      <td className={styles.columnOne}>
        <p className={styles.text}>{action.text}</p>
        <p className={styles.name}>{action.name}</p>
      </td>

      <td className={styles.columnTwo}>
        <p className={styles.time}>{time}</p>
        {/* <p>{action.organization}</p> */}
      </td>
    </tr>
  )
}

ActionItem.propTypes = {
  action: PropTypes.object,
}

ActionItemModal.propTypes = {
  onRequestClose: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ActionItem
