import React from "react"

const ColoredHighlight = ({ value }) => {
  const isPositive = value > 0
  const isEmpty = value === null || value === undefined

  if (isEmpty) {
    return <span className="text-gray-500">-</span>
  }

  if (value === 0) {
    return <span className="text-gray-500">0</span>
  }

  return (
    <div className={`${isPositive ? "text-green-600" : "text-red-600"}`}>
      {value}
    </div>
  )
}

export default ColoredHighlight
