import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
import {
  faPencil,
  faTrashAlt,
  faCommentAlt,
  faCommentAltPlus,
} from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import { useMediaQuery } from "react-responsive"

import * as styles from "./StockTransferContentItem.module.css"
import usePermissions from "hooks/usePermissions"
import { roundNumber } from "../../../../../services/helpers"
import { Permission } from "services/types"
import { faCompactDisc, faListOl } from "@fortawesome/pro-duotone-svg-icons"

interface StockTransferContentItemProps {
  transfer: any
  item: any
  itemType: "product" | "recipe" | "dish"
  onDelete?: Function
  onComment?: Function
  onEditQty?: Function
  allowDelete?: boolean
}
const StockTransferContentItem = ({
  transfer,
  item,
  itemType,
  onDelete,
  onComment,
  onEditQty,
  allowDelete = true,
}: StockTransferContentItemProps) => {
  const hasCase = !!item.sentQtyInCase
  const hasComments =
    !!item.senderComment ||
    !!item.receiverComment ||
    !!transfer.products.find((p) => p.source?.refId === item.id)
      ?.receiverComment ||
    !!transfer.products.find((p) => p.source?.refId === item.id)?.senderComment

  const permissionObj = usePermissions("Transfers") as Permission
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const getItemValue = () => {
    switch (itemType) {
      case "product":
        return (hasCase ? item.sentCasePrice : item.sentPrice) * item.sentQty
      case "recipe":
        return item.portion?.cost * item.sentQty
      case "dish":
        return item.dishCost * item.sentQty
    }
  }

  const getIcon = () => {
    switch (itemType) {
      case "product":
        return hasCase ? faCube : faWineBottle
      case "recipe":
        return faListOl
      case "dish":
        return faCompactDisc
    }
  }

  const itemValue = getItemValue()
  const icon = getIcon()

  const handleButtonClick = (data) => {
    switch (data) {
      case "comment":
        if (onComment) onComment({ ...item, name: item.name ?? item.sentName })
        break
      case "edit":
        if (onEditQty) onEditQty(item, "sentQty")
        break
      case "delete":
        if (onDelete) onDelete(item)
        break
      default:
        return
    }
  }

  const actions = () => {
    const options = [
      {
        key: "comment",
        title: "Comment",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "edit",
        title: "Edit",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete || !allowDelete,
      },
    ]

    return (
      <div className="pl-3 flex-shrink-0" style={{ textAlign: "right" }}>
        <ActionsButton
          options={options}
          onActionClick={(action) => handleButtonClick(action.type)}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={classNames("mr-4", styles.details, styles.info)}>
        <span className={styles.name}>{item.name ?? item.sentName}</span>
        <span className={styles.detail}>
          {item.sentSize} {item.sentMeasure}{" "}
          {item.sentUnit ? `(${item.sentUnit})` : ""}
        </span>
      </div>
      <div className="flex flex-col flex-grow text-left">
        <span className={styles.detail}>QTY</span>
        <span>
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {item.sentQty}
        </span>
      </div>

      <div className="flex flex-col flex-grow text-left">
        <div className={styles.detail}>VALUE</div>
        <div>
          {itemValue ? (
            roundNumber(itemValue)
          ) : (
            <span className="text-sm text-gray-500 inline-block mr-1 text-center">
              -
            </span>
          )}
        </div>
      </div>

      {!isMobile ? (
        <>
          <button
            className={styles.actionButton}
            disabled={!permissionObj?.permissions.modify}
            onClick={() => handleButtonClick("comment")}
          >
            {hasComments && <div className={styles.thumbnailIndicator}></div>}
            <FontAwesomeIcon
              icon={hasComments ? faCommentAlt : faCommentAltPlus}
            />
          </button>
          <button
            className={styles.actionButton}
            disabled={!permissionObj?.permissions.modify}
            onClick={() => handleButtonClick("edit")}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button
            className={styles.actionButton}
            disabled={!permissionObj?.permissions.delete || !allowDelete}
            onClick={() => handleButtonClick("delete")}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-primaryPink" />
          </button>
        </>
      ) : (
        actions()
      )}
    </div>
  )
}

export default StockTransferContentItem
