import {
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface TabTitleProps {
  title: string
  tabKey: string
  isActive: boolean
  handleTabChange: (tabKey: string) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void
  showInfo?: string
  showWarning?: boolean
}

const TabTitle = React.forwardRef<HTMLButtonElement, TabTitleProps>(
  (
    {
      title,
      tabKey,
      isActive,
      handleTabChange,
      onKeyDown,
      showInfo,
      showWarning,
    },
    ref
  ) => {
    const onClick = () => {
      handleTabChange(tabKey)
    }

    return (
      <li>
        <button
          ref={ref}
          type="button"
          role="tab"
          aria-controls={`${tabKey}-panel`}
          aria-selected={isActive}
          id={`${tabKey}-tab`}
          className={`${isActive ? "active flex items-baseline" : ""}`}
          onKeyDown={onKeyDown}
          onClick={onClick}
          tabIndex={isActive ? 0 : -1}
        >
          {title}
          {showWarning && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-red-600 ml-1"
            />
          )}
          {showInfo && (
            <span
              className="ml-1"
              data-for={"tabs_tooltip"}
              data-tip={showInfo}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          )}
        </button>
      </li>
    )
  }
)

export default TabTitle
