import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"

import { formatAsYearMonthDay } from "services/helpers"

const TimeLabel = ({ date }) => {
  return (
    <>
      {date && (
        <span className="text-xs text-gray-700 font-light">
          <FontAwesomeIcon icon={faCalendar} size="sm" />{" "}
          {formatAsYearMonthDay(new Date(date))}
        </span>
      )}
    </>
  )
}

TimeLabel.propTypes = {
  date: PropTypes.string,
  onlyDate: PropTypes.bool,
}

export default TimeLabel
