import React from "react"
import Loader from "../Loader/Loader"

export default ({ loading, text, children }) => {
  if (loading) {
    return (
      <Loader
        isLoading={true}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading...
      </Loader>
    )
  } else if (text) {
    return <div className="growyze-centered-text">{text}</div>
  } else {
    return children
  }
}
