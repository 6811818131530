import React from "react"
import { getInitials } from "services/helpers"

import * as styles from "./UserAvatar.module.css"

interface UserAvatarProps {
  user: {
    firstName: string
    lastName: string
  }
}

const UserAvatar = ({ user }: UserAvatarProps) => {
  return <div className={styles.avatar}>{getInitials({ user })}</div>
}

export default UserAvatar
