import React, { useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import classNames from "classnames/bind"
import * as styles from "./PlaceOrderModal.module.css"

Modal.setAppElement("body")

interface PlaceOrderProps {
  onRequestClose: Function
  showCancel: Boolean
  showReject: Boolean
  type: string
  title: string
  text: string
  confirmButtonText: string
  cancelButtonText: string
  rejectButtonText: string
  askToNotifySupplier: boolean
  onConfirm: Function
  onReject: Function
}

const PlaceOrderModal: React.FunctionComponent<PlaceOrderProps> = ({
  onRequestClose,
  showCancel = true,
  showReject = false,
  askToNotifySupplier = true,
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  rejectButtonText,
  onConfirm,
  onReject,
}) => {
  const [apiCallAdditionalParameters, setApiCallAdditionalParameters] =
    useState({
      shouldNotifySupplier: askToNotifySupplier,
    })

  const confirm = () => {
    if (onConfirm) {
      onConfirm({
        shouldNotifySupplier: !apiCallAdditionalParameters.shouldNotifySupplier,
      })
    }
    onRequestClose()
  }

  const reject = useCallback(() => {
    onRequestClose()
    if (onReject) {
      onReject()
    }
  }, [onRequestClose, onReject])

  return (
    <Modal isOpen onRequestClose={onRequestClose} portalClassName="confirm">
      <button
        type="button"
        onClick={onRequestClose as React.MouseEventHandler}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.welcomeContent}>
          <h1 className="text-2xl pb-2 mt-3">{title}</h1>
          {text && <p className="text-lg text-gray-700 pt-2">{text}</p>}
        </div>

        {askToNotifySupplier && (
          <div className="flex justify-center my-4">
            <label className="space-x-2 flex items-center">
              <input
                type="checkbox"
                className="text-primaryPink"
                checked={apiCallAdditionalParameters.shouldNotifySupplier}
                onChange={() =>
                  setApiCallAdditionalParameters((prevState) => {
                    return {
                      shouldNotifySupplier: !prevState.shouldNotifySupplier,
                    }
                  })
                }
              />
              <span>Email supplier</span>
            </label>
          </div>
        )}

        <div className={styles.buttons}>
          {showCancel && (
            <button
              type="button"
              onClick={onRequestClose as React.MouseEventHandler}
              className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
            >
              {cancelButtonText || "Cancel"}
            </button>
          )}
          {showReject && (
            <button
              type="button"
              onClick={reject}
              className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
            >
              {rejectButtonText || "Reject"}
            </button>
          )}
          <button
            type="button"
            onClick={confirm}
            className={classNames(
              "button button--autoWidth text-primaryPink",
              {}
            )}
          >
            {confirmButtonText || "Confirm"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default PlaceOrderModal
