/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { navigate, Link } from "@reach/router"

import {
  faPlus,
  faTrashAlt,
  faPencil,
  faFileDownload,
} from "@fortawesome/pro-regular-svg-icons"
import { combineSales, removeSale } from "services/sales"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showError, showSuccess } from "services/toast"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { getSales } from "services/sales"
import PosImportButton from "components/integrations/PosImportButton/PosImportButton"
import usePermissions from "hooks/usePermissions"
import * as styles from "./Sales.module.css"
import { ExtendedFlexiblePeriod, Permission } from "services/types"
import { convertTimeStampFormat } from "services/helpers"
import { salesOrigins } from "services/constants"
import { getSalesDummy } from "services/dummy"
import { faCoins } from "@fortawesome/pro-duotone-svg-icons"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import usePagination from "hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { getOrgNameById } from "services/user"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import SubSalesItem from "../SubSalesItem/SubSalesItem"
import DateSelection from "components/forms/DateSelection"
import { useModals } from "hooks/useModals"

interface SalesProps {
  live: boolean
  showCreatedDate?: boolean
}
const Sales: React.FunctionComponent<SalesProps> = ({
  live,
  showCreatedDate,
}: SalesProps) => {
  const [salesData, setSalesData] = useState({
    content: [],
  })
  const initialSelectedPeriod: ExtendedFlexiblePeriod = {
    from: undefined,
    to: undefined,
    enteredTo: undefined,
    inUserTimezone: {
      from: undefined,
      to: undefined,
      enteredTo: undefined,
    },
  }
  const pagination = usePagination()
  const previousPage = usePrevious<number>(pagination.page)
  const fromPromise = usePromise()
  const [loading, setLoading] = useState<boolean>(false)
  const permissionObj = usePermissions("Sales") as Permission
  const [selectedSales, setSelectedSales] = useState<string[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<ExtendedFlexiblePeriod>(
    initialSelectedPeriod
  )

  const {
    organization,
    organizations,
    selectedOrganizations,
    organizationPermissions,
  } = useContext(GlobalStateContext)

  const handleCombineSales = async () => {
    const params = {
      from: selectedPeriod.inUserTimezone.from,
      to: selectedPeriod.inUserTimezone.enteredTo,
      subSales: selectedSales.map((id) => ({ id: id })),
    }

    const response = await combineSales(params)

    if (response.message) {
      showError(response.message)
    }

    setSelectedPeriod(initialSelectedPeriod)
    getData()
    setSelectedSales([])
  }

  const { showModal, RenderModals } = useModals([
    {
      id: "combineSales",
      comp: ConfirmModal,
      props: {
        title: `Combine Sales`,
        confirmButtonText: "Combine",
        children: (
          <DateSelection value={selectedPeriod} onChange={setSelectedPeriod} />
        ),
        onConfirm: handleCombineSales,
        disableConfirm: !selectedPeriod.from || !selectedPeriod.to,
      },
    },
    {
      id: "confirmDeleteSalesFile",
      comp: ConfirmModal,
      props: {
        type: "danger",
        title: `Delete Sales File`,
        text: "Are you sure you want to delete this sales file?",
        confirmButtonText: "Delete",
      },
    },
  ])

  const getOrigin = (origin) => {
    if (salesOrigins[origin]) {
      return (
        <span className="text-xs text-gray-800 flex items-center">
          <img src={salesOrigins[origin].logo} className="w-3 mr-1" />{" "}
          <span>from {salesOrigins[origin].title}</span>
        </span>
      )
    }

    return (
      <span className="text-xs text-gray-800 flex items-center">
        <FontAwesomeIcon icon={faFileDownload} className="mr-1 text-xs w-3" />
        <span>
          {live ? "Live" : "Manual"} {origin === "COMBINED" && "(combined)"}
        </span>
      </span>
    )
  }

  const columns = [
    {
      key: "from",
      title: "From-To",
      dataKey: "from",
      width: 230,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        if (
          rowData.organizations &&
          rowData.organizations[0] === organization.id
        ) {
          return (
            <Link
              to={rowData.id}
              className="font-sansSemiBold font-semibold text-primaryBlue flex flex-col py-4"
            >
              {formatDate(new Date(rowData.from))} -{" "}
              {formatDate(new Date(rowData.to))}
              {getOrigin(rowData.salesOrigin)}
            </Link>
          )
        }
        return (
          <div className="font-sansSemiBold font-semiboldflex flex-col py-4">
            {formatDate(new Date(rowData.from))} -{" "}
            {formatDate(new Date(rowData.to))}
            {getOrigin(rowData.salesOrigin)}
          </div>
        )
      },
    },
    {
      key: "totalSales",
      width: 100,
      title: "Total Sales",
      dataKey: "totalSales",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col">{rowData.totalSales}</div>
      ),
    },
    {
      key: "nonMatchingPosIdsCount",
      width: 100,
      title: "Non matching POS IDs",
      dataKey: "nonMatchingPosIdsCount",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col">
          {rowData.organizations &&
          rowData.organizations[0] === organization.id ? (
            <button
              onClick={() => navigate(`/dashboard/sales/${rowData.id}`)}
              disabled={!permissionObj?.permissions.read}
              className="font-sansSemiBold font-semibold text-primaryBlue"
            >
              {rowData.nonMatchingPosIdsCount +
                rowData.autoGeneratedPosIdsCount || 0}
            </button>
          ) : (
            <span className="font-sansSemiBold font-semibold">
              {rowData.nonMatchingPosIdsCount +
                rowData.autoGeneratedPosIdsCount || 0}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 150,
      className: "justify-end",
      cellRenderer: ({ rowData }) => (
        <>
          {rowData.organizations &&
          rowData.organizations.length > 0 &&
          rowData.organizations[0] === organization.id ? (
            <>
              <button
                type="button"
                className="button whitespace-nowrap button--smaller button--autoWidth button--paleBlue mr-2"
                disabled={!permissionObj?.permissions.read}
                onClick={() => {
                  navigate(`/dashboard/sales/${rowData.id}`)
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
              <button
                type="button"
                className="button button--smaller button--autoWidth button--primaryPink no-truncate"
                disabled={!permissionObj?.permissions.delete}
                onClick={(e) => {
                  e.preventDefault()
                  onDelete(rowData)
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ]

  // The checkboxes and location column are only shown for main orgs
  if (organizationPermissions?.general?.isMain) {
    columns.splice(0, 0, {
      key: "checkbox",
      width: 45,
      title: "",
      dataKey: "checkbox",
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const checked = selectedSales.includes(rowData.id)
        return (
          <input
            type="checkbox"
            className="text-primaryBlue cursor-pointer"
            checked={checked}
            onChange={() => handleSelect(checked, rowData.id)}
          />
        )
      },
    })

    columns.splice(2, 0, {
      key: "location",
      width: 150,
      title: "Location/Area",
      dataKey: "location",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        let orgId = rowData.organizations && rowData.organizations[0]
        const numLocations = getNumLocations(rowData.subSales)

        if (rowData.subSales) {
          const orgs = rowData.subSales
            .map((sale: any) => sale.organizations)
            .flat()
          if (!orgs.includes(organization.id)) {
            orgId = orgs[0]
          } else {
            orgId = organization.id
          }
        }

        return (
          <span>
            {getOrgNameById(orgId, organizations)}
            {numLocations && numLocations > 1 && ", +" + (numLocations - 1)}
          </span>
        )
      },
    })
  }

  if (showCreatedDate) {
    columns.splice(1, 0, {
      key: "createdAt",
      width: 100,
      title: "Created at",
      dataKey: "createdAt",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="block">
            {convertTimeStampFormat(
              new Date(rowData.createdAt),
              "Etc/UTC",
              true,
              true
            )}
          </div>
        )
      },
    })
  }

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th" // Handles 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  function formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    const day = date.getDate()
    const month = monthNames[date.getMonth()]
    const year = date.getFullYear()

    const ordinalSuffix = getOrdinalSuffix(day)

    return `${month} ${day}${ordinalSuffix} ${year}`
  }

  const rowRenderer = ({ rowData, cells }) => {
    if (rowData.content) {
      return (
        <SubSalesItem
          subSales={rowData.content.subSales}
          formatDate={formatDate}
        />
      )
    }

    return cells
  }

  const formattedData = (data) => {
    const latestData = [...data]

    latestData.forEach((item) => {
      if (item.salesOrigin === "COMBINED") {
        item.children = [
          {
            id: `${item.id}-detail`,
            content: {
              subSales: item.subSales,
            },
          },
        ]
      }
    })

    return latestData
  }

  const actionOptions = [
    {
      key: "combine",
      title: "Combine sales",
      disabled: !permissionObj?.permissions.modify,
    },
  ]

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setSalesData({ content: [] })
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: live ? "createdAt,desc" : "from,desc&sort=to,desc",
      live: live,
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getSales(params))

    if (result && !result.error) {
      setSalesData({ ...salesData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getData()
  }, [selectedOrganizations])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  const deleteSale = async (sale) => {
    const deleted = await removeSale(sale.id)
    if (deleted) {
      showSuccess("Sale deleted!")
      getData()
    }
  }

  const handleSelect = (checked, saleId) => {
    if (checked) {
      setSelectedSales(selectedSales.filter((id) => id !== saleId))
    } else {
      setSelectedSales([...selectedSales, saleId])
    }
  }

  const startCombineSales = () => {
    showModal("combineSales")
  }

  const handleGroupActionClick = (action: { type: string }) => {
    switch (action.type) {
      case "combine":
        if (selectedSales.length < 2) {
          showError("Please select at least 2 sales to combine.")
          return
        }
        return startCombineSales()
    }
  }

  const getNumLocations = (subSales) => {
    if (!subSales) return

    const orgs = subSales.map((sale: any) => sale.organizations).flat()
    return new Set(orgs).size
  }

  const onDelete = (sale) =>
    showModal("confirmDeleteSalesFile", { onConfirm: () => deleteSale(sale) })

  const dataTitle = live ? "Live sales" : "Imported sales"

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <h2 className="text-lg mr-3 py-3 md:mr-auto">{dataTitle}</h2>
          {!live && (
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2 my-2">
              <LocationsSelector />
              <PosImportButton />
              <button
                className="button flex-shrink-0 button--autoWidth button--primaryGreen"
                disabled={!permissionObj?.permissions.modify}
                onClick={(e) => {
                  e.preventDefault()
                  navigate("sales/import")
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Import File
              </button>
            </div>
          )}
        </div>

        {organizationPermissions?.general?.isMain &&
          selectedSales.length > 0 && (
            <div className="flex  items-center py-2 px-8 border-b gap-x-4">
              <span className="font-semibold">
                <span className="text-primaryBlue">
                  {selectedSales.length} product
                  {selectedSales.length === 1 ? " " : "s "}
                </span>
                <span className="text-gray-600">selected</span>
              </span>
              <DropdownButton
                dropDirection="top-left"
                label="Apply action"
                buttonClass={
                  "button button--primaryGreen button--smallest text-sm"
                }
                options={actionOptions}
                onActionClick={(action) => handleGroupActionClick(action)}
              />
            </div>
          )}

        <div className={styles.content}>
          <ExtBaseTable
            key="table"
            title={dataTitle}
            id="sales"
            loading={loading}
            pagination={pagination}
            data={formattedData(salesData.content)}
            columns={columns}
            estimatedRowHeight={200}
            empty={{
              icon: faCoins,
              color: "blue",
              getDummy: () => getSalesDummy,
            }}
            expandColumnKey={"from"}
            expand={{}}
            rowRenderer={rowRenderer}
          />
        </div>
      </div>
      <RenderModals />
    </>
  )
}
export default Sales
