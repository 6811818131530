import React, { ReactElement, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames"

interface FormDropDownProps {
  children: any
  buttonText?: string | JSX.Element
  buttonTextSuffix?: string | JSX.Element
  labelStyle?: string
  customPadding?: string
  defaultExpanded?: boolean
  fullWidth?: boolean
  actionButton?: ReactElement
  noBorder?: boolean
}

const FormDropdown = ({
  buttonText,
  buttonTextSuffix,
  children,
  labelStyle,
  customPadding,
  defaultExpanded,
  fullWidth,
  actionButton,
  noBorder = false,
}: FormDropDownProps) => {
  const [expanded, setExpanded] = useState<boolean>(!!defaultExpanded)

  return (
    <>
      <div
        className={`text-md text-gray-700 ${
          noBorder ? "" : "border-t"
        } w-full flex items-center ${customPadding || "py-3"}`}
      >
        <button
          className={classNames("flex items-center", {
            "justify-between": fullWidth,
          })}
          onClick={(e) => {
            e.preventDefault()
            setExpanded(!expanded)
          }}
          type="button"
        >
          {buttonText && (
            <h5 className={labelStyle || "text-gray-700"}>{buttonText}</h5>
          )}
          <FontAwesomeIcon
            icon={expanded ? faChevronUp : faChevronDown}
            className="ml-2"
          />
          {buttonTextSuffix}
        </button>
        {actionButton}
      </div>
      {expanded && children}
    </>
  )
}

export default FormDropdown
