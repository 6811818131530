import { CaseDto } from "services/products/types"
import {
  Address,
  DayOfWeekEnum,
  MeasureEnum,
  Pageable,
  Sort,
  UnitEnum,
} from "services/types"

/**
 *
 * @export
 * @interface Repetition
 */
export interface Repetition {
  /**
   *
   * @type {number}
   * @memberof Repetition
   */
  interval?: number
}

/**
 *
 * @export
 * @interface WeeklyRepetition
 */
export interface WeeklyRepetition {
  /**
   *
   * @type {string}
   * @memberof WeeklyRepetition
   */
  dayOfWeek?: DayOfWeekEnum
  /**
   *
   * @type {number}
   * @memberof WeeklyRepetition
   */
  interval?: number
}

/**
 *
 * @export
 * @interface OrderRecurrenceReq
 */
export interface OrderRecurrenceReq {
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceReq
   */
  createdDate?: Date
  /**
   *
   * @type {Repetition}
   * @memberof OrderRecurrenceReq
   */
  dailyRepetition?: Repetition
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceReq
   */
  endDate?: Date
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceReq
   */
  id?: string
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceReq
   */
  nextOrderDate?: Date
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceReq
   */
  orderTemplateId?: string
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceReq
   */
  orderTime?: string
  /**
   *
   * @type {Array<string>}
   * @memberof OrderRecurrenceReq
   */
  organizations?: Array<string>
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceReq
   */
  repetitionType?: RepetitionTypeEnum
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceReq
   */
  startDate?: Date
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceReq
   */
  updatedDate?: Date
  /**
   *
   * @type {WeeklyRepetition}
   * @memberof OrderRecurrenceReq
   */
  weeklyRepetition?: WeeklyRepetition
}

export enum RepetitionTypeEnum {
  DAILY = <any>"DAILY",
  WEEKLY = <any>"WEEKLY",
}

/**
 *
 * @export
 * @interface OrderRecurrenceRes
 */
export interface OrderRecurrenceRes {
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceRes
   */
  createdDate?: Date
  /**
   *
   * @type {Repetition}
   * @memberof OrderRecurrenceRes
   */
  dailyRepetition?: Repetition
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceRes
   */
  endDate?: Date
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceRes
   */
  id?: string
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceRes
   */
  nextOrderDate?: Date
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceRes
   */
  orderTemplateId?: string
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceRes
   */
  orderTime?: string
  /**
   *
   * @type {Array<string>}
   * @memberof OrderRecurrenceRes
   */
  organizations?: Array<string>
  /**
   *
   * @type {string}
   * @memberof OrderRecurrenceRes
   */
  repetitionType?: RepetitionTypeEnum
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceRes
   */
  startDate?: Date
  /**
   *
   * @type {Date}
   * @memberof OrderRecurrenceRes
   */
  updatedDate?: Date
  /**
   *
   * @type {WeeklyRepetition}
   * @memberof OrderRecurrenceRes
   */
  weeklyRepetition?: WeeklyRepetition
}

/**
 *
 * @export
 * @interface OrderTemplate
 */
export interface OrderTemplate {
  /**
   *
   * @type {Date}
   * @memberof OrderTemplate
   */
  createdDate?: Date
  /**
   *
   * @type {Address}
   * @memberof OrderTemplate
   */
  deliveryAddress?: Address
  /**
   *
   * @type {string}
   * @memberof OrderTemplate
   */
  id?: string
  /**
   *
   * @type {Array<OrderTemplateItem>}
   * @memberof OrderTemplate
   */
  items?: Array<OrderTemplateItem>
  /**
   *
   * @type {string}
   * @memberof OrderTemplate
   */
  notes?: string
  /**
   *
   * @type {Array<string>}
   * @memberof OrderTemplate
   */
  organizations?: Array<string>
  /**
   *
   * @type {OrderTemplateSupplier}
   * @memberof OrderTemplate
   */
  supplier?: OrderTemplateSupplier
  /**
   *
   * @type {Date}
   * @memberof OrderTemplate
   */
  updatedDate?: Date
}

/**
 *
 * @export
 * @interface OrderTemplateItem
 */
export interface OrderTemplateItem {
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  barcode?: string
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  measure?: MeasureEnum
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  name?: string
  /**
   *
   * @type {boolean}
   * @memberof OrderTemplateItem
   */
  orderInCase?: boolean
  /**
   *
   * @type {CaseDto}
   * @memberof OrderTemplateItem
   */
  productCase?: CaseDto
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  productId?: string
  /**
   *
   * @type {number}
   * @memberof OrderTemplateItem
   */
  quantity?: number
  /**
   *
   * @type {number}
   * @memberof OrderTemplateItem
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  subCategory?: string
  /**
   *
   * @type {string}
   * @memberof OrderTemplateItem
   */
  unit?: UnitEnum
}

/**
 *
 * @export
 * @interface OrderTemplateSupplier
 */
export interface OrderTemplateSupplier {
  /**
   *
   * @type {string}
   * @memberof OrderTemplateSupplier
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof OrderTemplateSupplier
   */
  name?: string
}

/**
 *
 * @export
 * @interface OrderTemplateWithRecurrence
 */
export interface OrderTemplateWithRecurrence {
  /**
   *
   * @type {Date}
   * @memberof OrderTemplateWithRecurrence
   */
  createdDate?: Date
  /**
   *
   * @type {Address}
   * @memberof OrderTemplateWithRecurrence
   */
  deliveryAddress?: Address
  /**
   *
   * @type {string}
   * @memberof OrderTemplateWithRecurrence
   */
  id?: string
  /**
   *
   * @type {Array<OrderTemplateItem>}
   * @memberof OrderTemplateWithRecurrence
   */
  items?: Array<OrderTemplateItem>
  /**
   *
   * @type {string}
   * @memberof OrderTemplateWithRecurrence
   */
  notes?: string
  /**
   *
   * @type {Array<string>}
   * @memberof OrderTemplateWithRecurrence
   */
  organizations?: Array<string>
  /**
   *
   * @type {OrderRecurrenceRes}
   * @memberof OrderTemplateWithRecurrence
   */
  recurrence?: OrderRecurrenceRes
  /**
   *
   * @type {OrderTemplateSupplier}
   * @memberof OrderTemplateWithRecurrence
   */
  supplier?: OrderTemplateSupplier
  /**
   *
   * @type {Date}
   * @memberof OrderTemplateWithRecurrence
   */
  updatedDate?: Date
}

/**
 *
 * @export
 * @interface PageOrderTemplateWithRecurrence
 */
export interface PageOrderTemplateWithRecurrence {
  /**
   *
   * @type {Array<OrderTemplateWithRecurrence>}
   * @memberof PageOrderTemplateWithRecurrence
   */
  content?: Array<OrderTemplateWithRecurrence>
  /**
   *
   * @type {boolean}
   * @memberof PageOrderTemplateWithRecurrence
   */
  empty?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PageOrderTemplateWithRecurrence
   */
  first?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PageOrderTemplateWithRecurrence
   */
  last?: boolean
  /**
   *
   * @type {number}
   * @memberof PageOrderTemplateWithRecurrence
   */
  number?: number
  /**
   *
   * @type {number}
   * @memberof PageOrderTemplateWithRecurrence
   */
  numberOfElements?: number
  /**
   *
   * @type {Pageable}
   * @memberof PageOrderTemplateWithRecurrence
   */
  pageable?: Pageable
  /**
   *
   * @type {number}
   * @memberof PageOrderTemplateWithRecurrence
   */
  size?: number
  /**
   *
   * @type {Sort}
   * @memberof PageOrderTemplateWithRecurrence
   */
  sort?: Sort
  /**
   *
   * @type {number}
   * @memberof PageOrderTemplateWithRecurrence
   */
  totalElements?: number
  /**
   *
   * @type {number}
   * @memberof PageOrderTemplateWithRecurrence
   */
  totalPages?: number
}
