import React, { useEffect, useState } from "react"

type Props = {
  name: string
  value: any
  options: Array<any>
  onChange: Function
  activeClass: string
  className: string
  baseClassName?: string
  updateFromParent?: boolean
}

const RadioGroup = (props: Props) => {
  const {
    name,
    value,
    options,
    onChange,
    updateFromParent = true,
    className = "",
    baseClassName = "radioGroup",
    activeClass = "active",
  } = props
  const [stateValue, setStateValue] = useState(value)

  const setValue = (val) => {
    setStateValue(val)
    onChange(val)
  }

  useEffect(() => {
    if (updateFromParent) {
      setStateValue(value)
    }
  }, [value])

  return (
    <div className={baseClassName + " " + className}>
      {options.map((option, i) => (
        <label
          htmlFor={name + "-" + option.value}
          key={i}
          className={
            "radioButton " +
            (option.className ?? "") +
            (stateValue == option.value ? " " + activeClass : "")
          }
          onClick={() => setValue(option.value)}
        >
          <input
            type="radio"
            id={name + "-" + option.value}
            name={name}
            value={option.value}
            defaultChecked={stateValue == option.value}
          />
          {option.label}
        </label>
      ))}
    </div>
  )
}

export default RadioGroup
