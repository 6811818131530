import React, { useState } from "react"
import { initialDeliveryAddressStub } from "services/constants"
import { objHasValue } from "services/helpers"
import { Address } from "services/types"
import AddressForm from "../AddressForm/AddressForm"

interface CombinedAddressForm {
  initialAddress: Address
  handleLocationDataChange(
    values: Address,
    type: "address" | "deliveryAddress"
  ): void
  selectTimeZone?: boolean
  initialDeliveryAddress?: Address
  onValidChange?(valid: boolean, type: string): void
  isNullable?: boolean
  allowNullable?: boolean
  showCompanyName?: boolean
  selectCountry?: boolean
  validateOnMount?: boolean
}

const CombinedAddressForm = ({
  selectTimeZone = false,
  initialAddress,
  initialDeliveryAddress,
  handleLocationDataChange,
  onValidChange,
  isNullable,
  allowNullable,
  showCompanyName = false,
  selectCountry = false,
  validateOnMount = true,
}: CombinedAddressForm) => {
  const [deliveryAddressEnabled, setDeliveryAddressEnabled] = useState(
    objHasValue(initialDeliveryAddress) ? true : false
  )

  return (
    <>
      <AddressForm
        selectCountry={selectCountry}
        initialValues={initialAddress}
        selectTimeZone={selectTimeZone}
        allowNullable={allowNullable}
        isNullable={isNullable}
        onDataChange={({ nextValues }) => {
          handleLocationDataChange(nextValues, "address")
        }}
        onValidChange={(valid) =>
          onValidChange && onValidChange(valid, "address")
        } //Intentionallyl eft empty
        validateOnMount={validateOnMount}
        showCompanyName={showCompanyName}
      />
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          checked={deliveryAddressEnabled}
          onChange={(e) => {
            const checked = e.target.checked
            setDeliveryAddressEnabled(checked)
            // Make delivery address empty when this option is unchecked
            if (!checked) {
              handleLocationDataChange(
                initialDeliveryAddressStub,
                "deliveryAddress"
              )
            }
          }}
          name="differentDelivery"
          className="form-checkbox text-primaryPink"
        />
        <span className="ml-2 text-gray-700">Different delivery address</span>
      </label>
      {deliveryAddressEnabled && (
        <AddressForm
          selectCountry={false}
          initialValues={initialDeliveryAddress}
          onDataChange={({ nextValues }) => {
            handleLocationDataChange(nextValues, "deliveryAddress")
          }}
          onValidChange={(valid) =>
            onValidChange && onValidChange(valid, "deliveryAddress")
          } //Intentionallyl eft empty
          isNullable={isNullable}
          validateOnMount={validateOnMount}
          allowNullable={allowNullable}
        />
      )}
    </>
  )
}

export default CombinedAddressForm
