import React, { useMemo, useState } from "react"
import * as queryString from "query-string"
import PaginationComponent from "components/common/Pagination/Pagination"

export interface PaginationDefaults {
  page: number
  size: number
  totalPages?: number
  totalElements?: number
  numberOfElements?: number
}

export interface Pagination extends PaginationDefaults {
  set: React.Dispatch<React.SetStateAction<PaginationDefaults>>
  setFromResult: (result: any) => void
  goto: (page: any) => void
  component?: any
}

export const paginationDefaults: PaginationDefaults = {
  page: 0,
  size: 20,
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
}

export const usePagination = (persist?: boolean): Pagination => {
  const queryParams = queryString.parse(location.search)

  const [paginationData, setPaginationData] = useState({
    ...paginationDefaults,
    page: Number(queryParams.page) || paginationDefaults.page,
  })

  const setFromResult = (result) => {
    setPaginationData({
      page: result?.pageable?.pageNumber ?? paginationData.page,
      size: result?.size,
      totalPages: result?.totalPages,
      totalElements: result?.totalElements,
      numberOfElements: result?.numberOfElements,
    })
  }

  const goto = (page) => setPaginationData({ ...paginationData, page })

  const paginationFunctions = {
    set: setPaginationData,
    setFromResult: setFromResult,
    goto: goto,
  }

  const obj = {
    ...paginationData,
    ...paginationFunctions,
  } as Pagination

  const handlePageClick = ({ selected }) => {
    obj.goto(selected)

    if (persist) {
      const params = {
        page: selected,
      }
      //Persist currently selected page in URL search params
      const query = new URLSearchParams(params)
      const path = `${location.pathname}?${query.toString()}`
      window.history.replaceState(null, "", path)
    }
  }

  const wrapedPaginationComponent = useMemo(() => {
    return obj?.totalPages ? (
      <div className="mb-4">
        <PaginationComponent data={obj} onPageClick={handlePageClick} />
      </div>
    ) : (
      <></>
    )
  }, [paginationData])

  return {
    ...obj,
    component: wrapedPaginationComponent,
  }
}

export default usePagination
