import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditPrice.module.css"

const EditPrice = ({ item, onSave, isSaving }) => {
  const inputRef = useRef(null)
  const [price, setPrice] = useState(
    item ? (item.selectedPrice ? item.selectedPrice : item.price) : ""
  )

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setPrice(parseFloat(value))
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    window.setTimeout(() => {
      const el = inputRef.current
      if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
      } else if (typeof el.createTextRange != "undefined") {
        el.focus()
        var range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="lg:px-2 w-full sm:w-1/2">
          <input
            ref={inputRef}
            className={`block form-control border-0 px-0 ${styles.input}`}
            onFocus={setCursorAtTheEnd}
            type="number"
            step="0.01"
            value={price || ""}
            onChange={handleInputChange}
            placeholder="Edit invoice cost"
          />
        </div>
      </div>
      <div className={styles.footer}>
        <button
          onClick={() => onSave(price)}
          className="button button--primaryBlue"
          disabled={isSaving}
        >
          {isSaving && (
            <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
          )}
          Save
        </button>
      </div>
    </div>
  )
}

EditPrice.propTypes = {
  item: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

export default EditPrice
