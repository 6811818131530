/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import BaseTable, { AutoResizer } from "react-base-table"
import { deliveryNoteStates } from "services/constants"
import { format, isValid } from "date-fns"
import { faChevronDown, faCalendar } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMediaQuery } from "react-responsive"
import classNames from "classnames/bind"

import * as styles from "./DeliveryNoteSelectItem.module.css"

const cx = classNames.bind(styles)

const DeliveryNoteSelectItem = ({ deliveryNote, onSelect }) => {
  const [collapse, setCollapse] = useState(false)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const isInvoiced = deliveryNote.isInvoiced
  // const isCompleted = deliveryNote.status === "COMPLETED"
  // const isInQuery = deliveryNote.status === "IN_QUERY"
  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col my-3">
          <span className="font-sansSemiBold font-semibold text-primaryBlue text-sm">
            {rowData.name}
          </span>
          <span className="font-sansSemiBold font-semibold text-gray-700 text-xs">
            {rowData.size} {rowData.measure}
          </span>
        </div>
      ),
    },
    {
      key: "orderQty",
      width: 80,
      title: "Order Qty",
      dataKey: "orderQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
    },
    {
      key: "dnQty",
      width: 80,
      title: "DN Qty",
      dataKey: "dnQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
    },
    {
      key: "receivedQty",
      width: 80,
      title: "Received",
      dataKey: "receivedQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
    },
    {
      key: "comment",
      width: 80,
      title: "Note",
      dataKey: "comment",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
    },
  ]

  const nameButton = (rowData) => {
    const dnState = deliveryNoteStates.find((oS) => oS.value === rowData.status)

    return (
      <div className="flex items-center">
        <div className="lg:pl-2 mr-3">
          <button
            type="button"
            className="button--smaller button--autoWidth"
            onClick={(e) => {
              e.preventDefault()
              setCollapse(!collapse)
            }}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={collapse ? 180 : 0}
            />
          </button>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}
          style={{ minWidth: "200px", textAlign: "left" }}
          className="p-4 flex outline-none focus:outline-none flex-col"
        >
          <span className="font-sansSemiBold focus:outline-none relative font-semibold text-primaryBlue">
            <span
              className="rounded-full absolute"
              style={{
                backgroundColor: dnState.color,
                left: "-18px",
                top: "8px",
                width: "10px",
                height: "10px",
              }}
            ></span>
            <div className="flex flex-col">
              <span className="whitespace-normal">
                {deliveryNote.status !== "DRAFT"
                  ? `${
                      deliveryNote.deliveryNoteNumber
                        ? deliveryNote.deliveryNoteNumber
                        : "DN-??"
                    }`
                  : `[DRAFT]${
                      deliveryNote.deliveryNoteNumber
                        ? deliveryNote.deliveryNoteNumber
                        : "DN-??"
                    }`}
              </span>
              {deliveryNote.deliveryDate &&
                isValid(new Date(deliveryNote.deliveryDate)) && (
                  <span className="text-gray-700 text-xs font-sansSemiBold font-semibold">
                    <FontAwesomeIcon icon={faCalendar} size="sm" />{" "}
                    {format(new Date(deliveryNote.deliveryDate), "dd-MM-yy")}
                  </span>
                )}
            </div>
          </span>
          <span className="text-sm font-sansSemiBold focus:outline-none font-semibold text-gray-700">
            {deliveryNote.supplier.name}
          </span>
        </button>
      </div>
    )
  }

  /**
   * Action used for both Edit and Re-deliveryNote
   * @param  {Object} rowData current deliveryNote
   * @return {Function}         shows action buttons
   */
  const actions = (rowData) => {
    return (
      <div
        className="px-1 lg:px-3"
        style={{
          minWidth: !isTabletOrMobile ? "125px" : "0",
          textAlign: "right",
        }}
      >
        <button
          type="button"
          className={classNames(
            "button whitespace-nowrap button--smaller button--autoWidth mr-2 text-xs md:text-sm",
            {
              "button--paleBlue": !collapse,
              "button--paleBlueDarker": collapse,
            }
          )}
          onClick={(e) => {
            e.preventDefault()
            onSelect(rowData)
          }}
        >
          Select
        </button>
      </div>
    )
  }

  return (
    <div
      className="border deliveryNote -mb-px"
      style={{
        backgroundColor: collapse || isInvoiced ? "#F8F8FF" : "#FFFFFF",
      }}
    >
      <div className="flex flex-wrap items-center justify-between">
        {nameButton(deliveryNote)}
        <div
          className="flex items-center justify-between lg:justify-end p-3 "
          style={{ minWidth: isTabletOrMobile ? "0" : "250px" }}
        >
          {deliveryNote.po ? (
            <span className={cx("noteOrderStatus", "found")}>
              {`${deliveryNote.po}`}
            </span>
          ) : (
            <span className={cx("noteOrderStatus", "notfound")}>
              No order found
            </span>
          )}
        </div>
        {isInvoiced ? (
          <span className={`mr-2 ${cx("noteOrderStatus", "completed")}`}>
            Invoiced already
          </span>
        ) : (
          actions(deliveryNote)
        )}
        {/* {&& actions(deliveryNote)} */}
      </div>
      {collapse && (
        <div className="w-full px-4 pb-4 deliveryNoteDetails">
          <AutoResizer height={Infinity}>
            {({ width }) => (
              <BaseTable
                fixed
                style={{ backgroundColor: "transparent" }}
                estimatedRowHeight={50}
                width={width}
                maxHeight={
                  deliveryNote.products.length === 0 ? undefined : Infinity
                }
                height={deliveryNote.products.length === 0 ? 150 : undefined}
                data={deliveryNote.products}
                rowKey="name"
                columns={columns}
                emptyRenderer={
                  <div className={styles.noItems}>No items found</div>
                }
              />
            )}
          </AutoResizer>
        </div>
      )}
    </div>
  )
}

DeliveryNoteSelectItem.propTypes = {
  deliveryNote: PropTypes.object,
  onSelect: PropTypes.func,
}

export default DeliveryNoteSelectItem
