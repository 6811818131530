import React, { useContext } from "react"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import usePermissions from "hooks/usePermissions"
import { navigate } from "gatsby"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import {
  deleteMenuProfitabilityReport,
  exportMenuProfitabilityReport,
} from "services/menu-profitability-report"
import { showError, showSuccess } from "services/toast"
import { getExportFileName } from "services/export"
import { getOrgNameById } from "services/user"
import { faChartBar, faDollarSign } from "@fortawesome/pro-duotone-svg-icons"
import ReportNameCell from "components/baseList/cells/ReportName"
import { roundNumber } from "services/helpers"

interface MenuProfitabilityReportItemProps {
  item: {
    id: string
    periodFrom: string
    periodTo: string
    organizations: string[]
    totalCost: number
    totalProfit: number
    totalProfitPercentage: number
    totalSoldValue: number
  }
  onUpdated: () => void
}

const MenuProfitabilityReportItem: React.FC<
  MenuProfitabilityReportItemProps
> = ({ item, onUpdated }: MenuProfitabilityReportItemProps) => {
  const {
    organization,
    organizations,
    organizationGroup,
    organizationPermissions,
  } = useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("Sales")

  const actionOptions = [
    {
      key: "view",
      title: "View report",
      // @ts-ignore
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "export",
      title: "Export report",
      // @ts-ignore
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "delete",
      title: "Delete report",
      type: "danger",
      disabled:
        // @ts-ignore
        !permissionObj?.permissions.delete ||
        (item?.organizations.length &&
          item?.organizations[0] !== organization?.id),
    },
  ]

  const handleActionClick = (action) => {
    const { type } = action
    const fileName = getExportFileName("menu-profitability", "", "xlsx")
    const params = {
      organizations: organizationGroup,
    }

    switch (type) {
      case "view":
        navigate(`/dashboard/insights/menu-profitability/${item.id}`)
        break
      case "delete":
        onDelete()
        break
      case "export":
        exportMenuProfitabilityReport(item.id, fileName, "xlsx", params)
        break
      default:
        break
    }
  }

  const onDelete = () => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete report",
      text: "Are you sure you want to delete this menu profitability report?",
      confirmButtonText: "Delete",
      onConfirm: () => handleDeleteReport(),
    })
  }

  const handleDeleteReport = async () => {
    const res = await deleteMenuProfitabilityReport(item.id)
    if (res?.message) {
      showError(res?.message)
    } else {
      showSuccess("Report deleted!")
      onUpdated()
    }
  }

  const numberField = (value, isPercentage = false) => {
    return (
      <span className="font-sansSemiBold text-sm">
        {value === null ? "-" : value}
        {value && isPercentage ? "%" : ""}
      </span>
    )
  }

  const columns = [
    {
      name: "Total cost",
      component: numberField(item.totalCost),
    },
    {
      name: "Total profit",
      component: (
        <div className="flex items-baseline text-sm">
          {numberField(item.totalProfit)}
          <span>
            <span className="text-gray-500 ml-1">
              {roundNumber(item.totalProfitPercentage)}%
            </span>
          </span>
        </div>
      ),
    },
    {
      name: "Total sold value",
      component: numberField(item.totalSoldValue),
    },
  ]

  return (
    <div className="flex items-center justify-between pl-3 pr-1 py-1 border-t first:rounded-t-md last:rounded-b-md border border-b-0 last:border-b gap-4 flex-wrap">
      <ReportNameCell
        data={item}
        disabled={!permissionObj?.permissions.read}
        iconStyles={{
          mainIcon: {
            backgroundColor: "#E0FFFF",
            color: "#0D847D",
          },
          subIcon: {
            color: "#0D847D",
          },
        }}
        onClick={() =>
          navigate(`/dashboard/insights/menu-profitability/${item.id}`, {
            state: {
              organizationId:
                item?.organizations.length && item?.organizations[0],
            },
          })
        }
        mainIcon={faChartBar}
        subIcon={faDollarSign}
      >
        {organizationPermissions?.general?.isMain && (
          <span className="font-sansSemiBold font-semibold text-sm">
            {item.organizations.length &&
              getOrgNameById(item.organizations[0], organizations)}
          </span>
        )}
      </ReportNameCell>

      {columns.map((column, i) => (
        <div className="flex flex-col" key={i}>
          <span className="text-gray-700 text-sm">{column.name}</span>
          {column.component}
        </div>
      ))}

      <div className="flex items-center">
        <ActionsButton
          options={actionOptions}
          onActionClick={(action) => handleActionClick(action)}
        />
      </div>
    </div>
  )
}

export default MenuProfitabilityReportItem
