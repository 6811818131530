/* eslint-disable react/prop-types */
import React, { useState, Dispatch, SetStateAction, useEffect } from "react"
import ReactTooltip from "react-tooltip"
import BaseTable, { AutoResizer, Column } from "react-base-table"
import { faBan } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"

import FilterSelect from "components/forms/FilterSelect"
import BodyPortal from "components/common/BodyPortal/BodyPortal"

import { uuidv4 } from "services/helpers"
import { showSuccess } from "services/toast"

//@ts-ignore
import * as styles from "./EditableImportTable.module.css"

const cx = classNames.bind(styles)

interface Props {
  type: string
  verifyField: (key: string, item?: string) => string
  onImport: (data: []) => void
  importData: never[]
  setImportData: Dispatch<SetStateAction<never[]>>
  keys: { label: string; value: string }[]
  storedKeys: {}
  setStoredKeys: Dispatch<SetStateAction<{}>>
  emptyStoredKeys: {}
  reversedStoredKeys: {}
  hiddenIndexes: {}
  setHiddenIndexes: Dispatch<SetStateAction<{}>>
  formattingHasError?: boolean
  setFormattingHasError?: Dispatch<SetStateAction<boolean>>
  customDisableImportCondition?: boolean
  removeFirstRow: boolean
}

const EditableImportTable = ({
  type,
  verifyField,
  onImport,
  importData,
  setImportData,
  keys,
  storedKeys,
  setStoredKeys,
  emptyStoredKeys,
  reversedStoredKeys,
  hiddenIndexes,
  setHiddenIndexes,
  formattingHasError,
  setFormattingHasError,
  customDisableImportCondition,
  removeFirstRow,
}: Props) => {
  const productKeysRequired = keys.map((itm) => ({
    ...itm,
    label: itm.label + (itm.required ? "*" : ""),
  }))
  const [errorColumns, setErrorColumns] = useState([])
  const [prevStoredKeys, setPrevStoredKeys] = useState(storedKeys)
  const [prevImportData, setPrevImportData] = useState(importData)
  const [prevRemoveFirstRow, setPrevRemoveFirstRow] = useState(removeFirstRow)

  if (storedKeys !== prevStoredKeys) {
    //more performant way to reset error columns without useEffect
    setPrevStoredKeys(storedKeys)
    setErrorColumns([])
  } else if (importData !== prevImportData) {
    setPrevImportData(importData)
    setErrorColumns([])
  } else if (removeFirstRow !== prevRemoveFirstRow) {
    setPrevRemoveFirstRow(removeFirstRow)
    setErrorColumns([])
  }

  const handleUndoDeleteColumn = (data, storedKeys) => {
    setHiddenIndexes({
      ...hiddenIndexes,
      [data]: false,
    })

    setStoredKeys(storedKeys)
  }

  const handleUndoDeleteRow = (index, rowData, data) => {
    const newData = [].concat(data)
    newData.splice(index, 0, rowData[0])
    setImportData(newData)
  }

  const updateCellData = (column, row, value) => {
    const newData = [].concat(importData)
    newData[row] = { ...newData[row] }
    newData[row].row = [].concat(newData[row].row)
    newData[row].row[column] = value
    setImportData(newData)
  }

  // const hasHeaders = (data, isFormatted = false) => { //AUTO DETECT HEADERS. NOT CURRENTLY IN USE
  //   if (!data) return
  //   let hasHeaders = false
  //   const labels = keys.map((key) => key.label.toLowerCase())
  //   const firstRow = isFormatted ? Object.values(data[0]) : data[0].row

  //   if (firstRow.length > 0) {
  //     for (let i = 0; i < firstRow.length; i++) {
  //       if (labels.find((label) => label === firstRow[i].toLowerCase())) {
  //         hasHeaders = true
  //         break
  //       }
  //     }
  //   }

  //   return hasHeaders
  // }

  const columns: any[] = []
  let firstLoop = true
  let verificationError = false

  const formattedData = importData.map((r, dataIndex) => {
    const shouldVerifyRow = removeFirstRow ? dataIndex !== 0 : true

    const formattedRow = {}
    formattedRow.id = "row-" + uuidv4()

    const rowObj = {}
    r.row.forEach((item, index) => (rowObj[`item-${index}`] = item))

    r.row.forEach((item, index) => {
      if (hiddenIndexes[`item-${index}`]) {
        return
      }

      formattedRow[`item-${index}`] = item

      const hasError =
        verifyField(`item-${index}`, item, rowObj, importData).length > 0 &&
        shouldVerifyRow

      if (hasError) {
        verificationError = true

        if (!errorColumns.includes(`item-${index}`)) {
          const refreshedErrorColumns = [].concat(errorColumns)
          refreshedErrorColumns.push(`item-${index}`)
          setErrorColumns(refreshedErrorColumns)
        }
      }

      if (firstLoop) {
        columns.push({
          key: `item-${index}-${dataIndex}-${item}`,
          width: 250,
          title: "Item",
          dataKey: `item-${index}`,
          flexGrow: 1,
          flexShrink: 0,
          hiddenIndexes,
          className: "text-sm",
          data: importData, // This needs to be added to ensure that the data gets refreshed on rerender
          reversedStoredKeys: reversedStoredKeys, // This needs to be added to ensure that the data gets refreshed on rerender
          removeFirstRow: removeFirstRow,
          columnHasError: errorColumns.includes(`item-${index}`),
          cellRenderer: (cellData) => {
            const updateValBuffer = (ev) => {
              updateCellData(
                cellData.columnIndex,
                cellData.rowIndex,
                ev.target.value
              )
            }

            const error = verifyField(
              cellData.column.dataKey,
              cellData.cellData,
              cellData.rowData,
              importData
            )

            const isError =
              error.length > 0 &&
              (removeFirstRow ? cellData.rowIndex !== 0 : true)

            return (
              <div
                className={`${isError ? styles.cellWrapperError : ""} ${
                  styles.cellWrapper
                }`}
              >
                <textarea
                  key={`textarea-${cellData.columnIndex}-${cellData.rowIndex}`}
                  className="bg-transparent w-full h-full resize-none border-0"
                  onBlur={updateValBuffer}
                  defaultValue={cellData.cellData}
                />
                {isError && (
                  <span
                    className="text-red-400 block text-xs absolute bottom-0 whitespace-nowrap"
                    data-tip={error}
                    data-for={`cellErrorTooltip-${cellData.columnIndex}-${cellData.rowIndex}`}
                  >
                    {error}
                  </span>
                )}
                <BodyPortal
                  id={`cellErrorTooltip-${cellData.columnIndex}-${cellData.rowIndex}-portal`}
                >
                  <ReactTooltip
                    id={`cellErrorTooltip-${cellData.columnIndex}-${cellData.rowIndex}`}
                    type="light"
                    place="top"
                    effect="solid"
                    border={true}
                    borderColor="#e2e8f0"
                  />
                </BodyPortal>
              </div>
            )
          },
        })
      }
    })

    if (firstLoop) {
      firstLoop = false
    }

    return formattedRow
  })

  columns.push({
    key: `action-1`,
    width: 40,
    title: "Item",
    dataKey: `action-1`,
    flexGrow: 1,
    flexShrink: 0,
    frozen: Column.FrozenDirection.RIGHT,
    className: "text-sm",
    data: importData, // This needs to be added to ensure that the data gets refreshed on rerender
    removeFirstRow: removeFirstRow,
    cellRenderer: (cellData) => {
      return (
        <div>
          <BodyPortal id={`editTooltip-${cellData.rowIndex}-portal`}>
            <ReactTooltip
              id={`editTooltip-${cellData.rowIndex}`}
              type="light"
              place="top"
              effect="solid"
              border={true}
              borderColor="#e2e8f0"
            />
          </BodyPortal>
          <button
            className="text-primaryPink pl-2 "
            data-for={`editTooltip-${cellData.rowIndex}`}
            data-tip="Delete row"
            onClick={() => {
              const newData = [].concat(importData)
              const deletedRow = newData.splice(cellData.rowIndex, 1)
              setImportData(newData)
              showSuccess(() => (
                <div className="flex flex-col">
                  <span>Row deleted!</span>
                  <span
                    className="underline"
                    onClick={() =>
                      handleUndoDeleteRow(
                        cellData.rowIndex,
                        deletedRow,
                        newData
                      )
                    }
                  >
                    Undo
                  </span>
                </div>
              ))
            }}
          >
            <FontAwesomeIcon icon={faBan} className="mr-2" />
          </button>
        </div>
      )
    },
  })

  const handleImport = () => {
    if (removeFirstRow) {
      formattedData.shift()
    }
    onImport(formattedData)
  }

  const headerRenderer = ({ columns }) => {
    // frozen table's header
    if (columns.every((x) => x.frozen)) return <div></div>
    return (
      <>
        {columns
          .filter((x) => !x.frozen)
          .map((x, index) => {
            return (
              <div
                key={`table-header-${index}`}
                className={cx("text-sm", {
                  cellWrapperError: x.columnHasError,
                })}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  flex: "0 0 auto",
                  width: "250px",
                  overflow: "hidden",
                }}
              >
                <FilterSelect
                  options={productKeysRequired}
                  key={`select-${x.dataKey}-${Object.values(storedKeys).join(
                    "-"
                  )}`}
                  value={reversedStoredKeys[x.dataKey]}
                  isClearable={true}
                  isPortal
                  isOptionDisabled={(opt) => !!storedKeys[opt.value]?.length}
                  onChange={async (val) => {
                    const newStoredKeys = {
                      ...storedKeys,
                    }
                    // Reset the values for the existing index
                    Object.keys(newStoredKeys).forEach((storedKey) => {
                      if (newStoredKeys[storedKey] === x.dataKey) {
                        newStoredKeys[storedKey] = ""
                      }
                    })

                    if (val) {
                      newStoredKeys[val.value] = x.dataKey
                    }

                    setStoredKeys(newStoredKeys)
                    // await setCategory(val)
                  }}
                  placeholder="Assign Header"
                  className="my-2 w-full mr-6"
                />
                <BodyPortal id={`editTooltip-column${index}-portal`}>
                  <ReactTooltip
                    id={`editTooltip-column${index}`}
                    type="light"
                    place="top"
                    effect="solid"
                    border={true}
                    borderColor="#e2e8f0"
                  />
                </BodyPortal>
                <button
                  data-for={`editTooltip-column${index}`}
                  data-tip="Delete Column"
                  className="-ml-4 mr-4 text-primaryPink"
                  onClick={() => {
                    setHiddenIndexes({
                      ...hiddenIndexes,
                      [x.dataKey]: true,
                    })

                    showSuccess(() => (
                      <div className="flex flex-col">
                        <span>Column deleted!</span>
                        <span
                          className="underline"
                          onClick={() =>
                            handleUndoDeleteColumn(x.dataKey, storedKeys)
                          }
                        >
                          Undo
                        </span>
                      </div>
                    ))

                    // make sure we reset the header value as well if it was set
                    const newStoredKeys = {
                      ...storedKeys,
                    }

                    Object.keys(newStoredKeys).forEach((storedKey) => {
                      if (newStoredKeys[storedKey] === x.dataKey) {
                        newStoredKeys[storedKey] = ""
                      }
                    })

                    setStoredKeys(newStoredKeys)
                  }}
                >
                  <FontAwesomeIcon icon={faBan} />
                </button>
              </div>
            )
          })}
      </>
    )
  }

  useEffect(() => {
    if (setFormattingHasError) {
      setFormattingHasError(verificationError)
    }
  }, [verificationError])

  return (
    <>
      {formattedData.length > 0 && (
        <div className="flex flex-col w-full h-full growyze-table">
          {/* Table */}
          <div className="flex-grow-1 h-full" style={{ minHeight: "200px" }}>
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  headerRenderer={headerRenderer}
                  fixed
                  width={width}
                  height={height}
                  data={formattedData}
                  columns={columns}
                  emptyRenderer={<div>No items found</div>}
                  ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
                />
              )}
            </AutoResizer>
          </div>

          <div className="flex justify-between items-center absolute bottom-0 left-0 text-right w-full bg-white pl-8 pr-4 lg:pr-6 py-4 border-t">
            <div className={styles.meta}>
              1 - {formattedData.length} of {formattedData.length} items
            </div>
            <button
              type="submit"
              disabled={
                customDisableImportCondition
                  ? customDisableImportCondition
                  : formattingHasError || emptyStoredKeys.length > 0
              }
              onClick={handleImport}
              className="button button--autoWidth button--primaryGreen"
            >
              <span className="capitalize">{`Import ${type}s`}</span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default EditableImportTable
