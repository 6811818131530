import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditTotal.module.css"
import { roundNumber } from "services/helpers"

const EditTotal = ({ invoice, onSave, isSaving }) => {
  const inputRef = useRef(null)
  const [totalCost, setTotalCost] = useState(
    invoice ? roundNumber(Number(invoice.totalCost), 3) : ""
  )

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setTotalCost(Number(value))
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    window.setTimeout(() => {
      const el = inputRef.current
      if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
      } else if (typeof el.createTextRange != "undefined") {
        el.focus()
        var range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="lg:px-2 w-full sm:w-1/2">
          <input
            ref={inputRef}
            className={`block form-control border-0 px-0 ${styles.input}`}
            onFocus={setCursorAtTheEnd}
            type="number"
            step="0.001"
            value={totalCost || ""}
            onChange={handleInputChange}
            placeholder="Edit invoice cost"
          />
        </div>
      </div>
      <div className={styles.footer}>
        <button
          onClick={() => onSave({ totalCost })}
          className="button button--primaryBlue"
          disabled={isSaving}
        >
          {isSaving && (
            <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
          )}
          Save
        </button>
      </div>
    </div>
  )
}

EditTotal.propTypes = {
  invoice: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

export default EditTotal
