import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartBar, faSearch } from "@fortawesome/pro-duotone-svg-icons"
import { showSuccess } from "services/toast"

import Loader from "components/common/Loader/Loader"
import AsyncSelect from "components/forms/AsyncSelect"
import CategorySelect from "components/common/CategorySelect/CategorySelect"
import { getStockTakes } from "services/stock-take/stock-take"
import { getSales } from "services/sales"
import { createGPReport, updateGPReport } from "services/gp-report"
import { formatReportSelectOptionLabel } from "services/helpers"

//@ts-ignore
import * as styles from "./GPReportModal.module.css"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"

import { Category } from "services/types"
import { formatCategoriesAsReportFilterCategories } from "services/reportHelpers"
import useCategories from "hooks/useCategories"
interface Props {
  gpReportId: string
  gpData: any
  onRequestClose: () => void
  refreshData: () => void
  title?: string
  loadingText?: string
}

const GPReportModal = ({
  gpReportId,
  gpData,
  title,
  loadingText,
  onRequestClose,
  refreshData,
  ...otherProps
}: Props) => {
  const { openingStockTakeReportId, closingStockTakeReportId, sales } =
    gpData || {}

  const [loading, setLoading] = useState(false)
  const [openingStockTake, setOpeningStockTake] = useState<{
    label: string | undefined
    value: {}
  } | null>(null)
  const [closingStockTake, setClosingStockTake] = useState<{
    label: string | undefined
    value: {}
  } | null>(null)
  const [salesReport, setSalesReport] = useState<{
    label: string | undefined
    value: {}
  } | null>(null)

  const [showFilters, setShowFilters] = useState(false)

  const filterFunction = (categories: Category[]) => {
    return categories.map((category) => ({
      ...category,
      active: true,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        active: true,
      })),
    }))
  }
  const defaultCategories = useCategories(filterFunction).categories
  const [categories, setCategories] = useState<Category[]>(defaultCategories)

  useEffect(() => {
    if (!categories.length) {
      setCategories(defaultCategories)
    }
  }, [defaultCategories])

  const fetchFinalisedStockTakes = async (inputValue) => {
    const params = {
      size: 99,
      sort: "completedAt,desc",
      status: "COMPLETED",
      partialName: inputValue ? inputValue : null,
    }
    const result = await getStockTakes(params)

    if (openingStockTakeReportId) {
      const openingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === openingStockTakeReportId
      )
      setOpeningStockTake({
        label: formatReportSelectOptionLabel(openingStockTake, "stocktake"),
        value: openingStockTake,
      })
    }

    if (closingStockTakeReportId) {
      const closingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === closingStockTakeReportId
      )
      setClosingStockTake({
        label: formatReportSelectOptionLabel(closingStockTake, "stocktake"),
        value: closingStockTake,
      })
    }

    return result
  }

  const fetchSalesReports = async () => {
    const params = {
      size: 99,
      sort: "from,desc",
      live: false,
    }

    const result = await getSales(params)

    const salesFile = result.content.find((sf) => sf.id === sales?.id)

    if (salesFile) {
      setSalesReport({
        label: formatReportSelectOptionLabel(salesFile, "sales"),
        value: salesFile,
      })
    }

    return result
  }

  const onCreateGPReport = async (
    closingStockTake,
    openingStockTake,
    salesReport
  ) => {
    setLoading(true)
    const params = {
      gpReportId: gpReportId ? gpReportId : null,
      closingStockTakeReportId: closingStockTake.value.stockTakeReport.id,
      openingStockTakeReportId: openingStockTake.value.stockTakeReport.id,
      salesId: salesReport ? salesReport.value.id : null,
      filterCategories: formatCategoriesAsReportFilterCategories(categories),
    }

    const report = gpReportId
      ? await updateGPReport(params)
      : await createGPReport(params)

    if (report) {
      showSuccess("Report updated!")

      if (refreshData) {
        await refreshData()
      }

      setLoading(false)
      onRequestClose()

      return report
    }
  }

  const categoryCount = categories
    ? categories.reduce((acc, item) => {
        return acc + item.subCategories.filter((subCat) => subCat.active).length
      }, 0)
    : 0

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      portalClassName="gpReportCreate"
      {...otherProps}
    >
      <div className="flex flex-col h-full">
        <div className={styles.header}>
          <div className={styles.thumbnailWrapper}>
            <div className={styles.thumbnail}>
              <div
                className={styles.iconWrapper}
                style={{
                  backgroundColor: "#EAEAF7",
                  color: "#000055",
                }}
              >
                <FontAwesomeIcon icon={faChartBar} />
                <span
                  className={styles.thumbnailBadge}
                  style={{
                    color: "#000055",
                  }}
                >
                  <FontAwesomeIcon size="xs" icon={faSearch} />
                </span>
              </div>
            </div>
          </div>
          <h2 className="text-lg">
            {title ? title : "New GP analysis report"}
          </h2>
        </div>
        <div className={styles.content}>
          <div className="my-1 px-2 w-full">
            <Loader
              isLoading={loading}
              style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
            >
              {loadingText ? loadingText : "Creating GP analysis report..."}
            </Loader>

            <h2 className="my-2">Select opening stocktake</h2>
            <AsyncSelect
              name="Opening stocktake"
              label="Opening stocktake"
              className="w-full"
              promise={fetchFinalisedStockTakes}
              placeholder="Opening stocktake"
              value={openingStockTake}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "stocktake")
              }
              isClearable={true}
              onChange={(val) => {
                setOpeningStockTake(val)
              }}
            />

            <h2 className="mt-6 mb-2">Select closing stocktake</h2>
            <AsyncSelect
              name="Closing stocktake"
              label="Closing stocktake"
              className="w-full"
              promise={fetchFinalisedStockTakes}
              placeholder="Closing stocktake"
              value={closingStockTake}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "stocktake")
              }
              isClearable={true}
              onChange={(val) => {
                setClosingStockTake(val)
              }}
            />

            <h2 className="mt-6 mb-2">Select sales file (optional)</h2>
            <AsyncSelect
              label="Sales file"
              name="Sales file"
              className="w-full"
              promise={fetchSalesReports}
              placeholder="Sales file"
              value={salesReport}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "sales")
              }
              isClearable={true}
              onChange={(val) => {
                setSalesReport(val)
              }}
            />
          </div>
          <div className="mt-2">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-baseline"
            >
              <FontAwesomeIcon
                icon={showFilters ? faChevronUp : faChevronDown}
                className="mr-2 text-sm"
              />
              <span className="mb-2 mt-2 flex flex-col items-start">
                <span className="underline hover:no-underline text-primaryBlue font-semibold font-sansSemiBold">
                  Select product categories
                </span>
                {!showFilters && (
                  <span className="font-normal font-sans text-sm text-gray-600">
                    {categoryCount} categories selected
                  </span>
                )}
              </span>
            </button>
          </div>
          <div
            className={`flex flex-col sm:flex-row mx-2 ${
              !showFilters ? "hidden" : ""
            }`}
          >
            <CategorySelect
              categories={categories}
              onSelectCategories={(selectedCategories) =>
                setCategories(selectedCategories)
              }
            />
          </div>
        </div>
        <div className={styles.footer}>
          <button className={styles.cancelButton} onClick={onRequestClose}>
            Close
          </button>
          <button
            className="button ml-auto button--autoWidth button--primaryGreen"
            onClick={() => {
              onCreateGPReport(closingStockTake, openingStockTake, salesReport)
            }}
            disabled={!openingStockTake || !closingStockTake}
          >
            <span className="hidden lg:inline">
              {gpReportId ? "Update" : "Create"} report
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default GPReportModal
