import { get, post, put, remove } from "../api"

import {
  OrderRecurrenceRes,
  OrderTemplate,
  OrderTemplateWithRecurrence,
  PageOrderTemplateWithRecurrence,
} from "./types"

import { APIError } from "../types"

export const getOrderTemplates = (
  params
): Promise<PageOrderTemplateWithRecurrence | APIError> =>
  get("/order-templates", params)

export const getOrderTemplate = (
  orderTemplateId,
  params = {
    includeApprovers: true,
  }
): Promise<OrderTemplateWithRecurrence> =>
  get(`/order-templates/${orderTemplateId}`, params)

export const createOrderTemplate = (orderTemplate: OrderTemplate) => {
  return post(`/order-templates`, orderTemplate)
}

export const updateOrderTemplate = (
  orderTemplateId,
  orderTemplate: OrderTemplate
) => {
  return put(`/order-templates/${orderTemplateId}`, orderTemplate)
}

export const removeOrderTemplate = (orderTemplateId) => {
  return remove(`/order-templates/${orderTemplateId}`)
}

/**
 * Order Recurrence
 */

export const createOrderRecurrence = (
  orderTemplateId,
  orderRecurrence: OrderRecurrenceRes
) => {
  return post(`/order-templates/${orderTemplateId}/recurrence`, orderRecurrence)
}

export const updateOrderRecurrence = (
  orderTemplateId,
  orderRecurrence: OrderRecurrenceRes
) => {
  return put(`/order-templates/${orderTemplateId}/recurrence`, orderRecurrence)
}
