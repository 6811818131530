import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons"
import { faCircle, faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Label from "components/common/Label/Label"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import { navigate } from "gatsby"
import React, { useContext, useState } from "react"
import { useLocation } from "react-use"
import { refreshLogin } from "services/auth"
import { getCombinedUserInformation } from "services/user"

const stayOnSamePage = process.env.GATSBY_DEV_STAY_ON_PAGE_AFTER_SWITCH ?? null

const Line = ({ handleOrgSwitch, org }) => {
  const isMain = org.type === "MAIN" // Define location as main in case of enterprise account
  const isDemo = org.type === "DEMO"
  const isSelected = org.isSelected // Is current organization set to selected

  return (
    <div className="flex w-full rounded-sm">
      <button
        className="flex items-center w-full py-2 hover:bg-gray-200 transition-colors px-4"
        onClick={() => handleOrgSwitch(org.id)}
      >
        <FontAwesomeIcon
          icon={faCircle}
          style={{ fontSize: 8 }}
          className={`mr-2  ${
            isSelected ? "text-primaryGreen" : "text-gray-400"
          }`}
        />
        <span
          className={`flex items-center space-x-2 w-full text-left ${
            isSelected ? "font-semibold font-sansSemiBold" : ""
          }`}
        >
          <span>{org.companyName}</span>
          {isMain && (
            <div>
              <Label type="success">MAIN</Label>
            </div>
          )}
          {isDemo && (
            <div>
              <Label type="danger">DEMO</Label>
            </div>
          )}
        </span>
      </button>
    </div>
  )
}

function VenueSwitchPopup({ isOpen, closePopup }) {
  const [searchInput, setSearchInput] = useState("")
  const {
    organization,
    organizations,
    organizationsFormatted,
    organizationPermissions,
  } = useContext(GlobalStateContext)
  const { pathname } = useLocation()
  const dispatch = useContext(GlobalDispatchContext)
  const isMainOrg = organizationPermissions?.general?.isMain

  const handleOrgSwitch = async (orgId) => {
    if (orgId === organization.id) return

    closePopup()

    await refreshLogin(orgId)

    const userInfo = await getCombinedUserInformation()

    dispatch({ type: "SET_COMBINED_USER_INFORMATION", payload: userInfo })

    if (stayOnSamePage) {
      navigate(pathname + "", { replace: true })
    } else {
      if (pathname !== "/dashboard") {
        navigate(`/dashboard`, { replace: true })
      }
    }
  }

  React.useEffect(() => {
    if (!isOpen) setSearchInput("")
  }, [isOpen])

  const allOrgs = organizations.map((a) => ({
    companyName: a.label,
    id: a.value,
    type: a.type,
  }))

  const filteredOrgs = (
    !searchInput ? organizationsFormatted : allOrgs
  )?.filter((a) =>
    a.companyName.toLowerCase().includes(searchInput.toLowerCase())
  )

  // Return empty fragment if shouldnt be open
  if (false === isOpen) {
    return <></>
  }

  return (
    <div className="relative">
      <div
        className={
          "absolute border border-gray-200  pt-2 w-72 ml-4 bg-white rounded-md shadow-2xl z-30 items-start"
        }
      >
        <div className="px-4 pb-2 border-b flex flex-col">
          <span>
            {organization && organization.companyName && (
              <>
                <span className="text-xs text-gray-700">
                  Selected location:
                </span>
                <span className="block text-base text-primaryBlue font-sansSemiBold font-medium">
                  {organization.companyName}
                </span>
              </>
            )}
          </span>
        </div>

        <div className="p-2 bg-gray-50">
          <div className="flex items-center border border-gray-400 bg-white px-2 rounded-md">
            <FontAwesomeIcon icon={faSearch} className="text-gray-600" />
            <input
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              type="text"
              className="rounded-lg h-8 w-full border-0 text-sm px-2"
              placeholder="Find location..."
            />
            {searchInput && (
              <FontAwesomeIcon
                icon={faTimes}
                className="text-red-500 cursor-pointer"
                onClick={() => setSearchInput("")}
              />
            )}
          </div>
        </div>

        <div className="text-black flex flex-col text-left overflow-y-auto max-h-80 md:max-h-96">
          <ul className="flex flex-col text-black bg-gray-50">
            {filteredOrgs.map((org) => {
              return (
                <li
                  className={`cursor-pointer flex flex-col first:border-t border-b`}
                  key={"Organization-selector-" + org.id}
                >
                  <Line handleOrgSwitch={handleOrgSwitch} org={org} />
                  {Boolean(org.subOrgsMapped?.length) && (
                    <ul className="ml-4 flex flex-col">
                      {!searchInput &&
                        org.subOrgsMapped.map((a) => (
                          <div
                            key={a.id}
                            className="border-b border-gray-200 last:border-b-0 first:border-t"
                          >
                            <Line handleOrgSwitch={handleOrgSwitch} org={a} />
                          </div>
                        ))}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
        </div>

        {isMainOrg && ( // "Add locations" button, only visible for main accounts
          <button
            type="button"
            onClick={() => {
              navigate("/dashboard/company-settings?tab=locations")
            }}
            className="flex items-center justify-start w-full px-4 py-3 font-semibold bg-white border-b text-primaryGreen hover:bg-gray-200 border-gray-200 space-x-4 text-base"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add
            location / area
          </button>
        )}
      </div>
    </div>
  )
}

export default VenueSwitchPopup
