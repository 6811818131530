import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"

import AddProductForm from "../AddProductForm/AddProductForm"
import AddRecipeForm from "../AddRecipeForm/AddRecipeForm"

const EditStockModal = ({
  product,
  recipe,
  onRequestClose,
  onEdit,
  ...otherProps
}) => {
  const onEditStockTake = (data, newQty) => {
    onEdit(data, newQty)
    onRequestClose()
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      {product ? (
        <AddProductForm
          product={product}
          onCancel={onRequestClose}
          onEdit={onEditStockTake}
        />
      ) : (
        recipe && (
          <AddRecipeForm
            newRecipe={{ ...recipe.recipe, ...recipe }}
            onCancel={onRequestClose}
            onEdit={onEditStockTake}
            isEdit={true}
          />
        )
      )}
    </Modal>
  )
}

EditStockModal.propTypes = {
  product: PropTypes.object,
  onRequestClose: PropTypes.func,
  onEdit: PropTypes.func,
}

export default EditStockModal
