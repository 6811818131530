import React, { useContext } from "react"
import { useField } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"
import { AsideContext } from "context/aside/AsideContext"
import AsideCalendar from "../common/AsideCalendar/AsideCalendar"
import { convertToFormat } from "services/helpers"
import { FlexiblePeriod } from "services/types"

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  label: string
  disableBefore?: Date
}

const DateInput = ({ label, disableBefore, ...rest }: Props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, helpers] = useField(rest)

  const { setValue } = helpers

  const aside = useContext(AsideContext)

  const toggleDatePicker = () => {
    // @ts-ignore
    aside.showAside(AsideCalendar, {
      headerText: "Date picker",
      mode: "single",
      disable: { before: disableBefore ? disableBefore : null },
      onConfirm: (data: FlexiblePeriod) => {
        if (data.from) {
          setValue(data.from)
        }
        aside.hideAside()
      },
    })
  }

  return (
    <div className="flex flex-col items-start mb-8">
      {label && (
        <label
          className="font-sansBold text-primaryBlue mb-2"
          htmlFor={rest.name}
        >
          {label}
        </label>
      )}
      <div
        className="flex items-center relative cursor-pointer"
        onClick={() => toggleDatePicker()}
      >
        <input
          className="form-control form-control--first form-control--last pr-11 h-11 border-gray-300 w-72"
          type="text"
          readOnly={true}
          value={field.value ? convertToFormat(field.value) : undefined}
          {...rest}
        />
        <FontAwesomeIcon
          icon={faCalendar}
          className="absolute right-4 z-10 text-primaryBlue"
        />
      </div>
    </div>
  )
}

export default DateInput
