import InitialContext from "context/global/InitialContext"

const accountingReducer = (state, action) => {
  switch (action.type) {
    case "ACCOUNTING": {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          [action.payload.provider.key]: {
            ...(state.accounting[action.payload.provider.key] ||
              InitialContext.accounting),
            [action.payload.field]: action.payload.value,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

export default accountingReducer
