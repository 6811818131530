import React, { useContext, useEffect } from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import BaseList from "components/baseList/BaseList"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import usePermissions from "hooks/usePermissions"
import usePagination from "hooks/usePagination"
import MenuProfitabilityReportItem from "./MenuProfitabilityReportItem"
import { showError } from "services/toast"
import { getMenuProfitabilityReports } from "services/menu-profitability-report"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { usePrevious } from "react-use"
import { faChartBar } from "@fortawesome/pro-duotone-svg-icons"
import { getMenuProfitabilityReportsDummy } from "services/dummy"
import { ModalContext } from "context/modal/ModalContext"
import MenuProfitabilityReportModal from "./MenuProfitabilityReportModal"

const MenuProfitabilityReports: React.FC = () => {
  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("Sales")
  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)

  const [reportsData, setReportsData] = React.useState<{
    content: []
  } | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setReportsData({ content: [] })
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: "createdAt,desc",
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    const reports = await getMenuProfitabilityReports(params)

    if (reports?.message) {
      showError(reports.message)
    } else {
      setReportsData(reports)
      pagination.setFromResult(reports)
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getData()
  }, [selectedOrganizations])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  return (
    <>
      <Helmet>
        <title>Menu Profitability Report</title>
      </Helmet>
      <div className="w-full flex flex-col h-full overflow-hidden">
        <Header title="Menu Profitability Report" />

        <div className="flex justify-between px-8 py-4 border-b flex-row-reverse flex-wrap gap-y-2">
          <button
            type="button"
            // @ts-ignore
            disabled={!permissionObj?.permissions.modify}
            className="button button--autoWidth button--primaryGreen"
            onClick={() =>
              modal.showModal(MenuProfitabilityReportModal, {
                onUpdated: getData,
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
            <span className="hidden lg:inline">New Report</span>
          </button>

          <LocationsSelector />
        </div>

        <div className="px-0 pb-6 -mt-px relative flex-grow w-full h-full overflow-x-hidden overflow-y-auto lg:px-8 lg:py-6">
          <BaseList
            title="Menu Profitability Reports"
            id="menu-profitability-reports"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faChartBar,
              color: "green",
              getDummy: () => getMenuProfitabilityReportsDummy,
            }}
            data={reportsData?.content || []}
            rowRender={(report) => (
              <MenuProfitabilityReportItem
                key={report.id}
                item={report}
                onUpdated={getData}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default MenuProfitabilityReports
