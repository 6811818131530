import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCube, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import { faPencil, faTimes } from "@fortawesome/pro-light-svg-icons"
import { SelectedItem } from "./types"

interface NewProductSelectSummaryProps {
  selectedProducts: SelectedItem[]
  qtyInputTypes: { quantityKey: string; label: string; shortLabel?: string }[]
  onEdit: (product: SelectedItem) => void
  deselectItem: (product: SelectedItem) => void
  priceLabel?: string
  enablePriceEdit?: boolean
}

const NewProductSelectSummary = ({
  selectedProducts,
  qtyInputTypes,
  onEdit,
  deselectItem,
  priceLabel = "Price",
  enablePriceEdit,
}: NewProductSelectSummaryProps) => {
  return (
    <div className="flex-grow w-full relative overflow-hidden">
      <div className="flex flex-col w-full h-full overflow-auto">
        {selectedProducts.map((p) => {
          return (
            <div key={p.id} className="flex items-center px-4 py-3 border-b">
              <FontAwesomeIcon
                icon={p.packaging === "multiple" ? faCube : faWineBottle}
                className="text-primaryBlue self-start mr-3"
              />
              <div className="flex flex-col flex-grow">
                <h2 className="text-primaryBlue font-sansSemiBold font-semibold mb-2">
                  {p.name}
                </h2>
                <div className="flex flex-wrap items-baseline justify-start">
                  {qtyInputTypes.length > 0 &&
                    qtyInputTypes.map((qtyInputType) => {
                      return (
                        <div key={qtyInputType.quantityKey}>
                          <span className="uppercase text-xs text-gray-600 mr-2">
                            {qtyInputType.label}
                          </span>
                          <span className="text-primaryBlue font-semibold font-sansSemiBold mr-4">
                            {p.quantities[qtyInputType.quantityKey]}
                          </span>
                        </div>
                      )
                    })}
                  {enablePriceEdit && (
                    <button
                      onClick={() => onEdit(p)}
                      className="bg-primaryBlueVeryLight flex items-center rounded-full px-3 mr-4"
                    >
                      <span className="uppercase text-xs text-gray-600 mr-2">
                        {priceLabel}
                      </span>
                      <span className="text-primaryBlue font-semibold font-sansSemiBold mr-2">
                        {p.selectedPrice}
                      </span>

                      <FontAwesomeIcon
                        className="text-primaryBlueLighter text-sm"
                        icon={faPencil}
                      />
                    </button>
                  )}
                </div>
              </div>
              <button className="text-red-600" onClick={() => deselectItem(p)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NewProductSelectSummary
