import React, { memo } from "react"
import { getActivities, getNotifications } from "services/dashboard"
import ListWidget from "../ListWidget/ListWidget"
import { processResponse } from "../utils"
import { WidgetProps } from "../Widgets"

const processActivity = (obj) => ({
  id: obj.id,
  name: obj.message.userFullName,
  text: obj.message.text,
  date: new Date(obj.createdAt),
  type: obj.message.type,
  organization: obj.organizationsNames?.[0],
})

export const ActivityWidget = memo<WidgetProps>(({ organizations }) => (
  <ListWidget
    getItems={processResponse(getActivities, processActivity)}
    params={{
      sort: "createdAt,desc",
      organizations,
      size: 20,
    }}
  ></ListWidget>
))

export const NotificationWidget = memo<WidgetProps>(({ organizations }) => (
  <ListWidget
    getItems={processResponse(getNotifications, processActivity)}
    params={{
      sort: "createdAt,desc",
      organizations,
      size: 20,
    }}
  ></ListWidget>
))
