// extracted by mini-css-extract-plugin
export var active = "StockTakeInput-module--active--HSvbo";
export var backIcon = "StockTakeInput-module--backIcon--Yd2jJ";
export var camera = "StockTakeInput-module--camera--XYGGB";
export var clickArea = "StockTakeInput-module--clickArea--+YOfz";
export var confirm = "StockTakeInput-module--confirm--AwUPI";
export var confirmContainer = "StockTakeInput-module--confirmContainer--gvVD1";
export var confirmImg = "StockTakeInput-module--confirmImg--rQmwq";
export var container = "StockTakeInput-module--container--6pC4P";
export var content = "StockTakeInput-module--content--YF5T9";
export var contentConfirm = "StockTakeInput-module--content--confirm--mnFZg";
export var displayIntro = "StockTakeInput-module--displayIntro--uFNVI";
export var flash = "StockTakeInput-module--flash--HYrcO";
export var header = "StockTakeInput-module--header--MYcX7";
export var headerMain = "StockTakeInput-module--headerMain--lHQ+E";
export var navBar = "StockTakeInput-module--navBar--SLDwP";
export var notFound = "StockTakeInput-module--notFound--O7VSY";
export var notFoundContent = "StockTakeInput-module--notFoundContent--HcQ0b";
export var notFoundHeader = "StockTakeInput-module--notFoundHeader--N+VJ9";
export var notFoundTitle = "StockTakeInput-module--notFoundTitle--oR0WU";
export var placeHolder = "StockTakeInput-module--placeHolder--lohi4";
export var productSelect = "StockTakeInput-module--productSelect--CfXbJ";
export var productSelectContainer = "StockTakeInput-module--productSelectContainer--sz9WM";
export var rotate = "StockTakeInput-module--rotate--zFqCv";
export var scanPending = "StockTakeInput-module--scanPending--P3E7e";
export var scanPendingAnimation = "StockTakeInput-module--scanPendingAnimation--Uhr2q";
export var scanPendingButton = "StockTakeInput-module--scanPendingButton--SVKII";
export var scanPendingText = "StockTakeInput-module--scanPendingText--nUOAr";
export var scanning = "StockTakeInput-module--scanning--mx+qS";
export var show = "StockTakeInput-module--show--pqr6d";
export var spinning = "StockTakeInput-module--spinning--9B00s";
export var step2 = "StockTakeInput-module--step2--vR2D1";
export var step3 = "StockTakeInput-module--step3--p2VWA";
export var step4 = "StockTakeInput-module--step4--3v4Gc";
export var tabButton = "StockTakeInput-module--tabButton--jz1mS";
export var tabs = "StockTakeInput-module--tabs--akbVx";
export var title = "StockTakeInput-module--title--W2GTX";
export var transparent = "StockTakeInput-module--transparent--ZlAB9";