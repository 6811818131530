import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import React, { useState, PropsWithChildren } from "react"

interface CollapsibleSectionProps extends PropsWithChildren {
  icon: IconDefinition
  heading: string
  description: string
}

const CollapsibleSection = ({
  icon,
  heading,
  description,
  children,
}: CollapsibleSectionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleSectionExpanded = () => setExpanded(!expanded)

  return (
    <div
      className={`flex flex-col w-full rounded shadow-collapsible mb-4 md:mb-6 border ${
        expanded
          ? "shadow-collapsibleExpanded border-collapsibleExpanded"
          : "shadow-collapsible border-collapsible"
      }`}
    >
      <div
        className="flex justify-between items-center cursor-pointer p-3 md:p-5"
        onClick={toggleSectionExpanded}
      >
        <div className="flex flex-col md:mr-16">
          <h3 className="text-lg font-bold font-sansBold text-primaryBlue ">
            <FontAwesomeIcon icon={icon} className="mr-2" size="xs" />
            {heading}
          </h3>
          <p className="text-gray-800 mt-2 text-sm">{description}</p>
        </div>
        <FontAwesomeIcon
          icon={expanded ? faChevronUp : faChevronDown}
          className="ml-2"
        />
      </div>
      {expanded && <div className="p-3 md:p-5">{children}</div>}
    </div>
  )
}

export default CollapsibleSection
