import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
  Ref,
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faUndo,
  faTimes,
  faBarcodeRead,
} from "@fortawesome/pro-regular-svg-icons"
import { roundNumber } from "services/helpers"
import { showCompatibleMeasures } from "services/measurements"
import { productMeasures } from "services/constants"
import { convertPartialQtyToProductMeasure } from "services/measurements"
import FilterSelect from "components/forms/FilterSelect"

//@ts-ignore
import * as styles from "./AddProductForm.module.css"
import ProductMeta from "./ProductMeta"
import { ProductOrder } from "services/types"
import {
  getProductById,
  getProducts,
  updateProduct,
} from "services/products/products"
import { faCube, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import { ModalContext } from "context/modal/ModalContext"
import EditBarcodeModal from "components/common/EditBarcodeModal/EditBarcodeModal"
import { showError, showSuccess } from "services/toast"
import IconToggle from "components/common/IconToggle/IconToggle"

interface AddProductFormProps {
  product: ProductOrder
  onCancel: Function
  onSave: Function
  onEdit?: Function
  isTemplate?: boolean
}

const AddProductForm = ({
  onCancel,
  onSave,
  onEdit,
  product,
  isTemplate = false,
}: AddProductFormProps) => {
  const setInitialFullCount = (): number | undefined =>
    product.quantity ? Math.floor(product.quantity) : undefined

  const setInitialPartialCount = () => {
    const hasDecimal = !Number.isInteger(product.quantity)

    if (product.quantity && hasDecimal) {
      const partialCountInMeasurement =
        (product.quantity - Math.floor(product.quantity)) * product.size
      return [partialCountInMeasurement]
    }

    return []
  }

  const listEndRef: Ref<HTMLElement> = useRef(null)

  const [newProduct, setNewProduct] = useState({
    ...product,
    packaging: product.countedInCase ? "multiple" : "single",
  })
  const [isFullProdLoaded, setIsFullProdLoaded] = useState(false)

  const [fullItemCount, setFullItemCount] = useState(setInitialFullCount())
  const [partialItemCounts, setPartialItemCounts] = useState(
    setInitialPartialCount()
  )
  const [measure, setMeasure] = useState(
    productMeasures.find(
      (measure) =>
        measure.type === (newProduct.measure === "each" ? "each" : "percentage")
    )
  )
  const [subValue, setSubvalue] = useState<number | null>(null)

  if (measure === undefined) {
    throw new Error(`Invalid measure ${newProduct.measure}`)
  }

  const incompleteProduct =
    newProduct.size === null || newProduct.measure === null

  const modal = useContext(ModalContext)

  const handleFullCountChange = (event) => {
    const value = parseInt(event.target.value.replace(/\D/, ""))

    if (isNaN(value)) {
      return setFullItemCount(undefined)
    }
    setFullItemCount(value)
  }

  const handleSubValueChange = (event) => {
    const value = parseFloat(event.target.value)

    if (isNaN(value)) {
      return setSubvalue(null)
    }
    setSubvalue(value)
  }

  const decreaseFullQuantity = () => {
    setFullItemCount((oldC) => (oldC > 0 ? oldC - 1 : 0))
  }

  const increaseFullQuantity = () => {
    setFullItemCount((oldC) => (oldC ?? 0) + 1)
  }

  const getPartialItemCounts = () => {
    let convertedValue
    // Percentage is the only supported measure type for multiple packaging products
    if (measure.type === "percentage" || newProduct.packaging === "multiple") {
      const percentage = (subValue ?? 0) / 100
      convertedValue = percentage * newProduct.size
    } else if (measure.type === "each") {
      convertedValue = subValue
    } else {
      convertedValue = convertPartialQtyToProductMeasure(
        subValue,
        measure.value,
        newProduct.measure
      )
    }
    if (!convertedValue) {
      return partialItemCounts
    }
    return [...partialItemCounts, convertedValue]
  }

  const addSubvalue = () => {
    const counts = getPartialItemCounts()
    setPartialItemCounts(counts)
    setSubvalue(null)
  }

  const scrollToBottom = () => {
    listEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    })
  }

  const removeSubvalue = useCallback(
    (index) => {
      const newArray = [...partialItemCounts]
      newArray.splice(index, 1)
      // eslint-disable-next-line no-unused-vars
      setPartialItemCounts(() => [...newArray])
    },
    [partialItemCounts]
  )

  const getSubItemCountTotal = (list) =>
    list.reduce((a, b) => {
      return a + b
    }, 0)

  const getTotalValue = () => {
    const theFullItemCount = isNaN(Number(fullItemCount)) ? 0 : fullItemCount
    // Make sure filled partial qty is picked up from the input field also if the state is not updated yet
    // 31-10-23 (Ruben): We had an issue where partialItemCounts state was not updated before user clicked 'Save & next'
    // That caused additional quantities to be missing out
    const currentPartialItemCounts = subValue
      ? getPartialItemCounts()
      : partialItemCounts
    const subItemCountTotal = getSubItemCountTotal([
      ...currentPartialItemCounts,
    ])

    return (theFullItemCount ?? 0) + subItemCountTotal / newProduct.size
  }

  const onUpdateBarcode = (data) => {
    modal.showModal(EditBarcodeModal, {
      title: "Edit barcode",
      text: "This will update the barcode in the stocktake and your product list.",
      confirmButtonText: "Update",
      data: data,
      onConfirm: async (updatedData) => {
        const { id } = data
        const { barcode, barcodes } = updatedData

        const productData = await getProductById(id)

        productData.barcode = barcode
        productData.barcodes = barcodes

        setNewProduct({ ...newProduct, barcode, barcodes })

        const result = await updateProduct(id, productData)

        if (
          result &&
          !result.error &&
          !result.errors &&
          result.status !== 400 &&
          result.status !== 409
        ) {
          showSuccess("Product updated!")
        } else {
          showError(
            result.message ||
              "Saving failed. Did you enter all required fields?"
          )
        }
      },
    })
  }

  const getProduct = async () => {
    if (newProduct.id) {
      const result = await getProducts({
        partialProductName: newProduct.description,
        partialBarcode: newProduct.barcode,
      })
      const item = result?.content?.[0]
      setNewProduct({ ...newProduct, productCase: item?.productCase })
    }
  }

  if (!isFullProdLoaded) {
    //Fetch full product from db in order to access productCase value
    //which is required in order to check if SelectPackaging should be enabled
    getProduct()
    setIsFullProdLoaded(true)
  }

  const handlePackagingChange = (value) => {
    const newProductData = { ...newProduct, packaging: value }
    if (value === "multiple") {
      newProductData["countedInCase"] = true
      newProductData.caseSize = newProductData.productCase.size
      newProductData.price = newProductData.productCase.price

      if (fullItemCount !== undefined) {
        setFullItemCount(fullItemCount / newProductData.productCase.size)
      }
    } else {
      newProductData["countedInCase"] = false
      newProductData.price =
        newProductData.productCase.price / newProductData.productCase.size

      if (fullItemCount !== undefined) {
        setFullItemCount(fullItemCount * newProductData.productCase.size)
      }
    }

    setNewProduct(newProductData)
    setPartialItemCounts([])
  }

  useEffect(() => {
    scrollToBottom()
  }, [fullItemCount, partialItemCounts, newProduct])

  return (
    <>
      <div className={styles.header}>
        <div className="flex">
          <h3 className={styles.productName}>{newProduct.description}</h3>
          {!onEdit && (
            <button
              onClick={async (e) => {
                e.preventDefault()
                onUpdateBarcode(newProduct)
              }}
              className={`button button--autoWidth button--transparent no-truncate`}
            >
              <FontAwesomeIcon icon={faBarcodeRead} className="inline " />
            </button>
          )}
        </div>
        <ProductMeta product={newProduct}></ProductMeta>
        {!isTemplate && (
          <div className={styles.totalValue}>
            <span>Total</span>
            {isNaN(roundNumber(getTotalValue()))
              ? "-"
              : roundNumber(getTotalValue())}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.summary}>
          <ul className={styles.summaryItemList}>
            {partialItemCounts.map((count, index) => {
              return (
                <li key={index} className={styles.summaryItem}>
                  {newProduct.packaging === "single" ? (
                    <span>
                      {roundNumber(count)} {newProduct.measure}
                    </span>
                  ) : (
                    <span>
                      {roundNumber((count / newProduct.size) * 100)} %
                    </span>
                  )}
                  <button
                    onClick={() => removeSubvalue(index)}
                    className="px-4 py-2 text-primaryPink rounded-full hover:bg-gray-100"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </li>
              )
            })}
          </ul>
          <div ref={listEndRef} />
        </div>

        {!incompleteProduct && !isTemplate && (
          <div className="flex flex-row flex-wrap items-stretch justify-center border-t bg-gray-100 w-full">
            {newProduct.productCase != undefined && (
              <div className={styles.inputCol}>
                <label htmlFor="packaging">Packaging</label>
                <IconToggle
                  onChange={handlePackagingChange}
                  isBoolean={false}
                  value={newProduct.packaging}
                  icons={[
                    {
                      icon: faWineBottle,
                      value: "single",
                    },
                    { icon: faCube, value: "multiple" },
                  ]}
                />
              </div>
            )}
            <div className={styles.inputCol}>
              <label htmlFor="fullQuantity">Full item count</label>
              <div className="flex items-center">
                <button
                  className={styles.roundButton}
                  type="button"
                  onClick={decreaseFullQuantity}
                >
                  -
                </button>
                <input
                  className={styles.textInput}
                  name="fullQuantity"
                  id="fullQuantity"
                  onChange={handleFullCountChange}
                  type="number"
                  placeholder="XX"
                  value={fullItemCount}
                />
                <button
                  className={styles.roundButton}
                  type="button"
                  onClick={increaseFullQuantity}
                >
                  +
                </button>
              </div>
            </div>
            <div className={styles.inputCol}>
              <label htmlFor="subValue">Additional quantities</label>
              <div className="flex align-items-stretch">
                <input
                  className={`${styles.textInput} ${styles.additionalQtyInput}`}
                  name="subValue"
                  id="subValue"
                  type="number"
                  step={0.2}
                  value={subValue !== null ? subValue : ""}
                  placeholder="XX"
                  onChange={handleSubValueChange}
                />
                {newProduct.packaging === "single" ? (
                  <FilterSelect
                    options={showCompatibleMeasures(
                      newProduct.measure,
                      newProduct.unit
                    )}
                    value={measure}
                    defaultValue={measure}
                    getOptionLabel={(opt) => `${opt.label}`}
                    onChange={async (val) => {
                      setMeasure(val)
                    }}
                    stylesOverride={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fff",
                        borderLeft: "none",
                        borderRadius: "0 0.25rem 0.25rem 0",
                        // width: "fit-content",
                        borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                        boxShadow: "none",
                        // zIndex: state.isFocused ? 2 : null,
                        minHeight: 45,
                      }),
                    }}
                    placeholder="Measures"
                    menuPlacement="top"
                    className={styles.measures}
                  />
                ) : (
                  <span className={styles.percentage}>%</span>
                )}
                <button
                  onClick={addSubvalue}
                  type="button"
                  className="button button--autoWidth text-base button--primaryGreen no-truncate ml-2"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add
                </button>
              </div>
            </div>
          </div>
        )}

        {incompleteProduct && (
          <div className="border-t p-4 bg-gray-100 text-center">
            <h4 className="text-primaryPink fonts-sansSemibold font-semibold">
              This product is missing a size and/or measure. Please add it to
              the product in the supplier catalog.
            </h4>
          </div>
        )}
        <div className={styles.formBottom}>
          <button
            onClick={onCancel}
            type="button"
            className="text-primaryPink mr-auto px-2 sm:px-6 font-semibold font-sansSemiBold button--autoWidth"
          >
            <FontAwesomeIcon icon={faUndo} className="mr-2" />
            Cancel
          </button>
          <button
            onClick={() => {
              if (onEdit) {
                onEdit(newProduct, getTotalValue())
              }

              if (onSave) {
                onSave(false, newProduct, getTotalValue())
              }
            }}
            disabled={isTemplate ? incompleteProduct : false}
            type="button"
            className={`${
              onEdit && "button button--primaryBlue"
            } text-primaryBlue mx-3 px-6 font-semibold font-sansSemiBold button--autoWidth`}
          >
            Save
          </button>

          {!onEdit && (
            <button
              type="button"
              onClick={() => {
                if (onSave) {
                  onSave(true, newProduct, getTotalValue())
                }
              }}
              disabled={isTemplate ? incompleteProduct : false}
              className="button mx-3 button--primaryBlue button--autoWidth no-truncate"
            >
              Save & Next
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default AddProductForm
