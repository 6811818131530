import { get, patch } from "./api"

export const getDiscrepancies = (invoiceId) => {
  return get(`/invoices/${invoiceId}/discrepancies`).then(
    (response) => response.itemsDiscrepancies
  )
}

export const updateInvoiceDiscrepancy = (params) => {
  const { invoiceId, discrepancyId, payload } = params
  return patch(`/invoices/${invoiceId}/discrepancies/${discrepancyId}`, payload)
}
