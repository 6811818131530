import React, { useState, useCallback, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import Modal from "react-modal"
import classNames from "classnames/bind"

import AddProductForm from "components/stock/AddProductForm/AddProductForm"
import BarcodeScanner from "components/scanner/BarcodeScanner/BarcodeScanner"
import ProductNotFound from "components/stock/ProductNotFound/ProductNotFound"
import { getProductByBarcode } from "services/products/products"
import { Product } from "services/types"
//@ts-ignore
import * as styles from "./StockTakeSheetInput.module.css"
import NewProductSelect from "components/common/NewProductSelect/NewProductSelect"

const cx = classNames.bind(styles)

interface Props {
  /**
   *  list of selected items
   */
  currentProducts: Product[]
  /**
   * index that new input should start from
   */
  startIndex: number
  /**
   * on close modal callback
   */
  onRequestClose: () => void
  /**
   * handle save
   */
  onSave: (products: Product[]) => void
}

const StockTakeInput = ({
  currentProducts,
  startIndex,
  onRequestClose,
  onSave,
  ...otherProps
}: Props) => {
  const scannerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [currentProduct, setCurrentProduct] = useState(null)
  const [currentProductsLocal, setCurrentProductsLocal] =
    useState(currentProducts)

  const setStep2 = useCallback(async (barcodeResult) => {
    const { barcodeText } = barcodeResult

    pauseScanning()

    if (barcodeText) {
      setCurrentStep(2)
      setLoading(true)

      // Get product by barcode here
      const result = await getProductByBarcode(barcodeText)

      // Product not found
      if (result.status === 404) {
        setNotFound(true)
        setLoading(false)
        return
      }

      // Product found
      if (result) {
        setCurrentProduct({ ...result })
        setLoading(false)
        setCurrentStep(3)
      }
    } else {
      setNotFound(true)
      return
    }
  }, [])

  useEffect(() => {
    if (currentProduct) {
      setCurrentStep(3)
    }
  }, [currentProduct, setCurrentStep])

  const resumeScanning = () => {
    setNotFound(false)
    setCurrentStep(1)
    setCurrentProduct(null)

    if (scannerRef.current) {
      scannerRef.current.resume()
    }
  }

  const pauseScanning = () => {
    if (scannerRef.current) {
      scannerRef.current.pause()
    }
  }

  const onProductSave = (next, product) => {
    const productArray = [...currentProductsLocal]
    productArray.splice(startIndex, 0, product)

    onSave(productArray)
    if (next) {
      setCurrentProductsLocal(productArray)
      resumeScanning()
    } else {
      onRequestClose()
    }
  }

  const handleProductSelect = async (products) => {
    onSave(products)
    setLoading(false)
    onRequestClose()
  }

  const content = (
    <BarcodeScanner
      ref={scannerRef}
      onInit={() => setLoading(false)}
      onReadSuccess={setStep2}
      blur={currentStep === 3 || currentStep === 4}
      onAddManually={() => setCurrentStep(4)}
    />
  )

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading && currentStep !== 3 && currentStep !== 4}
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.navBar}>
          <nav className={cx("header", "transparent")}>
            <button onClick={onRequestClose}>
              <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
            </button>

            <div className={styles.headerMain}>
              <h2 className={styles.title}>Add product</h2>
            </div>

            <div className={styles.placeHolder}></div>
          </nav>
        </div>

        <div className={cx("content", "step" + currentStep)}>
          {content}

          {(currentStep === 3 || currentStep === 4 || notFound) && (
            <div
              className={styles.clickArea}
              onClick={resumeScanning}
              aria-hidden="true"
              role="button"
            ></div>
          )}

          <div className={cx("notFound", { show: notFound })}>
            <ProductNotFound
              onResume={resumeScanning}
              onAddManually={() => {
                setNotFound(false)
                setCurrentStep(4)
              }}
            />
          </div>

          <div className={styles.confirmContainer}>
            <div className={styles.confirm}>
              {currentProduct && (
                <AddProductForm
                  product={currentProduct}
                  onCancel={resumeScanning}
                  onSave={onProductSave}
                  isTemplate={true}
                />
              )}
            </div>
          </div>

          <div className={styles.productSelectContainer}>
            <div className={styles.productSelect}>
              <div className="h-full overflow-hidden flex flex-col bg-white">
                <div className="flex-grow flex flex-col overflow-auto relative">
                  <NewProductSelect
                    searchBySupplier
                    searchByBarcode
                    allowClearAll
                    allowSelectAll
                    onSelect={handleProductSelect}
                    onClose={resumeScanning}
                    qtyPicker={false}
                    multiSelect={true}
                    enablePriceEdit={false}
                    requiredFields={["size", "measure"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StockTakeInput
