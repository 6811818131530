import React from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import StockTakes from "components/stock/StockTakes/StockTakes"
import StockTakeSheets from "components/stock/StockTakeSheets/StockTakeSheets"
import BelowMinQtyReport from "components/stock/BelowMinQtyReport/BelowMinQtyReport"

import * as styles from "./StockTakeWrapper.module.css"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"

const StockTakeWrapper = () => {
  return (
    <>
      <Helmet>
        <title>Stocktake</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Stocktake" />
        <Tabs
          initialTab={"dashboard"}
          className="growyze-tabs pt-4 px-2"
          connectSearchParams
        >
          <Tab title="Dashboard" tabKey="dashboard">
            <BelowMinQtyReport />
          </Tab>
          <Tab title="History" tabKey="history">
            <StockTakes />
          </Tab>
          <Tab title="Sheets" tabKey="sheets">
            <StockTakeSheets />
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default StockTakeWrapper
