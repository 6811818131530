import React, { useState } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import PropTypes from "prop-types"
import CustomSelect from "components/forms/CustomSelect"
import TextInput from "components/forms/TextInput"
import { normalizeNullValues } from "services/helpers"
import Logo from "inline-svg/growyze-logo-primary-blue-dark.svg"
import * as styles from "./OrganizationSelect.module.css"
import { useMediaQuery } from "react-responsive"

Modal.setAppElement("body")

const OrganizationsSchema = Yup.object().shape({
  organization: Yup.string().required("Company is required"),
  token: Yup.string().required("Token is required"),
})

const OrganizationSelect = ({ organizations, onSubmitOrganization, token }) => {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    organization: "",
    token: token ? token : "",
  }

  const onSubmit = async (data) => {
    const params = {
      activeOrgId: data.organization,
      sessionId: data.token,
    }
    setLoading(true)
    const result = await onSubmitOrganization(params)

    setLoading(false)

    if (!result) {
      return
    }

    if (result) {
      return navigate(`/dashboard`, { replace: true })
    }
  }
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues(initialValues)}
      validationSchema={OrganizationsSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form className={styles.form}>
          <Logo className="mb-4 block mx-auto" />
          <div className={styles.welcomeContent}>
            <h1 className="text-4xl mb-4">Choose location / area</h1>
            {!token && (
              <p className="text-lg text-gray-700">
                We&apos;ve sent you an email with your login token.
              </p>
            )}
          </div>
          {!loading && (
            <>
              <div>
                <CustomSelect
                  showValidations={false}
                  required={true}
                  name="organization"
                  id="organization"
                  label="Venue"
                  isSearchable={!isTabletOrMobile}
                  placeholder="Venue"
                  className="form-control form-control--first form-control--last"
                  options={organizations}
                  getOptionLabel={(opt) =>
                    `${opt.label}${opt.type === "MAIN" ? " (main)" : ""}`
                  }
                  blurInputOnSelect={false}
                />
              </div>
              {!token && (
                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      name="token"
                      id="token"
                      type="text"
                      className="form-control"
                      placeholder="Token"
                      label="Token"
                      required={true}
                    />
                  </div>
                </div>
              )}

              <div className={styles.buttons}>
                <button
                  type="button"
                  onClick={() => navigate(token ? "/login" : "/login/admin")}
                  className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
                >
                  Cancel
                </button>
                <button
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  className={"button button--autoWidth button--primaryGreen"}
                >
                  Continue
                </button>
              </div>
            </>
          )}
          {loading && (
            <div className="text-center text-3xl text-primaryPink">
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

OrganizationSelect.propTypes = {
  organizations: PropTypes.array,
  onSubmitOrganization: PropTypes.func,
  token: PropTypes.string,
}

export default OrganizationSelect
