import React from "react"
import {
  InvoicedProduct,
  getCaseQty,
  getCasePrice,
  getUnitPrice,
  getUnitQty,
} from "services/invoices"
import { roundNumber } from "services/helpers"
import ProductIcon from "components/common/ProductIcon/ProductIcon"

interface InvoicedProductItemProps {
  item: InvoicedProduct
  layout?: "horizontal" | "vertical"
}

const InvoicedProductItem: React.FunctionComponent<
  InvoicedProductItemProps
> = ({ item, layout = "vertical" }: InvoicedProductItemProps) => {
  return (
    <div
      className={`w-full flex flex-grow py-2 px-2 ${
        layout === "horizontal" ? "items-center flex-wrap" : "flex-col"
      }`}
    >
      <span
        className={`text-sm text-primaryBlue ${
          layout === "horizontal" ? "w-full md:w-1/2" : "w-full"
        }`}
      >
        {item.description}
      </span>
      {item.invoiceQtyInCase ? (
        <span
          className={`flex text-sm items-center ${
            layout === "horizontal" ? "w-full md:w-1/2" : "w-full"
          }`}
        >
          <ProductIcon product={item} inCase={true} />
          <span>
            {getCaseQty(item.invoiceQty, item.invoiceQtyInCase, item)}
          </span>
          <div className="flex mx-1">
            <span className="text-sm text-gray-500">x</span>
          </div>

          <div className="flex">
            <span className="text-sm">
              {getCasePrice(item.invoicePrice, item.invoiceQtyInCase, item)}
            </span>
          </div>
          <div className="flex text-sm w-full xs:w-auto">
            <div className="flex justify-center mx-1">
              <span className="text-sm text-gray-500">=</span>
            </div>
            <span>{roundNumber(item.invoiceQty * item.invoicePrice, 3)}</span>
          </div>
        </span>
      ) : (
        <span
          className={`flex text-sm items-center ${
            layout === "horizontal" ? "w-full md:w-1/2" : "w-full"
          }`}
        >
          <ProductIcon product={item} inCase={false} />
          <span>
            {getUnitQty(item.invoiceQty, item.invoiceQtyInCase, item)}
          </span>
          <div className="flex mx-1">
            <span className="text-sm text-gray-500">x</span>
          </div>

          <div className="flex">
            <span className="text-sm">
              {getUnitPrice(item.invoicePrice, item.invoiceQtyInCase, item)}
            </span>
          </div>
          <div className="flex text-sm w-full xs:w-auto">
            <div className="flex justify-center mx-1">
              <span className="text-sm text-gray-500">=</span>
            </div>
            <span>{roundNumber(item.invoiceQty * item.invoicePrice, 3)}</span>
          </div>
        </span>
      )}
    </div>
  )
}

export default InvoicedProductItem
