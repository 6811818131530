import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from "react"
import EventBus from "services/events"

export type AsideContextType = {
  component: React.Component | FunctionComponent<any> | null
  props: any
  isOpen: boolean
  showAside(
    component: React.ReactNode | FunctionComponent<any>,
    props: any
  ): void
  hideAside(): void
}

export const AsideContext = createContext<AsideContextType>({
  component: null,
  props: {},
  isOpen: false,
  showAside: () => {},
  hideAside: () => {},
})

export const AsideProvider = (props: { children?: React.ReactChild }) => {
  const showAside = (component, props = {}) => {
    setAsideState({
      ...asideState,
      isOpen: true,
      component,
      props,
    })
  }

  const hideAside = () => {
    setAsideState({ ...asideState, isOpen: false })

    setTimeout(
      () =>
        setAsideState({
          ...asideState,
          component: null,
          props: {},
        }),
      400
    )
  }

  const [asideState, setAsideState] = useState({
    component: null,
    props: {},
    isOpen: false,
    showAside: showAside,
    hideAside: hideAside,
  })

  useEffect(() => {
    EventBus.on("logout", () => hideAside())
    return () => {
      EventBus.remove("logout", () => {
        console.log("logout listener removed from aside")
      })
    }
  }, [])

  return (
    <AsideContext.Provider value={asideState}>
      {props.children}
    </AsideContext.Provider>
  )
}

export const AsideConsumer = AsideContext.Consumer
