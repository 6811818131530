import React, { useState, useEffect, useContext } from "react"
import { ColumnShape, BaseTableProps } from "react-base-table"
import { navigate, Link } from "@reach/router"
import Helmet from "react-helmet"
import { useMediaQuery } from "react-responsive"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import AsyncSelect from "components/forms/AsyncSelect"

import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"

import { getSuppliers, searchSuppliers } from "services/suppliers/suppliers"

import { showError, showSuccess } from "services/toast"

import usePermissions from "hooks/usePermissions"

import { apiResponseIsError } from "../../../services/helpers"
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import usePagination from "hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import FiltersButton from "components/common/FiltersButton/FiltersButton"
import {
  getOrderTemplates,
  removeOrderTemplate,
} from "services/order-templates/order-templates"
import StandingOrderItemInfo from "./StandingOrderItemInfo"
import StandingOrderItemFrequency from "./StandingOrderItemFrequency"
import StandingOrderItemActions from "./StandingOrderItemActions"
import StandingOrderItemContent from "./StandingOrderItemContent"
import { Supplier, PageSupplier } from "services/suppliers/types"
import {
  OrderTemplateWithRecurrence,
  PageOrderTemplateWithRecurrence,
} from "services/order-templates/types"

interface OrderTemplateRow extends OrderTemplateWithRecurrence {
  children: OrderTemplateRowChild[]
}

interface OrderTemplateRowChild {
  id: string
  content?: OrderTemplateWithRecurrence
}

const StandingOrders = () => {
  const modal = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState<PageOrderTemplateWithRecurrence>({
    content: [],
  })
  const [supplierData, setSupplierData] = useState<PageSupplier>({
    content: [],
  })
  const [supplier, setSupplier] = useState<Supplier | null>(null)

  const { organization, selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const permissionObj = usePermissions("Orders")
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const pagination = usePagination()

  const hasAddress =
    organization &&
    organization.address &&
    organization.address.addressLine1 &&
    organization.address.city

  const hasDeliveryAddress =
    organization &&
    organization.deliveryAddress &&
    organization.deliveryAddress.addressLine1 &&
    organization.deliveryAddress.city

  const hasNoSuppliers = supplierData.content?.length === 0

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setOrderData({ content: [] })
      return
    }

    setLoading(true)

    const params: {
      page: number
      sort: string
      supplierId?: string | undefined
    } = {
      page: pagination.page,
      sort: "createdDate,desc",
    }

    if (supplier && "id" in supplier) {
      params.supplierId = supplier.id
    }

    const result = await getOrderTemplates(params)

    if (result && !("error" in result)) {
      setOrderData({ ...orderData, ...result })
      pagination.setFromResult(result)

      const suppliersResult = await getSuppliers()

      if (suppliersResult && !("error" in suppliersResult)) {
        setSupplierData({ ...supplierData, ...suppliersResult })
        setLoading(false)
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  // EFFECTS

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      getData()
    }
    return () => {
      isMounted = false
    }
  }, [pagination.page, supplier, selectedOrganizations])

  // ACTIONS

  const deleteOrderTemplate = async (orderTemplate) => {
    const result = await removeOrderTemplate(orderTemplate.id)

    if (apiResponseIsError(result)) {
      if (result?.message) {
        showError(result.message)
      } else {
        showError("Something went wrong...")
      }
    } else {
      showSuccess("Order template deleted!")
      getData()
    }
  }

  const onDelete = (order) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${order.po || "Order"}`,
      text: "Are you sure you want to delete this order template?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteOrderTemplate(order),
    })

  const handleActionClick = (action, orderTemplate) => {
    const { type } = action
    switch (type) {
      case "orderTemplate.edit":
        navigate(
          `/dashboard/purchases/orders/standing-orders/${orderTemplate.id}`
        )
        break
      case "orderTemplate.delete":
        onDelete(orderTemplate)
        break
      default:
        break
    }
  }

  // BASETABLE

  const columns: ColumnShape<OrderTemplateWithRecurrence>[] = [
    {
      key: "name",
      title: "Item",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <StandingOrderItemInfo orderTemplate={rowData} />
      ),
    },
    {
      key: "supplier",
      title: "Supplier",
      width: 150,
      dataKey: "supplier",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Link
          to={`/dashboard/purchases/suppliers/${rowData?.supplier?.id}`}
          className="text-primaryBlue truncate"
        >
          {rowData?.supplier?.name}
        </Link>
      ),
    },
    {
      key: "frequency",
      title: "Frequency",
      dataKey: "frequency",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <StandingOrderItemFrequency orderTemplate={rowData} />
      ),
    },
    {
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 60,
      className: "justify-end actions",
      cellRenderer: ({ rowData }) => (
        <StandingOrderItemActions
          orderTemplate={rowData}
          handleActionClick={handleActionClick}
        />
      ),
    },
  ]

  const formattedData = (data?: OrderTemplateWithRecurrence[]) => {
    if (!data) return []

    const formatted: OrderTemplateRow[] = data.map((item) => {
      return {
        ...item,
        children: [
          {
            id: `${item.id}-detail`,
            content: item.items && item,
          },
        ],
      }
    })

    return formatted
  }

  const rowRenderer: BaseTableProps<
    OrderTemplateRow | OrderTemplateRowChild
  >["rowRenderer"] = ({ rowData, cells }) => {
    if ("content" in rowData)
      return (
        <div className={"orderDetails w-full px-8 pb-8 itemContent"}>
          <StandingOrderItemContent orderTemplate={rowData.content} />
        </div>
      )
    return cells
  }

  return (
    <>
      <Helmet>
        <title>Standing orders</title>
      </Helmet>
      <div className="w-full flex flex-col h-full overflow-hidden">
        <div className="px-4 py-1 flex items-center flex-wrap border-b relative z-10 flex-shrink-0 lg:px-8">
          <FiltersButton>
            <AsyncSelect
              promise={searchSuppliers}
              placeholder={isTabletOrMobile ? "Supplier" : "All suppliers"}
              isClearable={true}
              optionLabel="name"
              optionValue="id"
              onChange={(val) => {
                setSupplier(val)
              }}
              className="w-56"
              name="suppliers"
              label="Suppliers"
            />
            <LocationsSelector />
          </FiltersButton>

          <div className="ml-auto space-x-4">
            <button
              className="button my-2 ml-auto button--autoWidth button--primaryGreen"
              onClick={(e) => {
                e.preventDefault()

                if (!hasDeliveryAddress && !hasAddress) {
                  return modal.showModal(ConfirmModal, {
                    type: "success",
                    title: "No address",
                    text: "To place an order a company and/or address is needed",
                    confirmButtonText: "Add address",
                    onConfirm: () => {
                      navigate("/dashboard/company-settings", {
                        state: { initialTab: "company-details" },
                      })
                    },
                  })
                }

                if (hasNoSuppliers) {
                  return modal.showModal(ConfirmModal, {
                    type: "success",
                    title: "No suppliers yet",
                    text: "You have no suppliers added yet. To place an order, please add a supplier.",
                    confirmButtonText: "Add supplier",
                    onConfirm: () => {
                      navigate("/dashboard/purchases/suppliers")
                    },
                  })
                }

                navigate("orders/standing-orders/new")
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
              <span className="hidden lg:inline">New standing order</span>
            </button>
          </div>
        </div>

        <div className="px-0 pb-6 -mt-px flex flex-grow w-full h-full overflow-auto lg:px-8 lg:py-6">
          <ExtBaseTable
            loading={loading}
            pagination={pagination}
            data={formattedData(orderData.content)}
            columns={columns}
            title="Standing orders"
            id="standingOrders"
            empty={{
              icon: faShoppingCart,
              color: "pink",
            }}
            estimatedRowHeight={200}
            expandColumnKey={"name"}
            expand={{}}
            rowRenderer={rowRenderer}
          />
        </div>
      </div>
    </>
  )
}

export default StandingOrders
