import React, { useState, useRef } from "react"
import { useClickAway } from "react-use"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import QuickActions from "components/dashboard/QuickActions/QuickActions"

import * as styles from "./QuickActionDropdown.module.css"

const cx = classNames.bind(styles)

const QuickActionDropdown = ({ mobile = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useClickAway(dropdownRef, () => {
    setIsOpen(false)
  })

  return (
    <div className={cx("container", { mobile: mobile })} ref={dropdownRef}>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.button}>
        <FontAwesomeIcon
          size="lg"
          icon={faPlus}
          transform={{ rotate: isOpen ? 45 : 0 }}
        />
      </button>
      <div
        className={cx("content", "quickActionDropdown", {
          hidden: !isOpen,
          mobile: mobile,
        })}
      >
        <div className="px-4 pb-2 border-b flex flex-col">
          <span className="font-sansSemiBold font-medium text-primaryBlue text-center lg:text-left">
            Quick actions
          </span>
        </div>
        <QuickActions onActionClicked={() => setIsOpen(false)} wrap={true} />
      </div>
      {mobile && (
        <div
          className={cx("overlay", { hidden: !isOpen })}
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </div>
  )
}

QuickActionDropdown.propTypes = {
  mobile: PropTypes.bool,
}

export default QuickActionDropdown
