import React, { useState, useEffect } from "react"
import { navigate, useLocation } from "@reach/router"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"

import * as queryString from "query-string"

import * as styles from "./SalesWrapper.module.css"
import Sales from "../Sales/Sales"

const SalesWrapper = () => {
  const location = useLocation()
  const initialTab: string = location?.state?.initialTab

  const [activeTab, setActiveTab] = useState(
    initialTab ? initialTab : "imported"
  )

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    if (queryParams.tab) {
      setActiveTab(queryParams.tab)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Sales</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Sales" />
        <ul className={styles.tabs}>
          <li className={activeTab === "imported" ? styles.activeTab : ""}>
            <button
              type="button"
              onClick={() => {
                navigate("?tab=imported")
              }}
            >
              Imported
            </button>
          </li>
          <li className={activeTab === "live" ? styles.activeTab : ""}>
            <button
              type="button"
              onClick={() => {
                navigate("?tab=live")
              }}
            >
              Live
            </button>
          </li>
        </ul>
        {activeTab === "imported" && <Sales live={false} />}
        {activeTab === "live" && <Sales live={true} showCreatedDate />}
      </div>
    </>
  )
}

export default SalesWrapper
