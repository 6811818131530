import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

import * as styles from "./Note.module.css"

const Note = ({ note = "", onChange, disabled = false }) => {
  const areaRef = useRef(null)
  const [message, setMessage] = useState(note)

  //
  // Handlers
  //

  const handleTextareaChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setMessage(value)
    onChange(value)
  }

  useEffect(() => {
    areaRef.current.focus()
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <textarea
        ref={areaRef}
        disabled={disabled}
        className={`form-control border-0 p-0 ${styles.textarea}`}
        rows={4}
        value={message || ""}
        onChange={handleTextareaChange}
        placeholder="Add notes about this stocktake.."
      ></textarea>
    </div>
  )
}

Note.propTypes = {
  onChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Note
