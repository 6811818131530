import React, { useContext } from "react"
import ReactDOMServer from "react-dom/server"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"
import { ModalContext } from "context/modal/ModalContext"
import ReactTooltip from "react-tooltip"

import VideoModal from "../VideoModal/VideoModal"

import * as styles from "./VideoHelpButton.module.css"
import { uuidv4 } from "services/helpers"
import useShowHelpButton from "hooks/useShowHelpButton"

const VideoHelpButton = () => {
  const modal = useContext(ModalContext)
  const showButton = useShowHelpButton()

  const openVideoHelpModal = () => {
    modal.showModal(VideoModal)
  }

  const uuid = uuidv4()

  return showButton ? (
    <>
      <ReactTooltip
        id={`videoButtonTooltip-${uuid}`}
        type="light"
        place="bottom"
        effect="solid"
        border={true}
        borderColor="#e2e8f0"
      />
      <button
        className={styles.button}
        onClick={openVideoHelpModal}
        data-tip={`${ReactDOMServer.renderToString(
          <FontAwesomeIcon
            className="text-xs text-primaryBlue mr-1"
            icon={faPlay}
          />
        )} See how it works!`}
        data-html={true}
        data-for={`videoButtonTooltip-${uuid}`}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    </>
  ) : null
}

export default VideoHelpButton
