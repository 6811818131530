import React from "react"
import Helmet from "react-helmet"

import Header from "components/dashboard/Header/Header"
import Orders from "./Orders/Orders"
import StandingOrders from "./StandingOrders/StandingOrders"
import { isDevelopment } from "services/constants"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"

const OrdersOverview = () => {
  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="w-full flex flex-col h-full overflow-hidden">
        <Header title="Orders" />
        <Tabs
          initialTab={"history"}
          className="growyze-tabs pt-4 px-2"
          connectSearchParamKey="tab"
          connectSearchParams
        >
          <Tab title="History" tabKey="history">
            <Orders />
          </Tab>
          <Tab
            title="Standing orders"
            tabKey="standing-orders"
            isAllowed={isDevelopment}
          >
            <StandingOrders />
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default OrdersOverview
