import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import { useContext, useEffect, useState } from "react"
import {
  deleteCategory,
  getCategories,
  updateCategory,
} from "services/categories"
import { showError } from "services/toast"
import { Category } from "services/types"

const useCategories = (
  filter?: (categories: Category[]) => any[]
): {
  categories: any[]
  loadingCategories: boolean
  updateCat(category: Category): Promise<void>
  removeCat(category: Category): Promise<void>
  getCategoriesData(): Promise<void>
} => {
  const dispatch = useContext(GlobalDispatchContext)
  const [loadingCategories, setLoading] = useState<boolean>(false)
  const { categories } = useContext(GlobalStateContext)

  const getCategoriesData = async () => {
    setLoading(true)
    const res = await getCategories()

    if (res?.message) {
      showError(res.message)
    } else {
      dispatch({ type: "SET_CATEGORIES", payload: res.content })
    }
    setLoading(false)
  }

  const updateCat = async (category: Category) => {
    setLoading(true)
    if (category.id) {
      const res = await updateCategory(category.id, category)
      dispatch({ type: "UPDATE_CATEGORY", payload: res })
    }
    setLoading(false)
  }

  const removeCat = async (category: Category) => {
    setLoading(true)
    if (category.id) {
      await deleteCategory(category.id)
      const newRes = categories.filter((c: Category) => c.id !== category.id)
      dispatch({ type: "UPDATE_CATEGORY", payload: newRes })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!categories.length) {
      getCategoriesData()
    }
  }, [])

  return {
    categories: filter ? filter(categories) : categories,
    updateCat,
    removeCat,
    loadingCategories,
    getCategoriesData,
  }
}

export default useCategories
