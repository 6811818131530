import React from "react"
import { Address } from "services/types"

const OrganizationLocationInfo = ({
  location,
}: {
  location: { address: Address; deliveryAddress?: Address }
}) => {
  return (
    <>
      <div>
        <h3 className="mb-2">Address:</h3>
        <ul className="text-sm">
          <li>{location?.address?.addressLine1}</li>
          <li>{location?.address?.addressLine2}</li>
          <li>
            {location?.address?.postCode} {location?.address?.city}{" "}
            {location?.address?.country}
          </li>
        </ul>
      </div>
      {location.deliveryAddress && (
        <div>
          <h3 className="mb-2">Delivery address:</h3>
          <ul className="text-sm">
            <li>{location?.deliveryAddress?.addressLine1}</li>
            <li>{location?.deliveryAddress?.addressLine2}</li>
            <li>
              {location?.deliveryAddress?.postCode} {location?.address?.city}{" "}
              {location?.address?.country}
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default OrganizationLocationInfo
