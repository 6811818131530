/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { countries } from "services/constants"

import { convertToFormat } from "services/helpers"
import AddressForm from "components/common/AddressForm/AddressForm"
import { AddressWithZoneId } from "services/types"
import {
  OrderRecurrenceRes,
  OrderTemplateWithRecurrence,
} from "services/order-templates/types"
import { RepetitionTypeEnum } from "services/order-templates/types"
import { formatRecurrenceDescription } from "services/order-templates/helpers"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import { faPaperPlane } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import usePagination from "hooks/usePagination"
import { OrderStep } from "../types"

interface Props {
  step: OrderStep
  orderTemplate: OrderTemplateWithRecurrence
  orderRecurrence: OrderRecurrenceRes
  isEditable: boolean
  placed: boolean
  editingAddress: boolean
  setOrderTemplate: React.Dispatch<
    React.SetStateAction<OrderTemplateWithRecurrence>
  >
  moveStep: (direction: "next" | "prev", currentStep: OrderStep) => void
  setEditingAddress: React.Dispatch<React.SetStateAction<boolean>>
}

const StandingOrderDetails = ({
  step,
  orderTemplate,
  orderRecurrence,
  isEditable,
  placed,
  editingAddress,
  setOrderTemplate,
  moveStep,
  setEditingAddress,
}: Props) => {
  const [saveAddressEnabled, setSaveAddressEnabled] = useState(false)
  const [addressData, setAddressData] = useState<AddressWithZoneId | undefined>(
    orderTemplate.deliveryAddress
  )

  const pagination = usePagination()

  const isMobile = useMediaQuery({ maxWidth: 639 })

  const items = orderTemplate.items?.filter((item) => item.quantity)
  const notes = orderTemplate.notes
  const supplier = orderTemplate.supplier

  const foundCountry = countries?.find((c) =>
    c.options.find((option) => option.value === addressData?.country)
  )

  const addressValidChange = (isValid) => {
    setSaveAddressEnabled(isValid)
  }

  const handleAddressChange = (data) => {
    setAddressData(data.nextValues)
  }

  const saveAddress = () => {
    setOrderTemplate({ ...orderTemplate, deliveryAddress: addressData })
    setEditingAddress(false)
  }

  const updateNotes = (value) => {
    setOrderTemplate({ ...orderTemplate, notes: value })
  }

  const standingOrderItemsColumns = (isDesktop: boolean) => {
    return [
      {
        key: "name",
        title: "Name",
        dataKey: "name",
        width: 200,
        flexGrow: 1,
        flexShrink: 0,
        className: "font-sansSemiBold font-semibold text-primaryBlue",
        cellRenderer: ({ rowData }) => (
          <div className="flex flex-col my-3">
            <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue">
              {rowData.name}
            </span>
          </div>
        ),
      },
      {
        key: "packaging",
        title: "Packaging",
        width: 90,
        dataKey: "packaging",
        className: "text-sm",
        flexGrow: 1,
        flexShrink: 0,
        cellRenderer: ({ rowData }) => {
          const hasCase = !!rowData.productCase
          const isOrderedAsCase = rowData.orderInCase

          return rowData.totalPrice ? null : (
            <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-2">
              {isOrderedAsCase && hasCase
                ? `${rowData.productCase.size} x ${rowData.size} ${rowData.measure}`
                : `${rowData.size} ${rowData.measure}`}
            </span>
          )
        },
      },
      {
        key: "quantity",
        title: "Qty",
        flexGrow: 1,
        flexShrink: 0,
        className: "text-sm",
        dataKey: "quantity",
        width: isDesktop ? 80 : 60,
      },
    ]
  }

  return (
    <div className="w-full flex flex-col p-4 lg:p-8">
      <div className="border rounded-lg">
        {placed && (
          <div className="flex flex-col items-center px-6 py-8 border-b">
            <h1 className="text-2xl text-primaryBlue mb-4">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-3" />
              Standing order placed!
            </h1>
            <p className="text-lg max-w-xl text-center">
              An email is on it’s way to the supplier to request your items.
              View your order details below
            </p>
          </div>
        )}

        <div className="flex flex-wrap items-center px-4">
          <h2 className="text-xl text-gray-700 my-4 mr-auto">
            New order for{" "}
            <span className="text-gray-900">{supplier && supplier.name}</span>
          </h2>
        </div>
        <div className="w-full h-auto">
          <ExtBaseTable
            data={items}
            columns={standingOrderItemsColumns(!isMobile)}
            emptyRenderer={
              <div className="flex items-center font-sansSemiBold font-semibold justify-center h-full text-xl text-center">
                No items yet
              </div>
            }
            rowKey="productId"
            pagination={pagination}
          />
        </div>
        <div className={`flex items-stretch ${isEditable ? "flex-wrap" : ""}`}>
          <div className="w-full px-4 my-4 md:w-1/2">
            <div className="form-group h-full flex flex-col">
              <label
                htmlFor="notes"
                className="font-semibold text-darkGray font-sansSemiBold block mb-4"
              >
                {isEditable ? "Add a note for this supplier" : "Notes"}
              </label>
              <div className="flex-grow">
                {isEditable ? (
                  <textarea
                    style={{ resize: "none" }}
                    className="form-control form-control--first form-control--last h-full w-full border-gray-300"
                    name="notes"
                    id="notes"
                    placeholder="Any notes, e.g. related to deliveries, etc"
                    value={notes ? notes : ""}
                    onChange={(e) => updateNotes(e.target.value)}
                  ></textarea>
                ) : (
                  <div className="flex flex-col w-full border-t border-gray-300 pt-4 pb-2 gap-1 leading-5">
                    {notes}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-4 my-4 flex flex-col items-start text-darkGray md:w-1/2">
            <>
              <label className="font-semibold font-sansSemiBold block mb-4">
                Delivery address
              </label>
              {addressData &&
                (!editingAddress ? (
                  <>
                    <div className="flex flex-col w-full border-t border-gray-300 pt-4 pb-2 gap-1 leading-5">
                      <span>{addressData.addressLine1}</span>
                      {addressData.addressLine2 && (
                        <span>{addressData.addressLine2}</span>
                      )}
                      <span>
                        {addressData.city} {addressData.postCode}
                      </span>
                      <span>
                        {foundCountry
                          ? foundCountry.label
                          : addressData.country}
                      </span>
                    </div>
                    {isEditable ? (
                      <button
                        className="button--autoWidth text-primaryPink font-sansSemiBold font-semibold mb-4"
                        onClick={() => setEditingAddress(true)}
                      >
                        Change address
                      </button>
                    ) : null}
                  </>
                ) : (
                  <div className="-mt-2">
                    <AddressForm
                      initialValues={addressData}
                      onValidChange={(isValid) => addressValidChange(isValid)}
                      onDataChange={handleAddressChange}
                    />
                    <button
                      disabled={!saveAddressEnabled}
                      className="button button--primaryGreen"
                      onClick={saveAddress}
                    >
                      Save
                    </button>
                  </div>
                ))}
            </>
          </div>
          <div
            className={`w-full px-4 my-4 text-darkGray md:w-1/2 ${
              isEditable ? "mt-0" : ""
            }`}
          >
            <div className="form-group">
              <label
                htmlFor="notes"
                className="font-semibold text-darkGray font-sansSemiBold block mb-4"
              >
                Order recurrence
              </label>
              <div className="flex flex-col w-full border-t border-gray-300 pt-4 pb-2 gap-1 leading-5">
                <p>{formatRecurrenceDescription(orderRecurrence)}</p>
                {orderRecurrence?.repetitionType ===
                  RepetitionTypeEnum.WEEKLY &&
                  orderRecurrence?.weeklyRepetition?.dayOfWeek && (
                    <p>
                      On{" "}
                      <span className="capitalize">
                        {orderRecurrence?.weeklyRepetition?.dayOfWeek
                          .toString()
                          .toLowerCase()}
                      </span>
                    </p>
                  )}
                <p>
                  <>At {orderRecurrence?.orderTime}</>
                </p>
                {orderRecurrence?.startDate && (
                  <p>Starts on {convertToFormat(orderRecurrence?.startDate)}</p>
                )}
                {orderRecurrence?.endDate && (
                  <p>Ends on {convertToFormat(orderRecurrence?.endDate)}</p>
                )}
              </div>
              {isEditable ? (
                <button
                  className="button--autoWidth text-primaryPink font-sansSemiBold font-semibold"
                  onClick={() => moveStep("prev", step)}
                >
                  Change recurrence
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StandingOrderDetails
