import React from "react"

//@ts-ignore
import * as styles from "./PrintDish.module.css"
import { Dish, DishElementType } from "../../../services/dishes"
import SimpleTable from "components/orders/SimpleTable/SimpleTable"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import ServerImage from "components/common/ServerImage/ServerImage"
import { allergens } from "services/constants"
import { findValueLabel } from "../../../services/helpers"

const typesToProps: Record<DishElementType, string> = {
  INGREDIENT: "ingredient",
  RECIPE: "recipe",
  OTHER_INGREDIENT: "otherIngredient",
}
const unwrapElement = (element) => element[typesToProps[element.type]]

const compose =
  (f, g) =>
  (...args) =>
    f(g(...args))

const presentElement = (element: any) => ({
  Ingredient: element?.product?.name || element?.recipe?.name || element?.name,
  Qty: [element.usedQty, element.measure].join(" "),
})

const convertElement = compose(presentElement, unwrapElement)

export default ({ values, image }: { values: Dish; image: any }) => (
  <div className={styles.container}>
    <h1>{values.name}</h1>
    {/*
    <div className={styles.imageWrapper}>
      {image != undefined ? (
        <ServerImage fileId={image.fileId}></ServerImage>
      ) : undefined}
    </div>
      */}

    <div className="my-7">
      <CostProfitChart
        cost={values.totalCost}
        profit={values.profit}
        costPercentage={values.totalCostPercent}
        profitPercentage={values.profitPercent}
      />
    </div>
    <div className={styles.ingredientsContainer}>
      {image != undefined ? (
        <div className={styles.insideImageWrapper}>
          <ServerImage fileId={image.fileId}></ServerImage>
        </div>
      ) : undefined}
      {(values.allergens?.length > 0 ||
        values.mayContainAllergens?.length > 0) && (
        <div>
          <h2>Allergens</h2>
          <h4>Contains</h4>
          {values.allergens
            ?.map((allergen) => findValueLabel(allergens, allergen))
            .join(", ")}

          {values.mayContainAllergens?.length > 0 && (
            <>
              <h4>May contain</h4>
              {values.mayContainAllergens
                ?.map((allergen) => findValueLabel(allergens, allergen))
                .join(", ")}
            </>
          )}
        </div>
      )}
      {values.method && (
        <div>
          <h2>Method</h2>
          <div className={styles.textField}>{values.method}</div>
        </div>
      )}
      {values.sections?.map(({ name, elements }, index) => (
        <div key={name ? name : `PrintDish-${index}`}>
          <h2>{name}</h2>
          <SimpleTable
            style={{ tableLayout: "auto", borderRadius: "0.375rem" }}
            props={elements.map(convertElement)}
          ></SimpleTable>
        </div>
      ))}
    </div>

    {values.notes && (
      <div>
        <h2>Notes</h2>
        <div className={styles.textField}>{values.notes}</div>
      </div>
    )}
  </div>
)
