import InitialContext from "context/global/InitialContext"
import { isDevelopment } from "services/constants"

const mainReducer = (state, action) => {
  /** Log actions for development purposes */
  if (isDevelopment) {
    console.info(`ACTION: ${action.type}`)
  }
  switch (action.type) {
    case "RESET_STATE": {
      return InitialContext
    }
    case "SET_CATEGORIES": {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case "UPDATE_CATEGORY": {
      return {
        ...state,
        categories: state.categories.map((cat) => {
          if (cat.id === action.payload.id) {
            return action.payload
          }
          return cat
        }),
      }
    }
    default: {
      return state
    }
  }
}

export default mainReducer
