import React, { useState } from "react"
import EmptyListState from "components/base/EmptyListState"

const SetEmptyListState = (props, forceProps) => {
  const { empty } = props
  const [emptyData, setEmptyData] = useState(null)
  let tmpEmptyData = null

  if (!props.data?.length && !props?.loading) {
    forceProps.className =
      (props.className ?? "") + " pointer-events-none dummy-list"
    forceProps.footerHeight = 180
    forceProps.emptyList = (
      <EmptyListState {...props.empty} title={props.title} />
    )

    if (emptyData == null) {
      // Yes, this is correct: empty?.data()()
      // it reaturn a call back with one function
      tmpEmptyData = empty?.getDummy ? empty?.getDummy()() : []
      setEmptyData(tmpEmptyData)
    }

    forceProps.data = tmpEmptyData ?? emptyData
  } else {
    forceProps.emptyList = <></>
  }

  return forceProps
}
export default SetEmptyListState
