import {
  faEdit,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import EditPreferredProduct from "components/suppliers/EditPreferredProduct/EditPreferredProduct"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import { AsideContext } from "context/aside/AsideContext"
import React, { useContext, useState } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import {
  getProductsByGroupId,
  unmarkAsSameItem,
} from "services/products/products"

interface UnmarkButtonOptionType {
  type: "single" | "group"
  label: string
  description?: string
  disabled?: boolean
  clickHandler?: () => void
}

function ModalUnmarkItems({ item, groupChildren, onRequestClose, onSuccess }) {
  const aside = useContext(AsideContext)

  const [selectedProduct, setSelectedProduct] = useState(item)
  const [unmarkSucces, setDeleteSuccess] = useState(false)

  const childrenWithSameBarcode = groupChildren.filter(
    (a: any) => !a.preferred && a.barcode === selectedProduct.barcode
  )

  const startEditProduct = async (product: any, onAsideClose) => {
    const values = { ...product, generateBarcode: false }

    if (product.preferred) {
      const products = product.children
        ? [product, ...product.children]
        : await getProductsByGroupId(product.groupId)
      aside.showAside(EditPreferredProduct, {
        products: products,
        // fetchProducts: getData,
        onClose: () => {
          aside.hideAside()
        },
      })
    } else {
      aside.showAside(EditProduct, {
        headerText: "Edit product",
        initialValues: values,
        onClose: onAsideClose ? onAsideClose : aside.hideAside,
      })
    }
  }

  //Universal approach - Prints out messages from BE
  const handler = (type) =>
    unmarkAsSameItem(item.id, type).then((data) => {
      const { status, message } = data
      if (typeof data === "object" && data?.status) {
        const isError =
          (data?.status && status.toString().startsWith("4")) ||
          status.toString().startsWith("5")
        if (isError)
          return toast.error(
            message || "There was an error when unmarking item as same"
          )
      } else if (data === true || data?.id) {
        onSuccess()
        setSelectedProduct(data)
        return toast.success(message || "Succesfully unmarked an item")
      }
    })

  const options: UnmarkButtonOptionType[] = [
    {
      type: "single",
      label: "Unmark this product",
      clickHandler: () => {
        handler("single").then(() => setDeleteSuccess(true))
      },
      description: groupChildren.length
        ? `1 out of ${groupChildren.length} sub products`
        : "",
    },
    {
      type: "group",
      label: `Unmark all with same barcode`,
      description: groupChildren.length
        ? `${childrenWithSameBarcode.length} out of ${groupChildren.length} sub products.`
        : "",
      clickHandler: () => handler("group").then(() => onRequestClose()),
      disabled: childrenWithSameBarcode.length < 2,
    },
  ]

  return (
    <Modal
      onRequestClose={onRequestClose}
      portalClassName="confirm" //Re-using a class for dynamic height
      isOpen
    >
      <div className="p-6 h-full flex flex-col rounded-md">
        <button
          type="button"
          onClick={onRequestClose as React.MouseEventHandler}
          className="text-gray-700 absolute right-0 px-5 -mt-2"
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </button>

        <h2 className="text-xl">Unmark selected item(s)</h2>
        <p>
          This action will unmark either currently selected product or all
          products with the same barcode.
        </p>
        <span className="text-black bg-gray-300 rounded-xl py-1 px-2 text-sm self-start mt-4">
          {" "}
          <FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />
          Preferred product will not be affected by this action.
        </span>

        <h3 className="text-primaryBlue font-semibold mt-8">Selected item:</h3>

        <div className="flex items-center flex-col pb-4">
          <ul className="p-4 border rounded-sm mt-2 bg-gray-100 w-full">
            <li className="font-semibold">{selectedProduct.name}</li>
            <li className="text-gray-600">{selectedProduct.barcode}</li>
            <li>{selectedProduct.supplierName}</li>
          </ul>

          {false === unmarkSucces && (
            <div className="text-white flex space-x-2 mt-6 w-full">
              {options.map((opt, idx) => {
                return (
                  <div
                    className="flex flex-1 flex-col relative"
                    key={idx + "ModalUnmarkItems-option"}
                  >
                    <button
                      disabled={opt.disabled}
                      className="bg-primaryBlue hover:bg-opacity-75 transition-colors rounded-md p-2 px-3 cursor-pointer font-semibold w-full"
                      type="button"
                      onClick={opt.clickHandler}
                    >
                      <span>{opt.label}</span>
                    </button>
                    {opt.description && (
                      <span className="text-gray-600 inline-block text-xs text-center mt-2">
                        {opt.description}
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
          )}

          {true === unmarkSucces && (
            <>
              {/* <h3 className="text-primaryBlue font-semibold mt-6 mb-4">Congratulations</h3> */}
              <div className="text-black flex flex-col space-y-4 items-center space-x-2 w-full bg-gray-100 border p-8 rounded-bl-lg rounded-br-lg">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-500 text-4xl"
                />
                <h3 className="mb-1">Item(s) sucessfully unmarked</h3>
                <span className="text-gray-600">
                  Product&apos;s barcode is now <b>{selectedProduct.barcode}</b>
                </span>
                <div className="flex space-x-2">
                  <button
                    className="bg-primaryBlue px-3 py-2 hover:bg-opacity-80 transition-colors text-white rounded-md text-sm space-x-1"
                    onClick={() => {
                      onRequestClose()
                      startEditProduct(selectedProduct, () => aside.hideAside())
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    <span className="text-xs font-semibold">Edit item</span>
                  </button>
                  <button
                    className="bg-gray-700 bg-opacity-50 hover:bg-opacity-40 transition-colors px-3 py-2 text-white rounded-md text-sm space-x-1"
                    onClick={onRequestClose}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    <span className="text-xs font-semibold">Close</span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalUnmarkItems
