import React, { useContext } from "react"
import * as styles from "./FreemiumUpgradeSection.module.css"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import UpgradeButton from "../UpgradeButton/UpgradeButton"

interface IFreemiumUpgradeSection {
  theme: "light" | "dark"
  onButtonClick?(): void
}

const FreemiumUpgradeSection = ({
  theme = "dark",
  onButtonClick,
}: IFreemiumUpgradeSection) => {
  const { paymentPlan } = useContext(GlobalStateContext)

  return paymentPlan?.type === "FREEMIUM" ? (
    <div
      className={`${styles.upgradeSection} ${
        theme === "light" ? styles.light : ""
      }`}
    >
      <h3>You&apos;re on Free plan</h3>
      <UpgradeButton theme={theme} onClick={onButtonClick} />
    </div>
  ) : null
}

export default FreemiumUpgradeSection
