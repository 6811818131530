import React, { useContext } from "react"
import { navigate, Link } from "@reach/router"
import { formatAsYearMonthDay } from "services/helpers"
import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as styles from "./OrderItemInfo.module.css"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

const OrderItemInfo = ({ order }) => {
  const { organization } = useContext(GlobalStateContext)

  return (
    <div className="flex w-auto items-center">
      <div
        className={styles.iconWrapper}
        style={{
          backgroundColor: "#FFE5F0",
          color: "#FC3762",
        }}
      >
        <FontAwesomeIcon icon={faShoppingCart} />
      </div>
      <button
        onClick={(e) => {
          e.preventDefault()
          navigate(`/dashboard/purchases/orders/${order.id}`)
        }}
        style={{ minWidth: "180px", textAlign: "left" }}
        className="font-sansSemiBold flex flex-col flex-grow focus:outline-none font-semibold text-primaryBlue py-3 px-3"
        disabled={
          order.organizations.length &&
          order.organizations[0] !== organization.id
        }
      >
        <p>
          {order.status === "REVIEW" && (
            <span className="opacity-50">[{order.status}]</span>
          )}
          <span>
            {order.po
              ? `#${order.po}`
              : formatAsYearMonthDay(new Date(order.createdDate))}
          </span>
        </p>
        <p className="text-xs text-gray-700">
          <FontAwesomeIcon icon={faClock} size="sm" />{" "}
          {formatAsYearMonthDay(new Date(order.createdDate))}
        </p>
        <Link
          to={`/dashboard/purchases/suppliers/${order?.supplier?.id}`}
          className="lg:hidden text-gray-700 font-normal font-sans text-sm truncate"
        >
          {order.supplier.name}
        </Link>
      </button>
    </div>
  )
}

export default OrderItemInfo
