import FormDropdown from "components/forms/FormDropdown"
import React from "react"
import { AccountingProvider, useAccountingProviders } from "services/accounting"
import AccountingProductForm from "./AccountingProductForm"

interface AccountingProductFormsProps {
  product: any
  setProductAccountingParams: any
}

const AccountingProductForms = (props: AccountingProductFormsProps) => {
  const { product, setProductAccountingParams } = props
  const { providers } = useAccountingProviders()

  const forms = Object.values(providers).map(
    (provider: AccountingProvider, i) => {
      if (provider?.id) {
        return (
          <FormDropdown
            buttonText={
              <span className="flex flex-row items-center">
                Accounting {provider.title}
                <img
                  src={provider.logo}
                  className="w-4 h-auto ml-2"
                  alt="Provider logo"
                />
              </span>
            }
            fullWidth={true}
            key={i}
          >
            <div className="form-group">
              <div className="input-container">
                <AccountingProductForm
                  provider={provider}
                  product={product}
                  setProductAccountingParams={setProductAccountingParams}
                />
              </div>
            </div>
          </FormDropdown>
        )
      }
    }
  )

  return <>{forms}</>
}

export default AccountingProductForms
