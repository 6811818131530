import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, FormikContext } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { usePrevious } from "react-use"
import { decimalCount, formatQuantityValue } from "services/helpers"

interface QuantityProps {
  value: number | "" | undefined
  label?: string
  fieldName: string
  onChange: Function
  placeholder?: string
  allowNegative?: boolean
  allowDecimal?: boolean
  inForm?: boolean
  classes?: {
    button?: string
    field?: string
    fieldWidth?: string
    label?: string
    container?: string
  }
  addClasses?: any
  displayStyle?: "form" | "rounded"
}

const defaultClasses = {
  container: "input-container w-full flex",
  label: " form-label w-20 pr-2",
  button: "form-control w-12",
  field: "form-control text-center",
  fieldWidth: "w-32",
}

const roundedClasses = {
  container: "flex py-1 items-center justify-center",
  label:
    "font-sansSemiBold text-right font-semibold text-sm text-gray-800 w-20 pr-2",
  button:
    "w-8 h-8 flex-shrink-0 rounded-full flex items-center justify-center border border-primaryGreen text-primaryGreen transition-colors ease-in-out duration-200 hover:text-white hover:bg-primaryGreen rounded-btn-disabled",
  field:
    "border rounded-3xl self-stretch font-sansSemiBold font-semibold flex items-center justify-center text-center mx-2",
  fieldWidth: "w-20",
}

const Quantity = (props: QuantityProps) => {
  const {
    value,
    label = "",
    fieldName,
    onChange,
    placeholder = "",
    allowNegative = true,
    allowDecimal = true,
    classes = {},
    addClasses = {},
    displayStyle = "form",
  } = props

  const inFormik = useContext(FormikContext) ? true : false
  const [currentValue, setCurrentValue] = useState(value)
  const previousValue = usePrevious(currentValue)
  const shouldFloat = value !== undefined && !Number.isInteger(Number(value))
  const step =
    shouldFloat && allowDecimal ? (decimalCount(value) >= 2 ? 0.01 : 0.1) : 1
  const currentClasses =
    displayStyle === "rounded"
      ? { ...defaultClasses, ...roundedClasses, ...classes }
      : { ...defaultClasses, ...classes }

  Object.entries(addClasses).forEach(([key, val]) => {
    if (currentClasses[key]) {
      currentClasses[key] += ` ${val}`
    }
  })

  const formated = (val) => {
    if (allowDecimal) {
      return formatQuantityValue(val)
    } else {
      return Math.round(Number(val))
    }
  }

  const handleWheel = (event) => {
    event.target.blur()
  }

  const handleKeyDown = (event) => {
    // prevent comma and dot keys
    if (event.keyCode === 188) {
      event.preventDefault()
      return
    }

    // Escape key removes value
    if (event.keyCode === 27) {
      event.target.blur()
      setCurrentValue(formated(0))
    }
  }

  const add = (dir) => {
    let newValue = (value ?? 0) + (dir == "min" ? -step : step)
    if (!allowNegative && newValue < 0) {
      newValue = 0
    }
    setCurrentValue(formated(newValue))
  }

  const handleChange = (val) => {
    setCurrentValue(formated(val))
  }

  const fieldOptions = {
    name: fieldName,
    id: fieldName,
    value: value,
    className: currentClasses?.field,
    placeholder: placeholder,
    min: allowNegative ? undefined : 0,
    step: step,
    type: "number",
    onChange: (e) => handleChange(e.target.value),
    onBlur: (e) => handleChange(e.target.value),
    onWheel: handleWheel,
    onKeyDown: handleKeyDown,
  }

  useEffect(() => {
    if (previousValue !== null && value !== currentValue) {
      setCurrentValue(value)
    }
  }, [value])

  useEffect(() => {
    onChange(formated(currentValue))
  }, [currentValue])

  return (
    <>
      <div className={currentClasses.container}>
        {label && <span className={currentClasses.label}>{label}</span>}
        <button
          type="button"
          disabled={!allowNegative && currentValue === 0}
          onClick={() => add("min")}
          className={`pre disabled:opacity-50 disabled:pointer-events-none ${currentClasses.button}`}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        {inFormik ? <Field {...fieldOptions} /> : <input {...fieldOptions} />}
        <button
          type="button"
          onClick={() => add("plus")}
          className={`suf disabled:opacity-50 disabled:pointer-events-none ${currentClasses.button}`}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </>
  )
}

export default Quantity
