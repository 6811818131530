// extracted by mini-css-extract-plugin
export var additionalQtyInput = "AddProductForm-module--additionalQtyInput--llC9q";
export var content = "AddProductForm-module--content--Bxgwj";
export var formBottom = "AddProductForm-module--formBottom--nh-mX";
export var header = "AddProductForm-module--header--yBFZH";
export var inputCol = "AddProductForm-module--inputCol--DMYfd";
export var measures = "AddProductForm-module--measures--BZnPF";
export var percentage = "AddProductForm-module--percentage---V78N";
export var productMeta = "AddProductForm-module--productMeta--KVYNB";
export var productName = "AddProductForm-module--productName--hWPif";
export var roundButton = "AddProductForm-module--roundButton--B8EYX";
export var summary = "AddProductForm-module--summary--KNeQG";
export var summaryItem = "AddProductForm-module--summaryItem--2bxnG";
export var summaryItemList = "AddProductForm-module--summaryItemList--vx8tl";
export var textInput = "AddProductForm-module--textInput--Xp9wK";
export var totalValue = "AddProductForm-module--totalValue--JDBaQ";