/* eslint-disable */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons"
import * as styles from "./ConfirmDetailsMessages.module.css"

const ConfirmDetailsMessages = ({ invoice }) => {
  const [collapse, setCollapse] = useState(false)

  const hasDiscrepancies = () => {
    return (
      invoice.hasQtyDiscrepancies ||
      invoice.hasNoReceivedProducts ||
      invoice.hasProductPriceDiscrepancies ||
      invoice.hasNoOrderedProducts ||
      invoice.hasTotalCostDiscrepancy
    )
  }

  const countDiscrepancies = () => {
    let total = 0

    if (invoice.hasQtyDiscrepancies) total += 1
    if (invoice.hasNoReceivedProducts) total += 1
    if (invoice.hasProductPriceDiscrepancies) total += 1
    if (invoice.hasNoOrderedProducts) total += 1
    if (invoice.hasTotalCostDiscrepancy) total += 1

    return total
  }

  const toggleCollapse = () => setCollapse(!collapse)

  const renderErrorMessages = (
    <>
      {invoice.hasQtyDiscrepancies && (
        <li className="py-1">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 mt-1" />
          There is a discrepancy between the delivery and the invoiced items.
        </li>
      )}
      {invoice.hasProductPriceDiscrepancies && (
        <li className="py-1">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 mt-1" />
          There is a discrepancy between the product price and the invoice
          price.
        </li>
      )}
      {invoice.hasNoReceivedProducts && (
        <li className="py-1">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 mt-1" />
          Some items you ordered were not delivered. Check you have the correct
          invoiced quantity.
        </li>
      )}
      {invoice.hasNoOrderedProducts && (
        <li className="py-1">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 mt-1" />
          There are items on the invoice that were not received.
        </li>
      )}
      {invoice.hasTotalCostDiscrepancy && (
        <li className="py-1">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 mt-1" />
          You have a discrepancy between your invoice cost and expected total
          cost from delivery.
        </li>
      )}
    </>
  )

  const hasMultipleErrors = countDiscrepancies() > 1

  return (
    <>
      {invoice.supplier && hasDiscrepancies() && (
        <div className={styles.warningContainer}>
          <div className={styles.warning}>
            <ul className={styles.warningItems}>
              {hasMultipleErrors ? (
                <>
                  <li className="py-1 font-sansBold">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-2 mt-1"
                    />
                    There are discrepancies, please check and confirm!
                  </li>
                  {collapse && renderErrorMessages}
                </>
              ) : (
                renderErrorMessages
              )}
            </ul>
            {hasMultipleErrors && (
              <div className="flex py-1">
                <FontAwesomeIcon
                  icon={collapse ? faChevronUp : faChevronDown}
                  className="mr-2 mt-1 cursor-pointer"
                  size="lg"
                  onClick={toggleCollapse}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

ConfirmDetailsMessages.propTypes = {
  invoice: PropTypes.object,
}

export default ConfirmDetailsMessages
