// extracted by mini-css-extract-plugin
export var bottomSummary = "GPReport-module--bottomSummary--evqPS";
export var chartBar = "GPReport-module--chartBar--37Dfv";
export var chartContainer = "GPReport-module--chartContainer--r5je8";
export var container = "GPReport-module--container--vHPOd";
export var content = "GPReport-module--content--l1OM-";
export var header = "GPReport-module--header--pP7OJ";
export var headerColumns = "GPReport-module--headerColumns--nNH9-";
export var headerContent = "GPReport-module--headerContent--jHm3a";
export var headerRight = "GPReport-module--headerRight--Ewaq8";
export var headerTopColumn = "GPReport-module--headerTopColumn--rD0+6";
export var indicator = "GPReport-module--indicator--bFNEL";
export var indicatorActual = "GPReport-module--indicatorActual--SLpM8";
export var indicatorExpected = "GPReport-module--indicatorExpected--XCKi5";
export var indicatorVariance = "GPReport-module--indicatorVariance--9FQQ1";
export var reportContent = "GPReport-module--reportContent--ebXWa";
export var statContainer = "GPReport-module--statContainer--7w51v";
export var statsRow = "GPReport-module--statsRow--eEoXG";
export var statsWrapper = "GPReport-module--statsWrapper--1C0DK";
export var summaryItem = "GPReport-module--summaryItem--10YRR";
export var summaryItemLabel = "GPReport-module--summaryItemLabel--iNPaY";
export var summaryItemValue = "GPReport-module--summaryItemValue--zMjcn";
export var thumbnail = "GPReport-module--thumbnail--kjTky";
export var thumbnailBadge = "GPReport-module--thumbnailBadge--fo7zn";