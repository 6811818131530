import React, { useContext, useEffect, useState } from "react"
import classNames from "classnames/bind"
import { useDropzone } from "react-dropzone"
import { downloadStorageFile } from "services/storage"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import { upload } from "services/file-processing"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { convertTimeStampFormat } from "services/helpers"
import { ModalContext } from "context/modal/ModalContext"
import { showError } from "services/toast"

import * as styles from "../FileList/FileList.module.css"
import Loader from "../Loader/Loader"

const cx = classNames.bind(styles)

type FileListProps = {
  files: any
  clientKey: string
  isLoading?: boolean
  onUploaded?: any
  onDeleted?: any
  extractedFile?: any
  disabled?: boolean
  filesOwner?: any
  modifyPermission?: boolean
}

const FileList = ({
  files,
  clientKey,
  isLoading,
  onUploaded,
  onDeleted,
  extractedFile = null,
  disabled = false,
  filesOwner = null,
  modifyPermission = true,
}: FileListProps) => {
  const [loading, setLoading] = useState(isLoading ? isLoading : false)
  const { organization } = useContext(GlobalStateContext)

  const modal = useContext(ModalContext)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const onDrop = async (acceptedFiles) => {
    setLoading(true)
    // Do something with the files
    if (acceptedFiles.length > 0) {
      let isLast = false
      const uploadedFiles: any = []

      for (let i = 0; i < acceptedFiles.length; i++) {
        const formData = new FormData()

        const params = {
          client: clientKey,
        }

        const currentFile = acceptedFiles[i]

        formData.append("file", currentFile)

        const uploaded = await upload(formData, params)

        isLast = acceptedFiles.length - 1 === i

        if (uploaded && !uploaded.message) {
          uploadedFiles.push(uploaded)
        }

        if (isLast) {
          setLoading(false)
          onUploaded(uploadedFiles)
        }
      }
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: loading || disabled,
    maxSize: 10000000, // 10MB limit
    onDrop,
    onDropRejected: (errors) => {
      setLoading(false)
      errors.forEach((e) => {
        showError(`${e.errors[0].message} for file ${e.file.name}`)
      })
    },
  })

  const onDownload = (file) => {
    downloadStorageFile(file.fileId, file.fileName, filesOwner)
  }

  const onDeleteFile = async (file) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete file?",
      text: "Are you sure you want to delete this file?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        onDeleted(file)
      },
    })
  }

  return (
    <div>
      {clientKey && (
        <>
          {!modifyPermission ? (
            <div className="border rounded-md p-4 mb-2 bg-error bg-opacity-20">
              <p>
                You can only view already uploaded files on this account and
                page. To edit or upload, either go to the sub-account or to the
                deliveries page.
              </p>
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={cx("baseStyle", {
                loading: loading,
                focusedStyle: isFocused,
                acceptStyle: isDragAccept,
                rejectStyle: isDragReject,
              })}
            >
              <Loader isLoading={loading} />
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here (max 10MB) ...</p>
              ) : screenWidth < 500 ? (
                <p>Click to select files (max 10MB)</p>
              ) : (
                <p>Drag some files here, or click to select files (max 10MB)</p>
              )}
            </div>
          )}
        </>
      )}

      {(extractedFile || files?.length > 0) && (
        <ul className={styles.fileList}>
          {extractedFile && (
            <li key={extractedFile.fileId} className={styles.item}>
              <button
                onClick={() => onDownload(extractedFile)}
                className={styles.itemButton}
              >
                <span className={styles.fileName}>
                  {extractedFile.fileName}
                </span>
                {extractedFile.createdAt && (
                  <span className={styles.fileDate}>
                    {convertTimeStampFormat(
                      extractedFile.createdAt,
                      organization?.address.zoneId
                    )}
                  </span>
                )}
              </button>
              <span className={styles.manualBadge}>Extracted</span>
            </li>
          )}
          {files?.length > 0 &&
            files.map((file) => {
              if (file.extractedFile) {
                return null
              }
              return (
                <li key={file.fileId} className={styles.item}>
                  <button
                    type="button"
                    onClick={() => onDownload(file)}
                    className={styles.itemButton}
                  >
                    <span className={styles.fileName}>{file.fileName}</span>
                    {file.createdAt && (
                      <span className={styles.fileDate}>
                        {convertTimeStampFormat(
                          file.createdAt,
                          organization?.address.zoneId
                        )}
                      </span>
                    )}
                  </button>
                  {modifyPermission && (
                    <button
                      type="button"
                      className="button button--autoWidth no-truncate button--transparent-pink"
                      disabled={disabled}
                      onClick={() => onDeleteFile(file)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  )}
                </li>
              )
            })}
        </ul>
      )}

      {(!files || files?.length == 0) && !extractedFile && clientKey && (
        <ul className={styles.fileList}>
          <li className={styles.noItems}>No files yet</li>
        </ul>
      )}
    </div>
  )
}

export default FileList
