import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
} from "react"
import BodyPortal from "components/common/BodyPortal/BodyPortal"
import { useState } from "react"

interface ModalConfig {
  id: string
  comp:
    | FunctionComponent<any>
    | ReactNode
    | ((onRequestClose: any) => React.JSX.Element)
  props: any
}

interface ExtraModalProps {
  [key: string]: any
}

export const useModals = (comps: ModalConfig[]) => {
  const processedComps = useMemo(() => {
    return comps.map(({ id, comp, props }) => {
      return { id, comp, props }
    })
  }, [comps])
  // list of active modalIds
  const [visibleModals, setVisibleModals] = useState<string[]>([])
  const [extraModalProps, setExtraModalProps] = useState<ExtraModalProps>()


  const showModal = useCallback((modalId: string, props?: any) => {
    if (props && Object.keys(props).length) {
      setExtraModalProps((prev) => ({ ...prev, [modalId]: props }))
    }
    setVisibleModals((prev) => [...prev, modalId])
  }, [])

  const hideModal = useCallback((modalId) => {
    setVisibleModals((prev) => prev.filter((id) => id !== modalId))
  }, [])

  const RenderModals = useMemo(() => {
    return () => {
      return (
        <BodyPortal id="modal-root">
          {processedComps.map(({ id, comp, props }) => {
            const isVisible = visibleModals.includes(id)
            const extraProps = extraModalProps && extraModalProps[id]
            const ModalComp = comp
            return isVisible ? (
              // @ts-ignore
              <ModalComp
                key={id}
                onRequestClose={(data) => {
                  hideModal(id)
                  if (props.onClose) {
                    props.onClose(data)
                  }
                }}
                {...props}
                {...extraProps}
              />
            ) : null
          })}
        </BodyPortal>
      )
    }
  }, [visibleModals, extraModalProps, processedComps])

  return { showModal, hideModal, RenderModals }
}
