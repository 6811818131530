import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"

import {
  header,
  content,
  footer,
  cancelButton,
  container,
} from "./SupplierSelectModal.module.css"

const SupplierSelectModal = ({
  onRequestClose,
  onSelect,
  noSupplierAllowed = false,
  ...otherProps
}) => {
  const [supplier, setSupplier] = useState({})

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <div className={container}>
        <div className={header}>
          <h2 className="text-lg">Select supplier</h2>
        </div>
        <div className={content}>
          <div className="px-2 w-full">
            <SupplierSelect
              onSelect={setSupplier}
              noSupplierAllowed={noSupplierAllowed}
            />
          </div>
        </div>
        <div className={footer}>
          <button className={cancelButton} onClick={onRequestClose}>
            Close
          </button>
          <button
            className="button ml-auto button--autoWidth button--primaryGreen"
            onClick={() => {
              onSelect(supplier)
              onRequestClose()
            }}
          >
            <span className="inline">Select</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

SupplierSelectModal.propTypes = {
  onRequestClose: PropTypes.func,
  onSelect: PropTypes.func,
}

export default SupplierSelectModal
