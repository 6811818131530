import React, { useContext, useState } from "react"
import { Formik, Form } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import Modal from "react-modal"
import * as Yup from "yup"
import TextInput from "../../forms/TextInput"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import { useMediaQuery } from "usehooks-ts"
import CustomSelect from "components/forms/CustomSelect"
import { countries } from "services/constants"
import { formatCurrencyValue } from "services/helpers"
import {
  confirmPayment,
  createPaymentIntent,
  getMyPaymentPlan,
  subscribeCustomer,
} from "services/payment"
import { refreshLogin } from "services/auth"
import { showSuccess } from "services/toast"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"

Modal.setAppElement("body")

const StripeModalSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  addressLine: Yup.string().required("Address line is required"),
  postCode: Yup.string().required("Post code is required"),
  city: Yup.string().required("City is required"),
})

const StripeModal = ({ onRequestClose, ...otherProps }) => {
  const stripe = useStripe()
  const { user } = useContext(GlobalStateContext)
  const elements = useElements()
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useContext(GlobalDispatchContext)
  const isDesktop = useMediaQuery("(min-width: 1023px)")

  const initialValues = {
    name: "",
    addressLine: "",
    postCode: "",
    city: "",
    country: "",
  }

  const handlePaymentSubscription = async (subscriptionId) => {
    try {
      const isCustomerSubscribed = await subscribeCustomer({
        stripePriceId: otherProps.stripePriceId,
        subscriptionId,
      })

      if (isCustomerSubscribed) {
        await refreshLogin()
        const paymentPlan = await getMyPaymentPlan()
        dispatch({ type: "SET_PAYMENT_PLAN", payload: paymentPlan })
        onRequestClose()
        otherProps.handlePaymentHistory()
        showSuccess("Payment succeeded! New plan activated!")
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true)

    try {
      if (!stripe || !elements) {
        throw new Error("Stripe has not initialized")
      }

      if (!values.country) {
        throw new Error("Please fill in a country")
      }

      setError("")

      const cardElement = elements.getElement(CardElement)
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        })

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message)
      }

      const paymentIntentResponse = await createPaymentIntent({
        amount: otherProps.price * 0.2,
        paymentMethodId: paymentMethod.id,
        email: user.username,
        stripePriceId: otherProps.stripePriceId,
      })

      if (paymentIntentResponse.error) {
        throw new Error(paymentIntentResponse.error.split(";")[0])
      }

      const { clientSecret, status, subscriptionId, paymentIntentId } =
        paymentIntentResponse
      if (status === "requires_action") {
        const { error: confirmError, paymentIntent: confirmedPaymentIntent } =
          await stripe.confirmCardPayment(clientSecret)

        if (confirmError) {
          throw new Error(confirmError.message)
        }

        if (confirmedPaymentIntent.status === "succeeded") {
          confirmPayment({
            intentId: paymentIntentId,
          })
            .then((confirmPaymentRes) => {
              if (confirmPaymentRes.status === "succeeded") {
                handlePaymentSubscription(subscriptionId)
              }
            })
            .catch((error) => {
              setError(error.message)
            })
        }
      } else if (status === "succeeded") {
        handlePaymentSubscription(subscriptionId)
      }
    } catch (error) {
      setError(error.message)
      setIsLoading(false)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal
      isOpen={true}
      portalClassName="contact"
      onRequestClose={() => {
        !isLoading && onRequestClose()
      }}
      style={{
        content: {
          width: isDesktop ? "30%" : "100%",
        },
      }}
      {...otherProps}
    >
      <button
        type="button"
        disabled={isLoading}
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <Formik
        initialValues={initialValues}
        validationSchema={StripeModalSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="mx-auto w-full flex flex-col my-4 px-6">
            <div className="flex justify-center mb-6">
              <img src="/images/growyze-logo.png" className="w-40" alt="Logo" />
            </div>
            <div className="flex justify-center mb-4">
              <h1>{`${otherProps.label} plan £${formatCurrencyValue(
                (otherProps.price / 100) * 1.2,
                2,
                true
              )} inc VAT`}</h1>
            </div>

            {error && (
              <div
                className={
                  "text-red-600 block w-full mx-auto max-w-xs text-center font-sansBold font-semibold mb-4"
                }
              >
                {error}
              </div>
            )}

            <>
              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    required={true}
                    name="name"
                    type="text"
                    className="form-control form-control--first"
                    placeholder="Name"
                    label="Name"
                  />
                </div>
              </div>
              <div className="input-container">
                <TextInput
                  required={true}
                  name="addressLine"
                  label="Address line"
                  type="text"
                  placeholder="Address line"
                  className="form-control form-control--first"
                />
              </div>
              <div className="form-group form-group--flex">
                <div className="input-container input-container--left">
                  <TextInput
                    required={true}
                    name="postCode"
                    label="Post code"
                    type="text"
                    placeholder="Post code"
                    className="form-control"
                  />
                </div>
                <div className="input-container input-container--right">
                  <TextInput
                    required={true}
                    name="city"
                    label="City"
                    type="text"
                    placeholder="City"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <CustomSelect
                    name="country"
                    id="country"
                    label="Country"
                    placeholder="Country"
                    isSearchable={isDesktop}
                    className="form-control form-control--last"
                    options={countries}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <CardElement
                    className="block border border-solid border-opacity-100 border-gray-300 p-4"
                    options={{ hidePostalCode: true }}
                  />
                </div>
              </div>
              {otherProps.userHasThisPlan ? (
                <>
                  <div className="p-3 text-center font-bold mt-4 button button--lightGray disabled">
                    {" "}
                    This is your current plan
                  </div>
                  <i className="pt-4 text-center h-20">
                    {otherProps.btnSuffix}
                  </i>
                </>
              ) : (
                <button
                  type="submit"
                  className="button mt-5 mb-4"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <FontAwesomeIcon
                        icon={faSpinnerThird}
                        className="mr-1"
                        spin
                      />
                      Processing...
                    </>
                  ) : (
                    "Purchase £" +
                    formatCurrencyValue((otherProps.price / 100) * 1.2, 2, true)
                  )}
                </button>
              )}
            </>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

StripeModal.propTypes = {
  onRequestClose: PropTypes.func,
}

export default StripeModal
