// extracted by mini-css-extract-plugin
export var address = "OrderDetails-module--address--VG4+L";
export var bottom = "OrderDetails-module--bottom--fsVJB";
export var container = "OrderDetails-module--container--ZiEjM";
export var content = "OrderDetails-module--content--NdzxF";
export var date = "OrderDetails-module--date--blX5J";
export var detailsHeader = "OrderDetails-module--detailsHeader--pVUqH";
export var noItems = "OrderDetails-module--noItems--jUrSK";
export var note = "OrderDetails-module--note--fIwjU";
export var noteLabel = "OrderDetails-module--noteLabel--TS6H5";
export var po = "OrderDetails-module--po--5BFjb";
export var successMessage = "OrderDetails-module--successMessage--pnfRF";
export var title = "OrderDetails-module--title--XXj1n";