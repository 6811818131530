import React, { useState, useEffect } from "react"
import {
  faArrowRight,
  faArrowLeft,
  faTimes,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import { useMediaQuery } from "react-responsive"

import { navigate } from "gatsby"

import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import SupplierCatalog from "components/suppliers/SupplierCatalog/SupplierCatalog"

import { apiResponseIsError, handleError } from "services/helpers"
import { Supplier, Product } from "services/types"
import {
  addProductsToSupplierFromMarketplace,
  createSupplierWithProductsFromMarketplace,
  getSupplierFromMarketplaceById,
  getSuppliers,
} from "services/suppliers/suppliers"
import { showSuccess, showError } from "../../../services/toast"

//@ts-ignore
import * as styles from "./SupplierMarketplaceDetails.module.css"
import { getAllItemsFromPaginatedResponse } from "../../../services/helpers"

interface CreateSupplierParams {
  supplier: Supplier
  marketplaceSupplierId: string
  productsIds?: string[]
}

interface AddProductsToSupplierParams {
  marketplaceSupplierId: string
  productsIds?: string[]
}

const SupplierMarketplaceDetails = ({ supplierId }) => {
  const [supplier, setSupplier] = useState<Supplier | null>(null)
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  const [loading, setLoading] = useState(false)
  const [showItems, setShowItems] = useState(false)
  const [isAdded, setIsAdded] = useState(false)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const itemsList = () => {
    return (
      <div className="z-20 absolute lg:relative w-full h-full flex flex-col left-0 top-0 lg:left-auto lg:top-auto bg-white">
        <header className="px-4 py-2 flex items-center justify-between lg:hidden">
          <h4 className="text-lg text-primaryBlue">Your items</h4>
          <button
            onClick={() => setShowItems(false)}
            className="text-lg p-2 text-primaryBlue"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </header>
        <ul className="border-t lg:-mx-8 px-4 lg:mt-4 lg:px-8 flex-grow overflow-y-auto">
          {selectedProducts.length > 0 &&
            selectedProducts.map((item, index) => (
              <li
                className="flex border-b w-full text-gray-700 text-sm py-2 items-center"
                key={index}
              >
                <span className="flex-grow">
                  {item.name}{" "}
                  {item.size || item.measure ? (
                    <small>
                      {item.size} {item.measure}
                    </small>
                  ) : null}
                </span>

                <button
                  type="button"
                  className="button--autoWidth text-primaryPink"
                  onClick={() => removeItem(item.id)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </li>
            ))}

          {selectedProducts.length === 0 && (
            <p className="py-2">No items selected from supplier</p>
          )}
        </ul>
      </div>
    )
  }

  const removeItem = (itemId) => {
    const newSelectedProducts = selectedProducts.filter(
      (item) => item.id !== itemId
    )
    setSelectedProducts(newSelectedProducts)
  }

  const handleSelectionChange = (items) => setSelectedProducts(items)

  const getSupplierData = async (supplierId) => {
    setLoading(true)

    const result = await getSupplierFromMarketplaceById(supplierId)

    const allSuppliers = await getAllItemsFromPaginatedResponse(getSuppliers)

    const addedSupplier = allSuppliers?.find(
      (supplier) => supplier.marketplaceSupplier?.id === supplierId
    )

    setIsAdded(!!addedSupplier)

    if (result && !result.error) {
      setSupplier(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleCreateSupplier = async (supplier) => {
    const params: CreateSupplierParams = {
      marketplaceSupplierId: supplier.id,
      supplier: {
        ...supplier,
        markeplaceSupplier: {
          id: supplier.id,
          name: supplier.name,
          email: supplier.email,
        },
      },
      productsIds: selectedProducts.map((prod) => prod.id),
    }

    try {
      setLoading(true)
      const result = await createSupplierWithProductsFromMarketplace(params)
      if (apiResponseIsError(result)) {
        if (result?.message) {
          showError(result.message)
        } else {
          showError("Something went wrong...")
        }
      } else {
        showSuccess(`${supplier.name} was added to your suppliers`)
        navigate("/dashboard/purchases/suppliers")
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleAddProductsToSupplier = async (supplier) => {
    const params: AddProductsToSupplierParams = {
      marketplaceSupplierId: supplier.id,
      productsIds: selectedProducts.map((prod) => prod.id),
    }

    try {
      setLoading(true)
      const result = await addProductsToSupplierFromMarketplace(params)

      if (apiResponseIsError(result)) {
        if (result?.message) {
          showError(result.message)
        } else {
          showError("Something went wrong...")
        }
      } else {
        showSuccess(
          `${selectedProducts.length} ${
            selectedProducts.length > 1 ? "products were" : "product was"
          } added to ${supplier.name} in your suppliers`
        )
        navigate("/dashboard/purchases/suppliers")
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (supplierId) {
      getSupplierData(supplierId).catch(handleError)
    }
  }, [supplierId])

  return (
    <>
      <Helmet>
        <title>{isAdded ? "Add supplier products" : "Add Supplier"}</title>
      </Helmet>
      <div className={styles.container}>
        {loading ? (
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading..
          </Loader>
        ) : (
          <>
            <Header
              back
              title={
                isAdded
                  ? `Add products to ${supplier?.name}`
                  : `Add ${supplier?.name} to suppliers`
              }
            />

            <div className={styles.wrapper}>
              {isTabletOrMobile && showItems && itemsList()}

              <div className={styles.main}>
                <div className={styles.content}>
                  <div className={"-mt-px"}>
                    <SupplierCatalog
                      selectedItems={selectedProducts}
                      onSelectionChange={handleSelectionChange}
                      supplier={supplier}
                      fromMarketplace={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.sidebar}>
                <div className={styles.sidebarContent}>
                  <span className="block font-semibold text-gray-700 text-sm lg:text-base font-sansSemiBold lg:mb-2">
                    Selected items
                  </span>

                  {isTabletOrMobile ? (
                    <button
                      className="text-primaryPink font-sansSemiBold font-semibold w-1/3 text-left order-first"
                      onClick={() => setShowItems(true)}
                    >
                      {selectedProducts.length}{" "}
                      {selectedProducts.length !== 1 ? "items" : "item"}
                      <FontAwesomeIcon icon={faChevronUp} className="ml-2" />
                    </button>
                  ) : (
                    itemsList()
                  )}
                </div>
                <div className={styles.sidebarFooter + " flex-row"}>
                  {isTabletOrMobile && (
                    <button
                      className={
                        "button button--autoWidth button--transparent text-primaryBlue -ml-4 order-1"
                      }
                      onClick={() => {}}
                    >
                      <span className=" text-primaryBlue">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Back
                      </span>
                    </button>
                  )}

                  <button
                    className={
                      isTabletOrMobile
                        ? "button button--autoWidth button--primaryPink order-last"
                        : "button button--primaryPink order-last"
                    }
                    onClick={() => {
                      if (supplier) {
                        if (isAdded) {
                          handleAddProductsToSupplier(supplier)
                        } else {
                          handleCreateSupplier(supplier)
                        }
                      }
                    }}
                    disabled={!supplier}
                  >
                    <span className="hidden lg:inline">
                      {isAdded ? "Add products" : "Add supplier"}
                    </span>
                    <FontAwesomeIcon className="lg:ml-3" icon={faArrowRight} />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SupplierMarketplaceDetails
