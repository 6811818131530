import React, { useState } from "react"
import Quantity from "components/forms/Quantity"

interface StockTransferEditQuantityProps {
  field: string
  value: number
  onSave: Function
  onClose: Function
}

const StockTransferEditQuantity = ({
  field,
  value,
  onSave,
  onClose,
}: StockTransferEditQuantityProps) => {
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = (val) => {
    setCurrentValue(val)
  }

  const handleSave = () => {
    onSave({ [field]: currentValue })
    onClose()
    return
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <Quantity
        fieldName={field}
        value={currentValue}
        allowNegative={false}
        onChange={handleChange}
        displayStyle={"rounded"}
        addClasses={{ field: " w-32" }}
      />

      <button
        type="button"
        onClick={handleSave}
        className="button button--primaryBlue m-4"
      >
        Done
      </button>
    </div>
  )
}

export default StockTransferEditQuantity
