import { initialDeliveryNote } from "context/global/InitialContext"

const deliveryNoteReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_NEW_DELIVERY_NOTE": {
      return {
        ...state,
        newDeliveryNote: {
          ...state.newDeliveryNote,
          ...action.payload,
        },
      }
    }
    case "RESET_NEW_DELIVERY_NOTE": {
      const { resetSupplier } = action.options
      return {
        ...state,
        newDeliveryNote: {
          ...state.newDeliveryNote,
          ...initialDeliveryNote,
          supplier: resetSupplier ? null : state.newDeliveryNote.supplier,
        },
      }
    }
    default: {
      return state
    }
  }
}
export default deliveryNoteReducer
