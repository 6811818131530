import React, { useEffect, useState, useContext } from "react"
import { ModalContext } from "context/modal/ModalContext"
import * as queryString from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showError, showSuccess } from "services/toast"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { useLocation } from "@reach/router"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { IntegrationButtonProps } from "services/types"
import {
  createSageIntegration,
  getBusinessesFromSage,
  getSageClientId,
  getSageIntegrations,
  redirectToSageAuthorization,
  removeSageIntegration,
  updateSageIntegrationBusiness,
} from "services/sage"

const SageIntegrationButton = ({
  id = null,
  onConnectionChange,
  canConnect,
  canDisconnect,
}: IntegrationButtonProps) => {
  const route = useLocation()
  const url = typeof window !== "undefined" ? window.location.origin : ""
  const redirect = `${url}/dashboard/callback/sage`
  const modal = useContext(ModalContext)
  const queryParams = queryString.parse(route.search)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [integrationId, setIntegrationId] = useState<string | null>(id) // @todo this id needs to be passed in from the integrations overview. Will probably work as soon BE is ready automatically (no change needed here)
  const [sageOrganization, setSageOrganization] = useState<string | null>(null)
  const [checkingConnection, setCheckingConnection] = useState(true)

  const startSageAuthorization = async () => {
    getSageClientId()
      .then((clientId) => {
        setLoading(true)
        if (clientId.includes("error")) {
          throw Error(JSON.parse(clientId))
        }

        redirectToSageAuthorization(clientId)
      })
      .catch(() => {
        setError("Failed to connect to Sage, please try again later")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const confirmRemove = () => {
    setError("")
    if (id) {
      modal.showModal(ConfirmModal as any, {
        type: "danger",
        title: `Disconnect Sage integration?`,
        text: "Are you sure you want to disconnect this integration?",
        confirmButtonText: "Disconnect",
        onConfirm: async () => {
          try {
            const success = await removeSageIntegration(id)
            if (success) {
              showSuccess("Sage disconnected!")
              setIntegrationId(null)
              setSageOrganization(null)
              onConnectionChange?.()
            } else {
              showError(
                "Failed to disconnect Sage integration. Please try again."
              )
            }
          } catch (error) {
            console.error(error)
          }
        },
      })
    }
  }

  const onLoad = async () => {
    try {
      const connections = await getSageIntegrations()

      // Connection already exists
      if (connections.length > 0) {
        setIntegrationId(connections[0].id)
        setSageOrganization(connections[0].organizations[0])
      }
      // Create Sage connection
      else if (queryParams.callback === "sage" && queryParams.code) {
        setLoading(true)
        const params = {
          code: queryParams.code,
          organizations: null,
          redirectUrl: redirect,
        }

        const response = await createSageIntegration(params)
        if (response) {
          const business = await getBusinessesFromSage({
            sageIntegrationId: response.id,
          })
          await updateSageIntegrationBusiness({
            sageIntegrationId: response.id,
            businessId: business[0].id,
          })

          setIntegrationId(response.id)
          setSageOrganization(response.organizations[0])
          showSuccess("Sage connected!")
          onConnectionChange?.()
        }
        setLoading(false)
      }

      setCheckingConnection(false)
    } catch (e: any) {
      console.log(e)
      setError(e.message)
      setCheckingConnection(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  if (integrationId && sageOrganization) {
    return canDisconnect ? (
      <button
        className="text-primaryPink font-semibold font-sansSemiBold hover:bg-gray-50 px-3 bg-white border rounded border-gray-200 text-sm self-start text-left mr-3"
        style={{ height: "40px " }}
        onClick={confirmRemove}
      >
        Disconnect
      </button>
    ) : null
  }

  return (
    <div className="inline-flex flex-col items-end mr-3">
      {canConnect && (
        <button
          className="text-primaryGreen font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left"
          style={{ height: "40px" }}
          disabled={loading || checkingConnection}
          onClick={() => {
            startSageAuthorization()
          }}
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
              Connecting..
            </>
          ) : (
            <>
              Connect
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </>
          )}
        </button>
      )}
      {error && <span className="text-primaryPink text-xs">{error}</span>}
    </div>
  )
}

export default SageIntegrationButton
