import React, { useContext, useState } from "react"
import PropTypes from "prop-types"

import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { format } from "date-fns"
import {
  formatCurrencyValue,
  roundNumber,
  formatReason,
  uuidv4,
  convertTimeStampFormat,
} from "services/helpers"

import * as styles from "./WasteDetailItem.module.css"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

const WasteDetailItem = ({ wastePeriod, wasteProduct }) => {
  const [collapsed, setCollapsed] = useState(false)
  const { date, totalCost, totalQty, wastesPerDay } = wastePeriod
  const currency = null
  const { organization } = useContext(GlobalStateContext)
  return (
    <div className={styles.wasteDetail}>
      <div className="flex w-full">
        <div
          className={styles.wasteDetailItem}
          onClick={() => setCollapsed(!collapsed)}
        >
          <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
          <span>{format(new Date(date), "dd/MM/yyyy")}</span>
        </div>
        <div className={styles.wasteDetailItem}>{totalCost}</div>
        <div className={styles.wasteDetailItem}>{totalQty}</div>
        <div className={styles.wasteDetailItem}></div>
      </div>
      {collapsed && (
        <div>
          {wastesPerDay.map((waste) => {
            return (
              <div key={uuidv4()} className="flex py-1 text-sm">
                <div className={styles.wasteDetailCell}>
                  <span className="font-sansBold mr-1">
                    {convertTimeStampFormat(
                      waste.timeOfRecord,
                      organization?.address.zoneId,
                      true
                    )}
                  </span>
                  <span>{` (${waste.reporter})`}</span>
                </div>
                <div className={styles.wasteDetailCell}>
                  <span>{`${
                    currency
                      ? formatCurrencyValue(waste.totalCost)
                      : roundNumber(waste.totalCost)
                  }`}</span>
                </div>
                <div className={styles.wasteDetailCell}>
                  <span className="font-sansBold">
                    {roundNumber(waste.totalQty)}
                  </span>
                  &nbsp;
                  <span className="font-sans">
                    {` (${roundNumber(
                      waste.totalQty * wasteProduct.product.size
                    )}${
                      wasteProduct.product.measure
                        ? wasteProduct.product.measure
                        : ""
                    })`}
                  </span>
                </div>
                <div className={styles.wasteDetailCell}>
                  <span className="font-sansBold text-secondaryOrange">
                    {formatReason(waste.reason)}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

WasteDetailItem.propTypes = {
  wastePeriod: PropTypes.object,
  wasteProduct: PropTypes.object,
}

export default WasteDetailItem
