import { initialWasteGroup } from "context/global/InitialContext"

const wasteReducer = (state, action) => {
  switch (action.type) {
    /**
     * Waste actions
     */

    case "ADD_NEW_WASTE_GROUP": {
      return {
        ...state,
        selectedWasteGroup: initialWasteGroup,
      }
    }

    case "SELECT_WASTE_GROUP": {
      const { selectedWasteGroup } = action.payload
      return {
        ...state,
        selectedWasteGroup: {
          ...state.selectedWasteGroup,
          ...selectedWasteGroup,
        },
      }
    }

    case "RESET_WASTE_GROUP_PRODUCTS": {
      return {
        ...state,
        selectedWasteGroup: initialWasteGroup,
      }
    }
    default: {
      return state
    }
  }
}

export default wasteReducer
