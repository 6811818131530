import React, { useState, useEffect, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalculator, faChartBar } from "@fortawesome/pro-duotone-svg-icons"
import {
  faFileDownload,
  faSync,
  faEllipsisH,
} from "@fortawesome/pro-regular-svg-icons"
import Helmet from "react-helmet"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import SearchInput from "components/forms/SearchInput"
import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import ExpandableCategorisedProductTable from "components/common/ExpandableCategorisedProductTable/ExpandableCategorisedProductTable"

import { roundNumber, getFileNameDateTime } from "services/helpers"
import {
  getCOGSReportById,
  exportCOGSReport,
  updateReportById,
} from "services/stock-take/stock-take"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"

//@ts-ignore
import * as styles from "./COGSReport.module.css"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import ReactTooltip from "react-tooltip"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { getExportFileName } from "services/export"
import ReportNameCell from "components/baseList/cells/ReportName"
import ReportModal from "../ReportModal/ReportModal"
import { showError, showSuccess } from "services/toast"
import { ColumnShape } from "react-base-table"

const COGSReport = ({ cogsReportId }: { cogsReportId: string }) => {
  const [cogsData, setCOGSData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [generating, setGenerating] = useState(false)

  const [query, setQuery] = useState("")

  const {
    organization,
    organizationGroup,
  }: { organization: any; organizationGroup: any } =
    useContext(GlobalStateContext)
  const permissionObj = usePermissions("COGS") as Permission

  const modal = useContext(ModalContext)
  const DEFAULT_ERROR_MESSAGE = "Something went wrong!"
  // Report cell components

  const reportActions = [
    { title: "Export full report (csv)", key: "export.full.csv" },
    { title: "Export full report (xlsx)", key: "export.full.xlsx" },
    { title: "Export summary (xlsx)", key: "export.summary.xlsx" },
    { title: "Export summary (pdf)", key: "export.summary.pdf" },
  ]

  const Title = ({ rowData }: { rowData: any }) => {
    return (
      <div className="flex flex-col my-3 text-sm">
        <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue">
          {rowData.name}
        </span>
        <span className="text-gray-700">
          {rowData.size} {rowData.measure}
        </span>
      </div>
    )
  }

  // const Category = ({
  //   rowData,
  // }: {
  //   rowData: { category: string; subCategory: string }
  // }) => {
  //   return (
  //     <div className="flex flex-col my-3 text-sm">
  //       <span className="text-gray-700">
  //         {rowData.category}{" "}
  //         {rowData.subCategory ? `- ${rowData.subCategory}` : ""}
  //       </span>
  //     </div>
  //   )
  // }

  const Number = ({ rowData, dataKey }: { rowData: any; dataKey: string }) => {
    return (
      <div className="flex flex-col my-3 text-sm">
        {rowData[dataKey] ? roundNumber(rowData[dataKey]) : ""}
      </div>
    )
  }

  const COGS = ({ rowData }: { rowData: any }) => {
    return (
      <span
        className={
          rowData.totalCOGS < 0
            ? "text-primaryPink font-sansSemiBold font-semibold"
            : ""
        }
      >
        {roundNumber(rowData.totalCOGS)}
      </span>
    )
  }

  const handleError = (result) => {
    if (result.status === 403) {
      // do nothing, message comes from api.js
    } else if (result.status?.toString().charAt(0) === "4") {
      let errorText = DEFAULT_ERROR_MESSAGE
      if (result?.errors?.length > 0) {
        errorText = result.errors[0].defaultMessage
      } else if (result.message) {
        errorText = result.message
      }
      showError(errorText)
    } else {
      showError(DEFAULT_ERROR_MESSAGE)
    }
  }

  const columns: ColumnShape<object>[] = [
    {
      key: "name",
      title: "Item name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      headerClassName: "pl-6",
      cellRenderer: Title,
    },
    // {
    //   key: "category",
    //   title: "Category",
    //   dataKey: "category",
    //   width: 200,
    //   flexGrow: 1,
    //   flexShrink: 0,
    //   headerClassName: "pl-6",
    //   cellRenderer: Category,
    // },
    {
      key: "openingQty",
      title: "Opening Qty",
      width: 100,
      dataKey: "openingQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="openingQty" />
      ),
    },
    {
      key: "deliveryQty",
      title: "Delivery (+)",
      width: 100,
      dataKey: "deliveryQty",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="deliveryQty" />
      ),
    },
    {
      key: "transferredQty",
      title: "Transfers (+/-)",
      width: 120,
      dataKey: "transferredQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      cellRenderer: ({ rowData }: { rowData: any }) => (
        <>{rowData.transferredQty ? roundNumber(rowData.transferredQty) : ""}</>
      ),
    },
    {
      key: "closingQty",
      title: "Actual closing qty",
      width: 140,
      dataKey: "closingQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      headerRenderer: () => {
        return (
          <div className="flex flex-shrink-0 items-center">
            <p className="mr-1">Actual closing qty</p>
            {cogsData.theoretical && <span className="text-error">*</span>}
          </div>
        )
      },
      cellRenderer: ({ rowData }: { rowData: any }) => (
        <>
          <Number rowData={rowData} dataKey="closingQty" />
          {rowData.theoretical && <span className="text-error ml-1">*</span>}
        </>
      ),
    },
    {
      key: "consumptionQty",
      title: "Consumption",
      width: 100,
      dataKey: "consumptionQty",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="consumptionQty" />
      ),
    },
    {
      key: "price",
      title: "Cost Price",
      width: 100,
      dataKey: "price",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="price" />
      ),
    },
    {
      key: "totalCOGS",
      title: "COGS",
      width: 100,
      dataKey: "totalCOGS",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: COGS,
    },
    {
      key: "totalCostGoodsSpend",
      title: "COG Spent",
      width: 100,
      dataKey: "totalCostGoodsSpend",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="totalCostGoodsSpend" />
      ),
    },
    {
      key: "totalSoldValue",
      title: "Sales",
      width: 100,
      dataKey: "totalSoldValue",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="totalSoldValue" />
      ),
    },
    {
      key: "totalCostGoodsSpendPercentage",
      title: "COG Spent %",
      headerRenderer: () => {
        return (
          <div>
            <span>COG Spent %</span>

            <span
              data-for="spent_percentage_tooltip"
              data-tip="COG Spent / Sales"
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="ml-2 opacity-75"
              />
            </span>

            <ReactTooltip
              id={"spent_percentage_tooltip"}
              type="light"
              place="top"
              effect="float"
              border={true}
              borderColor="#e2e8f0"
            />
          </div>
        )
      },
      width: 160,
      dataKey: "totalCostGoodsSpendPercentage",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Number rowData={rowData} dataKey="totalCostGoodsSpendPercentage" />
      ),
    },
  ]

  const onEditCOGSReport = () => {
    modal.showModal(ReportModal, {
      endpoint: "/stock-take/cogs-reports",
      currentReportId: cogsReportId,
      stockTakeReportId: cogsData.closingStockTakeReportId,
      currentReportData: cogsData,
      title: "Edit COGS report",
      iconConfig: {
        mainIcon: {
          icon: faChartBar,
          color: "#e20d3b",
          backgroundColor: "#FC37620D",
        },
        subIcon: { icon: faCalculator, color: "#e20d3b" },
      },
      loadingText: "Updating COGS Report",
      insights: true,
      isUpdate: true,
      onUpdate: () => getData(),
    })
  }

  const getData = async (regenerate = false) => {
    if (!regenerate) {
      setLoading(true)
    }

    if (regenerate) {
      const {
        openingStockTakeReportId,
        closingStockTakeReportId,
        theoretical,
        insights,
        filterCategories,
        periodTo,
        sales,
      } = cogsData

      const params = {} as any

      if (filterCategories) {
        params.filterCategories = filterCategories
      }

      const updated = await updateReportById(
        "/stock-take/cogs-reports",
        cogsReportId,
        closingStockTakeReportId,
        openingStockTakeReportId,
        sales ? sales.id : null,
        insights,
        theoretical,
        closingStockTakeReportId ? undefined : periodTo, // either (closing) stocktakeReportId or periodTo needs to be provided
        params
      )

      if (
        !updated ||
        [400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
          updated.status
        )
      ) {
        handleError(updated)
      } else {
        showSuccess("Report refreshed!", { position: "top-center" })
      }
    }

    const getParams = {
      organizations: organizationGroup,
    } as any

    if (query) {
      getParams.partialProductName = query
    }

    // fromPromise prevents call on unmount of component
    const result = await getCOGSReportById(cogsReportId, getParams)

    if (
      result &&
      ![400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
        result.status
      )
    ) {
      setCOGSData(result)
      setLoading(false)
      setGenerating(false)
    } else {
      handleError(result)
    }

    setLoading(false)
    setGenerating(false)
  }

  // This will be called onMount as well, so no extra mounted useEffect needed
  useEffect(() => {
    getData()
  }, [query])

  const regenerate = () => {
    setGenerating(true)
    getData(true)
  }

  const handleActionClick = ({ type }) => {
    const [action, reportName, ext] = type.split(".")

    const params = {}
    if (cogsData?.organizations[0] !== organization.id) {
      params["organizations"] = organizationGroup
    }

    const summarised = reportName == "summary" ? "/summarised" : ""
    const fileName = getExportFileName(
      "cogs-report",
      reportName,
      ext,
      getFileNameDateTime()
    )

    switch (action) {
      case "refresh":
        regenerate()
        break
      case "export":
        exportCOGSReport(cogsReportId, fileName, ext, params, summarised)
        break
      default:
        break
    }
  }

  return (
    <>
      <Helmet>
        <title>COGS report</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="COGS report" />

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading report..
          </Loader>
          {cogsData && (
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <div className={styles.headerTopColumn}>
                  <ReportNameCell
                    data={cogsData}
                    iconStyles={{
                      mainIcon: {
                        backgroundColor: "#FC37620D",
                        color: "#e20d3b",
                      },
                      subIcon: {
                        color: "#e20d3b",
                      },
                    }}
                    onEdit={onEditCOGSReport}
                    editEnabled={permissionObj?.permissions.modify}
                    disabled={!permissionObj?.permissions.modify}
                    mainIcon={faChartBar}
                    subIcon={faCalculator}
                  />

                  <button
                    type="button"
                    disabled={!permissionObj?.permissions.modify}
                    className="items-center text-orange-600 rounded hover:text-orange-500 font-sansSemiBold font-semibold px-0 lg:px-2 py-2 flex-shrink-0"
                    onClick={regenerate}
                  >
                    <FontAwesomeIcon
                      icon={faSync}
                      spin={generating}
                      className="mr-2 block"
                    />
                  </button>

                  <div className="flex flex-grow gap-x-3 items-center">
                    <div className="my-2 flex-grow">
                      <SearchInput
                        label="Search by products"
                        placeholder="Search by products"
                        className="form-control rounded"
                        onSearchChange={setQuery}
                      />
                    </div>

                    <DropdownButton
                      onActionClick={handleActionClick}
                      label={<FontAwesomeIcon icon={faFileDownload} />}
                      mobileIcon={faEllipsisH}
                      options={reportActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {cogsData && (
            <ExpandableCategorisedProductTable
              data={cogsData}
              columns={columns}
              productValueMap={(prod: any) => {
                return {
                  totalCOGS: prod.cogs,
                  totalCostClosingStock: prod.totalCostClosingStock,
                  totalCostGoodsSpend: prod.costGoodsSpend,
                  totalSoldValue: prod.soldValue,
                  totalCostGoodsSpendPercentage: prod.costGoodsSpendPercentage,
                }
              }}
            />
          )}
        </div>
        {cogsData && (
          <div className={styles.bottomSummary}>
            <div className={styles.summaryItem}>
              <span className="mr-1">Total value:</span>
              <span
                className={`font-sansSemiBold font-semibold ${
                  cogsData.totalCOGS < 0
                    ? "text-primaryPink"
                    : cogsData.totalCOGS > 0
                    ? "text-primaryGreen"
                    : ""
                }`}
              >
                {roundNumber(cogsData.totalCOGS)}
              </span>
            </div>
            {cogsData.theoretical && (
              <div className={`${styles.summaryItem} ml-auto`}>
                <span
                  className={`font-sansSemiBold font-semibold text-sm text-error `}
                >
                  <span className="mr-1">*</span>Theoretical stock included
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default COGSReport
