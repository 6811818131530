import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./QueryNote.module.css"

const QueryNote = ({ supplier, onSave, isSaving }) => {
  const areaRef = useRef(null)
  const [message, setMessage] = useState("")

  //
  // Handlers
  //

  const handleTextareaChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setMessage(value)
  }

  useEffect(() => {
    areaRef.current.focus()
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <p className="text-center mb-4 w-full font-sansSemiBold font-semibold text-primaryBlue">
        Are you sure you’d like to notify the supplier of the identified
        discrepancies?
      </p>
      <label className="text-gray-700 mb-3 text-center w-full">Comment</label>
      <textarea
        ref={areaRef}
        className={`form-control ${styles.textarea}`}
        rows={4}
        value={message || ""}
        onChange={handleTextareaChange}
        placeholder={`Send a comment to ${supplier.name}`}
      ></textarea>
      <button
        onClick={() => onSave({ message })}
        className="button button--primaryBlue"
        disabled={isSaving}
      >
        {isSaving && (
          <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
        )}
        Confirm
      </button>
    </div>
  )
}

QueryNote.propTypes = {
  supplier: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

export default QueryNote
