// extracted by mini-css-extract-plugin
export var deliveryDate = "StockTakeHeader-module--deliveryDate--8oSgb";
export var disabled = "StockTakeHeader-module--disabled--O6pcM";
export var editButton = "StockTakeHeader-module--editButton--X8uj-";
export var header = "StockTakeHeader-module--header--C1X2V";
export var headerMain = "StockTakeHeader-module--headerMain--qfjRs";
export var iconWrapper = "StockTakeHeader-module--iconWrapper--6RhCM";
export var ref = "StockTakeHeader-module--ref--55DWi";
export var statusBulb = "StockTakeHeader-module--statusBulb--fa1UV";
export var supplierName = "StockTakeHeader-module--supplierName--xwpUS";
export var thumbnail = "StockTakeHeader-module--thumbnail--cufSn";
export var thumbnailWrapper = "StockTakeHeader-module--thumbnailWrapper--AUl+u";