import { faStars } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"

interface IUpgradeButton {
  onClick?(): void
  theme?: "light" | "dark"
}

const UpgradeButton = ({ onClick, theme }: IUpgradeButton) => {
  return (
    <Link to="/dashboard/company-settings?tab=billing-details">
      <button
        onClick={() => onClick && onClick()}
        className={`button button--gradient${
          theme === "light" ? "-light" : ""
        } button--smaller button--autoWidth`}
      >
        <FontAwesomeIcon icon={faStars} className="mr-2" />
        Upgrade
      </button>
    </Link>
  )
}

export default UpgradeButton
