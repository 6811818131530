import React, { Dispatch } from "react"
import InitialContext from "./InitialContext"

import accountingReducer from "../reducers/accountingReducer"
import aiRecipeReducer from "../reducers/aiRecipeReducer"
import deliveryNoteReducer from "../reducers/deliveryNoteReducer"
import globalOrderReducer from "../reducers/globalOrderReducer"
import invoiceReducer from "../reducers/invoiceReducer"
import mainReducer from "../reducers/mainReducer"
import orderReducer from "../reducers/orderReducer"
import organizationReducer from "../reducers/organizationReducer"
import onboardingReducer from "../reducers/onboardingReducer"
import paymentPlanReducer from "../reducers/paymentPlanReducer"
import stocktakeReducer from "../reducers/stocktakeReducer"
import stocktakeSheetReducer from "../reducers/stocktakeSheetReducer"
import stocktransferReducer from "../reducers/stocktransferReducer"
import uiControlsReducer from "../reducers/uiControlsReducer"
import userReducer from "../reducers/userReducer"
import wasteReducer from "../reducers/wasteReducer"

type GlobalState = typeof InitialContext

type Action = { type: string; payload?: any }

export const GlobalStateContext =
  React.createContext<GlobalState>(InitialContext)
export const GlobalDispatchContext = React.createContext<Dispatch<Action>>(
  () => {
    throw new Error("Dispatch not available")
  }
)

const reduceReducers =
  (...reducers) =>
  (state, action) =>
    reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state)

const rootReducer = reduceReducers(
  mainReducer,
  accountingReducer,
  aiRecipeReducer,
  deliveryNoteReducer,
  globalOrderReducer,
  invoiceReducer,
  orderReducer,
  organizationReducer,
  onboardingReducer,
  paymentPlanReducer,
  stocktakeReducer,
  stocktakeSheetReducer,
  stocktransferReducer,
  uiControlsReducer,
  userReducer,
  wasteReducer
)

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(rootReducer, InitialContext)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
