import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditName.module.css"

const EditName = ({ currentName, onSave, isSaving }) => {
  const inputRef = useRef(null)
  const [name, setName] = useState(currentName ? currentName : "")

  //
  // Handlers
  //

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setName(value)
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    // eslint-disable-next-line no-undef
    window.setTimeout(() => {
      const el = inputRef.current
      if (el && typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
      } else if (typeof el.createTextRange != "undefined") {
        el.focus()
        var range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <input
        type="text"
        ref={inputRef}
        className={`form-control border-0 px-0 ${styles.input}`}
        onFocus={setCursorAtTheEnd}
        value={name || ""}
        onChange={handleInputChange}
        placeholder={`Edit stocktake name`}
      />
      <button
        onClick={() => onSave(name)}
        className="button button--primaryBlue"
        disabled={isSaving}
      >
        {isSaving && (
          <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
        )}
        Save
      </button>
    </div>
  )
}

EditName.propTypes = {
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  currentName: PropTypes.string,
}

export default EditName
