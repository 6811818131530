import React, { useState } from "react"
//@ts-ignore
import * as styles from "./Discrepancies.module.css"
import Discrepancy from "components/delivery-notes/Discrepancy/Discrepancy"
import SimpleTable from "components/orders/SimpleTable/SimpleTable"
import { DiscrepancyStatus } from "services/types"
import HandleDiscrepancy from "./HandleDiscrepancy"
import LoaderContainer from "../LoaderContainer/LoaderContainer"

const capitalize = (type: string) =>
  type.slice(0, 1) + type.slice(1).toLowerCase()

const formatType = (type: string) => capitalize(type).replaceAll("_", " ")

export enum DiscrepancyType {
  MISSING_PRODUCT = "MISSING_PRODUCT",
  MISSING_SKU_CODE = "MISSING_SKU_CODE",
  QTY_UNKNOWN = "QTY_UNKNOWN",
  PRICE_UNKNOWN = "PRICE_UNKNOWN",
  MISSING_UNIT = "MISSING_UNIT",
  CASE_SIZE_UNKNOWN = "CASE_SIZE_UNKNOWN",
}

export interface Discrepancy {
  type: DiscrepancyType
  lineItem: {
    name: string
    qty: string
    code: string
    unit: string
    price: string
  }
  status: DiscrepancyStatus
  id: string
}

const message = (prop, single) => `
    The ${prop} of ${
  single ? `this product` : `these products`
} could not be obtained from the document. Please add the  ${
  single ? `product` : `products`
} with ${single ? `its` : `their`} appropriate ${prop}.
`

const messages: { [key: string]: string } = {
  MISSING_PRODUCT:
    "The following item with the below SKU/Product code cannot be found. Please add it to the supplier's catalogue.",
  MISSING_SKU_CODE: message("SKU code", true),
  QTY_UNKNOWN: message("quantity", true),
  PRICE_UNKNOWN: message("price", true),
  MISSING_UNIT:
    "We couldn't determine the unit of the following item. Please specify if it is in case or not.",
  CASE_SIZE_UNKNOWN:
    "The product has been ordered in case, but no case size is specified in growyze.",
}

const messagesPlural: { [key: string]: string } = {
  MISSING_PRODUCT:
    "The following items with the below SKU/Product codes cannot be found. Please add them to the supplier's catalogue.",
  MISSING_SKU_CODE: message("SKU code", false),
  QTY_UNKNOWN: message("quantity", false),
  PRICE_UNKNOWN: message("price", false),
  MISSING_UNIT:
    "We couldn't determine the unit of the following items. Please specify they are in case or not.",
  CASE_SIZE_UNKNOWN: message("case size", false),
}

const groupBy = <A,>(
  fieldName: string,
  array: Array<A>
): { [key: string]: Array<A> } =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[fieldName]]: (obj[item[fieldName]] || []).concat([item]),
    }),
    {}
  )
export default ({
  discrepancies: discrepancies,
  instance,
  handleDiscrepancy,
}) => {
  const unresolvedDiscrepancies = discrepancies
    ? discrepancies.filter(
        (discrepancy) => discrepancy.status !== DiscrepancyStatus.RESOLVED
      )
    : undefined
  const discrepancyTable = groupBy("type", unresolvedDiscrepancies || [])

  const [discrepancy, setDiscrepancy] = useState<Discrepancy | undefined>(
    undefined
  )
  const discrepancyAction = (discrepancy) => (
    <button
      className="button button--primaryGreen"
      /*
          Pass a different object each time to ensure that any actions that are done 
          when clicking on the button are carried out, even if the same discrepancy is already selected.
          (This is React's way of passing a message to a child component)
          */
      onClick={() => setDiscrepancy({ ...discrepancy })}
    >
      Resolve
    </button>
  )

  const displayDiscrepancy = (discrepancy: Discrepancy) => ({
    "SKU Code": discrepancy.lineItem.code,
    Name: discrepancy.lineItem.name,
    Unit: discrepancy.lineItem.unit,
    Price: discrepancy.lineItem.price,
    Quantity: discrepancy.lineItem.qty,
    Action: discrepancyAction(discrepancy),
  })

  const visualizeDiscrepancies = (
    type: string,
    discrepanciesTable: Array<Discrepancy>
  ) => (
    <div className={styles.discrepancy} key={type}>
      <h1>{formatType(type)}</h1>
      <p>
        {discrepanciesTable.length == 1 ? messages[type] : messagesPlural[type]}
      </p>
      <SimpleTable
        props={discrepanciesTable.map(displayDiscrepancy)}
        cellStyle={{
          maxWidth: "200px",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      ></SimpleTable>
    </div>
  )

  return (
    <div className={styles.container}>
      <HandleDiscrepancy
        instance={instance}
        discrepancy={discrepancy}
        handleDiscrepancy={(...params) => {
          setDiscrepancy(undefined)
          handleDiscrepancy(...params)
        }}
      ></HandleDiscrepancy>
      <LoaderContainer loading={unresolvedDiscrepancies === undefined}>
        {unresolvedDiscrepancies?.length > 0 ? (
          <>
            <div className={styles.discrepancy} key="intro">
              <h1>We found discrepancies while extracting document.</h1>
              <p>
                Please, review them and then click &quot;Complete Review&quot;:
              </p>
            </div>

            {Object.keys(discrepancyTable)
              .sort()
              .map((type) => {
                return visualizeDiscrepancies(type, discrepancyTable[type])
              })}
          </>
        ) : (
          <div className={styles.discrepancy}>
            <h1>There are no discrepancies in the document</h1>
            <p>You can now complete it.</p>
          </div>
        )}
      </LoaderContainer>
    </div>
  )
}
