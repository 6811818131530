import React, { useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import {
  exportMenuProfitabilityReport,
  getMenuProfitabilityReport,
  updateMenuProfitabilityReport,
} from "services/menu-profitability-report"
import { showError } from "services/toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartBar, faDollarSign } from "@fortawesome/pro-duotone-svg-icons"
import { faFileDownload, faSync } from "@fortawesome/pro-regular-svg-icons"
import { format } from "date-fns"
import { getExportFileName } from "services/export"
import MenuProfitabilityReportModal from "./MenuProfitabilityReportModal"
import { ModalContext } from "context/modal/ModalContext"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { getOrgNameById } from "services/user"
import Loader from "components/common/Loader/Loader"
import usePermissions from "hooks/usePermissions"
import ReactTooltip from "react-tooltip"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { getSale } from "services/sales"
import ReportNameCell from "components/baseList/cells/ReportName"

interface MenuProfitabilityReportProps {
  reportId: string
}

interface ReportData {
  periodFrom: string
  periodTo: string
  createdAt: string
  updatedAt: string
  organizations: string[]
  items: Item[]
  sales: { id: string }
}

interface Item {
  name: string
  soldQty: number
  cost: number
  totalCost: number
  profit: number
  profitPercentage: number
  totalSoldValue: number
  source: { refId: string; type: string }
}

const MenuProfitabilityReport: React.FC<MenuProfitabilityReportProps> = ({
  reportId,
}: MenuProfitabilityReportProps) => {
  // @ts-ignore
  const { organizations, organizationGroup, organizationPermissions } =
    useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("Sales")

  const [reportData, setReportData] = useState<ReportData | null>(null)
  const [saleData, setSaleData] = useState<{ from: string; to: string } | null>(
    null
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [generating, setGenerating] = useState<boolean>(false)

  const columns = [
    {
      label: "Sales item name",
      value: "name",
      width: "200px",
      className: "font-sansSemiBold",
    },
    {
      label: "Sold Qty",
      value: "soldQty",
      width: "100px",
    },
    {
      label: "Cost",
      value: "cost",
      width: "100px",
    },
    {
      label: "Total Cost",
      value: "totalCost",
      width: "100px",
    },
    { label: "Profit", value: "profit", width: "100px" },
    { label: "Profit %", value: "profitPercentage", width: "100px" },
    {
      label: "Total Sold Value",
      value: "totalSoldValue",
      width: "150px",
    },
  ]

  const totals = [
    { label: "Total cost", value: "totalCost" },
    { label: "Total profit", value: "totalProfit" },
    { label: "Total profit %", value: "totalProfitPercentage" },
    { label: "Total sold value", value: "totalSoldValue" },
  ]

  const saleFrom = saleData?.from
    ? format(new Date(saleData?.from), "dd/MM/yyyy")
    : null
  const saleTo = saleData?.to
    ? format(new Date(saleData?.to), "dd/MM/yyyy")
    : null

  const getReportData = async (regenerate = false) => {
    !regenerate && setLoading(true)
    regenerate && setGenerating(true)

    if (regenerate && reportData) {
      const updateParams = {
        periodFrom: reportData.periodFrom,
        periodTo: reportData.periodTo,
      }

      if (reportData.sales) {
        updateParams["sales"] = { id: reportData.sales.id }
      }
      const res = await updateMenuProfitabilityReport(reportId, updateParams)

      if (res?.message) {
        showError(res.message)
      }
    }

    const params = {
      organizations: organizationGroup,
    }
    const report = await getMenuProfitabilityReport(reportId, params)

    if (report?.message) {
      showError(report.message)
    } else {
      setReportData(report)
    }

    setLoading(false)
    setGenerating(false)
  }

  const getSaleData = async () => {
    if (!reportData || !reportData.sales?.id) {
      setSaleData(null)
      return
    }

    setLoading(true)
    const sale = await getSale(reportData.sales.id, {})

    if (sale?.message) {
      showError(sale.message)
    } else {
      setSaleData(sale)
    }

    setLoading(false)
  }

  const exportReport = async () => {
    const fileName = getExportFileName("menu-profitability", "", "xlsx")
    exportMenuProfitabilityReport(reportId, fileName)
  }

  useEffect(() => {
    getReportData()
  }, [])

  useEffect(() => {
    getSaleData()
  }, [reportData])

  return (
    <>
      <Helmet>
        <title>Menu Profitability Report</title>
      </Helmet>

      <Loader
        isLoading={loading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading report...
      </Loader>

      {!loading && (
        <div className="w-full flex flex-col h-full flex-grow overflow-hidden">
          <Header back title="Menu Profitability Report" />

          <div className="relative flex flex-col flex-grow w-full h-full overflow-hidden">
            <div className="px-6 py-1 flex items-center justify-between border-b flex-wrap gap-y-2">
              {reportData && (
                <ReportNameCell
                  data={reportData}
                  disabled={!permissionObj?.permissions.modify}
                  iconStyles={{
                    mainIcon: {
                      backgroundColor: "#E0FFFF",
                      color: "#0D847D",
                    },
                    subIcon: {
                      color: "#0D847D",
                    },
                  }}
                  editEnabled={permissionObj?.permissions.modify}
                  onEdit={() =>
                    modal.showModal(MenuProfitabilityReportModal, {
                      reportId: reportId,
                      initialValues: reportData,
                      onUpdated: () => {
                        getReportData()
                        getSaleData()
                      },
                    })
                  }
                  mainIcon={faChartBar}
                  subIcon={faDollarSign}
                >
                  {saleFrom && saleTo && (
                    <div className="text-xs text-gray-700">
                      {"Sale: "}
                      <span className="font-sansSemiBold">
                        {saleFrom} - {saleTo}
                      </span>
                    </div>
                  )}
                  {organizationPermissions?.general?.isMain &&
                    reportData?.organizations.length && (
                      <div className="text-xs text-gray-700">
                        {"Location: "}
                        <span className="font-sansSemiBold">
                          {getOrgNameById(
                            reportData?.organizations[0],
                            organizations
                          )}
                        </span>
                      </div>
                    )}
                </ReportNameCell>
              )}

              <div className="ml-auto flex items-center gap-x-8">
                <button
                  type="button"
                  // @ts-ignore
                  disabled={!permissionObj?.permissions.modify}
                  className="flex items-center text-orange-600 rounded hover:text-orange-500 font-sansSemiBold gap-x-2"
                  onClick={() => getReportData(true)}
                >
                  <FontAwesomeIcon icon={faSync} spin={generating} />
                  Refresh
                </button>

                <button
                  type="button"
                  className="button button--primaryGreen button--autoWidth"
                  onClick={exportReport}
                  // @ts-ignore
                  disabled={loading || !permissionObj?.permissions.read}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </button>
              </div>
            </div>

            {reportData?.items?.length === 0 ? (
              <div className="p-6 italic">No items data in this report.</div>
            ) : (
              <div className="overflow-auto w-full px-6 mt-0 flex-grow">
                <table className="w-full text-left">
                  <thead className="w-full">
                    <tr className=" items-center font-sansSemiBold py-2 sticky border-b top-0 bg-white">
                      {columns.map((column, i) => (
                        <th
                          key={i}
                          className="pr-6 py-2 relative font-sansSemiBold"
                          style={{ width: column.width }}
                        >
                          <span className="block text-sm w-full overflow-ellipsis whitespace-nowrap">
                            {column.label}
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="w-full">
                    {reportData?.items?.map((item: Item, i: number) => (
                      <tr
                        key={i}
                        className="items-center border-t last:border-b py-2"
                      >
                        {columns.map((column, i) => (
                          <td
                            className="truncate pr-6 py-2 text-primaryBlue text-sm"
                            key={i}
                            style={{ width: column.width }}
                          >
                            {column.value === "name" && (
                              <>
                                <span
                                  className={`${column.className} ${
                                    !item.source && "text-primaryGray"
                                  }`}
                                  data-for={"mapped_item_tooltip"}
                                  data-tip={
                                    item.source &&
                                    `This item is mapped to a ${item.source.type.toLowerCase()}.`
                                  }
                                >
                                  {item[column.value]}
                                </span>

                                {item.cost === null && (
                                  <span
                                    data-for="unmapped_item_tooltip"
                                    data-tip="This item is in the sales file but has not been mapped to a product/recipe/dish."
                                  >
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                      className="text-error ml-1"
                                    />
                                  </span>
                                )}
                              </>
                            )}

                            {column.value !== "name" && (
                              <>
                                {item[column.value] === null ? (
                                  "-"
                                ) : (
                                  <span
                                    className={
                                      column.className +
                                      " " +
                                      ((column.value === "profit" ||
                                        column.value === "profitPercentage") &&
                                      item[column.value] > 0
                                        ? "text-green-500"
                                        : item[column.value] < 0
                                        ? "text-red-500"
                                        : "")
                                    }
                                  >
                                    {item[column.value]}
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactTooltip
                  id="unmapped_item_tooltip"
                  type="light"
                  place="right"
                  effect="float"
                  border={true}
                  borderColor="#e2e8f0"
                />
                <ReactTooltip
                  id={"mapped_item_tooltip"}
                  type="light"
                  place="top"
                  effect="float"
                  border={true}
                  borderColor="#e2e8f0"
                />
              </div>
            )}

            <div className="px-6 py-3 border-t flex items-center gap-x-8 w-full justify-start bg-white">
              {reportData &&
                reportData?.items?.length !== 0 &&
                totals.map((total, i) => (
                  <div key={i} className="flex flex-col">
                    <span className="font-sansSemiBold font-semibold text-xs lg:text-sm text-gray-700">
                      {total.label}
                    </span>
                    <span className="font-sansSemiBold font-semibold text-xs lg:text-sm">
                      {reportData[total.value] === null
                        ? "-"
                        : reportData[total.value]}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MenuProfitabilityReport
