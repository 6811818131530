import React, { useRef, useState, useEffect } from "react"
import { useIntersection } from "react-use"
import { getServerImage } from "services/storage"
import PropTypes from "prop-types"
import { usePrevious } from "react-use"

import * as styles from "./ServerImage.module.css"

const ServerImage = ({ fileId, alt, children }) => {
  const intersectionRef = useRef(null)
  const refPlaceholder = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  })
  const [gettingImg, setGettingImg] = useState(false)
  const [imgSrc, setImgSrc] = useState(null)
  const previousFileId = usePrevious(fileId)

  const getImage = async () => {
    if (!fileId) {
      return new Error("No fileId")
    }
    setGettingImg(true)
    const src = await getServerImage(fileId)
    setImgSrc(src)
    setGettingImg(false)
  }

  useEffect(() => {
    if (previousFileId === fileId) {
      return
    }
    getImage()
  }, [fileId])

  useEffect(() => {
    if (
      !imgSrc &&
      intersection &&
      intersection.intersectionRatio >= 1 &&
      !gettingImg
    ) {
      getImage()
    }
    if (imgSrc) {
      refPlaceholder.current.remove()
    }
  }, [intersection, imgSrc, getImage])

  return (
    <div className={styles.imageWrapper} ref={intersectionRef}>
      <div className={styles.placeholder} ref={refPlaceholder}>
        {children}
      </div>
      {imgSrc && <img className={styles.image} src={imgSrc} alt={alt} />}
    </div>
  )
}

ServerImage.propTypes = {
  fileId: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.any,
}

export default ServerImage
