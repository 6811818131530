import React, { useState, useEffect } from "react"

interface TypingEffectProps {
  messages: [any]
  speed: number
  onFinished?: Function
}

const TypingEffect = ({
  messages,
  speed = 30,
  onFinished,
}: TypingEffectProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [currentText, setCurrentText] = useState("")
  const [messageHistory, setMessageHistory] = useState<JSX.Element[]>([])
  const [currentClassName, setCurrentClassName] = useState<string>("")

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (currentMessageIndex < messages.length) {
      timer = setTimeout(() => {
        const message = messages[currentMessageIndex]

        let text = ""

        new Array(message.props.children).map((element) => {
          if (Array.isArray(element)) {
            text += element.join("")
          } else {
            text += element
          }
        })

        if (currentText.length < text.length) {
          setCurrentText(text.substring(0, currentText.length + 1))
          setCurrentClassName(getClassName(message))
        } else {
          setMessageHistory([
            ...messageHistory,
            React.cloneElement(message, {
              key: currentMessageIndex,
              className: currentClassName,
            }),
          ])
          setCurrentMessageIndex(currentMessageIndex + 1)
          setCurrentText("")
          setCurrentClassName("")
        }
      }, speed) // Adjust typing speed here (in milliseconds)
    } else {
      if (onFinished) onFinished()
    }

    const element = document.getElementById("currentText")
    element?.scrollIntoView()

    return () => clearTimeout(timer)
  }, [
    currentText,
    currentMessageIndex,
    currentClassName,
    messageHistory,
    messages,
  ])

  useEffect(() => {
    setCurrentMessageIndex(0)
    setCurrentText("")
    setMessageHistory([])
  }, [messages])

  const getClassName = (element: JSX.Element): string => {
    const classNameProp = element.props.className
    if (classNameProp && typeof classNameProp === "string") {
      return classNameProp
    }
    return ""
  }

  return (
    <div>
      {messageHistory.map((message, index) =>
        React.cloneElement(message, { key: index })
      )}
      <p
        className={
          currentMessageIndex < messages.length ? currentClassName : ""
        }
        id="currentText"
      >
        {currentText}
      </p>
    </div>
  )
}

/*
//Example:
const ChatComponent = () => {
  const messages: ChatMessage[] = [
    <span className="greeting">Hello there!</span>,
    <span className="question">How can I assist you today?</span>,
    <span className="advice">Feel free to ask me anything.</span>
  ];

  return (
    <div>
      <h1>ChatGPT</h1>
      <TypingEffect messages={messages} />
    </div>
  );
};
*/

export default TypingEffect
