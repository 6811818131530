import { productMeasures } from "./constants"

const LITRE = "L"
const GAL = "gal"
const ML = "ml"
const CL = "cl"
const FL_OZ_UK = "fl_oz_UK"
const PT_UK = "pt_UK"
const HF_PT_UK = "hf_pt_UK"

const GRAM = "g"
const KG = "kg"
const LBS = "lbs"
const OUNCE = "oz"

const EACH = "each"
const PERCENTAGE = "%"

// volume
const GAL_TO_ML = 3785.411784
const CL_TO_ML = 10.0
const LITRE_TO_ML = 1000.0
const FL_OZ_UK_TO_ML = 28.4130625
const PT_UK_TO_ML = 568.261485
const HF_PT_UK_TO_ML = 284.1307425

const ML_TO_GAL = 0.0002641721
const ML_TO_CL = 0.1
const ML_TO_LITRE = 0.001
const ML_TO_FL_OZ_UK = 0.0351951
const ML_TO_PT_UK = 0.0017597533
const ML_TO_HF_PT_UK = 0.0035195065

//weight

const KG_TO_GRAM = 1000
const LBS_TO_GRAM = 453.59237
const OUNCE_TO_GRAM = 28.3495231

const GRAM_TO_KG = 0.001
const GRAM_TO_LBS = 0.00220462262
const GRAM_TO_OUNCE = 0.0352739619

const BOTTLE_MEASURES = [PERCENTAGE, ML, LITRE, CL, FL_OZ_UK]
const CAN_MEASURES = [PERCENTAGE, ML, LITRE, CL, FL_OZ_UK]
const KEG_MEASURES = [PERCENTAGE, LITRE, GAL]
const VOLUME_MEASURES = [PERCENTAGE, ML, LITRE, CL, FL_OZ_UK, GAL]
const WEIGHT_MEASURES = [PERCENTAGE, GRAM, KG, LBS, OUNCE]
const EACH_MEASURE = [EACH, PERCENTAGE]

export const convertPartialQtyToProductMeasure = (
  partialQty,
  partialQtyMeasure,
  productMeasure
) => {
  let partialQtyInBasicMeasure
  switch (partialQtyMeasure) {
    case LITRE:
      partialQtyInBasicMeasure = partialQty * LITRE_TO_ML
      break
    case GAL:
      partialQtyInBasicMeasure = partialQty * GAL_TO_ML
      break
    case CL:
      partialQtyInBasicMeasure = partialQty * CL_TO_ML
      break
    case PT_UK:
      partialQtyInBasicMeasure = partialQty * PT_UK_TO_ML
      break
    case HF_PT_UK:
      partialQtyInBasicMeasure = partialQty * HF_PT_UK_TO_ML
      break
    case FL_OZ_UK:
      partialQtyInBasicMeasure = partialQty * FL_OZ_UK_TO_ML
      break
    case KG:
      partialQtyInBasicMeasure = partialQty * KG_TO_GRAM
      break
    case OUNCE:
      partialQtyInBasicMeasure = partialQty * OUNCE_TO_GRAM
      break
    case LBS:
      partialQtyInBasicMeasure = partialQty * LBS_TO_GRAM
      break
    case GRAM:
      partialQtyInBasicMeasure = partialQty
      break
    case ML:
      partialQtyInBasicMeasure = partialQty
      break
    default:
      return "Not existing measure"
  }

  let partialQtyInProductMeasure
  switch (productMeasure) {
    case LITRE:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * ML_TO_LITRE
      break
    case GAL:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * ML_TO_GAL
      break
    case CL:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * ML_TO_CL
      break
    case FL_OZ_UK:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * ML_TO_FL_OZ_UK
      break
    case PT_UK:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * ML_TO_PT_UK
      break
    case HF_PT_UK:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * ML_TO_HF_PT_UK
      break
    case KG:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * GRAM_TO_KG
      break
    case OUNCE:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * GRAM_TO_OUNCE
      break
    case LBS:
      partialQtyInProductMeasure = partialQtyInBasicMeasure * GRAM_TO_LBS
      break
    case GRAM:
      partialQtyInProductMeasure = partialQtyInBasicMeasure
      break
    case ML:
      partialQtyInProductMeasure = partialQtyInBasicMeasure
      break
    default:
      return "Not existing anymore"
  }

  return partialQtyInProductMeasure
}

export const showCompatibleMeasures = (measure, unit) => {
  const isVolume = VOLUME_MEASURES.includes(measure)
  const isWeight = WEIGHT_MEASURES.includes(measure)

  if (unit === "Bottle") {
    return getOptions(BOTTLE_MEASURES)
  }

  if (unit === "Can") {
    return getOptions(CAN_MEASURES)
  }

  if (unit === "Keg") {
    return getOptions(KEG_MEASURES)
  }

  if ((unit === "Other" && isVolume) || isVolume) {
    return getOptions(VOLUME_MEASURES)
  }

  if ((unit === "Other" && isWeight) || isWeight) {
    return getOptions(WEIGHT_MEASURES)
  }

  if (measure === "each") {
    return getOptions(EACH_MEASURE)
  }

  return productMeasures
}

const getOptions = (measuresArray) => {
  return measuresArray.map((measure) =>
    productMeasures.find((productMeasure) => productMeasure.value === measure)
  )
}
