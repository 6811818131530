import React, { useState } from "react"
import Modal from "react-modal"

import AddWasteForm from "components/waste/AddWasteForm/AddWasteForm"
import AddRecipeWasteForm from "components/waste/AddRecipeWasteForm/AddRecipeWasteForm"
import AddDishWasteForm from "../AddDishWasteForm/AddDishWasteForm"
import { formatAsYearMonthDay } from "services/helpers"

const EditWasteModal = ({
  onRequestClose,
  onEdit,
  recipe,
  product,
  dish,
  waste,
  wasteGroup,
  ...otherProps
}) => {
  const [globalDate, setGlobalDate] = useState<string>(
    formatAsYearMonthDay(new Date(waste.timeOfRecord))
  )
  const onWasteEdit = (instance, wasteId, updatedData) => {
    if (onEdit) {
      onEdit(instance, wasteId, updatedData)
    }

    onRequestClose()
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      {product && (
        <AddWasteForm
          newProduct={product}
          onEdit={onWasteEdit}
          onCancel={onRequestClose}
          waste={waste}
          wasteGroup={wasteGroup}
          globalDate={globalDate}
          setGlobalDate={setGlobalDate}
          isEdit
          allowDateSelect={true}
        />
      )}
      {recipe && (
        <AddRecipeWasteForm
          newRecipe={recipe}
          onEdit={onWasteEdit}
          onCancel={onRequestClose}
          waste={waste}
          wasteGroup={wasteGroup}
          isEdit
        />
      )}
      {dish && (
        <AddDishWasteForm
          newDish={dish}
          onEdit={onWasteEdit}
          onCancel={onRequestClose}
          waste={waste}
          wasteGroup={wasteGroup}
          isEdit
        />
      )}
    </Modal>
  )
}

export default EditWasteModal
