import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { normalizeNullValues } from "services/helpers"
import TextInput from "../../forms/TextInput"
import { inviteUser } from "services/user"
import { showError, showSuccess } from "services/toast"
import Modal from "react-modal"
import PermissionMatrix from "components/auth/PermissionMatrix/PermissionMatrix"
import { getDefaultFeatures } from "services/features"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import * as styles from "./InviteForm.module.css"

const Schema = Yup.object().shape({
  username: Yup.string()
    .email("Email address is invalid")
    .required("Email address is required"),
  permissionsPerFeatures: Yup.object().nullable(),
})

const InviteForm = ({
  onRequestClose,
  loading,
  onUserInvited,
  ...otherProps
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [defaultValues, setDefaultValues] = useState({
    username: "",
    permissionsPerFeatures: {},
  })

  useEffect(() => {
    const abortController = new AbortController()
    const getDefaultPermissions = async () => {
      const params = {
        page: 0,
        size: 999,
        sort: "name,asc",
      }
      const res = await getDefaultFeatures(params, {
        signal: abortController.signal,
      })

      if (res) {
        let newObj = {
          ...defaultValues,
          permissionsPerFeatures: {},
        }
        res.content.forEach((i) => {
          newObj.permissionsPerFeatures[i.id] = {
            name: i.name,
            permissions: i.permissions,
          }
        })
        setDefaultValues(newObj)
      } else {
        showError("Could not get default permissions")
      }
    }

    getDefaultPermissions()
    return () => {
      abortController.abort()
    }
  }, [])

  const handleInvite = (data, { resetForm }) => {
    setIsSubmitting(true)
    const params = {
      username: data.username,
      permissionsPerFeatures: Object.keys(data.permissionsPerFeatures).map(
        (k) => {
          return {
            id: k,
            permissions: data.permissionsPerFeatures[k].permissions,
          }
        }
      ),
    }

    inviteUser(params)
      .then((res) => {
        if (!res.error) {
          showSuccess(`An invitation email has been sent to ${params.username}`)
          resetForm({})
          onUserInvited()
          onRequestClose()
        } else {
          showError(
            res.message || "Invite failed. Did you enter all required fields?"
          )
        }
        setIsSubmitting(false)
      })
      .catch((err) => {
        showError(
          err.message || "Invite failed. Did you enter all required fields?"
        )
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      isOpen
      portalClassName="invite"
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      {...otherProps}
    >
      <div className="overflow-hidden flex flex-col flex-grow">
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={normalizeNullValues(defaultValues)}
          validationSchema={Schema}
          onSubmit={handleInvite}
        >
          {({ values, isValid, setValues }) => (
            <Form className="overflow-hidden flex flex-col flex-grow">
              <div className="flex-shrink-0 py-3 px-4 border-b relative">
                <h2 className={styles.tabContentTitle}>Invite team member</h2>
                <button
                  type="button"
                  className="absolute  text-xl top-0 right-1 p-3 text-gray-700"
                  onClick={onRequestClose}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="row px-4 pb-3 flex-grow overflow-auto">
                <div className="form-group flex items-center w-full mb-3 pt-3">
                  <div className="input-container flex-grow">
                    <TextInput
                      required={true}
                      name={`username`}
                      label="Email address"
                      type="email"
                      placeholder="Email address *"
                      className="form-control rounded"
                    />
                  </div>
                </div>

                <h3 className={styles.permissionsTitle}>Permissions</h3>
                <PermissionMatrix
                  values={values}
                  setValues={setValues}
                  name={`permissionsPerFeatures`}
                />
              </div>

              <div className="w-full py-3 px-4 border-t flex items-center justify-end flex-shrink-0">
                <button
                  type="submit"
                  className="button button--primaryGreen"
                  style={{ width: "fit-content" }}
                  disabled={!isValid || isSubmitting}
                >
                  Invite
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

InviteForm.propTypes = {
  onRequestClose: PropTypes.func,
  loading: PropTypes.bool,
  onUserInvited: PropTypes.func,
}

export default InviteForm
