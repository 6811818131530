import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import { faFilter } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./ListFiltersModalButton.module.css"

const ListFiltersModalButton = ({ showFilters, filterCounter = null }) => {
  return (
    <div className="relative">
      <button
        onClick={() => showFilters()}
        className={`button whitespace-nowrap relative button--autoWidth button--paleBlue ${styles.button}`}
      >
        <FontAwesomeIcon icon={faFilter} className="lg:mr-2" />
        <span className="hidden  lg:flex">Filters </span>
      </button>
      {filterCounter > 0 && (
        <span className="text-center text-xs font-sansSemiBold font-semibold absolute -top-2 right-4 z-20 bg-primaryBlue text-white w-5 h-5 rounded-full block">
          {filterCounter}
        </span>
      )}
    </div>
  )
}

ListFiltersModalButton.propTypes = {
  showFilters: PropTypes.func,
}

export default ListFiltersModalButton
