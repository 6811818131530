import React, { useContext } from "react"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { navigate } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ConfirmCheckbox from "components/common/ConfirmCheckbox/ConfirmCheckbox"

import * as styles from "./GetStartedStep.module.css"

const cx = classNames.bind(styles)

const GetStartedStep = ({ step, onToggle, closeModal }) => {
  const { organization } = useContext(GlobalStateContext)
  const { name, checked, icon, subItems } = step

  const renderTitle = (step) => {
    switch (step) {
      case "id":
        return <span>Signup</span>
      case "orgId":
        return <span>Complete your profile</span>
      case "hasAddedProducts":
        return <span>Add your products</span>
      case "hasCheckedCorrectPricesAndBarcodes":
        return (
          <div>
            <span
              className={styles.link}
              onClick={() => navigateToPage("/dashboard/products/items")}
            >
              Check
            </span>{" "}
            if you have the correct prices and barcodes
          </div>
        )
      case "hasAddedStocktake":
        return (
          <span
            className={styles.link}
            onClick={() => navigateToPage("/dashboard/stock/stocktake")}
          >
            Complete a stocktake
          </span>
        )
      case "hasAddedRecipe":
        return (
          <span
            className={styles.link}
            onClick={() =>
              navigateToPage("/dashboard/products/cookbook/recipes")
            }
          >
            Setup your recipes
          </span>
        )
      case "hasAddedWaste":
        return (
          <span
            className={styles.link}
            onClick={() => navigateToPage("/dashboard/stock/waste")}
          >
            Record and monitor your waste
          </span>
        )
      default:
        return <span>No title</span>
    }
  }

  const renderDescription = (step) => {
    switch (step) {
      case "id":
        return <span>You are all done.</span>
      case "orgId":
        return (
          <span>
            Change your{" "}
            <span
              className={styles.link}
              onClick={() => navigateToPage("/dashboard/company-settings")}
            >
              Company Settings
            </span>
            , or edit your{" "}
            <span
              className={styles.link}
              onClick={() => navigateToPage("/dashboard/account-settings")}
            >
              Personal Details.
            </span>{" "}
          </span>
        )
      case "hasAddedProducts":
        return <span>There are three ways you can set up your products:</span>
      case "hasCheckedCorrectPricesAndBarcodes":
        return (
          <span>
            Make sure you have added the correct prices and barcodes on the
            products that you’ve added.
          </span>
        )

      case "hasAddedStocktake":
        return (
          <span>
            Do it fast and easy. Stock can be counted by as many people as you’d
            need. Simply{" "}
            <span
              className={styles.link}
              onClick={() =>
                navigateToPage("/dashboard/company-settings", {
                  initialTab: "users-details",
                })
              }
            >
              invite your Team
            </span>{" "}
            and get them to help.
          </span>
        )

      case "hasAddedRecipe":
        return (
          <span>
            Add your menu items to monitor profitability. Need help?{" "}
            <a
              className={styles.link}
              href={`mailto:support@growyze.com?subject=Get me started - recipes&body=I would like to add some recipes for ${organization.companyName}.`}
            >
              Get in touch
            </a>{" "}
            with us and we’ll get your recipes set up within 2 working days.
          </span>
        )

      case "hasAddedWaste":
        return (
          <span>Start recording your waste daily. Make stuff accountable.</span>
        )

      default:
        return <span>No description available</span>
    }
  }

  const renderSubItemTitle = (subItem) => {
    switch (subItem) {
      case "hasImportedProductTemplate":
        return (
          <div>
            Upload our sample products{" "}
            <span
              className={styles.link}
              onClick={() =>
                navigateToPage("/dashboard/products/items/import", {
                  uploadSampleProducts: true,
                })
              }
            >
              list
            </span>
          </div>
        )

      case "hasImportedOwnProducts":
        return (
          <div>
            Upload your own list of{" "}
            <span
              className={styles.link}
              onClick={() => navigateToPage("/dashboard/products/items/import")}
            >
              products
            </span>
          </div>
        )

      case "hasImportedInvoices":
        return (
          <div>
            Send us your suppliers{" "}
            <span
              onClick={() => {
                onToggle("hasAddedProducts", subItem.checked)
              }}
            >
              <a
                href={`mailto:support@growyze.com?subject=Get me started - invoices&body=Here are the invoices for ${organization.companyName}.`}
                className={styles.link}
              >
                invoices
              </a>
            </span>
          </div>
        )

      default:
        return <span>No description available</span>
    }
  }

  const navigateToPage = (location, options) => {
    closeModal()
    navigate(location, { state: options })
  }

  return (
    <div className={styles.container}>
      <div className="flex w-full pr-3">
        <div
          className={cx("icon", {
            checked,
          })}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="flex flex-col flex-1">
          <span
            className={cx("title", {
              checked,
            })}
          >
            {renderTitle(name)}
          </span>
          <div className={cx("description", { checked })}>
            {renderDescription(name)}
          </div>
          {subItems &&
            subItems.map((subItem, idx) => {
              return (
                <div key={idx} className={styles.subItem}>
                  <div
                    className={cx("subItemContent", {
                      checked,
                    })}
                  >
                    <span className="text-sm font-sansBold py-1">
                      {renderSubItemTitle(subItem.name)}
                    </span>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <div className={cx("checkBox", { withItems: subItems.length > 0 })}>
        <ConfirmCheckbox
          isChecked={checked !== null}
          clickHandler={() => onToggle(name, checked)}
        />
      </div>
    </div>
  )
}

GetStartedStep.propTypes = {
  step: PropTypes.object,
  onToggle: PropTypes.func,
  closeModal: PropTypes.func,
}

export default GetStartedStep
