import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AsideContext } from "context/aside/AsideContext"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import React, { useContext, useMemo } from "react"
import { calculateTotalCost } from "services/orders/orders"
import ProductsGlobalOrderOverview from "../ProductsGlobalOrderOverview/ProductsGlobalOrderOverview"

function ProductsGlobalCartButton() {
  const aside = useContext(AsideContext)
  const { globalOrder } = useContext(GlobalStateContext)

  const total = useMemo(
    () => calculateTotalCost(globalOrder),
    [globalOrder.products]
  )

  return globalOrder.products.length > 0 ? (
    <div
      onClick={() => {
        aside.showAside(ProductsGlobalOrderOverview, {
          headerText: "Your Cart",
        })
      }}
      className="z-50 flex cursor-pointer group flex-row bottom-6 left-4 lg:left-8 absolute bg-white shadow-2xl rounded-md overflow-hidden justify-center items-start border border-primaryGreen"
    >
      <div className="bg-primaryGreen pt-5 lg:pt-6 hover:translate-x-4 group-hover:bg-primaryGreenLighter transition-colors text-white px-3 pb-8 w-16 self-stretch relative flex justify-center items-center">
        <div className="lg:hidden absolute -mr-6 -mt-6 bg-white h-5 w-5 flex justify-center items-center rounded-full text-xs text-primaryBlue">
          {globalOrder.products.length}
        </div>
        <FontAwesomeIcon
          className="text-xl h-full w-auto"
          icon={faShoppingCart}
        />
        <div className="absolute lg:hidden bottom-0 left-0 text-sm font-sansSemiBold font-semibold w-full flex justify-center mb-1">
          {total}
        </div>
      </div>

      <div className="hidden lg:flex flex-col px-6 space-y-1 bg-white py-2 items-start">
        <span className="text-xs">{globalOrder.products.length} items</span>
        <h2>Total: {total}</h2>

        <button className="text-primaryBlue transition-colors group-hover:text-primaryGreenDarker text-sm font-semibold cursor-pointer">
          View cart
        </button>
      </div>
    </div>
  ) : null
}

export default ProductsGlobalCartButton
