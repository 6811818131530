import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faBuilding,
  faCity,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons"
import { faArrowRight, faCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"

interface OptionType {
  beforeHeaderContent?: JSX.Element
  icon: IconProp
  type: "enterprise" | "company"
  headerText: string
  subText?: string
  footerContent?: JSX.Element
}

function AccountType(props) {
  const { nextStep, setSelectedAccountType, selectedAccountType } = props

  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    selectedAccountType || null
  )

  useEffect(() => {
    setSelectedAccountType(selectedOption)
  }, [selectedOption])

  const options: OptionType[] = [
    {
      beforeHeaderContent: (
        <div
          data-for="welcome_tooltip"
          data-tip="The Main (admin) account can be used to manage suppliers, products, recipes & dishes across all business locations / areas"
          className="flex justify-start self-start items-center space-x-2 bg-gray-100 rounded-full px-2 py-1"
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-gray-600 text-xs"
          />
          <span className="text-xs text-gray-600">
            Includes &apos;main&apos; account
          </span>
        </div>
      ),
      type: "enterprise",
      headerText: "Create Enterprise Account",
      subText:
        "Suitable for larger businesses that want to manage multiple locations / areas",
      icon: faCity,
      footerContent: (
        <div>
          <span className="text-xs text-gray-600">
            * Fees apply to each account/area
          </span>
        </div>
      ),
    },
    {
      type: "company",
      headerText: "Create One Account only",
      subText:
        "Suitable for smaller businesses that want to manage a single location / one area",
      icon: faBuilding,
    },
  ]

  return (
    <div className="max-w-7xl w-full">
      <div className="flex flex-col items-center space-y-2 mb-8">
        <h2 className="text-4xl">Hi, welcome to growyze!</h2>
        <span className="text-xl">choose your account type</span>
      </div>

      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 px-4">
        {options.map((option) => {
          const isSelected = option.type === selectedOption?.type

          return (
            <button
              onClick={() => setSelectedOption(option)}
              key={option.type + "-OptionType"}
              style={{ height: 290 }}
              className={`relative border border-gray-400 rounded-md justify-center items-center flex flex-col p-4 ${
                isSelected && "border-primaryPink"
              }`}
            >
              {option?.beforeHeaderContent ?? ""}
              <FontAwesomeIcon icon={option.icon} className="text-7xl mt-6" />
              <div className="my-4">
                <h4 className="text-lg mb-2">{option.headerText}</h4>
                {option.subText && (
                  <p className="text-gray-700">{option.subText}</p>
                )}
              </div>
              {option.footerContent ?? ""}
              <div
                className={`absolute flex text-sm justify-center items-center right-3 top-3 rounded-full w-8 h-8 border-gray-400 border ${
                  isSelected && "border-primaryPink bg-primaryPink text-white"
                }`}
              >
                {isSelected && <FontAwesomeIcon icon={faCheck} />}
              </div>
            </button>
          )
        })}
      </div>
      <ReactTooltip
        place="top"
        className="max-w-xs w-full"
        type="light"
        id="welcome_tooltip"
      />
      <div className="flex justify-center mt-4">
        <button
          disabled={!selectedOption}
          className="button button--primaryPink button--autoWidth"
          onClick={nextStep}
        >
          Continue <FontAwesomeIcon icon={faArrowRight} />{" "}
        </button>
      </div>
    </div>
  )
}

export default AccountType
