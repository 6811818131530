import { subStockTakeHelper } from "services/stock-take/stock-take"
import dot from "services/dot-prop-immutable"
import { initialStockTake } from "context/global/InitialContext"

const stocktakeReducer = (state, action) => {
  switch (action.type) {
    /**
     * Main Stocktake actions
     */
    case "CREATE_NEW_STOCKTAKE": {
      const { stockTakeData } = action.payload
      return {
        ...state,
        newStockTake: stockTakeData,
      }
    }
    case "UPDATE_NEW_STOCKTAKE": {
      const { stockTakeData } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...stockTakeData,
          key: state.newStockTake.key,
          products: state.newStockTake.products
            ? state.newStockTake.products
            : [],
          subStockTakeReports: state.newStockTake.subStockTakeReports,
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_PRODUCT": {
      const { product } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          products: [product, ...state.newStockTake.products],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_RECIPE": {
      const { recipe } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          recipes: [recipe, ...state.newStockTake.recipes],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_PRODUCTS_FROM_SHEET": {
      const { stockTakeData, products, recipes } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...stockTakeData,
          products: products.concat([...state.newStockTake.products]),
          recipes: recipes.concat([...state.newStockTake.recipes]),
        },
      }
    }

    case "REMOVE_NEW_STOCKTAKE_PRODUCT": {
      const { productId, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const products = dot.get(state, `newStockTake${subPath}.products`)
      const productIndex = products.findIndex((p) => p.id == productId)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.products.${productIndex}.unsynced.remove`,
          true
        ),
      }
    }

    case "REMOVE_NEW_STOCKTAKE_PRODUCT_RESULT": {
      const { productId, subStockTakeId = false, success } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      let products = dot.get(state, `newStockTake${subPath}.products`)
      if (success) {
        products = products.filter((p) => p.id !== productId)
      } else {
        const productIndex = products.findIndex((p) => p.id == productId)
        products[productIndex].unsynced.remove = false
      }

      return {
        ...dot.set(state, `newStockTake${subPath}.products`, products),
      }
    }

    case "REMOVE_NEW_STOCKTAKE_RECIPE": {
      const { recipeId, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const recipes = dot.get(state, `newStockTake${subPath}.recipes`)
      const recipeIndex = recipes.findIndex((r) => r.id == recipeId)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.recipes.${recipeIndex}.unsynced.remove`,
          true
        ),
      }
    }

    case "REMOVE_NEW_STOCKTAKE_RECIPE_RESULT": {
      const { recipeId, subStockTakeId = false, success } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      let recipes = dot.get(state, `newStockTake${subPath}.recipes`)

      if (success) {
        recipes = recipes.filter((r) => r.id !== recipeId)
      } else {
        const recipeIndex = recipes.findIndex((r) => r.id == recipeId)
        recipes[recipeIndex].unsynced.remove = false
      }

      return {
        ...dot.set(state, `newStockTake${subPath}.recipes`, recipes),
      }
    }

    case "UPDATE_NEW_STOCKTAKE_PRODUCT": {
      const { product, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const products = dot.get(state, `newStockTake${subPath}.products`)
      const productIndex = products.findIndex((p) => p.id == product.id)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.products.${productIndex}`,
          product
        ),
      }
    }

    case "UPDATE_NEW_STOCKTAKE_RECIPE": {
      const { recipe, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const recipes = dot.get(state, `newStockTake${subPath}.recipes`)
      const recipeIndex = recipes.findIndex((r) => r.id == recipe.id)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.recipes.${recipeIndex}`,
          recipe
        ),
      }
    }

    case "UPDATE_NEW_STOCKTAKE_PRODUCTS": {
      const { products } = action.payload
      const currentProducts = [...state.newStockTake.products]
      const updatedStockTakeProductBarcodes = products.map((i) => i.barcode)
      const currentStockTakeProducts = currentProducts.filter(
        (p) => updatedStockTakeProductBarcodes.indexOf(p.barcode) < 0
      )
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          products: [...currentStockTakeProducts, ...products],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_RECIPES": {
      const { recipes } = action.payload
      const currentRecipes = [...state.newStockTake.recipes]
      const updatedStockTakeRecipeIds = recipes.map((i) => i.recipe.id)
      const currentStockTakeRecipes = currentRecipes.filter(
        (r) => updatedStockTakeRecipeIds.indexOf(r.recipe.id) < 0
      )
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          recipes: [...currentStockTakeRecipes, ...recipes],
        },
      }
    }

    case "FINALIZE_NEW_STOCKTAKE": {
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...action.payload,
          status: "COMPLETED",
        },
      }
    }

    /**
     * Substocktake actions
     */
    case "CREATE_NEW_STOCKTAKE_SUBREPORT": {
      const { stockTakeData } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [
            ...state.newStockTake.subStockTakeReports,
            stockTakeData,
          ],
        },
      }
    }
    case "UPDATE_NEW_STOCKTAKE_SUBREPORT": {
      const { stockTakeData, subStockTakeKey } = action.payload
      let currentReports = [...state.newStockTake.subStockTakeReports]
      currentReports = currentReports.map((r) => {
        if (r.key === subStockTakeKey) {
          return {
            ...r,
            ...stockTakeData,
            products: r.products,
            key: r.key,
          }
        } else {
          return r
        }
      })
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...currentReports],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_SUBREPORT_PRODUCT": {
      const { product, subStockTakeKey } = action.payload
      const newSubStockTakeReports = [
        ...state.newStockTake.subStockTakeReports.map((r) => {
          if (r.key === subStockTakeKey) {
            return {
              ...r,
              products: [product, ...r.products],
            }
          } else {
            return r
          }
        }),
      ]
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...newSubStockTakeReports],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_SUBREPORT_RECIPE": {
      const { recipe, subStockTakeKey } = action.payload
      const newSubStockTakeReports = [
        ...state.newStockTake.subStockTakeReports.map((r) => {
          if (r.key === subStockTakeKey) {
            return {
              ...r,
              recipes: [recipe, ...r.recipes],
            }
          } else {
            return r
          }
        }),
      ]
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...newSubStockTakeReports],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_SUBREPORT_PRODUCTS_FROM_SHEET": {
      const { stockTakeData, products, recipes, subStockTakeKey } =
        action.payload
      const newSubStockTakeReports = [
        ...state.newStockTake.subStockTakeReports.map((r) => {
          if (r.key === subStockTakeKey) {
            return {
              ...r,
              ...stockTakeData,
              products: products.concat([...r.products]),
              recipes: recipes.concat([...r.recipes]),
            }
          } else {
            return r
          }
        }),
      ]
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...newSubStockTakeReports],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SUBREPORT_RECIPE_QTY": {
      const { recipeId, subStockTakeId, qty } = action.payload
      const index = state.newStockTake.subStockTakeReports
        .map((r) => r.id)
        .indexOf(subStockTakeId)

      const newRecipes = [
        ...state.newStockTake.subStockTakeReports[index].recipes.map((r) => {
          if (r.id === recipeId) {
            return {
              ...r,
              totalQty: qty,
              totalCost: qty * r.recipe.totalCost,
            }
          } else {
            return r
          }
        }),
      ]

      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [
            ...state.newStockTake.subStockTakeReports.map((r) => {
              if (r.id === subStockTakeId) {
                return {
                  ...r,
                  recipes: [...newRecipes],
                }
              } else {
                return r
              }
            }),
          ],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SUBREPORT_PRODUCTS": {
      const { products, subStockTakeId } = action.payload
      const subStockTakeIndex =
        state.newStockTake.subStockTakeReports.findIndex(
          (r) => r.id == subStockTakeId
        )
      const currentProducts = [
        ...state.newStockTake.subStockTakeReports[subStockTakeIndex].products,
      ]
      const updatedStockTakeProductBarcodes = products.map((i) => i.barcode)
      const currentStockTakeProducts = currentProducts.filter(
        (p) => updatedStockTakeProductBarcodes.indexOf(p.barcode) < 0
      )

      const currentSubStockTakes = state.newStockTake.subStockTakeReports
      currentSubStockTakes[subStockTakeIndex].products = [
        ...products,
        ...currentStockTakeProducts,
      ]

      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...currentSubStockTakes],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SUBREPORT_RECIPES": {
      const { recipes, subStockTakeId } = action.payload

      const subStockTakeIndex = state.newStockTake.subStockTakeReports
        .map((r) => r.id)
        .indexOf(subStockTakeId)
      const currentRecipes = [
        ...state.newStockTake.subStockTakeReports[subStockTakeIndex].recipes,
      ]
      const updatedStockTakeRecipeIds = recipes.map((i) => i.recipe.id)
      const currentStockTakeRecipes = currentRecipes.filter(
        (r) => updatedStockTakeRecipeIds.indexOf(r.recipe.id) < 0
      )

      const currentSubStockTakes = state.newStockTake.subStockTakeReports.map(
        (r) => {
          if (r.id === subStockTakeId) {
            return {
              ...r,
              recipes: [...currentStockTakeRecipes, ...recipes],
            }
          } else {
            return r
          }
        }
      )

      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...currentSubStockTakes],
        },
      }
    }

    case "REMOVE_NEW_STOCKTAKE_SUBREPORT": {
      const { subStockTakeKey } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [
            ...state.newStockTake.subStockTakeReports.filter(
              (st) => st.key !== subStockTakeKey
            ),
          ],
        },
      }
    }

    case "RESET_NEW_STOCKTAKE": {
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...initialStockTake,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default stocktakeReducer
