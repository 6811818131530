// extracted by mini-css-extract-plugin
export var actions = "ConfirmDetailsItem-module--actions--reTE1";
export var checked = "ConfirmDetailsItem-module--checked--vo1fg";
export var confirmButtonHover = "ConfirmDetailsItem-module--confirmButtonHover--sHz3m";
export var item = "ConfirmDetailsItem-module--item--DSq4L";
export var itemAmount = "ConfirmDetailsItem-module--itemAmount--yj83N";
export var itemConfirm = "ConfirmDetailsItem-module--itemConfirm--HGIFW";
export var itemCount = "ConfirmDetailsItem-module--itemCount--UH6UA";
export var itemEdit = "ConfirmDetailsItem-module--itemEdit--VaCU5";
export var itemError = "ConfirmDetailsItem-module--itemError--ErlQ7";
export var itemLabel = "ConfirmDetailsItem-module--itemLabel--xUcZb";
export var itemMeasure = "ConfirmDetailsItem-module--itemMeasure--z2Bi0";
export var itemName = "ConfirmDetailsItem-module--itemName--8ANAd";
export var itemPrimary = "ConfirmDetailsItem-module--itemPrimary--WGbvz";
export var itemQty = "ConfirmDetailsItem-module--itemQty--xFlIA";
export var itemStatus = "ConfirmDetailsItem-module--itemStatus---e6Hj";
export var itemStatusError = "ConfirmDetailsItem-module--itemStatusError--rlbuB ConfirmDetailsItem-module--itemStatus---e6Hj";
export var itemWrapper = "ConfirmDetailsItem-module--itemWrapper--Pe5bQ";
export var manualBadge = "ConfirmDetailsItem-module--manualBadge--TnSjl";
export var mathSymbol = "ConfirmDetailsItem-module--mathSymbol--poMh+";