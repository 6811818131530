import React from "react"

interface SelectCellRowData {
  id: string
  groupId: string
  _isSub?: boolean
  preferred?: boolean
}

interface SelectCellProps {
  isSelected: boolean
  rowData: SelectCellRowData
  onChange(checked: boolean): void
}

const SelectCell = ({ isSelected, rowData, onChange }: SelectCellProps) => {
  const isSubElement = rowData._isSub || !rowData.preferred
  return (
    <div
      className={`${isSubElement && "pl-4 flex items-center justify-center"}`}
    >
      <input
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        name="itemSelect"
        className={`form-checkbox text-primaryPink ${isSubElement && "hidden"}`}
        checked={isSelected}
        // Products with no children are preferred by default
        disabled={!rowData.preferred || rowData._isSub}
      />
    </div>
  )
}

export default React.memo(SelectCell)
