import { faExclamation, faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faPencil } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InvoicedProductItem from "components/invoices/InvoicedProductItem/InvoicedProductItem"
import { format } from "date-fns"
import React, { useContext, useEffect, useMemo, useState } from "react"
import Select from "react-select"
import { formatQuantityValue } from "services/helpers"
import { Invoice, InvoicedProduct, AccountingAccount } from "services/invoices"
import {
  AccountingEditParams,
  AccountingProvider,
  getAccountingInvoicedProductDefaults,
  useAccountingRelationsRetriever,
} from "services/accounting"
import Loader from "components/common/Loader/Loader"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import InitialContext from "context/global/InitialContext"
import { getWidths } from "../AccountingHelpers"
import ReactTooltip from "react-tooltip"
import { showError } from "services/toast"

interface AccountingPublishFormProps {
  provider: AccountingProvider
  invoice: Invoice
  AccountingParams: AccountingEditParams
  invoicedProducts: InvoicedProduct[]
  onEditDetails(): void
  setAccountingParams(props: AccountingEditParams): void
  handleInvoiceItemChange(val, field: string, index: number): void
  handleInvoiceItemDefaults(defaults: any): void
  setDefaultAccountForLineItems(
    newAccount: AccountingAccount,
    prevAccount: AccountingAccount
  ): void
  taxDiscrepancy: number
}

const AccountingPublishForm: React.FunctionComponent<
  AccountingPublishFormProps
> = (props: AccountingPublishFormProps) => {
  const {
    provider,
    invoice,
    AccountingParams,
    invoicedProducts,
    onEditDetails,
    setAccountingParams,
    handleInvoiceItemChange,
    handleInvoiceItemDefaults,
    setDefaultAccountForLineItems,
    taxDiscrepancy,
  } = props

  const [loading, setLoading] = useState<boolean>(false)
  const { accounting } = useContext(GlobalStateContext)
  const [productDefaults, setProductDefaults] = useState(null)
  const { accounts, suppliers, taxRates, trackingCategories, taxRateZero } =
    accounting[provider.key] || InitialContext.accounting

  const providerKey = provider.key
  const retriever = useAccountingRelationsRetriever(provider)

  useEffect(() => {
    setLoading(true)
    //setAccountingInvoiceProductDefaults(false)
    // retriever will fill accounting[provider.key] array with needed values
    const getDefaults = async () => {
      return await getAccountingInvoicedProductDefaults(invoice.id)
    }

    retriever
      .get(["accounts", "taxRates", "suppliers", "trackingCategories"])
      .then((success) => {
        if (!success) {
          showError("Count not retrieve accounting options")
          setLoading(false)
        } else {
          getDefaults().then((data) => {
            setLoading(false)
            setProductDefaults(data.barcodeToProductAccounting)
          })
        }
      })
    return () => {
      setLoading(false)
      retriever.abort()
    }
  }, [])

  useEffect(() => {
    if (AccountingParams?.totalVat === 0) {
      setAccountingParams({
        ...AccountingParams,
        taxRate: taxRateZero?.[0],
      })
    }
  }, [taxRateZero])

  // first account / taxrates need to be set from dispach
  useEffect(() => {
    if (productDefaults != null) {
      handleInvoiceItemDefaultsWithDefaultFromStore(productDefaults)
    }
  }, [productDefaults])

  const handleInvoiceItemDefaultsWithDefaultFromStore = (defaults) => {
    // this function is need because the results from the API are not in the same format
    // values in the select dropdown need to be comparable values, so the order of the attributes is important
    // the function return selectable values.

    const newDefaults = {}
    Object.entries(defaults).map(([barcode, product]: [string, any]) => {
      const account = accounts.find(
        (account) =>
          account.id === product?.[providerKey + "Accounting"]?.account.id
      )
      const taxRate = taxRates.find(
        (taxRate) =>
          taxRate.name === product?.[providerKey + "Accounting"]?.taxRate.name
      )
      newDefaults[barcode] = {
        taxRate,
        account,
      }
    })
    handleInvoiceItemDefaults(newDefaults)
  }

  const handleTextareaChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setAccountingParams({ ...AccountingParams, description: value })
  }

  const taxRateOptions = [
    {
      label: "Default",
      options: [
        {
          name: "Extract from invoice",
          displayedAs: "Extract from invoice",
          taxType: null,
          taxRate: null,
        },
      ],
    },
    { label: "From Accounting", options: taxRates },
  ]

  const { lineWidth } = useMemo(() => getWidths(provider), [provider])

  return (
    <>
      <Loader isLoading={loading} />
      <div
        className={
          "relative overflow-hidden flex-grow " + (loading ? "opacity-50" : "")
        }
      >
        <div className="flex items-start flex-wrap px-4 md:px-4">
          <div className="mt-4 w-full flex flex-col md:w-1/2 px-2">
            <label className="font-sansSemiBold font-semibold mb-2">
              Invoice number<sup className="text-red-600">*</sup>
            </label>
            <div className="flex items-start">
              <span
                className={`font-bold font-sansBold ${
                  !invoice.invoiceNumber ? "text-red-600" : " text-primaryBlue"
                }`}
              >
                {invoice.invoiceNumber
                  ? invoice.invoiceNumber
                  : "Invoice number is required"}
              </span>
              <button
                type="button"
                onClick={() => onEditDetails()}
                className="text-gray-700 ml-2"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </div>
          </div>
          <div className="mt-4 mb-2 w-full flex flex-col md:w-1/2 px-2">
            <label className="font-sansSemiBold font-semibold mb-2">
              Due date<sup className="text-red-600">*</sup>
            </label>
            <div className="flex items-start">
              <span className="font-bold font-sansBold text-primaryBlue">
                {invoice.dueDate ? (
                  format(new Date(invoice.dueDate), "dd/MM/yyyy")
                ) : (
                  <>
                    <label
                      className="text-sm mb-4"
                      data-tip={"Issue Date will be used if Due Date is empty"}
                      data-for={"dueDateTooltip"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </label>
                    <ReactTooltip
                      id={"dueDateTooltip"}
                      type="light"
                      place="top"
                      effect="solid"
                      border={true}
                      borderColor="#e2e8f0"
                    />
                  </>
                )}
              </span>
              <button
                type="button"
                onClick={() => onEditDetails()}
                className="text-gray-700 ml-2"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </div>
          </div>
          <div className="mt-2 mb-2 w-full md:w-1/2 px-2">
            <div className="flex items-center flex-wrap">
              <label className="font-sansSemiBold font-semibold">
                Accounting supplier<sup className="text-red-600">*</sup>
              </label>
              {invoice.supplier && (
                <span className="bg-primaryBlueVeryLight rounded-full ml-auto block px-3 py-1 text-sm">
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className="mr-2 text-sm"
                  />
                  <span className="uppercase text-xs">Invoice supplier:</span>{" "}
                  <span className="font-semibold font-sansSemiBold text-primaryBlue">
                    {invoice.supplier.name}
                  </span>
                </span>
              )}
            </div>
            <Select
              name="supplier"
              options={suppliers}
              placeholder={"Select a supplier"}
              isDisabled={loading}
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt}
              onChange={(value) => {
                setAccountingParams({ ...AccountingParams, supplier: value })
              }}
              value={AccountingParams.supplier || null}
              className="mt-3"
            />
          </div>
          <div className="mt-2 mb-2 w-full md:w-1/2 px-2">
            <label className="font-sansSemiBold font-semibold">
              Accounting default account<sup className="text-red-600">*</sup>
            </label>
            <Select
              name="account"
              options={accounts}
              placeholder={"Select a default Accounting account"}
              isDisabled={loading}
              getOptionLabel={(opt) => `${opt.code} - ${opt.name}`}
              getOptionValue={(opt) => opt}
              onChange={(newAccount) => {
                const currentAccount = AccountingParams.account
                setDefaultAccountForLineItems(newAccount, currentAccount)
                setAccountingParams({
                  ...AccountingParams,
                  account: newAccount,
                })
              }}
              value={AccountingParams.account || null}
              className="mt-3"
            />
          </div>
          <div className="mt-2 mb-2 w-full px-2">
            <label className="font-sansSemiBold font-semibold mb-4 block">
              Accounting invoice items
            </label>
            <div className="flex items-center flex-wrap w-full mb-4">
              <div
                className={
                  "border-b pb-2 w-full text-xs text-gray-700 hidden md:block uppercase font-sansSemiBold font-semibold " +
                  lineWidth
                }
              >
                Item name
              </div>
              <div
                className={
                  "border-b pb-2 w-full pl-2 text-xs text-gray-700 hidden md:block font-sansSemiBold font-semibold uppercase " +
                  lineWidth
                }
              >
                Accounting Account
              </div>
              {provider.taxPerLine && (
                <div
                  className={
                    "border-b pb-2 w-full pl-2 text-xs text-gray-700 hidden md:block font-sansSemiBold font-semibold uppercase " +
                    lineWidth
                  }
                >
                  Tax Rate
                </div>
              )}
              {provider.categoryPerLine && (
                <div
                  className={
                    "border-b pb-2 w-full pl-2 text-xs text-gray-700 hidden md:block font-sansSemiBold font-semibold uppercase " +
                    lineWidth
                  }
                >
                  {provider.categoryLabel}
                </div>
              )}
              {invoice.products.map((item, index) => {
                return (
                  <div
                    className={`flex items-center flex-wrap w-full ${
                      index % 2 === 0 ? "bg-gray-50" : ""
                    }`}
                    key={`${item.barcode}-${index}`}
                  >
                    <div className={"w-full py-2 px-2 " + lineWidth}>
                      <InvoicedProductItem item={item} />
                    </div>
                    <div className={"w-full py-2 px-2 " + lineWidth}>
                      <Select
                        name={`account-invoice-item-${index}`}
                        options={accounts}
                        placeholder={"Account (optional)"}
                        isDisabled={loading}
                        getOptionLabel={(opt) => `${opt.code} - ${opt.name}`}
                        getOptionValue={(opt) => opt}
                        onChange={(val) => {
                          handleInvoiceItemChange(val, "account", index)
                          handleInvoiceItemChange(val.taxRate, "taxRate", index)
                        }}
                        value={
                          invoicedProducts[index][providerKey]?.account || null
                        }
                      />
                    </div>
                    {provider.taxPerLine && (
                      <div className={"w-full py-2 px-2 " + lineWidth}>
                        <Select
                          name={`taxRate-invoice-item-${index}`}
                          options={taxRateOptions}
                          placeholder={"Tax rate (optional)"}
                          isDisabled={loading}
                          getOptionLabel={provider.taxOptionLabelRenderFn}
                          getOptionValue={(opt) => opt}
                          onChange={(val) =>
                            handleInvoiceItemChange(val, "taxRate", index)
                          }
                          value={
                            invoicedProducts[index][providerKey]?.taxRate ||
                            null
                          }
                        />
                      </div>
                    )}
                    {provider.categoryPerLine && (
                      <div className={"w-full py-2 px-2 " + lineWidth}>
                        <Select
                          name={`category-invoice-item-${index}`}
                          options={trackingCategories}
                          placeholder={provider.categoryLabel + " (optional)"}
                          isDisabled={loading}
                          getOptionLabel={provider.categoryOptionLabelRenderFn}
                          getOptionValue={(opt) => opt}
                          onChange={(val) =>
                            handleInvoiceItemChange(
                              val,
                              "trackingCategory",
                              index
                            )
                          }
                          value={
                            invoicedProducts[index][providerKey]
                              ?.trackingCategory || null
                          }
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mb-4 w-full px-2 flex flex-col">
            <label className="font-sansSemiBold font-semibold mb-1">
              Total Net Cost<sup className="text-red-600">*</sup>
            </label>
            <div className="input-container input-container--left input-container--right">
              <input
                required={true}
                name="netCost"
                type="number"
                value={AccountingParams.netCost ?? ""}
                placeholder="Total NET"
                className="form-control form-control--smaller form-control--first form-control--last"
                onChange={(e) => {
                  setAccountingParams({
                    ...AccountingParams,
                    netCost: formatQuantityValue(e.target.value),
                  })
                }}
              />
            </div>
          </div>
          {taxRateOptions && (
            <div className="mb-4 w-full md:w-1/2 px-2 flex flex-col relative">
              <label className="font-sansSemiBold font-semibold mb-1">
                Tax rate
              </label>
              <Select
                name="taxRate"
                options={taxRateOptions}
                placeholder={"Select a tax rate"}
                maxMenuHeight={200}
                isDisabled={loading}
                getOptionLabel={provider.taxOptionLabelRenderFn}
                getOptionValue={(opt) => opt}
                onChange={(value) => {
                  setAccountingParams({
                    ...AccountingParams,
                    taxRate: value ? value : null,
                  })
                }}
                value={
                  AccountingParams?.taxRate
                    ? AccountingParams.taxRate
                    : taxRateOptions[0]?.options[0]
                }
              />
            </div>
          )}
          <div className="mb-4 w-full md:w-1/2 px-2 flex flex-col">
            <label className="font-sansSemiBold font-semibold mb-1">
              Total Tax (from invoice)
            </label>
            <div className="input-container input-container--left input-container--right">
              <input
                required={true}
                name="totalVat"
                type="number"
                placeholder="Total tax"
                value={AccountingParams.totalVat ?? ""}
                className="form-control form-control--smaller form-control--first form-control--last"
                onChange={(e) => {
                  setAccountingParams({
                    ...AccountingParams,
                    totalVat: formatQuantityValue(e.target.value),
                  })
                }}
              />
            </div>
          </div>

          {taxDiscrepancy != 0 && (
            <div className="text-red-600 block px-2 pb-4 text-lg">
              <b>Incorrect tax</b> from line items. Discrepancy:{" "}
              <b>{taxDiscrepancy}</b>
            </div>
          )}
          <div className="mb-4 w-full px-2">
            <label className="font-sansSemiBold font-semibold mb-2 block">
              Accounting description
            </label>
            <textarea
              className={`form-control border rounded border-gray-400 w-full mb-4 text-left`}
              style={{ resize: "none" }}
              rows={6}
              value={AccountingParams.description ?? ""}
              onChange={handleTextareaChange}
              placeholder={`Add a description in Accounting`}
            ></textarea>
          </div>
          {!invoice.invoiceNumber && (
            <span className="text-red-600 block px-2 py-2">
              Invoice number is required to publish to Accounting
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountingPublishForm
