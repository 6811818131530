import { get, post, put, remove } from "../api"
import { CategoryParams } from "../types"
import { exportResponse } from "../export"
import { uuidv4 } from "../helpers"

export const getStockTakes = (params) => {
  return get("/stock-take/report", params)
}

export const createStockTake = (params) => {
  return post(`/stock-take/report`, params)
}

export const createSubStockTake = (stockTakeId, params) => {
  return post(`/stock-take/report/${stockTakeId}/sub-report`, params)
}

export const getStockTake = (stockTakeId, params = {}) => {
  return get(`/stock-take/report/${stockTakeId}`, params)
}

export const getSubStockTake = (stockTakeId, subStockTakeId, params = {}) => {
  return get(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}`,
    params
  )
}

export const updateStockTake = (stockTakeId, params) => {
  return put(`/stock-take/report/${stockTakeId}`, params)
}

export const updateSubStockTake = (stockTakeId, subStockTakeId, params) => {
  return put(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}`,
    params
  )
}

export const mergeStockTake = (stockTakeId, completedAt) => {
  return get(
    `/stock-take/report/${stockTakeId}/merge?completedAt=${completedAt}`
  )
}

export const deleteStockTake = (stockTakeId) => {
  return remove(`/stock-take/report/${stockTakeId}`)
}

export const deleteSubStockTake = (stockTakeId, subStockTakeId) => {
  return remove(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}`
  )
}

export const updateStockTakeProduct = (stockTakeId, barcode, params) => {
  // please note in rare cases the barcode can contain spaces (encode to keep these)
  return put(
    `/stock-take/report/${stockTakeId}/products/${encodeURIComponent(barcode)}`,
    params
  )
}

export const updateSubStockTakeProduct = (
  stockTakeId,
  subStockTakeId,
  barcode,
  params
) => {
  // please note in rare cases the barcode can contain spaces (encode to keep these)
  return put(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products/${encodeURIComponent(
      barcode
    )}`,
    params
  )
}

export const deleteStockTakeProduct = (stockTakeId, barcode) => {
  // please note in rare cases the barcode can contain spaces (encode to keep these)
  return remove(
    `/stock-take/report/${stockTakeId}/products/${encodeURIComponent(barcode)}`
  )
}

export const deleteSubStockTakeProduct = (
  stockTakeId,
  subStockTakeId,
  barcode
) => {
  return remove(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products/${encodeURIComponent(
      barcode
    )}`
  )
}

export const completeStockTake = (stockTakeId) => {
  return post(`/stock-take/report/${stockTakeId}/complete`)
}

export const completeSubStockTake = (stockTakeId, subStockTakeId) => {
  return post(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/complete`
  )
}

export const getStockTakeProducts = (stockTakeId, params) => {
  return get(`/stock-take/report/${stockTakeId}/products`, params)
}

export const getSubStockTakeProducts = (
  stockTakeId,
  subStockTakeId,
  params
) => {
  return get(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products`,
    params
  )
}

export const addStockTakeProducts = (stockTakeId, params) => {
  return post(`/stock-take/report/${stockTakeId}/products`, params)
}

export const addSubStockTakeProducts = (
  stockTakeId,
  subStockTakeId,
  params
) => {
  return post(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products`,
    params
  )
}

// Recipes

export const getRecipesFromMainReport = (stockTakeId, params) => {
  return get(`/stock-take/report/${stockTakeId}/recipes`, params)
}

export const getRecipesFromSubStockTakeReport = (
  stockTakeId,
  subStockTakeId,
  params
) => {
  return get(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes`,
    params
  )
}

export const addStockTakeRecipes = (stockTakeId, params) => {
  return post(`/stock-take/report/${stockTakeId}/recipes`, params)
}

export const addSubStockTakeRecipes = (stockTakeId, subStockTakeId, params) => {
  return post(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes`,
    params
  )
}

export const updateRecipeInMainReport = (stockTakeId, recipeId, params) => {
  return put(`/stock-take/report/${stockTakeId}/recipes/${recipeId}`, params)
}

export const updateRecipeInSubStockTakeReport = (
  stockTakeId,
  subStockTakeId,
  recipeId,
  params
) => {
  return put(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes/${recipeId}`,
    params
  )
}

export const deleteRecipeFromMainReport = (stockTakeId, recipeId) => {
  return remove(`/stock-take/report/${stockTakeId}/recipes/${recipeId}`)
}

export const deleteRecipeFromSubStockTakeReport = (
  stockTakeId,
  subStockTakeId,
  recipeId
) => {
  return remove(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes/${recipeId}`
  )
}

// OTHER

export const downloadStockTakeReport = async (
  stockTakeReportId,
  fileType,
  fileName,
  subStockTakePortId = null
) => {
  const subReport = subStockTakePortId
    ? `/sub-report/${subStockTakePortId}`
    : ""
  const response = await get(
    `/stock-take/report/${stockTakeReportId}${subReport}/export-as-${fileType}?fileName=${fileName}`,
    {},
    {
      download: true,
    }
  )
  exportResponse(response, fileName)
}

export const createReport = (
  endpoint:
    | "/gp-reports"
    | "/stock-take/cogs-reports"
    | "/stock-take/discrepancy-report",
  stocktakeReportId: string | undefined,
  openingStockTakeReportId: string,
  salesId: string | null = null,
  insights = false,
  theoretical = false,
  endDate = undefined,
  params: { filterCategories?: CategoryParams } = {
    filterCategories: undefined,
  }
) => {
  const queryParams = {}

  if (salesId) {
    queryParams["salesId"] = salesId
  }

  // This report is being generated from the 'Insights' section
  if (insights) {
    queryParams["insights"] = insights
  }

  if (theoretical) {
    queryParams["theoretical"] = theoretical
  }

  if (stocktakeReportId) {
    // Only for stock discrepancy reports, this param is named differently (= BE inconsistancy)
    if (endpoint !== "/stock-take/discrepancy-report") {
      queryParams["closingStockTakeReportId"] = stocktakeReportId
    } else {
      queryParams["stockTakeReportId"] = stocktakeReportId
    }
  }

  if (openingStockTakeReportId) {
    queryParams["openingStockTakeReportId"] = openingStockTakeReportId
  }

  if (endDate) {
    queryParams["periodTo"] = endDate
  }

  const queryParamsToUrl = new URLSearchParams(queryParams).toString()

  return post(`${endpoint}?${queryParamsToUrl}`, params.filterCategories)
}

export const createDiscrepancyReport = (
  stockTakeId: string | undefined,
  openingStockTakeReportId: string,
  salesId: string | null = null,
  insights = false,
  theoretical = false,
  endDate = undefined,
  params: { filterCategories?: CategoryParams } = {
    filterCategories: undefined,
  }
) => {
  const queryParams = {}

  if (salesId) {
    queryParams["salesId"] = salesId
  }

  // This report is being generated from the 'Insights' section
  if (insights) {
    queryParams["insights"] = insights
  }

  if (theoretical) {
    queryParams["theoretical"] = theoretical
  }

  if (stockTakeId) {
    queryParams["stockTakeReportId"] = stockTakeId
  }

  if (openingStockTakeReportId) {
    queryParams["openingStockTakeReportId"] = openingStockTakeReportId
  }

  if (endDate) {
    queryParams["periodTo"] = endDate
  }

  const queryParamsToUrl = new URLSearchParams(queryParams).toString()

  return post(
    `/stock-take/discrepancy-report?${queryParamsToUrl}`,
    params.filterCategories
  )
}

export const updateReportById = (
  endpoint:
    | "/gp-reports"
    | "/stock-take/cogs-reports"
    | "/stock-take/discrepancy-report",
  currentReportId: string,
  stocktakeReportId?: string | null,
  openingStockTakeReportId?: string | null,
  salesId?: string | null,
  insights = false,
  theoretical = false,
  endDate = undefined,
  params: { filterCategories?: CategoryParams } = {
    filterCategories: undefined,
  }
) => {
  const queryParams = {}

  if (salesId) {
    queryParams["salesId"] = salesId
  }

  // This report is being generated from the 'Insights' section
  if (insights) {
    queryParams["insights"] = insights
  }

  if (theoretical) {
    queryParams["theoretical"] = theoretical
  }

  if (stocktakeReportId) {
    // Only for stock discrepancy reports, this param is named differently (= BE inconsistancy)
    if (endpoint !== "/stock-take/discrepancy-report") {
      queryParams["closingStockTakeReportId"] = stocktakeReportId
    } else {
      queryParams["stockTakeReportId"] = stocktakeReportId
    }
  }

  if (openingStockTakeReportId) {
    queryParams["openingStockTakeReportId"] = openingStockTakeReportId
  }

  if (endDate) {
    queryParams["periodTo"] = endDate
  }

  const queryParamsToUrl = new URLSearchParams(queryParams).toString()

  return put(
    `${endpoint}/${currentReportId}?${queryParamsToUrl}`,
    params.filterCategories
  )
}

export const updateDiscrepancyReportById = (
  discrepancyReportId: string,
  stocktakeReportId?: string | null,
  openingStockTakeReportId?: string | null,
  salesId?: string | null,
  insights = false,
  theoretical = false,
  periodTo = undefined,
  params: { filterCategories?: CategoryParams } = {
    filterCategories: undefined,
  }
) => {
  const queryParams = {}

  if (salesId) {
    queryParams["salesId"] = salesId
  }

  // This report is being generated from the 'Insights' section
  if (insights) {
    queryParams["insights"] = insights
  }

  if (theoretical) {
    queryParams["theoretical"] = theoretical
  }

  if (stocktakeReportId) {
    queryParams["stockTakeReportId"] = stocktakeReportId
  }

  if (openingStockTakeReportId) {
    queryParams["openingStockTakeReportId"] = openingStockTakeReportId
  }

  if (periodTo) {
    queryParams["periodTo"] = periodTo
  }

  const queryParamsToUrl = new URLSearchParams(queryParams).toString()

  return put(
    `/stock-take/discrepancy-report/${discrepancyReportId}?${queryParamsToUrl}`,
    params.filterCategories
  )
}

export const getDiscrepancyReport = (discrepancyReportId, params) => {
  return get(`/stock-take/discrepancy-report/${discrepancyReportId}`, params)
}

export const updateDiscrepancyReport = (stockTakeId, params) => {
  return put(
    `/stock-take/discrepancy-report?stockTakeReportId=${stockTakeId}`,
    params
  )
}

export const deleteDiscrepancyReport = (discrepancyReportId) => {
  return remove(`/stock-take/discrepancy-report/${discrepancyReportId}`)
}

export const getDiscrepancyReportItems = (discrepancyReportId, params) => {
  return get(
    `/stock-take/discrepancy-report/${discrepancyReportId}/discrepancy-data`,
    params
  )
}

export const downloadDiscrepancyReport = async (
  discrepancyReportId,
  fileType,
  fileName
) => {
  const response = await get(
    `/stock-take/discrepancy-report/${discrepancyReportId}/export-as-${fileType}?fileName=${fileName}`,
    {},
    {
      download: true,
    }
  )
  exportResponse(response, fileName)
}

// Stocktake sheets (templates)

export const getStockTakeSheets = (params) => {
  return get("/stock-take/report-templates", params)
}

export const createStockTakeSheet = (params) => {
  return post("/stock-take/report-templates", params)
}

export const getStockTakeSheet = (stockTakeSheetId) => {
  return get(`/stock-take/report-templates/${stockTakeSheetId}`)
}

export const updateStockTakeSheet = (stockTakeSheetId, params) => {
  return put(`/stock-take/report-templates/${stockTakeSheetId}`, params)
}

export const refreshStockTakeSheet = (stockTakeSheetId) => {
  return put(`/stock-take/report-templates/${stockTakeSheetId}/refresh`)
}

export const deleteStockTakeSheet = (stockTakeSheetId) => {
  return remove(`/stock-take/report-templates/${stockTakeSheetId}`)
}

export const exportStockTakeSheetsXlsx = async (stockTakeSheetId, fileName) => {
  const response = await get(
    `/stock-take/report-templates/${stockTakeSheetId}/export-as-xlsx?fileName=${fileName}`,
    {},
    {
      download: true,
    }
  )
  exportResponse(response, fileName)
}

// COGS

export const getCOGSReportsByCriteria = (params) => {
  return get("/stock-take/cogs-reports", params)
}

export const createCOGSReport = (params) => {
  const salesIdString = params.salesId ? `&salesId=${params.salesId}` : ""
  return post(
    `/stock-take/cogs-reports?closingStockTakeReportId=${params.closingStockTakeReportId}&openingStockTakeReportId=${params.openingStockTakeReportId}${salesIdString}`,
    params.filterCategories
  )
}

export const getCOGSReportById = (cogsReportId, params) => {
  return get(`/stock-take/cogs-reports/${cogsReportId}`, params)
}

export const updateCOGSReport = (params) => {
  const salesIdString = params.salesId ? `&salesId=${params.salesId}` : ""
  return put(
    `/stock-take/cogs-reports/${params.cogsReportId}?closingStockTakeReportId=${params.closingStockTakeReportId}&openingStockTakeReportId=${params.openingStockTakeReportId}${salesIdString}`,
    params.filterCategories
  )
}

export const deleteCOGSReport = (cogsReportId) => {
  return remove(`/stock-take/cogs-reports/${cogsReportId}`)
}

export const exportCOGSReport = async (
  cogsReportId,
  fileName,
  downloadFormat,
  params,
  subUrl = ""
) => {
  const response = await get(
    `/stock-take/cogs-reports/${cogsReportId}${subUrl}/export-as-${downloadFormat}?fileName=${fileName}`,
    params,
    {
      download: true,
    }
  )
  exportResponse(response, fileName)
}

export const getCOGSReportProducts = (cogsReportId, params) => {
  return get(`/stock-take/cogs-reports/${cogsReportId}/products`, params)
}

// BELOW MIN QTY
export const getAllStockBelowMinQtyReports = () => {
  return get("/stock-below-min-qty-reports")
}

export const createBelowMinQtyReport = () => {
  return post("/stock-below-min-qty-reports")
}

export const getStockBelowMinQtyReport = (stockBelowMinQtyReportId) => {
  return get(`/stock-below-min-qty-reports/${stockBelowMinQtyReportId}`)
}

export const getStockBelowMinQtyReportProducts = (
  stockBelowMinQtyReportId,
  params
) => {
  return get(
    `/stock-below-min-qty-reports/${stockBelowMinQtyReportId}/products`,
    params
  )
}

export const updateStockBelowMinQtyReport = (stockBelowMinQtyReportId) => {
  return put(`/stock-below-min-qty-reports/${stockBelowMinQtyReportId}`)
}

export const sendEmailForStockBelowMinQty = (
  stockBelowMinQtyReportId,
  username
) => {
  return post(
    `/stock-below-min-qty-reports/${stockBelowMinQtyReportId}/email-me?username=${username}`
  )
}

/** ----------------------------------------------------------------------------
 * HELPER FUNCTIONS
 ---------------------------------------------------------------------------- */

/**
 * Given a stocktake, dispatches the "RESET_NEW_STOCKTAKE" and "CREATE_NEW_STOCKTAKE"
 * actions to set the stocktake as the one being edited. Also dispatches the
 * "CREATE_NEW_STOCKTAKE_SUBREPORT" action for each subreport in the stocktake.
 *
 * @param {object} stocktake - The stocktake to set as the one being edited.
 * @param {function} dispatch - The dispatch function to use for dispatching actions.
 */
export const setStockTakeContextData = (
  stocktake: any,
  dispatch: any,
  totalDiscrepancyAmount?: number,
  discrepancyReportId?: string
) => {
  dispatch({ type: "RESET_NEW_STOCKTAKE" })

  const params = {
    id: stocktake.id,
    name: stocktake.name,
    createdAt: stocktake.createdAt,
    completedAt: stocktake.completedAt,
    note: stocktake.note,
    status: stocktake.status,
    totalAmount: stocktake.totalAmount,
    totalDiscrepancyAmount: totalDiscrepancyAmount, // optional
    discrepancyReportId: discrepancyReportId, // optional
    key: uuidv4(),
    unsynced: false,
    products: [],
    recipes: [],
    subStockTakeReports: [],
  }
  dispatch({
    type: "CREATE_NEW_STOCKTAKE",
    payload: {
      stockTakeData: params,
    },
  })

  for (let i = 0; i < stocktake.subStockTakeReports.length; i++) {
    const subRep = stocktake.subStockTakeReports[i]

    dispatch({
      type: "CREATE_NEW_STOCKTAKE_SUBREPORT",
      payload: {
        stockTakeData: {
          ...subRep,
          key: uuidv4(),
          products: [],
          recipes: [],
          unsynced: false,
        },
      },
    })
  }
}

export const subStockTakeHelper = (state, subStockTakeId) => {
  const subStockTakeIndex = state.newStockTake.subStockTakeReports.findIndex(
    (s) => s.id == subStockTakeId
  )
  return subStockTakeId && subStockTakeIndex !== -1
    ? `.subStockTakeReports.${subStockTakeIndex}`
    : ""
}
