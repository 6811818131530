import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { getInitialsFromString } from "services/helpers"

import * as styles from "./StockTransferComments.module.css"
const cx = classNames.bind(styles)

interface StockTransferCommentsProps {
  transfer: any
  product: any
  onSave: Function
  onClose: Function
  isOutgoing: boolean
}

const StockTransferComments = ({
  transfer,
  product,
  onSave,
  onClose,
  isOutgoing,
}: StockTransferCommentsProps) => {
  const { status, senderOrg, receiverOrg } = transfer
  const { senderComment, receiverComment } = product
  const inputRef = useRef(null)
  const [comment, setComment] = useState(null)

  const currentOrg = {
    name: isOutgoing ? senderOrg.companyName : receiverOrg.companyName,
    comment: isOutgoing ? senderComment : receiverComment,
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setComment(value)
  }

  const getOtherComment = () => {
    if (isOutgoing) {
      return (
        receiverComment ||
        transfer.products.find((p) => p.source?.refId === product.id)
          ?.receiverComment
      )
    } else {
      const list =
        product.source?.type === "RECIPE"
          ? transfer.recipes
          : product.source?.type === "DISH"
          ? transfer.dishes
          : []

      return (
        senderComment ||
        list.find((item) => item.id === product.source?.refId)?.senderComment
      )
    }
  }

  const otherOrg = {
    name: isOutgoing ? receiverOrg.companyName : senderOrg.companyName,
    comment: getOtherComment(),
  }

  useEffect(() => {
    setComment(currentOrg.comment)
  }, [])

  return (
    <div className="flex-grow w-full overflow-hidden">
      <button
        type="button"
        onClick={() => onClose()}
        className="text-gray-700 absolute right-0 top-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>

      <div className="p-6">
        {status !== "DRAFT" && otherOrg.comment && (
          <>
            <div className=" font-sansSemiBold flex items-center">
              <div className={styles.avatar}>
                {getInitialsFromString(otherOrg.name)}
              </div>
              <div className="text-gray-800 text-sm flex flex-col ml-3">
                {otherOrg.name}
              </div>
            </div>
            <div className={styles.commentBox}>{otherOrg.comment}</div>
          </>
        )}
        {currentOrg.name && (
          <div className="flex flex-col items-end">
            <div className="text-right font-sansSemiBold flex justify-end items-center">
              <div className="text-primaryBlue text-sm flex flex-col mr-3">
                {currentOrg.name}
                <span className="text-xs text-gray-600">Your comment</span>
              </div>
              <div className={cx(styles.avatar, styles.avatarCurrent)}>
                {getInitialsFromString(currentOrg.name)}
              </div>
            </div>
            <textarea
              rows={3}
              ref={inputRef}
              className={cx("form-control", styles.textarea)}
              value={comment || ""}
              onChange={handleInputChange}
              placeholder={`Add a comment`}
            ></textarea>
          </div>
        )}
        <button
          type="button"
          onClick={() => onSave(transfer, product, comment)}
          className="button button--primaryBlue mt-2"
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default StockTransferComments
