import React, { useContext, useEffect, useState } from "react"
import { getIntegrations, IntegrationListResult } from "services/integrations"
import { integrationConfig } from "services/constants"
import SquareIntegrationButton from "../Square/SquareIntegrationButton/SquareIntegrationButton"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import { CloverIntegrationButton } from "../Clover/CloverIntegrationButton/CloverIntegrationButton"
import { AsideContext } from "context/aside/AsideContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import SquareDetails from "../Square/SquareDetails/SquareDetails"
import CloverDetails from "../Clover/CloverDetails/CloverDetails"
import usePermissions from "hooks/usePermissions"
import { IntegrationButtonProps, Permission } from "services/types"
import XeroIntegrationButton from "../Xero/XeroIntegrationButton/XeroIntegrationButton"
import XeroDetails from "../Xero/XeroDetails/XeroDetails"
import GoEposIntegrationButton from "../GoEpos/GoEposIntegrationButton/GoEposIntegrationButton"
import GoEposDetails from "../GoEpos/GoEposDetails/GoEposDetails"
import NewbridgeIntegrationButton from "../Newbridge/NewbridgeIntegrationButton/NewbridgeIntegrationButton"
import NewbridgeDetails from "../Newbridge/NewbridgeDetails/NewbridgeDetails"
import SageIntegrationButton from "../Sage/SageIntegrationButton/SageIntegrationButton"
import SageDetails from "../Sage/SageDetails/SageDetails"

interface IntegrationConfig {
  id?: string
  status: "ACTIVE" | "INACTIVE"
  title: string
  type: string
  comingSoon?: boolean
  comp: {
    integrationButton: React.ReactElement
    details: React.ReactElement
  }
  description: string
  logo: string
}

interface GroupedIntegrationList {
  groupName: string
  integrations: IntegrationConfig[]
}

const componentMap = (props?: IntegrationButtonProps) => {
  return {
    SQUARE: {
      // @ts-ignore
      integrationButton: <SquareIntegrationButton {...props} />,
      details: SquareDetails,
      headerText: "Square details",
    },
    CLOVER: {
      // @ts-ignore
      integrationButton: <CloverIntegrationButton {...props} />,
      details: CloverDetails,
      headerText: "Clover details",
    },
    XERO: {
      // @ts-ignore
      integrationButton: <XeroIntegrationButton {...props} />,
      details: XeroDetails,
      headerText: "Xero details",
    },
    SAGE: {
      // @ts-ignore
      integrationButton: <SageIntegrationButton {...props} />,
      details: SageDetails,
      headerText: "Sage details",
    },
    GOEPOS: {
      // @ts-ignore
      integrationButton: <GoEposIntegrationButton {...props} />,
      details: GoEposDetails,
      headerText: "GoEpos details",
    },
    NEWBRIDGE: {
      // @ts-ignore
      integrationButton: <NewbridgeIntegrationButton {...props} />,
      details: NewbridgeDetails,
      headerText: "Newbridge details",
    },
    // Todo: LIGHTSPEED
  }
}

const IntegrationList = () => {
  const permissionObj = usePermissions("Company settings") as Permission
  const aside = useContext(AsideContext)
  const [integrationGroupedList, setIntegrationGroupedList] = useState<
    GroupedIntegrationList[]
  >([])

  const mapList = (list) => {
    return Object.keys(list).map((key) => {
      return {
        integrations: list[key].map((item) => {
          return {
            ...item,
            ...integrationConfig[item.type],
            comp: componentMap({
              status: item.status,
              id: item.id,
              onConnectionChange: () => getIntegrationList(),
              canConnect: permissionObj?.permissions.modify,
              canDisconnect: permissionObj?.permissions.delete,
            })[item.type],
          }
        }),
        groupName: key,
      }
    })
  }

  const openDetails = (type: string, integrationId?: string) => {
    const comp = componentMap()[type]

    aside.showAside(comp.details, { id: integrationId })
  }

  const getIntegrationList = async (controller?: AbortController) => {
    try {
      const list: IntegrationListResult = await getIntegrations(
        {},
        { ...(controller ? { signal: controller.signal } : {}) }
      )
      const mapped = mapList(list)
      setIntegrationGroupedList(mapped)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    getIntegrationList(abortController)
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <>
      {integrationGroupedList.map((list) => {
        return (
          <div key={list.groupName}>
            <h3 className="uppercase text-gray-700 mb-3 font-sansSemiBold font-semibold text-sm">
              {list.groupName.replace(/([A-Z])/g, " $1").trim()}
            </h3>
            <ul className="flex flex-row flex-wrap items-stretch w-full sm:-mx-3">
              {list.integrations.map((int) => {
                return (
                  <li
                    key={int.type}
                    className="w-full sm:w-1/2 md:w-1/3 sm:px-3 pb-6"
                  >
                    <div className="bg-white h-full shadow-sm p-4 border rounded-lg flex flex-col justify-between">
                      <div className="flex items-start justify-between mb-3">
                        <div className="w-10 h-10">
                          <img
                            src={int.logo}
                            alt={int.title}
                            className="object-contain object-center w-full h-auto max-h-full max-w-full"
                          />
                        </div>
                        {int.status === "ACTIVE" && (
                          <ItemStatus
                            status={{ color: "#35cc5f", label: "Connected" }}
                            style={{
                              minWidth: "none",
                              fontSize: "0.9rem",
                              justifyContent: "flex-end",
                            }}
                          />
                        )}
                      </div>
                      <h3 className="font-semibold font-sansSemiBold text-lg text-gray-900 mb-3">
                        {int.title}
                      </h3>
                      <div className="w-full text-gray-700 text-sm mb-3">
                        {int.description}
                      </div>
                      <div className="w-full text-left">
                        {!int.comingSoon &&
                          (int.comp?.integrationButton || null)}
                        {int.comingSoon && !int.id && (
                          <span className="text-primaryPink text-sm font-semibold font-sansSemiBold mt-2">
                            Coming soon
                          </span>
                        )}
                        {/* If has integration (comp) and there is an integration ID,
                      show button to show details modal
                  */}
                        {int.comp?.integrationButton &&
                        int.id &&
                        permissionObj?.permissions.modify ? (
                          <button
                            className="text-primaryBlue font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left"
                            style={{ height: "40px " }}
                            onClick={() => openDetails(int.type, int.id)}
                          >
                            View details
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="ml-2"
                            />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </>
  )
}

export default IntegrationList
