import { get, post, patch } from "./api"

export const getDiscrepancies = (orderId) => {
  return get(`/orders/${orderId}/discrepancies`).then(
    (response) => response.discrepancies
  )
}

export const cancelOrder = (orderId) => {
  return post(`/orders/${orderId}/cancel`)
}

export const updateOrderDiscrepancy = (params) => {
  const { orderId, discrepancyId, payload } = params
  return patch(`/orders/${orderId}/discrepancies/${discrepancyId}`, payload)
}
