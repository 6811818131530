import React, { useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import * as styles from "./DocumentCamera.module.css"

const cx = classNames.bind(styles)

const DocumentCameraFiles = ({ selectedFiles, onSelectToggle, onConfirm }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!selectedFiles) {
      return
    }
    // only trigger if an item is removed or added
    if (selectedFiles.length) {
      const { clientWidth, scrollWidth } = containerRef.current
      if (scrollWidth > clientWidth) {
        containerRef.current.scrollBy({
          left: scrollWidth - clientWidth,
          behaviour: "smooth",
        })
      }
    }
  }, [selectedFiles])

  return selectedFiles.length > 0 ? (
    <>
      {selectedFiles.filter((file) => file.selected).length > 0 && (
        <button
          className={styles.continueWithSelectionButton}
          onClick={onConfirm}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>{selectedFiles.filter((file) => file.selected).length}</span>
        </button>
      )}
      <div className={styles.selectedImageList} ref={containerRef}>
        <ul>
          {selectedFiles.map((file, index) => {
            return (
              <li
                key={file.dataUri}
                className={cx({ selected: file.selected })}
                onClick={() => onSelectToggle(file, index)}
              >
                <img src={file.dataUri} alt={file.name} />
                {file.selected && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles.selectedIcon}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  ) : null
}

DocumentCameraFiles.propTypes = {
  selectedFiles: PropTypes.array,
  onSelectToggle: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default DocumentCameraFiles
