import React, { useState } from "react"

import {
  formatCurrencyValue,
  getRecipeMeasures,
  roundNumber,
} from "services/helpers"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo } from "@fortawesome/pro-regular-svg-icons"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import * as styles from "./AddRecipeForm.module.css"
import FilterSelect from "components/forms/FilterSelect"
import { convertPartialQtyToProductMeasure } from "services/measurements"
import Quantity from "components/forms/Quantity"

const percentageMeasure = {
  label: "%",
  value: "percentage",
  type: "percentage",
}

const validationSchema = Yup.object().shape(
  {
    fullQty: Yup.number().when("partialQty", {
      is: null,
      then: Yup.number().required("Please fill in full quantity"),
    }),
    partialQty: Yup.number().when("fullQty", {
      is: 0,
      then: Yup.number().nullable(),
      otherwise: Yup.number().nullable(),
    }),
  },
  [["partialQty", "fullQty"]]
)

const distributeToFullAndPartialQuantity = (quantity) =>
  quantity == undefined
    ? { fullQty: undefined, partialQty: undefined }
    : {
        fullQty: Math.floor(quantity),
        partialQty: roundNumber((quantity - Math.floor(quantity)) * 100),
      }

interface AddRecipeFormProps {
  onCancel: Function
  onSave: Function
  newRecipe: any
  onEdit?: Function
  isEdit: boolean
  fullQtyAsPortions?: boolean
  hidePartialQty?: boolean
}

const AddRecipeForm = ({
  onCancel,
  newRecipe,
  isEdit,
  onSave,
  onEdit,
  fullQtyAsPortions = false,
  hidePartialQty = false,
}: AddRecipeFormProps) => {
  const initialValues = distributeToFullAndPartialQuantity(newRecipe.totalQty)

  const inputMeasures = getRecipeMeasures(newRecipe)
  const recipeMeasure = inputMeasures.find(
    ({ value }) => value === newRecipe?.yield?.measure
  )

  const [measure, setMeasure] = useState(percentageMeasure)

  const totalValue = (values) =>
    (values.fullQty ?? 0) +
    convertPartialQuantity(values.partialQty ?? 0, measure.value)

  const convertPartialQuantity = (quantity, measure) => {
    if (measure === "percentage") {
      return quantity / 100
    } else if (measure === "portion") {
      return quantity / newRecipe.portionCount
    } else {
      return (
        convertPartialQtyToProductMeasure(
          quantity,
          measure,
          newRecipe.yield.measure
        ) / newRecipe.yield.size
      )
    }
  }

  const saveRecipe = (values, next = false) => {
    onSave(next, newRecipe, totalValue(values))
  }

  const editRecipe = (values) => {
    if (onEdit) onEdit(newRecipe, totalValue(values))
  }

  const currency = null

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.productName}>
          {isEdit ? newRecipe.recipe.name : newRecipe.name}
        </h3>
        <span className="font-semibold font-sansSemiBold text-gray-500">
          Total portion cost{" "}
          {currency
            ? formatCurrencyValue(newRecipe.totalCost / newRecipe.portionCount)
            : roundNumber(newRecipe.totalCost / newRecipe.portionCount)}
        </span>
        <span className="font-semibold font-sansSemiBold text-gray-500">
          Total cost{" "}
          {currency
            ? formatCurrencyValue(newRecipe.totalCost)
            : roundNumber(newRecipe.totalCost)}
        </span>
        <span className="text-gray-500">
          {newRecipe.yield &&
            ` ${newRecipe.yield.size} ${recipeMeasure?.label}`}
          {newRecipe.portionCount && ` / ${newRecipe.portionCount} portions`}
        </span>
      </div>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, isValid, errors, touched }) => {
          return (
            <div className="flex flex-col h-full overflow-auto">
              <div className={styles.content}>
                <div className={styles.summary}>
                  <Form className="flex flex-col flex-shrink-0 w-full px-5 md:px-16 md:py-2">
                    <div className="flex flex-col md:flex-row pt-3">
                      <div
                        className={`w-full md:w-1/2 ${
                          hidePartialQty ? "md:m-auto" : "md:mr-10"
                        }`}
                      >
                        <div className="form-group">
                          <label htmlFor="fullQty" className={styles.formLabel}>
                            {fullQtyAsPortions ? "Portions" : "Full item count"}
                          </label>
                          <div className="input-container w-full flex flex-nowrap justify-between items-center">
                            <Quantity
                              value={values.fullQty}
                              placeholder="XX"
                              fieldName={"fullQty"}
                              onChange={(value) => {
                                setFieldValue("fullQty", value)
                              }}
                              allowNegative={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`w-full md:w-1/2 ${
                          hidePartialQty && "hidden"
                        }`}
                      >
                        <label
                          htmlFor="partialQty"
                          className={styles.formLabel}
                        >
                          Additional quantity
                        </label>

                        <div className="form-group flex md:form-group--flex w-full">
                          <div
                            className="input-container input-container--left"
                            style={{ maxWidth: "200px" }}
                          >
                            <Field
                              name="partialQty"
                              id="partialQty"
                              type="number"
                              className={`${styles.quantityInputSecond} form-control form-control--topleft form-control--bottomleft`}
                              min={0}
                              step={0.2}
                              placeholder="XX"
                            />
                          </div>
                          <div
                            className="input-container input-container--right"
                            style={{ maxWidth: "calc(100% - 60px)" }}
                          >
                            <FilterSelect
                              options={[percentageMeasure].concat(
                                inputMeasures
                              )}
                              value={measure}
                              defaultValue={measure}
                              getOptionLabel={(opt) => `${opt.label}`}
                              onChange={async (val) => {
                                setMeasure(val)
                              }}
                              placeholder="Measures"
                            />
                          </div>
                        </div>
                        <div className="text-xs text-primaryPink mt-3">
                          {touched.partialQty &&
                            errors.partialQty &&
                            errors.partialQty}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className={styles.formBottom}>
                <button
                  onClick={onCancel}
                  type="button"
                  className="text-primaryPink mr-auto px-2 font-semibold font-sansSemiBold button--autoWidth whitespace-nowrap"
                >
                  <FontAwesomeIcon icon={faUndo} className="mr-2" />
                  Cancel
                </button>
                <button
                  onClick={
                    isEdit ? () => editRecipe(values) : () => saveRecipe(values)
                  }
                  disabled={!isValid}
                  type="button"
                  className={`${
                    isEdit && "button button--primaryGreen"
                  } text-primaryBlue mx-3 px-2 font-semibold font-sansSemiBold button--autoWidth`}
                >
                  Save
                </button>
                {!isEdit && (
                  <button
                    type="button"
                    disabled={!isValid}
                    onClick={() => {
                      saveRecipe(values, true)
                    }}
                    className="button mx-3 button--primaryGreen button--autoWidth no-truncate"
                  >
                    Save & Next
                  </button>
                )}
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}

export default AddRecipeForm
