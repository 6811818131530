import { get, post, put, remove } from "./api"
import { exportResponse } from "./export"

export const getMenuProfitabilityReports = (params) => {
  return get("/menu-profitability-reports", params)
}

export const getMenuProfitabilityReport = (reportId, params) => {
  return get(`/menu-profitability-reports/${reportId}`, params)
}

export const createMenuProfitabilityReport = (params) => {
  return post("/menu-profitability-reports", params)
}

export const updateMenuProfitabilityReport = (reportId, params) => {
  return put(`/menu-profitability-reports/${reportId}`, params)
}

export const deleteMenuProfitabilityReport = (reportId) => {
  return remove(`/menu-profitability-reports/${reportId}`)
}

export const exportMenuProfitabilityReport = async (
  reportId,
  fileName,
  downloadFormat = "xlsx",
  params = {}
) => {
  const response = await get(
    `/menu-profitability-reports/${reportId}/export-as-${downloadFormat}?fileName=${fileName}`,
    params,
    {
      download: true,
    }
  )
  exportResponse(response, fileName)
}
