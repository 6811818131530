import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious, useUpdateEffect } from "react-use"
import { getCOGSReportsByCriteria } from "services/stock-take/stock-take"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
// import SearchInput from "components/forms/SearchInput"
import COGSReportItem from "components/stock/COGSReportItem/COGSReportItem"
// import COGSReportModal from "../COGSReportModal/COGSReportModal"
import { ModalContext } from "context/modal/ModalContext"
//@ts-ignore
import * as styles from "./COGSReports.module.css"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { faCalculator, faChartBar } from "@fortawesome/pro-duotone-svg-icons"
import { getCogsReportsDummy } from "services/dummy"
import BaseList from "components/baseList/BaseList"
import usePagination from "hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import ReportModal from "../ReportModal/ReportModal"
import { navigate } from "gatsby"

const COGSReports = () => {
  const {
    selectedOrganizations,
    organizationPermissions,
  }: {
    selectedOrganizations: any
    organizationPermissions: any
  } = useContext(GlobalStateContext)

  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [cogsReportsData, setCOGSReportsData] = useState({
    content: [],
  })
  const permissionObj = usePermissions("COGS") as Permission
  // const [q, setQ] = useState("")
  // const previousQ = usePrevious(q)

  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)
  const modal = useContext(ModalContext)

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations &&
      selectedOrganizations.length === 0
    ) {
      setCOGSReportsData({ content: [] })
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: "periodTo,desc",
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getCOGSReportsByCriteria(params))

    if (result && !result.error) {
      setCOGSReportsData({ ...cogsReportsData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const openCreateReportModal = () =>
    modal.showModal(ReportModal, {
      endpoint: "/stock-take/cogs-reports",
      title: "New COGS Report",
      iconConfig: {
        mainIcon: {
          icon: faChartBar,
          color: "#e20d3b",
          backgroundColor: "#FC37620D",
        },
        subIcon: { icon: faCalculator, color: "#e20d3b" },
      },
      onCreate: (reportId: string) =>
        navigate(`/dashboard/insights/cogs-reports/${reportId}`),
      insights: true,
    })

  useEffect(() => {
    getData()
  }, [])

  useUpdateEffect(() => {
    getData()
  }, [selectedOrganizations])

  useUpdateEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  return (
    <>
      <Helmet>
        <title>COGS Report</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="COGS Report" />

        <div className={styles.subHeader}>
          <LocationsSelector />

          <button
            className="button my-2 ml-auto button--autoWidth button--primaryGreen"
            onClick={() => openCreateReportModal()}
            disabled={!permissionObj?.permissions.modify}
          >
            <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
            <span className="hidden lg:inline">New COGS Report</span>
          </button>
        </div>

        <div className={styles.content}>
          <BaseList
            title="COGS Reports"
            id="cogs-reports"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faCalculator,
              color: "green",
              getDummy: () => getCogsReportsDummy,
            }}
            data={cogsReportsData.content}
            rowRender={(cogsReport) => (
              <COGSReportItem
                key={cogsReport.id}
                cogs={cogsReport}
                onDeleted={() => getData()}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default COGSReports
