// extracted by mini-css-extract-plugin
export var actionButton = "StockTransferProductIncoming-module--actionButton--X7uR8";
export var active = "StockTransferProductIncoming-module--active--UfsqV";
export var container = "StockTransferProductIncoming-module--container--2XodQ";
export var detail = "StockTransferProductIncoming-module--detail--zaezr";
export var detailIndicator = "StockTransferProductIncoming-module--detailIndicator--sVQU0";
export var details = "StockTransferProductIncoming-module--details--3V3PJ";
export var inner = "StockTransferProductIncoming-module--inner--kzcMS";
export var name = "StockTransferProductIncoming-module--name--JQ595";
export var notFoundButton = "StockTransferProductIncoming-module--notFoundButton--uONnv";
export var notFoundSwitch = "StockTransferProductIncoming-module--notFoundSwitch--SW5Vt";
export var productNotFound = "StockTransferProductIncoming-module--productNotFound--WR4-O";
export var receivedSwitch = "StockTransferProductIncoming-module--receivedSwitch--e7CNK";
export var sent = "StockTransferProductIncoming-module--sent--gXrmm";
export var sentReceived = "StockTransferProductIncoming-module--sentReceived--Sf35M";
export var sentSwitch = "StockTransferProductIncoming-module--sentSwitch--M6ojk";
export var thumbnailIndicator = "StockTransferProductIncoming-module--thumbnailIndicator--HE-nv";
export var viewSwitch = "StockTransferProductIncoming-module--viewSwitch--sXlRs";
export var viewSwitchBadge = "StockTransferProductIncoming-module--viewSwitchBadge--Fwgh8";
export var viewSwitchIndicator = "StockTransferProductIncoming-module--viewSwitchIndicator--4EK6s";