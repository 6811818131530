/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { navigate } from "@reach/router"
import SearchInput from "components/forms/SearchInput"
import {
  faPlus,
  faSync,
  faFileDownload,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showError, showSuccess } from "services/toast"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "@reach/router"
import * as queryString from "query-string"

import FilterSelect from "components/forms/FilterSelect"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import {
  getRecipes,
  recalculateAllRecipesWithLatestPrices,
  createRecipe,
  updateRecipeFiles,
  updateRecipeFeaturedImage,
  removeRecipe,
  reassignRecipeToOrg,
  reassingRecipesToOrgs,
  unassignRecipesFromOrgs,
  exportRecipes,
} from "services/recipes/recipes"
import Helmet from "react-helmet"
import * as styles from "./Recipes.module.css"
import { recipesQueryParameters } from "services/constants"

import usePermissions from "hooks/usePermissions"
import ActionsButton from "components/common/ActionsButton/ActionsButton"

import SimpleTable from "components/orders/SimpleTable/SimpleTable"
import RecipeItemName from "../RecipeItemName/RecipeItemName"
import RecipeItemDishCount from "../RecipeItemDishCount/RecipeItemDishCount"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import ModalAssignProductLocations from "components/common/ModalAssignLocations/ModalAssignLocations"
import { toast } from "react-toastify"
import SelectCell from "components/baseTable/cells/SelectCell"
import SelectAllCell from "components/baseTable/cells/SelectAllCell"
import { Category, Permission } from "services/types"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import { faListOl, faSparkles } from "@fortawesome/pro-duotone-svg-icons"
import { getRecipiesDummy } from "services/dummy"
import usePagination, { paginationDefaults } from "hooks/usePagination"
import Button from "components/common/Button/Button"
import RecipeGeneratorAiModal from "../RecipeGeneratorAi/RecipeGeneratorAiModal"
import { initialiseQueryParameterState } from "services/helpers"
import { getExportFileName } from "services/export"
import useCategories from "hooks/useCategories"

const Recipes = () => {
  const location = useLocation()
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search
  )

  const queryParamsFormatted = {
    partialRecipeName:
      queryParams.partialRecipeName && String(queryParams.partialRecipeName),
    partialPosId: queryParams.partialPosId && String(queryParams.partialPosId),
    partialIngredientName:
      queryParams.partialIngredientName &&
      String(queryParams.partialIngredientName),
    parameterValue:
      queryParams.parameterValue && String(queryParams.parameterValue),
    category: queryParams.category && String(queryParams.category),
    sort: queryParams.sort,
  }
  const { organizationPermissions } = useContext(GlobalStateContext)
  const isAllowedToManageLocations = organizationPermissions?.general.isMain

  const modal = useContext(ModalContext)

  const filterFunction = (categories: Category[]) => {
    return categories
      .filter((category) => category.active)
      .map((category) => ({
        value: category.name,
        label: category.name,
      }))
  }
  const { categories } = useCategories(filterFunction)

  const [recipesData, setRecipesData] = useState({
    content: [],
  })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const permissionObj = usePermissions("Recipes & Dishes") as Permission
  const pagination = usePagination()

  const searchParamType: string =
    queryParamsFormatted.parameterValue || "partialRecipeName"

  const [q, setQ] = useState<string | null>(
    queryParamsFormatted.partialRecipeName || queryParamsFormatted.partialPosId
      ? searchParamType === "partialRecipeName"
        ? queryParamsFormatted.partialRecipeName
        : queryParamsFormatted.partialPosId
      : ""
  )

  const previousQ = usePrevious(q)
  const [queryParameter, setQueryParameter] = useState(
    initialiseQueryParameterState(
      recipesQueryParameters,
      searchParamType,
      "partialRecipeName"
    )
  )

  const fromPromise = usePromise()
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState<{
    label: string
    value: string
  } | null>(
    queryParamsFormatted.category
      ? {
          label: queryParamsFormatted.category,
          value: queryParamsFormatted.category,
        }
      : null
  )
  const previousCategory = usePrevious(category)

  const sortParam = //ensures sort state is persisted via url params
    !Array.isArray(queryParamsFormatted.sort) && queryParamsFormatted.sort
      ? queryParamsFormatted.sort.split(",")
      : ["name", "asc"]

  const [sortState, setSortState] = useState<{ [x: string]: any }>({
    [sortParam[0]]: sortParam[1],
  })

  // EFFECTS

  useEffect(() => {
    getData()
  }, [sortState, q, pagination.page, category])

  // ACTIONS

  interface getDataParams {
    page: typeof paginationDefaults.page
    size: number
    sort?: string
    parameterValue?: string
    partialRecipeName?: string
    partialPosId?: string
    partialIngredientName?: string
    category?: string
  }
  const getData = async () => {
    setLoading(true)

    const params: getDataParams = {
      page: pagination.page,
      size: pagination.size,
      sort: `${Object.keys(sortState)[0]},${Object.values(sortState)[0]}`,
      parameterValue: queryParameter.value,
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialRecipeName":
          params.partialRecipeName = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        case "partialIngredientName":
          params.partialIngredientName = q
          break
        default:
          params.partialRecipeName = q
          break
      }
      if (previousQ !== q && previousQ !== undefined) {
        params.page = 0
      }
    }

    if (category) {
      params.category = category.value
      if (previousCategory !== category && previousCategory !== undefined) {
        params.page = 0
      }
    }

    // Change url based on params
    // @ts-ignore
    const query = new URLSearchParams(params)
    const path = `${location.pathname}?${query.toString()}`
    window.history.replaceState(null, "", path)

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getRecipes(params))

    if (result && !result.error) {
      setRecipesData({ ...recipesData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const recalculateRecipes = async () => {
    const recalculated = await recalculateAllRecipesWithLatestPrices()
    if (recalculated) {
      showSuccess("Recipes recalculated successfully!")
      getData()
    }
  }

  const handleActionClick = (type) => {
    switch (type) {
      case "export.csv":
        return exportRecipes("csv", getExportFileName("recipes", "", "csv"))
      case "export.xlsx":
        return exportRecipes("xlsx", getExportFileName("recipes", "", "xlsx"))
      case "export.deleted.xlsx":
        return exportRecipes(
          "xlsx",
          getExportFileName("recipes", "deleted", "xlsx"),
          true
        )
    }
  }

  const onColumnSort = ({ key, order }) => setSortState({ [key]: order })

  const itemActions = [
    {
      key: "recipe.duplicate",
      title: "Duplicate",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },

    // { key: "recipe.add_to_dish", title: "Add to dish", type: "default" },
    ...(isAllowedToManageLocations
      ? [
          {
            key: "recipe.manageLocations",
            title: "Manage locations",
            type: "default",
            disabled: !permissionObj?.permissions.modify,
          },
        ]
      : []),
    {
      key: "recipe.delete",
      title: "Delete",
      type: "danger",
      disabled:
        !permissionObj?.permissions.delete ||
        !organizationPermissions?.recipes?.remove,
    },
  ]

  const onManageLocations = (recipes: any[]) => {
    const recipeIds = recipes.map((a) => a.id)
    const organizationIds = recipes.map((a) => a.organizations).flat(1)

    const isBulkSelect = recipes.length > 1

    // @ts-ignore
    modal.showModal(ModalAssignProductLocations, {
      items: [...recipes],
      onSubmit: (_recipeIds, orgIds, _action) => {
        const callRequest =
          _action === "assign"
            ? //Assign
              isBulkSelect
              ? reassingRecipesToOrgs(recipeIds, orgIds)
              : reassignRecipeToOrg(recipes[0].id, { organizations: orgIds })
            : //Unassign
              unassignRecipesFromOrgs(recipeIds, orgIds)
        return callRequest
          .catch((err) => {
            toast.error(err)
          })
          .finally(() => {
            getData()
            setSelection([])
          })
      },
      type: "recipe",
      showActionPicker: recipes.length > 1,
      initialOrgs: isBulkSelect ? [] : organizationIds,
    })
  }

  const handleItemActionClick = (action, recipe) => {
    const { type } = action
    switch (type) {
      case "recipe.view":
      case "recipe.edit":
        navigate(`/dashboard/products/cookbook/recipes/${recipe.id}`)
        break
      case "recipe.manageLocations":
        onManageLocations([recipe])
        break
      case "recipe.delete":
        onDelete(recipe)
        break
      case "recipe.duplicate":
        onDuplicate(recipe)
        break
      default:
        break
    }
  }

  const deleteRecipe = async (recipe) => {
    const deleted = await removeRecipe(recipe.id)
    if (deleted) {
      showSuccess("Recipe deleted!")
      getData()
    }
  }

  const onDelete = (recipe) => {
    // @ts-ignore
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete recipe",
      text: "Are you sure you want to delete this recipe?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteRecipe(recipe),
    })
  }

  const onDuplicate = async (recipe) => {
    const newRecipe = {
      ...recipe,
      posId: null,
      dishes: null,
      files: [],
      featuredFile: null,
      name: `Copy of ${recipe.name}`,
    }

    const currentFiles = recipe.files
    const currentFeaturedFile = recipe.featuredFile

    // remove old id
    delete newRecipe.id

    try {
      const result = await createRecipe(newRecipe)

      if (result) {
        if (currentFiles && currentFiles.length > 0) {
          await updateRecipeFiles(result.id, { files: currentFiles })
        }
        if (currentFeaturedFile) {
          await updateRecipeFeaturedImage(result.id, currentFeaturedFile)
        }
        getData()
        showSuccess("Recipe duplicated!")
        navigate(`/dashboard/products/cookbook/recipes/${result.id}`)
      }
    } catch {
      showError("Something went wrong")
    }
  }

  const showGenerateRecipeAiModal = () => {
    modal.showModal(RecipeGeneratorAiModal, {})
  }

  // BASETABLE

  const [selection, setSelection] = useState([])
  const columns = [
    ...(isAllowedToManageLocations
      ? [
          {
            key: "select",
            title: "Select",
            dataKey: "select",
            width: 45,
            flexGrow: 0,
            flexShrink: 0,
            headerRenderer: (
              <SelectAllCell
                selection={selection}
                setSelection={setSelection}
                onSelectAll={() => setSelection([...recipesData.content])}
              />
            ),
            cellRenderer: (
              <SelectCell selection={selection} setSelection={setSelection} />
            ),
          },
        ]
      : []),
    {
      key: "name",
      title: "Item",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      sortable: true,
      cellRenderer: ({ rowData }) => (
        <RecipeItemName
          recipe={rowData}
          permissionObj={permissionObj}
          handleActionClick={handleItemActionClick}
        />
      ),
    },
    {
      key: "inDishCount",
      title: "In dish",
      width: 60,
      dataKey: "inDishCount",
      className: "text-sm",
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <RecipeItemDishCount recipe={rowData} permissionObj={permissionObj} />
      ),
    },
    {
      key: "salePrice",
      title: "Sales price",
      width: 120,
      dataKey: "salePrice",
      className: "text-sm",
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span className="flex items-baseline">
          {rowData.salePrice}
          {rowData.salePriceWithTax ? (
            <span className="text-xs ml-1 text-gray-600">
              ({rowData.salePriceWithTax} inc. tax)
            </span>
          ) : null}
        </span>
      ),
    },
    {
      key: "costProfit",
      width: 150,
      dataKey: "costProfit",
      flexGrow: 1,
      className: "h-full",
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <CostProfitChart
          cost={rowData.totalCost}
          profit={rowData.profit}
          costPercentage={rowData.totalCostPercent}
          profitPercentage={rowData.profitPercent}
        />
      ),
    },
    {
      key: "action",
      flexShrink: 0,
      width: 60,
      className: "justify-end actions",
      cellRenderer: ({ rowData }) => {
        return (
          <ActionsButton
            options={itemActions}
            onActionClick={(action) => handleItemActionClick(action, rowData)}
          />
        )
      },
    },
  ]

  const formattedData = (data) => {
    const latestData = [...data]

    latestData.forEach((item) => {
      item.children = [
        {
          id: `${item.id}-detail`,
          content: item.ingredients,
        },
      ]
    })

    return latestData
  }

  const rowRenderer = ({ rowData, cells }) => {
    const displayIngredient = (ingr) => ({
      Name: ingr.product.name,
      Qty: ingr.usedQty,
      Cost: ingr.cost,
    })

    if (rowData.content)
      return (
        <div className="w-full px-8 pb-8 itemContent">
          <SimpleTable
            props={rowData.content.map(displayIngredient)}
            style={{ border: "none", backgroundColor: "transparent" }}
            isDeletedProduct={rowData.content.map(
              (ingr) => ingr.product.isDeleted
            )}
          ></SimpleTable>
        </div>
      )
    return cells
  }

  const onDeleteMultiple = () => {
    // TODO : Adding delete multiple logic
    // modal.showModal(ConfirmModal, {
    //   type: "danger",
    //   title: `Delete ${products.length} products`,
    //   text: "Are you sure you want to delete the selected products?",
    //   confirmButtonText: "Delete",
    //   onConfirm: () => deleteMultipleProducts(products),
    // })
  }

  const handleGroupActionClick = (
    action,
    selection,
    modal,
    getData,
    setSelection,
    onDeleteMultiple
  ) => {
    const { type } = action
    /* eslint-disable */
    const products = selection ?? []

    switch (type) {
      case "items.delete":
        onDeleteMultiple(selection.map((a) => a.id))
        break

      case "items.manageLocations":
        //Assign locations

        onManageLocations(selection)
        break

      default:
        break
    }
  }

  return (
    <>
      <Helmet>
        <title>Recipes</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <div className="flex items-center flex-grow relative my-2 w-auto md:w-40 mr-4 md:mr-6">
            <SearchInput
              label="Search by name"
              placeholder={
                isTabletOrMobile
                  ? `Search by ${queryParameter.label.toLowerCase()}`
                  : `Search by recipe ${queryParameter.label.toLowerCase()}`
              }
              className="form-control rounded rounded-tr-none rounded-br-none border-r-0 mr-4 md:mr-6"
              onSearchChange={(val) => {
                setQ(val)
              }}
              initialValue={q}
            />
            <FilterSelect
              options={recipesQueryParameters}
              value={queryParameter && queryParameter.value}
              onChange={(val) => {
                setQueryParameter(val)
              }}
              stylesOverride={{
                container: (provided) => ({
                  ...provided,
                  position: "static",
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#fff",
                  borderLeft: "none",
                  borderRadius: "0 0.25rem 0.25rem 0",
                  width: "fit-content",
                  borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  boxShadow: "none",
                  zIndex: state.isFocused ? 2 : null,
                  minHeight: 45,
                  ":hover": {
                    ...provided[":hover"],
                    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "0px",
                  width: "0px",
                }),
                menu: (provided) => ({
                  ...provided,
                  left: "0px",
                }),
              }}
            />
          </div>

          <div className="inline-flex justify-self-end items-center">
            <FilterSelect
              options={categories}
              value={category ? category?.value : null}
              onChange={(val) => {
                setCategory(val)
              }}
              placeholder="All categories"
              isClearable={true}
              isSearchable={isTabletOrMobile ? false : true}
              className="my-2 lg:my-0 w-48 mr-4 md:mr-6"
            />

            <Button
              color="green"
              className="flex-grow"
              mobileIconOnly={true}
              icon={faSync}
              title="Recalculate"
              onClick={() => {
                recalculateRecipes()
              }}
            />
            <Button
              color="green"
              className="flex-grow"
              mobileIconOnly={true}
              icon={faSparkles}
              title="Generate Recipe (AI)"
              onClick={showGenerateRecipeAiModal}
            />

            <Button
              color="green"
              className="flex-grow"
              mobileIconOnly={true}
              icon={faPlus}
              title="Add Recipe"
              onClick={() => {
                navigate("recipes/new")
              }}
              disabled={!permissionObj?.permissions.modify}
            />

            <DropdownButton
              onActionClick={(opt) => {
                const { type } = opt
                handleActionClick(type)
              }}
              label={<FontAwesomeIcon icon={faFileDownload} />}
              mobileIcon={faFileDownload}
              options={[
                {
                  title: "Export to .CSV",
                  key: "export.csv",
                  disabled: !permissionObj?.permissions.read,
                },
                {
                  title: "Export to .XLSX",
                  key: "export.xlsx",
                  disabled: !permissionObj?.permissions.read,
                },
                {
                  title: "Export deleted to .XLSX",
                  key: "export.deleted.xlsx",
                  disabled: !permissionObj?.permissions.read,
                },
              ]}
            />
          </div>
        </div>

        {selection?.length > 0 && (
          <div className="flex mx-8 mt-6">
            <div className="flex">
              <div>
                <span className="font-semibold mr-4">
                  <span className="text-primaryBlue">
                    {selection.length}{" "}
                    {selection.length === 1 ? "product" : "products"}
                  </span>{" "}
                  <span className="text-gray-600">selected</span>
                </span>
              </div>

              <DropdownButton
                dropDirection="top-left"
                label="Apply action"
                buttonClass={
                  "button button--primaryGreen button--smallest text-sm"
                }
                options={[
                  {
                    key: "items.manageLocations",
                    title: "Manage locations",
                    disabled:
                      !permissionObj?.permissions.modify ||
                      !organizationPermissions?.general?.isMain,
                  },
                  // {
                  //   key: "items.delete",
                  //   title: "Delete",
                  //   type: "danger",
                  //   disabled: !permissionObj?.permissions.delete,
                  // },
                ]}
                onActionClick={(action) =>
                  handleGroupActionClick(
                    action,
                    selection,
                    modal,
                    getData,
                    setSelection,
                    onDeleteMultiple
                  )
                }
              />
            </div>
          </div>
        )}

        <div className={styles.content}>
          <ExtBaseTable
            key="table"
            loading={loading}
            pagination={pagination}
            data={formattedData(recipesData.content)}
            columns={columns}
            estimatedRowHeight={60}
            sortState={sortState}
            onColumnSort={onColumnSort}
            expandColumnKey={"name"}
            title="Recipes"
            id="recipes"
            empty={{
              icon: faListOl,
              color: "blue",
              getDummy: () => getRecipiesDummy,
            }}
            expand={{}}
            rowRenderer={rowRenderer}
          />
        </div>
      </div>
    </>
  )
}

export default Recipes
