import React from "react"
import Modal from "react-modal"
import RecipeSelect from "components/common/RecipeSelect/RecipeSelect"

export default ({ onSelect, createNewRecipe, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <Modal isOpen={isOpen} onRequestClose={() => {}}>
      <RecipeSelect
        onClose={() => {
          setIsOpen(false)
        }}
        onSelect={(product) => {
          setIsOpen(false)
          onSelect(product)
        }}
        createNewRecipe={
          createNewRecipe
            ? (data) => {
                setIsOpen(false)
                createNewRecipe(data)
              }
            : null
        }
        {...props}
      ></RecipeSelect>
    </Modal>
  )
}
