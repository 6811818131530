import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { formatCurrencyValue } from "services/helpers"
//@ts-ignore
import * as styles from "../EditElements.module.css"
import { faListOl } from "@fortawesome/pro-solid-svg-icons"
import { RecipeRow } from "services/dishes"
export default ({
  element,
  change,
}: {
  element: RecipeRow
  change: Function
}) => {
  const ingredient = element.recipe
  const Icon = () => (
    <div className={styles.iconWrapper}>
      <FontAwesomeIcon icon={faListOl} />
    </div>
  )
  return (
    <div
      className="cursor-pointer flex items-center truncate overflow-ellipsis w-full pl-2"
      onClick={change()}
    >
      <Icon />
      <div>
        <a className="font-sansSemiBold underline hover:no-underline font-semibold text-primaryBlue">
          {ingredient.name}
        </a>
        <div className={styles.infoField}>
          {formatCurrencyValue(ingredient.totalCost)}
        </div>
      </div>
    </div>
  )
}
