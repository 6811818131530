import { faCompactDisc } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ServerImage from "components/common/ServerImage/ServerImage"
import React from "react"
import { productCategories } from "services/constants"
import { Dish } from "services/dishes"

import * as styles from "./DishIcon.module.css"

interface DishIconProps {
  dish: Dish
  disabled?: boolean
  onClick?(params: any): void
}

const DishIcon = ({ dish, onClick, disabled = false }: DishIconProps) => {
  const matchedCat = productCategories.find((c) => c.value === dish.category)
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={styles.imageWrapper}
    >
      <ServerImage fileId={dish.featuredFile?.fileId} alt={dish.name}>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon className="text-2xl" icon={faCompactDisc} />
        </div>
      </ServerImage>
      {matchedCat && (
        <span className={styles.catIcon}>
          <FontAwesomeIcon icon={matchedCat.icon} />
        </span>
      )}
    </button>
  )
}

export default DishIcon
