import ColoredIcon from "components/common/ColoredIcon/ColoredIcon"
import EmptyState from "components/common/EmptyState/EmptyState"
import { PrimaryColor, useColors } from "hooks/useColors"
import React from "react"
import { ucfirst } from "services/helpers"

export type EmptyProps = {
  title: string
  color: PrimaryColor
  heading?: string
  text?: string
  icon?: any
  getDummy?: any
}

const EmptyListState = (empty: EmptyProps) => {
  const colors = useColors()
  const color = colors["primary" + ucfirst(empty.color)]
  const bgColor = colors["primary" + ucfirst(empty.color) + "VeryLight"]
  const text = empty?.text ?? "Try different selection or add your first item"
  const heading = `No ${empty.title} found`
  return (
    <div className="empty-state-holder flex font-sansSemiBold font-semibold justify-center h-full w-full text-xl text-center">
      <EmptyState
        iconElement={
          <ColoredIcon
            icon={empty?.icon}
            color={color}
            backgroundColor={bgColor}
          />
        }
        heading={heading}
        entityTitle={empty.title}
        text={text}
      />
    </div>
  )
}

export default EmptyListState
