import React from "react"
import { formatCurrencyValue } from "services/helpers"
//@ts-ignore
import * as styles from "../EditElements.module.css"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import { ProductRow } from "services/dishes"
export default ({
  element,
  change,
}: {
  element: ProductRow
  change: Function
}) => {
  const ingredient = element.product
  return (
    <div
      className="cursor-pointer truncate overflow-ellipsis w-full pl-2"
      onClick={change()}
    >
      <a className="font-sansSemiBold mb-1 underline hover:no-underline font-semibold text-primaryBlue">
        {ingredient.name}
      </a>

      <div className={styles.infoField}>
        {ingredient.packaging !== "multiple" ? (
          <div className="mr-4">
            <ProductIcon
              product={element.product}
              inCase={element.product.hasCase}
            ></ProductIcon>
            {ingredient.size} {ingredient.measure}
          </div>
        ) : (
          <div className="mr-4">
            {ingredient.size} {ingredient.measure}
          </div>
        )}
        <div className="mr-4"> {formatCurrencyValue(ingredient.price)}</div>
        <div> {ingredient.supplierName && `(${ingredient.supplierName})`}</div>
      </div>
    </div>
  )
}
