import { faChevronDown } from "@fortawesome/pro-light-svg-icons"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"
import { faEye, faSyncAlt } from "@fortawesome/pro-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import React, { useEffect, useRef, useState } from "react"
import { DateRange, DayPicker } from "react-day-picker"
import { useClickAway } from "react-use"
import LocationsSelector from "./LocationsSelector"

const DateSelector = ({
  options,
  selectedOption,
  setSelectedOption,
  setshowDateSelector,
}) => {
  const [range, setRange] = useState<DateRange | undefined>()

  useEffect(() => {
    if (range?.to && range?.from) setSelectedOption(range)
  }, [range])

  return (
    <div className="flex flex-col" style={{ maxWidth: "90vw" }}>
      <div
        className="flex flex-col sm:flex-row bg-white border rounded-lg p-8 w-auto sm:space-x-8"
        style={{ zIndex: 999 }}
      >
        <div className="max-w-full">
          <DayPicker
            className="DateRangePicker"
            id="test"
            mode="range"
            selected={range}
            max={62} // Allow selection of maximum 62 days
            onSelect={setRange}
            weekStartsOn={1} // Start week on monday
          />
          {Boolean(range) && (
            <div className="hidden sm:block">
              <div className="flex flex-col">
                <span>
                  <span className="font-semibold text-primaryBlue inline-block w-16">
                    From:
                  </span>{" "}
                  {range?.from ? format(range?.from, "dd/MM/yyyy") : "-"}
                </span>
                <span>
                  <span className="font-semibold text-primaryBlue inline-block w-16">
                    To:
                  </span>{" "}
                  {range?.to ? format(range?.to, "dd/MM/yyyy") : "-"}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="border-l flex flex-col items-start pl-8 w-44 mb-4">
          <ul className="whitespace-nowrap text-left">
            {options.map((opt) => (
              <li
                onClick={() => {
                  setSelectedOption(opt)
                  setRange(undefined)
                }}
                key={opt.value}
                className={`${
                  selectedOption.value === opt.value &&
                  "bg-primaryBlue text-white"
                } p-2 px-4 rounded-md cursor-pointer`}
              >
                {opt.displayName}
              </li>
            ))}
          </ul>
          <button
            className="mt-8 sm:mt-auto bg-primaryGreen text-white p-2 rounded-md hover:bg-opacity-90 w-full text-center"
            onClick={() => setshowDateSelector(false)}
          >
            View results
          </button>
        </div>
      </div>
    </div>
  )
}

const ChartVisibilitySelector = ({ visibleCharts, setVisibleCharts }) => {
  const toggleKeyVisibility = (target) => {
    setVisibleCharts((prev) => {
      const temp = [...prev]
      const found = temp.find((a) => a.key === target.key)
      found.visible = !found.visible
      return temp
    })
  }

  return (
    <div className="bg-white shadow-md border rounded-lg p-5 flex w-auto space-x-8">
      <ul className="space-y-2">
        {visibleCharts.map((a) => {
          return (
            <li
              key={"ChartKey-" + a.key}
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => toggleKeyVisibility(a)}
            >
              <input
                type="checkbox"
                readOnly
                className="text-primaryBlue"
                checked={a.visible}
              />
              <span>{a.displayName}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const ChartFilters = ({
  setSelectedOption,
  selectedOption,
  setrefreshObserver,
  options,
  visibleCharts,
  setVisibleCharts,
}) => {
  const [showDateSelector, setshowDateSelector] = useState(false)
  const [showVisibilitySelector, setShowVisibilitySelector] = useState(false)

  const isSelectedOptionDate = selectedOption?.to && selectedOption?.from

  const [isRefreshSpinning, setisRefreshSpinning] = useState(false) // Used as disable conditional in refresh button

  const refresh = () => {
    // Throttled refresh
    setrefreshObserver((prev) => prev + 1)
    setisRefreshSpinning(true)
    setTimeout(() => {
      setisRefreshSpinning(false)
    }, 1500)
  }

  const dateSelectorRef = useRef(null)
  const visibilitySelectorRef = useRef(null)

  useClickAway(dateSelectorRef, () => {
    setshowDateSelector(false)
  })

  useClickAway(visibilitySelectorRef, () => {
    setShowVisibilitySelector(false)
  })

  return (
    <div className="flex items-center w-full mt-4 relative pb-4 px-2">
      <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-8 sm:mr-8 flex-shrink-0">
        <div ref={dateSelectorRef} className="relative" style={{ height: 45 }}>
          <button
            onClick={() => setshowDateSelector(!showDateSelector)}
            className="relative w-56 flex items-center rounded-lg h-full px-4 transition-colors button--paleBlue"
          >
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faCalendar} />
              {isSelectedOptionDate ? (
                <div className="text-base flex flex-row space-x-2 items-center justify-start">
                  <div className="flex">
                    <div>
                      <span>
                        {selectedOption?.from
                          ? format(selectedOption?.from, "dd/MM")
                          : "-"}
                      </span>
                    </div>
                    <span className="mx-1">-</span>
                    <div>
                      <span>
                        {selectedOption?.to
                          ? format(selectedOption?.to, "dd/MM")
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <span className="ml-4 font-semibold text-gray-700">
                  {selectedOption.displayName}
                </span>
              )}
            </div>
            <div className="ml-auto pl-4 border-l border-gray-600 border-opacity-50">
              <FontAwesomeIcon
                icon={faChevronDown}
                rotation={showDateSelector ? 180 : undefined}
                className="text-gray-600 text-opacity-50 hover:text-opacity-100"
              />
            </div>
          </button>
          {showDateSelector && (
            <div className="absolute left-0 top-full mt-2 shadow-md">
              <DateSelector
                setshowDateSelector={setshowDateSelector}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
          )}
        </div>
        {/* <OrganizationFilter /> */}
        <LocationsSelector
          className="button--paleBlue rounded-lg"
          allOrganizations={true}
          // width={240}
        />
      </div>

      <div
        ref={visibilitySelectorRef}
        className="sm:flex-1 ml-auto sm:ml-0 justify-between space-y-1 sm:space-y-0 sm:space-x-1 flex flex-col sm:flex-row"
      >
        <button
          disabled={isRefreshSpinning}
          onClick={refresh}
          className="bg-primaryGreen h-12 w-12 rounded-md flex items-center justify-center text-white hover:bg-opacity-80 transition-colors"
        >
          <FontAwesomeIcon icon={faSyncAlt} spin={isRefreshSpinning} />
        </button>
        <button
          onClick={() => setShowVisibilitySelector(!showVisibilitySelector)}
          className="bg-primaryBlue h-12 w-12 rounded-md flex items-center justify-center text-white hover:bg-opacity-80 transition-colors"
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
        {showVisibilitySelector && (
          <div
            className="absolute right-0 top-full mt-1 mr-2"
            style={{ zIndex: 999 }}
          >
            <ChartVisibilitySelector
              visibleCharts={visibleCharts}
              setVisibleCharts={setVisibleCharts}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChartFilters
