import React, { useEffect, useState } from "react"
import FileSelect from "../FileSelect/FileSelect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImagePolaroid, faTimes } from "@fortawesome/pro-light-svg-icons"
import { showError } from "services/toast"
import { upload } from "services/file-processing"
import ServerImage from "../ServerImage/ServerImage"
//@ts-ignore
import * as styles from "./FeaturedImage.module.css"
import { FileItem } from "services/types"
import { showSuccess } from "../../../services/toast"

interface FeaturedImageProps {
  onChange({ action: string, file: any }): void
  currentFile?: FileItem | null
  clientKey: string
  disabled?: boolean
}

const FeaturedImage = ({
  onChange,
  currentFile,
  clientKey,
  disabled,
}: FeaturedImageProps) => {
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null)
  const [loading, setLoading] = useState(false)

  const uploadToServer = async (file: File) => {
    setLoading(true)

    showSuccess("Image uploaded")

    const formData = new FormData()

    const params = {
      client: clientKey, // clientKey can be 'recipe', 'dish', 'menu' for example
    }

    formData.append("file", file)
    try {
      const response = await upload(formData, params)
      setSelectedFile(response)
      onChange({ action: "file-added", file: response })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      showError(e.message || "Something went wrong")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeImage = (e: any) => {
    e.preventDefault()
    onChange({ action: "file-removed", file: selectedFile })
    setSelectedFile(null)
  }

  useEffect(() => {
    if (currentFile && !selectedFile) {
      setSelectedFile(currentFile)
    }
  }, [currentFile])

  return (
    <FileSelect
      allowedTypes={["image/png", "image/jpg", "image/jpeg"]}
      disabled={loading || disabled}
      className="flex-grow flex items-stretch"
      onSelect={(file) => {
        uploadToServer(file)
      }}
    >
      <div
        className={`${styles.featuredImage} ${disabled ? styles.disabled : ""}`}
      >
        {selectedFile ? (
          <>
            <ServerImage
              fileId={selectedFile.fileId}
              alt={selectedFile.fileName}
            >
              <FontAwesomeIcon
                icon={faImagePolaroid}
                className="text-3xl mb-2"
              />
            </ServerImage>
            <button
              onClick={removeImage}
              type="button"
              disabled={disabled}
              className={styles.removeImage}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faImagePolaroid} className="text-3xl mb-2" />
            <span className="text-sm px-3">
              Click to select a featured image
            </span>
          </>
        )}
      </div>
    </FileSelect>
  )
}

export default FeaturedImage
