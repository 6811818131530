import React, { useState } from "react"
import { usePrevious } from "react-use"
import { DayPicker } from "react-day-picker"
import "./Calendar.css"

type CallendarProps = {
  onSelect: Function
  selectedDate: string | Date
  modifiers?: any
  [key: string]: any
}

const Calendar = ({
  onSelect,
  selectedDate,
  modifiers,
  ...restProps
}: CallendarProps) => {
  const [selectedDay, setSelectedDay] = useState(
    selectedDate ? new Date(selectedDate) : undefined
  )
  const previousDay = usePrevious(selectedDay)

  const handleDayClick = (day, modifiers) => {
    if (modifiers.disabled) {
      return
    }

    setSelectedDay(day)
    if (previousDay !== day) {
      onSelect(day)
    }
  }

  const calendarStyles = {
    selected: {
      color: "#FFFFFF",
      backgroundColor: "#4B4B88",
    },
  }

  return (
    <DayPicker
      className="DateRangePicker"
      onDayClick={handleDayClick}
      mode="single"
      defaultMonth={selectedDay}
      selected={selectedDay}
      modifiersStyles={calendarStyles}
      modifiers={modifiers}
      showOutsideDays
      {...restProps}
    />
  )
}

export default Calendar
