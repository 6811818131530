import React, { useContext, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import * as styles from "./OrganizationSettings.module.css"
import Header from "components/dashboard/Header/Header"
import queryString from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { faSyncAlt, faWineBottle } from "@fortawesome/pro-duotone-svg-icons"
import { usePromise, usePrevious } from "react-use"
import { useLocation } from "@reach/router"
import { getInvitations } from "services/user"
import OrganizationDetailsForm from "components/organization/OrganizationDetailsForm/OrganizationDetailsForm"
import AddressForm from "components/common/AddressForm/AddressForm"
import OrganizationPlanSelect from "components/organization/OrganizationPlanSelect/OrganizationPlanSelect"
import OrganizationNotifications from "components/organization/OrganizationNotifications/OrganizationNotifications"
import ContactDetailsForm from "components/common/ContactDetailsForm/ContactDetailsForm"
import Invites from "components/organization/Invites/Invites"
import IntegrationList from "components/integrations/IntegrationList/IntegrationList"
import { getMyOrganization, updateMyOrganization } from "services/organization"
import { refreshLogin } from "services/auth"
import { showSuccess, showError } from "services/toast"
import Helmet from "react-helmet"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import InviteFormModal from "components/organization/Invites/InviteForm"
import StockTransferList from "components/stock/transfers/StockTransferConnections/StockTransferConnections"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import usePermissions from "hooks/usePermissions"
import OrganizationLocations from "../OrganizationLocations/OrganizationLocations"
import OrganizationApiKeyAuth from "../OrganizationApiKeyAuth/OrganizationApiKeyAuth"

import CategoriesSettings from "components/categories/CategoriesSettings"
import CollapsibleSection from "components/common/CollapsibleSection"

const OrganizationSettings = () => {
  const location = useLocation()
  const permissionObj = usePermissions("Company settings")
  const initialTab =
    location.state && location.state.initialTab
      ? location.state.initialTab
      : null

  const { organization, user, organizationPermissions } =
    useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const userHasSubLocations = organizationPermissions?.general.isMain //Check if user has sub Locations, Main = has length

  const enabledTabs = [
    { key: "company-details", label: "Details" },
    ...(userHasSubLocations ? [{ key: "locations", label: "Locations" }] : []),
    { key: "users-details", label: "Team" },
    { key: "notifications", label: "Notifications" },
    { key: "integrations", label: "Integrations" },
    { key: "features", label: "Features" },
    // { key: "billing-details", label: "Billing" },
    { key: "api-key", label: "API" },
  ]

  const [initialCompanyData, setInitialCompanyData] = useState(null)
  const [activeTab, setActiveTab] = useState(
    initialTab ? initialTab : "company-details"
  )

  const [companyData, setCompanyData] = useState({})
  const [addressData, setAddressData] = useState({})
  const [deliveryAddressData, setDeliveryAddressData] = useState(null)
  const [contactDetails, setContactDetails] = useState({})

  const [companySubmitEnabled, setCompanySubmitEnabled] = useState(false)
  const [contactSubmitEnabled, setContactSubmitEnabled] = useState(false)
  const [useDeliveryAddress, setUseDeliveryAddress] = useState(false)
  const [addressValid, setAddressValid] = useState(false)
  const [deliveryAddressValid, setDeliveryAddressValid] = useState(false)

  const [invitesData, setInvitesData] = useState({ content: [] })
  const [loading, setLoading] = useState(false)
  const [invitesPaginationData, setInvitesPaginationData] = useState({
    page: 0,
    size: 10,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })
  const previousPage = usePrevious(invitesPaginationData.page)

  const fromPromise = usePromise()

  const removeEmptyEmailFields = (data) => {
    const newData = { ...data }

    Object.keys(newData).forEach((item) => {
      if (Array.isArray(newData[item])) {
        newData[item] = newData[item].filter((email) => email !== "")

        if (newData[item].length === 0) {
          newData[item] = [""]
        }
      } else {
        if (typeof newData[item] === "object") {
          newData[item] = removeEmptyEmailFields(newData[item])
        }
      }
    })

    return newData
  }

  const saveOrganization = async (data) => {
    if (activeTab === "notifications") {
      data = removeEmptyEmailFields(data)
    }

    const params = { ...organization, ...data }
    if (!useDeliveryAddress && params.deliveryAddress !== null) {
      params.deliveryAddress = null
    }
    const updateResult = await updateMyOrganization(params)

    if (updateResult.status === 409) {
      showError(`Failed to update company. ${updateResult.message}`)
    } else if (updateResult && !updateResult.error && !updateResult.errors) {
      dispatch({ type: "UPDATE_ORGANIZATION", payload: updateResult })

      // Update user zoneId if timezone is changed, therefore we need to get a new JWT token
      // So we need to refresh the login
      if (data.address && organization.address.zoneId !== data.address.zoneId) {
        const newUser = await refreshLogin(params.id)
        dispatch({ type: "SET_USER", payload: newUser })
      }

      showSuccess("Company updated!")
    } else if (updateResult && updateResult.error) {
      showError(`${updateResult.error.message}`)
    }
  }

  const onOrganizationChange = (data) => {
    setCompanyData(data.nextValues)
  }

  const contactDetailsChange = (data) => {
    setContactDetails(data.nextValues)
  }

  const addressChange = (data, type) => {
    if (type === "address") {
      setAddressData(data.nextValues)
    }
    if (type === "deliveryAddress") {
      const values = data.nextValues
      if (values.zoneId) {
        delete values.zoneId
      }
      setDeliveryAddressData(values)
    }
  }

  const addressSubmitEnabled = () => {
    return useDeliveryAddress ? deliveryAddressValid : addressValid
  }

  useEffect(() => {
    if (organization && Object.keys(organization).length) {
      setInitialCompanyData({
        businessType: organization.businessType,
        companyName: organization.companyName,
        description: organization.description,
        employeesNumberRange: organization.employeesNumberRange,
        logo: organization.logo,
        website: organization.website,
      })

      if (organization.deliveryAddress !== null) {
        setUseDeliveryAddress(true)
      }
    }
  }, [organization])

  // Invites

  const getInvitesData = useCallback(async () => {
    setLoading(true)

    const params = {
      page: invitesPaginationData.page,
      size: invitesPaginationData.size,
      sort: "invitationDate,desc",
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getInvitations(params))
    const organizationResult = await getMyOrganization()

    if (
      result &&
      !result.error &&
      organizationResult &&
      !organizationResult.error
    ) {
      organizationResult["orgCreator"]["active"] = true

      if (invitesPaginationData.page === 0) {
        result["content"].unshift(organizationResult?.orgCreator) // Add company owner as first to the list
      }

      result["content"].forEach((invite) => {
        invite["orgCreator"] = organizationResult?.orgCreator.id === invite.id
      })

      setInvitesData({ ...invitesData, ...result })
      setInvitesPaginationData({
        ...invitesPaginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  })

  const handleInvitesPageClick = ({ selected }) => {
    setInvitesPaginationData({ ...invitesPaginationData, page: selected })
  }

  const initialContactDetails = user && {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.username,
    telephone: null,
  }

  const initialAdditionalContacts = {
    deliveriesContacts: {
      queriedEmails: [""],
      approvedEmails: [""],
      rejectedEmails: [""],
    },
    invoicesContacts: {
      queriedEmails: [""],
      approvedEmails: [""],
      rejectedEmails: [""],
    },
    ordersContacts: {
      placedEmails: [""],
    },
  }

  useEffect(() => {
    if (previousPage !== undefined) {
      getInvitesData()
    }
  }, [invitesPaginationData.page])

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    if (
      queryParams.tab &&
      enabledTabs.map((tab) => tab.key).indexOf(queryParams.tab) > -1
    ) {
      setActiveTab(queryParams.tab)
    }
  }, [])

  const hasNoAdditionalContacts =
    organization && organization.additionalContacts
      ? !organization.additionalContacts.deliveriesContacts &&
        !organization.additionalContacts.invoicesContacts &&
        !organization.additionalContacts.ordersContacts
      : true

  return (
    <>
      <Helmet>
        <title>Company settings</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Company settings" />

        {organization && (
          <ul className={styles.tabs}>
            {enabledTabs.map((tab) => {
              return (
                <li
                  key={tab.key}
                  className={activeTab === tab.key ? styles.activeTab : ""}
                >
                  <button
                    type="button"
                    onClick={() => navigate(`?tab=${tab.key}`)}
                  >
                    {tab.label}
                  </button>
                </li>
              )
            })}
          </ul>
        )}

        <div className={styles.content}>
          {organization ? (
            <>
              {activeTab === "locations" && (
                <div className="max-w-3xl">
                  <h2 className={styles.tabContentTitle}>Company locations</h2>
                  <OrganizationLocations />
                </div>
              )}
              {activeTab === "company-details" && (
                <div className="w-full flex flex-row flex-wrap lg:flex-nowrap">
                  <div className="w-full mr-6 mb-8 lg:w-1/2">
                    <div id="company" className="mb-8">
                      <h2 className={styles.tabContentTitle}>
                        Company details
                      </h2>

                      <OrganizationDetailsForm
                        initialValues={initialCompanyData}
                        onDataChange={(data) => onOrganizationChange(data)}
                        onValidChange={(isValid) =>
                          setCompanySubmitEnabled(isValid)
                        }
                      />
                    </div>

                    <div id="contact">
                      <h2 className={styles.tabContentTitle}>
                        Contact details
                      </h2>

                      <ContactDetailsForm
                        initialValues={
                          organization.contactDetails
                            ? organization.contactDetails
                            : initialContactDetails
                        }
                        onDataChange={(data) => contactDetailsChange(data)}
                        onValidChange={(isValid) =>
                          setContactSubmitEnabled(isValid)
                        }
                      />
                    </div>
                    <button
                      type="submit"
                      className="button button--autoWidth mt-2"
                      onClick={(e) => {
                        e.preventDefault()
                        saveOrganization({
                          address: addressData,
                          deliveryAddress: deliveryAddressData,
                          contactDetails: contactDetails,
                          companyName: companyData.companyName,
                          website: companyData.website,
                          businessType: companyData.businessType,
                          employeesNumberRange:
                            companyData.employeesNumberRange,
                        })
                      }}
                      disabled={
                        !addressSubmitEnabled() ||
                        !companySubmitEnabled ||
                        !contactSubmitEnabled ||
                        !permissionObj?.permissions.modify
                      }
                    >
                      Save
                    </button>
                  </div>

                  <div id="company-address" className="w-full lg:w-1/2">
                    <h2 className={styles.tabContentTitle}>Address details</h2>
                    <AddressForm
                      initialValues={
                        organization.address ? organization.address : {}
                      }
                      onDataChange={(data) => addressChange(data, "address")}
                      onValidChange={(isValid) => {
                        setAddressValid(isValid)
                      }}
                      allowNullable
                      isNullable={true}
                      selectTimeZone={true}
                    />
                    <div className="mb-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          checked={useDeliveryAddress}
                          onChange={(e) =>
                            setUseDeliveryAddress(e.target.checked)
                          }
                          name="differentDelivery"
                          className="form-checkbox text-primaryPink"
                        />
                        <span className="ml-2 text-gray-700">
                          Different delivery address
                        </span>
                      </label>
                      {useDeliveryAddress && (
                        <AddressForm
                          initialValues={
                            organization.deliveryAddress
                              ? organization.deliveryAddress
                              : {}
                          }
                          onDataChange={(data) =>
                            addressChange(data, "deliveryAddress")
                          }
                          onValidChange={(isValid) =>
                            setDeliveryAddressValid(isValid)
                          }
                          allowNullable
                          isNullable={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "users-details" && (
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <h2 className={styles.tabContentTitle}>Team</h2>
                    {permissionObj?.permissions.modify && (
                      <button
                        className="button button--primaryGreen"
                        onClick={() =>
                          modal.showModal(InviteFormModal, {
                            onUserInvited: () => {
                              getInvitesData()
                            },
                          })
                        }
                        style={{ width: "fit-content" }}
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Team member
                      </button>
                    )}
                  </div>

                  <Invites
                    invitesData={invitesData}
                    getInvitesData={getInvitesData}
                    invitesPaginationData={invitesPaginationData}
                    handleInvitesPageClick={handleInvitesPageClick}
                    loading={loading}
                  />
                </div>
              )}

              {activeTab === "notifications" && (
                <div className="w-full">
                  <div className="flex flex-col justify-between items-start">
                    <h2 className={styles.tabContentTitle}>Notifications</h2>
                    <h4 className={styles.tabContentSubTitle}>
                      Add the email addresses of team members or third parties
                      to send additional notifications{" "}
                    </h4>
                  </div>
                  <OrganizationNotifications
                    initialValues={
                      hasNoAdditionalContacts
                        ? initialAdditionalContacts
                        : organization.additionalContacts
                    }
                    onSubmit={(additionalContacts) => {
                      saveOrganization({ additionalContacts })
                    }}
                  />
                </div>
              )}

              {activeTab === "integrations" && (
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <h2 className={styles.tabContentTitle}>Integrations</h2>
                  </div>
                  <p className="text-gray-800 mb-6">
                    Integrations enable you to make third-party applications
                    part of your growyze workflow
                  </p>
                  <IntegrationList />
                </div>
              )}

              {activeTab === "features" && (
                <div className="w-full">
                  <CollapsibleSection
                    icon={faSyncAlt}
                    heading="Stock transfers"
                    description="Enable or disable stock transfer connections with other venues"
                  >
                    <StockTransferList />
                  </CollapsibleSection>

                  <CollapsibleSection
                    icon={faWineBottle}
                    heading="Categories set up"
                    description="Choose and/or create your product categories"
                  >
                    <CategoriesSettings />
                  </CollapsibleSection>
                </div>
              )}

              {activeTab === "billing-details" && (
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <h2 className={styles.tabContentTitle}>Billing</h2>
                  </div>
                  <p className="text-gray-800 mb-6">
                    Adjust your plan and billing options
                  </p>
                  <OrganizationPlanSelect />
                </div>
              )}

              {activeTab === "api-key" && (
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <h2 className={styles.tabContentTitle}>API Key</h2>
                  </div>
                  <p className="text-gray-800 mb-6">
                    Easily generate a new growyze API key for secure
                    organization authentication
                  </p>
                  <p className="text-gray-800 mb-6">
                    Only for GoEpos and Squirrel POS Integrations
                  </p>
                  <OrganizationApiKeyAuth />
                </div>
              )}
            </>
          ) : (
            <div className="flex h-full flex-col items-center justify-start mt-16">
              <h1 className="text-3xl mb-3">Nothing here yet..</h1>
              <p className="text-lg mb-b text-gray-700 mb-4">
                Please provide your company details to get you started.
              </p>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/dashboard/welcome")
                }}
                className="button button--autoWidth w-auto mb-3 bg-primaryPink"
              >
                Let’s start{" "}
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

OrganizationSettings.propTypes = {
  location: PropTypes.object,
}

export default OrganizationSettings
