import React, { RefObject } from "react"
import { isBrowser } from "services/helpers"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import "./BarcodeScanner.css"
import { TextResult } from "dynamsoft-javascript-barcode"

let promiseLoadDbrjs
let BarcodeReader
let BarcodeScanner
if (isBrowser()) {
  // prettier-ignore
  promiseLoadDbrjs = (async () => {
    try {
      ({ BarcodeReader, BarcodeScanner } = await import(
        "dynamsoft-javascript-barcode"
      ))
      if (!BarcodeReader.engineResourcePath.includes("/dbr/")) {
        BarcodeReader.engineResourcePath = "/dbr/"
        // Please visit https://www.dynamsoft.com/customer/license/trialLicense to get a trial license
        BarcodeReader.license = process.env.GATSBY_DYNAMSOFT_PROD_KEY
        // BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
      }
    } catch (e) {
      console.log('Could not load DBR library')
    }
  })()
}

interface ScannerCompProps {
  onReadSuccess(result: TextResult): void
  onInit(): void
  blur: boolean
  onAddManually(): void
}

class ScannerComp extends React.Component<ScannerCompProps> {
  constructor(props) {
    super(props)
  }

  scanner: any = null
  elRef: RefObject<HTMLDivElement> = React.createRef()

  async componentDidMount() {
    try {
      await promiseLoadDbrjs
      this.scanner =
        this.scanner ||
        (await BarcodeScanner.createInstance({
          bPlaySoundOnSuccessfulRead: true,
          regionMaskFillStyle: "rgba(0,0,0,0)",
          regionMaskStrokeStyle: "rgba(255,255,255, 1)",
          barcodeFillStyle: "rgba(252, 55, 98, 0.5)",
          barcodeStrokeStyle: "rgba(252, 55, 98, 0.9)",
        }))

      const settings = await this.scanner.getRuntimeSettings()
      /**
       * 1 means true
       * Using a percentage is easier
       * The following code shrinks the decoding region by 25% on all sides
       */
      settings.region.regionMeasuredByPercentage = 1
      settings.region.regionLeft = 25
      settings.region.regionTop = 25
      settings.region.regionRight = 75
      settings.region.regionBottom = 75
      await this.scanner.updateRuntimeSettings(settings)

      this.scanner.setUIElement(this.elRef.current)
      this.scanner.onUniqueRead = (_, result: TextResult) => {
        this.props.onReadSuccess(result)
      }
      await this.scanner.open()
      this.props.onInit()
    } catch (ex) {
      console.log("whats the error", ex)
      console.error(ex)
    }
  }
  async componentWillUnmount() {
    if (this.scanner) {
      try {
        await this.scanner.destroyContext()
      } catch (e) {
        console.log("error this.scanner.destroyContext")
      }
      // this.scanner.destroy()
    }
  }
  shouldComponentUpdate() {
    // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
    return false
  }

  async pause() {
    if (this.scanner) {
      try {
        await this.scanner.hide()
      } catch (e) {
        console.log("error this.scanner.hide")
      }
    }
  }

  async resume() {
    if (this.scanner) {
      try {
        await this.scanner.show()
      } catch (e) {
        console.log("error this.scanner.resume")
      }
    }
  }

  render() {
    return (
      <div
        ref={this.elRef}
        className={classNames("component-barcode-scanner", {
          blur: this.props.blur,
        })}
      >
        <svg
          className="dce-bg-camera"
          style={{ display: "none" }}
          viewBox="0 0 2048 1792"
        >
          <path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path>
        </svg>
        <div className="dce-video-container"></div>
        <canvas className="dbrScanner-cvs-drawarea"></canvas>
        <div className="dbrScanner-cvs-scanarea"></div>
        <div className="dbrScanner-scanActions">
          <button
            className="dbrScanner-scanAction"
            onClick={() => {
              this.pause()
              this.props.onAddManually()
            }}
          >
            <FontAwesomeIcon className="mr-3" icon={faPlus} />
            Add manually
          </button>
        </div>
      </div>
    )
  }
}

export default ScannerComp
