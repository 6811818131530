// extracted by mini-css-extract-plugin
export var bottomSummary = "DiscrepancyReport-module--bottomSummary--WScpg";
export var container = "DiscrepancyReport-module--container--kivtF";
export var content = "DiscrepancyReport-module--content--acOA4";
export var header = "DiscrepancyReport-module--header--efHYO";
export var headerColumns = "DiscrepancyReport-module--headerColumns--hM1I-";
export var headerContent = "DiscrepancyReport-module--headerContent--fDlCS";
export var headerRight = "DiscrepancyReport-module--headerRight--Sg7u3";
export var headerTopColumn = "DiscrepancyReport-module--headerTopColumn--AUoMZ";
export var reportContent = "DiscrepancyReport-module--reportContent--zlNPa";
export var summaryItem = "DiscrepancyReport-module--summaryItem--EeUL1";
export var thumbnail = "DiscrepancyReport-module--thumbnail--m37iW";
export var thumbnailBadge = "DiscrepancyReport-module--thumbnailBadge--pN6W8";