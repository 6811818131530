import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { isToday } from "date-fns"

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"

import {
  createWaste,
  createWasteRecipe,
  createWasteDish,
  deleteWaste,
  editWaste,
  editWasteRecipe,
  editWasteDish,
  getWastesById,
  deleteWasteGroupById,
  deleteWasteProduct,
  deleteWasteRecipe,
  deleteWasteDish,
  deleteWasteRecipeRecord,
  deleteWasteDishRecord,
  getWasteGroupForCurrentDay,
  getWastes,
} from "services/waste/waste"
import { updateOnboardingSteps } from "services/onboarding"
import { showSuccess, showError } from "services/toast"

import Loader from "components/common/Loader/Loader"
import WasteHeader from "../WasteHeader/WasteHeader"
import WasteContent from "../WasteContent/WasteContent"
import WasteFooter from "../WasteFooter/WasteFooter"
import WasteInput from "../WasteInput/WasteInput"
import { WasteTab } from "services/waste/types"

const WasteModal = ({
  onRequestClose,
  getDataWasteGroups,
  onDirectScan,
  allowDateSelect,
  ...otherProps
}) => {
  const { selectedWasteGroup } = useContext(GlobalStateContext)
  const { onboardingSteps } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState<WasteTab>("products")

  useEffect(() => {
    if (onDirectScan) onScanAction()
  }, [])

  const getWasteIdByDate = async (date) => {
    setIsLoading(true)

    const dateTimeString = new Date(date).toISOString()
    const params = {
      from: dateTimeString,
      to: dateTimeString,
    }
    const result = await getWastes(params)

    if (result && !result.error) {
      setIsLoading(false)
      return result.content.length ? result.content[0].id : ""
    }

    setIsLoading(false)
    return ""
  }

  const onCreateWaste = async (product, waste) => {
    const id = selectedWasteGroup.id
    const params = {
      ...waste,
      product: {
        barcode: product.barcode,
        id: product.id,
      },
    }

    const addedWaste = await createWaste(params, id)

    const today = isToday(new Date(addedWaste.date))

    if (addedWaste && !addedWaste.message) {
      showSuccess("Waste added successfully")

      if (today) {
        getWastesToday()
      } else {
        const id = await getWasteIdByDate(addedWaste.date)
        getDataWastes(id)
      }

      getDataWasteGroups()

      const params = {
        ...onboardingSteps,
        hasAddedWaste: true,
      }

      const updated = await updateOnboardingSteps(params)

      if (updated && !updated.message) {
        dispatch({
          type: "UPDATE_ONBOARDING_STEPS",
          payload: { onboardingSteps: updated },
        })
      }
    } else {
      showError("Adding waste failed, please try again later")
    }
  }

  const onCreateRecipeWaste = async (recipe, waste) => {
    const id = selectedWasteGroup.id
    const params = {
      ...waste,
      recipeId: recipe.id,
    }

    const addedWasteRecipe = await createWasteRecipe(params, id)
    const today = isToday(new Date(addedWasteRecipe.date))

    if (addedWasteRecipe && !addedWasteRecipe.message) {
      showSuccess("Waste added successfully")
      today ? await getWastesToday() : await getDataWastes()
      await getDataWasteGroups()
    } else {
      showError("Adding waste failed, please try again later")
    }
  }

  const onCreateDishWaste = async (dish, waste) => {
    const id = selectedWasteGroup.id
    const params = {
      ...waste,
      dishId: dish.id,
    }

    const addedWasteDish = await createWasteDish(params, id)
    const today = isToday(new Date(addedWasteDish.date))

    if (addedWasteDish && !addedWasteDish.message) {
      showSuccess("Waste added successfully")
      today ? await getWastesToday() : await getDataWastes()
      await getDataWasteGroups()
    } else {
      showError("Adding waste failed, please try again later")
    }
  }

  const onEditWaste = async (wasteGroupId, wasteId, barcode, updatedData) => {
    const edited = await editWaste(wasteGroupId, wasteId, barcode, updatedData)

    if (edited && !edited.message) {
      showSuccess("Waste edited successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(edited.message)
    }
  }

  const onEditWasteRecipe = async (
    wasteGroupId,
    wasteId,
    recipeId,
    updatedData
  ) => {
    const edited = await editWasteRecipe(
      wasteGroupId,
      wasteId,
      recipeId,
      updatedData
    )

    if (edited && !edited.message) {
      showSuccess("Waste edited successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(edited.message)
    }
  }

  const onEditWasteDish = async (
    wasteGroupId,
    wasteId,
    dishId,
    updatedData
  ) => {
    const edited = await editWasteDish(
      wasteGroupId,
      wasteId,
      dishId,
      updatedData
    )

    if (edited && !edited.message) {
      showSuccess("Waste edited successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(edited.message)
    }
  }

  const onDeleteWaste = async (waste, product, wasteGroupId) => {
    const deleted = await deleteWaste(
      wasteGroupId,
      waste.id,
      product.product.barcode
    )
    if (deleted && !deleted.message) {
      showSuccess("Waste deleted successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onDeleteWasteGroup = async (waste) => {
    const deleted = await deleteWasteGroupById(waste.id)
    if (deleted && !deleted.message) {
      showSuccess("Waste deleted successfully")
      onRequestClose()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onDeleteWasteProduct = async (wasteGroupId, product) => {
    const deleted = await deleteWasteProduct(
      wasteGroupId,
      product.product.barcode
    )

    if (deleted && !deleted.message) {
      showSuccess(
        "All waste records for this item (exc contained in recipes) are deleted"
      )
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onDeleteWasteRecipe = async (wasteGroupId, wasteId) => {
    const deleted = await deleteWasteRecipe(wasteGroupId, wasteId)

    if (deleted && !deleted.message) {
      showSuccess("All wastes for the product deleted successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onDeleteWasteDish = async (wasteGroupId, wasteId) => {
    const deleted = await deleteWasteDish(wasteGroupId, wasteId)

    if (deleted && !deleted.message) {
      showSuccess("All wastes for the product deleted successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onDeleteWasteRecipeRecord = async (wasteGroupId, recipeId, wasteId) => {
    const deleted = await deleteWasteRecipeRecord(
      wasteGroupId,
      recipeId,
      wasteId
    )

    if (deleted && !deleted.message) {
      showSuccess("All wastes for the recipe was deleted successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onDeleteWasteDishRecord = async (wasteGroupId, dishId, wasteId) => {
    const deleted = await deleteWasteDishRecord(wasteGroupId, dishId, wasteId)

    if (deleted && !deleted.message) {
      showSuccess("All wastes for the recipe was deleted successfully")
      getDataWastes()
      getDataWasteGroups()
    } else {
      showError(deleted.message)
    }
  }

  const onScanAction = (manually = false, tab?: WasteTab) => {
    // @ts-ignore
    modal.showModal(WasteInput, {
      waste: selectedWasteGroup,
      onSave: onCreateWaste,
      onSaveRecipe: onCreateRecipeWaste,
      onSaveDish: onCreateDishWaste,
      allowDateSelect,
      manually: manually,
      initialTab: tab,
    })
  }

  const getDataWastes = async (id = "") => {
    setIsLoading(true)
    const wastes = await getWastesById(id || selectedWasteGroup.id)

    if (wastes && !wastes.message) {
      dispatch({
        type: "SELECT_WASTE_GROUP",
        payload: {
          selectedWasteGroup: wastes,
        },
      })
      setIsLoading(false)
    } else {
      dispatch({
        type: "RESET_WASTE_GROUP_PRODUCTS",
      })
      setIsLoading(false)
    }
  }

  const getWastesToday = async () => {
    setIsLoading(true)
    const wastes = await getWasteGroupForCurrentDay()

    if (wastes && !wastes.message) {
      dispatch({
        type: "SELECT_WASTE_GROUP",
        payload: {
          selectedWasteGroup: wastes,
        },
      })
      setIsLoading(false)
    } else {
      showError("Could not get results")
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <div className="flex flex-col h-full">
        <WasteHeader
          wasteGroup={selectedWasteGroup}
          deleteWasteGroup={onDeleteWasteGroup}
        />
        <WasteContent
          wasteGroup={selectedWasteGroup}
          removeWaste={onDeleteWaste}
          deleteWasteProduct={onDeleteWasteProduct}
          deleteWasteRecipe={onDeleteWasteRecipe}
          deleteWasteDish={onDeleteWasteDish}
          deleteWasteRecipeRecord={onDeleteWasteRecipeRecord}
          deleteWasteDishRecord={onDeleteWasteDishRecord}
          editWaste={onEditWaste}
          editWasteRecipe={onEditWasteRecipe}
          editWasteDish={onEditWasteDish}
          tab={tab}
          setTab={setTab}
        />
        <WasteFooter
          closeModal={onRequestClose}
          onScanAction={onScanAction}
          tab={tab}
        />
        <Loader
          isLoading={isLoading}
          style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
        >
          Loading wastes..
        </Loader>
      </div>
    </Modal>
  )
}

WasteModal.propTypes = {
  onRequestClose: PropTypes.func,
  getDataWasteGroups: PropTypes.func,
  onDirectScan: PropTypes.bool,
}

export default WasteModal
