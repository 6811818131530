import React, { useContext } from "react"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import usePermissions from "hooks/usePermissions"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

const StandingOrderItemActions = ({ orderTemplate, handleActionClick }) => {
  const permissionObj = usePermissions("Orders")
  const { organization } = useContext(GlobalStateContext)

  const options: Array<{
    key: string
    title: string
    type: string
    disabled?: boolean
  }> = [
    {
      key: "orderTemplate.edit",
      title: "Edit",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "orderTemplate.delete",
      title: "Delete",
      type: "danger",
      disabled: !permissionObj?.permissions.delete,
    },
  ]

  return (
    <div className="px-1 flex-shrink-0" style={{ textAlign: "right" }}>
      {orderTemplate?.organizations.length &&
        orderTemplate?.organizations[0] === organization?.id && (
          <ActionsButton
            options={options}
            onActionClick={(action) => handleActionClick(action, orderTemplate)}
            disabled={!options.length}
          />
        )}
    </div>
  )
}

export default StandingOrderItemActions
