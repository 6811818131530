import { get, post } from "./api"
import { saveAs } from "file-saver"
import { getFileNameDateTime, slugify } from "./helpers"

export interface ExportReportTypeInterface {
  value: string
  label: string
  subUrl?: string
  status?: boolean
  supplier?: boolean
  transfers?: boolean
  period: boolean
  productSelector?: boolean
  downloadFormats: string[]
  organizations: string[] | null
}

interface TypeExportObjectInterface {
  urlType?: string
  singular: string
  statusOptions: { label: string; value: string; color: string }[]
  transferOptions: { label: string; value: string; color: string }[]
  reportTypes: ExportReportTypeInterface[]
}

export interface ExportObjectInterface {
  orders: TypeExportObjectInterface
  deliveries: TypeExportObjectInterface
  invoices: TypeExportObjectInterface
  transfers: TypeExportObjectInterface
}

export const getExportFileName = (
  mainContentType = "all",
  exportName = "",
  downloadFormat = "xlsx",
  datetime: String | Date | Array<Date> | null = null
) => {
  const strDatetime = getExportDatetime(datetime)
  const exportNameFormatted = exportName
    ? "-" + slugify(exportName.toLowerCase())
    : ""
  return `${mainContentType}${exportNameFormatted}-${strDatetime}.${downloadFormat}`
}

const getExportDatetime = (
  datetime: String | Date | Array<Date> | null = null
) => {
  if (datetime instanceof Date) {
    return getFileNameDateTime(datetime) // formated datetime object
  } else if (datetime instanceof Array) {
    return (
      "from-" +
      getFileNameDateTime(datetime[0]) +
      "-to-" +
      getFileNameDateTime(datetime[1])
    ) // for example two dates in an array
  } else if (typeof datetime == "string") {
    return datetime // for exampel date range "`from-yyyy-mm-dd-to-yyyy-mm-dd`"
  } else {
    return getFileNameDateTime()
  }
}

export const exportResponse = async (response, fileName) => {
  if (response) {
    const result = await response.blob()
    saveAs(result, fileName)
  } else {
    return false
  }
}

export const exportSummary = async (url, params, fileName) => {
  const response = await get(url, params, {
    download: true,
  })

  exportResponse(response, fileName)
}

export const exportDetailed = async (url, params, fileName) => {
  const statusQuery = params.status ? `&status=${params.status}` : ""
  const response = await post(
    `${url}?from=${params.from}&to=${params.to}${statusQuery}`,
    params,
    {
      download: true,
    }
  )

  exportResponse(response, fileName)
}

export const exportProductsOfInvoicesAsXlsx = async (params, fileName) => {
  const response = await post("/invoices/products/export-as-xlsx", params, {
    download: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/octet-stream",
    },
  })

  exportResponse(response, fileName)
}
