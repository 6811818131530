import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./AddComment.module.css"

const AddComment = ({ item, onSave, isSaving }) => {
  const areaRef = useRef(null)
  const [comment, setComment] = useState(item ? item.comment : "")

  //
  // Handlers
  //

  const handleTextareaChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setComment(value)
  }

  useEffect(() => {
    areaRef.current.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    // eslint-disable-next-line no-undef
    window.setTimeout(() => {
      const el = areaRef.current
      if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
      } else if (typeof el.createTextRange != "undefined") {
        el.focus()
        var range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <textarea
        ref={areaRef}
        className={`form-control border-0 px-0 ${styles.textarea}`}
        rows={6}
        onFocus={setCursorAtTheEnd}
        value={comment || ""}
        onChange={handleTextareaChange}
        placeholder={`Add a note for ${item.name}`}
      ></textarea>
      <button
        onClick={() => onSave(comment)}
        className="button button--primaryBlue"
        disabled={isSaving}
      >
        {isSaving && (
          <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
        )}
        Save
      </button>
    </div>
  )
}

AddComment.propTypes = {
  item: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

export default AddComment
