import { connect } from "formik"
import { useEffect } from "react"
import usePrevious from "react-use/lib/usePrevious"

const FormikEffect = ({ onChange, onValidationChange, formik }) => {
  const { values, isValid, validateForm } = formik
  const prevValues = usePrevious(values)
  const prevIsValid = usePrevious(isValid)

  /*
    Make sure form is validated on first load
   */
  useEffect(() => {
    if (!prevIsValid) {
      validateForm()
    }
  }, [])

  /*
    Effect for change of values
   */
  useEffect(() => {
    onChange({ prevValues, nextValues: values })
  }, [values])

  /*
    Effect for change of validation
   */
  useEffect(() => {
    onValidationChange(isValid)
  }, [isValid])

  return null
}

export default connect(FormikEffect)
