import React from "react"
import Modal from "react-modal"
import DishSelect from "components/common/DishSelect/DishSelect"

export default ({ onSelect, createNewDish, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  return (
    <Modal isOpen={isOpen} onRequestClose={() => {}}>
      <DishSelect
        onClose={() => {
          setIsOpen(false)
        }}
        onSelect={(product) => {
          setIsOpen(false)
          onSelect(product)
        }}
        createNewDish={
          createNewDish
            ? (data) => {
                setIsOpen(false)
                createNewDish(data)
              }
            : null
        }
        {...props}
      ></DishSelect>
    </Modal>
  )
}
