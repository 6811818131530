import { get, post, put, remove } from "./api"
import { PageableData, FileItem } from "services/types"
import { Product, Supplier } from "./types"

export interface Delivery {
  id: string
  organizations?: string[] | null
  deliveryNoteNumber: string
  po?: null
  deliveryDate: string
  dateOfScanning: string
  completedDate?: null
  approvedDate?: null
  inQueryDate?: null
  rejectedDate?: null
  supplier: Supplier
  products?: Product[]
  extractedFile?: null
  files?: null
  globalDiscrepancies?: null[] | null
  status: string
  message?: null
  messageQueryToSupplier?: null
  hasReceivedQtyDiscrepancies: boolean
  hasDNQtyDiscrepancies: boolean
  hasReceivedOrderQtyDiscrepancies: boolean
  hasNoOrderedProducts: boolean
  hasNoDeliveredProducts: boolean
  isCreatedManuallyWithoutOrder: boolean
  organizationsNames?: string[] | null
}

export const getDeliveryNotes = (params): Promise<PageableData<Delivery>> => {
  return get("/delivery-notes", params)
}

export const addDeliveryNote = (deliveryNote) => {
  return post("/delivery-notes", deliveryNote)
}

export const updateDeliveryNote = (deliveryNoteId, params) => {
  return put(`/delivery-notes/${deliveryNoteId}`, params)
}

export const querySupplier = (deliveryNoteId, params) => {
  return post(`/delivery-notes/${deliveryNoteId}/query-supplier`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const rejectDeliveryNote = (
  deliveryNoteId,
  { message, notifySupplier, completeOrder }
) => {
  return post(
    `/delivery-notes/${deliveryNoteId}/reject-dn?message=${message}&notifySupplier=${notifySupplier}&completeOrder=${completeOrder}`
  )
}

export const approveDeliveryNote = (deliveryNoteId) => {
  return post(`/delivery-notes/${deliveryNoteId}/approve-dn`)
}

export const removeDeliveryNote = (deliveryNoteId) => {
  return remove(`/delivery-notes/${deliveryNoteId}`)
}

export const updateDeliveryNoteFiles = async (
  deliveryNoteId: string,
  params: { files: FileItem[] }
) => {
  return post(`/delivery-notes/${deliveryNoteId}/files`, params)
}
