import { initialInvoice } from "context/global/InitialContext"

const invoiceReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_NEW_INVOICE": {
      return {
        ...state,
        newInvoice: {
          ...state.newInvoice,
          ...action.payload,
        },
      }
    }
    case "RESET_NEW_INVOICE": {
      return {
        ...state,
        newInvoice: {
          ...state.newInvoice,
          ...initialInvoice,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default invoiceReducer
