import { initialStockTransfer } from "context/global/InitialContext"
import dot from "services/dot-prop-immutable"

const stocktransferReducer = (state, action) => {
  switch (action.type) {
    /**
     * Stock transfer actions
     */

    case "CREATE_NEW_STOCK_TRANSFER": {
      const { stockTransferData } = action.payload

      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          ...stockTransferData,
        },
      }
    }

    case "RESET_NEW_STOCK_TRANSFER": {
      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          ...initialStockTransfer,
        },
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER": {
      const { stockTransferData } = action.payload
      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          ...stockTransferData,
        },
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT": {
      const { items, type } = action.payload
      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          [type]: items,
        },
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM_ADD": {
      const { item, type } = action.payload
      const items = dot.get(state, `newStockTransfer.${type}`)
      return {
        ...dot.set(state, `newStockTransfer.${type}`, [...items, ...[item]]),
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM": {
      const { item, type, id, idField } = action.payload

      const items = dot.get(state, `newStockTransfer.${type}`)
      const itemIndex =
        idField == "source.refId"
          ? items.findIndex((i) => i?.source?.refId == id)
          : items.findIndex((i) => i[idField] == id)
      const orgItem = items[itemIndex]

      return {
        ...dot.set(state, `newStockTransfer.${type}.${itemIndex}`, {
          ...orgItem,
          ...item,
        }),
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM_REMOVE": {
      const { id, type, idField } = action.payload

      const items = dot
        .get(state, `newStockTransfer.${type}`)
        .filter((item) => item[idField] !== id)

      return {
        ...dot.set(state, `newStockTransfer.${type}`, [...items]),
      }
    }
    default: {
      return state
    }
  }
}
export default stocktransferReducer
