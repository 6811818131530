import { faSpinner } from "@fortawesome/pro-duotone-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { addDays, format, parseISO } from "date-fns"
import useFreemiumCounter from "hooks/useFreemiumCounter"
import React from "react"
import Modal from "react-modal"
import UpgradeButton from "../UpgradeButton/UpgradeButton"

interface RequestCounterProps {
  onRequestClose?: () => void
  limitReachedMessage?: string
  limitReachedRow?: string
}

const RequestCounter = ({
  onRequestClose = () => {},
  limitReachedMessage = "",
  limitReachedRow = "",
}: RequestCounterProps) => {
  const { formattedFreemiumData, additionalData } = useFreemiumCounter()

  const lastRefreshDate = additionalData?.lastResetDate?.current
  const parsedDate = lastRefreshDate ? parseISO(lastRefreshDate) : ""
  const renewalDate = addDays(parseISO(lastRefreshDate), 30)

  const limitReachedDisplayName =
    formattedFreemiumData?.[limitReachedRow]?._displayName

  const limitReachedMessageParts = limitReachedMessage
    .replace(/\[(.+?)\]/g, `|`)
    .split("|")

  const limitReachedMessageFormated =
    limitReachedMessageParts?.length == 2 ? (
      <>
        {limitReachedMessageParts[0]}
        <strong>{limitReachedDisplayName}</strong>
        {limitReachedMessageParts[1]}
      </>
    ) : (
      "Limit reached"
    )

  return (
    <Modal
      onRequestClose={onRequestClose}
      portalClassName="overflow-auto"
      isOpen
    >
      <div className="text-xl text-primaryBlue font-semibold w-full border-b px-8 py-4 flex justify-between items-center">
        <h2>Remaining actions</h2>
        <button onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="flex flex-col overflow-auto h-full">
        <div className="px-8 py-4 space-y-2">
          {limitReachedMessage && (
            <div className="border rounded-md bg-primaryPinkLighter bg-opacity-20 p-4 space-y-2">
              <p className="text-error font-semibold">
                {limitReachedMessageFormated}
              </p>
              <p>
                You have hit the limit on your plan. Upgrade now for unlimited
                actions and advanced features!
              </p>
            </div>
          )}

          <div className="text-gray-600 text-base space-y-2 px-2">
            <p>Your Free plan includes:</p>
          </div>
        </div>

        <div className="px-8 pb-8">
          <div className="flex font-semibold mb-2 text-primaryBlue px-2">
            <span className="w-1/2">Feature</span>
            <span className="w-1/4">Current</span>
            <span className="w-1/4">Remaining</span>
          </div>
          {!Object.keys(formattedFreemiumData).length && (
            <div className="flex justify-center px-8">
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-4xl mt-8"
                spin
              />
            </div>
          )}
          <ul className="text-xs">
            {Object.values(formattedFreemiumData).map((curr: any, idx) => {
              return (
                <li
                  className={
                    "flex items-center border-b p-2" +
                    (curr._slug === limitReachedRow
                      ? " bg-primaryPinkLighter bg-opacity-20"
                      : "")
                  }
                  key={curr._slug + idx}
                >
                  <span className="inline-block font-semibold w-1/2 text-sm">
                    {curr._displayName}
                  </span>
                  <div className="flex w-1/2">
                    <div className="w-1/2">
                      <span className="text-gray-600 text-sm">
                        {curr.current}
                      </span>
                    </div>
                    <div className="w-1/2">
                      <span
                        className={`${
                          curr.remaining <= 0
                            ? "text-red-500"
                            : "text-green-500"
                        } font-semibold text-sm`}
                      >
                        {curr.remaining}
                      </span>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="px-8 mb-8 text-gray-600 bg-gray-200 py-4">
          <p className="mb-4">The number of actions resets every 30 days</p>
          {lastRefreshDate && (
            <p className="text-primaryBlue">
              Date last renewed:{" "}
              <span className="font-semibold">
                {lastRefreshDate?.split("T")[0].split("-").reverse().join("/")}
              </span>
            </p>
          )}
          {parsedDate && (
            <p className="text-primaryBlue">
              Date to be renewed:{" "}
              <span className="font-semibold">
                {format(renewalDate, "dd/MM/yyyy")}
              </span>
            </p>
          )}
        </div>
      </div>
      <footer className="flex justify-end space-x-4 items-center py-4 border-t px-8">
        <a
          href="https://www.growyze.com/book-a-demo"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline py-2"
        >
          Book a demo
        </a>
        {/* <Link
          onClick={onRequestClose}
          to="/dashboard/company-settings?tab=billing-details"
          target="_blank"
          className="bg-primaryBlue hover:bg-primaryBlueLighter text-white font-semibold px-6 py-2 rounded-full"
        >
          Upgrade
        </Link> */}
        <UpgradeButton onClick={onRequestClose} />
      </footer>
    </Modal>
  )
}

export default RequestCounter
