import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import Modal from "react-modal"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import AsyncSelect from "components/forms/AsyncSelect"
import { normalizeNullValues } from "services/helpers"
import { getStockTransferConnectionsByCriteria } from "services/stock-transfer"
import { Organization } from "services/types"

import * as styles from "./StockTransferEditVenue.module.css"

Modal.setAppElement("body")

const OrganizationsSchema = Yup.object().shape({
  organization: Yup.object().required(),
})

interface StockTransferEditVenueProps {
  onRequestClose: Function
  organization: Organization
  onSelect: Function
}

const StockTransferEditVenue = ({
  organization,
  onSelect,
  onRequestClose,
}: StockTransferEditVenueProps) => {
  const initialValues = {
    organization: {},
  }

  const getStockTransferConnections = () => {
    const params = {
      size: 20,
    }
    return getStockTransferConnectionsByCriteria(params)
  }

  const onSubmit = async (data) => {
    const orgData = {
      receiverOrg: {
        companyName: data.organization.companyName,
        id: data.organization.id,
      },
      senderOrgId: organization.id,
    }
    onSelect(orgData)
  }

  return (
    <div className={styles.container}>
      <div className={styles.thumbnail}>
        <FontAwesomeIcon icon={faSyncAlt} size="lg" />
        <span className={styles.thumbnailBadge}>
          <span>
            <FontAwesomeIcon size="xs" icon={faArrowRight} />
          </span>
        </span>
      </div>
      <div className={styles.welcomeContent}>
        <p className="mt-4">Select the venue you want to transfer stock to</p>
      </div>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={normalizeNullValues(initialValues)}
        validationSchema={OrganizationsSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, isSubmitting, values, setValues }) => (
          <Form className={styles.form}>
            <div>
              <AsyncSelect
                promise={getStockTransferConnections}
                placeholder="Venue"
                name="organization"
                id="organization"
                label="Venue"
                isClearable={true}
                optionLabel={(opt) => opt.connectedOrg.companyName}
                optionValue="connectedOrg"
                className="form-control form-control--first form-control--last"
                blurInputOnSelect={false}
                onChange={(val) => {
                  setValues({
                    ...values,
                    organization: val ? val.value : "",
                  })
                }}
              />
            </div>

            <div className={styles.buttons}>
              <button
                type="button"
                onClick={() => onRequestClose()}
                className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
              >
                Cancel
              </button>
              <button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                className={"button button--autoWidth"}
              >
                Switch
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StockTransferEditVenue
