import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

interface ColoredIconProps {
  backgroundColor: string
  color: string
  icon: IconDefinition
  className?: string
}

const ColoredIcon = ({
  backgroundColor,
  color,
  icon,
  className,
}: ColoredIconProps) => {
  return (
    <div
      className={`w-10 h-10 rounded-full flex items-center justify-center ${
        className || ""
      }`}
      style={{
        backgroundColor,
        color,
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  )
}

export default ColoredIcon
