import React, { useContext, useEffect, useState } from "react"

//@ts-ignore
import * as styles from "./EditDish.module.css"
import { Form, Formik, FormikHelpers } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"
import TextInput from "components/forms/TextInput"
import {
  createDish,
  Dish,
  reassignDishToOrg,
  removeDish,
  saveDish,
  updateDishFeaturedImage,
  updateDishFiles,
} from "services/dishes"
import EditSections from "./EditSections"
import CustomSelect from "components/forms/CustomSelect"
import { allergens, editDishInfoTabs, editDishTabs } from "services/constants"
import { showSuccess, showError } from "services/toast"
import { navigate } from "gatsby"
import PrintDish from "../PrintDish/PrintDish"
import FeaturedImage from "components/common/FeaturedImage/FeaturedImage"
import TextArea from "components/forms/TextArea"
import FileList from "components/common/FileList/FileList"
import { Category, FileItem, Permission } from "services/types"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import { DishElement } from "../../../services/dishes"
import { normalizeIngredientMeasures, printPage } from "services/helpers"
import usePermissions from "hooks/usePermissions"
import { findValueIcon, findValueLabel } from "../../../services/helpers"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import LocationSelection from "components/common/LocationSelection/LocationSelection"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import RecipeAndDishWaste from "components/recipes/EditRecipe/RecipeAndDishWaste"
import { useFileOwner } from "hooks/useFileOwner"
import useCategories from "hooks/useCategories"
import SalesAndProfit from "components/common/SalesAndProfit/SalesAndProfit"

export interface Props {
  dish: Dish
  getData: (id: string | undefined) => void
  isAsideView: boolean
  onSubmitCallback: (data: any) => {}
  onClose: () => void
}

export default ({
  dish,
  getData,
  isAsideView,
  onSubmitCallback,
  onClose,
}: Props) => {
  const modal = useContext(ModalContext)
  const [infoTabs, setInfoTab] = useState("generalInfo")
  const [tab, setTab] = useState<string>("elements")
  const [featuredFile, setFeaturedFile] = useState<FileItem | null>(
    dish.featuredFile ? dish.featuredFile : null
  )
  const { organizationPermissions } = useContext(GlobalStateContext)
  const isAllowedToManageLocations = organizationPermissions?.general?.isMain
  const [files, setFiles] = useState<FileItem[]>(dish.files ? dish.files : [])
  const [fileLoading, setFileLoading] = useState(false)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([])
  const filesOwner = useFileOwner(dish)

  const filterFunction = (categories: Category[]) => {
    return categories
      .filter((category) => category.active)
      .map((category) => ({
        value: category.name,
        label: category.name,
      }))
  }
  const { categories } = useCategories(filterFunction)

  const [isWastePercentage, setIsWastePercentage] =
    React.useState<boolean>(true)

  const preprocessDishElement = (element: DishElement): DishElement => {
    if (element.type === "INGREDIENT") {
      element.ingredient = normalizeIngredientMeasures(element.ingredient)
      return element
    } else {
      return element
    }
  }
  const preprocessDish = (dish: Dish): Dish => ({
    ...dish,
    sections: dish.sections.map((section) => ({
      name: section.name,
      elements: section.elements.map(preprocessDishElement),
    })),
  })

  const generalInfoTabs = [
    ...editDishInfoTabs,
    ...(isAllowedToManageLocations
      ? [
          {
            key: "locations",
            label: "Manage locations",
          },
        ]
      : []),
  ]

  useEffect(() => {
    setIsWastePercentage(dish?.waste?.percent ? true : false)
  }, [dish])

  const saveLocations = async (dishId: string) => {
    const params = {
      organizations: selectedOrgs,
    }
    return await reassignDishToOrg(dishId, params)
  }

  const submit = (dish, helpers: FormikHelpers<Dish>) => {
    if (isWastePercentage) {
      dish.waste = { ...dish.waste, cost: null }
    } else {
      dish.waste = { ...dish.waste, percent: null }
    }

    const newDish = dish.id === undefined

    const request = newDish
      ? createDish(dish)
      : saveDish(dish.id, preprocessDish(dish))
    request
      .then((response) => {
        const { message, status } = response
        helpers.setSubmitting(false)
        if (
          [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 500].includes(
            status
          )
        ) {
          throw { message: message || "Something went wrong", status }
        } else {
          return response
        }
      })
      .then(async (dishRes) => {
        if (!dishRes) return

        if (newDish) {
          if (files.length > 0) {
            setFileLoading(true)
            updateDishFiles(dishRes.id, { files: files }).then(() =>
              setFileLoading(false)
            )
          }

          /** Update locations for this dish */
          if (isAllowedToManageLocations) {
            await saveLocations(dishRes.id)
          }

          if (featuredFile) {
            updateDishFeaturedImage(dishRes.id, featuredFile)
          }

          if (onSubmitCallback) {
            onSubmitCallback(dishRes)
            onClose() //Closes dish modal if callback is provided
          }

          if (isAsideView) {
            getData(dishRes.id)
          } else {
            navigate("/dashboard/products/cookbook/dishes/" + dishRes.id)
          }
        } else {
          helpers.setValues(dishRes)
          setFiles(dishRes.files)
          setFeaturedFile(dishRes.featuredFile)
        }
      })
      .then((res) => {
        if (res === undefined) return
        showSuccess(dish.id ? "Dish saved." : "Dish created.")
      })
      .catch(({ message }) => showError(message))
  }

  const updateFeaturedImg = async (file) => {
    setFeaturedFile(file)

    // If dish doesn't exist, don't try to save the featured img
    if (!dish.id) {
      return
    }
    await updateDishFeaturedImage(dish.id, file)
  }

  const handleFeaturedImgChange = ({ action, file }) => {
    switch (action) {
      case "file-added":
        updateFeaturedImg(file)
        break
      case "file-removed":
        updateFeaturedImg(null)
        break
      default:
        break
    }
  }

  const handleFileUploaded = async (newFiles) => {
    setFileLoading(true)

    const newFileList = [...(files || []), ...newFiles]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    // If there is no recipe yet, don't try to save the files
    if (!dish.id) {
      setFileLoading(false)
      return
    }

    try {
      await updateFiles(newFileList)
      // setStats(formatRecipeData(res))
      setFileLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setFileLoading(false)
      showError(e.message || "File upload failed")
    }
  }

  const updateFiles = (files) => {
    return updateDishFiles(dish.id, { files: files })
  }

  const handleFileDeleted = async (file) => {
    setFileLoading(true)
    const newFileList = [...files.filter((f) => f.fileId !== file.fileId)]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    // If there is no recipe yet, don't try to save the files
    if (!dish.id) {
      setFileLoading(false)
      return
    }

    try {
      await updateFiles(newFileList)
      // setStats(formatRecipeData(res))
      setFileLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setFileLoading(false)
      showError(e.message || "File delete failed")
    }
  }

  const onDelete = () => {
    // @ts-ignore
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete dish",
      text: "Are you sure you want to delete this dish?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteDish(),
    })
  }

  const onDuplicate = async () => {
    const newDish = {
      ...dish,
      posId: null,
      menus: null,
      files: [],
      featuredFile: null,
      name: `Copy of ${dish.name}`,
    }

    const currentFiles = dish.files
    const currentFeaturedFile = dish.featuredFile

    delete newDish.id
    try {
      const result = await createDish(newDish)

      if (currentFiles && currentFiles.length > 0) {
        await updateDishFiles(result.id, { files: currentFiles })
      }
      if (currentFeaturedFile) {
        await updateDishFeaturedImage(result.id, currentFeaturedFile)
      }
      if (isAsideView) {
        getData(result.id)
      } else {
        navigate(`/dashboard/products/cookbook/dishes/${result.id}`)
      }
      showSuccess("Dish duplicated!")
    } catch (e) {
      console.log(e)
      showError("Something went wrong")
    }
  }

  const deleteDish = async () => {
    const deleted = await removeDish(dish.id)
    if (deleted) {
      showSuccess("Dish deleted!")
      if (isAsideView) {
        getData(dish.id)
      } else {
        navigate("/dashboard/products/cookbook/dishes")
      }
    }
  }

  const dishActions = [
    {
      key: "dish.duplicate",
      title: "Duplicate",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    // { key: "dish.add_to_menu", title: "Add to menu", type: "default" },
    {
      key: "dish.print",
      title: "Print",
      type: "default",
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "dish.delete",
      title: "Delete",
      type: "danger",
      disabled:
        !permissionObj?.permissions.delete ||
        !organizationPermissions?.dishes?.remove,
    },
  ]

  const handleActionClick = (action) => {
    const { type } = action
    switch (type) {
      case "dish.delete":
        onDelete()
        break
      case "dish.duplicate":
        onDuplicate()
        break
      case "dish.print":
        printPage()
        break
      default:
        break
    }
  }

  return (
    <Formik initialValues={dish} onSubmit={submit}>
      {({ isSubmitting, values }) => {
        const dishHasAllergens = [
          ...(values.allergens || []),
          ...(values.mayContainAllergens || []),
        ].length

        return (
          <>
            <Form className={styles.container}>
              <div className={styles.wrapper}>
                <div className={`growyze-tabs bg-gray-100 ${styles.tabs}`}>
                  <ul>
                    {generalInfoTabs.map((tab) => {
                      return (
                        <li key={tab.key}>
                          <button
                            type="button"
                            className={infoTabs === tab.key ? "active" : ""}
                            onClick={() => setInfoTab(tab.key)}
                          >
                            {tab.label}
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                {
                  {
                    generalInfo: (
                      <div className={styles.infoSection}>
                        <div className={styles.form}>
                          <div className="form-group">
                            <div className="input-container">
                              <TextInput
                                required={true}
                                showValidations={false}
                                name="name"
                                label="Dish name"
                                placeholder="Dish name"
                                disabled={!permissionObj?.permissions.modify}
                                type="string"
                                className="form-control form-control--first"
                              />
                            </div>
                            <div className="input-container">
                              <CustomSelect
                                name="category"
                                label="Category"
                                placeholder="Category"
                                isDisabled={!permissionObj?.permissions.modify}
                                defaultValue={values.category}
                                className="form-control form-control--last"
                                options={categories}
                              ></CustomSelect>
                            </div>
                            <div className="input-container">
                              <TextInput
                                name="barcode"
                                required={false}
                                label="Barcode (optional)"
                                disabled={!permissionObj?.permissions.modify}
                                type="text"
                                placeholder="Barcode (optional)"
                                className="form-control form-control--last"
                              />
                            </div>
                          </div>
                        </div>

                        <div className={styles.formFeaturedImg}>
                          <h3 className={styles.sectionHeading}>
                            Featured image
                          </h3>
                          <FeaturedImage
                            onChange={handleFeaturedImgChange}
                            currentFile={featuredFile}
                            disabled={!permissionObj?.permissions.modify}
                            clientKey="dish"
                          />
                        </div>

                        <div className="form-group self-stretch flex flex-col flex-1 w-full md:w-1/5 px-2 mb-4">
                          <span className="inline-flex items-baseline justify-start">
                            <span className="mr-1 mb-3 font-sansSemiBold font-semibold text-gray-800">
                              Allergens
                            </span>
                            <ReactTooltip
                              id={`infoBox-${dishHasAllergens}`}
                              type="light"
                              place="top"
                              effect="solid"
                              border={true}
                              borderColor="#e2e8f0"
                            />
                            <span
                              data-tip="growyze accepts no liability for the use of any information resulting from the use of this calculator. It is your responsibility to ensure that this information is true and correct"
                              data-for={`infoBox-${dishHasAllergens}`}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                          </span>
                          {!dishHasAllergens ? (
                            <p className="text-xs">No allergens</p>
                          ) : (
                            <>
                              {values.allergens?.length > 0 ? (
                                <div className="mb-4">
                                  <h4 className="text-xs">Contains</h4>
                                  <div className="flex flex-wrap">
                                    {values.allergens?.map((allergen) => (
                                      <span
                                        key={allergen}
                                        className="w-14 m-2 text-xs flex flex-col items-center capitalize text-center"
                                      >
                                        <img
                                          className="w-8 p-1"
                                          src={findValueIcon(
                                            allergens,
                                            allergen
                                          )}
                                        />
                                        {findValueLabel(allergens, allergen)}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              ) : null}
                              {values.mayContainAllergens?.length > 0 ? (
                                <div>
                                  <h4 className="text-xs">May contain</h4>
                                  <div className="flex flex-wrap">
                                    {values.mayContainAllergens?.map(
                                      (allergen) => (
                                        <span
                                          key={allergen}
                                          className="w-14 m-2 text-xs flex flex-col items-center capitalize text-center"
                                        >
                                          <img
                                            className="w-8 p-1"
                                            src={findValueIcon(
                                              allergens,
                                              allergen
                                            )}
                                          />
                                          {findValueLabel(allergens, allergen)}
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    ),
                    salesAndProfit: <SalesAndProfit values={values} />,
                    locations: (
                      <div className="px-4 lg:px-6 pt-4">
                        <h3 className={styles.sectionHeading}>Locations</h3>
                        <p className="text-gray-700 text-sm mb-3">
                          Distribute this dish across your locations
                        </p>
                        <LocationSelection
                          currentSelection={selectedOrgs}
                          onSelectionChange={(orgs) => setSelectedOrgs(orgs)}
                          itemId={dish.id}
                          itemType="dish"
                          itemClass={"md:w-1/2 lg:w-1/3"}
                        />
                      </div>
                    ),
                  }[infoTabs]
                }

                <div className={`growyze-tabs bg-gray-100 ${styles.tabs}`}>
                  <ul>
                    {editDishTabs.map((t) => {
                      return (
                        <li key={t.key}>
                          <button
                            type="button"
                            className={tab === t.key ? "active" : ""}
                            onClick={() => setTab(t.key)}
                          >
                            {t.label}
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className={styles.tabContent}>
                  {
                    {
                      elements: (
                        <div className="px-4 lg:px-6">
                          <EditSections sections={values.sections} />
                        </div>
                      ),
                      method: (
                        <div className="px-4 lg:px-6 py-6 w-full">
                          <h4 className="mr-2 font-sansSemiBold font-semibold mb-4 text-gray-800">
                            Method
                          </h4>
                          <div className="input-container">
                            <TextArea
                              showValidations={false}
                              name="method"
                              value={values.method}
                              className={`${styles.dishDescription} form-control--first form-control--last`}
                              placeholder="Add description/method here"
                              disabled={!permissionObj?.permissions.modify}
                              label="Method"
                              id="dish-method"
                              rows={10}
                              key="method"
                            />
                          </div>
                        </div>
                      ),
                      waste: (
                        <RecipeAndDishWaste
                          data={dish}
                          isWastePercentage={isWastePercentage}
                          setIsWastePercentage={setIsWastePercentage}
                        />
                      ),
                      files: (
                        <div className="m-4">
                          <FileList
                            files={files}
                            filesOwner={filesOwner}
                            isLoading={fileLoading}
                            clientKey="dish"
                            disabled={!permissionObj?.permissions.modify}
                            onUploaded={handleFileUploaded}
                            onDeleted={handleFileDeleted}
                          />
                        </div>
                      ),
                      notes: (
                        <div className="px-4 lg:px-6 py-6 w-full">
                          <h4 className="mr-2 font-sansSemiBold font-semibold mb-4 text-gray-800">
                            Notes
                          </h4>
                          <div className="input-container">
                            <TextArea
                              showValidations={false}
                              name="notes"
                              value={values.notes}
                              className={`${styles.dishDescription} form-control--first form-control--last`}
                              placeholder="Any notes you like to add to this dish?"
                              label="Notes"
                              disabled={!permissionObj?.permissions.modify}
                              id="dish-notes"
                              rows={10}
                              key="notes"
                            />
                          </div>
                        </div>
                      ),
                    }[tab]
                  }
                </div>
              </div>
              <div className={styles.formFooter}>
                {dish.id && (
                  <ActionsButton
                    options={dishActions}
                    buttonClass={styles.actionBtn}
                    dropDirection="top"
                    onActionClick={(action) => handleActionClick(action)}
                    asPortal={false}
                  />
                )}
                <button
                  className="button button--autoWidth button--primaryGreen"
                  type="submit"
                  disabled={isSubmitting || !permissionObj?.permissions.modify}
                >
                  {dish.id ? "Save" : "Create"}
                </button>
              </div>
            </Form>
            <PrintDish values={values} image={featuredFile}></PrintDish>
          </>
        )
      }}
    </Formik>
  )
}
