import React, { useState, useEffect, useContext } from "react"
import Helmet from "react-helmet"
import { format } from "date-fns"
import {
  formatCurrencyValue,
  removeTimezone,
  roundNumber,
} from "services/helpers"
import {
  constructWasteReport,
  exportWasteReportAsCSV,
} from "services/waste/waste"
import { getProductByNameAndBarcode } from "services/products/products"
import { showError } from "services/toast"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faBan,
} from "@fortawesome/pro-duotone-svg-icons"
import { faFileDownload, faEllipsisH } from "@fortawesome/pro-regular-svg-icons"

import Header from "components/dashboard/Header/Header"
import AsyncSelect from "components/forms/AsyncSelect"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import Loader from "components/common/Loader/Loader"
import WasteReportContent from "../WasteReportContent/WasteReportContent"
import { AsideContext } from "context/aside/AsideContext"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"

import * as styles from "./WasteReport.module.css"
import usePermissions from "hooks/usePermissions"
import { getExportFileName } from "services/export"

const WasteReport = () => {
  const aside = useContext(AsideContext)
  const [wasteReport, setWasteReport] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  })
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const permissionObj = usePermissions("Waste")

  const toggleDatePicker = () => {
    //@ts-ignore
    aside.showAside(AsideCalendar, {
      selectedPeriod,
      headerText: "Date picker",
      onConfirm: (data) => {
        setSelectedPeriod(data)
        aside.hideAside()
      },
      onClose: () => aside.hideAside(),
    })
  }

  const loadProductOptions = (inputValue) =>
    getProductByNameAndBarcode({ partialName: inputValue })

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  const handleActionClick = ({ type }) => {
    const params = {
      from: removeTimezone(wasteReport.fromDate),
      to: removeTimezone(wasteReport.toDate),
      barcode: selectedProduct && selectedProduct.barcode,
    }

    const fileName = getExportFileName(
      "waste",
      "report",
      "csv",
      `${params.from}-${params.to}`
    )

    switch (type) {
      case "export":
        exportWasteReportAsCSV(params, fileName)
        break
      default:
    }
  }

  const getData = async () => {
    setLoading(true)

    const { from, to } = selectedPeriod

    let params = {}

    if (from && to) {
      params.from = removeTimezone(from)
      params.to = removeTimezone(to)
    }

    if (selectedProduct) {
      params.barcode = selectedProduct.barcode
    }

    const wasteReport = await constructWasteReport(params)

    if (wasteReport && !wasteReport.message) {
      setWasteReport(wasteReport)
      setLoading(false)
    } else {
      showError(wasteReport.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedPeriod.to && selectedPeriod.from) {
      getData()
    }
  }, [selectedPeriod])

  useEffect(() => {
    if (selectedPeriod.from) {
      getData()
    }
  }, [selectedProduct])

  const dropdownOptions = [
    {
      key: "export",
      title: "Export to .CSV",
      disabled: !permissionObj?.permissions.read,
    },
  ]

  const currency = null

  return (
    <>
      <Helmet>
        <title>Waste report</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Waste report" />

        <div className={styles.content}>
          <Loader
            isLoading={isLoading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading report..
          </Loader>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <div className={styles.headerTopColumn}>
                <div className="flex items-center">
                  <div className={styles.thumbnail}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span className={styles.thumbnailBadge}>
                      <span
                        style={{
                          backgroundColor: "#FFE5DB",
                          color: "#F52525",
                        }}
                      >
                        <FontAwesomeIcon size="xs" icon={faBan} />
                      </span>
                    </span>
                  </div>
                  <div className="my-2 mr-4 flex flex-col">
                    <h2 className="text-md mb-1">Waste report</h2>
                    {wasteReport ? (
                      <p className="text-gray-700 text-sm">
                        <span className="mr-6">
                          Period:{" "}
                          <strong>{`${format(
                            new Date(wasteReport.fromDate),
                            "dd/MM/yyyy"
                          )}`}</strong>{" "}
                          -{" "}
                          <strong>{`${format(
                            new Date(wasteReport.toDate),
                            "dd/MM/yyyy"
                          )}
                        `}</strong>
                        </span>
                      </p>
                    ) : (
                      <span className="text-sm text-gray-700">
                        No dates selected
                      </span>
                    )}
                  </div>
                </div>

                <div className="my-2 flex-grow sm:mr-4">
                  <AsyncSelect
                    promise={loadProductOptions}
                    placeholder="Please select a product"
                    className={styles.productSelect}
                    defaultValue={
                      selectedProduct
                        ? {
                            label: selectedProduct.name,
                            value: selectedProduct.name,
                          }
                        : true
                    }
                    defaultOptions={true}
                    isClearable={true}
                    optionLabel="description"
                    optionValue="description"
                    includeMeta
                    noOptionsMessage={() => "Type to search"}
                    onChange={(product) => {
                      setSelectedProduct(product)
                    }}
                  />
                </div>

                <div className="my-2 flex w-full sm:w-auto mr-4 max-w-xs">
                  <div className="flex flex-1 md:flex-none">
                    <div className={`${styles.dateField} input-container mr-4`}>
                      <input
                        type="text"
                        value={formatDate(selectedPeriod.from)}
                        className="cursor-pointer form-control rounded"
                        placeholder="From"
                        readOnly
                        onClick={toggleDatePicker}
                      />
                    </div>
                  </div>
                  <div className="flex flex-1 md:flex-none">
                    <div className={`${styles.dateField} input-container`}>
                      <input
                        type="text"
                        className="cursor-pointer form-control rounded"
                        value={formatDate(selectedPeriod.to)}
                        placeholder="To"
                        readOnly
                        onClick={toggleDatePicker}
                      />
                    </div>
                  </div>
                </div>

                {selectedPeriod.from && selectedPeriod.to && (
                  <div className="flex my-2 justify-end flex-shrink">
                    <DropdownButton
                      label={<FontAwesomeIcon icon={faFileDownload} />}
                      mobileIcon={faEllipsisH}
                      options={dropdownOptions}
                      onActionClick={handleActionClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.reportContent}>
            {wasteReport ? (
              <WasteReportContent wasteReport={wasteReport} />
            ) : (
              <span className="text-lg text-gray-600 font-sansBold mt-2">
                To create a report, select a from date
              </span>
            )}
          </div>
        </div>
        {wasteReport && (
          <div className={styles.bottomSummary}>
            Total cost of waste:{" "}
            <span
              className={"font-sansSemiBold font-semibold text-primaryPink"}
            >
              {currency
                ? formatCurrencyValue(wasteReport.totalCost)
                : roundNumber(wasteReport.totalCost)}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default WasteReport
