// extracted by mini-css-extract-plugin
export var content = "AddRecipeForm-module--content--+Ktcu";
export var formBottom = "AddRecipeForm-module--formBottom--Iy0W9";
export var formLabel = "AddRecipeForm-module--formLabel--RYHLG";
export var header = "AddRecipeForm-module--header--+R3AR";
export var productMeta = "AddRecipeForm-module--productMeta--4vtDw";
export var productName = "AddRecipeForm-module--productName--5f+ao";
export var quantityInput = "AddRecipeForm-module--quantityInput--dgfOe";
export var quantityInputSecond = "AddRecipeForm-module--quantityInputSecond--eGJot";
export var subValueContainer = "AddRecipeForm-module--subValueContainer--vx2jc";
export var subValueLabel = "AddRecipeForm-module--subValueLabel--E7OQz";
export var summary = "AddRecipeForm-module--summary--tBI9X";
export var summaryItem = "AddRecipeForm-module--summaryItem--45cn6";
export var summaryItemList = "AddRecipeForm-module--summaryItemList--ty3Kk";