import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import Modal from "react-modal"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import AsyncSelect from "components/forms/AsyncSelect"
import { normalizeNullValues } from "services/helpers"
import { getStockTransferConnectionsByCriteria } from "services/stock-transfer"

import * as styles from "./StockTransferVenueSelectModal.module.css"
import { Organization } from "services/types"

Modal.setAppElement("body")

const OrganizationsSchema = Yup.object().shape({
  organization: Yup.object().required(),
})

interface StockTransferVenueSelectModalProps {
  onRequestClose: Function
  organization: Organization
  onSelect: Function
}

const StockTransferVenueSelectModal = ({
  onRequestClose,
  organization,
  onSelect,
}: StockTransferVenueSelectModalProps) => {
  const initialValues = {
    organization: {},
  }

  const getStockTransferConnections = () => {
    const params = {
      size: 20,
    }
    return getStockTransferConnectionsByCriteria(params)
  }

  const onSubmit = async (data) => {
    onRequestClose()
    const orgData = {
      receiverOrg: {
        companyName: data.organization.companyName,
        id: data.organization.id,
      },
      senderOrg: {
        companyName: organization.companyName,
        id: organization.id,
      },
    }
    onSelect(orgData)
  }

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      portalClassName="organizations"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.thumbnail}>
          <FontAwesomeIcon icon={faSyncAlt} size="lg" />
          <span className={styles.thumbnailBadge}>
            <span>
              <FontAwesomeIcon size="xs" icon={faArrowRight} />
            </span>
          </span>
        </div>
        <div className={styles.welcomeContent}>
          <h1 className="text-3xl mb-4 mt-3 text-center">New stock transfer</h1>
          <p>Select the venue you want to transfer stock to</p>
        </div>

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={normalizeNullValues(initialValues)}
          validationSchema={OrganizationsSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, dirty, isSubmitting, values, setValues }) => (
            <Form className={styles.form}>
              <div>
                <AsyncSelect
                  promise={getStockTransferConnections}
                  placeholder="Venue"
                  name="organization"
                  id="organization"
                  label="Venue"
                  isClearable={true}
                  optionLabel={(opt) => opt.connectedOrg.companyName}
                  optionValue="connectedOrg"
                  className="form-control form-control--first form-control--last"
                  blurInputOnSelect={false}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      organization: val ? val.value : "",
                    })
                  }}
                />
              </div>

              <div className={styles.buttons}>
                <button
                  type="button"
                  onClick={() => onRequestClose()}
                  className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
                >
                  Cancel
                </button>
                <button
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  className={"button button--autoWidth"}
                >
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default StockTransferVenueSelectModal
