import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons"

type SwitchPasswordProps = {
  state: boolean
  onChange: Function
}

const SwitchPassword = (props: SwitchPasswordProps) => {
  return (
    <button
      className="absolute right-3 top-3 text-gray-700 z-50"
      type="button"
      onClick={() => props.onChange(!props.state)}
      title={`${props.state ? "Hide" : "Show"} password`}
      tabIndex={-1}
    >
      <FontAwesomeIcon
        icon={props.state ? faEyeSlash : faEye}
        className="mr-2"
      />
    </button>
  )
}

export default SwitchPassword
