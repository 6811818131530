// extracted by mini-css-extract-plugin
export var content = "AddDishForm-module--content--WNdMG";
export var formBottom = "AddDishForm-module--formBottom--8IABD";
export var formLabel = "AddDishForm-module--formLabel--IsHLV";
export var header = "AddDishForm-module--header--wdOf6";
export var inputCol = "AddDishForm-module--inputCol--siI1Q";
export var productMeta = "AddDishForm-module--productMeta--mN3Xw";
export var productName = "AddDishForm-module--productName--CQ9lF";
export var quantityInput = "AddDishForm-module--quantityInput--K9Gbq";
export var quantityInputSecond = "AddDishForm-module--quantityInputSecond--7OVyQ";
export var subValueContainer = "AddDishForm-module--subValueContainer--8Uxlg";
export var subValueLabel = "AddDishForm-module--subValueLabel--ZAczv";
export var summary = "AddDishForm-module--summary--UeeNS";
export var summaryItem = "AddDishForm-module--summaryItem--AvGPo";
export var summaryItemList = "AddDishForm-module--summaryItemList--1UH3u";