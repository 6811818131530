import React from "react"

interface TabProps {
  title: string
  tabKey: string
  children: any
  [key: string]: any
}

const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>
}

export default Tab
