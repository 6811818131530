import ActionsButton from "components/common/ActionsButton/ActionsButton"
import CartButton from "components/orders/CartButton/CartButton"
import { GlobalDispatchContext } from "context/global/GlobalContextProvider"
import React, { useContext } from "react"
import { selectedPackagingMatchesOrderedInConfig } from "services/orders/orders"
import { useMediaQuery } from "react-responsive"

const ActionCell = ({
  rowData,
  permissionObj,
  globalOrder,
  handleActionClick,
  multiSelectActive,
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: "1023" })

  const onCartButtonQuantityChange = (
    newProductQuantity: number | string,
    product,
    globalOrder,
    dispatch
  ) => {
    const productInContext = globalOrder.products.find(
      (a) => a.id === product.id
    )
    if (
      productInContext &&
      (newProductQuantity === 0 || newProductQuantity === "0")
    ) {
      dispatch({
        type: "REMOVE_FROM_GLOBAL_ORDER",
        payload: {
          productId: product.id,
        },
      })
    } else if (
      !productInContext &&
      (newProductQuantity === 1 || newProductQuantity === "1")
    ) {
      // eslint-disable-next-line no-debugger
      // debugger
      dispatch({
        type: "ADD_TO_GLOBAL_ORDER",
        payload: {
          product: product,
          _basketQuantity: 1,
          _selectedCase: product._selectedCase,
        },
      })
    } else if (
      productInContext &&
      // newProductQuantity > 0 &&
      productInContext._basketQuantity !== newProductQuantity
    ) {
      dispatch({
        type: "UPDATE_GLOBAL_ORDER_PRODUCT",
        payload: {
          product: productInContext,
          _basketQuantity: newProductQuantity,
        },
      })
    }
  }

  const actions = [
    {
      key: "item.edit",
      title: "Edit",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "item.duplicate",
      title: "Duplicate item",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "item.unmarkAsSame",
      title: "Unmark as same item",
      type: "default",
      disabled: !permissionObj?.permissions.modify || rowData.preferred,
    },
    {
      key: "item.generateRecipeAi",
      title: "Generate AI Recipe",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "item.delete",
      title: "Delete",
      type: "danger",
      disabled: !permissionObj?.permissions.delete,
    },
  ].filter((a) => Boolean(!a.disabled)) //Filtering out all disabled options out of the OPTIONS list, They will not be visible in menu

  const selectedQuantity = React.useMemo(
    () =>
      globalOrder.products.find((a) => a.id === rowData.id)?._basketQuantity,
    [globalOrder.products]
  )

  const shouldDisableAddToCart =
    !selectedPackagingMatchesOrderedInConfig(rowData)

  return (
    <div className="flex space-x-2 items-center">
      {rowData.supplierId === "no-supplier-id" ? null : (
        <CartButton
          initialValue={isTabletOrMobile ? "" : Math.abs(1)}
          allowFloat
          quantity={selectedQuantity || 0}
          onChange={(newQuantity) => {
            onCartButtonQuantityChange(
              newQuantity,
              rowData,
              globalOrder,
              dispatch
            )
          }}
          disabled={shouldDisableAddToCart}
          tooltip={
            shouldDisableAddToCart
              ? "You're not allowed to order the product in this package type"
              : undefined
          }
        />
      )}
      <div className={multiSelectActive ? "invisible" : "visible"}>
        <ActionsButton
          options={actions}
          disabled={multiSelectActive}
          onActionClick={(action) => handleActionClick(action, rowData)}
        />
      </div>
    </div>
  )
}

export default React.memo(ActionCell)
