import { isBrowser } from "services/helpers"
import { createPortal } from "react-dom"
import { memo, useEffect, useRef, useState } from "react"

const Portal = ({ id, children }) => {
  const el = useRef(
    isBrowser()
      ? document.getElementById(id) || document.createElement("div")
      : null
  )
  const [dynamic] = useState(
    el && el.current ? !el.current.parentElement : false
  )

  useEffect(() => {
    if (!isBrowser) return
    if (dynamic && el.current) {
      el.current.id = id
      document.body.appendChild(el.current)
    }
    return () => {
      if (dynamic && el.current?.parentElement) {
        el.current.parentElement.removeChild(el.current)
      }
    }
  }, [id])

  return createPortal(children, el.current)
}

export default memo(Portal)
