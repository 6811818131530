import React from "react"
import FilterSelect from "../FilterSelect"
import SearchInput from "../SearchInput"
import { useMediaQuery } from "react-responsive"

//@ts-ignore
import * as styles from "./SearchByParamInput.module.css"

type SearchByParamInputProp = {
  currentParam: any
  setCurrentParam: Function
  setSearchValue: Function
  paramOptions: any
  className?: string
  entity?: string
}

const SearchByParamInput = ({
  currentParam,
  setCurrentParam,
  setSearchValue,
  paramOptions,
  className = "",
  entity = "Product",
}: SearchByParamInputProp) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  return (
    <div className={`flex items-center flex-grow relative my-2 ${className}`}>
      <SearchInput
        label="Search by name"
        placeholder={
          isTabletOrMobile
            ? `Search by ${currentParam.label}`
            : `Search by ${entity} ${currentParam.label}`.replace("  ", " ")
        }
        className={`form-control rounded mr-4 md:mr-6 ${styles.searchInput}`}
        onSearchChange={(val) => {
          setSearchValue(val)
        }}
      />
      <FilterSelect
        options={paramOptions}
        value={currentParam && currentParam.value}
        onChange={(val) => {
          setCurrentParam(val)
        }}
        stylesOverride={{
          container: (provided) => ({
            ...provided,
            position: "static",
          }),
          control: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            borderLeft: "none",
            borderRadius: "0 0.25rem 0.25rem 0",
            width: "fit-content",
            borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
            boxShadow: "none",
            zIndex: state.isFocused ? 2 : null,
            minHeight: 45,
            ":hover": {
              ...provided[":hover"],
              borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: "0px",
            width: "0px",
          }),
          menu: (provided) => ({
            ...provided,
            left: "0px",
          }),
        }}
      />
    </div>
  )
}

export default SearchByParamInput
