import React, { useState, useContext } from "react"
import { usePrevious, useUpdateEffect } from "react-use"
import { getStockTakes } from "services/stock-take/stock-take"
import { stockTakeStates } from "services/constants"
import { uuidv4, convertTimeStampFormat } from "services/helpers"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FilterSelect from "components/forms/FilterSelect"
import SearchInput from "components/forms/SearchInput"
import StockTakeItem from "components/stock/StockTakeItem/StockTakeItem"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import StockTakeModal from "components/stock/StockTake/StockTake"
import StockTakesInProgressModal from "components/stock/StockTakesInProgressModal/StockTakesInProgressModal"
import { ModalContext } from "context/modal/ModalContext"
import usePermissions from "hooks/usePermissions"
import BaseList from "components/baseList/BaseList"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import { getReportsDummy } from "services/dummy"
import usePagination from "hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import FiltersButton from "components/common/FiltersButton/FiltersButton"
import { Permission } from "services/types"

import * as styles from "./StockTakes.module.css"

const StockTakes = () => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const {
    organization,
    selectedOrganizations,
    organizationPermissions,
  }: {
    organization: any
    selectedOrganizations: any
    organizationPermissions: any
  } = useContext(GlobalStateContext)

  const [loading, setLoading] = useState(false)
  const [stockTakesData, setStockTakesData] = useState({
    content: [],
  })
  const [status, setStatus] = useState<{ value: string; label: string } | null>(
    null
  )
  const previousStatus = usePrevious(status)
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)
  const permissionObj = usePermissions("Stocktakes") as Permission

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setStockTakesData({ content: [] })
      return
    }

    setLoading(true)

    const params: any = {
      page: pagination.page,
      sort: "createdAt,desc&sort=completedAt,desc",
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    if (status) {
      params.status = status.value

      if (previousStatus && previousStatus.value !== status.value) {
        params.page = 0
      }
    }

    if (q) {
      params.partialName = q

      if (previousQ !== q) {
        params.page = 0
      }
    }

    const result = await getStockTakes(params)

    if (result && !result.error) {
      setStockTakesData({ ...stockTakesData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useUpdateEffect(() => {
    getData()
  }, [selectedOrganizations])

  useUpdateEffect(() => {
    if (previousStatus || status) {
      getData()
    }
  }, [status])

  useUpdateEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  useUpdateEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <FiltersButton>
            <FilterSelect
              options={stockTakeStates.filter((s) => s.label !== "Completed")}
              value={status}
              onChange={(val) => {
                setStatus(val)
              }}
              placeholder="Status: all"
              isClearable={true}
              className="w-56"
            />

            <LocationsSelector />
          </FiltersButton>

          <div className="my-2 flex-grow order-last sm:order-none w-full sm:w-max">
            <SearchInput
              label="Search by name"
              placeholder="Search by name"
              className="form-control rounded"
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
          </div>

          <button
            className="button my-2 ml-auto button--autoWidth button--primaryGreen"
            disabled={!permissionObj?.permissions.modify}
            onClick={() => {
              modal.showModal(StockTakesInProgressModal, {
                onCreate: (e) => {
                  e.preventDefault()
                  dispatch({ type: "RESET_NEW_STOCKTAKE" })
                  dispatch({
                    type: "CREATE_NEW_STOCKTAKE",
                    payload: {
                      stockTakeData: {
                        name: `Stocktake ${convertTimeStampFormat(
                          new Date(),
                          organization?.address.zoneId
                        )}`,
                        status: "IN_PROGRESS",
                        key: uuidv4(),
                        unsynced: true,
                        subStockTakeReports: [],
                        products: [],
                        recipes: [],
                      },
                    },
                  })
                  dispatch({
                    type: "CREATE_NEW_STOCKTAKE_SUBREPORT",
                    payload: {
                      stockTakeData: {
                        name: `Main bar`,
                        status: "IN_PROGRESS",
                        key: uuidv4(),
                        unsynced: true,
                        products: [],
                        recipes: [],
                      },
                    },
                  })
                  modal.showModal(StockTakeModal, { onUpdate: () => getData() })
                },
              })
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
            <span className="hidden lg:inline">New stocktake</span>
          </button>
        </div>

        <div className={styles.content}>
          <BaseList
            title="stocktakes"
            id="stocktakes"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faSyncAlt,
              color: "green",
              getDummy: () => getReportsDummy,
            }}
            data={stockTakesData.content}
            rowRender={(stocktake) => (
              <StockTakeItem
                key={stocktake.stockTakeReport.id}
                stocktake={stocktake}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default StockTakes
