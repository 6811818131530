import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

import * as styles from "./ProgressBar.module.css"

const ProgressBar = ({ progress, isBlue }) => {
  return (
    <div className={styles.progressBar}>
      <div
        className={cx("actualProgress", { isBlue })}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  isBlue: PropTypes.bool,
}

export default ProgressBar
