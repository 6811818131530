import Foldable from "components/common/Foldable/Foldable"
import Loader from "components/common/Loader/Loader"
import AddLocations from "components/organization/AddLocations/AddLocations"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import React, { useContext, useEffect, useState } from "react"
import { objHasValue } from "services/helpers"
import {
  createSubOrganization,
  getMyOrganizationConnections,
} from "services/organization"
import { Organization } from "services/types"
import { getCombinedUserInformation } from "services/user"
import OrganizationLocationInfo from "../OrganizationLocationInfo/OrganizationLocationInfo"

const OrganizationLocations = () => {
  const { organization } = useContext(GlobalStateContext)
  //State
  const [locations, setLocations] = useState<Organization[]>([])
  const [newLocations, setNewLocations] = useState<Organization[]>([])
  const [saveEnabled, setSaveEnabled] = useState<Boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const dispatch = useContext(GlobalDispatchContext)

  //For future reference
  const formatLocationsToMatchYupStructure = (data) => {
    const sortedOrgs = data.sort((a, b) => {
      return a.companyName.toLowerCase() < b.companyName.toLowerCase() ? -1 : 1
    })
    return sortedOrgs.map((a) => {
      return {
        ...a,
        address: { ...a.address, companyName: a.companyName },
      }
    })
  }

  const getOrgLocations = () => {
    return getMyOrganizationConnections().then(async (res) => {
      // refresh userInfo to make sure all new locations are loaded (also in venue switch)
      const userInfo = await getCombinedUserInformation()

      dispatch({ type: "SET_COMBINED_USER_INFORMATION", payload: userInfo })
      setLocations(formatLocationsToMatchYupStructure(res.content))
      setNewLocations([])
    })
  }

  useEffect(() => {
    //Fetch locations from Connections GET on mount
    getOrgLocations()
  }, [])

  const saveLocations = async () => {
    setSaving(true)

    try {
      for (const location of newLocations) {
        const formattedParams = {
          address: location.address,
          deliveryAddress: objHasValue(location.deliveryAddress)
            ? location.deliveryAddress
            : null,
          companyName: location.address.companyName,
        }
        await createSubOrganization(formattedParams)
      }
      await getOrgLocations()
    } catch (e) {
      console.log(e)
    }
    setSaving(false)
  }

  return (
    <div className="relative">
      <Loader isLoading={saving}>Saving locations..</Loader>
      {locations.map((location) => {
        return (
          <Foldable headerText={location.address.companyName} key={location.id}>
            <div className="p-4 bg-paleBlue flex flex-col py-4 space-y-3">
              <OrganizationLocationInfo location={location} />
            </div>
          </Foldable>
        )
      })}
      {!saving && (
        <AddLocations
          locationsCount={locations.length}
          initialLocations={newLocations}
          showFirstLocation={false}
          zoneId={organization?.address.zoneId}
          country={organization?.address.country}
          onLocationsChange={(data, valid) => {
            setNewLocations(data)
            setSaveEnabled(valid)
          }}
        />
      )}
      <div className="mt-4">
        <span className="mb-2 text-gray-600 block">
          *fees apply to each location / area
        </span>
        <button
          onClick={() => saveLocations()}
          className="button button--primaryGreen button--autoWidth"
          disabled={!saveEnabled || !newLocations.length}
        >
          Save locations
        </button>
      </div>
    </div>
  )
}

export default OrganizationLocations
