const onboardingReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ONBOARDING_STEPS": {
      const { onboardingSteps } = action.payload

      return {
        ...state,
        onboardingSteps,
      }
    }
    default: {
      return state
    }
  }
}
export default onboardingReducer
