// import { Link } from "gatsby"
import React from "react"
import * as styles from "./OptionsPopup.module.css"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

type OptionsPopupProps = {
  active: boolean
  title?: string | React.ReactNode
  children: any
  activeCallback: Function
  fullHeight?: boolean
  renderInactive?: boolean
}

const OptionsPopup = ({
  children,
  active,
  activeCallback,
  title = "",
  fullHeight = false,
  renderInactive = false,
}: OptionsPopupProps) => {
  return (
    <div className={cx("container", { display: active })}>
      <button className={styles.bg} onClick={() => activeCallback()} />
      <div
        className={cx(styles.content, {
          [styles.contentFullHeight]: fullHeight,
        })}
      >
        {title && <div className={styles.header}>{title}</div>}
        {(renderInactive || active) && <>{children}</>}
      </div>
    </div>
  )
}

export default OptionsPopup
