{/* prettier-ignore */}
import React, { Fragment } from "react"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons"

type OrganizationPlanComparisonV2Props = {
  proAction: Function
  premiumAction: Function
}

// prettier-ignore
function OrganizationPlanComparisonV2(props: OrganizationPlanComparisonV2Props) {
    
const lines = [
    {title:'Control & site management',type:'heading',info:'',plans:['V','V']},
    {title:'Granular user access (user permissions)',type:'line',info:'Customise individual user roles and permissions for tailored access control within your team.',plans:['V','V']},
    {title:'Centralised admin account to manage all sites/ areas',type:'line',info:'Manage multiple sites or areas seamlessly from a single, centralised admin account.',plans:['X','V']},
    {title:'Mind the Gap Q&A sessions with experts',type:'line',info:'Benefit from expert guidance during our \'Mind the Gap\' Q&amp;A sessions to optimise your operations.',plans:['X','V']},

    {title:'Reporting',type:'heading',info:'',plans:['V','V']},
    {title:'GP Analysis',type:'line',info:'Gain insights into your gross profit margins to help optimise pricing and reduce waste.',plans:['V','V']},
    {title:'COGs',type:'line',info:'Track the cost of goods sold for a clear view of your inventory spending and profit margins.',plans:['V','V']},
    {title:'Stock discrepancy report',type:'line',info:'Identify and rectify stock variances quickly to maintain accurate inventory levels.',plans:['V','V']},
    {title:'Price changes report',type:'line',info:'Stay informed of any changes in supplier pricing to manage costs effectively.',plans:['V','V']},
    {title:'Multi-site dashboard',type:'line',info:'Monitor and manage the operational aspects of each site with a comprehensive dashboard overview, enhancing your control over daily activities.',plans:['X','V']},

    {title:'Inventory management',type:'heading',info:'',plans:['V','V']},
    {title:'Stocktakes with barcode scanning',type:'line',info:'Streamline your inventory counts with efficient barcode scanning for accurate and fast stocktaking.',plans:['V','V']},
    {title:'Stock on hand (with POS integration)',type:'line',info:'Keep real-time track of inventory levels with POS integration to maintain optimal stock on hand.',plans:['V','V']},
    {title:'Waste recording & tracking',type:'line',info:'Easily record and track waste to identify trends and implement measures to reduce loss.',plans:['V','V']},
    {title:'Par levels',type:'line',info:'Set and manage par levels to ensure you always have the right amount of stock and avoid overordering.',plans:['V','V']},
    {title:'Stock transfers',type:'line',info:'Facilitate the transfer of stock between locations smoothly, ensuring balanced inventory across your business.',plans:['X','V']},

    {title:'Supplier ordering',type:'heading',info:'',plans:['V','V']},
    {title:'Orders from suppliers',type:'line',info:'Effortlessly create and manage orders with suppliers directly through our platform.',plans:['V','V']},
    {title:'PO approval',type:'line',info:'Streamline your procurement process with straightforward purchase order approvals.',plans:['V','V']},
    {title:'Order reminders',type:'line',info:'Stay informed with automated reminders that ensure you\'re always aligned with your supplier ordering schedule.',plans:['V','V']},
    {title:'Delivery notes',type:'line',info:'Easily capture delivery notes on mobile when receiving orders to quickly spot and record any discrepancies, keeping your inventory tracking accurate and up-to-date.',plans:['V','V']},
    {title:'Minimum order value',type:'line',info:'Set a minimum order value to ensure order efficiency and cost management.',plans:['V','V']},
    {title:'Price changes alerts',type:'line',info:'Receive instant alerts for any price changes from your suppliers, helping you stay cost-aware.',plans:['V','V']},

    {title:'Recipe management',type:'header',info:'',plans:['V','V']},
    {title:'Dishes & recipes costing',type:'line',info:'Calculate your dishes and recipes costs accurately for better pricing decisions.',plans:['V','V']},
    {title:'Margin calculator (suggested sales price & target margin)',type:'line',info:'Determine optimal sales prices and target profit margins.',plans:['V','V']},
    {title:'Real-time price changes alerts',type:'line',info:'Stay ahead with alerts on ingredient price changes to maintain cost-effective recipes.',plans:['V','V']},
    {title:'Printable dishes & recipes cards',type:'line',info:'Generate printable cards for your dishes and recipes for convenient kitchen use.',plans:['V','V']},
    {title:'Allergens',type:'line',info:'Track and display allergen information to ensure customer safety and comply with regulations.',plans:['V','V']},

    {title:'Invoice reconciliation/ management',type:'heading',info:'',plans:['V','V']},
    {title:'Discrepancy alerts',type:'line',info:'Receive immediate notifications for any mismatches between delivered products and invoices to keep your accounts in check.',plans:['V','V']},
    {title:'Automated invoice validation',type:'line',info:'Simplify your bookkeeping with our automated system that validates invoices against orders and deliveries.',plans:['30 invoice','Unlimited']},    

    {title:'Integrations',type:'heading',info:'',plans:['V','V']},
    {title:'POS integration',type:'line',info:'Seamlessly connect with your Point of Sale system for real-time sales tracking and inventory management.',plans:['V','V']},
    {title:'Accounting system integration',type:'line',info:'Effortlessly synchronise with your accounting software for accurate financial records and streamlined operations.',plans:['V','V']},
    {title:<a className="underline text-primaryPink" href="https://www.growyze.com/integrations" target="_blank" rel="noreferrer">See list of integrations here</a>,type:'line',info:'',plans:['','']},

    {title:'Support, Onboarding & Training',type:'heading',info:'',plans:['V','V']},
    {title:'Chat & email support',type:'line',info:'Reach out to our team anytime via chat and email for quick, reliable assistance.',plans:['V','V']},
    {title:'Dedicated Acount Manager',type:'line',info:'Enjoy personalised service and support from a DAM who understands your business.',plans:['X','V']},
    {title:'Onboarding',type:'line',info:'Get expert guidance to set up and optimise growyze with our comprehensive onboarding.',plans:['Paid add on','With annual subscription']},
    {title:'Site visit (available for UK businesses)',type:'line',info:'Benefit from a tailored on-site visit for in-depth training and setup support, exclusive to UK businesses.',plans:['Paid add on','50% OFF with annual subscription']},
    
    {title:'',type:'line',info:'',plans:['Get Started','Book a Demo']},
    
]
    const renderOption = (line, option)=>{
        if (line.type == "heading"){
            return ''
        }
        if (option == "V"){
          return <FontAwesomeIcon icon={faCheckCircle} className="text-primaryGreen" />
        }else if (option == "X"){
          return <FontAwesomeIcon icon={faTimes} className="text-primaryPink" />
        }else if (option == "Book a Demo"){
            return <button className={"self-end mt-8 button button--primaryPink"} onClick={() => props.proAction()}>{option}</button>
        }else if (option == "Get Started"){
            return <button className={"self-end mt-8 button button--primaryPink"} onClick={() => props.premiumAction()}>{option}</button>
        }else{
          return option
        }
      }
        
      return (
        <div className={"flex flex-col flex-wrap mt-10 container mx-auto mb-20"}>
            <h2 className="text-4xl my-4 text-primaryBlue text-center w-full">Plans comparison</h2>        
            <div className="flex flex-col lg:flex-row space-around pt-10 w-full overflow-x-scroll lg:overflow-visible">
              <div className="grid grid-cols-4 gap-x-4 mx-auto" style={{'gridTemplateColumns':'2fr 1fr 1fr','gridTemplateRows':'repeat(1, 1fr)'}}> 
                <div key='a1'></div>                
                <div key='a3' className="border border-b-0 rounded-t-lg text-center pt-3 pb-6"><h2 className="text-2xl">Pro</h2></div>
                <div key='a4' className="border border-b-0 rounded-t-lg text-center pt-3 pb-6 bg-primaryGreen bg-opacity-5"><h2 className="text-2xl">Premium</h2></div>
                {lines.map((line,i)=>{
                  return (
                    <Fragment key={i}>
                      <div key={"b"+i} className="py-3 flex" style={{minWidth:'300px'}}>
                        <div>
                        {line.type == 'heading' ? (<h2 className="mt-8">{line.title}</h2>): line.title}       
                        </div>
                        <div>{line.info && (
                            <div>
                                <span data-for={"info_tooltip" + line.title} data-tip={line.info} className="cursor-help">
                                    <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    className={"ml-2 top-4 opacity-40 z-10"}
                                    />
                                </span>
                            </div>
                        )}</div>
                        <ReactTooltip
                            type="light"
                            id={"info_tooltip" + line.title}
                            getContent={(dataTip) => `${dataTip}`}
                            effect="solid"
                        />
                      </div>
                      {line.plans.map((option,j)=>{
                        return <div key={"c"+j} className={'border border-b-0 border-t-0 text-center flex items-center justify-center py-3 px-10 ' + (j == 1 ? ' bg-primaryGreen bg-opacity-5': '')}>
                          {renderOption(line, option)}
                        </div>
                      })}
                    </Fragment>
                  )
                })}
                <div key='d1'></div>
                <div key='d2' className="border border-t-0 rounded-b-lg text-center pt-3"></div>
                <div key='d3' className="border border-t-0 rounded-b-lg text-center pt-3 bg-primaryGreen bg-opacity-5"></div>
                
              </div>
            </div>
        </div>   
      )
    }

export default OrganizationPlanComparisonV2
