import React, { useEffect, useState } from "react"
import Loader from "components/common/Loader/Loader"
import { getProductHistory } from "services/products/products"
import { showError } from "services/toast"
import ProductHistoryItem from "../ProductHistoryItem/ProductHistoryItem"

interface ProductHistoryProps {
  id: string
}

export interface ProductHistoryData {
  id: string
  changes: string[]
  changedAt: string
  organizations: string[]
  productId: string
  user: {
    id: string
    firstName: string
    lastName: string
    username: string
  }
}

const ProductHistory: React.FunctionComponent<ProductHistoryProps> = ({
  id,
}) => {
  const [productHistory, setProductHistory] = useState<
    ProductHistoryData[] | null
  >(null)
  const [loading, setLoading] = useState(false)

  const getHistory = async () => {
    setLoading(true)

    const params = {
      size: 999,
    }
    const res = await getProductHistory(id, params)

    if (res.message) {
      showError(res.message)
    } else {
      setProductHistory(res.content.reverse())
    }

    setLoading(false)
  }

  useEffect(() => {
    getHistory()
  }, [id])

  return (
    <div className="w-full overflow-auto">
      {loading && <Loader isLoading={loading} />}

      {!loading &&
        (productHistory && productHistory.length > 0 ? (
          productHistory.map((data: ProductHistoryData, i: number) => (
            <ProductHistoryItem key={i} data={data} />
          ))
        ) : (
          <span className="px-4">No changes made to this product yet.</span>
        ))}
    </div>
  )
}

export default ProductHistory
