import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

type Props = {
  expandable?: boolean
  expanded: boolean
  onExpand: Function
}

const ExpandIcon = (props: Props) => {
  const { expandable = null, expanded, onExpand } = props

  if (!expandable) {
    return null
  }

  return (
    <FontAwesomeIcon
      className="cursor-pointer text-primaryBlueLighter mr-3"
      size="sm"
      icon={expanded ? faChevronUp : faChevronDown}
      onClick={() => {
        onExpand(!expanded)
      }}
    />
  )
}

export default ExpandIcon
