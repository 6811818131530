/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useMemo } from "react"

import { useMediaQuery } from "react-responsive"
import BaseTable, { AutoResizer } from "react-base-table"
import classNames from "classnames/bind"
import {
  productCategories,
  productBeverageCategories,
  productFoodCategories,
} from "services/constants"
import { uuidv4, roundNumber } from "services/helpers"
import { ModalContext } from "context/modal/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faMinusCircle,
  faSync,
  faArrowLeft,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons"
import { faList } from "@fortawesome/pro-duotone-svg-icons"

import SearchInput from "components/forms/SearchInput"
import Note from "components/stock/Note/Note"
import StockTakeInput from "components/stock/StockTakeInput/StockTakeInput"
import EditStockModal from "../EditStockModal/EditStockModal"
import StockTakeSheetSelect from "components/stock/StockTakeSheetSelect/StockTakeSheetSelect"
import {
  getStockTakeSheet,
  refreshStockTakeSheet,
} from "services/stock-take/stock-take"

import * as styles from "./StockTakeContent.module.css"
import usePermissions from "hooks/usePermissions"
import {
  faBarcodeRead,
  faCube,
  faWineBottle,
} from "@fortawesome/pro-solid-svg-icons"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"
import SearchByParamInput from "components/forms/SearchByParamInput/SearchByParamInput"
import { Permission } from "services/types"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"

const cx = classNames.bind(styles)

const stockTakeQueryParameters = [
  {
    label: "Product barcode",
    value: "partialBarcode",
  },
  {
    label: "Product name",
    value: "partialProductName",
  },
]

const StockTakeContent = ({
  stocktake,
  onUpdate,
  onClose,
  onSearch,
  isLoading,
  startSyncing,
  stockTakeSheet,
  setStockTakeSheet,
  query,
  openStockSheetSelect = false,
  onCloseStockSheetSelect,
}) => {
  const [tab, setTab] = useState("items")
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("Stocktakes") as Permission
  const dispatch = useContext(GlobalDispatchContext)
  const { mobileKeyboardOpen } = useContext(GlobalStateContext)
  const isParentReport = stocktake && stocktake.subStockTakeReports
  const isCompleted = stocktake && stocktake.status === "COMPLETED"
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [selectSheet, setSelectSheet] = useState(false)
  const [queryParameter, setQueryParameter] = useState(
    stockTakeQueryParameters[1]
  )

  useEffect(() => {
    setSelectSheet(openStockSheetSelect)
  }, [openStockSheetSelect])

  const onAddProduct = async (product, quantity) => {
    // @TODO: check if selected stocktake

    const newProduct = {
      ...product,
      id: uuidv4(),
      quantity: quantity,
      unsynced: { add: true },
    }
    await dispatch({
      type: isParentReport
        ? "ADD_NEW_STOCKTAKE_PRODUCT"
        : "ADD_NEW_STOCKTAKE_SUBREPORT_PRODUCT",
      payload: {
        product: newProduct,
        subStockTakeKey: stocktake.key,
      },
    })
    // Make sure we end up on the right tab (e.g. when you add a product from scanner while being on the recipes tab)
    setTab("items")
    startSyncing()
  }

  const onAddRecipe = async (recipe, quantity) => {
    // @TODO: check if selected stocktake
    const newRecipe = {
      ...recipe,
      id: uuidv4(),
      recipe: { name: recipe.name, id: recipe.id },
      totalQty: quantity,
      unsynced: { add: true },
    }
    await dispatch({
      type: isParentReport
        ? "ADD_NEW_STOCKTAKE_RECIPE"
        : "ADD_NEW_STOCKTAKE_SUBREPORT_RECIPE",
      payload: {
        recipe: newRecipe,
        subStockTakeKey: stocktake.key,
      },
    })
    setTab("recipes")
    startSyncing()
  }

  const onNoteChange = async (newNote) => {
    if (!permissionObj?.permissions.modify) {
      return
    }
    const isParentReport = stocktake.subStockTakeReports

    dispatch({
      type: isParentReport
        ? "UPDATE_NEW_STOCKTAKE"
        : "UPDATE_NEW_STOCKTAKE_SUBREPORT",
      payload: {
        stockTakeData: {
          note: newNote,
          unsynced: true,
        },
        subStockTakeKey: stocktake.key,
      },
    })
    startSyncing()
  }

  const onEditProduct = (product) => {
    modal.showModal(EditStockModal, {
      product,
      onEdit: (product, newQty) => {
        if (product.packaging === "single" || product.packaging == null) {
          return changeProductQty(product, newQty, stocktake)
          // Old logic: If the user selected multiple packaging, just multiply the
          // amount of items with the case size in order to get the quantity
          // in single items
        } else if (product.packaging === "multiple") {
          changeProductQty(product, newQty, stocktake)
        } else {
          throw new TypeError(`Invalid packaging - ${product.packaging}`)
        }
      },
    })
  }

  const onEditRecipe = (recipe) => {
    modal.showModal(EditStockModal, {
      recipe,
      onEdit: (recipe, newQty) => {
        return changeRecipeQty(recipe, newQty, stocktake)
      },
    })
  }

  const onScanAction = (type, manually = false) => {
    modal.showModal(StockTakeInput, {
      onSave: onAddProduct,
      onSaveRecipe: onAddRecipe,
      // disable this call as it was causing duplicated sync product requests
      // onClose: () => onUpdate(stocktake.id, !isParentReport),
      type: type,
      manually: manually,
    })
  }

  const totalProductsValue = () => {
    return stocktake.products.reduce(
      (a, v) => (a = v.totalAmount ? a + v.totalAmount : a + 0),
      0
    )
  }

  const totalRecipesValue = () => {
    return stocktake.recipes.reduce(
      (a, v) => (a = v.totalCost ? a + v.totalCost : a + 0),
      0
    )
  }

  const filteredProducts = (products) => {
    if (query) {
      return products.filter((element) =>
        queryParameter.value == "partialProductName"
          ? element.description.toUpperCase().includes(query.toUpperCase())
          : element.barcode.includes(query)
      )
    }

    return products
  }

  const filteredRecipes = (recipes) => {
    if (query) {
      return recipes.filter((element) =>
        element.recipe.name.toUpperCase().includes(query.toUpperCase())
      )
    }

    return recipes
  }

  const getStockTakeSheetData = async (sheetId) => {
    await refreshStockTakeSheet(sheetId)
    const result = await getStockTakeSheet(sheetId)

    return result
  }

  const onAddStocksheetProducts = async (sheet) => {
    const products = sheet.products
      ? sheet.products.map((product) => ({
          ...product,
          description: product.name,
          id: uuidv4(),
          quantity: 0,
          unsynced: { add: true },
        }))
      : []

    const recipes = sheet.recipes
      ? sheet.recipes.map((recipe) => ({
          recipe: { id: recipe.recipeId },
          id: uuidv4(),
          totalQty: 0,
          unsynced: { add: true },
        }))
      : []

    await dispatch({
      type: isParentReport
        ? "ADD_NEW_STOCKTAKE_PRODUCTS_FROM_SHEET"
        : "ADD_NEW_STOCKTAKE_SUBREPORT_PRODUCTS_FROM_SHEET",
      payload: {
        stockTakeData: {
          unsynced: true,
          stockTakeTemplateId: sheet.id,
        },
        products,
        recipes,
        subStockTakeKey: stocktake.key,
      },
    })
    startSyncing()
  }

  const onStocksheetSelect = async (data) => {
    const stockTakeSheetData = await getStockTakeSheetData(data.id)
    stockTakeSheetData.products = stockTakeSheetData.products.filter(
      (product) => !product.isDeleted
    )
    setStockTakeSheet(stockTakeSheetData)
    await onAddStocksheetProducts(stockTakeSheetData)
    onUpdate(stocktake.id, !isParentReport)
  }

  const closeStockSheetSelect = () => {
    setSelectSheet(false)
    onCloseStockSheetSelect()
  }

  const decreaseFullQuantity = (prevQty) => {
    return prevQty > 0 ? Number(prevQty) - 1 : 0
  }

  const increaseFullQuantity = (prevQty) => {
    return Number(prevQty ?? 0) + 1
  }

  const changeProductQty = (product, qty, stocktake) => {
    if (product.quantity == 0 && qty == 0) {
      return
    }

    const subStockTakeId = stocktake?.subStockTakeReports || stocktake.id

    dispatch({
      type: "UPDATE_NEW_STOCKTAKE_PRODUCT",
      payload: {
        product: {
          ...product,
          quantity: qty,
          totalAmount: product.price * qty,
          unsynced: { ...product.unsynced, update: true },
        },
        subStockTakeId: subStockTakeId,
      },
    })
    startSyncing()
  }

  const changeRecipeQty = async (recipe, qty, stocktake) => {
    if (recipe.totalQty == 0 && qty == 0) {
      return
    }

    const subStockTakeId = stocktake?.subStockTakeReports || stocktake.id
    dispatch({
      type: "UPDATE_NEW_STOCKTAKE_RECIPE",
      payload: {
        recipe: {
          ...recipe,
          totalQty: qty,
          unsynced: { ...recipe.unsynced, update: true },
        },
        subStockTakeId: subStockTakeId,
      },
    })
    startSyncing()
  }

  const removeProduct = async (product, stocktake) => {
    const subStockTakeId = stocktake?.subStockTakeReports || stocktake.id
    dispatch({
      type: "REMOVE_NEW_STOCKTAKE_PRODUCT",
      payload: {
        productId: product.id,
        subStockTakeId,
      },
    })
    startSyncing()
  }

  const removeRecipe = async (recipe, stocktake) => {
    const subStockTakeId = stocktake?.subStockTakeReports || stocktake.id
    dispatch({
      type: "REMOVE_NEW_STOCKTAKE_RECIPE",
      payload: {
        recipeId: recipe.id,
        subStockTakeId,
      },
    })
    startSyncing()
  }

  const isUnsynced = (product) => {
    return (
      product?.unsynced?.add ||
      product?.unsynced?.update ||
      product?.unsynced?.remove
    )
  }

  const isUnsyncedAdd = (product) => {
    return product?.unsynced?.add
  }

  const tableColumns = useMemo(() => {
    const items = tab === "items"
    const editHandler = items ? onEditProduct : onEditRecipe
    const changeHandler = items ? changeProductQty : changeRecipeQty
    const cats = productBeverageCategories.concat(productFoodCategories)
    const hasSheet = !!stockTakeSheet

    let columns = [
      {
        key: "description",
        title: "Item",
        dataKey: "description",
        width: 200,
        flexGrow: 1,
        flexShrink: 0,
        cellRenderer: ({ rowData }) => {
          const matchedCat = items
            ? productCategories.find((c) => c.value === rowData.category)
            : null
          const matchedSubCat = items
            ? cats.find((c) => c.value === rowData.subCategory)
            : null
          const name = items ? rowData.description : rowData.recipe.name

          return (
            <div className="flex py-3 items-start">
              {items && (
                <FontAwesomeIcon
                  icon={rowData.countedInCase ? faCube : faWineBottle}
                  className="text-primaryBlue self-start mt-1 mr-2 text-sm"
                />
              )}
              <div className="flex flex-col text-left">
                <div className="flex items-center">
                  <button
                    onClick={() => editHandler(rowData)}
                    className="font-bold text-sm font-sansBold text-primaryBlue text-left"
                    disabled={rowData.isDeleted}
                  >
                    {name}
                  </button>

                  {rowData.isDeleted && (
                    <>
                      <span
                        data-for="deleted_product_tooltip"
                        data-tip="This product is deleted."
                      >
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          size="lg"
                          className="text-error ml-2"
                        />
                      </span>

                      <ReactTooltip
                        id={"deleted_product_tooltip"}
                        type="light"
                        place="right"
                        effect="float"
                        border={true}
                        borderColor="#e2e8f0"
                      />
                    </>
                  )}
                </div>

                <span className="text-xs font-light text-gray-700">
                  {rowData.barcode}
                </span>
                {items && (
                  <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
                    {rowData.countedInCase && rowData.caseSize
                      ? `${rowData.caseSize} x `
                      : ""}
                    {rowData.size} {rowData.measure}
                  </span>
                )}
                {items && (rowData.category || rowData.subCategory) && (
                  <span className="text-xs text-gray-600 hidden md:inline-block">{`${
                    rowData.category
                      ? matchedCat
                        ? matchedCat.label
                        : rowData.category
                      : ""
                  } - ${
                    rowData.subCategory
                      ? matchedSubCat
                        ? matchedSubCat.label
                        : rowData.subCategory
                      : ""
                  }`}</span>
                )}
              </div>
            </div>
          )
        },
      },
      {
        key: "quantity",
        title: "Qty",
        dataKey: "quantity",
        width: 75,
        flexGrow: 1,
        flexShrink: 0,
        cellRenderer: ({ rowData }) => {
          const isSheetProduct =
            hasSheet &&
            (rowData.recipe
              ? stockTakeSheet.recipes
                  ?.map((p) => p.recipeId)
                  .includes(rowData.recipe.id)
              : stockTakeSheet.products
                  ?.map((p) => p.barcode)
                  .includes(rowData.barcode))

          const qty = items ? rowData.quantity : rowData.totalQty

          return isCompleted && !isParentReport ? (
            <span className="font-sansSemiBold font-semibold text-left text-gray-700">
              {roundNumber(qty)}
            </span>
          ) : (
            <div
              className={
                styles.quantityCell +
                (isUnsyncedAdd(rowData)
                  ? " opacity-40 pointer-events-none"
                  : "")
              }
            >
              {isSheetProduct && (
                <div className={styles.sheetIndicator}>
                  <FontAwesomeIcon icon={faList} size="xs" className="mr-1" />{" "}
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                </div>
              )}

              <div className={styles.inputCol}>
                <button
                  className={styles.roundButton}
                  type="button"
                  onClick={() =>
                    changeHandler(rowData, decreaseFullQuantity(qty), stocktake)
                  }
                  disabled={!permissionObj?.permissions.modify}
                >
                  -
                </button>
                <input
                  className={styles.textInput}
                  name="fullQuantity"
                  id="fullQuantity"
                  onChange={(event) => {
                    changeHandler(rowData, event.target.value, stocktake)
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                  type="number"
                  min={0}
                  placeholder="XX"
                  value={qty === 0 ? "" : roundNumber(qty)}
                  disabled={!permissionObj?.permissions.modify}
                />
                <button
                  className={styles.roundButton}
                  type="button"
                  onClick={() =>
                    changeHandler(rowData, increaseFullQuantity(qty), stocktake)
                  }
                  disabled={!permissionObj?.permissions.modify}
                >
                  +
                </button>
              </div>
            </div>
          )
        },
      },
      {
        key: "price",
        title: "Value",
        dataKey: "price",
        width: 100,
        flexGrow: 1,
        flexShrink: 0,
        className: "font-sansSemiBold font-semibold text-gray-700",
        cellRenderer: ({ rowData }) => {
          const amount = items ? rowData.totalAmount : rowData.totalCost

          return (
            <>
              {amount > -1 && !isUnsynced(rowData) ? (
                roundNumber(amount)
              ) : (
                <FontAwesomeIcon
                  spin
                  icon={faSync}
                  size="xs"
                  className="text-gray-500"
                />
              )}
            </>
          )
        },
      },
    ]

    if (!isCompleted || isParentReport) {
      columns = [
        ...columns,
        {
          key: "action",
          title: "",
          dataKey: "id",
          width: 50,
          flexGrow: 1,
          flexShrink: 0,
          className: "font-sansSemiBold font-semibold text-primaryPink ",
          cellRenderer: ({ rowData }) => {
            return (
              <div
                className={`flex flex-col my-3 ${
                  rowData?.unsynced?.remove ? "opacity-50" : ""
                }`}
              >
                <button
                  className={styles.removeButton}
                  disabled={
                    !permissionObj?.permissions.modify ||
                    rowData?.unsynced?.remove == "remove"
                  }
                  onClick={() => {
                    items
                      ? removeProduct(rowData, stocktake)
                      : removeRecipe(rowData, stocktake)
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </button>
              </div>
            )
          },
        },
      ]
    }
    return columns
  }, [tab, stocktake])

  return (
    <div
      className={cx("contentContainer", {
        selectSheet: selectSheet,
      })}
    >
      <Tabs
        onTabChange={(tab) => setTab(tab)}
        initialTab={"items"}
        className="growyze-tabs bg-gray-100"
      >
        <Tab title="Items" tabKey="items">
          <div className={styles.content}>
            <div className="w-full flex-grow lg:pb-20">
              {!isTabletOrMobile && stocktake.products.length > 0 && (
                <div className="relative flex">
                  <SearchByParamInput
                    className="w-full m-3 my-3"
                    currentParam={queryParameter}
                    setCurrentParam={setQueryParameter}
                    setSearchValue={onSearch}
                    paramOptions={stockTakeQueryParameters}
                    entity=""
                  />
                </div>
              )}

              <AutoResizer>
                {({ width, height }) => (
                  <BaseTable
                    fixed={isTabletOrMobile ? true : false}
                    estimatedRowHeight={70}
                    width={width}
                    ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
                    height={height}
                    headerHeight={stocktake.products.length > 0 ? 30 : 0}
                    data={filteredProducts(stocktake.products)}
                    columns={tableColumns}
                    emptyRenderer={
                      <div className="flex flex-col items-center justify-center h-full">
                        <h3 className="text-lg mb-4 font-semibold">
                          {stocktake.products.length == 0
                            ? "No items in this stocktake yet"
                            : "No items in this stocktake match your search"}
                        </h3>
                        <div className="flex justify-center items-center mb-6">
                          {!stockTakeSheet && (
                            <>
                              <button
                                className={cx(
                                  "button button--autoWidth button--primaryBlue"
                                )}
                                disabled={
                                  isLoading ||
                                  !permissionObj?.permissions.modify
                                }
                                onClick={() => {
                                  setSelectSheet(!selectSheet)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faList}
                                  className="mr-2"
                                />
                                Use stocksheet
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  />
                )}
              </AutoResizer>
            </div>
            {stocktake.products.length > 0 && (
              <div
                className={cx("summary", {
                  keyboardOpen: mobileKeyboardOpen,
                })}
              >
                <span>
                  {stocktake.products.length}{" "}
                  {stocktake.products.length !== 1 ? "items" : "item"}
                </span>
                <span>Total value: {roundNumber(totalProductsValue())}</span>
              </div>
            )}
          </div>
        </Tab>
        <Tab title="Recipes" tabKey="recipes">
          <div className={styles.content}>
            <div className="w-full flex-grow lg:pb-20">
              {!isTabletOrMobile && stocktake.recipes.length > 0 && (
                <div className="p-3">
                  <SearchInput
                    label="Search by recipes"
                    placeholder="Search by recipes"
                    className="form-control rounded"
                    onSearchChange={(value) => {
                      onSearch(value)
                    }}
                  />
                </div>
              )}
              <AutoResizer>
                {({ width, height }) => (
                  <BaseTable
                    fixed={isTabletOrMobile ? true : false}
                    estimatedRowHeight={70}
                    width={width}
                    ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
                    height={height}
                    headerHeight={stocktake.recipes.length > 0 ? 30 : 0}
                    data={filteredRecipes(stocktake.recipes)}
                    columns={tableColumns}
                    emptyRenderer={
                      <div className="flex flex-col items-center justify-center h-full">
                        <h3 className="text-lg mb-4 font-semibold font-sansSemiBold">
                          {stocktake.recipes.length == 0
                            ? "No recipes in this stocktake yet"
                            : "No recipes in this stocktake match your search"}
                        </h3>
                        <div className="flex justify-center items-center mb-6">
                          <button
                            className={cx(
                              "button button--autoWidth button--primaryGreen",
                              "scanButton"
                            )}
                            disabled={
                              isLoading || !permissionObj?.permissions.modify
                            }
                            onClick={() => onScanAction("recipes")}
                          >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Add recipe
                          </button>
                          {!stockTakeSheet && (
                            <>
                              <span className="font-sansBold mx-4">or</span>
                              <button
                                className={cx(
                                  "button button--autoWidth button--primaryBlue"
                                )}
                                disabled={
                                  isLoading ||
                                  !permissionObj?.permissions.modify
                                }
                                onClick={() => {
                                  setSelectSheet(!selectSheet)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faList}
                                  className="mr-2"
                                />
                                Use stocksheet
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  />
                )}
              </AutoResizer>
            </div>
            {stocktake.recipes.length > 0 && (
              <div
                className={cx("summary", {
                  keyboardOpen: mobileKeyboardOpen,
                })}
              >
                <span>
                  {stocktake.recipes.length}{" "}
                  {stocktake.recipes.length !== 1 ? "items" : "item"}
                </span>
                <span>Total value: {roundNumber(totalRecipesValue())}</span>
              </div>
            )}
          </div>
        </Tab>
        <Tab title="Notes" tabKey="notes">
          <div className={styles.content}>
            {!isCompleted || isParentReport ? (
              <Note
                disabled={!permissionObj?.permissions.modify}
                note={stocktake.note}
                onChange={onNoteChange}
              />
            ) : (
              <div className="h-full px-4 lg:px-6 py-4 w-full text-gray-700">
                {stocktake.note || "No notes added"}
              </div>
            )}
          </div>
        </Tab>
      </Tabs>

      <footer className={cx("footer", { keyboardOpen: mobileKeyboardOpen })}>
        <button className={styles.cancelButton} onClick={onClose}>
          Close
        </button>

        {tab === "items" && (!isCompleted || isParentReport) && (
          <div className="space-x-4">
            <button
              className={cx(
                "button button--autoWidth button--primaryGreen",
                "scanButton"
              )}
              disabled={!permissionObj?.permissions.modify}
              onClick={() => onScanAction("items")}
            >
              <FontAwesomeIcon icon={faBarcodeRead} className="mr-2" />
              Scan
            </button>

            <button
              className={cx(
                "button button--autoWidth button--primaryGreen",
                "scanButton"
              )}
              disabled={!permissionObj?.permissions.modify}
              onClick={() => onScanAction("items", true)}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add manually
            </button>
          </div>
        )}
        {tab === "recipes" && (!isCompleted || isParentReport) && (
          <button
            className={cx(
              "button button--autoWidth button--primaryGreen",
              "scanButton"
            )}
            disabled={isLoading || !permissionObj?.permissions.modify}
            onClick={() => onScanAction("recipes")}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add recipe
          </button>
        )}
      </footer>

      <div
        className={cx("overlay", { hidden: !selectSheet })}
        onClick={closeStockSheetSelect}
      ></div>

      <div className={styles.sheetSelectContainer}>
        <div className={styles.sheetSelect}>
          <div className="h-full overflow-hidden flex flex-col bg-white">
            <div className="px-6 py-4 flex items-center border-b">
              <button
                className="text-primaryBlue mr-4"
                onClick={closeStockSheetSelect}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <h2 className="text-xl text-primaryBlue">Select a stocksheet</h2>
            </div>
            <div className="flex-grow flex flex-col overflow-auto relative">
              <StockTakeSheetSelect
                selectSheet={selectSheet}
                onSelect={onStocksheetSelect}
                onClose={closeStockSheetSelect}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StockTakeContent
