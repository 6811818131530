import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ModalContext } from "context/modal/ModalContext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBan } from "@fortawesome/pro-duotone-svg-icons"

import { isToday } from "date-fns"
import { convertTimeStampFormat } from "services/helpers"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

import * as styles from "./WasteHeader.module.css"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import usePermissions from "hooks/usePermissions"

const WasteHeader = ({ wasteGroup, deleteWasteGroup }) => {
  const modal = useContext(ModalContext)
  const today = isToday(new Date(wasteGroup.date))
  const { organization } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Waste")
  const onDelete = (waste) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete waste of ${waste.date || "Stocktake"}`,
      text: "Are you sure you want to delete this waste?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteWasteGroup(waste),
    })
  }

  const handleActionClick = (action, waste) => {
    const { type } = action

    switch (type) {
      case "waste.delete":
        onDelete(waste)
        break
      default:
        break
    }
  }

  const actions = (rowData) => {
    const options = [
      {
        key: "waste.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]

    return (
      <div className="sm:px-3 flex-shrink-0" style={{ textAlign: "right" }}>
        <ActionsButton
          options={options}
          onActionClick={(action) => handleActionClick(action, rowData)}
        />
      </div>
    )
  }

  return (
    <div className={styles.wasteHeader}>
      <div className="flex flex-1 items-center">
        <button className={`${styles.imageWrapper} mx-3`}>
          <div
            className={styles.iconWrapper}
            style={{
              backgroundColor: "#FFE5DB",
              color: "#F52525",
            }}
          >
            <FontAwesomeIcon icon={faBan} />
          </div>
        </button>
        <h2 className="text-sm sm:text-lg">
          New waste: {today ? "Today" : wasteGroup.date}
        </h2>
      </div>
      <div className="flex flex-1 items-center justify-end">
        <span className="text-xs sm:text-md text-gray-500 font-sansSemiBold font-semibold">
          {wasteGroup.updatedAt &&
            `Updated ${convertTimeStampFormat(
              wasteGroup.updatedAt,
              organization?.address.zoneId
            )}`}
        </span>
        {actions(wasteGroup)}
      </div>
    </div>
  )
}

WasteHeader.propTypes = {
  wasteGroup: PropTypes.object,
  deleteWasteGroup: PropTypes.func,
}

export default WasteHeader
