import React, { useContext, useEffect, useState } from "react"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { navigate } from "gatsby"
import { isLoggedIn } from "services/auth"
import { isBrowser } from "services/helpers"
import { getCombinedUserInformation } from "services/user"

interface PrivateRouteProps {
  component: React.ComponentClass<any>
  location: Location
}

const PrivateRoute = ({ component, location, ...rest }: PrivateRouteProps) => {
  const { user: currentUser, onboardingSteps } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [loading, setLoading] = useState(true)
  const [isInitializing, setIsInitializing] = useState(false)

  const getUserInformation = async () => {
    if (isInitializing) {
      return
    }
    setIsInitializing(true)
    const userInfo = await getCombinedUserInformation()
    dispatch({ type: "SET_COMBINED_USER_INFORMATION", payload: userInfo })
    setLoading(false)
    setIsInitializing(false)
  }

  const handleTokenRemove = ({ key, oldValue, newValue }) => {
    const isTokenEvent = key && key.includes("growyzeToken")
    // move to logout id token is removed
    if (isTokenEvent && newValue === null && location.pathname !== `/login`) {
      navigate("/login")
    }
    // Reload tab if for example venue is switched (token is refreshed)
    if (isTokenEvent && newValue !== null && newValue !== oldValue) {
      window.location.reload()
    }
  }

  const init = async () => {
    await getUserInformation()
  }

  useEffect(() => {
    // handle token remove
    window.addEventListener("storage", handleTokenRemove)
    return () => {
      window.removeEventListener("storage", handleTokenRemove)
    }
  }, [])

  useEffect(() => {
    if (!isBrowser()) {
      return
    }

    if (!isLoggedIn() && location.pathname !== `/login`) {
      navigate("/login")
      return
    }

    if (!currentUser || !onboardingSteps?.id) {
      setLoading(true)
      init()
    } else {
      setLoading(false)
    }
    return () => {
      setLoading(false)
    }
  }, [])

  const Component = component

  return !loading ? <Component location={location} {...rest} /> : null
}

export default PrivateRoute
