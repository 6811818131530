import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { differenceInDays, differenceInMinutes, formatDistance } from "date-fns"
import { useContext } from "react"

interface PlanExpirationData {
  expirationDate: string | null
  timeLeftInDays: number | null
  timeLeftInTotal: number | null
  formattedTimeLeft: string | null
}

const usePaymentPlanExpirationData = (): PlanExpirationData => {
  const { paymentPlan } = useContext(GlobalStateContext)

  const timeLeftInDays = paymentPlan?.expirationDate
    ? differenceInDays(new Date(paymentPlan.expirationDate), new Date())
    : null

  const timeLeftInTotal = paymentPlan?.expirationDate
    ? differenceInMinutes(new Date(paymentPlan.expirationDate), new Date())
    : null

  const formattedTimeLeft = paymentPlan?.expirationDate
    ? formatDistance(new Date(paymentPlan.expirationDate), new Date(), {
        addSuffix: true,
      })
    : null

  return {
    expirationDate: paymentPlan.expirationDate,
    timeLeftInDays,
    timeLeftInTotal,
    formattedTimeLeft,
  }
}

export default usePaymentPlanExpirationData
