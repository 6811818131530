// extracted by mini-css-extract-plugin
export var deliveryDate = "StockTakeSheetHeader-module--deliveryDate--f9sEC";
export var disabled = "StockTakeSheetHeader-module--disabled--9nKqs";
export var editButton = "StockTakeSheetHeader-module--editButton--gU3fV";
export var header = "StockTakeSheetHeader-module--header--1Z5DL";
export var headerMain = "StockTakeSheetHeader-module--headerMain--8pg7U";
export var iconWrapper = "StockTakeSheetHeader-module--iconWrapper--qRB-V";
export var ref = "StockTakeSheetHeader-module--ref--PyjZN";
export var statusBulb = "StockTakeSheetHeader-module--statusBulb--QPWc2";
export var supplierName = "StockTakeSheetHeader-module--supplierName--doH0Z";
export var thumbnail = "StockTakeSheetHeader-module--thumbnail--UC47K";
export var thumbnailBadge = "StockTakeSheetHeader-module--thumbnailBadge--OwfEi";
export var thumbnailWrapper = "StockTakeSheetHeader-module--thumbnailWrapper--WQ67P";