import { useLocation } from "react-use"
import { helpVideos } from "services/constants"

const useShowHelpButton = (): boolean => {
  let show = false
  const { pathname } = useLocation()
  const matches = helpVideos.map((v) => v.pathname)
  for (let i = 0; i < matches.length; i++) {
    if (pathname === matches[i]) {
      show = true
    }
  }
  return show
}

export default useShowHelpButton
