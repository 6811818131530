import React from "react"
import * as styles from "./ResetPassword.module.css"
import AuthFormWrapper from "../AuthFormWrapper/AuthFormWrapper"
import Helmet from "react-helmet"
import { Formik, Form } from "formik"
import TextInput from "../../forms/TextInput"
import { Link } from "@reach/router"
import Logo from "../../../inline-svg/growyze-logo-primary-blue-dark.svg"
import ContactModal from "components/common/ContactModal/ContactModal"
import { ModalConsumer } from "../../../context/modal/ModalContext"
import { navigate } from "gatsby"
import { resetPassword, isLoggedIn } from "services/auth"

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      submitted: false,
      retries: 0,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
  }

  handleSubmit(data, ref) {
    this.setState({ ...this.state, username: data.username })
    return this.resetPassword(data.username, ref)
  }

  resetPassword(username, formRef = null) {
    // login user
    resetPassword({ username })
      .then(() => {
        this.setState({
          submitted: true,
          retries: this.state.submitted ? this.state.retries + 1 : 0,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          error: true,
        })
      })

    if (formRef) {
      formRef.setSubmitting(false)
    }
  }

  componentDidMount() {
    if (isLoggedIn()) {
      navigate(`/dashboard`, { replace: true })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Reset password</title>
        </Helmet>
        <AuthFormWrapper>
          <Formik
            initialValues={{
              username: "",
            }}
            onSubmit={this.handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col mb-4 px-6">
                <Logo className="mb-6 block mx-auto" />

                <div className={styles.welcomeContent}>
                  <h1 className="text-4xl mb-4">Reset password</h1>
                  {this.state.submitted ? (
                    <div>
                      <p className="text-lg text-gray-700 mb-4">
                        If account exists, an email will be sent with further
                        instructions.
                      </p>
                      <p className="text-sm text-gray-700 mb-4">
                        Didn’t receive the email? Please check your spam folder.{" "}
                        {this.state.retries === 0 && (
                          <span>
                            <button
                              className="text-primaryPink"
                              onClick={(e) => {
                                e.preventDefault()
                                this.resetPassword(this.state.username)
                              }}
                            >
                              Resend email verification
                            </button>
                            {" or "}
                          </span>
                        )}
                        <ModalConsumer>
                          {({ showModal }) => (
                            <a
                              href="mailto:support@growyze.com"
                              className="text-primaryPink"
                              onClick={(e) => {
                                e.preventDefault()
                                showModal(ContactModal)
                              }}
                            >
                              Contact us
                            </a>
                          )}
                        </ModalConsumer>
                      </p>
                    </div>
                  ) : (
                    <p className="text-lg text-gray-700 mb-4">
                      You’ll be back in action very soon.
                    </p>
                  )}
                </div>

                {this.state.error && (
                  <div className={styles.error}>
                    Having trouble?{" "}
                    <ModalConsumer>
                      {({ showModal }) => (
                        <a
                          href="mailto:support@growyze.com"
                          className="text-primaryPink"
                          onClick={(e) => {
                            e.preventDefault()
                            showModal(ContactModal)
                          }}
                        >
                          Contact us
                        </a>
                      )}
                    </ModalConsumer>
                  </div>
                )}

                {!this.state.submitted && (
                  <div className="form-group mt-2">
                    <div className="input-container">
                      <TextInput
                        name="username"
                        label="Email address"
                        showValidations={false}
                        type="text"
                        placeholder="Email address"
                        className="form-control form-control--first form-control--last"
                      />
                    </div>
                  </div>
                )}
                {!this.state.submitted && (
                  <button
                    type="submit"
                    className="button mt-5"
                    disabled={isSubmitting}
                  >
                    Reset password
                  </button>
                )}
              </Form>
            )}
          </Formik>

          <div className="flex flex-wrap justify-between px-6">
            <p className="text-sm text-gray-700 text-center py-2">
              Back to{" "}
              <button
                className="text-primaryPink"
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/login", { state: { backwards: true } })
                }}
              >
                Log In
              </button>
            </p>
            <p className="text-sm text-gray-700 py-2">
              No account yet?{" "}
              <Link className="text-primaryPink" to="/signup">
                Sign Up
              </Link>
            </p>
          </div>
        </AuthFormWrapper>
      </>
    )
  }
}

export default ResetPassword
