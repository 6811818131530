// extracted by mini-css-extract-plugin
export var container = "SupplierMarketplaceDetails-module--container--no+Kj";
export var content = "SupplierMarketplaceDetails-module--content--di+o9";
export var disableProductSelect = "SupplierMarketplaceDetails-module--disableProductSelect--tVbNB";
export var main = "SupplierMarketplaceDetails-module--main--CsFr6";
export var noOrders = "SupplierMarketplaceDetails-module--noOrders--mFQDH";
export var selectedSupplier = "SupplierMarketplaceDetails-module--selectedSupplier--uaOuX";
export var sidebar = "SupplierMarketplaceDetails-module--sidebar--SUXE5";
export var sidebarContent = "SupplierMarketplaceDetails-module--sidebarContent--ZtLWC";
export var sidebarFooter = "SupplierMarketplaceDetails-module--sidebarFooter--XTILU";
export var sidebarTitle = "SupplierMarketplaceDetails-module--sidebarTitle--VwkFX";
export var subHeader = "SupplierMarketplaceDetails-module--subHeader--EUX44";
export var subHeaderStep = "SupplierMarketplaceDetails-module--subHeaderStep--69FxL";
export var subHeaderStepActive = "SupplierMarketplaceDetails-module--subHeaderStepActive--ea51f";
export var supplierTooltip = "SupplierMarketplaceDetails-module--supplierTooltip--GLXKg";
export var wrapper = "SupplierMarketplaceDetails-module--wrapper--enXB4";