const globalOrderReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MULTIPLE_TO_GLOBAL_ORDER": {
      //Locals
      const local = state.globalOrder
      const currentProducts = local.products

      const { products } = action.payload

      const newUniqueProducts = products.filter(
        (product) => !currentProducts.find((a) => a.barcode === product.barcode)
      )

      const res = newUniqueProducts.map((a) => {
        return {
          ...a,
          _basketQuantity: Math.ceil(a._basketQuantity ? a._basketQuantity : 1),
          _selectedCase: a._selectedCase,
        }
      })

      return {
        ...state,
        globalOrder: {
          products: currentProducts.concat(res),
        },
      }
    }

    // GLOBAL ORDER

    case "ADD_TO_GLOBAL_ORDER": {
      const local = state.globalOrder
      const { product, _basketQuantity, _selectedCase } = action.payload

      const currentProducts = local.products

      return {
        ...state,
        globalOrder: {
          products: currentProducts.concat({
            //Expecting product to match default product data, from basetable it should be rowData
            ...product,
            //Optionally passable properties
            _basketQuantity: Math.ceil(
              _basketQuantity
                ? _basketQuantity
                : product._basketQuantity
                ? product._basketQuantity
                : 1
            ),
            _selectedCase: _selectedCase
              ? _selectedCase
              : product._selectedCase
              ? product._selectedCase
              : null,
          }),
        },
      }
    }

    case "REMOVE_FROM_GLOBAL_ORDER": {
      const local = state.globalOrder

      const { productId } = action.payload
      const currentProducts = local.products

      const tempCurrentProducts = [...currentProducts]

      //Removing specific index matching product id from current product list
      tempCurrentProducts.splice(
        tempCurrentProducts.findIndex((a) => a.id === productId),
        1
      )

      return {
        ...state,
        globalOrder: {
          products: tempCurrentProducts,
        },
      }
    }

    case "UPDATE_GLOBAL_ORDER_PRODUCT": {
      const local = state.globalOrder
      const { product, _basketQuantity, _selectedCase, replaceWith } =
        action.payload
      const currentProducts = local.products

      const tempCurrentProducts = [...currentProducts]
      const spreadTarget = replaceWith ? replaceWith : product

      tempCurrentProducts[
        tempCurrentProducts.findIndex((a) => a.id === product.id)
      ] = {
        ...spreadTarget,
        //Optionally passable properties, removed Math.ceil
        _basketQuantity: Math.ceil(
          _basketQuantity
            ? _basketQuantity
            : spreadTarget._basketQuantity
            ? spreadTarget._basketQuantity
            : 1
        ),
        _selectedCase: _selectedCase
          ? _selectedCase
          : spreadTarget._selectedCase
          ? spreadTarget._selectedCase
          : null,
      }

      return {
        ...state,
        globalOrder: {
          products: [...tempCurrentProducts],
        },
      }
    }

    case "CLEAR_GLOBAL_ORDER_PRODUCT": {
      return {
        ...state,
        globalOrder: {
          products: [],
        },
      }
    }
    default: {
      return state
    }
  }
}

export default globalOrderReducer
