import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-solid-svg-icons"

import Dots from "components/common/Dots/Dots"
import OrganizationDetailsForm from "components/organization/OrganizationDetailsForm/OrganizationDetailsForm"

interface CompanyDetailsProps {
  organizationDetails: any
  onDataChange: Function
  nextStep(): void
  prevStep(): void
  dotCount?: number
  isAdmin?: boolean
}

const CompanyDetails = ({
  organizationDetails,
  onDataChange,
  prevStep,
  nextStep,
  dotCount,
  isAdmin = true,
}: CompanyDetailsProps) => {
  const [nextEnabled, setNextEnabled] = useState(false)

  return (
    <>
      {!isAdmin && (
        <h1 className="text-4xl text-center mb-2">Hi, welcome to growyze!</h1>
      )}
      <h1 className="text-center text-2xl mb-6">Your Business Details</h1>
      <OrganizationDetailsForm
        initialValues={organizationDetails}
        onDataChange={(data) => onDataChange(data)}
        onValidChange={(isValid) => setNextEnabled(isValid)}
        showTelephoneField
      />
      <div className="flex px-0 justify-between">
        <button
          type="button"
          className={
            "font-sansBold font-bold text-gray-700" +
            (isAdmin ? "" : " invisible")
          }
          onClick={prevStep}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="md:mr-4" />
          <span className="hidden md:inline">Previous</span>
        </button>
        <Dots index={0} count={dotCount ?? 2} />
        <button
          type="button"
          className="button button--autoWidth bg-primaryPink"
          onClick={nextStep}
          disabled={!nextEnabled}
        >
          <span className="hidden md:inline">Next</span>
          <FontAwesomeIcon icon={faArrowRight} className="md:ml-4" />
        </button>
      </div>
    </>
  )
}

export default CompanyDetails
