import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { usePromise, usePrevious } from "react-use"
import Helmet from "react-helmet"
import { getWastes } from "services/waste/waste"
import { getProductByNameAndBarcode } from "services/products/products"
import { removeTimezone } from "services/helpers"
import { isToday, format } from "date-fns"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import WasteModal from "../WasteModal/WasteModal"
import { ModalContext } from "context/modal/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import Header from "components/dashboard/Header/Header"
import AsyncSelect from "components/forms/AsyncSelect"
import WasteItem from "../WasteItem/WasteItem"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"

import * as styles from "./Waste.module.css"
import { AsideContext } from "context/aside/AsideContext"
import usePermissions from "hooks/usePermissions"
import usePagination from "hooks/usePagination"
import BaseList from "components/baseList/BaseList"
import { getWasteDummy } from "services/dummy"
import { faBan } from "@fortawesome/pro-duotone-svg-icons"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import FiltersButton from "components/common/FiltersButton/FiltersButton"

const Waste = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)
  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)

  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [wasteData, setWasteData] = useState({
    content: [],
  })
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  })

  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)
  const permissionObj = usePermissions("Waste")

  const toggleDatePicker = () => {
    //@ts-ignore
    aside.showAside(AsideCalendar, {
      selectedPeriod,
      headerText: "Date picker",
      onConfirm: (data) => {
        setSelectedPeriod(data)
        aside.hideAside()
      },
      onClose: () => aside.hideAside(),
    })
  }

  const loadProductOptions = (inputValue) =>
    getProductByNameAndBarcode({ partialName: inputValue })

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setWasteData({ content: [] })
      return
    }

    setLoading(true)

    let params = {
      page: pagination.page,
      sort: "date,desc",
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    const { from, to } = selectedPeriod

    if (from && to) {
      params.from = removeTimezone(from)
      params.to = removeTimezone(to)
    }

    if (selectedProduct) {
      params.barcode = selectedProduct.barcode
    }

    const result = await fromPromise(getWastes(params))

    if (result && !result.error) {
      setWasteData({ ...wasteData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const addNewWasteToday = (e) => {
    e.preventDefault()

    if (wasteData.content.length > 0) {
      const [firstWasteGroup] = wasteData.content
      const isWasteGroupToday = isToday(new Date(firstWasteGroup.date))

      if (isWasteGroupToday) {
        dispatch({
          type: "SELECT_WASTE_GROUP",
          payload: {
            selectedWasteGroup: firstWasteGroup,
          },
        })
      } else {
        dispatch({ type: "ADD_NEW_WASTE_GROUP" })
      }
    } else {
      dispatch({ type: "ADD_NEW_WASTE_GROUP" })
    }

    modal.showModal(WasteModal, {
      getDataWasteGroups: getData,
      onDirectScan: false,
      allowDateSelect: true,
    })
  }

  const createWasteReport = async (e) => {
    e.preventDefault()
    return navigate(`/dashboard/stock/waste-report`)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  useEffect(() => {
    if (selectedPeriod.to && selectedPeriod.from) {
      getData()
    }
  }, [selectedPeriod])

  useEffect(() => {
    getData()
  }, [selectedProduct, selectedOrganizations])

  return (
    <>
      <Helmet>
        <title>Waste</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Waste" />

        <div className={styles.subHeader}>
          <div className="my-2 flex flex-grow-0">
            <div className="flex">
              <div className={`${styles.dateField} input-container mr-4`}>
                <input
                  type="text"
                  value={formatDate(selectedPeriod.from)}
                  className="cursor-pointer form-control rounded"
                  placeholder="From"
                  readOnly
                  onClick={toggleDatePicker}
                />
              </div>
              <div className={`${styles.dateField} input-container`}>
                <input
                  type="text"
                  className="cursor-pointer form-control rounded"
                  value={formatDate(selectedPeriod.to)}
                  placeholder="To"
                  readOnly
                  onClick={toggleDatePicker}
                />
              </div>
            </div>
          </div>

          <FiltersButton>
            <div className="w-64">
              <AsyncSelect
                promise={loadProductOptions}
                placeholder="Please select a product"
                defaultValue={
                  selectedProduct
                    ? {
                        label: selectedProduct.name,
                        value: selectedProduct.name,
                      }
                    : true
                }
                defaultOptions={true}
                className=""
                isClearable={true}
                optionLabel="description"
                optionValue="description"
                includeMeta
                noOptionsMessage={() => "Type to search"}
                onChange={(product) => {
                  setSelectedProduct(product)
                }}
              />
            </div>
            <LocationsSelector className="w-64" />
          </FiltersButton>

          <div className="ml-auto">
            <button
              type="button"
              className="button mr-4 ml-auto button--autoWidth button--primaryBlue"
              onClick={createWasteReport}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" /> Report
            </button>
            <button
              type="button"
              className="button my-2 ml-auto button--autoWidth button--orange"
              onClick={addNewWasteToday}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
              <span className="hidden lg:inline">Add waste</span>
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <BaseList
            title="Waste"
            id="waste"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faBan,
              color: "orange",
              getDummy: () => getWasteDummy,
            }}
            data={wasteData.content}
            rowRender={(waste) => (
              <WasteItem
                key={waste.id}
                waste={waste}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
                onCreated={() => getData()}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default Waste
