const paymentPlanReducer = (state, action) => {
  switch (action.type) {
    case "SET_PAYMENT_PLAN": {
      return {
        ...state,
        paymentPlan: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
export default paymentPlanReducer
