import { Category, CategoryParams, MainCategory } from "./types"

export const applyReportFiltersToCategories = (
  categories: Category[],
  filterCategories: CategoryParams | null,
  includeUncategorised: boolean,
  selectAll = false
) => {
  const formattedCategories = categories.map((category) => {
    const categoryFound = filterCategories?.mainCategories?.find(
      (filterCategory) => filterCategory.name === category.name
    )

    const formattedSubcategories = category.subCategories.map((subCategory) => {
      const subCategoryFound = categoryFound
        ? categoryFound.subCategories.find(
            (filterSubCategory) => filterSubCategory === subCategory.name
          )
        : selectAll
      return { ...subCategory, active: !!subCategoryFound }
    })

    if (includeUncategorised) {
      formattedSubcategories.push({
        active: selectAll || !!categoryFound?.includeUncategorised,
        name: "No category",
      })
    }

    return {
      ...category,
      active: selectAll || !!categoryFound,
      subCategories: formattedSubcategories,
    }
  })

  if (includeUncategorised) {
    formattedCategories.push({
      active: selectAll || !!filterCategories?.includeUncategorised,
      subCategories: [],
      name: "No category",
      desc: null,
    })
  }

  return formattedCategories
}

export const formatCategoriesAsReportFilterCategories = (
  categories: Category[]
): CategoryParams => {
  const includeUncategorisedCategoryLevel = categories.find(
    (category) => category.name === "No category" && category.active
  )

  const formattedCategories: MainCategory[] = []

  categories.forEach((category) => {
    if (!category.active || category.name === "No category") return

    const includeUncategorisedSubcategoryLevel = category.subCategories.find(
      (subCategory) => subCategory.name === "No category" && subCategory.active
    )

    const formattedSubcategories: string[] = []

    category.subCategories.forEach((subCategory) => {
      if (!subCategory.active || subCategory.name === "No category") return

      formattedSubcategories.push(subCategory.name)
    })

    formattedCategories.push({
      includeUncategorised: !!includeUncategorisedSubcategoryLevel,
      name: category.name,
      subCategories: formattedSubcategories,
    })
  })
  return {
    includeUncategorised: !!includeUncategorisedCategoryLevel,
    mainCategories: formattedCategories,
  }
}
