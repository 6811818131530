import React from "react"
import Modal from "react-modal"
import ProductSelect from "components/common/ProductSelect/ProductSelect"

const ModalProductSelector = ({
  currentProducts,
  setProducts,
  onRequestClose,
  allowedSuppliers,
}) => {
  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
    >
      <ProductSelect
        currentProducts={currentProducts}
        multiSelect
        onClose={onRequestClose}
        onSelect={(products) => {
          setProducts(products)
          onRequestClose()
        }}
        searchBySupplier
        allowedSuppliers={allowedSuppliers}
      />
    </Modal>
  )
}

export default ModalProductSelector
