import React, { useContext } from "react"
import { navigate } from "gatsby"
import { ModalContext } from "context/modal/ModalContext"

import {
  formatCurrencyValue,
  getFileNameDateTime,
  roundNumber,
} from "services/helpers"
import {
  deleteCOGSReport,
  exportCOGSReport,
} from "services/stock-take/stock-take"
import { showSuccess, showError } from "services/toast"

import { faCalculator, faChartBar } from "@fortawesome/pro-duotone-svg-icons"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

//@ts-ignore
import Container from "components/common/OverviewItem/Container/Container"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { getOrgNameById } from "services/user"
import { getExportFileName } from "services/export"
import ReportNameCell from "components/baseList/cells/ReportName"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHashtag } from "@fortawesome/pro-regular-svg-icons"
import CornerLabel from "components/common/CornerLabel/CornerLabel"

interface COGSReportItemProps {
  cogs: any
  onDeleted(): void
}

const COGSReportItem = ({ cogs, onDeleted }: COGSReportItemProps) => {
  const {
    organization,
    organizations,
    organizationPermissions,
  }: { organization: any; organizations: any; organizationPermissions: any } =
    useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const currency = null
  const permissionObj = usePermissions("COGS") as Permission
  const {
    id,
    totalCOGS,
    totalCostClosingStock,
    totalSoldValue,
    totalCOGSPercentage,
    totalCostGoodsSpendPercentage,
    theoretical,
  } = cogs || {}
  const isSelfCreatedReport =
    cogs.organizations.length && cogs.organizations[0] === organization.id

  const options = isSelfCreatedReport
    ? [
        {
          key: "cogs.edit",
          title: "View report",
          disabled: !permissionObj?.permissions.read,
        },
        {
          key: "cogs.delete",
          title: "Delete",
          type: "danger",
          disabled: !permissionObj?.permissions.delete,
        },
        {
          key: "cogs.export.csv",
          title: "Export full report (csv)",
          disabled: !permissionObj?.permissions.read,
        },
        {
          key: "cogs.export.xlsx",
          title: "Export full report (xlsx)",
          disabled: !permissionObj?.permissions.read,
        },
      ]
    : []

  const onDelete = (cogs) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete COGS report",
      text: "Are you sure you want to delete this COGS report?",
      confirmButtonText: "Delete",
      onConfirm: () => handleDeleteCOGSReport(cogs),
    })
  }

  const handleDeleteCOGSReport = async (cogs) => {
    const deleted = await deleteCOGSReport(cogs.id)
    if (deleted && !deleted.message) {
      showSuccess("Report deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
  }

  const handleActionClick = (action, cogs) => {
    const { type } = action
    const fileDatetime = getFileNameDateTime()

    switch (type) {
      case "cogs.edit":
        navigate(`/dashboard/insights/cogs-reports/${cogs.id}`)
        break
      case "cogs.delete":
        onDelete(cogs)
        break
      case "cogs.export.csv":
        exportCOGSReport(
          cogs.id,
          getExportFileName("cogs-report", "full", "csv", fileDatetime),
          "csv",
          {}
        )
        break
      case "cogs.export.xlsx":
        exportCOGSReport(
          cogs.id,
          getExportFileName("cogs-report", "full", "xlsx", fileDatetime),
          "xlsx",
          {}
        )
        break
      default:
        break
    }
  }

  return (
    <Container
      data={cogs}
      actionOptions={options}
      handleActionClick={handleActionClick}
    >
      <>
        {theoretical && (
          <CornerLabel colorClassName="text-red-600">
            <FontAwesomeIcon icon={faHashtag} className="mr-1" /> Theoretical
          </CornerLabel>
        )}

        <ReportNameCell
          data={cogs}
          disabled={!permissionObj?.permissions.read}
          iconStyles={{
            mainIcon: {
              backgroundColor: "#FC37620D",
              color: "#e20d3b",
            },
            subIcon: {
              color: "#e20d3b",
            },
          }}
          onClick={() => navigate(`/dashboard/insights/cogs-reports/${id}`)}
          mainIcon={faChartBar}
          subIcon={faCalculator}
        >
          {organizationPermissions?.general?.isMain && (
            <span className="font-sansSemiBold text-sm font-semibold flex items-center">
              {cogs.organizations.length &&
                getOrgNameById(cogs.organizations[0], organizations)}
            </span>
          )}
        </ReportNameCell>

        <div className="flex flex-col p-2" style={{ minWidth: "140px" }}>
          <span className="text-sm text-gray-700">COGS</span>
          <div className="flex items-baseline">
            <span className="text-sm font-sansSemiBold font-semibold">
              {`${
                currency
                  ? formatCurrencyValue(totalCOGS)
                  : roundNumber(totalCOGS)
              }`}
            </span>
            {totalCOGSPercentage ? (
              <span className="text-xs font-sansSemiBold font-semibold text-primaryPink ml-1">
                ({`${roundNumber(totalCOGSPercentage)}%`})
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex flex-col p-2" style={{ minWidth: "180px" }}>
          <span className="text-sm text-gray-700">Cost of Closing Stock</span>
          <span className="text-sm font-sansSemiBold font-semibold">
            {`${
              currency
                ? formatCurrencyValue(totalCostClosingStock)
                : roundNumber(totalCostClosingStock)
            }`}
          </span>
        </div>

        <div className="flex flex-col p-2" style={{ minWidth: "100px" }}>
          <span className="text-sm text-gray-700">Sales</span>
          <span className="text-sm font-sansSemiBold font-semibold">
            {totalSoldValue > 0
              ? `${
                  currency
                    ? formatCurrencyValue(totalSoldValue)
                    : roundNumber(totalSoldValue)
                }`
              : "-"}
          </span>
        </div>

        <div className="flex flex-col p-2" style={{ minWidth: "120px" }}>
          <span className="text-sm text-gray-700">COG Spent %</span>
          <span className="text-sm font-sansSemiBold font-semibold">
            {totalCostGoodsSpendPercentage ? (
              <span>{`${roundNumber(totalCostGoodsSpendPercentage)}%`}</span>
            ) : (
              "-"
            )}
          </span>
        </div>
      </>
    </Container>
  )
}

export default COGSReportItem
