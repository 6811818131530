export const invoiceReviewObject = {
  event: "document_evaluate",
  data: {
    id: "637a5b32de84e69df5e38d68",
    prediction: {
      lines: {
        line_items: [],
      },
      annotations: {
        invoice_number: [
          {
            text: "43242343",
          },
        ],
        invoice_date: [
          {
            text: "20 Nov 2022",
          },
        ],
        due_date: null,
        gross_amount: [
          {
            text: "156.23",
          },
        ],
        net_amount: [
          {
            text: "156.23",
          },
        ],
        sender_email: null,
        sender_name: [
          {
            text: 'On 18/11/2022,00:16," Castell Howell Foods',
          },
        ],
        receiver_email: [
          {
            text: "growyze-invoices-staging@outlook.com",
          },
        ],
        email_subject: null,
        sender_VAT: [
          {
            text: "",
          },
        ],
      },
    },
    feedback: {
      lines: [
        {
          line_elements: {
            description: {
              text: "Frozen Pork Sausage Meat 1kg",
            },
            quantity: {
              text: "1",
            },
            seller_element_number: {
              text: "60317",
            },
            total_amount: {
              text: "4.62",
            },
            unit: {
              text: "kilo",
            },
            unit_price: {
              text: "4.62",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "CPride Rump Steak 227g.",
            },
            quantity: {
              text: "12",
            },
            seller_element_number: {
              text: "1898",
            },
            total_amount: {
              text: "49.2",
            },
            unit: {
              text: "",
            },
            unit_price: {
              text: "4.1",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Lamb Rump Pave Welsh 170g",
            },
            quantity: {
              text: "2",
            },
            seller_element_number: {
              text: "",
            },
            total_amount: {
              text: "50.97",
            },
            unit: {
              text: "per",
            },
            unit_price: {
              text: "23.49",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Missing product Case",
            },
            quantity: {
              text: "5",
            },
            seller_element_number: {
              text: String(Math.floor(Math.random() * 1000000000000)),
            },
            total_amount: {
              text: "15.08",
            },
            unit: {
              text: "CASE",
            },
            unit_price: {
              text: "10",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Missing product2",
            },
            quantity: {
              text: "5",
            },
            seller_element_number: {
              text: String(Math.floor(Math.random() * 1000000000000)),
            },
            total_amount: {
              text: "15.08",
            },
            unit: {
              text: "2kg",
            },
            unit_price: {
              text: "10",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Missing product",
            },
            quantity: {
              text: "5",
            },
            seller_element_number: {
              text: String(Math.floor(Math.random() * 1000000000000)),
            },
            total_amount: {
              text: "15.08",
            },
            unit: {
              text: "KILO",
            },
            unit_price: {
              text: "10",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Pork Tenderloin Fillet",
            },
            quantity: {
              text: "2",
            },
            seller_element_number: {
              text: "",
            },
            total_amount: {
              text: "15.08",
            },
            unit: {
              text: "CASE",
            },
            unit_price: {
              text: "7.5",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Unsmoked Rindless Back Bacon BLUE Please note our new bank details: Sort Code 201904 A/C No. 30534706",
            },
            quantity: {
              text: "4",
            },
            seller_element_number: {
              text: "4854",
            },
            total_amount: {
              text: "36.36",
            },
            unit: {
              text: "CASE",
            },
            unit_price: {
              text: "9.09",
            },
          },
        },
        {
          line_elements: {
            description: {
              text: "Unknown price",
            },
            quantity: {
              text: "2",
            },
            seller_element_number: {
              text: "00035647",
            },
            total_amount: {
              text: "36.36",
            },
            unit: {
              text: "BOTTLE",
            },
            unit_price: {
              text: "",
            },
          },
        },

        {
          line_elements: {
            description: {
              text: "Unknown Quantity",
            },
            quantity: {
              text: "",
            },
            seller_element_number: {
              text: "00028483",
            },
            total_amount: {
              text: "36.36",
            },
            unit: {
              text: "CASE",
            },
            unit_price: {
              text: "9.09",
            },
          },
        },
      ],
      annotations: {
        invoice_number: [
          {
            text: String(Math.floor(Math.random() * 10000)),
          },
        ],
        invoice_date: [
          {
            text: new Date().toISOString().split("T")[0],
            //text: "2022-12-09",
          },
        ],
        due_date: [
          {
            text: "",
          },
        ],
        gross_amount: [
          {
            text: "156.23",
          },
        ],
        net_amount: [
          {
            text: "156.23",
          },
        ],
        sender_email: [
          {
            text: "",
          },
        ],
        sender_name: [
          {
            text: 'On 18/11/2022,00:16," Castell Howell Foods',
          },
        ],
        receiver_email: [
          {
            text: "lupdkgkfgoslwmnunz@bvhrk.com",
          },
        ],
        email_subject: null,
        sender_VAT: [
          {
            text: "123",
          },
        ],
      },
    },
    flag_for_review: false,
  },
}
