import React, { useState, useContext, useRef, useEffect } from "react"
import { Formik, Form, FieldArray, Field } from "formik"
import TextInput from "components/forms/TextInput"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo, faTimes } from "@fortawesome/pro-light-svg-icons"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { createCategory } from "services/categories"
import { CategoryDto, updateCategory } from "../../services/categories"
import { Category } from "services/types"
import { SubCategory } from "../../services/types"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

/**
 * Subcategory Item Component
 */
interface SubcategoryItemProps {
  index: number
  handleEnterKey: (e: any) => void
  isDeleted: boolean
  handleDeleteSubcategory: () => any
  handleUndoDeleteSubcategory: () => any
}

const SubcategoryItem = ({
  index,
  handleEnterKey,
  isDeleted,
  handleDeleteSubcategory,
  handleUndoDeleteSubcategory,
}: SubcategoryItemProps) => {
  const inputRef: React.LegacyRef<HTMLInputElement> | null = useRef(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div className="flex items-center  border-b" key={`subCategory-${index}`}>
      <div className={"form-group w-full"}>
        <Field name={`subCategories.${index}.name`}>
          {({ field }) => (
            <input
              type="text"
              className="w-full border-0 px-0 py-3"
              onKeyDown={handleEnterKey}
              placeholder="Enter subcategory name"
              ref={inputRef}
              {...field}
            />
          )}
        </Field>
      </div>
      <div className="flex items-center h-full">
        {isDeleted ? (
          <button
            type="button"
            onClick={() => handleUndoDeleteSubcategory()}
            className="btn border border-primaryGreen rounded-full font-sansBold mr-2 h-6 w-6 flex-shrink-0 flex items-center justify-center"
          >
            <FontAwesomeIcon
              icon={faUndo}
              size="sm"
              className="text-primaryGreen h-6 w-6"
            />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => handleDeleteSubcategory()}
            className="btn border border-primaryPink rounded-full font-sansBold mr-2 h-6 w-6 flex-shrink-0 flex items-center justify-center"
          >
            <FontAwesomeIcon
              icon={faTimes}
              size="sm"
              className="text-primaryPink h-6 w-6"
            />
          </button>
        )}
      </div>
    </div>
  )
}

/**
 * Categories Settings Component
 */

const schema = Yup.object().shape({
  name: Yup.string().required("Category name is required"),
})

interface CategoriesSettingsSidebarProps {
  category: Category
  onClose: () => void
}

const CategoriesSettingsSidebar = ({
  category,
  onClose,
}: CategoriesSettingsSidebarProps) => {
  const modal = useContext(ModalContext)

  const [deletedSubcategories, setDeletedSubcategories] = useState<
    SubCategory[]
  >([])

  const defaultValues = category
    ? { name: category.name, subCategories: category.subCategories }
    : { name: "", subCategories: [] }

  const handleCreateCategory = async (data: typeof defaultValues) => {
    const payload: CategoryDto = {
      name: data.name,
      desc: null,
      active: true,
      subCategories: data.subCategories.filter(
        (subCategory) => subCategory.name.length > 0
      ),
    }
    try {
      await createCategory(payload)
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditCategory = async (data: typeof defaultValues) => {
    const payload: CategoryDto = {
      name: data.name,
      desc: category.desc,
      active: category.active,
      subCategories: data.subCategories.filter((subCategory) => {
        const deletedIndex = deletedSubcategories.findIndex(
          (deletedSubCategory) => deletedSubCategory.name === subCategory.name
        )
        return deletedIndex === -1 && subCategory.name.length > 0
      }),
    }

    const updateCategoryAndCloseSidebar = async () => {
      if (category.id) {
        await updateCategory(category.id, payload)
      }
      return onClose()
    }

    try {
      if (deletedSubcategories.length > 0) {
        modal.showModal(ConfirmModal, {
          onRequestClose: modal.hideModal(),
          text: "Some subcategories have been deleted, all linked items will be set to uncategorised.",
          confirmButtonText: "Continue and save",

          onConfirm: updateCategoryAndCloseSidebar,
        })
      } else {
        updateCategoryAndCloseSidebar()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={defaultValues}
      validationSchema={schema}
      onSubmit={category ? handleEditCategory : handleCreateCategory}
    >
      {({ isValid, values, errors, touched }) => {
        return (
          <Form className="flex flex-col h-full overflow-hidden">
            <div className="form-group flex-grow m-6 overflow-auto">
              <div className="input-container mb-5">
                <TextInput
                  name="name"
                  showValidations={false}
                  label="Category name *"
                  type="text"
                  placeholder="Category name"
                  className="form-control rounded"
                  displayLabel={true}
                />
                {touched.name && errors.name && (
                  <div className="text-xs text-primaryPink mt-3">
                    {errors.name}
                  </div>
                )}
              </div>

              <FieldArray
                name="subCategories"
                render={(ArrayHelpers) => {
                  const { push, remove } = ArrayHelpers
                  const subcategoriesCount =
                    values.subCategories.length - deletedSubcategories.length

                  const handlePushItem = () =>
                    push({ name: "", active: true, desc: null })

                  const handleEnterKey = (e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault()
                      handlePushItem()
                    }
                  }

                  return (
                    <div>
                      <span className="pb-1 block font-semibold text-primaryBlue">
                        Subcategories ({subcategoriesCount})
                      </span>
                      {values.subCategories.map(
                        (subCategory: SubCategory, index: number) => {
                          const deletedIndex = deletedSubcategories.findIndex(
                            (deletedSubCategory) =>
                              deletedSubCategory.name === subCategory.name
                          )

                          const isDeleted = deletedIndex !== -1

                          const handleDeleteSubcategory = () => {
                            if (category) {
                              const deleted = [...deletedSubcategories]
                              deleted.push(subCategory)
                              return setDeletedSubcategories(deleted)
                            } else {
                              return remove(index)
                            }
                          }

                          const handleUndoDeleteSubcategory = () => {
                            const deleted = [...deletedSubcategories]
                            deleted.splice(deletedIndex, 1)
                            return setDeletedSubcategories(deleted)
                          }

                          return (
                            <SubcategoryItem
                              key={`subCategory-${index}`}
                              index={index}
                              isDeleted={isDeleted}
                              handleEnterKey={handleEnterKey}
                              handleDeleteSubcategory={handleDeleteSubcategory}
                              handleUndoDeleteSubcategory={
                                handleUndoDeleteSubcategory
                              }
                            />
                          )
                        }
                      )}

                      <button
                        type="button"
                        onClick={handlePushItem}
                        className="font-semibold mt-5 text-primaryGreen"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          size="sm"
                          className="mr-1"
                        />
                        Add item
                      </button>
                    </div>
                  )
                }}
              />
            </div>
            <footer className="flex justify-end items-center w-full border-t px-6 py-4">
              <button
                type="button"
                className="mr-6 font-semibold font-sansSemiBold"
                onClick={(e) => {
                  e.preventDefault()
                  onClose()
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isValid}
                className="button button--autoWidth button--primaryGreen"
              >
                Save
              </button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CategoriesSettingsSidebar
