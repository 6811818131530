import React, { ReactElement } from "react"
import * as Yup from "yup"
import { Formik, Form } from "formik"
// import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import FileInput from "components/forms/FileInput"
import FormikEffect from "components/forms/FormikEffect"

import { normalizeNullValues } from "services/helpers"

//@ts-ignore
import * as styles from "./EditableImportForm.module.css"

interface Props {
  isUploading: boolean
  onUpload: (ev: []) => Promise<void>
  infoText: ReactElement
  loadingText?: ReactElement
  exampleFile?: string
  disabled?: boolean
}

const SUPPORTED_FORMATS = [
  "text/csv",
  "application/csv",
  "application/vnd.ms-excel",
  "application/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]

const FILE_SIZE = 10485760 // MAX 10MB (binary bytes)

const Schema = Yup.object().shape({
  importFile: Yup.mixed()
    .required("A file is required")
    .test(
      "fileSize",
      "File size is too large",
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileType",
      "Unsupported file format",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
})

const EditableImportForm = ({
  isUploading,
  onUpload,
  exampleFile,
  infoText,
  disabled = false,
}: Props) => {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues({})}
      validationSchema={Schema}
    >
      {() => (
        <>
          <FormikEffect onChange={() => {}} onValidationChange={() => {}} />
          <Form className="flex  mb-6">
            <div className="flex justify-center w-full flex-col items-center">
              <h5 className={styles.title}>
                To get started, please upload a file
              </h5>
              <p className="text-sm text-gray-700 mb-6">
                Please upload a CSV, XLS or XLSX file
                {exampleFile && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={exampleFile}
                    className="text-primaryPink text-sm"
                  >
                    {" "}
                    (view example)
                  </a>
                )}
              </p>
              <div className="form-group">
                {!isUploading && (
                  <FileInput
                    name="importFile"
                    id="importFile"
                    accept={`.csv, .xls, .xlsx, ${SUPPORTED_FORMATS.join(
                      ", "
                    )}`}
                    disabled={disabled}
                    label="Select file"
                    onChange={onUpload}
                    placeholder="Select File"
                    isSingleFile
                  />
                )}
              </div>
              {infoText}
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default EditableImportForm
