import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { getMappedDefaultFeatures } from "services/features"
import { Permission } from "services/types"
import * as styles from "./PermissionWidget.module.css"
import { showError } from "services/toast"
import { faCheck, faBan } from "@fortawesome/pro-regular-svg-icons"

interface PermissionGroup {
  category: string
  features: Permission[]
}

interface PermissionWidgetProps {
  currentPermissions: any
}

const PermissionWidget = ({ currentPermissions }: PermissionWidgetProps) => {
  const [permissions, setPermissions] = useState<PermissionGroup[]>([])
  const [openCats, setOpenCats] = useState<string[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    const getDefaultPermissions = async () => {
      const params = {
        page: 0,
        size: 999,
        sort: "name,asc",
      }
      const res = await getMappedDefaultFeatures(params, {
        signal: abortController.signal,
      })
      if (res) {
        setPermissions(res)
        setOpenCats(res.map((r) => r.category))
      } else {
        showError("Could not get default permissions")
      }
    }

    getDefaultPermissions()
    return () => {
      abortController.abort()
    }
  }, [])

  const isOpenCat = (cat: string) => {
    return openCats.indexOf(cat) > -1
  }

  const toggleCat = (cat: string) => {
    const newList = isOpenCat(cat)
      ? openCats.filter((c) => c !== cat)
      : [...openCats, cat]
    setOpenCats(newList)
  }

  const permissionTypes = [
    { key: "read", label: "View" },
    { key: "modify", label: "Edit" },
    { key: "delete", label: "Delete" },
  ]

  return (
    <div>
      <header className={styles.header}>
        <span className={styles.featureColumn}>Feature</span>
        {permissionTypes.map((p) => (
          <span key={p.key} className={`${styles.permColumn} cursor-pointer`}>
            {p.label}
          </span>
        ))}
      </header>
      {permissions.map((perm) => (
        <div key={perm.category}>
          <div>
            <button
              type="button"
              className="text-xs w-full font-bold font-sansBold text-left rounded text-gray-800 hover:text-gray-700 px-1 py-2"
              onClick={() => toggleCat(perm.category)}
            >
              <FontAwesomeIcon
                icon={isOpenCat(perm.category) ? faChevronUp : faChevronDown}
                className="mr-2 text-xs"
              />
              {perm.category.replace(/_+/g, " ")}
            </button>
          </div>
          <div
            className={`pr-2 pl-6 pb-2 ${
              isOpenCat(perm.category) ? "" : "hidden"
            }`}
          >
            <ul className="">
              {perm.features.map((f) => {
                return (
                  <li
                    key={f.id}
                    className="py-1 flex items-center bg-white border-t"
                  >
                    <h3 className={styles.featureColumn}>{f.name}</h3>
                    {permissionTypes.map((p) => (
                      <div
                        key={`${f.name}_${p.key}`}
                        className={styles.permColumn}
                      >
                        <FontAwesomeIcon
                          icon={
                            currentPermissions.find((p) => p.name === f.name)
                              ?.permissions[p.key]
                              ? faCheck
                              : faBan
                          }
                        />
                      </div>
                    ))}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PermissionWidget
