import React, { useContext, useState } from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import BaseList from "components/baseList/BaseList"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import {
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/pro-duotone-svg-icons"
import { getMenuProfitabilityReportsDummy } from "services/dummy"
import usePagination from "hooks/usePagination"
import { getDiscrepancyReports } from "services/discrepancy-report"
import { showError } from "services/toast"
import usePermissions from "hooks/usePermissions"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import { usePrevious, useUpdateEffect } from "react-use"
// import DiscrepancyReportModal from "components/stock/DiscrepancyReportModal/DiscrepancyReportModal"
import DiscrepancyReportItem from "./DiscrepancyReportItem"
import ReportModal from "../ReportModal/ReportModal"
import { navigate } from "gatsby"
// import DateSelection from "components/forms/DateSelection"

export interface DiscrepancyReportProps {
  createdAt: string
  id: string
  insight: boolean
  name: string
  openingStockTakeReportId: string
  organizations: string[]
  periodFrom: string
  periodTo: string
  sales: string | null
  stockTakeReportId: string
  theoretical: boolean
  totalDiscrepancyAmount: number
  updatedAt: string | null
}

const DiscrepancyReports = () => {
  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const modal = useContext(ModalContext)

  const [reportsData, setReportsData] = React.useState<
    DiscrepancyReportProps[] | null
  >(null)
  const [loading, setLoading] = useState<boolean>(false)

  // const [selectedPeriod, setSelectedPeriod] = useState({
  //   from: undefined,
  //   to: undefined,
  //   enteredTo: undefined,
  //   inUserTimezone: {
  //     from: undefined,
  //     to: undefined,
  //     enteredTo: undefined,
  //   },
  // } as any)

  const permissionObj = usePermissions("Sales") // TODO
  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)

  const openModal = () => {
    modal.showModal(ReportModal, {
      endpoint: "/stock-take/discrepancy-report",
      iconConfig: {
        mainIcon: {
          icon: faExclamationTriangle,
          color: "#f2691c",
          backgroundColor: "#feefdd",
        },
        subIcon: { icon: faSyncAlt, color: "#35ccc3" },
      },
      title: "New Discrepancy Report",
      onCreate: (reportId: string) =>
        navigate(`/dashboard/stock/stocktake/discrepancy-report/${reportId}`),
      insights: true,
    })
  }

  const getData = async () => {
    if (
      // @ts-ignore
      organizationPermissions?.general?.isMain &&
      // @ts-ignore
      selectedOrganizations.length === 0
    ) {
      setReportsData([])
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: "createdAt,desc",
    }

    // @ts-ignore
    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    // if (selectedPeriod.from) {
    //   params["from"] = selectedPeriod.inUserTimezone.from
    // }

    // if (selectedPeriod.to) {
    //   params["to"] = selectedPeriod.inUserTimezone.enteredTo
    // }

    const res = await getDiscrepancyReports(params)

    if (res?.message) {
      showError(res.message)
    } else {
      setReportsData(res.content)
      pagination.setFromResult(res)
    }

    setLoading(false)
  }

  useUpdateEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  useUpdateEffect(() => {
    getData()
  }, [
    selectedOrganizations,
    // selectedPeriod.inUserTimezone.from,
    // selectedPeriod.inUserTimezone.enteredTo,
  ])

  return (
    <>
      <Helmet>
        <title>Stock Discrepancy</title>
      </Helmet>
      <div className="w-full flex flex-col h-full overflow-hidden">
        <Header title="Stock Discrepancy" />

        <div className="flex justify-between px-8 py-4 border-b flex-row-reverse flex-wrap gap-y-2">
          <button
            type="button"
            // @ts-ignore
            disabled={!permissionObj?.permissions.modify}
            className="button button--autoWidth button--primaryGreen"
            onClick={openModal}
          >
            <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
            <span className="hidden lg:inline">New Report</span>
          </button>
          <div className="flex items-center gap-x-4">
            <LocationsSelector />

            {/* <DateSelection
              value={selectedPeriod}
              onChange={setSelectedPeriod}
            /> */}
          </div>
        </div>

        <div className="px-0 pb-6 -mt-px relative flex-grow w-full h-full overflow-x-hidden overflow-y-auto lg:px-8 lg:py-6">
          <BaseList
            title="Discrepancy Reports"
            id="discrepancy-reports"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faExclamationTriangle,
              color: "orange",
              getDummy: () => getMenuProfitabilityReportsDummy, // TODO
            }}
            data={reportsData || []}
            rowRender={(report) => {
              // console.log("report", report)
              return (
                <DiscrepancyReportItem
                  key={report.id}
                  item={report}
                  onDelete={getData}
                />
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

export default DiscrepancyReports
