import React from "react"
import { Formik, Form } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import * as Yup from "yup"
import { normalizeNullValues } from "services/helpers"
import PropTypes from "prop-types"
import TextInput from "../../forms/TextInput"

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, "equalTo", equalTo)

const Schema = Yup.object().shape({
  username: Yup.string().required("Email is required"),
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .min(6, "Minimum of 6 characters")
    .required("Password is required"),
  confirmNewPassword: Yup.string()
    .required("Confirm your password")
    .equalTo(Yup.ref("newPassword"), "Passwords do not match"),
})

const ChangePasswordForm = ({ onDataChange, onValidChange, initialValues }) => {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues(initialValues)}
      validationSchema={Schema}
    >
      {() => (
        <>
          <FormikEffect
            onChange={onDataChange}
            onValidationChange={onValidChange}
          />
          <Form className="flex flex-col mb-4">
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  disabled={true}
                  name="username"
                  autoComplete="username"
                  label="Email"
                  type="text"
                  placeholder="Email *"
                  className="form-control form-control--first"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="oldPassword"
                  autoComplete="current-password"
                  label="Old password *"
                  type="password"
                  placeholder="Old password *"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="newPassword"
                  label="New password *"
                  autoComplete="new-password"
                  type="password"
                  placeholder="New password *"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="confirmNewPassword"
                  label="Confirm password *"
                  autoComplete="new-password"
                  type="password"
                  placeholder="Confirm password *"
                  className="form-control form-control--last"
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

ChangePasswordForm.propTypes = {
  onDataChange: PropTypes.func,
  onValidChange: PropTypes.func,
  initialValues: PropTypes.object,
}

export default ChangePasswordForm
