import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { normalizeNullValues } from "services/helpers"
import { getUserPermissions, updateUserPermissions } from "services/user"
import { showError, showSuccess } from "services/toast"
import PermissionMatrix from "components/auth/PermissionMatrix/PermissionMatrix"
import UserAvatar from "components/common/UserAvatar/UserAvatar"
import { format } from "date-fns"

const Schema = Yup.object().shape({
  permissionsPerFeatures: Yup.object().nullable(),
})

const UserPermissionsForm = ({ user, onClose }) => {
  const [defaultValues, setDefaultValues] = useState<{
    permissionsPerFeatures: {}
  }>({
    permissionsPerFeatures: {},
  })

  useEffect(() => {
    const abortController = new AbortController()
    const getDefaultPermissions = async () => {
      const res = await getUserPermissions(user.username, {
        signal: abortController.signal,
      })

      if (res.permissionsPerFeatures) {
        const newObj = { ...defaultValues }
        res.permissionsPerFeatures.forEach((i) => {
          newObj.permissionsPerFeatures[i.id] = {
            name: i.name,
            permissions: i.permissions,
          }
        })
        setDefaultValues(newObj)
      } else {
        showError("Could not get default permissions")
      }
    }

    getDefaultPermissions()
    return () => {
      abortController.abort()
    }
  }, [])

  const handleSave = (data) => {
    const params = {
      permissionsPerFeatures: Object.keys(data.permissionsPerFeatures).map(
        (k) => {
          return {
            id: k,
            permissions: data.permissionsPerFeatures[k].permissions,
          }
        }
      ),
    }

    updateUserPermissions(user.username, params)
      .then((res) => {
        if (!res.error) {
          showSuccess(`Permissions updated for ${user.username}`)
          onClose()
        } else {
          showError(
            res.message || "Update failed. Did you enter all required fields?"
          )
        }
      })
      .catch((err) => {
        showError(
          err.message || "Update failed. Did you enter all required fields?"
        )
      })
  }

  return (
    <div className="overflow-hidden flex flex-col flex-grow">
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={normalizeNullValues(defaultValues)}
        validationSchema={Schema}
        onSubmit={handleSave}
      >
        {({ isValid, values, setValues }) => (
          <Form className="overflow-hidden flex flex-col flex-grow">
            {/* <div className="flex-shrink-0 py-3 px-4 border-b relative">
              <h2 className="text-xl text-primaryBlue">
                Edit user permissions
              </h2>

              <button
                type="button"
                className="absolute  text-xl top-0 right-1 p-3 text-gray-700"
                onClick={onClose}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div> */}
            <div className="flex-grow overflow-auto">
              <div className="flex px-4 py-3 border-b">
                <UserAvatar user={user} />
                <div className="flex flex-col ml-2">
                  <span className="font-semibold font-sansSemiBold text-sm text-primaryBlue">
                    {user.firstName} {user.lastName}
                  </span>
                  <span className="text-xs text-gray-700">{user.username}</span>
                  {!user.active && user.invitationDate && (
                    <span className="text-xs font-sansSemiBold font-semibold text-primaryBlue">
                      Invite sent at{" "}
                      {format(new Date(user.invitationDate), "dd/MM/yyyy")}
                    </span>
                  )}
                </div>
              </div>
              <div className="row px-4 py-3">
                <PermissionMatrix
                  values={values}
                  setValues={setValues}
                  name={`permissionsPerFeatures`}
                />
              </div>
            </div>

            <div className="w-full py-3 px-4 border-t flex items-center justify-end flex-shrink-0">
              <button
                type="submit"
                className="button button--primaryGreen"
                style={{ width: "fit-content" }}
                disabled={!isValid}
              >
                Save changes
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UserPermissionsForm
