import React, { useState, useEffect, useContext } from "react"
import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import StockTake from "components/stock/StockTake/StockTake"
import {
  getDiscrepancyReport,
  // updateDiscrepancyReportById,
  getStockTake,
  downloadDiscrepancyReport,
  setStockTakeContextData,
  updateReportById,
} from "services/stock-take/stock-take"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/pro-duotone-svg-icons"
import {
  faFileDownload,
  faSync,
  faEllipsisH,
  faExternalLinkSquare,
} from "@fortawesome/pro-regular-svg-icons"
import { roundNumber, slugify } from "services/helpers"
import { ModalContext } from "context/modal/ModalContext"
import { GlobalDispatchContext } from "context/global/GlobalContextProvider"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import SearchInput from "components/forms/SearchInput"
import { useMediaQuery } from "react-responsive"
import Helmet from "react-helmet"
import DiscrepancyReportContentCategorised from "../DiscrepancyReportContentCategorised/DiscrepancyReportContentCategorised"

import * as styles from "./DiscrepancyReport.module.css"
import usePermissions from "hooks/usePermissions"
import { showError, showSuccess } from "services/toast"
import { getExportFileName } from "services/export"
import { Permission } from "services/types"
import ReportNameCell from "components/baseList/cells/ReportName"
import ReportModal from "../ReportModal/ReportModal"

interface DiscrepancyReportProps {
  discrepancyReportId: string
}

const DiscrepancyReport = ({ discrepancyReportId }: DiscrepancyReportProps) => {
  const [discrepancyData, setDiscrepancyData] = useState({} as any)
  const [loading, setLoading] = useState(true)
  const [generating, setGenerating] = useState(false)
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [query, setQuery] = useState("")
  const discrepancyReportPermissionObj = usePermissions(
    "Stock discrepancy report"
  ) as Permission
  const stockTakesPermissionObj = usePermissions("Stocktakes") as Permission

  const DEFAULT_ERROR_MESSAGE = "Something went wrong!"

  const reportActions = [
    ...(isTabletOrMobile
      ? [
          {
            title: "View stocktake",
            key: "stocktake.view",
            disabled: !stockTakesPermissionObj?.permissions.read,
          },
        ]
      : []),
    {
      title: "Export to .CSV",
      key: "export.csv",
      disabled: !discrepancyReportPermissionObj?.permissions.read,
    },
    {
      title: "Export to .XLSX",
      key: "export.xlsx",
      disabled: !discrepancyReportPermissionObj?.permissions.read,
    },
    // { title: "Export to .PDF", key: "export.pdf" },
  ]

  const handleError = (result) => {
    if (result.status === 403) {
      // do nothing, message comes from api.js
    } else if (result.status?.toString().charAt(0) === "4") {
      let errorText = DEFAULT_ERROR_MESSAGE
      if (result?.errors?.length > 0) {
        errorText = result.errors[0].defaultMessage
      } else if (result.message) {
        errorText = result.message
      }
      showError(errorText)
    } else {
      showError(DEFAULT_ERROR_MESSAGE)
    }
  }

  const getData = async (regenerate = false) => {
    if (!regenerate) {
      setLoading(true)
    }

    if (regenerate) {
      const {
        openingStockTakeReportId,
        stockTakeReportId,
        theoretical,
        insights,
        filterCategories,
        periodTo,
        sales,
      } = discrepancyData

      const params = {} as any

      if (filterCategories) {
        params.filterCategories = filterCategories
      }

      const updated = await updateReportById(
        "/stock-take/discrepancy-report",
        discrepancyReportId,
        stockTakeReportId,
        openingStockTakeReportId,
        sales ? sales.id : null,
        insights,
        theoretical,
        stockTakeReportId ? undefined : periodTo, // either (closing) stocktakeReportId or periodTo needs to be provided
        params
      )

      if (
        !updated ||
        [400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
          updated.status
        )
      ) {
        handleError(updated)
      } else {
        showSuccess("Report refreshed!", { position: "top-center" })
      }
    }

    const getParams = {} as any

    if (query) {
      getParams.partialProductName = query
    }

    // No organizationGroup here? --> see COGSReport content

    const result = await getDiscrepancyReport(discrepancyReportId, getParams)

    if (
      result &&
      ![400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
        result.status
      )
    ) {
      setDiscrepancyData(result)
      setLoading(false)
      setGenerating(false)
    } else {
      handleError(result)
    }

    setLoading(false)
    setGenerating(false)
  }

  // This will be called onMount as well, so no extra mounted useEffect needed
  useEffect(() => {
    getData()
  }, [query])

  const regenerate = () => {
    setGenerating(true)
    getData(true)
  }

  const handleActionClick = ({ type }) => {
    const [action, ext] = type.split(".")

    if (type === "stocktake.view") {
      return openStockTake()
    }
    if (action == "export") {
      const discrepancyReportname = slugify(discrepancyData.name)
      const fileName = getExportFileName(
        "discrepancy-report",
        "for-" + discrepancyReportname,
        ext
      )
      downloadDiscrepancyReport(discrepancyReportId, ext, fileName)
    }
  }

  const openStockTake = async () => {
    const stocktake = await getStockTake(discrepancyData.stockTakeReportId)
    setStockTakeContextData(
      stocktake,
      dispatch,
      discrepancyData.totalDiscrepancyAmount,
      discrepancyData.id
    )
    return modal.showModal(StockTake, { onUpdate: () => regenerate() })
  }

  const onEditStocktakeReport = () => {
    modal.showModal(ReportModal, {
      endpoint: "/stock-take/discrepancy-report",
      currentReportId: discrepancyReportId,
      stockTakeReportId: discrepancyData.stockTakeReportId,
      title: "Edit stocktake report",
      iconConfig: {
        mainIcon: {
          icon: faExclamationTriangle,
          color: "#f2691c",
          backgroundColor: "#feefdd",
        },
        subIcon: { icon: faSyncAlt, color: "#35ccc3" },
      },
      loadingText: "Updating Stock Discrepancy Report",
      currentReportData: discrepancyData,
      insights: discrepancyData.insights,
      isUpdate: true,
      onUpdate: () => getData(),
    })
  }

  return (
    <>
      <Helmet>
        <title>Discrepancy report</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Discrepancy report" />

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading report..
          </Loader>

          <div className={styles.header}>
            <div className={styles.headerContent}>
              <div className={styles.headerTopColumn}>
                <ReportNameCell
                  data={discrepancyData}
                  iconStyles={{
                    mainIcon: {
                      backgroundColor: "#feefdd",
                      color: "#f2691c",
                    },
                    subIcon: {
                      color: "#35ccc3",
                    },
                  }}
                  editEnabled={
                    discrepancyReportPermissionObj?.permissions.modify
                  }
                  disabled={!discrepancyReportPermissionObj?.permissions.modify}
                  onEdit={onEditStocktakeReport}
                  mainIcon={faExclamationTriangle}
                  subIcon={faSyncAlt}
                />

                <button
                  type="button"
                  disabled={!stockTakesPermissionObj?.permissions.modify}
                  className="items-center text-orange-600 rounded hover:text-orange-500 font-sansSemiBold font-semibold px-0 lg:px-2 py-2 flex-shrink-0"
                  onClick={regenerate}
                >
                  <FontAwesomeIcon
                    icon={faSync}
                    spin={generating}
                    className="mr-2 block"
                  />
                </button>

                <div className="flex flex-grow gap-x-3 items-center">
                  <div className="my-2 flex-grow">
                    <SearchInput
                      label="Search by products"
                      placeholder="Search by products"
                      className="form-control rounded"
                      onSearchChange={setQuery}
                    />
                  </div>

                  {discrepancyData.stockTakeReportId && !isTabletOrMobile && (
                    <button
                      className="button button--autoWidth ml-auto button--paleBlue"
                      onClick={openStockTake}
                      disabled={!stockTakesPermissionObj?.permissions.read}
                    >
                      Stocktake{" "}
                      <FontAwesomeIcon
                        icon={faExternalLinkSquare}
                        className="ml-2"
                      />
                    </button>
                  )}
                  <DropdownButton
                    onActionClick={handleActionClick}
                    label={<FontAwesomeIcon icon={faFileDownload} />}
                    mobileIcon={faEllipsisH}
                    options={reportActions}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.reportContent}>
            <DiscrepancyReportContentCategorised data={discrepancyData} />
          </div>
        </div>
        {!loading && (
          <div className={styles.bottomSummary}>
            <div className={styles.summaryItem}>
              <span className="mr-1">Total value:</span>
              <span
                className={`font-sansSemiBold font-semibold ${
                  discrepancyData.totalDiscrepancyAmount < 0
                    ? "text-primaryPink"
                    : discrepancyData.totalDiscrepancyAmount > 0
                    ? "text-primaryGreen"
                    : ""
                }`}
              >
                {roundNumber(discrepancyData.totalDiscrepancyAmount)}
              </span>
            </div>
            {discrepancyData.theoretical && (
              <div className={`${styles.summaryItem} ml-auto`}>
                <span
                  className={`font-sansSemiBold font-semibold text-sm text-error `}
                >
                  <span className="mr-1">*</span>Theoretical stock included
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default DiscrepancyReport
