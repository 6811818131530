import { isBrowser } from "./helpers"
import { setOrganization, isLoggedIn } from "./auth"
import { get, post, put } from "./api"

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT

export const getOrganizationById = (organizationId) => {
  return get(`/organizations/${organizationId}`)
}

export const getLocalOrganization = () => {
  return new Promise((resolve) => {
    if (!isBrowser()) {
      resolve(null)
    }
    const organization = window.localStorage.getItem(
      `growyzeOrganization_${ENVIRONMENT}`
    )
    if (organization) {
      resolve(JSON.parse(organization))
    } else {
      resolve(null)
    }
  })
}

export const getMyOrganization = async (force = false) => {
  if (!force && !isLoggedIn()) {
    return Promise.resolve(null)
  }
  const organization = await getLocalOrganization()
  if (organization && !force) {
    return Promise.resolve(organization)
  } else {
    return get(`/organizations/my`)
  }
}

export const createOrganization = (roleOfUser, params) => {
  return post(`/organizations?roleOfUser=${roleOfUser}`, params)
}

export const createSubOrganization = (params) => {
  //address, deliveryAddress, companyName
  return post(`/organizations/sub`, params)
}

export const updateMyOrganization = async (params) => {
  const updateResult = await put(`/organizations/my`, params)
  if (updateResult.error) {
    return updateResult
  }

  setOrganization(updateResult)

  return updateResult
}

export const getMyOrganizationConnections = async (params) => {
  const withInitialParams = {
    page: 0,
    size: 100,
    sort: "name",
    ...params,
  }

  return await get(`/organizations/my/connections`, withInitialParams)
}

export const getExtendedOrgRequestCounter = async () => {
  return await get(`/org-request-counter/me`)
}

export const getApiKey = async () => {
  return await get("/api-key/auth")
}

export const createApiKey = async () => {
  return await post("/api-key/auth")
}
