import React, { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
//@ts-ignore
import * as styles from "./Loader.module.css"

const Loader: React.FC<{
  isLoading: boolean
  children?: any
  [x: string]: any
}> = ({ children, isLoading, ...restProps }) => {
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  return (
    <CSSTransition in={loading} timeout={300} classNames="loader" unmountOnExit>
      <div className={styles.loaderContainer} {...restProps}>
        <svg
          className={styles.loader}
          viewBox="0 0 73 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.41833 36.008C8.75606 33.6194 11.4315 27.6479 17.877 21.4376C22.1334 17.377 26.7547 13.7941 32.1056 11.6444C37.4566 9.49465 43.1723 8.41979 49.0097 8.30036C51.9284 8.18093 62.1438 8.30036 71.0215 10.9278C71.2648 9.49465 71.508 7.82264 71.6296 6.50891L72.116 2.92602L68.5893 1.97059C67.6164 1.6123 66.8867 1.37344 66.0354 1.25401C65.1841 1.13458 64.3329 0.895722 63.6032 0.776292C61.9006 0.537433 60.3197 0.298574 58.6171 0.179144C55.3336 -0.0597148 51.9284 -0.0597148 48.6449 0.179144C41.9562 0.776292 35.1459 2.44831 29.1869 5.67291C23.2279 8.77807 10.337 16.7799 3.89157 33.6194C2.55384 37.3217 6.68865 37.3217 7.41833 36.008Z"
            fill="url(#animated_paint0_linear)"
          />
          <path
            d="M66.2787 13.0775C63.6032 12.4804 61.171 12.3609 60.4413 12.3609C59.9548 13.3164 59.4684 14.2718 58.9819 15.2273C57.5226 17.7353 55.9416 20.2433 54.2391 22.393C52.5365 24.4233 50.8339 26.3342 48.7665 28.0062C46.8207 29.6782 44.6317 30.992 42.3211 32.0668C40.0104 33.1417 37.4566 33.7388 34.9027 34.0971C32.4705 34.336 30.0382 34.0971 28.214 32.664C23.5928 28.9617 26.3899 23.3485 26.7547 22.0347C26.9979 21.1987 25.1737 20.4822 24.5657 20.8404C23.9576 21.3182 23.7144 21.557 23.3496 22.2736C22.6199 23.5873 22.1334 25.1399 22.0118 26.6925C21.7686 29.7977 22.9847 33.2611 25.5386 35.7691C27.9708 38.2772 32.7137 43.0544 51.0771 39.9492C47.5504 46.279 43.0507 52.131 37.2133 55.8333C33.6866 58.1025 29.6734 59.5356 25.417 60.0134C21.1605 60.4911 16.6609 60.0134 12.1612 58.8191C7.29673 57.5053 4.62126 54.7585 2.67547 52.8476C1.58096 51.7727 0 52.8476 0 53.803C0.243224 57.147 4.86449 61.2077 10.4587 63.9545C15.0799 66.2237 20.4309 67.1791 25.9034 67.0597C31.376 67.0597 36.8485 65.5071 41.713 62.8797C46.4559 60.2522 50.4691 56.5499 53.8742 52.4893C57.1578 48.4287 59.8332 44.0098 62.1439 39.5909C64.3329 35.0526 66.1571 30.3948 67.6164 25.6176C68.3461 23.229 69.0758 20.8404 69.5622 18.4519C69.927 17.0187 70.1703 15.5855 70.5351 14.1524C69.0758 13.7941 67.6164 13.4358 66.2787 13.0775Z"
            fill="url(#animated_paint1_linear)"
          />
          <defs>
            <linearGradient
              id="animated_paint0_linear"
              x1="38.5177"
              y1="36.8058"
              x2="40.1118"
              y2="0.289373"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3EAFAE">
                <animate
                  attributeName="stop-color"
                  values="#3EAFAE; #24247B; #FA5E80; #3EAFAE"
                  dur="5s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="0.744792" stopColor="#35CCC3">
                <animate
                  attributeName="stop-color"
                  values="#35CCC3; #000055; #FC3762; #35CCC3"
                  dur="5s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
            </linearGradient>
            <linearGradient
              id="animated_paint1_linear"
              x1="35.933"
              y1="67.0696"
              x2="62.0089"
              y2="2.11609"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3EAFAE" stopOpacity="0.75">
                <animate
                  attributeName="stop-color"
                  values="#3EAFAE; #24247B; #FA5E80; #3EAFAE"
                  dur="5s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="0.744792" stopColor="#35CCC3">
                <animate
                  attributeName="stop-color"
                  values="#35CCC3; #000055; #FC3762; #35CCC3"
                  dur="5s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
            </linearGradient>
          </defs>
        </svg>
        <span className={styles.loaderMessage}>{children}</span>
      </div>
    </CSSTransition>
  )
}

export default Loader
