// extracted by mini-css-extract-plugin
export var backIcon = "DeliveryNote-module--backIcon--IHx2V";
export var camera = "DeliveryNote-module--camera--7airE";
export var clickArea = "DeliveryNote-module--clickArea--Pdz3k";
export var confirm = "DeliveryNote-module--confirm--hAWnL";
export var confirmContainer = "DeliveryNote-module--confirmContainer--pBN9k";
export var confirmImg = "DeliveryNote-module--confirmImg--BGI5J";
export var confirmPdf = "DeliveryNote-module--confirmPdf--Lyn0Q";
export var container = "DeliveryNote-module--container--i1mOF";
export var content = "DeliveryNote-module--content--8kjLP";
export var contentConfirm = "DeliveryNote-module--content--confirm--DFgAp";
export var displayIntro = "DeliveryNote-module--displayIntro--InZfH";
export var flash = "DeliveryNote-module--flash--yfTst";
export var header = "DeliveryNote-module--header--x1Miy";
export var headerMain = "DeliveryNote-module--headerMain--hxIjJ";
export var navBar = "DeliveryNote-module--navBar--RU9Bc";
export var orderSelect = "DeliveryNote-module--orderSelect--pQ70G";
export var orderSelectContainer = "DeliveryNote-module--orderSelectContainer--NLE3j";
export var pdfIcon = "DeliveryNote-module--pdfIcon--vZUAr";
export var placeHolder = "DeliveryNote-module--placeHolder--HK4Hf";
export var rotate = "DeliveryNote-module--rotate--O3mff";
export var scanPending = "DeliveryNote-module--scanPending--h+O0P";
export var scanPendingAnimation = "DeliveryNote-module--scanPendingAnimation--nuQUj";
export var scanPendingButton = "DeliveryNote-module--scanPendingButton--VKQNf";
export var scanPendingText = "DeliveryNote-module--scanPendingText--qP2Rh";
export var scanning = "DeliveryNote-module--scanning--yNrsY";
export var spinning = "DeliveryNote-module--spinning--ebIeE";
export var step2 = "DeliveryNote-module--step2--EAj3d";
export var step3 = "DeliveryNote-module--step3--teRHY";
export var step4 = "DeliveryNote-module--step4--kP5Rg";
export var title = "DeliveryNote-module--title--NKQa+";
export var transparent = "DeliveryNote-module--transparent--hyAhX";