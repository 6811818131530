import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, FieldArray } from "formik"
import React, { useEffect, useState } from "react"
import { getMappedDefaultFeatures } from "services/features"
import { showError } from "services/toast"
import { Permission } from "services/types"
import { forcedReadPermissions } from "services/constants"
import classNames from "classnames/bind"
import * as styles from "./PermissionMatrix.module.css"

const cx = classNames.bind(styles)

interface PermissionGroup {
  category: string
  features: Permission[]
}

interface PermissionMatrixProps {
  values: any
  name: string
  setValues(params: any): void
}

const PermissionMatrix = ({
  values,
  name,
  setValues,
}: PermissionMatrixProps) => {
  const [permissions, setPermissions] = useState<PermissionGroup[]>([])
  const [openCats, setOpenCats] = useState<string[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    const getDefaultPermissions = async () => {
      const params = {
        page: 0,
        size: 999,
        sort: "name,asc",
      }
      const res = await getMappedDefaultFeatures(params, {
        signal: abortController.signal,
      })
      if (res) {
        setPermissions(res)
        setOpenCats(res.map((r) => r.category))
      } else {
        showError("Could not get default permissions")
      }
    }

    getDefaultPermissions()
    return () => {
      abortController.abort()
    }
  }, [])

  const isOpenCat = (cat: string) => {
    return openCats.indexOf(cat) > -1
  }

  const toggleCat = (cat: string) => {
    const exists = isOpenCat(cat)
    let newList = [...openCats]
    if (exists) {
      newList = newList.filter((c) => c !== cat)
    } else {
      newList = [...newList, cat]
    }
    setOpenCats(newList)
  }

  const onPermissionChange = (
    fieldId: string,
    permissionType: "modify" | "delete"
  ) => {
    const newValues = values
    if (values[name][fieldId]?.permissions[permissionType]) {
      newValues[name][fieldId].permissions[permissionType] = false
      setValues(newValues)
    } else {
      newValues[name][fieldId].permissions.read = true
      newValues[name][fieldId].permissions[permissionType] = true
      setValues(newValues)
    }
  }

  const columnIsFullyChecked = (
    permissionType: "read" | "modify" | "delete"
  ) => {
    const totalCount = values && Object.keys(values[name]).length
    const checkedCount =
      values &&
      Object.keys(values[name])
        .map((key) => values[name][key])
        .filter((p) => p.permissions[permissionType] === true).length
    return totalCount === checkedCount
  }

  const toggleColumn = (
    permissionType: "read" | "modify" | "delete",
    setChecked = false
  ) => {
    const newValues = values
    if (columnIsFullyChecked(permissionType) && !setChecked) {
      Object.entries(newValues[name]).forEach(([k, v]: [string, any]) => {
        const isReadPermission = permissionType === "read"

        const isForcedReadPermission =
          forcedReadPermissions.indexOf(v.name) > -1 && isReadPermission

        const hasCheckedEditOrDelete =
          isReadPermission &&
          (newValues[name][k].permissions["modify"] ||
            newValues[name][k].permissions["delete"])

        if (isForcedReadPermission || hasCheckedEditOrDelete) {
          newValues[name][k].permissions[permissionType] = true
        } else {
          newValues[name][k].permissions[permissionType] = false
        }
      })
      setValues(newValues)
    } else {
      Object.keys(newValues[name]).forEach((k: string) => {
        newValues[name][k].permissions[permissionType] = true
      })
      setValues(newValues)

      // When toggle edit or delete, toggle modify too
      if (permissionType === "delete" || permissionType === "modify") {
        toggleColumn("read", true)
      }
    }
  }

  return (
    <div>
      <FieldArray
        name={name}
        render={() => (
          <>
            <header className={styles.header}>
              <span className={styles.featureColumn}>Feature</span>
              <label
                htmlFor="allViewPermissions"
                className={`${styles.permColumn} cursor-pointer`}
              >
                <input
                  type="checkbox"
                  className="mb-1 form-checkbox text-primaryBlueLighter cursor-pointer"
                  id="allViewPermissions"
                  name="allViewPermissions"
                  checked={columnIsFullyChecked("read")}
                  disabled={
                    columnIsFullyChecked("delete") ||
                    columnIsFullyChecked("modify")
                  }
                  onChange={() => {
                    toggleColumn("read")
                  }}
                />
                View
              </label>
              <label
                htmlFor="allModifyPermissions"
                className={`${styles.permColumn} cursor-pointer`}
              >
                <input
                  type="checkbox"
                  className="mb-1 form-checkbox text-primaryBlueLighter cursor-pointer"
                  id="allModifyPermissions"
                  name="allModifyPermissions"
                  checked={columnIsFullyChecked("modify")}
                  onChange={() => {
                    toggleColumn("modify")
                  }}
                />
                Edit
              </label>
              <label
                htmlFor="allDeletePermissions"
                className={`${styles.permColumn} cursor-pointer`}
              >
                <input
                  type="checkbox"
                  className="mb-1 form-checkbox text-primaryBlueLighter cursor-pointer"
                  id="allDeletePermissions"
                  name="allDeletePermissions"
                  checked={columnIsFullyChecked("delete")}
                  onChange={() => {
                    toggleColumn("delete")
                  }}
                />
                Delete
              </label>
            </header>
            {permissions.map((perm) => (
              <div key={perm.category}>
                <div>
                  <button
                    type="button"
                    className="text-xs w-full font-bold font-sansBold text-left rounded text-gray-800 hover:text-gray-700 px-1 py-2"
                    onClick={() => toggleCat(perm.category)}
                  >
                    <FontAwesomeIcon
                      icon={
                        isOpenCat(perm.category) ? faChevronUp : faChevronDown
                      }
                      className="mr-2 text-xs"
                    />
                    {perm.category.replace(/_+/g, " ")}
                  </button>
                </div>
                <div
                  className={`pr-2 pl-6 pb-2 ${
                    isOpenCat(perm.category) ? "" : "hidden"
                  }`}
                >
                  <ul className="">
                    {perm.features.map((f) => {
                      return (
                        <li
                          key={f.id}
                          className="py-1 flex items-center bg-white border-t"
                        >
                          <h3 className={styles.featureColumn}>{f.name}</h3>
                          <div className={styles.permColumn}>
                            <Field
                              type="checkbox"
                              className={cx("field", "form-checkbox")}
                              disabled={
                                forcedReadPermissions.indexOf(f.name) > -1 ||
                                values[name][`${f.id}`]?.permissions?.modify ||
                                values[name][`${f.id}`]?.permissions?.delete
                              }
                              name={`${name}[${f.id}].permissions.read`}
                            />
                          </div>
                          <div className={styles.permColumn}>
                            <Field
                              type="checkbox"
                              className={cx("field", "form-checkbox")}
                              name={`${name}[${f.id}].permissions.modify`}
                              onChange={() =>
                                onPermissionChange(`${f.id}`, "modify")
                              }
                            />
                          </div>
                          <div className={styles.permColumn}>
                            <Field
                              type="checkbox"
                              className={cx("field", "form-checkbox")}
                              name={`${name}[${f.id}].permissions.delete`}
                              onChange={() =>
                                onPermissionChange(`${f.id}`, "delete")
                              }
                            />
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            ))}
          </>
        )}
      />
    </div>
  )
}

export default PermissionMatrix
