// extracted by mini-css-extract-plugin
export var active = "DocumentCamera-module--active--k8c65";
export var blur = "DocumentCamera-module--blur--UO4WB";
export var btnCapture = "DocumentCamera-module--btnCapture--ECDbU";
export var camera = "DocumentCamera-module--camera--Y1pWF";
export var displayIntro = "DocumentCamera-module--displayIntro--nEprC";
export var displayIntroContainer = "DocumentCamera-module--displayIntroContainer--sRKgt";
export var intro = "DocumentCamera-module--intro--2TTPv";
export var introContainer = "DocumentCamera-module--introContainer--M8x75";
export var introIcon = "DocumentCamera-module--introIcon--+DVW1";
export var moveCamera = "DocumentCamera-module--moveCamera--IM10r";
export var notSupported = "DocumentCamera-module--notSupported--RSIaE";
export var notSupportedBtn = "DocumentCamera-module--notSupportedBtn--vFiqr";
export var scanAction = "DocumentCamera-module--scanAction--qgP9o";
export var scanActions = "DocumentCamera-module--scanActions--GXxgZ";
export var scanBtn = "DocumentCamera-module--scanBtn--KOEUT";