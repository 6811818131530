import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import Calendar from "components/common/Calendar/Calendar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditDetails.module.css"

const EditDetails = ({ note, onSave, isSaving }) => {
  const inputRef = useRef(null)
  const [number, setNumber] = useState(note ? note.deliveryNoteNumber : "")
  const [date, setDate] = useState(note.deliveryDate ? note.deliveryDate : "")

  //
  // Handlers
  //

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setNumber(value)
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    window.setTimeout(() => {
      const el = inputRef.current
      if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
      } else if (typeof el.createTextRange != "undefined") {
        el.focus()
        var range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="px-2 w-full sm:w-1/2">
          <label className="block text-gray-700 mb-3 w-full">
            Delivery note number
          </label>
          <input
            ref={inputRef}
            type="text"
            className={`block border-0 px-0 form-control ${styles.input}`}
            onFocus={setCursorAtTheEnd}
            value={number || ""}
            onChange={handleInputChange}
            placeholder={`Add number or leave empty`}
          />
        </div>
        <div className="px-2 w-full sm:w-1/2">
          <label className="block text-gray-700 mb-3 w-full">
            Delivery date
          </label>
          <Calendar onSelect={(date) => setDate(date)} selectedDate={date} />
        </div>
      </div>
      <div className={styles.footer}>
        <button
          type="button"
          onClick={() => onSave({ number, date })}
          className="button button--primaryBlue"
          disabled={isSaving}
        >
          {isSaving && (
            <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
          )}
          Save
        </button>
      </div>
    </div>
  )
}

EditDetails.propTypes = {
  note: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

export default EditDetails
