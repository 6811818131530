import { faMegaphone } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useMediaQuery } from "react-responsive"

function ReferralButton({ collapsed = false }) {
  const referalLink = "https://www.growyze.com/referral"
  const isMobile = useMediaQuery({ maxWidth: 1023 })

  return (
    <a
      href={referalLink}
      rel="noreferrer"
      target="_blank"
      style={{ height: "40px" }}
      className={`
          group flex relative
          text-white
          ${
            isMobile
              ? "bg-primaryBlue hover:bg-primaryBlueLighter"
              : "bg-primaryPink hover:bg-primaryPinkLighter"
          }
          flex-row drop-shadow-md shadow-md items-center text-xs rounded-full
          py-2 flex-grow justify-center px-2
        `}
    >
      <FontAwesomeIcon
        icon={faMegaphone}
        className="text-base mt-1"
        style={{ transformOrigin: "50% 50%", rotate: "-25deg" }}
      />
      {!collapsed && (
        <div className="inline-flex pl-3 justify-start">
          <span className="font-bold text-sm">Refer a venue, Get £100</span>
        </div>
      )}
    </a>
  )
}

export default ReferralButton
