import React from "react"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import {
  faPencil,
  faTimes,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/pro-regular-svg-icons"
import { stockTransferStates } from "services/constants"
import usePermissions from "hooks/usePermissions"

import * as styles from "./StockTransferHeader.module.css"
import { Permission } from "services/types"
const cx = classNames.bind(styles)

interface StockTransferHeaderProps {
  transfer: any
  onEdit: Function
  onClose: Function
  isOutgoing: boolean
}

const StockTransferHeader = ({
  transfer,
  onEdit,
  onClose,
  isOutgoing,
}: StockTransferHeaderProps) => {
  const isDraft = transfer.status === "DRAFT"
  const permissionObj = usePermissions("Transfers") as Permission

  const statusColor = stockTransferStates.find(
    (state) => state.value === transfer.status
  )?.color

  return (
    <div className={styles.header}>
      <div className={styles.thumbnail}>
        <div
          className={styles.thumbnailIndicator}
          style={{ background: statusColor }}
        ></div>
        <FontAwesomeIcon icon={faSyncAlt} />
        <span
          className={cx(styles.thumbnailBadge, {
            [styles.thumbnailIncoming]: !isOutgoing,
          })}
        >
          <span>
            <FontAwesomeIcon
              size="xs"
              icon={isOutgoing ? faArrowRight : faArrowLeft}
            />
          </span>
        </span>
      </div>

      <div className={styles.headerMain}>
        <h3 className={styles.ref}>
          {isOutgoing ? " Send items" : "Incoming items"}
          {isDraft && (
            <button
              onClick={() => onEdit(transfer)}
              disabled={!permissionObj?.permissions.modify}
              className={cx("button--autoWidth", "editButton")}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
        </h3>
        <span className="text-sm text-gray-600 font-semibold">
          {transfer.receiverOrg.companyName}
        </span>
      </div>
      <button
        type="button"
        onClick={() => onClose()}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
    </div>
  )
}

export default StockTransferHeader
