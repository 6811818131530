import { faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface AccountingPublishFormHeaderProps {
  step: number
}
const AccountingPublishFormHeader: React.FunctionComponent<
  AccountingPublishFormHeaderProps
> = ({ step }: AccountingPublishFormHeaderProps) => {
  return (
    <ul className="flex flex-shrink-0 items-stretch border rounded self-stretch bg-paleBlue m-2">
      <li
        className={`px-4 py-2 ${
          step === 0
            ? "text-primaryBlue font-sansSemiBold font-semibold"
            : " text-primaryBlueLighter"
        }`}
      >
        Review details
      </li>
      <FontAwesomeIcon
        icon={faChevronRight}
        className="mx-1 text-primaryBlueLighter opacity-50 my-auto"
      />
      <li
        className={`px-4 py-2 ${
          step === 1
            ? "text-primaryBlue font-sansSemiBold font-semibold"
            : " text-primaryBlueLighter"
        }`}
      >
        Summary & Submit
      </li>
    </ul>
  )
}

export default AccountingPublishFormHeader
