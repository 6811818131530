export const getWidths = (provider) => {
  let devider = 2
  let colsLeft = 12

  colsLeft -= 5 //( qty:1, unit-price:1, account:3 )

  if (provider.taxPerLine) {
    devider++
    colsLeft -= 2
  }
  if (provider.categoryPerLine) {
    devider++
    colsLeft -= 2
  }
  return {
    lineWidth: `md:w-1/${devider}`,
    colsLeft: colsLeft,
  }
}
