import React, { useContext } from "react"

import Circle from "inline-svg/circle.svg"
import {
  faBolt,
  faChartNetwork,
  faHandsHeart,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "context/modal/ModalContext"
import SupportTicketModal from "components/common/SupportTicketModal/SupportTicketModal"

const OrganizationPlanAddons = () => {
  const modal = useContext(ModalContext)
  const openSupportTicketModal = (title) => {
    const params = {
      subject: `I'm interested in addon: ${title}`,
    }
    modal.showModal(SupportTicketModal, params)
  }

  const addons = [
    {
      icon: faBolt,
      price: "£79",
      priceCrossedOut: "£99",
      title: "Onboarding lifted off you",
      description:
        "Don't struggle to find time to set up growyze. Our team will set suppliers, orders and processes up for you, with limited involvement from your side.",
    },
    {
      icon: faChartNetwork,
      price: "",
      priceCrossedOut: "",
      title: "POS or accounting integration on request",
      description:
        "Can't see your favourite POS? We'll work with you to help sync or integrate your POS so you can make the most of growyze.",
    },
    {
      icon: faHandsHeart,
      price: "",
      priceCrossedOut: "",
      title: "On-site visit & training on request",
      description:
        "Our team will complete your first stocktake, show you how growyze works and train your staff on processes.",
    },
  ]

  return (
    <div
      className={
        "flex flex-col flex-wrap bg-gray-200 p-10 pb-20 mt-4  -mx-4 lg:-mx-8"
      }
    >
      <div className="container mx-auto">
        <h2 className="text-4xl my-4 text-primaryBlue text-center w-full">
          Add-Ons
        </h2>
        <div className=" text-center">Boost any Plan with these add-ons</div>
        <div className="flex flex-col lg:flex-row space-between gap-x-6">
          {addons.map((addon, i) => {
            return (
              <div
                key={i}
                className={
                  "flex flex-col w-full lg:w-1/3 flex-grow rounded-md border border-grey-500 bg-white shadow-lg flex-1 p-6 mt-10"
                }
              >
                <div className="flex w-100 flex-row justify-between items-center">
                  <div className="w-16 h-16 text-center items-center relative">
                    <FontAwesomeIcon
                      icon={addon.icon}
                      className="text-3xl mt-5 pt-0.5 z-0 ml-1"
                    />
                    <Circle
                      className="z-10 absolute top-0"
                      height="64"
                      width="64"
                    />
                  </div>
                  <div className="priceBox text-4xl font-bold flex flex-row">
                    {addon.price && addon.price}
                    {addon.priceCrossedOut && (
                      <div className="w-20 ml-7 relative">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="absolute text-7xl text-primaryPink z-10 ml-2 -mt-4"
                        />
                        <div className="w-20 relative z-0">
                          {addon.priceCrossedOut}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <h3 className="text-2xl mb-3 mt-8">{addon.title}</h3>
                <div className="pb-4">{addon.description}</div>
                <a
                  className="text-primaryPink mt-auto hover:underline cursor-pointer"
                  onClick={() => openSupportTicketModal(addon.title)}
                >
                  Get in touch <span className="text-3xl">&rarr;</span>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default OrganizationPlanAddons
