import { get, post, remove, patch } from "./api"
import { uuidv4 } from "./helpers"

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT

export const getSquarePosClientId = () => {
  return get("/square-pos-integrations/client-id", {}, { plain: true })
}

export const redirectToSquareAuthorization = (clientId) => {
  const rootSquareUrl =
    ENVIRONMENT === "production"
      ? "https://connect.squareup.com"
      : "https://connect.squareupsandbox.com"
  const CSRFToken = generateCSRFToken()
  const squareAuthUrl = `${rootSquareUrl}/oauth2/authorize?client_id=${clientId}&scope=MERCHANT_PROFILE_READ+PAYMENTS_READ+ORDERS_READ+ITEMS_READ+EMPLOYEES_READ+INVENTORY_READ+DEVELOPER_APPLICATION_WEBHOOKS_WRITE&session=false&state=${CSRFToken}`

  // Redirect
  window.location.href = squareAuthUrl
}

export const getCSRFToken = () => {
  return localStorage.getItem(`growyzeST_${ENVIRONMENT}`)
}

export const generateCSRFToken = () => {
  // More secure would be window.crypto.getRandomValues() but browser support is a litle unstable at the moment
  const uniqueValue = uuidv4()
  localStorage.setItem(`growyzeST_${ENVIRONMENT}`, uniqueValue)
  return uniqueValue
}

export const removeCSRFToken = () => {
  localStorage.removeItem(`growyzeST_${ENVIRONMENT}`)
}

export const createSquareConnection = (params) => {
  return post("/square-pos-integrations", params)
}

export const getSquareConnections = () => {
  return get("/square-pos-integrations")
}

export const getSquareLocations = (connectionId) => {
  return get(
    `/square-pos-integrations/locations?squarePosIntegrationId=${connectionId}`
  )
}

export const getSquareConnection = (connectionId, config = {}) => {
  return get(`/square-pos-integrations/${connectionId}`, config)
}

export const removeSquareConnection = (connectionId) => {
  return remove(`/square-pos-integrations/${connectionId}`)
}

export const setSquareIntegrationLocation = (connectionId, locationId) => {
  return patch(
    `/square-pos-integrations/${connectionId}/location/${locationId}`
  )
}
