import React, { useContext, useState, useEffect } from "react"
import * as styles from "./AccountSettings.module.css"
import Header from "components/dashboard/Header/Header"
import UserDetailsForm from "components/auth/UserDetailsForm/UserDetailsForm"
import ChangePasswordForm from "components/auth/ChangePasswordForm/ChangePasswordForm"
import { changePassword } from "services/user"
import { updateCurrentUser, refreshLogin } from "services/auth"
import { showSuccess, showError } from "services/toast"
import Helmet from "react-helmet"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import PermissionWidget from "../PermissionWidget/PermissionWidget"

const AccountSettings = () => {
  const { user } = useContext(GlobalStateContext)

  const dispatch = useContext(GlobalDispatchContext)

  const initialPasswordData = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    username: "",
  }

  const [loading, setLoading] = useState(false)
  const [passwordData, setPasswordData] = useState(initialPasswordData)
  const [userData, setUserData] = useState({})
  const [activeTab, setActiveTab] = useState("user-details")

  const [passwordSubmitEnabled, setPasswordSubmitEnabled] = useState(false)
  const [userSubmitEnabled, setUserSubmitEnabled] = useState(false)

  const [updateUsername, setUpdateUsername] = useState(false)

  const resetPasswordForm = () => {
    setPasswordData({
      ...initialPasswordData,
      username: user ? user.username : "",
    })
  }

  const setTab = (e, tab) => {
    e.preventDefault()
    setActiveTab(tab)
  }

  const saveUser = async (data) => {
    setLoading(true)
    let params
    let newUsername = data.newUsername !== ""

    if (newUsername) {
      params = {
        firstName: data.firstName,
        lastName: data.lastName,
        roleOfUser: data.roleOfUser,
        username: data.newUsername,
      }
    } else {
      params = {
        firstName: data.firstName,
        lastName: data.lastName,
        roleOfUser: data.roleOfUser,
        username: data.username,
      }
    }

    const result = await updateCurrentUser(params)

    if (result && result.error) {
      showError(result.message)
    } else if (result) {
      if (newUsername) {
        await refreshLogin()
      }
      dispatch({ type: "SET_USER", payload: result })
      setUserData({ ...params, newUsername: "", confirmNewUsername: "" })
      showSuccess("Profile updated!")
    }

    setLoading(false)
  }

  const savePassword = async (data) => {
    let params = data

    const changed = await changePassword(params)
    if (changed && !changed.error) {
      await refreshLogin()
      resetPasswordForm()
      showSuccess("Password changed!")
    } else {
      showError("Failed to change password, please check the required fields")
    }
  }

  const onPasswordDataChange = (data) => {
    setPasswordData(data.nextValues)
  }

  const onUserDataChange = (data) => {
    setUserData(data.nextValues)
  }

  useEffect(() => {
    if (user && Object.keys(user).length && !Object.keys(userData).length) {
      setUserData({
        firstName: user.firstName,
        lastName: user.lastName,
        roleOfUser: user.roleOfUser,
        username: user.username,
        newUsername: "",
        confirmNewUsername: "",
      })

      setPasswordData({
        ...passwordData,
        username: user.username,
      })
    }
  }, [user])

  return (
    <>
      <Helmet>
        <title>Account settings</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Account settings" />

        <ul className={styles.tabs}>
          <li className={activeTab === "user-details" ? styles.activeTab : ""}>
            <button type="button" onClick={(e) => setTab(e, "user-details")}>
              Profile
            </button>
          </li>
          <li
            className={activeTab === "password-details" ? styles.activeTab : ""}
          >
            <button
              type="button"
              onClick={(e) => setTab(e, "password-details")}
            >
              Change password
            </button>
          </li>
        </ul>

        <div className={styles.content}>
          {activeTab === "user-details" && Object.keys(userData).length && (
            <div className="flex flex-wrap -mx-3">
              <div className="w-full lg:w-1/2 px-3 mb-6">
                <h2 className={styles.tabContentTitle}>Your profile</h2>

                <UserDetailsForm
                  onDataChange={onUserDataChange}
                  onValidChange={(isValid) => setUserSubmitEnabled(isValid)}
                  initialValues={userData}
                  updateUsername={updateUsername}
                  setUpdateUsername={setUpdateUsername}
                />
                <button
                  type="submit"
                  className="button button--autoWidth"
                  onClick={(e) => {
                    e.preventDefault()
                    saveUser(userData)
                    setUpdateUsername(false)
                  }}
                  disabled={!userSubmitEnabled || loading}
                >
                  Save
                </button>
              </div>
              <div className="w-full lg:w-1/2 px-3">
                <h2 className={styles.tabContentTitle}>Your permissions</h2>

                {user && Object.keys(user).length && (
                  <PermissionWidget currentPermissions={user.permissions} />
                )}
              </div>
            </div>
          )}

          {activeTab === "password-details" && passwordData.username && (
            <div className="w-full lg:w-1/2">
              <h2 className={styles.tabContentTitle}>Change password</h2>

              <p className="text-gray-700 mb-3">
                To change your password, enter your old password and a new
                password twice. Your new password should be at least{" "}
                <strong>6 characters</strong> long.
              </p>

              <ChangePasswordForm
                onDataChange={onPasswordDataChange}
                onValidChange={(isValid) => setPasswordSubmitEnabled(isValid)}
                initialValues={passwordData}
              />

              <button
                type="submit"
                className="button button--autoWidth mt-2"
                onClick={(e) => {
                  e.preventDefault()
                  savePassword(passwordData)
                }}
                disabled={!passwordSubmitEnabled || loading}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountSettings
