import React, { useMemo } from "react"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loader from "components/common/Loader/Loader"
import classNames from "classnames"

//@ts-ignore
import * as styles from "./OrderSteps.module.css"
import { OrderStep, OrderStepItem } from "components/orders/types"

interface StepsProps {
  steps: Array<OrderStepItem>
  step: OrderStep
  modifyPermission: boolean
  loading: boolean
  isTabletOrMobile: boolean
  moveStep: (
    direction: "next" | "prev",
    currentStep: OrderStep
  ) => Promise<void>
}

const OrderSteps = ({
  steps,
  step,
  modifyPermission,
  loading,
  isTabletOrMobile,
  moveStep,
}: StepsProps) => {
  const activeStep = useMemo(
    () => steps.find((s) => s.key === step),
    [step, steps]
  )

  return (
    <div className="flex flex-col flex-grow w-full h-full relative overflow-auto lg:w-8/12">
      <div className="relative flex-grow flex-shrink w-full overflow-auto">
        <div className="flex items-stretch border-b w-full flex-shrink-0">
          {steps.map((stepItem) => {
            const isActiveStep = stepItem.key === step
            return (
              <div
                key={stepItem.key}
                className={classNames(
                  styles.subHeaderStep,
                  isActiveStep ? styles.subHeaderStepActive : ""
                )}
              >
                <FontAwesomeIcon icon={stepItem.icon} />
                {(isTabletOrMobile && isActiveStep) || !isTabletOrMobile ? (
                  <h3 className="truncate">{stepItem.title}</h3>
                ) : null}
              </div>
            )
          })}
        </div>
        <Loader
          isLoading={loading}
          style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
        >
          Loading..
        </Loader>

        {activeStep?.content}

        {!isTabletOrMobile && step !== steps[0].key && modifyPermission && (
          <button
            type="button"
            onClick={() => moveStep("prev", step)}
            className={"mb-8 ml-8 button button--autoWidth button--primaryBlue"}
            style={{ color: "#FFFFFF" }}
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
            Back
          </button>
        )}
      </div>
    </div>
  )
}

export default OrderSteps
