import React, { useEffect } from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import classNames from "classnames"
import { useField } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faCheck } from "@fortawesome/pro-regular-svg-icons"

const TextArea = ({
  label,
  className,
  showValidations = true,
  displayLabel = false,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props)

  useEffect(() => {
    if (meta.touched && meta.error && showValidations) {
      setTimeout(() => {
        ReactTooltip.rebuild()
      }, 100)
    }
  }, [meta.touched, meta.error, showValidations])

  return (
    <>
      <label
        className={!displayLabel ? "sr-only" : "pb-1 block"}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <textarea
        style={{
          paddingRight: showValidations && meta.touched ? "2.75rem" : "1rem",
          resize: "none",
        }}
        className={className}
        {...field}
        {...props}
      ></textarea>

      <ReactTooltip
        id={`textAreaTooltip-${props.id || props.name}`}
        type="light"
        place="left"
        effect="solid"
        border={true}
        borderColor="#e2e8f0"
      />

      {meta.touched && showValidations && (
        <>
          <span
            className={classNames("validation-mark", {
              "validation-mark--success": !meta.error,
              "validation-mark--error": meta.error,
            })}
            data-tip={meta.error}
            data-for={`textAreaTooltip-${props.id || props.name}`}
          >
            <FontAwesomeIcon icon={meta.error ? faTimes : faCheck} />
          </span>
        </>
      )}
    </>
  )
}

TextArea.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  showValidations: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  displayLabel: PropTypes.bool,
}

export default TextArea
