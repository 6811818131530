import React from "react"
import ReactTooltip from "react-tooltip"

import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OrganizationPlanNewProps {
  label: string
  tag: string
  tagColor: string
  tagStyle: any
  description: string
  price: string
  priceLabel: string
  featuresTitle: String
  featuresIcon: any
  featuresIconColor: String
  usps: Array<any>
  currentPaymentPlan: string
  isMostPopular: Boolean
  btnText: string
  btnColor: string
  btnSuffix: string
  btnCallback: Function
  userHasThisPlan: boolean
  children: any
}

const OrganizationPlanNew = (props: OrganizationPlanNewProps) => {
  const {
    label,
    tag,
    tagColor,
    tagStyle,
    description,
    priceLabel,
    featuresTitle,
    featuresIcon,
    featuresIconColor = "primaryPink",
    usps,
    btnText,
    btnColor,
    btnSuffix,
    btnCallback,
    userHasThisPlan,
    isMostPopular,
    children,
  } = props

  return (
    <div
      className={
        "flex flex-col rounded-md border border-gray-200 shadow-lg flex-1 mt-10 " +
        (isMostPopular ? "bg-primaryGreen bg-opacity-30 border-gray-400" : "")
      }
    >
      {isMostPopular && (
        <div
          className="relative w-100 -mt-12 h-12 pt-3 text-center mx-8 bg-opacity-20 rounded-t-lg"
          style={{ backgroundColor: "#fee4b4", top: "-1px" }}
        >
          Most Popular
        </div>
      )}
      <div className="flex flex-col flex-1 p-8">
        <div className="flex">
          <h2 className="mr-auto text-4xl">{label}</h2>
          <>
            {tag && (
              <div
                className={
                  "rounded text-lg border border-" +
                  tagColor +
                  " bg-" +
                  tagColor +
                  " bg-opacity-20 px-3 py-1"
                }
                style={tagStyle ?? {}}
              >
                {tag}
              </div>
            )}
          </>
        </div>
        <div className="text-2xl font-bold h-16 pt-6 text-gray-800">
          {priceLabel}
        </div>
        <div className="h-24 pb-4 text-md text-gray-700">{description}</div>
        <h3 className="text-lg border-b border-gray-500 text-primaryBlue pb-2 mb-2 h-16 relative">
          <span className="bottom-4 absolute">
            {featuresTitle}
            {featuresIcon && (
              <FontAwesomeIcon
                icon={featuresIcon}
                className={"inline ml-2 mt-1 text-" + featuresIconColor}
              />
            )}
          </span>
        </h3>
        <ul className="flex-1 mb-auto pb-4">
          {usps.map((item, index) => (
            <li key={index} className="flex py-1.5 text-md">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="mr-3 mt-1 text-primaryBlue"
              />
              <span>
                {item.label}
                {item.link && (
                  <a
                    className="pl-1 text-primaryPink inline"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={item.link}
                  >
                    {item.linkText}
                  </a>
                )}
              </span>
              {item.info && (
                <div>
                  <span data-for={"info_tooltip" + label} data-tip={item.info}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className={"ml-2 top-4 opacity-40 z-10"}
                    />
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
        <ReactTooltip
          type="light"
          id={"info_tooltip" + label}
          getContent={(dataTip) => `${dataTip}`}
          effect="solid"
        />

        {userHasThisPlan ? (
          <>
            <div className="p-3 text-center font-bold mt-4 button button--lightGray disabled">
              {" "}
              This is your current plan
            </div>
            <i className="pt-4 text-center h-20">{btnSuffix}</i>
          </>
        ) : (
          <>
            {children ? (
              children
            ) : (
              <button
                className={"self-end mt-8 button button--" + btnColor}
                onClick={() => btnCallback && btnCallback(props)}
              >
                {btnText}
              </button>
            )}
            <i className="pt-4 text-center h-20">{btnSuffix}</i>
          </>
        )}
      </div>
    </div>
  )
}

export default OrganizationPlanNew
