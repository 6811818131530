// extracted by mini-css-extract-plugin
export var checkBox = "GetStartedStep-module--checkBox--7SVbq";
export var checked = "GetStartedStep-module--checked--pxoae";
export var container = "GetStartedStep-module--container--IhEk2";
export var description = "GetStartedStep-module--description--t+vX5";
export var icon = "GetStartedStep-module--icon--204Lw";
export var link = "GetStartedStep-module--link--OTA-B";
export var subItem = "GetStartedStep-module--subItem--5hWS8";
export var subItemContent = "GetStartedStep-module--subItemContent--fysFN";
export var title = "GetStartedStep-module--title--VQ+tQ";
export var withItems = "GetStartedStep-module--withItems--oIXbY";