import { faArrowLeft, faCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Dots from "components/common/Dots/Dots"
import React, { useState } from "react"
import AddLocations from "../../organization/AddLocations/AddLocations"

const LocationDetails = ({
  prevStep,
  nextStep,
  dotCount,
  initialLocations,
  onLocationsChange,
  zoneId,
  country,
}) => {
  const [locations, setLocations] = useState(initialLocations || [])
  const [nextStepEnabled, setNextStepEnabled] = useState<Boolean>(false)
  return (
    <div>
      <AddLocations
        initialLocations={locations}
        onLocationsChange={(locations, enabled) => {
          setNextStepEnabled(enabled)
          setLocations(locations)
          onLocationsChange(locations)
        }}
        zoneId={zoneId}
        country={country}
      />
      <div className="flex justify-between mt-7">
        <button
          type="button"
          className="font-sansBold font-bold text-gray-700"
          onClick={prevStep}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="md:mr-4" />
          <span className="hidden md:inline">Previous</span>
        </button>
        <Dots index={2} count={dotCount ?? 2} />
        <button
          type="button"
          className="button button--autoWidth button--primaryPink"
          disabled={!nextStepEnabled}
          onClick={() => nextStep(locations)} //FINALIZE
        >
          <FontAwesomeIcon icon={faCheck} className="md:mr-4" />
          <span className="hidden sm:inline">Finish</span>
        </button>
      </div>
    </div>
  )
}

export default LocationDetails
