import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./RejectNote.module.css"

const RejectNote = ({ supplier, onSave, isSaving }) => {
  const areaRef = useRef(null)
  const [message, setMessage] = useState("")
  const [notifySupplier, setNotifySupplier] = useState(false)

  //
  // Handlers
  //

  const handleTextareaChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setMessage(value)
  }

  useEffect(() => {
    areaRef.current.focus()
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <label className="text-gray-700 mb-3 text-center w-full">
        Reason {notifySupplier && <span className="text-primaryPink">*</span>}
      </label>
      <textarea
        ref={areaRef}
        className={`form-control ${styles.textarea}`}
        rows={4}
        value={message || ""}
        onChange={handleTextareaChange}
        placeholder={`Tell ${supplier.name} why you want to reject this delivery.`}
      ></textarea>
      <div className="mb-4 w-full">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            value={notifySupplier}
            onChange={() => setNotifySupplier(!notifySupplier)}
            name="differentDelivery"
            className="form-checkbox text-primaryPink"
          />
          <span className="ml-2 text-gray-700">
            Notify {supplier.name} by email
          </span>
        </label>
        {notifySupplier && !message && (
          <span className="text-center w-full block mt-2 text-primaryPink">
            Please enter the reason for rejecting this invoice
          </span>
        )}
      </div>
      <button
        onClick={() => onSave({ message, notifySupplier })}
        className="button button--primaryPink"
        disabled={isSaving || (notifySupplier && !message)}
      >
        {isSaving && (
          <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
        )}
        Confirm
      </button>
    </div>
  )
}

RejectNote.propTypes = {
  supplier: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

export default RejectNote
