import React from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"

import { format } from "date-fns"
import { uuidv4 } from "services/helpers"

import WasteDetailItem from "../WasteDetailItem/WasteDetailItem"

import * as styles from "./WasteDetailsModal.module.css"

const WasteDetailsModal = ({
  onRequestClose,
  wasteProduct,
  wasteReport,
  ...otherProps
}) => {
  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className="flex flex-col m-1">
            <span className="text-sm text-gray-700">Period</span>
            <div className="text-primaryBlue font-sansBold text-md flex flex-col">
              <span>{`${format(
                new Date(wasteReport.fromDate),
                "dd/MM/yyyy"
              )} - ${format(
                new Date(wasteReport.toDate),
                "dd/MM/yyyy"
              )}`}</span>
            </div>
          </div>
          <div className="flex flex-col m-1">
            <span className="text-sm text-gray-700">Item name</span>
            <span className="text-primaryBlue font-sansBold text-md">
              {wasteProduct.product.name}
            </span>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col m-1">
              <span className="text-sm text-gray-700">Unit</span>
              <span className="font-sansBold text-sm">
                {wasteProduct.product.unit
                  ? wasteProduct.product.unit
                  : "No unit"}
              </span>
            </div>
            <div className="flex flex-col m-1">
              <span className="text-sm text-gray-700">Size/measure</span>
              <div className="font-sansBold text-sm">
                {wasteProduct.product.size && (
                  <span>{wasteProduct.product.size}&nbsp;</span>
                )}
                {wasteProduct.product.measure && (
                  <span>{wasteProduct.product.measure}</span>
                )}
                {!wasteProduct.product.size &&
                  !wasteProduct.product.measure && <span>No size/measure</span>}
              </div>
            </div>
            <div className="flex flex-col m-1">
              <span className="text-sm text-gray-700">Category</span>
              <span className="font-sansBold text-sm">{`${wasteProduct.product.category} - ${wasteProduct.product.subCategory}`}</span>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className="w-full flex h-full flex-col flex-grow">
            <div className="flex w-full">
              <div className={styles.wasteDetailCell}>Date</div>
              <div className={styles.wasteDetailCell}>Cost</div>
              <div className={styles.wasteDetailCell}>Qty</div>
              <div className={styles.wasteDetailCell}>Reason</div>
            </div>
            {wasteProduct.wastesForPeriod.map((wastePeriod) => {
              return (
                <WasteDetailItem
                  wastePeriod={wastePeriod}
                  wasteProduct={wasteProduct}
                  key={uuidv4()}
                />
              )
            })}
          </div>
        </div>
        <div className={styles.footer}>
          <button onClick={onRequestClose} className="cursor-pointer">
            <span className="text-gray-600 font-sansBold">Close</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

WasteDetailsModal.propTypes = {
  onRequestClose: PropTypes.func,
  wasteProduct: PropTypes.object,
  wasteReport: PropTypes.object,
}

export default WasteDetailsModal
