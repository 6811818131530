// extracted by mini-css-extract-plugin
export var active = "WasteInput-module--active--UC+m+";
export var backIcon = "WasteInput-module--backIcon--SlC7k";
export var camera = "WasteInput-module--camera--FzmO0";
export var clickArea = "WasteInput-module--clickArea--s5Dtr";
export var confirm = "WasteInput-module--confirm--RL1dW";
export var confirmContainer = "WasteInput-module--confirmContainer--EAXGA";
export var confirmImg = "WasteInput-module--confirmImg--c4XJI";
export var container = "WasteInput-module--container--5muQe";
export var content = "WasteInput-module--content--J6TL4";
export var contentConfirm = "WasteInput-module--content--confirm--ekhDI";
export var displayIntro = "WasteInput-module--displayIntro--CRD+2";
export var flash = "WasteInput-module--flash--CXrLr";
export var header = "WasteInput-module--header--g1xBx";
export var headerMain = "WasteInput-module--headerMain--K6o57";
export var navBar = "WasteInput-module--navBar--yyr1B";
export var notFound = "WasteInput-module--notFound--302-I";
export var notFoundContent = "WasteInput-module--notFoundContent--9izqI";
export var notFoundHeader = "WasteInput-module--notFoundHeader--MyF0w";
export var notFoundTitle = "WasteInput-module--notFoundTitle--b-lUp";
export var placeHolder = "WasteInput-module--placeHolder--cBLCg";
export var productSelect = "WasteInput-module--productSelect--kJ+zh";
export var productSelectContainer = "WasteInput-module--productSelectContainer--jXysp";
export var rotate = "WasteInput-module--rotate--HwjIy";
export var scanPending = "WasteInput-module--scanPending--S8Hut";
export var scanPendingAnimation = "WasteInput-module--scanPendingAnimation--7g7P2";
export var scanPendingButton = "WasteInput-module--scanPendingButton--W6yih";
export var scanPendingText = "WasteInput-module--scanPendingText--F-Za2";
export var scanning = "WasteInput-module--scanning--7ikxb";
export var show = "WasteInput-module--show--Q7egv";
export var spinning = "WasteInput-module--spinning--wfrgN";
export var step2 = "WasteInput-module--step2--gbXI2";
export var step3 = "WasteInput-module--step3--Mr01C";
export var step4 = "WasteInput-module--step4--d0Oeq";
export var tabButton = "WasteInput-module--tabButton--NSkxw";
export var tabs = "WasteInput-module--tabs--aFDw8";
export var title = "WasteInput-module--title--ReSxj";
export var transparent = "WasteInput-module--transparent--KFHQr";