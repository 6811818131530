import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import React from "react"
import {
  AccountingProvider,
  publishableInvoiceStates,
  useAccountingProviders,
} from "services/accounting"
import AccountingPublishButton from "./AccountingPublishButton"

type AccountingPublishButtonsProps = {
  invoice: any
  onClick: Function
}

const AccountingPublishButtons = (props: AccountingPublishButtonsProps) => {
  const { invoice, onClick } = props

  const { providers } = useAccountingProviders()

  const buttons = Object.values(providers).map(
    (provider: AccountingProvider, i) => {
      if (provider?.id && !publishableInvoiceStates.includes(invoice.status)) {
        let btnProps = {} as any
        if (invoice[provider.key + "Invoice"]) {
          btnProps = {
            title: "Published to " + provider.title,
            alt: provider.title + " details",
            icon: faCheck,
            onClick: () =>
              onClick({
                type: "ACCOUNTING_SUMMARY",
                title: "Published to " + provider.title,
                provider: provider,
              }),
          }
        } else {
          btnProps = {
            title: "Publish to " + provider.title,
            alt: "Publish to " + provider.title,
            onClick: () =>
              onClick({
                type: "PUBLISH_TO_ACCOUNTING",
                title: "Publish to " + provider.title,
                provider: provider,
              }),
          }
        }
        return (
          <AccountingPublishButton key={i} provider={provider} {...btnProps} />
        )
      }
    }
  )

  return <div className="flex flex-row flex-wrap">{buttons}</div>
}

export default AccountingPublishButtons
