import React from "react"
import { Formik, Form } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import * as Yup from "yup"
import { normalizeNullValues } from "services/helpers"
import { organizationRoles } from "services/constants"
import TextInput from "../../forms/TextInput"
import PropTypes from "prop-types"
import CustomSelect from "../../forms/CustomSelect"
import { updateEmailButton } from "./UserDetailsForm.module.css"

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, "equalTo", equalTo)

const Schema = Yup.object().shape(
  {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    roleOfUser: Yup.string().required("Please select a user role"),
    newUsername: Yup.string()
      .email("Invalid email")
      .when("confirmNewUsername", {
        is: (confirmNewUsername) => confirmNewUsername,
        then: Yup.string().required("New email is required"),
      })
      .nullable(),
    confirmNewUsername: Yup.string()
      .email("Invalid email")
      .when("newUsername", {
        is: (newUsername) => newUsername,
        then: Yup.string()
          .required("Please confirm new email")
          .equalTo(Yup.ref("newUsername"), "Emails do not match"),
      })
      .nullable(),
  },
  ["newUsername", "confirmNewUsername"]
)

const UserDetailsForm = ({
  onDataChange,
  onValidChange,
  initialValues,
  updateUsername,
  setUpdateUsername,
}) => {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues(initialValues)}
      validationSchema={Schema}
    >
      {() => (
        <>
          <FormikEffect
            onChange={onDataChange}
            onValidationChange={onValidChange}
          />
          <Form className="flex flex-col mb-4">
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="firstName"
                  label="First name"
                  type="text"
                  placeholder="First name *"
                  className="form-control form-control--first"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="lastName"
                  label="Last name"
                  type="text"
                  placeholder="Last name *"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-container">
                <CustomSelect
                  name="roleOfUser"
                  id="roleOfUser"
                  label="Company role"
                  placeholder="Company role *"
                  className="form-control"
                  options={organizationRoles}
                />
              </div>
            </div>

            <div className="form-group relative">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="username"
                  label="Email"
                  type="text"
                  placeholder="Email *"
                  className="form-control  form-control--last"
                  disabled
                />
              </div>
              {!updateUsername && (
                <button
                  onClick={setUpdateUsername}
                  className={`button button--autoWidth button--primaryGreen no-truncate ${updateEmailButton}`}
                >
                  Update
                </button>
              )}
            </div>

            {updateUsername && (
              <>
                <div className="form-group mt-4">
                  <div className="input-container">
                    <TextInput
                      required={false}
                      name="newUsername"
                      label="New email"
                      type="text"
                      placeholder="New email"
                      className="form-control form-control--first"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      required={false}
                      name="confirmNewUsername"
                      label="Confirm new username"
                      type="text"
                      placeholder="Confirm new email"
                      className="form-control form-control--last"
                    />
                  </div>
                </div>
              </>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
}

UserDetailsForm.propTypes = {
  onDataChange: PropTypes.func,
  onValidChange: PropTypes.func,
  initialValues: PropTypes.object,
  updateUsername: PropTypes.any,
  setUpdateUsername: PropTypes.func,
}

export default UserDetailsForm
