import React from "react"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import { Formik, Form, FieldArray } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import * as Yup from "yup"
import { countries, currencies } from "services/constants"
import { normalizeNullValues } from "services/helpers"
import PropTypes from "prop-types"
import TextInput from "components/forms/TextInput"
import TextArea from "components/forms/TextArea"
import CustomSelect from "components/forms/CustomSelect"
import FormDropdown from "components/forms/FormDropdown"
import OrderReminder from "components/suppliers/OrderReminder/OrderReminder"

import { faBell } from "@fortawesome/pro-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import usePermissions from "hooks/usePermissions"

import OrderApprovals from "../OrderApprovals/OrderApprovals"
import { Permission } from "services/types"
import FormGroupToggle from "components/forms/FormGroupToggle"
import FormFieldRepeater from "components/forms/FormFieldRepeater"

const Schema = Yup.object().shape({
  name: Yup.string()
    .trim("The Supplier name cannot include leading and trailing spaces")
    .required("Supplier name is required"),
  accountNumber: Yup.string().nullable(),
  address: Yup.object().shape({
    addressLine1: Yup.string().nullable(),
    city: Yup.string().nullable(),
    postCode: Yup.string().nullable(),
    country: Yup.string().nullable(),
  }),
  contactName: Yup.string()
    .trim("Contact name cannot include leading and trailing spaces")
    .required("Contact name is required"),
  email: Yup.string()
    .email("Email address is invalid")
    .required("Email address is required"),
  website: Yup.string(),
  telephone: Yup.string().matches(/^\+?[0-9\s]+$/, "Invalid phone number", {
    excludeEmptyString: true,
  }),
  currency: Yup.string().nullable(),
  notes: Yup.string().nullable(),
  orderReminders: Yup.array().of(
    Yup.object().shape({
      deliveryDay: Yup.string().required("Delivery day is required"),
      notifyMe: Yup.bool(),
      orderDay: Yup.string().required("Order day is required"),
      orderTillTime: Yup.string().required("Order till time is required"),
    })
  ),
  ordersContact: Yup.object()
    .shape({
      emails: Yup.array().of(
        Yup.string()
          .email("Email address is invalid")
          .required("Email address is required")
      ),
      telephone: Yup.string().matches(/^\+?[0-9\s]+$/, "Invalid phone number", {
        excludeEmptyString: true,
      }),
    })
    .notRequired()
    .default(undefined),
  deliveriesContact: Yup.object()
    .shape({
      emails: Yup.array().of(
        Yup.string()
          .email("Email address is invalid")
          .required("Email address is required")
      ),
      telephone: Yup.string().matches(/^\+?[0-9\s]+$/, "Invalid phone number", {
        excludeEmptyString: true,
      }),
    })
    .notRequired()
    .default(undefined),
  invoicesContact: Yup.object()
    .shape({
      emails: Yup.array().of(
        Yup.string()
          .email("Email address is invalid")
          .required("Email address is required")
      ),
      telephone: Yup.string().matches(/^\+?[0-9\s]+$/, "Invalid phone number", {
        excludeEmptyString: true,
      }),
    })
    .notRequired()
    .default(undefined),
})

const SupplierForm = ({ onDataChange, onValidChange, initialValues }) => {
  const newOrderReminder = {
    deliveryDay: "",
    notifyMe: false,
    orderDay: "",
    orderTillTime: "12:00",
  }
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const permissionObj = usePermissions("Company settings") as Permission

  const userHasReadPermission = permissionObj?.permissions?.read
  const userHasModifyPermission = permissionObj?.permissions?.modify

  const contactGroups = [
    {
      label: "Orders",
      field: "ordersContact",
    },
    {
      label: "Deliveries",
      field: "deliveriesContact",
    },
    {
      label: "Invoices",
      field: "invoicesContact",
    },
  ]

  return (
    <Formik
      validationSchema={Schema}
      initialValues={normalizeNullValues(initialValues)}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const approvalsEnabled = values?.orderApprovals?.enabled
        const minOrderValueEnabled = values?.minOrderConfig?.enabled
        const onShouldApproveAllOrdersChange = (e) => {
          const val = e.target.value === "true" ? true : false
          if (val) {
            setFieldValue("orderApprovals.aboveTotalValue", 0)
          }
          setFieldValue("orderApprovals.shouldApproveAllOrders", val)
        }

        return (
          <>
            <FormikEffect
              onChange={onDataChange}
              onValidationChange={onValidChange}
            />
            <Form className="flex flex-col flex-shrink-0 w-full mb-4">
              <div className="form-group form-group--flex">
                <div className="input-container input-container--2-3">
                  <TextInput
                    required={true}
                    name="name"
                    type="text"
                    className="form-control form-control--topleft form-control--bottomleft"
                    placeholder="Supplier name *"
                    label="Supplier name *"
                  />
                </div>
                <div className="input-container input-container--1-3">
                  <TextInput
                    showValidations={false}
                    name="accountNumber"
                    type="text"
                    className="form-control form-control--topright form-control--bottomright"
                    placeholder="Account number"
                    label="Account number"
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    showValidations={false}
                    name="vatNumber"
                    label="VAT number"
                    type="text"
                    placeholder="VAT number"
                    className="form-control form-control--last"
                  />
                </div>
              </div>

              <h5 className="mb-4 mt-6 text-gray-700">Main contact</h5>

              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    required={true}
                    name="contactName"
                    label="Contact name *"
                    type="text"
                    placeholder="Contact name *"
                    className="form-control form-control--first"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    required={true}
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Email *"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    name="website"
                    label="Website"
                    type="text"
                    placeholder="Website"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    name="telephone"
                    label="Phone number"
                    type="text"
                    placeholder="Phone number"
                    className="form-control form-control--last"
                  />
                </div>
              </div>

              <FormDropdown
                buttonText={
                  <span
                    data-tip="Add contact details to send notifications to suppliers when placing orders, querying deliveries and invoices"
                    data-for="supplierForm-tooltip"
                    className="flex items-center"
                  >
                    <span className="mr-1">Purchasing contacts</span>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ fontSize: "18px" }}
                    />
                  </span>
                }
                fullWidth={true}
              >
                {contactGroups.map((group, i) => (
                  <FormGroupToggle
                    key={i}
                    title={group.label}
                    id={"label-" + group.label}
                    label="Same as contact details"
                    initialChecked={values[group.field] === ""}
                    onChange={(checked) => {
                      if (checked) {
                        setFieldValue(group.field, "")
                      }
                    }}
                  >
                    <div className="input-container input-container--1 flex items-center relative">
                      <TextInput
                        required={"false"}
                        name={group.field + ".telephone"}
                        label={"Telephone"}
                        placeholder={"Telephone"}
                        type={"text"}
                        className="form-control"
                        showValidations={false}
                      />
                    </div>

                    <FormFieldRepeater
                      name={group.field + ".emails"}
                      label={"Email"}
                      placeholder={"Email"}
                      roundedTopRight=""
                    />
                  </FormGroupToggle>
                ))}
              </FormDropdown>

              <FormDropdown buttonText="Orders & Deliveries" fullWidth={true}>
                <div className="my-3 form-group">
                  <FieldArray name="orderReminders">
                    {({ push, remove }) => {
                      return (
                        <>
                          {values.orderReminders &&
                          values.orderReminders.length > 0 ? (
                            <>
                              <div className="hidden lg:flex font-sansBold text-gray-700 my-1">
                                <div className="flex" style={{ flex: 3 }}>
                                  Delivery days *
                                </div>
                                <div className="flex" style={{ flex: 4 }}>
                                  Place orders by *
                                </div>
                                <div className="flex justify-center flex-1">
                                  Notify
                                </div>
                                <div className="flex flex-1"></div>
                              </div>
                              {values.orderReminders.map(
                                (orderReminder, index) => (
                                  <OrderReminder
                                    index={index}
                                    orderReminder={orderReminder}
                                    onAdd={() => push(newOrderReminder)}
                                    onDelete={() => remove(index)}
                                    setFieldValue={setFieldValue}
                                    key={`orderReminder--${index}`}
                                  />
                                )
                              )}
                            </>
                          ) : (
                            <div className="flex flex-col w-3/4 lg:w-1/2">
                              <button
                                className="button button--primaryGreen"
                                onClick={() => push(newOrderReminder)}
                              >
                                <FontAwesomeIcon
                                  icon={faBell}
                                  className="mr-2"
                                  size="lg"
                                />
                                Set order reminder
                              </button>
                            </div>
                          )}
                        </>
                      )
                    }}
                  </FieldArray>
                </div>
              </FormDropdown>

              {userHasReadPermission && (
                <FormDropdown buttonText="Order approvals" fullWidth={true}>
                  <div className={"form-group form-group--flex w-full mt-4"}>
                    <label
                      className={classNames(
                        "items-center input-flex form-control form-control--first",
                        { "form-control--last": !approvalsEnabled }
                      )}
                    >
                      <input
                        type="checkbox"
                        value={""}
                        onChange={() => {
                          setFieldValue(
                            "orderApprovals.enabled",
                            !approvalsEnabled
                          )
                        }}
                        name="orderApprovals.enabled"
                        className={classNames("form-checkbox text-primaryPink")}
                        checked={approvalsEnabled}
                        disabled={!userHasModifyPermission}
                      />
                      <span className="ml-2 text-gray-700">
                        Enable order approvals for this supplier
                      </span>
                    </label>
                  </div>

                  {approvalsEnabled && (
                    <div className={"form-group form-group--flex w-full"}>
                      <div className="flex flex-col form-control form-control--last w-full border-gray-300 border py-3 px-4">
                        <label className="flex items-center justify-stretch form-control">
                          <input
                            type="radio"
                            className="form-radio text-primaryBlue focus:shadow-none focus:border-0"
                            name="orderApprovals.shouldApproveAllOrders"
                            checked={
                              values.orderApprovals.shouldApproveAllOrders
                            }
                            value="true"
                            onChange={onShouldApproveAllOrdersChange}
                            disabled={!userHasModifyPermission}
                          />
                          <span className="ml-3 flex-grow">All orders</span>
                        </label>
                        <div className="flex w-full justify-between">
                          <label className="flex items-center justify-stretch form-control flex-1">
                            <input
                              type="radio"
                              className="form-radio text-primaryBlue focus:shadow-none focus:border-0"
                              name="orderApprovals.shouldApproveAllOrders"
                              checked={
                                !values.orderApprovals.shouldApproveAllOrders
                              }
                              value="false"
                              onChange={onShouldApproveAllOrdersChange}
                              disabled={!userHasModifyPermission}
                            />
                            <span className="ml-3 flex-grow">
                              Only above total value
                            </span>
                          </label>
                          <input
                            min={0}
                            name="orderApprovals.aboveTotalValue"
                            type="number"
                            className="form-control rounded border-gray-300"
                            style={{ maxWidth: "150px" }}
                            value={values.orderApprovals.aboveTotalValue}
                            onChange={(e) =>
                              setFieldValue(
                                "orderApprovals.aboveTotalValue",
                                e.target.value
                              )
                            }
                            disabled={
                              values.orderApprovals.shouldApproveAllOrders ||
                              !userHasModifyPermission
                            }
                          />
                        </div>

                        <OrderApprovals
                          approvers={values.orderApprovals.approvers}
                          userHasModifyPermission={userHasModifyPermission}
                        />
                      </div>
                    </div>
                  )}
                </FormDropdown>
              )}

              <FormDropdown buttonText="Min. order value" fullWidth={true}>
                <div className={"form-group form-group--flex w-full mt-4"}>
                  <label
                    className={classNames(
                      "items-center input-flex form-control form-control--first",
                      { "form-control--last": !minOrderValueEnabled }
                    )}
                  >
                    <input
                      type="checkbox"
                      onChange={() => {
                        setFieldValue(
                          "minOrderConfig.enabled",
                          !minOrderValueEnabled
                        )
                        setFieldValue(
                          "minOrderConfig.minOrderValue",
                          minOrderValueEnabled ? 0 : 1000
                        )
                      }}
                      name="minOrderConfig.minOrderValue"
                      className={classNames("form-checkbox text-primaryPink")}
                      checked={minOrderValueEnabled}
                    />
                    <span className="ml-2 text-gray-700">
                      Enable min. order value for this supplier
                    </span>
                  </label>
                </div>

                {minOrderValueEnabled && (
                  <div className={"form-group form-group--flex w-full"}>
                    <div className="flex flex-col form-control form-control--last w-full border-gray-300 border py-3 px-4">
                      <input
                        min={0}
                        name="minOrderConfig.minOrderValue"
                        type="number"
                        className="form-control rounded border-gray-300"
                        value={values.minOrderConfig.minOrderValue}
                        onChange={(e) =>
                          setFieldValue(
                            "minOrderConfig.minOrderValue",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </FormDropdown>

              <FormDropdown buttonText="Additional info" fullWidth={true}>
                <div className="form-group mt-4 ">
                  <div className="input-container">
                    <TextInput
                      required={false}
                      name="address.addressLine1"
                      label="Address line 1"
                      type="text"
                      placeholder="Address line 1"
                      autoComplete="street"
                      className="form-control form-control--first"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      name="address.addressLine2"
                      showValidations={false}
                      label="Address line 2"
                      type="text"
                      placeholder="Address line 2"
                      autoComplete="street"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="form-group form-group--flex">
                  <div className="input-container input-container--right">
                    <TextInput
                      required={false}
                      name="address.city"
                      label="Post code"
                      type="text"
                      placeholder="City"
                      autoComplete="city"
                      className="form-control"
                    />
                  </div>
                  <div className="input-container input-container--left">
                    <TextInput
                      required={false}
                      name="address.postCode"
                      label="Post code"
                      type="text"
                      placeholder="Post code"
                      autoComplete="postcode"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-container">
                    <CustomSelect
                      required={false}
                      name="address.country"
                      id="country"
                      isClearable={true}
                      isSearchable={!isTabletOrMobile}
                      label="Country"
                      placeholder="Country"
                      className="form-control"
                      options={countries}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-container">
                    <CustomSelect
                      required={false}
                      isClearable={true}
                      name="currency"
                      id="currency"
                      isSearchable={true}
                      label="Currency"
                      placeholder="Currency"
                      className="form-control form-control--last"
                      options={currencies}
                    />
                  </div>
                </div>
                <div className="flex-grow">
                  <h5 className="mb-4 mt-6 text-gray-700">Notes</h5>
                  <div className="form-group">
                    <div className="input-container">
                      <TextArea
                        showValidations={false}
                        name="notes"
                        className="form-control form-control--last form-control--first"
                        placeholder="Any notes, e.g. related to deliveries, etc"
                        label="Notes"
                      />
                    </div>
                  </div>
                </div>
              </FormDropdown>
            </Form>
            <ReactTooltip
              id="supplierForm-tooltip"
              place="top"
              effect="solid"
              backgroundColor="white"
              textColor="#4E4E4E"
              borderColor="#DCDCDC"
              border={true}
              offset={{ left: 4, top: -3 }}
              className="font-sansBold z-50"
            />
          </>
        )
      }}
    </Formik>
  )
}

SupplierForm.propTypes = {
  onDataChange: PropTypes.func,
  onValidChange: PropTypes.func,
  initialValues: PropTypes.object,
}

export default SupplierForm
