type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const sendEvent = (
  eventName: string,
  userData: {
    firstName?: string
    lastName?: string
    email: string
    organization?: any
    accountType?: string
  }
) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: `APP_${eventName}`,
    userData,
  })
}
