import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { useContext } from "react"
import { Permission } from "./../services/types"

type PermissionOrPermissionList<T extends string | undefined> = T extends string
  ? Permission
  : Permission[]

const usePermissions = <T extends string | undefined>(
  feature: T
): PermissionOrPermissionList<T> | null => {
  const { organization, user } = useContext(GlobalStateContext)

  if (!user || !organization) {
    return null
  }

  const userPermissions = user.permissions

  if (userPermissions && feature) {
    const featurePermission = userPermissions.find(
      (f: Permission) => f.name === feature
    )
    if (featurePermission) {
      return featurePermission
    }
  }

  return userPermissions
}

export default usePermissions
