import React, { useContext, useState, useEffect } from "react"
import { useLocation } from "react-use"
import {
  authorizeWithClover,
  createCloverIntegration,
  deleteCloverIntegration,
} from "services/clover"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import * as queryString from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { showSuccess, showWarn } from "services/toast"
import { IntegrationButtonProps } from "services/types"

export const CloverIntegrationButton = ({
  status = "INACTIVE",
  id = null,
  onConnectionChange,
  canConnect,
  canDisconnect,
}: IntegrationButtonProps) => {
  const route = useLocation()

  const { organization } = useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const queryParams = queryString.parse(route.search)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [connected, setConnected] = useState(status === "ACTIVE" ? true : false)

  const startCloverAuth = async () => {
    try {
      setLoading(true)
      authorizeWithClover()
    } catch (e) {
      setLoading(false)
      setError("Failed to connect to clover, please try again later")
    }
  }

  const onLoad = async () => {
    if (connected) return
    if (
      queryParams.callback === "clover" &&
      queryParams.code &&
      queryParams.merchant_id
    ) {
      setLoading(true)
      const params = {
        code: queryParams.code,
        merchantId: queryParams.merchant_id,
        name: `CLOVER_POS - ${organization.companyName}`,
        organizations: null,
      }
      try {
        const created = await createCloverIntegration(params)
        if (created.id) {
          showSuccess("Clover connected!")
          setConnected(true)
          onConnectionChange()
        }
        setLoading(false)
      } catch (e: any) {
        console.log(e)
        setError(e.message)
        setLoading(false)
      }
    }
  }

  const confirmRemove = () => {
    setError("")
    if (connected && id) {
      // @ts-ignore
      modal.showModal(ConfirmModal, {
        type: "danger",
        title: `Disconnect Clover integration?`,
        text: "Are you sure you want to disconnect this integration?",
        confirmButtonText: "Disconnect",
        onConfirm: async () => {
          setLoading(true)
          const success = await deleteCloverIntegration(id)
          if (success) {
            showWarn("Clover disconnected!")
            setConnected(false)
            onConnectionChange()
          }
          setLoading(false)
        },
      })
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  return connected ? (
    canDisconnect ? (
      <button
        className="text-primaryPink font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left mr-3"
        style={{ height: "40px " }}
        onClick={confirmRemove}
      >
        Disconnect
      </button>
    ) : null
  ) : (
    <div className="inline-flex flex-col items-start mr-3">
      {canConnect && (
        <button
          className="text-primaryGreen font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left"
          style={{ height: "40px" }}
          disabled={loading}
          onClick={startCloverAuth}
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
              Connecting..
            </>
          ) : (
            <>
              Connect
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </>
          )}
        </button>
      )}
      {error && <span className="text-primaryPink text-xs">{error}</span>}
    </div>
  )
}
