const uiControlsReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR": {
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      }
    }
    case "OPEN_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpen: true,
      }
    }
    case "CLOSE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpen: false,
      }
    }
    case "KEYBOARD_IS_OPEN": {
      return {
        ...state,
        mobileKeyboardOpen: true,
      }
    }
    case "KEYBOARD_IS_CLOSED": {
      return {
        ...state,
        mobileKeyboardOpen: false,
      }
    }
    default: {
      return state
    }
  }
}

export default uiControlsReducer
