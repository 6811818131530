import React, { useContext } from "react"
import { ModalContext } from "context/modal/ModalContext"
import { formatCurrencyValue, roundNumber } from "services/helpers"
import { format } from "date-fns"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import EditWasteModal from "../EditWasteModal/EditWasteModal"
import WasteTable from "../WasteTable/WasteTable"

import * as styles from "./WasteContent.module.css"
import { WasteTab } from "services/waste/types"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"

interface WasteContentProps {
  wasteGroup: {
    id: string
    products: { product: { id: string } }[]
    recipes: { id: string }[]
    dishes: { id: string }[]
    totalCost: number
  }
  tab: WasteTab
  setTab: React.Dispatch<React.SetStateAction<WasteTab>>
  removeWaste: (waste: any, product: any, wasteGroupId: any) => Promise<void>
  deleteWasteProduct: (wasteGroupId: any, wasteId: any) => Promise<void>
  deleteWasteRecipe: (wasteGroupId: any, wasteId: any) => Promise<void>
  deleteWasteDish: (wasteGroupId: any, wasteId: any) => Promise<void>
  deleteWasteRecipeRecord: (
    wasteGroupId: any,
    recipeId: any,
    wasteId: any
  ) => Promise<void>
  deleteWasteDishRecord: (
    wasteGroupId: any,
    dishId: any,
    wasteId: any
  ) => Promise<void>
  editWaste: (
    wasteGroupId: any,
    wasteId: any,
    barcode: any,
    updatedData: any
  ) => Promise<void>
  editWasteRecipe: (
    wasteGroupId: any,
    wasteId: any,
    recipeId: any,
    updatedData: any
  ) => Promise<void>
  editWasteDish: (
    wasteGroupId: any,
    wasteId: any,
    dishId: any,
    updatedData: any
  ) => Promise<void>
}

const WasteContent = ({
  wasteGroup,
  tab,
  setTab,
  removeWaste,
  deleteWasteProduct,
  deleteWasteRecipe,
  deleteWasteDish,
  deleteWasteRecipeRecord,
  deleteWasteDishRecord,
  editWaste,
  editWasteRecipe,
  editWasteDish,
}: WasteContentProps) => {
  const modal = useContext(ModalContext)

  const getWasteActionConfig = (
    waste,
    item,
    tab: WasteTab,
    type: "edit" | "delete" | "deleteRecord"
  ) => {
    const wasteGroupId = wasteGroup?.id // Add guard for `wasteGroup`
    const timeFormatted = waste?.timeOfRecord
      ? format(new Date(waste.timeOfRecord), "dd-MM-yy - HH:mm")
      : "Waste"

    // Create reusable onEdit functions
    const editFunctions = {
      product: (product, wasteId, updatedData) =>
        product?.barcode &&
        editWaste(wasteGroupId, wasteId, product.barcode, updatedData),
      recipe: (recipe, wasteId, updatedData) =>
        recipe?.id &&
        editWasteRecipe(wasteGroupId, wasteId, recipe.id, updatedData),
      dish: (dish, wasteId, updatedData) =>
        dish?.id && editWasteDish(wasteGroupId, wasteId, dish.id, updatedData),
    }

    // Create reusable deleteRecord titles
    const deleteRecordTitles = {
      product: `Delete waste records for ${timeFormatted}`,
      recipe: `Delete waste records for ${
        item.recipe?.name || "Waste"
      } ${timeFormatted}`,
      dish: `Delete waste records for ${
        item.dish?.name || "Waste"
      } ${timeFormatted}`,
    }

    const wasteActionConfig = {
      products: {
        edit: item.product
          ? {
              product: item.product,
              onEdit: editFunctions.product,
            }
          : null, // Return null if the product is undefined
        deleteRecord: item.product
          ? {
              title: deleteRecordTitles.product,
              onConfirm: () => removeWaste(waste, item, wasteGroupId),
            }
          : null,
        delete: item.product
          ? {
              title: `Delete all waste records for ${
                item.product?.name || "this product"
              }?`,
              onConfirm: () => {
                deleteWasteProduct(wasteGroupId, waste)
              },
            }
          : null,
      },
      recipes: {
        edit: item.recipe
          ? {
              recipe: item.recipe,
              onEdit: editFunctions.recipe,
            }
          : null,
        deleteRecord: item.recipe
          ? {
              title: deleteRecordTitles.recipe,
              onConfirm: () =>
                deleteWasteRecipeRecord(wasteGroupId, item.recipe.id, waste.id),
            }
          : null,
        delete: item.recipe
          ? {
              title: `Delete all waste records for ${item.recipe.name}?`,
              onConfirm: () => deleteWasteRecipe(wasteGroupId, item.recipe.id),
            }
          : null,
      },
      dishes: {
        edit: item.dish
          ? {
              dish: item.dish,
              onEdit: editFunctions.dish,
            }
          : null,
        deleteRecord: item.dish
          ? {
              title: deleteRecordTitles.dish,
              onConfirm: () =>
                deleteWasteDishRecord(wasteGroupId, item.dish.id, waste.id),
            }
          : null,
        delete: item.dish
          ? {
              title: `Delete all waste records for ${item.dish.name}?`,
              onConfirm: () => deleteWasteDish(wasteGroupId, item.dish.id),
            }
          : null,
      },
    }

    return wasteActionConfig[tab]?.[type] || null // Safeguard against undefined values
  }

  const currency = null

  const onDeleteWasteRecord = (waste, singleWaste, itemType) => {
    let baseConfig = {
      type: "danger",
      text: "Are you sure you want to delete this record?",
      confirmButtonText: "Delete",
    }

    baseConfig = {
      ...baseConfig,
      ...getWasteActionConfig(waste, singleWaste, itemType, "deleteRecord"),
    }

    modal.showModal(ConfirmModal, baseConfig)
  }

  const onDeleteWasteTotal = (waste, itemType) => {
    let baseConfig = {
      type: "danger",
      text: "Are you sure you want to delete all records?",
      confirmButtonText: "Delete",
    }
    baseConfig = {
      ...baseConfig,
      ...getWasteActionConfig(waste, waste, itemType, "delete"),
    }

    modal.showModal(ConfirmModal, baseConfig)
  }

  const onEditWaste = (singleWaste, item, itemType: WasteTab) => {
    let baseConfig = {
      waste: singleWaste,
      wasteGroup,
    }

    baseConfig = {
      ...baseConfig,
      ...getWasteActionConfig(singleWaste, item, itemType, "edit"),
    }

    modal.showModal(EditWasteModal, baseConfig)
  }

  const calculateTotalCostWasteItems = (items) => {
    return items.reduce(
      (accumulator, item) =>
        item.totalCost ? accumulator + item.totalCost : accumulator + 0,
      0
    )
  }

  const getTotalCostForType = (type: WasteTab) => {
    return wasteGroup[type]
      ? currency
        ? formatCurrencyValue(calculateTotalCostWasteItems(wasteGroup[type]))
        : roundNumber(calculateTotalCostWasteItems(wasteGroup[type]))
      : 0
  }

  const tabContent = (itemType: WasteTab) => {
    return (
      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="p-2 sm:p-5 flex-grow overflow-auto">
          {wasteGroup[itemType]?.length > 0 ? (
            wasteGroup[itemType].map((groupItem, index) => {
              return (
                <React.Fragment key={`${itemType}-${index}`}>
                  <WasteTable
                    type={itemType}
                    waste={groupItem}
                    onEditWaste={(singleWaste, item) =>
                      onEditWaste(singleWaste, item, itemType)
                    }
                    onDeleteWasteRecord={(waste, item) =>
                      onDeleteWasteRecord(waste, item, itemType)
                    }
                    onDeleteWasteTotal={(waste) =>
                      onDeleteWasteTotal(waste, itemType)
                    }
                    wasteGroup={wasteGroup}
                  />
                </React.Fragment>
              )
            })
          ) : (
            <span className="text-sm">No waste items to show</span>
          )}
        </div>
        <div className={styles.total}>
          <span className="font-sansBold text-darkOrange">{`${
            wasteGroup[itemType]?.length || 0
          } items`}</span>
          <span className="font-sansBold text-darkOrange">{`Total cost: ${getTotalCostForType(
            itemType
          )}`}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.content}>
      <div className="flex flex-grow flex-col overflow-hidden">
        <Tabs
          className="growyze-tabs bg-white bg-opacity-95 border-b pt-1 border-gray-300"
          initialTab={tab || "products"}
          onTabChange={(tab: WasteTab) => setTab(tab)}
        >
          <Tab title="Items" tabKey="products">
            {tabContent("products")}
          </Tab>
          <Tab
            title="Recipes"
            tabKey="recipes"
            showInfo="Recipe ingredients can be found in the 'Items' section"
          >
            {tabContent("recipes")}
          </Tab>
          <Tab
            title="Dishes"
            tabKey="dishes"
            showInfo="Dish ingredients can be found in the 'Items' section"
          >
            {tabContent("dishes")}
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default WasteContent
