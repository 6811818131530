import { get, remove, post, put, getToken } from "./api"
import jwtDecode from "jwt-decode"
import { isLoggedIn } from "./auth"

export const createCustomer = (params) => {
  return post("/payments/customer", params)
}
export const createPaymentIntent = (params) => {
  return post("/payments/create-payment-intent", params)
}

export const getPaymentHistory = (params) => {
  return get(
    `/payments/payment-history/${encodeURIComponent(String(params.email))}`
  )
}

export const confirmPayment = (params) => {
  return get(
    `/payments/confirm-payment/${encodeURIComponent(String(params.intentId))}`
  )
}

export const subscribeCustomer = (params) => {
  return post("/payments/create-subscription", params)
}

export const updateCustomerSubscription = (params) => {
  return put(`/payments/customer/subscribe`, params)
}

export const cancelCustomerSubscription = () => {
  return remove("/payments/customer/subscribe")
}

export const updatePaymentPlan = (params) => {
  return put(
    `/payment-plans?orgId=${params.orgId}&type=${params.type}&validDays=${params.validDays}`,
    params
  )
}

export const getStripePublicKey = () => {
  if (!isLoggedIn()) {
    return Promise.resolve(null)
  }
  return get(
    "/payments/pk",
    {},
    {
      plain: true,
    }
  )
}

export const getMyPaymentPlan = async () => {
  if (!isLoggedIn()) {
    return Promise.resolve(null)
  }
  /**
   * temporary: get plan from token and merge with current plan
   * Reason: back-end does not update the plan from /payment-plans/me when the plan expires,
   * the token as the most up-to-date plan information
   *
   * @return  {Object}  returns the full plan objct
   */
  const token = (await getToken()) as string
  const tokenArray = token.split(" ")
  const tokenInfo = jwtDecode(tokenArray[1]) as any

  const plan = await get("/payment-plans/me")
  if (plan && tokenInfo.plan && tokenInfo.plan.type) {
    plan.type = tokenInfo.plan.type
  }
  if (plan && tokenInfo.plan && tokenInfo.plan.enabledFeatures) {
    plan.enabledFeatures = tokenInfo.plan.enabledFeatures
  }
  return Promise.resolve(plan)
}
