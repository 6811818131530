import React, { useState, useEffect, useContext, useRef } from "react"
import { Link } from "gatsby"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import {
  faExclamationCircle,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import Header from "components/dashboard/Header/Header"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/modal/ModalContext"
import {
  editRecipeTabs,
  editRecipeInfoTabs,
  percentageAndFullMeasures,
  allergens,
} from "services/constants"
import CustomSelect from "components/forms/CustomSelect"
import TextArea from "components/forms/TextArea"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import AsyncSelect from "components/forms/AsyncSelect"
import { getProducts } from "services/products/products"
import {
  getRecipe,
  updateRecipe,
  createRecipe,
  removeRecipe,
  updateRecipeFeaturedImage,
  updateRecipeFiles,
  reassignRecipeToOrg,
} from "services/recipes/recipes"
import { updateOnboardingSteps } from "services/onboarding"
import * as Yup from "yup"
import {
  findValueIcon,
  findValueLabel,
  getProductMeasures,
  normalizeIngredientMeasures,
  normalizeNullValues,
  printPage,
  roundNumber,
} from "services/helpers"

import Helmet from "react-helmet"
import { Formik, Form } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import { showError, showSuccess, showErrors } from "services/toast"
import TextInput from "../../forms/TextInput"
import * as styles from "./EditRecipe.module.css"
import { navigate } from "gatsby-link"
import FeaturedImage from "components/common/FeaturedImage/FeaturedImage"
import RecipePortionsForm from "./RecipePortionsForm"
import FileList from "components/common/FileList/FileList"
import usePermissions from "hooks/usePermissions"
import { apiResponseIsError } from "../../../services/helpers"
import PrintRecipe from "../PrintRecipe/PrintRecipe"
import LocationSelection from "components/common/LocationSelection/LocationSelection"
import { Category, Permission, Product } from "services/types"
import RecipeAndDishWaste from "./RecipeAndDishWaste"
import ReactTooltip from "react-tooltip"
import { useFileOwner } from "hooks/useFileOwner"
import useCategories from "hooks/useCategories"
import SalesAndProfit from "components/common/SalesAndProfit/SalesAndProfit"

const Schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  posId: Yup.string(),
  salePrice: Yup.number(),
  barcode: Yup.string().nullable(true),
  description: Yup.string().nullable(true),
  notes: Yup.string().nullable(true),
  yield: Yup.object()
    .shape({
      size: Yup.number().nullable(true),
      measure: Yup.string().nullable(true),
    })
    .nullable(),
})

interface Props {
  recipeId: string
  initialValues: { [key: string]: any }
  onSubmitCallback: (data: any) => {}
  onClose: () => void
}

interface Ingredient {
  product: Product | {}
  usedQty: number
}

const EditRecipe = ({
  recipeId: recipeIdProp,
  initialValues = {},
  onSubmitCallback,
  onClose,
}: Props) => {
  const {
    onboardingSteps,
    organizationPermissions,
    selectedAiGeneratedRecipe,
  } = useContext(GlobalStateContext)

  const isAllowedToManageLocations = organizationPermissions?.general?.isMain
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const [ingredients, setIngredients] = useState<Ingredient[]>([])
  const formRef = useRef()
  const [notFound, setNotFound] = useState(false)
  const [recipeId, setRecipeId] = useState(recipeIdProp)
  const [initialProducts, setInitialProducts] = useState([])
  const [stats, setStats] = useState({
    allergens: null,
    mayContainAllergens: null,
    name: "",
    barcode: "",
    posId: "",
    salePrice: "",
    totalCost: "",
    totalCostPercent: "",
    profit: "",
    profitPercent: "",
    description: "",
    otherIngredients: "",
    otherIngredientsCost: "",
    organizations: [],
    waste: {
      cost: "",
      percent: "",
    },
    notes: "",
    portionCount: null,
    portion: {
      size: null,
      measure: "",
    },
    yield: {
      size: null,
      measure: "",
    },
    ...initialValues,
  })
  const [featuredFile, setFeaturedFile] = useState(null)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [infoTabs, setInfoTab] = useState("generalInfo")
  const [tabs, setTab] = useState("ingredients")
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([])
  const [isWastePercentage, setIsWastePercentage] =
    React.useState<boolean>(true)

  const filterFunction = (categories: Category[]) => {
    return categories
      .filter((category) => category.active)
      .map((category) => ({
        value: category.name,
        label: category.name,
      }))
  }
  const { categories } = useCategories(filterFunction)

  const permissionObj = usePermissions("Recipes & Dishes") as Permission
  const showAiRecipe =
    selectedAiGeneratedRecipe &&
    window.location.search.includes("aiGenerated=true")
  const filesOwner = useFileOwner(stats)

  const generalInfoTabs = [
    ...editRecipeInfoTabs,
    ...(isAllowedToManageLocations
      ? [
          {
            key: "locations",
            label: "Manage locations",
          },
        ]
      : []),
  ]

  const isAsideView = !!onClose

  const recipeActions = [
    {
      key: "recipe.duplicate",
      title: "Duplicate",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    // { key: "recipe.add_to_dish", title: "Add to dish", type: "default" },
    {
      key: "recipe.print",
      title: "Print",
      type: "default",
      disabled: !permissionObj?.permissions.read,
    },
    {
      key: "recipe.delete",
      title: "Delete",
      type: "danger",
      disabled:
        !permissionObj?.permissions.delete ||
        !organizationPermissions?.recipes?.remove,
    },
  ]

  const formatRecipeData = (recipeData) => {
    const { salePrice } = recipeData
    return {
      ...recipeData,
      salePrice: salePrice > 0 ? salePrice : null,
    }
  }

  const getRecipeData = async (id) => {
    if (id) {
      const recipeData = await getRecipe(id)

      if (recipeData.status === 404) {
        setNotFound(true)
      } else {
        setIngredients(recipeData.ingredients)
        setStats(formatRecipeData(recipeData))
        setFiles(recipeData.files)
        setFeaturedFile(recipeData.featuredFile)
      }
      setLoading(false)
    } else {
      setLoading(false)
      setIngredients([
        {
          product: {},
          usedQty: 0,
        },
      ]) // Set standard products
    }
  }

  const getInitialProducts = async () => {
    const initialProductsData = await getProducts({
      partialProductName: "",
      size: 40,
      extended: true,
    })

    setInitialProducts(
      initialProductsData.content.map((prd) => ({
        label: `${prd.name} ${
          prd.size && prd.measure ? "- " + prd.size + prd.measure : ""
        } - ${prd.price} (${prd.supplierName})`,
        value: prd.name,
        ...prd,
      }))
    )
  }

  const saveLocations = async (recipeIdentifier: string) => {
    const params = {
      organizations: selectedOrgs,
    }
    return await reassignRecipeToOrg(recipeIdentifier, params)
  }

  useEffect(() => {
    if (recipeIdProp) {
      getRecipeData(recipeIdProp)
    }
  }, [recipeIdProp])

  useEffect(() => {
    checkAiGeneratedRecipe()
    getInitialProducts()
  }, [])

  useEffect(() => {
    setIsWastePercentage(stats?.waste?.percent ? true : false)
  }, [stats])

  const checkAiGeneratedRecipe = () => {
    if (showAiRecipe) {
      const instructions = selectedAiGeneratedRecipe.instructions
        .map((instruction, i) => {
          return i + 1 + ". " + instruction
        })
        .join("\n")
      setStats({
        ...stats,
        name: selectedAiGeneratedRecipe.name,
        description: instructions,
      })
    }
  }

  const saveRecipe = async (data) => {
    const params = {
      ...data,
      portion: data.portion.size || data.portion.measure ? data.portion : null,
      yield: data.yield.size || data.yield.measure ? data.yield : null,
      ingredients: ingredients.map(normalizeIngredientMeasures),
      targetMarginPercent: roundNumber(data.targetMarginPercent),
    }

    if (isWastePercentage) {
      params.waste = { ...params.waste, cost: null }
    } else {
      params.waste = { ...params.waste, percent: null }
    }

    if (recipeId) {
      const result = await updateRecipe(recipeId, params)
      if (result.status === 400 || result.status === 409) {
        return showErrors(result)
      } else {
        setIngredients(result.ingredients)
        setRecipeId(result.id)
        setStats(formatRecipeData(result))
        showSuccess("Recipe successfully saved")
        if (onSubmitCallback) {
          onSubmitCallback(result)
        }
      }
    } else {
      try {
        const result = await createRecipe(params)
        if (result) {
          if (result.status === 400 || result.status === 409) {
            return showErrors(result)
          }

          if (files.length > 0) {
            setFileLoading(true)
            const savedFiles = await updateRecipeFiles(result.id, {
              files: files,
            })
            if (
              savedFiles.status &&
              (savedFiles.status === 400 || savedFiles.status === 409)
            ) {
              return showErrors(savedFiles)
            }
            setFileLoading(false)
          }

          /** Update locations for this recipe */
          if (isAllowedToManageLocations) {
            await saveLocations(result.id)
          }

          if (featuredFile) {
            const savedFeaturedFile = await updateRecipeFeaturedImage(
              result.id,
              featuredFile
            )
            if (
              savedFeaturedFile.status &&
              (savedFeaturedFile.status === 400 ||
                savedFeaturedFile.status === 409)
            ) {
              return showErrors(savedFeaturedFile)
            }
          }

          if (onSubmitCallback) {
            onSubmitCallback(result)
            onClose() //Closes recipe modal if callback is provided
          }

          afterCreateRecipe(result.id)
        }
      } catch (e: any) {
        showError(e.message || "something went wrong")
      }
    }
  }

  const afterCreateRecipe = async (recipeId) => {
    showSuccess("Recipe created!")

    const params = {
      ...onboardingSteps,
      hasAddedRecipe: true,
    }

    const updated = await updateOnboardingSteps(params)

    if (updated && !updated.message) {
      dispatch({
        type: "UPDATE_ONBOARDING_STEPS",
        payload: { onboardingSteps: updated },
      })
    }

    if (isAsideView) {
      await getRecipeData(recipeId)
    } else {
      // Redirect to detail page
      navigate("/dashboard/products/cookbook/recipes/" + recipeId)
    }
  }

  const handleActionClick = (action) => {
    const { type } = action
    switch (type) {
      case "recipe.delete":
        onDelete()
        break
      case "recipe.duplicate":
        onDuplicate()
        break
      case "recipe.print":
        printPage()
        break
      default:
        break
    }
  }

  const onDelete = () => {
    // @ts-ignore
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete recipe",
      text: "Are you sure you want to delete this recipe?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteRecipe(),
    })
  }

  const onDuplicate = async () => {
    const newRecipe = {
      ...stats,
      posId: null,
      dishes: null,
      files: [],
      ingredients,
      featuredFile: null,
      name: `Copy of ${stats.name}`,
      id: null,
    }

    const currentFiles = files
    const currentFeaturedFile = featuredFile

    try {
      const result = await createRecipe(newRecipe)

      if (apiResponseIsError(result)) {
        return showError("Something went wrong")
      }

      if (currentFiles && currentFiles.length > 0) {
        await updateRecipeFiles(result.id, { files: currentFiles })
      }
      if (currentFeaturedFile) {
        await updateRecipeFeaturedImage(result.id, currentFeaturedFile)
      }

      showSuccess("Recipe duplicated!")

      if (isAsideView) {
        await getRecipeData(result.id)
      } else {
        navigate(`/dashboard/products/cookbook/recipes/${result.id}`)
      }
    } catch {
      showError("Something went wrong")
    }
  }

  const deleteRecipe = async () => {
    const deleted = await removeRecipe(recipeId)
    if (deleted) {
      showSuccess("Recipe deleted!")

      if (isAsideView) {
        onClose()
      } else {
        navigate("/dashboard/products/cookbook/recipes")
      }
    }
  }

  const loadProductOptions = (inputValue) => {
    return getProducts({
      partialProductName: inputValue,
      size: 40,
      extended: true,
    })
  }

  const updateFeaturedImg = async (file) => {
    setFeaturedFile(file)

    // If recipe doesn't exist, don't try to save the featured img
    if (!recipeId) {
      return
    }
    await updateRecipeFeaturedImage(recipeId, file)
  }

  const handleFeaturedImgChange = ({ action, file }) => {
    switch (action) {
      case "file-added":
        updateFeaturedImg(file)
        break
      case "file-removed":
        updateFeaturedImg(null)
        break
      default:
        break
    }
  }

  const updateFiles = (files) => {
    return updateRecipeFiles(recipeId, { files: files })
  }

  const handleFileUploaded = async (newFiles) => {
    setFileLoading(true)

    const newFileList = [...(files || []), ...newFiles]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    // If there is no recipe yet, don't try to save the files
    if (!recipeId) {
      setFileLoading(false)
      return
    }

    try {
      const res = await updateFiles(newFileList)
      setStats(formatRecipeData(res))
      setFileLoading(false)
    } catch (e) {
      setFileLoading(false)
      showError(e.message || "File upload failed")
    }
  }

  const handleFileDeleted = async (file) => {
    setFileLoading(true)
    const newFileList = [...files.filter((f) => f.fileId !== file.fileId)]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    // If there is no recipe yet, don't try to save the files
    if (!recipeId) {
      setFileLoading(false)
      return
    }

    try {
      const res = await updateFiles(newFileList)
      setStats(formatRecipeData(res))
      setFileLoading(false)
    } catch (e) {
      setFileLoading(false)
      showError(e.message || "File delete failed")
    }
  }

  return (
    <>
      <Helmet>
        <title>{recipeId ? "Edit Recipe" : "Add Recipe"}</title>
      </Helmet>
      <div className={styles.container}>
        {isAsideView ? null : (
          <Header back title={recipeId ? "Edit Recipe" : "Add Recipe"} />
        )}

        <div className={styles.content}>
          {notFound && (
            <div className="text-center mt-10">
              <h2>No Record Found</h2>
            </div>
          )}

          {!notFound && !loading && (
            <Formik
              enableReinitialize
              innerRef={formRef}
              validateOnMount
              onSubmit={saveRecipe}
              initialValues={normalizeNullValues(stats)}
              validationSchema={Schema}
            >
              {({ isSubmitting, values }) => {
                const recipeHasAllergens = [
                  ...(values.allergens || []),
                  ...(values.mayContainAllergens || []),
                ].length

                return (
                  <>
                    <FormikEffect
                      onChange={() => {}}
                      onValidationChange={() => {}}
                    />
                    <Form
                      className={`relative overflow-hidden flex flex-grow flex-col ${styles.form}`}
                    >
                      <div className="flex flex-col flex-grow overflow-auto">
                        <div
                          className={`growyze-tabs bg-gray-100 ${styles.tabs}`}
                        >
                          <ul>
                            {generalInfoTabs.map((tab) => {
                              return (
                                <li key={tab.key}>
                                  <button
                                    type="button"
                                    className={
                                      infoTabs === tab.key ? "active" : ""
                                    }
                                    onClick={() => setInfoTab(tab.key)}
                                  >
                                    {tab.label}
                                  </button>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        {
                          {
                            generalInfo: (
                              <div className="flex items-stretch flex-wrap lg:px-6 pt-4">
                                <div className="form-group w-full md:w-2/5 px-2 mb-4">
                                  <div className="input-container">
                                    <TextInput
                                      name="name"
                                      required={true}
                                      label="Menu Item Name"
                                      disabled={
                                        !permissionObj?.permissions.modify
                                      }
                                      type="text"
                                      value={values.name}
                                      placeholder="Menu Item name"
                                      className="form-control form-control--first"
                                    />
                                  </div>
                                  <div className="input-container">
                                    <CustomSelect
                                      showValidations={false}
                                      required={false}
                                      name="category"
                                      label="Category"
                                      isDisabled={
                                        !permissionObj?.permissions.modify
                                      }
                                      placeholder="Category"
                                      defaultValue={values.category}
                                      className="form-control"
                                      options={categories}
                                    ></CustomSelect>
                                  </div>
                                  <div className="input-container">
                                    <TextInput
                                      name="barcode"
                                      required={false}
                                      label="Barcode (optional)"
                                      disabled={
                                        !permissionObj?.permissions.modify
                                      }
                                      type="text"
                                      placeholder="Barcode (optional)"
                                      className="form-control form-control--last"
                                    />
                                  </div>
                                </div>

                                <div className="form-group self-stretch flex flex-col w-full md:w-1/5 px-2 mb-4">
                                  <h2 className="mb-3 font-sansSemiBold font-semibold text-gray-800">
                                    Featured image
                                  </h2>
                                  <FeaturedImage
                                    onChange={handleFeaturedImgChange}
                                    currentFile={featuredFile}
                                    disabled={
                                      !permissionObj?.permissions.modify
                                    }
                                    clientKey="recipe"
                                  />
                                </div>

                                <div className="form-group self-stretch flex flex-col flex-1 w-full md:w-1/5 px-2 mb-4">
                                  <span className="inline-flex items-baseline justify-start">
                                    <h2 className="mr-1 mb-3 font-sansSemiBold font-semibold text-gray-800">
                                      Allergens
                                    </h2>
                                    <ReactTooltip
                                      id={`infoBox-${recipeHasAllergens}`}
                                      type="light"
                                      place="top"
                                      effect="solid"
                                      border={true}
                                      borderColor="#e2e8f0"
                                    />
                                    <span
                                      data-tip="growyze accepts no liability for the use of any information resulting from the use of this calculator. It is your responsibility to ensure that this information is true and correct"
                                      data-for={`infoBox-${recipeHasAllergens}`}
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                  </span>
                                  {!recipeHasAllergens ? (
                                    <p className="text-xs">No allergens</p>
                                  ) : (
                                    <>
                                      {values.allergens?.length > 0 ? (
                                        <div className="mb-4">
                                          <h4 className="text-xs">Contains</h4>
                                          <div className="flex flex-wrap">
                                            {values.allergens?.map(
                                              (allergen) => (
                                                <span
                                                  key={allergen}
                                                  className="w-14 m-2 text-xs flex flex-col items-center capitalize text-center"
                                                >
                                                  <img
                                                    className="w-8 p-1"
                                                    src={findValueIcon(
                                                      allergens,
                                                      allergen
                                                    )}
                                                  />
                                                  {findValueLabel(
                                                    allergens,
                                                    allergen
                                                  )}
                                                </span>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      ) : null}
                                      {values.mayContainAllergens?.length >
                                      0 ? (
                                        <div>
                                          <h4 className="text-xs">
                                            May contain
                                          </h4>
                                          <div className="flex flex-wrap">
                                            {values.mayContainAllergens?.map(
                                              (allergen) => (
                                                <span
                                                  key={allergen}
                                                  className="w-14 m-2 text-xs flex flex-col items-center capitalize text-center"
                                                >
                                                  <img
                                                    className="w-8 p-1"
                                                    src={findValueIcon(
                                                      allergens,
                                                      allergen
                                                    )}
                                                  />
                                                  {findValueLabel(
                                                    allergens,
                                                    allergen
                                                  )}
                                                </span>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                              </div>
                            ),
                            salesAndProfit: <SalesAndProfit values={values} />,
                            locations: (
                              <div className="px-4 lg:px-6 pt-4">
                                <h3 className={styles.sectionHeading}>
                                  Locations
                                </h3>
                                <p className="text-gray-700 text-sm mb-3">
                                  Distribute this recipe across your locations
                                </p>
                                <LocationSelection
                                  currentSelection={selectedOrgs}
                                  onSelectionChange={(orgs) =>
                                    setSelectedOrgs(orgs)
                                  }
                                  itemId={recipeId}
                                  itemType="recipe"
                                  itemClass={"md:w-1/2 lg:w-1/3"}
                                />
                              </div>
                            ),
                          }[infoTabs]
                        }

                        <div
                          className={`growyze-tabs bg-gray-100 ${styles.tabs}`}
                        >
                          <ul>
                            {editRecipeTabs.map((tab) => {
                              return (
                                <li key={tab.key}>
                                  <button
                                    type="button"
                                    className={tabs === tab.key ? "active" : ""}
                                    onClick={() => setTab(tab.key)}
                                  >
                                    {tab.label}
                                  </button>
                                </li>
                              )
                            })}
                          </ul>
                        </div>

                        <div>
                          {
                            {
                              ingredients: (
                                <div className={styles.ingredientsWrapper}>
                                  {showAiRecipe && (
                                    <div className="w-full bg-gray-100 border border-gray-300 rounded p-4 text-gray-600 mb-4 mt-2">
                                      <h2 className="pb-3">
                                        To complete your recipe, add or create
                                        the following ingredients:
                                      </h2>
                                      {selectedAiGeneratedRecipe.ingredients.map(
                                        (ingr, i) => {
                                          return (
                                            <div key={i}>
                                              {ingr.name} - {ingr.qty}
                                            </div>
                                          )
                                        }
                                      )}
                                    </div>
                                  )}
                                  {ingredients?.map((ingr, index) => {
                                    const measures = getProductMeasures(
                                      ingr.product.measure
                                    ).concat(percentageAndFullMeasures)

                                    const defOptions = () => {
                                      const names = ingredients.map(
                                        (ingr) => ingr.product.name
                                      )
                                      return initialProducts.filter((p) => {
                                        return !names.includes(p.value)
                                      })
                                    }
                                    return (
                                      <div
                                        className={styles.ingredientWrapper}
                                        key={`ingredient-${ingr.product.barcode}-index-${index}`}
                                      >
                                        <span
                                          className={
                                            styles.ingredientsCount +
                                            (ingr.product.isDeleted
                                              ? " bg-primaryPinkLighter bg-opacity-20"
                                              : " bg-paleBlue")
                                          }
                                        >
                                          {index + 1}
                                        </span>
                                        <div
                                          className={styles.ingredientDetails}
                                        >
                                          {ingr.product.isDeleted && (
                                            <div className="pl-1 pr-2 md:pr-0">
                                              <span
                                                data-for="deleted_ingredient_tooltip"
                                                data-tip="This ingredient is deleted."
                                              >
                                                <FontAwesomeIcon
                                                  icon={faExclamationCircle}
                                                  size="lg"
                                                  className="text-error"
                                                />
                                              </span>

                                              <ReactTooltip
                                                id={
                                                  "deleted_ingredient_tooltip"
                                                }
                                                type="light"
                                                place="right"
                                                effect="float"
                                                border={true}
                                                borderColor="#e2e8f0"
                                              />
                                            </div>
                                          )}

                                          <div className="flex flex-col flex-grow">
                                            <div
                                              className={`${styles.formSearchRow} input-container`}
                                            >
                                              <AsyncSelect
                                                promise={loadProductOptions}
                                                id={`${
                                                  (ingr.product?.name |
                                                    "Product") +
                                                  index
                                                }`}
                                                defaultOptions={defOptions()}
                                                disabled={
                                                  !permissionObj?.permissions
                                                    .modify
                                                }
                                                placeholder="Please select a product"
                                                defaultValue={
                                                  ingr.product.name
                                                    ? {
                                                        label:
                                                          ingr.product.name,
                                                        value:
                                                          ingr.product.name,
                                                      }
                                                    : true
                                                }
                                                isClearable={true}
                                                optionLabel={(option) => {
                                                  return `${option.name} ${
                                                    option.size &&
                                                    option.measure
                                                      ? "- " +
                                                        option.size +
                                                        option.measure
                                                      : ""
                                                  } - ${option.price} (${
                                                    option.supplierName
                                                  })`
                                                }}
                                                optionValue="name"
                                                includeMeta
                                                noOptionsMessage={() =>
                                                  "Type to search"
                                                }
                                                onChange={(val) => {
                                                  if (val) {
                                                    setIngredients(
                                                      (prevState) => {
                                                        //Setting specific key in previous state to specific values from current val
                                                        const temp = [
                                                          ...prevState,
                                                        ]
                                                        const keysToExtract = [
                                                          "id",
                                                          "name",
                                                          "barcode",
                                                          "category",
                                                          "description",
                                                          "measure",
                                                          "size",
                                                          "subCategory",
                                                          "price",
                                                          "supplierId",
                                                          "supplierName",
                                                          "allergens",
                                                        ]
                                                        keysToExtract.forEach(
                                                          (eachKey) => {
                                                            temp[index].product[
                                                              eachKey
                                                            ] = val[eachKey]
                                                          }
                                                        )
                                                        return temp
                                                      }
                                                    )
                                                  }
                                                }}
                                                filterOptions={(option) => {
                                                  const names = ingredients.map(
                                                    (ingr) => ingr.product.name
                                                  )
                                                  return !names.includes(
                                                    option.value
                                                  )
                                                }}
                                              />
                                            </div>

                                            <div className="flex items-center justify-start">
                                              <div
                                                className={`input-container flex items-center justify-between sm:mr-2 text-primaryBlue ${
                                                  styles.rowCell
                                                } ${
                                                  ingr.product.name &&
                                                  !ingr.product.measure &&
                                                  styles.cellWrapperError
                                                }`}
                                              >
                                                <div className="flex flex-col">
                                                  <div
                                                    className={`font-sansSemiBold text-sm font-semibold bg-transparent px-2 ${styles.fixedCellValue}`}
                                                  >
                                                    <ProductIcon
                                                      inCase={false}
                                                      product={ingr.product}
                                                    ></ProductIcon>

                                                    {ingr.product.measure
                                                      ? `${ingr.product.size} ${ingr.product.measure}`
                                                      : "?"}
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className={`input-container flex items-center justify-between sm:mr-2 ${styles.rowCell}`}
                                              >
                                                <div className="flex flex-col">
                                                  <div
                                                    className={`input-container flex items-center ${styles.fixedCellValue}`}
                                                  >
                                                    {ingr.product.price
                                                      ? ingr.product.price
                                                      : ""}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className={`input-container flex items-center justify-between sm:mr-2 ${styles.rowCell}`}
                                              >
                                                <div className="flex flex-col">
                                                  <div
                                                    className={`input-container flex items-center ${styles.fixedCellValue}`}
                                                  >
                                                    {ingr.product.supplierName
                                                      ? `(${ingr.product.supplierName})`
                                                      : ""}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {ingr.product.name &&
                                        !ingr.product.measure ? (
                                          <div
                                            className={`input-container max-w-xs px-4 py-2 flex flex-col items-center self-center justify-between mr-auto`}
                                          >
                                            <p
                                              className={"text-xs text-red-600"}
                                            >
                                              Item missing size/measure. Go to{" "}
                                              <Link
                                                className="underline"
                                                to="/dashboard/products/items"
                                              >
                                                Products → Items
                                              </Link>{" "}
                                              to complete the set up
                                            </p>
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              className={`flex flex-col justify-center px-4 py-2 mr-auto`}
                                            >
                                              <span className="text-sm mb-1 text-gray-700">
                                                Quantity
                                              </span>
                                              <div className="flex items-stretch">
                                                {ingr.product.measure && (
                                                  <>
                                                    <div
                                                      className={`input-container items-center -mr-px ${styles.subValueContainer}`}
                                                    >
                                                      <TextInput
                                                        required={true}
                                                        name="qty"
                                                        label="Quantity"
                                                        type="number"
                                                        min={0}
                                                        step="any"
                                                        disabled={
                                                          !permissionObj
                                                            ?.permissions.modify
                                                        }
                                                        value={ingr.usedQty}
                                                        showValidations={false}
                                                        onChange={(val) => {
                                                          const newIngr =
                                                            [].concat(
                                                              ingredients
                                                            )
                                                          newIngr[
                                                            index
                                                          ].usedQty =
                                                            val.target.value

                                                          setIngredients(
                                                            newIngr
                                                          )
                                                        }}
                                                        placeholder="XX"
                                                        className="form-control form-control--topleft form-control--bottomleft"
                                                      />
                                                    </div>
                                                  </>
                                                )}

                                                {ingr.product.measure && (
                                                  <>
                                                    <div
                                                      className={`input-container ${styles.subValueContainer}`}
                                                    >
                                                      <CustomSelect
                                                        showValidations={false}
                                                        required={true}
                                                        key={`ingredient-${index}-measure`}
                                                        name="measure"
                                                        isDisabled={
                                                          !permissionObj
                                                            ?.permissions.modify
                                                        }
                                                        id="measure"
                                                        label="Measure"
                                                        placeholder="Measure"
                                                        onSelectCallback={(
                                                          val
                                                        ) => {
                                                          const newIngr =
                                                            [].concat(
                                                              ingredients
                                                            )
                                                          newIngr[
                                                            index
                                                          ].measure = val.value

                                                          setIngredients(
                                                            newIngr
                                                          )
                                                        }}
                                                        defaultValue={
                                                          ingr.measure
                                                            ? ingr.measure
                                                            : ingr.product
                                                                .measure
                                                        }
                                                        className="form-control form-control--topright form-control--bottomright flex-nowrap w-28"
                                                        options={measures}
                                                      />
                                                    </div>
                                                  </>
                                                )}
                                                {!ingr.product.measure && (
                                                  <p
                                                    className={
                                                      styles.inputPlaceHolder
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={
                                                        faExclamationTriangle
                                                      }
                                                      className="mr-2"
                                                    />
                                                    Please select a product
                                                  </p>
                                                )}
                                              </div>
                                            </div>

                                            <div
                                              className={`flex flex-col justify-center px-4 py-2 mr-auto w-40`}
                                            >
                                              <span className="text-sm mb-1 text-gray-700">
                                                Allergens
                                              </span>
                                              {!recipeHasAllergens ? (
                                                <p className="text-xs">
                                                  No allergens
                                                </p>
                                              ) : (
                                                <>
                                                  {ingr.product?.allergens
                                                    ?.length > 0 && (
                                                    <div className="text-xs mb-2">
                                                      <div className="text-gray-700">
                                                        Contains:
                                                      </div>
                                                      <div className="flex flex-wrap capitalize">
                                                        {ingr.product.allergens
                                                          .map((allergen) =>
                                                            findValueLabel(
                                                              allergens,
                                                              allergen
                                                            )
                                                          )
                                                          .join(", ")}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {ingr.product
                                                    ?.mayContainAllergens
                                                    ?.length > 0 && (
                                                    <div className="text-xs">
                                                      <div className="text-gray-700">
                                                        May contain:
                                                      </div>

                                                      <div className="flex flex-wrap text-xs capitalize">
                                                        {ingr.product.mayContainAllergens
                                                          .map((allergen) =>
                                                            findValueLabel(
                                                              allergens,
                                                              allergen
                                                            )
                                                          )
                                                          .join(", ")}
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </div>

                                            <div
                                              className={`input-container px-4 py-2 flex items-center justify-between ${styles.rowCell}`}
                                            >
                                              <div className="flex flex-col text-right">
                                                <span className="text-xs text-right font-semibold font-sansSemiBold uppercase mb-1 text-gray-600">
                                                  Cost
                                                </span>
                                                {ingr.cost !== undefined ? (
                                                  <div className="input-container text-red-600 font-semibold font-sansSemiBold">
                                                    {parseFloat(
                                                      ingr.cost
                                                    ).toFixed(2)}
                                                  </div>
                                                ) : (
                                                  <p
                                                    className={
                                                      styles.inputPlaceHolder
                                                    }
                                                  >
                                                    Save to see cost
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        <button
                                          type="button"
                                          onClick={(ev) => {
                                            ev.preventDefault()
                                            const newArr = [].concat(
                                              ingredients
                                            )
                                            newArr.splice(index, 1)
                                            setIngredients(newArr)
                                          }}
                                          disabled={
                                            !permissionObj?.permissions.modify
                                          }
                                          className="flex bg-primaryPinkLighter bg-opacity-20 border-l text-primaryPink flex-col items-center justify-center self-stretch"
                                          style={{ width: "31px" }}
                                        >
                                          <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                      </div>
                                    )
                                  })}
                                  <button
                                    className="button my-4 ml-auto button--autoWidth button--primaryGreen"
                                    type="button"
                                    disabled={
                                      !permissionObj?.permissions.modify
                                    }
                                    onClick={(e) => {
                                      e.preventDefault()
                                      const newIngr = [].concat(ingredients, {
                                        product: {},
                                        usedQty: undefined,
                                      })
                                      setIngredients(newIngr)
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      className="mr-2"
                                    />
                                    Add ingredient
                                  </button>

                                  <div className="flex flex-wrap">
                                    <div className="flex flex-wrap items-end w-full">
                                      <div className="flex flex-grow flex-col mb-4 md:mb-0 w-full md:w-auto md:pr-4">
                                        <div
                                          className={styles.formSearchRowHead}
                                        >
                                          Add any additional ingredients & their
                                          cost
                                        </div>
                                        <div className="flex items-center">
                                          <div
                                            className={`input-container w-full`}
                                          >
                                            <TextInput
                                              name="otherIngredients"
                                              label="Ingredient(s) description"
                                              type="text"
                                              disabled={
                                                !permissionObj?.permissions
                                                  .modify
                                              }
                                              placeholder="Ingredient(s) description"
                                              className="form-control form-control--first form-control--last"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="input-container flex items-center justify-between">
                                        <div className="flex flex-col">
                                          <div
                                            className={styles.formSearchRowHead}
                                          >
                                            Cost
                                          </div>
                                          <div className="input-container">
                                            <TextInput
                                              name="otherIngredientsCost"
                                              label="Cost"
                                              type="number"
                                              disabled={
                                                !permissionObj?.permissions
                                                  .modify
                                              }
                                              placeholder="Cost"
                                              className="form-control form-control--first form-control--last"
                                              showValidations={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                              portions: (
                                <div className="px-4 lg:px-6 py-6">
                                  <RecipePortionsForm recipeValues={values} />
                                </div>
                              ),
                              method: (
                                <div className="px-4 lg:px-6 py-6 w-full">
                                  <h4 className="mr-2 font-sansSemiBold font-semibold mb-4 text-gray-800">
                                    Method
                                  </h4>
                                  <div className="input-container">
                                    <TextArea
                                      showValidations={false}
                                      name="description"
                                      value={values.description}
                                      id="description"
                                      disabled={
                                        !permissionObj?.permissions.modify
                                      }
                                      className={`${styles.recipeDescription} form-control--first form-control--last`}
                                      placeholder="Add description/method here"
                                      label="Method"
                                      rows={10}
                                    />
                                  </div>
                                </div>
                              ),
                              waste: (
                                <RecipeAndDishWaste
                                  data={stats}
                                  isWastePercentage={isWastePercentage}
                                  setIsWastePercentage={setIsWastePercentage}
                                />
                              ),
                              files: (
                                <div className="m-4">
                                  <FileList
                                    files={files}
                                    filesOwner={filesOwner}
                                    isLoading={fileLoading}
                                    disabled={
                                      !permissionObj?.permissions.modify
                                    }
                                    clientKey="recipe"
                                    onUploaded={handleFileUploaded}
                                    onDeleted={handleFileDeleted}
                                  />
                                </div>
                              ),
                              notes: (
                                <div className="px-4 lg:px-6 py-6 w-full">
                                  <h4 className="mr-2 font-sansSemiBold font-semibold mb-4 text-gray-800">
                                    Notes
                                  </h4>
                                  <div className="input-container">
                                    <TextArea
                                      showValidations={false}
                                      name="notes"
                                      id="notes"
                                      disabled={
                                        !permissionObj?.permissions.modify
                                      }
                                      value={values.notes}
                                      className={`${styles.recipeDescription} form-control--first form-control--last`}
                                      placeholder="Any notes you like to add to this recipe?"
                                      label="notes"
                                      rows={10}
                                    />
                                  </div>
                                </div>
                              ),
                            }[tabs]
                          }
                        </div>
                      </div>

                      <div className="flex items-center justify-end bottom-0 text-right w-full bg-white px-4 lg:px-6 py-2 border-t">
                        {recipeId && (
                          <ActionsButton
                            options={recipeActions}
                            buttonClass={styles.actionBtn}
                            dropDirection="top"
                            onActionClick={(action) =>
                              handleActionClick(action)
                            }
                            asPortal={false}
                          />
                        )}
                        <button
                          className="button button--autoWidth button--primaryGreen"
                          type="submit"
                          disabled={
                            (loading && isSubmitting) ||
                            !permissionObj?.permissions.modify
                          }
                        >
                          {recipeId ? "Save" : "Create"}
                        </button>
                      </div>
                    </Form>
                    <PrintRecipe values={values} image={featuredFile} />
                  </>
                )
              }}
            </Formik>
          )}
        </div>
      </div>
    </>
  )
}

export default EditRecipe
