import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { roundNumber } from "services/helpers"

function ProductListWithCaseToggle({ items, setitems }) {
  const handleItemCaseChange = (item) => {
    setitems((prevState) => {
      const temp = [...prevState]
      const obj = temp.find((a) => a.id === item.id)
      if (obj)
        obj._isPackSelected = obj.productCase
          ? !obj._isPackSelected
          : obj._isPackSelected
      return temp
    })
  }

  return (
    <>
      {items.map((item: any, idx: number) => {
        const hasCase = item.productCase
        const isPackSelected = Boolean(hasCase && item._isPackSelected)
        const activeClasses =
          " bg-white border-gray-200 border rounded-md shadow-sm text-opacity-100"

        return (
          <div
            key={item.id + idx}
            className="border-b last:border-b-0 group flex flex-row items-center"
          >
            <div
              className={
                "flex flex-col flex-shrink-0 w-14 self-stretch relative p-2 bg-paleBlue border-r"
              }
              onClick={() => hasCase && handleItemCaseChange(item)}
            >
              <button
                className={`h-1/2 py-2 text-opacity-50 text-primaryBlue ${
                  isPackSelected === false ? activeClasses : "bg-paleBlue"
                }`}
                role="button"
              >
                <FontAwesomeIcon icon={faWineBottle} />
              </button>
              {hasCase && (
                <button
                  className={`h-1/2 py-2 text-opacity-50 text-primaryBlue ${
                    isPackSelected === true ? activeClasses : "bg-paleBlue"
                  }`}
                  role="button"
                >
                  <FontAwesomeIcon icon={faCube} />
                </button>
              )}
            </div>

            <div className="flex flex-col m-4 w-full">
              <div className="flex flex-col text-sm sm:text-base">
                <span>{item.name}</span>
                <div className="mt-2">
                  <span className="mr-2 font-semibold">
                    {" "}
                    {item.size} {item.measure}{" "}
                    {item._isPackSelected && "x " + item.productCase.size}{" "}
                    {item.unit}
                  </span>
                  <span className="text-sm text-gray-600">{item.barcode}</span>
                </div>
              </div>

              <div className="flex mt-2 justify-between items-center">
                {/* <div className={`${hasCase && "cursor-pointer"} text-primaryBlueLighter font-semibold`} onClick={() => handleItemCaseChange(item)}>
                                                    <ProductIcon product={item} inCase={isPackSelected} />
                                                    <span className={`${hasCase && "underline"}`}>{(isPackSelected) ? "pack" : "single"}</span>
                                                    <span className="text-sm font-thin text-transparent group-hover:text-primaryPink ml-2 transition-colors">switch case</span>
                                                </div> */}
                <span className="text-sm">{item.supplierName}</span>
                <span className="inline-block font-semibold text-primaryPink">
                  {roundNumber(
                    isPackSelected ? item.productCase.price : item.price
                  )}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ProductListWithCaseToggle
