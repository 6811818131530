import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import * as styles from "./ListFiltersModal.module.css"

const ListFiltersModal = ({
  className,
  children,
  title,
  total,
  isOpen,
  onClose,
}) => {
  return (
    isOpen && (
      <div className={`${styles.modal} ${className && className}`}>
        <div className={styles.header}>
          <h4 className={styles.title}>{title}</h4>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-700 right-0 py-4 px-5"
          >
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </button>
        </div>
        <div className={styles.main}>{children}</div>
        <div className={styles.footer}>
          <button
            className="button button--primaryGreen flex items-center"
            onClick={onClose}
          >
            Show {total} results
          </button>
        </div>
      </div>
    )
  )
}

ListFiltersModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ListFiltersModal
