import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

const Discrepancy = ({ deltaQty, type = null, showType = false }) => {
  if (deltaQty === undefined) {
    return null
  }
  let qty = parseFloat(deltaQty).toFixed(2)
  return (
    <span
      className={classNames("text-sm", {
        "text-primaryPink ml-2": qty < 0 || qty > 0,
      })}
    >
      {qty > 0 ? `+${qty}` : qty < 0 ? `${qty}` : null}
      {showType && deltaQty && type ? (
        <span className="text-xs ml-1">({type})</span>
      ) : null}
    </span>
  )
}

Discrepancy.propTypes = {
  deltaQty: PropTypes.any,
  type: PropTypes.string,
  showType: PropTypes.bool,
}

export default Discrepancy
