import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { usePromise } from "react-use"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"

import StockTakeItem from "components/stock/StockTakeItem/StockTakeItem"
import Pagination from "components/common/Pagination/Pagination"
import Loader from "components/common/Loader/Loader"

import { getStockTakes } from "services/stock-take/stock-take"

import {
  header,
  thumbnail,
  thumbnailWrapper,
  iconWrapper,
  content,
  noOrders,
  footer,
  cancelButton,
  actions,
} from "./StockTakesInProgressModal.module.css"

const StockTakesInProgressModal = ({
  onRequestClose,
  onCreate,
  ...otherProps
}) => {
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(false)
  const [stockTakesData, setStockTakesData] = useState({
    content: [],
  })
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 20,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })

  const getData = async () => {
    setLoading(true)

    let params = {
      page: paginationData.page,
      sort: "createdAt,desc",
      status: "IN_PROGRESS",
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getStockTakes(params))

    if (result && !result.error) {
      setStockTakesData({ ...stockTakesData, ...result })
      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handlePageClick = ({ selected }) => {
    setPaginationData({ ...paginationData, page: selected })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      portalClassName="stockTakesInProgress"
      {...otherProps}
    >
      <div className="flex flex-col h-full">
        <div className={header}>
          <div className={thumbnailWrapper}>
            <div className={thumbnail}>
              <div
                className={iconWrapper}
                style={{
                  backgroundColor: "#E0FFFF",
                  color: "#35CCC3",
                }}
              >
                <FontAwesomeIcon icon={faSyncAlt} />
              </div>
            </div>
          </div>
          <h2 className="text-lg">
            Join a stocktake in progress or start a new one
          </h2>
        </div>
        <div className={actions}>
          <button
            className="button ml-auto button--autoWidth button--primaryGreen"
            onClick={(e) => {
              onRequestClose()
              onCreate(e)
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span>New stocktake</span>
          </button>
        </div>
        <div className={content}>
          {stockTakesData.content.length > 0 &&
            stockTakesData.content.map((stocktake) => {
              return (
                <React.Fragment key={stocktake.stockTakeReport.id}>
                  <StockTakeItem
                    stocktake={stocktake}
                    onDeleted={() => getData()}
                    onUpdated={() => getData()}
                    stockTakeOptionsClose={onRequestClose}
                  />
                </React.Fragment>
              )
            })}
          {stockTakesData.content.length > 0 && (
            <Pagination data={paginationData} onPageClick={handlePageClick} />
          )}
          {stockTakesData.content.length === 0 && !loading && (
            <p className={noOrders}>No stocktakes currently in progress.</p>
          )}
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading stocktakes...
          </Loader>
        </div>
        <div className={footer}>
          <button className={cancelButton} onClick={onRequestClose}>
            Close
          </button>
          <button
            className="button ml-auto button--autoWidth button--primaryGreen invisible lg:visible"
            onClick={(e) => {
              onRequestClose()
              onCreate(e)
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span>New stocktake</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

StockTakesInProgressModal.propTypes = {
  product: PropTypes.object,
  onRequestClose: PropTypes.func,
  onCreate: PropTypes.func,
}

export default StockTakesInProgressModal
