import React from "react"
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OrderStep, OrderStepItem } from "components/orders/types"

interface StepsProps {
  step: OrderStep
  steps: Array<OrderStepItem>
  modifyPermission: boolean
  isTabletOrMobile: boolean
  disableNext: boolean
  sidebarHeader: React.JSX.Element
  moveStep: (
    direction: "next" | "prev",
    currentStep: OrderStep
  ) => Promise<void>
  displayActionLabel: (modifyPermission: boolean) => string | undefined
  handleNext: () => Promise<void>
}

const OrderStepsSidebar = ({
  step,
  steps,
  modifyPermission,
  isTabletOrMobile,
  disableNext,
  sidebarHeader,
  moveStep,
  displayActionLabel,
  handleNext,
}: StepsProps) => {
  const currentStep = steps.find((s) => s.key === step)
  return (
    <div className="flex flex-col w-full z-0 flex-wrap flex-shrink-0 relative bg-white shadow-xl h-auto overflow-hidden border -mt-px -mb-px rounded-tl-3xl lg:w-4/12 lg:h-full lg:flex-col lg:max-w-lg lg:flex-nowrap">
      <div className="px-4 py-3 items-center justify-between flex-grow flex flex-wrap overflow-y-auto overflow-x-hidden w-full lg:px-8 lg:py-6 lg:items-start lg:justify-start lg:flex-col lg:flex-nowrap">
        <>
          {sidebarHeader}

          {currentStep?.sidebarContent && currentStep?.sidebarContent}
        </>
      </div>
      <div
        className={`px-4 py-3 flex justify-between items-center w-full lg:px-8 lg:py-4 lg:border-t lg:flex-col ${
          step === steps[0].key ? "flex-row-reverse" : "flex-row"
        }`}
      >
        {isTabletOrMobile && step !== steps[0].key && modifyPermission && (
          <button
            type="button"
            onClick={() => moveStep("prev", step)}
            className={"button button--autoWidth button--primaryBlue"}
            style={{ color: "#FFFFFF" }}
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
            Back
          </button>
        )}

        <button
          className={`button button--primaryPink order-last ${
            isTabletOrMobile ? "button--autoWidth" : ""
          }`}
          onClick={handleNext}
          disabled={disableNext}
        >
          <span className="inline-block">
            {displayActionLabel(modifyPermission)}
          </span>
          <FontAwesomeIcon className="ml-3" icon={faArrowRight} />
        </button>
      </div>
    </div>
  )
}

export default OrderStepsSidebar
