const organizationReducer = (state, action) => {
  switch (action.type) {
    case "SET_ORGANIZATION": {
      return {
        ...state,
        organization: action.payload,
        selectedOrganizations: [action.payload.id],
      }
    }
    case "UPDATE_ORGANIZATION": {
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload,
        },
      }
    }
    case "SET_ORGANIZATIONS": {
      return {
        ...state,
        organizations: action.payload,
      }
    }
    case "SET_SELECTED_ORGANIZATIONS": {
      return {
        ...state,
        selectedOrganizations: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default organizationReducer
