import React, { useContext } from "react"
import { useMediaQuery } from "react-responsive"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import classNames from "classnames/bind"
import { isValid } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import {
  faPencil,
  faSpinnerThird,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons"
import PropTypes from "prop-types"
import { stockTakeStates } from "services/constants"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import SearchInput from "components/forms/SearchInput"
import { convertTimeStampFormat } from "services/helpers"
import * as styles from "./StockTakeHeader.module.css"
import usePermissions from "hooks/usePermissions"

const cx = classNames.bind(styles)

const StockTakeHeader = ({
  stocktake,
  selectedStockTake,
  allSaved,
  isSaving = false,
  onEdit,
  onSave,
  onDelete,
  onSearch,
  onFinalise,
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions("Stocktakes")
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const state = stockTakeStates.find((oS) => oS.value === stocktake.status)
  const subStockTakesInProgress =
    stocktake.subStockTakeReports &&
    stocktake.subStockTakeReports.filter((st) => st.status === "IN_PROGRESS")
      .length
  const { organization } = useContext(GlobalStateContext)
  let options = []

  if (stocktake.status !== "COMPLETED") {
    options = [
      ...options,
      {
        key: "stocktake.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]
  }

  // Show merge option if no parent and sub stocktakes are in progress and all is saved
  if (
    allSaved &&
    !subStockTakesInProgress &&
    stocktake.status === "IN_PROGRESS"
  ) {
    options = [
      ...options,
      {
        key: "stocktake.merge",
        title: "Finalise",
        type: "success",
        disabled: !permissionObj?.permissions.modify,
      },
    ]
  }

  const handleActionClick = (action) => {
    switch (action.type) {
      case "stocktake.edit_name":
        onEdit()
        break
      case "stocktake.delete":
        onDelete()
        break
      case "stocktake.merge":
        onFinalise()
        break
      default:
        break
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.thumbnailWrapper}>
        <div className={styles.thumbnail}>
          <div
            className={styles.iconWrapper}
            style={{
              backgroundColor: "#E0FFFF",
              color: "#35CCC3",
            }}
          >
            <FontAwesomeIcon icon={faSyncAlt} />
          </div>
        </div>
        {state && (
          <span
            className={styles.statusBulb}
            style={{
              backgroundColor: state.color,
              width: "12px",
              height: "12px",
            }}
          ></span>
        )}
      </div>

      <div className={styles.headerMain}>
        <h3 className={styles.ref}>
          {stocktake.name || `New stocktake`}
          <button
            onClick={onEdit}
            disabled={!permissionObj?.permissions.modify}
            className={cx("button--autoWidth", "editButton")}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </h3>
        <div className="flex items-center">
          {stocktake.completedAt ? (
            stocktake.completedAt &&
            isValid(new Date(stocktake.completedAt)) && (
              <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                <span className="mr-1">Finalised:</span>{" "}
                {convertTimeStampFormat(
                  stocktake.completedAt,
                  organization.address.zoneId
                )}
              </span>
            )
          ) : (
            <span className="text-gray-700 italic text-sm font-sansSemiBold font-semibold flex items-center">
              Not finalised yet
            </span>
          )}{" "}
        </div>
      </div>
      {/* <div className="flex items-center"> */}
      <button
        className={cx(
          "button no-truncate flex-shrink-0 button--transparent button--autoWidth ml-auto order-last sm:order-none",
          {
            "text-primaryGreenDarker": !allSaved,
            disabled: allSaved || isSaving,
          }
        )}
        onClick={onSave}
        disabled={allSaved || isSaving || !permissionObj?.permissions.modify}
      >
        {!allSaved && (
          <FontAwesomeIcon
            icon={isSaving ? faSpinnerThird : faCheck}
            spin={isSaving}
            className="mr-2"
          />
        )}
        {allSaved ? "All saved" : isSaving ? "Saving..." : "Save now"}
      </button>
      {isTabletOrMobile && selectedStockTake && (
        <div className="mt-2 w-auto sm:w-full">
          <SearchInput
            label="Search by products"
            placeholder="Search by products"
            className="form-control rounded"
            onFocus={() => dispatch({ type: "KEYBOARD_IS_OPEN" })}
            onBlur={() => dispatch({ type: "KEYBOARD_IS_CLOSED" })}
            onSearchChange={(value) => {
              onSearch(value)
            }}
          />
        </div>
      )}

      {options.length > 0 && (
        <ActionsButton
          options={options}
          className="ml-4"
          onActionClick={(action) => handleActionClick(action)}
        />
      )}
    </div>
    // </div>
  )
}

StockTakeHeader.propTypes = {
  stocktake: PropTypes.object,
  selectedStockTake: PropTypes.object,
  allSaved: PropTypes.bool,
  isSaving: PropTypes.bool,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onFinalise: PropTypes.func,
  onSearch: PropTypes.func,
}

export default StockTakeHeader
