import React, { useState, useRef, ReactNode } from "react"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMediaQuery } from "react-responsive"
import { useClickAway } from "react-use"
import ReactTooltip, { Place } from "react-tooltip"
import * as styles from "./DropdownButton.module.css"
import classNames from "classnames/bind"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

const cx = classNames.bind(styles)

interface DropdownButtonProps {
  label: string | ReactNode
  title?: string
  options: any[]
  mobileIcon?: IconDefinition | null
  onActionClick({ type: string }): void
  buttonClass?: string
  containerClass?: string
  tooltipDirection?: Place
  dropDirection?: "top-right" | "top-left"
  icon?: IconDefinition
}

const DropdownButton = ({
  label,
  title = "",
  options,
  mobileIcon = null,
  onActionClick,
  buttonClass = "button button--primaryGreen",
  containerClass = "mr-[10px]",
  tooltipDirection = "top",
  dropDirection = "top-right",
  icon = faChevronDown,
}: DropdownButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef(null)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  useClickAway(dropdownRef, () => {
    setIsOpen(false)
  })
  const renderLabel = isTabletOrMobile || label === undefined

  return (
    <div className={`${cx("container")} ${containerClass}`} ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`${buttonClass} ${cx("toggleButton")}`}
      >
        {renderLabel ? (
          <FontAwesomeIcon icon={mobileIcon || icon} />
        ) : (
          <span className={cx("textContainer")}>{label || "Actions"}</span>
        )}
        {!renderLabel && (
          <FontAwesomeIcon
            size="sm"
            icon={icon}
            rotation={isOpen ? 180 : undefined}
          />
        )}
      </button>

      <div
        className={cx("content", {
          hidden: !isOpen,
          "right-auto left-0": dropDirection === "top-left",
        })}
      >
        {title && <span className={cx("optionsTitle")}>{title}</span>}
        {options.length &&
          options.map((option, index) => {
            return (
              <span
                data-tip={option.tooltip}
                data-for="dropDownButtonTooltip"
                key={`${option.key}-${index}`}
              >
                <button
                  type="button"
                  className={cx("menuItem")}
                  onClick={(e) => {
                    e.preventDefault()
                    onActionClick({ type: option.key })
                    setIsOpen(false)
                  }}
                  disabled={option.disabled}
                >
                  {option.icon && (
                    <span className="mr-3 w-5 flex justify-center items-center">
                      <FontAwesomeIcon icon={option.icon} />
                    </span>
                  )}
                  <span
                    className={`${option.type === "danger" && "text-red-600"}`}
                  >
                    {option.title}
                  </span>
                </button>
              </span>
            )
          })}
      </div>
      <ReactTooltip
        id="dropDownButtonTooltip"
        type="light"
        place={tooltipDirection}
        effect="solid"
        border={true}
        borderColor="#e2e8f0"
        multiline={true}
      />
    </div>
  )
}

export default DropdownButton
