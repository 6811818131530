import React, { useState, useContext } from "react"
import {
  formatAsYearMonthDay,
  formatCurrencyValue,
  roundNumber,
} from "services/helpers"
import { wasteReasons } from "services/constants"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo, faPercentage } from "@fortawesome/pro-regular-svg-icons"

import TextArea from "components/forms/TextArea"
import CustomSelect from "components/forms/CustomSelect"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { ModalContext } from "context/modal/ModalContext"

import * as styles from "./AddRecipeWasteForm.module.css"

import CalendarModal from "components/common/Calendar/CalendarModal/CalendarModal"
import { getRecipeMeasures } from "services/helpers"
import FileList from "components/common/FileList/FileList"
import { showError } from "services/toast"
import { updateWasteRecipeFiles } from "services/waste/waste"
import { Recipe } from "services/recipes/types"
import { Waste, WasteGroup, WasteRecipe } from "services/waste/types"
import { GrowyzeFile } from "services/types"

const validationSchema = Yup.object().shape(
  {
    fullQty: Yup.number().when("partialQty", {
      is: null,
      then: Yup.number().required("Please fill in full quantity"),
    }),
    partialQty: Yup.number().when("fullQty", {
      is: 0,
      then: Yup.number()
        .min(1, "Please enter a value more than 1")
        .nullable()
        .required("Please fill in a partial quantity"),
      otherwise: Yup.number().nullable(),
    }),
    reason: Yup.string()
      .nullable()
      .required("Please fill in a reason of the waste"),
    comment: Yup.string().nullable(),
  },
  [["partialQty", "fullQty"]]
)

interface AddRecipeWasteFormProps {
  onCancel: () => void
  newRecipe: Recipe | WasteRecipe
  onEdit: (
    instance: Recipe | WasteRecipe,
    wasteId: Waste["id"],
    updatedData: Waste
  ) => void
  waste: Waste
  wasteGroup: WasteGroup
  isEdit: boolean
  onSave?: (next: boolean, recipe: Recipe | WasteRecipe, waste: Waste) => void
  allowDateSelect?: boolean
}

const AddRecipeWasteForm = ({
  onCancel,
  onSave,
  newRecipe,
  onEdit,
  waste,
  wasteGroup,
  isEdit,
  allowDateSelect = false,
}: AddRecipeWasteFormProps) => {
  const modal = useContext(ModalContext)
  const [date, setDate] = useState<string>(
    wasteGroup?.date || formatAsYearMonthDay(new Date())
  )

  const [files, setFiles] = useState<GrowyzeFile[]>(waste.files || [])
  const [fileLoading, setFileLoading] = useState<boolean>(false)

  const recipeId =
    "recipe" in newRecipe
      ? newRecipe.recipe?.id
      : "id" in newRecipe
      ? newRecipe.id
      : null

  const initialiseWasteMeasure = (
    waste: Waste,
    recipe: Recipe | WasteRecipe
  ) => {
    if (waste.wasteMeasure) {
      return waste.wasteMeasure
    } else if ("portion" in recipe && recipe.portion) {
      return "portion"
    } else if ("yield" in recipe && recipe.yield?.measure) {
      return recipe.yield.measure
    } else {
      return null
    }
  }

  const formatWastePartialQty = (waste, isInit = false) => {
    if (waste.partialQty) {
      if (waste.wasteMeasure) {
        /**
         * If waste measure is set then partial qty is not visualised as percentage so just use actual value
         */
        return waste.partialQty
      } else if (isInit) {
        return waste.partialQty * 100
      } else {
        return waste.partialQty / 100
      }
    } else {
      return 0
    }
  }

  const initialValues = {
    fullQty: waste?.fullQty || 0,
    partialQty: formatWastePartialQty(waste, true),
    reason: waste?.reason || "",
    comment: waste?.comment || "",
    wasteMeasure: initialiseWasteMeasure(waste, newRecipe),
  }

  const saveWasteRecipe = (values) => {
    const submitData = {
      ...values,
      partialQty: formatWastePartialQty(values),
      date,
      files: files,
    }
    onSave && onSave(false, newRecipe, submitData)
  }

  const editWasteRecipe = (values) => {
    const updatedData = {
      ...values,
      partialQty: formatWastePartialQty(values),
      date,
    }

    onEdit(newRecipe, waste.id, updatedData)
  }

  const currency = null
  const today = new Date()
  const toggleDatePicker = () => {
    // @ts-ignore
    modal.showModal(CalendarModal, {
      selectedDate: date,
      disabled: { after: today },
      onConfirm: (date) => {
        setDate(date)
      },
    })
  }

  const handleFileUploaded = async (newFiles) => {
    setFileLoading(true)

    const newFileList = [...(files || []), ...newFiles]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    if (isEdit) {
      try {
        const res = await updateWasteRecipeFiles(
          wasteGroup.id,
          recipeId,
          waste.id,
          { files: newFileList }
        )

        if (res.message) {
          showError(res.message)
        }
      } catch (e: any) {
        showError(e.message || "File upload failed")
      }
    }

    setFileLoading(false)
  }

  const handleFileDeleted = async (file) => {
    setFileLoading(true)
    const newFileList = [...files.filter((f) => f.fileId !== file.fileId)]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    if (isEdit) {
      try {
        const res = await updateWasteRecipeFiles(
          wasteGroup.id,
          recipeId,
          waste.id,
          { files: newFileList }
        )

        if (res.message) {
          showError(res.message)
        }
      } catch (e: any) {
        showError(e.message || "File delete failed")
      }
    }

    setFileLoading(false)
  }

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.productName}>
          {"recipe" in newRecipe
            ? newRecipe.recipe?.name
            : "name" in newRecipe
            ? newRecipe.name
            : ""}
        </h3>
        {newRecipe.totalCost && (
          <span className="font-semibold font-sansSemiBold text-gray-500">
            Total cost{" "}
            {currency
              ? formatCurrencyValue(Number(newRecipe.totalCost))
              : roundNumber(Number(newRecipe.totalCost))}
          </span>
        )}
        {allowDateSelect && (
          <div className={`${styles.dateField} input-container mr-4`}>
            <input
              type="text"
              value={date}
              className="cursor-pointer form-control rounded"
              placeholder="From"
              readOnly
              onClick={toggleDatePicker}
            />
          </div>
        )}
      </div>
      {/* @ts-ignore */}
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, isValid, errors, touched }) => {
          return (
            <div className="flex flex-col h-full overflow-auto">
              <div className={styles.content}>
                <div className={styles.summary}>
                  <Form className="flex flex-col flex-shrink-0 w-full px-5 md:px-16 md:py-2">
                    <div className="flex flex-col md:flex-row">
                      <div className={styles.inputCol}>
                        <label
                          htmlFor="fullQty"
                          className={styles.quantityLabel}
                        >
                          Full item count
                        </label>

                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              values.fullQty > 0 &&
                                setFieldValue("fullQty", values.fullQty - 1)
                            }}
                          >
                            -
                          </button>

                          <Field
                            name="fullQty"
                            id="fullQty"
                            className={styles.quantityInput}
                            type="number"
                            min={0}
                          />

                          <button
                            type="button"
                            onClick={() => {
                              values.fullQty >= 0 &&
                                setFieldValue("fullQty", values.fullQty + 1)
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className={`${styles.inputCol} md:ml-10`}>
                        <label
                          htmlFor="partialQty"
                          className={styles.quantityLabel}
                        >
                          Additional quantity
                        </label>

                        <div className="form-group flex md:form-group--flex w-full">
                          <div
                            className="input-container input-container--left"
                            style={{ maxWidth: "160px" }}
                          >
                            <Field
                              name="partialQty"
                              id="partialQty"
                              type="number"
                              className={`${styles.quantityInputSecond} form-control form-control--topleft form-control--bottomleft`}
                              min={0}
                              step={0.2}
                              placeholder="XX"
                            />
                          </div>

                          {values.wasteMeasure ? (
                            <div
                              className="input-container input-container--right"
                              style={{ maxWidth: "calc(100% - 80px)" }}
                            >
                              <CustomSelect
                                showValidations={false}
                                required={false}
                                name="wasteMeasure"
                                id="wasteMeasure"
                                label="Measurement"
                                placeholder="Select measure"
                                className="form-control form-control--topright text-sm"
                                options={getRecipeMeasures({
                                  ...newRecipe,
                                  portion:
                                    "portion" in newRecipe ||
                                    values.wasteMeasure === "portion",
                                  yield: { measure: values.wasteMeasure },
                                })}
                                onSelectCallback={(val) => {
                                  if (val) {
                                    setFieldValue("wasteMeasure", val.value)
                                  } else {
                                    setFieldValue("wasteMeasure", "")
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className="input-container input-container--right"
                              style={{ maxWidth: "calc(100% - 60px)" }}
                            >
                              <div className="form-control form-control--topright text-sm border h-full flex justify-center items-center bg-gray-200">
                                <FontAwesomeIcon
                                  icon={faPercentage}
                                  className="text-xl md:text-3xl text-gray-600"
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="text-xs text-primaryPink mt-3">
                          {touched.partialQty &&
                            errors.partialQty &&
                            errors.partialQty.toString()}
                        </div>
                      </div>
                    </div>

                    <h5 className="mb-4 mt-6 text-gray-700">Waste reason *</h5>
                    <CustomSelect
                      showValidations={false}
                      required={true}
                      name="reason"
                      id="reason"
                      label="Waste reason"
                      placeholder="Select a reason"
                      isClearable={true}
                      className="form-control form-control--first form-control--last"
                      options={wasteReasons}
                      onSelectCallback={(val) => {
                        if (val) {
                          setFieldValue("reason", val.value)
                        } else {
                          setFieldValue("reason", "")
                        }
                      }}
                      blurInputOnSelect={false}
                    />

                    <h5 className="mb-4 mt-6 text-gray-700">Comments</h5>

                    <div className="form-group">
                      <div className="input-container">
                        <TextArea
                          showValidations={false}
                          name="comment"
                          className="form-control form-control--first form-control--last"
                          placeholder="Anything else to add?"
                          label="Comments"
                        />
                      </div>
                    </div>

                    <h5 className="mb-4 mt-6 text-gray-700">Files</h5>

                    <div className="form-group">
                      <div className="input-container">
                        <FileList
                          files={files}
                          isLoading={fileLoading}
                          clientKey="waste"
                          onUploaded={handleFileUploaded}
                          onDeleted={handleFileDeleted}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className={styles.formBottom}>
                <button
                  onClick={onCancel}
                  type="button"
                  className="text-primaryPink mr-auto px-6 font-semibold font-sansSemiBold button--autoWidth"
                >
                  <FontAwesomeIcon icon={faUndo} className="mr-2" />
                  Cancel
                </button>
                <button
                  onClick={
                    isEdit
                      ? () => editWasteRecipe(values)
                      : () => saveWasteRecipe(values)
                  }
                  disabled={!isValid}
                  type="button"
                  className={`${
                    isEdit && "button button--primaryGreen"
                  } text-primaryBlue mx-3 px-6 font-semibold font-sansSemiBold button--autoWidth`}
                >
                  Save
                </button>
                {!isEdit && (
                  <button
                    type="button"
                    disabled={!isValid}
                    onClick={() => {
                      const submitData = {
                        ...values,
                        partialQty: formatWastePartialQty(values),
                        files: files,
                      }
                      // @ts-ignore
                      onSave && onSave(true, newRecipe, submitData)
                    }}
                    className="button mx-3 button--primaryGreen button--autoWidth no-truncate"
                  >
                    Save & Next
                  </button>
                )}
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}

export default AddRecipeWasteForm
