import React from "react"

interface TabContentProps {
  children: any
  tabKey: string
  className?: string
}

const TabContent = (props: TabContentProps) => {
  const { children, className = null, tabKey } = props

  const attrs = {
    role: "tabpanel",
    "aria-labelledby": `tab-${tabKey}`,
    id: `${tabKey}-panel`,
    tabIndex: 0,
    className: `${
      className ? className : ""
    } flex-grow self-stretch overflow-hidden flex flex-col`,
  }
  return <div {...attrs}>{children}</div>
}

export default TabContent
