import React, { useEffect, useState } from "react"
import { getNewbridgeIntegration } from "services/newbridge"
import { showError } from "services/toast"

interface NewbridgeDetailsProps {
  id: string
  onClose?(): void
}
const NewbridgeDetails = ({ id }: NewbridgeDetailsProps) => {
  const [details, setDetails] = useState<any>(null)

  useEffect(() => {
    const abortController = new AbortController()
    const getDetails = async () => {
      const res = await getNewbridgeIntegration(id, {
        signal: abortController.signal,
      })
      if (!res.error) {
        setDetails(res)
      } else {
        showError("Could not get Newbridge information")
      }
    }

    getDetails()
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="w-full flex-grow flex flex-col overflow-auto">
      {details ? (
        <div className="flex-grow flex flex-col px-4 md:px-6 py-3">
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Integration ID:
          </span>
          <span className="text-gray-800 flex items-center pb-2 border-b">
            <img
              src={"/images/integrations/newbridge-icon.png"}
              className="w-8 h-auto mr-2"
              alt="Newbridge details"
            />
            <span>{details?.id}</span>
          </span>
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Integration key:
          </span>
          <span className="text-gray-800 pb-2 border-b">{details?.key}</span>
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Site number:
          </span>
          <span className="text-gray-800 pb-2 border-b text-sm font-semibold">
            {details?.site?.number}
          </span>
        </div>
      ) : (
        <span className="px-4 md:px-6 py-3 text-gray-700">
          No information found
        </span>
      )}
    </div>
  )
}

export default NewbridgeDetails
