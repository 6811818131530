// extracted by mini-css-extract-plugin
export var closeButton = "ConfirmDetailsHeader-module--closeButton--Dhb3q";
export var deliveryDate = "ConfirmDetailsHeader-module--deliveryDate--ifAy+";
export var editButton = "ConfirmDetailsHeader-module--editButton--O2zjv";
export var found = "ConfirmDetailsHeader-module--found--mYH46";
export var header = "ConfirmDetailsHeader-module--header--klDDg";
export var headerMain = "ConfirmDetailsHeader-module--headerMain--qFbSq";
export var iconWrapper = "ConfirmDetailsHeader-module--iconWrapper--VrKck";
export var notFoundContainer = "ConfirmDetailsHeader-module--notFoundContainer--gEOdW";
export var noteOrderStatus = "ConfirmDetailsHeader-module--noteOrderStatus--HwzEG";
export var noteRef = "ConfirmDetailsHeader-module--noteRef--O-qV-";
export var noteSubDetails = "ConfirmDetailsHeader-module--noteSubDetails--P7OO1";
export var notfound = "ConfirmDetailsHeader-module--notfound--VkMRT";
export var statusBulb = "ConfirmDetailsHeader-module--statusBulb--uwzzB";
export var supplierName = "ConfirmDetailsHeader-module--supplierName--TmwG4";
export var thumbnail = "ConfirmDetailsHeader-module--thumbnail--Hjp2o";
export var thumbnailWrapper = "ConfirmDetailsHeader-module--thumbnailWrapper--CFRPH";