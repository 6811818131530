import React, { useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"

import { Category, SubCategory, CategoryGroup } from "../../../services/types"
import FormDropdown from "components/forms/FormDropdown"
import ConditionalWrapper from "components/util/ConditionalWrapper"
import CategoryList from "./CategoryList"

export interface CategorySelectProps {
  /**
   * Category list to be displayed in the component
   */
  categories: Category[]
  /**
   * Callback that receives full category list including updated category
   */
  onSelectCategories?: (categories: Category[]) => void
  /**
   * Callback that receives updated category
   */
  onSelectCategory?: (category: Category) => Promise<void>
  /**
   * Callback that receives category to edit
   */
  onEditCategory?: (category: Category) => void
  /**
   * Callback to trigger create category action
   */
  onCreateCategory?: () => void
  /**
   * Display grouped categories in FormDropdowns
   */
  groupCategoriesInDropdowns?: boolean
  /**
   * Block select functionality. To be used in context where user modify permissions are required
   */
  disabled?: boolean
}

const CategorySelect = ({
  categories,
  onSelectCategories,
  onSelectCategory,
  onEditCategory,
  onCreateCategory,
  groupCategoriesInDropdowns,
  disabled,
}: CategorySelectProps) => {
  const groupCategories = (categories: Category[]) => {
    const grouped: CategoryGroup[] = []

    if (!groupCategoriesInDropdowns) {
      grouped.push({ name: "All categories", categories: categories })
      return grouped
    }

    const defaultCats = categories.filter((cat) => cat.default)
    const customCats = categories.filter((cat) => !cat.default)

    if (defaultCats?.length) {
      grouped.push({ name: "Default categories", categories: defaultCats })
    }

    grouped.push({ name: "Custom categories", categories: customCats })

    return grouped
  }

  const handleCategoryChange = async (
    category: Category,
    subCategory?: SubCategory
  ) => {
    const newCategory = { ...category }

    if (subCategory) {
      if (!newCategory.active && !subCategory.active) {
        newCategory.active = !subCategory.active
      }
      newCategory.subCategories = category.subCategories.map((subCat) => {
        return subCat.name === subCategory.name
          ? { ...subCategory, active: !subCategory.active }
          : subCat
      })
    } else {
      newCategory.active = !category.active
      newCategory.subCategories = category.subCategories.map((subCat) => {
        return { ...subCat, active: !category.active }
      })
    }

    if (onSelectCategory) {
      await onSelectCategory(newCategory)
    } else if (onSelectCategories) {
      const allCategories = groupedCategories.flatMap(
        (catGroup) => catGroup.categories
      )
      const newCategories = allCategories.map((cat) => {
        return cat.name === newCategory.name ? newCategory : cat
      })
      onSelectCategories(newCategories)
    }
  }

  const groupedCategories = useMemo(
    () => groupCategories(categories),
    [categories]
  )

  return (
    <div className="flex flex-col items-start">
      {groupedCategories?.map((categoryGroup) => {
        return (
          <ConditionalWrapper
            key={categoryGroup.name}
            condition={groupCategoriesInDropdowns}
            wrapper={(children) => {
              return (
                <FormDropdown
                  buttonText={categoryGroup.name}
                  key={categoryGroup.name}
                  defaultExpanded={true}
                  actionButton={
                    <>
                      {!disabled &&
                        categoryGroup.name === "Custom categories" &&
                        onCreateCategory && (
                          <div className="ml-4">
                            <button
                              className={
                                "button button--h-8 button--autoWidth button--primaryGreen"
                              }
                              onClick={onCreateCategory}
                              type="button"
                            >
                              <FontAwesomeIcon icon={faPlus} className="mr-2" />
                              <span className="md:hidden">Add</span>
                              <span className="hidden md:inline">
                                Add custom category
                              </span>
                            </button>
                          </div>
                        )}
                    </>
                  }
                >
                  {children.props?.children?.length > 0 ? (
                    children
                  ) : (
                    <span className="text-sm text-gray-800 mb-4 italic">
                      No custom categories yet
                    </span>
                  )}
                </FormDropdown>
              )
            }}
          >
            <div className="flex flex-wrap mb-4">
              {categoryGroup?.categories?.map((category, index) => (
                <CategoryList
                  category={category}
                  handleCategoryChange={handleCategoryChange}
                  onEditCategory={onEditCategory}
                  key={`${category?.id}-${index}`}
                />
              ))}
            </div>
          </ConditionalWrapper>
        )
      })}
    </div>
  )
}

export default CategorySelect
