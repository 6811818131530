import React, {
  FunctionComponent,
  createContext,
  ReactNode,
  useState,
  useEffect,
} from "react"
import EventBus from "services/events"
import { uuidv4 } from "services/helpers"

interface ModalProviderProps {
  props: null
  children: any
}

interface modalHanderInterface {
  components: {}
  showModal: (
    component:
      | FunctionComponent<any>
      | ReactNode
      | ((onRequestClose: any) => React.JSX.Element),
    props?: {},
    key?: string
  ) => string
  updateModal: (key: string, component: any, props?: {}) => string
  hideModal: (modalKey?: string) => void
  hideAllModals: () => void
}

export const ModalContext = createContext({} as modalHanderInterface)

export const ModalProvider = (props: ModalProviderProps) => {
  const [components, setComponents] = useState({})

  const modelHandler = {
    components: components,
    showModal: (component, props = {}, key = uuidv4()) => {
      setComponents({
        ...components,
        [key]: { component, props },
      })
      return key
    },
    updateModal: (key, component, props = {}) => {
      setComponents({
        ...components,
        [key]: { component, props },
      })
    },
    hideModal: (modalKey) => {
      const tmpComponents = components
      delete tmpComponents[modalKey]
      setComponents({ ...tmpComponents })
    },
    hideAllModals: () => {
      setComponents({})
    },
  } as modalHanderInterface

  useEffect(() => {
    EventBus.on("showModal", (args) => {
      const props = args.props
      props.onRequestClose = modelHandler.hideModal
      modelHandler.showModal(args.component, props)
    })
    EventBus.on("logout", () => modelHandler.hideAllModals())
    EventBus.on("navigate", () => modelHandler.hideAllModals())

    return () => {
      EventBus.remove("logout", () => {
        console.log("logout listener removed from modal")
      })
      EventBus.remove("navigate", () => {
        console.log("navigate listener removed from modal")
      })
    }
  }, [])

  return (
    <ModalContext.Provider value={modelHandler}>
      {props.children}
    </ModalContext.Provider>
  )
}
export const ModalConsumer = ModalContext.Consumer
