import { OrderRecurrenceRes, RepetitionTypeEnum } from "./types"

export const findRecurrenceInterval = (
  orderRecurrence?: OrderRecurrenceRes
) => {
  const repetitionType = orderRecurrence?.repetitionType

  const repetitionTypeToRepetitionObject: {
    [key in keyof typeof RepetitionTypeEnum]?: number
  } = {
    DAILY: orderRecurrence?.dailyRepetition?.interval,
    WEEKLY: orderRecurrence?.weeklyRepetition?.interval,
  }

  return repetitionType ? repetitionTypeToRepetitionObject[repetitionType] : 0
}

export const formatRecurrenceTypeSingular = (
  orderRecurrence?: OrderRecurrenceRes
) => {
  const repetitionType = orderRecurrence?.repetitionType

  const repetitionTypeToSingular: {
    [key in keyof typeof RepetitionTypeEnum]?: string
  } = {
    DAILY: "day",
    WEEKLY: "week",
  }

  return repetitionType ? repetitionTypeToSingular[repetitionType] : ""
}

export const formatRecurrenceDescription = (
  orderRecurrence?: OrderRecurrenceRes
) => {
  const repetitionTypeInterval = findRecurrenceInterval(orderRecurrence)
  const repetitionTypeToSingular = formatRecurrenceTypeSingular(orderRecurrence)

  return orderRecurrence
    ? `Every ${
        Number(repetitionTypeInterval) === 1
          ? repetitionTypeToSingular
          : `${repetitionTypeInterval} ${repetitionTypeToSingular}s`
      }`
    : ""
}

export const formatRepetitionTypeIntervalKey = (
  repetitionType: RepetitionTypeEnum
) => {
  const repetitionTypeToIntervalKey: {
    [key in keyof typeof RepetitionTypeEnum]?: string
  } = {
    DAILY: "dailyRepetition",
    WEEKLY: "weeklyRepetition",
  }

  return repetitionTypeToIntervalKey[repetitionType]
}
