import { get } from "./api"
import { Permission } from "./types"

export const getFeatureMap = (
  response: any
): { category: string; features: Permission[] }[] => {
  if (!response.content) {
    return []
  }
  // @ts-ignore
  const cats: string[] = [...new Set(response.content.map((f) => f.category))]
  return cats.map((c) => {
    return {
      category: c,
      features: response.content.filter((i) => i.category === c),
    }
  })
}

export const getMappedDefaultFeatures = async (params: any, config: any) => {
  const res = await get(`/features`, params, config)
  return getFeatureMap(res)
}

export const getDefaultFeatures = (params: any, config: any) => {
  return get(`/features`, params, config)
}
