import React, { useMemo } from "react"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Order } from "services/orders/types"
import { OrderTemplate } from "services/order-templates/types"

interface StepItemListProps {
  order: Order | OrderTemplate
  modifyPermission: boolean
  setShowItemList: React.Dispatch<React.SetStateAction<boolean>>
  removeOrderItem: (itemId: any) => void
}

const OrderStepsItemList = ({
  order,
  modifyPermission,
  setShowItemList,
  removeOrderItem,
}: StepItemListProps) => {
  const itemCount = useMemo(
    () => order.items?.filter((item) => item.quantity).length,
    [order]
  )

  return (
    <div className="z-20 absolute lg:relative w-full h-full flex flex-col left-0 top-0 lg:left-auto lg:top-auto bg-white">
      <header className="px-4 py-2 flex items-center justify-between lg:hidden">
        <h4 className="text-lg text-primaryBlue">Your items</h4>
        <button
          onClick={() => setShowItemList(false)}
          className="text-lg p-2 text-primaryBlue"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </header>
      <ul className="border-t lg:-mx-8 px-4 lg:mt-4 lg:px-8 flex-grow overflow-y-auto">
        {itemCount
          ? order?.items
              ?.filter((item) => item.quantity)
              .map((item, index) => (
                <li
                  className="flex border-b w-full text-gray-700 text-sm py-2 items-center"
                  key={index}
                >
                  <span className="flex-grow">
                    <strong className="font-bold font-sansBold">
                      {item.quantity}x
                    </strong>{" "}
                    {item.name}{" "}
                    {item.size || item.measure ? (
                      <small>
                        ({item.orderInCase ? `${item.productCase?.size} x` : ""}
                        {item.size} {item.measure}
                        {item.orderInCase
                          ? " - pack"
                          : item.unit
                          ? ` - ${item.unit}`
                          : ""}
                        )
                      </small>
                    ) : null}
                  </span>

                  <button
                    type="button"
                    className="button--autoWidth text-primaryPink"
                    onClick={() => removeOrderItem(item.productId)}
                    disabled={!modifyPermission}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </li>
              ))
          : ""}

        {itemCount === 0 && <p className="py-2">No items in your cart yet</p>}
      </ul>
    </div>
  )
}

export default OrderStepsItemList
