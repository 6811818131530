// extracted by mini-css-extract-plugin
export var bottomSummary = "COGSReport-module--bottomSummary--nciD8";
export var container = "COGSReport-module--container--0tqTE";
export var content = "COGSReport-module--content--aEnnM";
export var header = "COGSReport-module--header--AnBsL";
export var headerColumns = "COGSReport-module--headerColumns--w4gYT";
export var headerContent = "COGSReport-module--headerContent--tLrJA";
export var headerRight = "COGSReport-module--headerRight--WdwOA";
export var headerTopColumn = "COGSReport-module--headerTopColumn--08ZBi";
export var reportContent = "COGSReport-module--reportContent--lxaxj";
export var summaryItem = "COGSReport-module--summaryItem--tNfJO";
export var thumbnail = "COGSReport-module--thumbnail--VNH5e";
export var thumbnailBadge = "COGSReport-module--thumbnailBadge--nMMiP";