// extracted by mini-css-extract-plugin
export var bottomSummary = "WasteReport-module--bottomSummary--gPxKB";
export var container = "WasteReport-module--container--Q5v07";
export var content = "WasteReport-module--content--dIqBZ";
export var dateField = "WasteReport-module--dateField--Wi2pC";
export var datePicker = "WasteReport-module--datePicker--dVSbS";
export var header = "WasteReport-module--header--ikgTv";
export var headerColumns = "WasteReport-module--headerColumns--YcpoE";
export var headerContent = "WasteReport-module--headerContent--o9yrT";
export var headerTopColumn = "WasteReport-module--headerTopColumn--s3kMJ";
export var productSelect = "WasteReport-module--productSelect--0v30S";
export var reportContent = "WasteReport-module--reportContent--nT8f-";
export var thumbnail = "WasteReport-module--thumbnail--H9hmi";
export var thumbnailBadge = "WasteReport-module--thumbnailBadge--Hp+VO";