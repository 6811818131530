// extracted by mini-css-extract-plugin
export var active = "DeliveryNoteDetails-module--active--FEThb";
export var backIcon = "DeliveryNoteDetails-module--backIcon--9m8w+";
export var btn = "DeliveryNoteDetails-module--btn--ws5ES";
export var btnCapture = "DeliveryNoteDetails-module--btnCapture--O+U4o";
export var btnConfirm = "DeliveryNoteDetails-module--btnConfirm--7uhCK";
export var btnConfirmScanning = "DeliveryNoteDetails-module--btnConfirmScanning--o23jS";
export var camera = "DeliveryNoteDetails-module--camera--Tq+sq";
export var clickArea = "DeliveryNoteDetails-module--clickArea--5JGSL";
export var confirm = "DeliveryNoteDetails-module--confirm--KcWqD";
export var confirmContainer = "DeliveryNoteDetails-module--confirmContainer--MVIy4";
export var confirmCtas = "DeliveryNoteDetails-module--confirmCtas--8IOVi";
export var confirmImg = "DeliveryNoteDetails-module--confirmImg--5eXBy";
export var container = "DeliveryNoteDetails-module--container--5ERlq";
export var content = "DeliveryNoteDetails-module--content--9csKR";
export var contentConfirm = "DeliveryNoteDetails-module--content--confirm--mU7rR";
export var displayIntro = "DeliveryNoteDetails-module--displayIntro--ZOK4N";
export var displayIntroContainer = "DeliveryNoteDetails-module--displayIntroContainer--Egusu";
export var flash = "DeliveryNoteDetails-module--flash--LrjWQ";
export var header = "DeliveryNoteDetails-module--header--Wkhae";
export var headerMain = "DeliveryNoteDetails-module--headerMain--BZdiT";
export var inputBtn = "DeliveryNoteDetails-module--inputBtn--zn7X-";
export var intro = "DeliveryNoteDetails-module--intro--KCGja";
export var introContainer = "DeliveryNoteDetails-module--introContainer--vRcKN";
export var introIcon = "DeliveryNoteDetails-module--introIcon--4YUCT";
export var moveCamera = "DeliveryNoteDetails-module--moveCamera--XknEy";
export var navBar = "DeliveryNoteDetails-module--navBar--OJzy+";
export var notSupported = "DeliveryNoteDetails-module--notSupported--gHKoX";
export var notSupportedBtn = "DeliveryNoteDetails-module--notSupportedBtn--qRqQX";
export var orderSelect = "DeliveryNoteDetails-module--orderSelect--hI3V3";
export var orderSelectContainer = "DeliveryNoteDetails-module--orderSelectContainer--JFGij";
export var placeHolder = "DeliveryNoteDetails-module--placeHolder--M8pdy";
export var retake = "DeliveryNoteDetails-module--retake--cSf7C";
export var rotate = "DeliveryNoteDetails-module--rotate--Kubl+";
export var scanAction = "DeliveryNoteDetails-module--scanAction--a4fFC";
export var scanActions = "DeliveryNoteDetails-module--scanActions--7no0m";
export var scanBtn = "DeliveryNoteDetails-module--scanBtn--cxdNt";
export var scanPending = "DeliveryNoteDetails-module--scanPending--Dwrf-";
export var scanPendingAnimation = "DeliveryNoteDetails-module--scanPendingAnimation--odD7u";
export var scanPendingButton = "DeliveryNoteDetails-module--scanPendingButton--c9hUA";
export var scanPendingText = "DeliveryNoteDetails-module--scanPendingText--psn1T";
export var scanning = "DeliveryNoteDetails-module--scanning--BLy1d";
export var spinning = "DeliveryNoteDetails-module--spinning--nsfWY";
export var step2 = "DeliveryNoteDetails-module--step2--DqQDa";
export var step3 = "DeliveryNoteDetails-module--step3--vahjQ";
export var step4 = "DeliveryNoteDetails-module--step4--WhLtz";
export var title = "DeliveryNoteDetails-module--title--SJZy7";
export var transparent = "DeliveryNoteDetails-module--transparent--xzcey";