import { toast } from "react-toastify"
import { ToastOptions } from "react-toastify"
import { ErrorResponse } from "./types"

const _showToast = (
  type = "success",
  title: string,
  config: ToastOptions = {}
) => {
  const finalConfig = { ...config, toastId: title }
  switch (type) {
    case "success":
      return toast.success(title, finalConfig)
    case "error":
      return toast.error(title, finalConfig)
    case "warn":
      return toast.warn(title, finalConfig)
    case "info":
      return toast.info(title, finalConfig)
    default:
      return toast.success(title, finalConfig)
  }
}

export const showSuccess = (title, config: ToastOptions = {}) => {
  return _showToast("success", title, config)
}

export const showError = (title, config: ToastOptions = {}) => {
  return _showToast("error", title, config)
}

export const showErrors = (
  errorResult: ErrorResponse,
  config: ToastOptions = {}
) => {
  if (errorResult.errors && errorResult.errors.length > 0) {
    let string = ""
    errorResult.errors.forEach((e, index) => {
      string += `${e.defaultMessage}${
        errorResult &&
        errorResult.errors &&
        errorResult.errors.length - 1 === index
          ? ""
          : ", "
      }`
    })
    showError(string, config)
  } else if (errorResult.message) {
    showError(errorResult.message, config)
  } else {
    showError("Something went wrong", config)
  }
}

export const showInfo = (title, config: ToastOptions = {}) => {
  return _showToast("info", title, config)
}

export const showWarn = (title, config: ToastOptions = {}) => {
  return _showToast("warn", title, config)
}
