import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTrash,
  faCheck,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons"

import usePermissions from "hooks/usePermissions"
import { Category, Permission } from "../../services/types"

import CategorySelect from "components/common/CategorySelect/CategorySelect"
import { AsideContext } from "context/aside/AsideContext"
import CategoriesSettingsSidebar from "./CategoriesSettingsSidebar"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { useBeforeUnload } from "react-use"
import useCategories from "hooks/useCategories"

const CategoriesSettings = () => {
  const aside = useContext(AsideContext)
  const modal = useContext(ModalContext)
  const {
    categories,
    updateCat,
    removeCat,
    loadingCategories,
    getCategoriesData,
  } = useCategories()

  const permissionObj = usePermissions("Company settings") as Permission

  const handleUpdate = async (category: Category) => {
    updateCat(category)
  }

  const handleDelete = async (category: Category) => {
    removeCat(category)
  }

  const showDeleteCategoryConfirmationModal = (category: Category) => {
    modal.showModal(ConfirmModal, {
      onRequestClose: modal.hideModal(),
      text: "After deleting this category list, all linked items will be set to uncategorised.",
      confirmButtonText: "Delete category list",
      type: "danger",
      onConfirm: async () => {
        await handleDelete(category)
        getCategoriesData()
        aside.hideAside()
      },
    })
  }

  const showCreateCategorySidebar = () => {
    // @ts-ignore
    aside.showAside(CategoriesSettingsSidebar, {
      headerText: "Create new category list",
      onClose: async () => {
        getCategoriesData()
        aside.hideAside()
      },
    })
  }

  const showEditCategorySidebar = (category: Category) => {
    // @ts-ignore
    aside.showAside(CategoriesSettingsSidebar, {
      headerText: "Edit category list",
      category,
      headerButton: (
        <button
          onClick={() => showDeleteCategoryConfirmationModal(category)}
          className="text-primaryPink font-bold"
        >
          <FontAwesomeIcon icon={faTrash} className="text-sm mr-1" /> Delete
          category list
        </button>
      ),
      onClose: async () => {
        // await getLatestCategories()
        getCategoriesData()
        aside.hideAside()
      },
    })
  }

  useBeforeUnload(aside.isOpen, "Are you sure?")

  return (
    <>
      <CategorySelect
        categories={categories}
        onSelectCategory={handleUpdate}
        onEditCategory={showEditCategorySidebar}
        onCreateCategory={showCreateCategorySidebar}
        groupCategoriesInDropdowns={true}
        disabled={!permissionObj?.permissions.modify}
      />
      {!!categories.length && (
        <span
          className={`block w-full pt-2 text-sm font-semibold font-sansSemiBold ${
            !loadingCategories ? "text-primaryGreenDarker" : "text-primaryPink"
          }`}
        >
          <FontAwesomeIcon
            icon={loadingCategories ? faSpinnerThird : faCheck}
            spin={loadingCategories}
            className="mr-1"
          />{" "}
          {loadingCategories ? "saving.." : "all changes saved"}
        </span>
      )}
    </>
  )
}

export default CategoriesSettings
