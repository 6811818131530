// extracted by mini-css-extract-plugin
export var activeTab = "ConfirmDetails-module--activeTab--pDNig";
export var container = "ConfirmDetails-module--container--PcDJI";
export var itemLabel = "ConfirmDetails-module--itemLabel--pm5jm";
export var items = "ConfirmDetails-module--items-----kv";
export var itemsSubTitle = "ConfirmDetails-module--itemsSubTitle--4lPMo";
export var itemsTitle = "ConfirmDetails-module--itemsTitle--WZ0Hh";
export var open = "ConfirmDetails-module--open--YkuVJ";
export var productSelect = "ConfirmDetails-module--productSelect--WPWQV";
export var productSelectContainer = "ConfirmDetails-module--productSelectContainer--ODy48";
export var tabs = "ConfirmDetails-module--tabs--swc23";
export var totalAmount = "ConfirmDetails-module--totalAmount--2XaFa";
export var totalCost = "ConfirmDetails-module--totalCost--xLIO6";