// extracted by mini-css-extract-plugin
export var active = "OrganizationPlanSelect-module--active--TMAg2";
export var cancelPlan = "OrganizationPlanSelect-module--cancelPlan--PCP1c";
export var compareTooltip = "OrganizationPlanSelect-module--compareTooltip--GT45i";
export var currentPlanIndicator = "OrganizationPlanSelect-module--currentPlanIndicator--hBugu";
export var noSuppliers = "OrganizationPlanSelect-module--noSuppliers--vI5I3";
export var placeTop = "OrganizationPlanSelect-module--place-top--wPeTP";
export var plan = "OrganizationPlanSelect-module--plan--hHR+Z";
export var plans = "OrganizationPlanSelect-module--plans--ThQ1B";
export var plansWrapper = "OrganizationPlanSelect-module--plansWrapper--SnPEY";
export var toggleButton = "OrganizationPlanSelect-module--toggleButton--R2ph1";