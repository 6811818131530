import React from "react"
import { formatCurrencyValue, roundNumber } from "services/helpers"

const OrderItemCost = ({ order }) => {
  return (
    <div className="flex flex-col w-auto px-1 order-4">
      {order.supplier.currency
        ? formatCurrencyValue(order.totalCost)
        : roundNumber(order.totalCost)}
    </div>
  )
}

export default OrderItemCost
