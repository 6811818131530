import PropTypes from "prop-types"
import React from "react"
import { Link } from "@reach/router"
import * as styles from "./QuickAction.module.css"
import { faBoxOpen } from "@fortawesome/pro-duotone-svg-icons"
import classNames from "classnames/bind"
import ColoredIcon from "components/common/ColoredIcon/ColoredIcon"
const cx = classNames.bind(styles)

const QuickAction = ({
  wrap = false,
  disabled = false,
  name,
  callback = undefined,
  icon,
  to = undefined,
  backgroundColor,
  color,
}) => {
  const ComponentWrap = ({ children }) =>
    to ? (
      <Link
        className={cx({
          quickActionWrap: wrap,
          quickAction: !wrap,
          disabled: disabled,
        })}
        disabled={disabled}
        to={to}
      >
        {children}
      </Link>
    ) : (
      <button
        className={cx({ quickActionWrap: wrap, quickAction: !wrap })}
        disabled={disabled}
        onClick={callback}
      >
        {children}
      </button>
    )

  return (
    <ComponentWrap>
      <ColoredIcon
        backgroundColor={backgroundColor}
        color={color}
        icon={icon}
        className="mr-3"
      />
      <span className={styles.description}>{name}</span>
    </ComponentWrap>
  )
}

QuickAction.propTypes = {
  wrap: PropTypes.bool,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  to: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
}

QuickAction.defaultProps = {
  to: undefined,
  callback: undefined,
  icon: faBoxOpen,
  wrap: false,
  backgroundColor: "#e2e8f0",
}

export default QuickAction
