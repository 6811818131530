import React from "react"
import {
  faBoxOpen,
  faFileAlt,
  faShoppingCart,
  faSyncAlt,
  faBan,
  faExclamationTriangle,
  faCloudUpload,
} from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"

import * as styles from "./ActionIcon.module.css"

const ActionIcon = ({ type, children }) => {
  let backgroundColor
  let color
  let icon

  switch (type) {
    case "DN":
      icon = faBoxOpen
      backgroundColor = "#FAEAFF"
      color = "#B91DB3"
      break
    case "INVOICE":
      icon = faFileAlt
      backgroundColor = "#E7EDFF"
      color = "#1B377D"
      break
    case "ORDER":
      icon = faShoppingCart
      backgroundColor = "#FFE5F0"
      color = "#FC3762"
      break
    case "WASTE":
      icon = faBan
      backgroundColor = "#FFE5DB"
      color = "#F52525"
      break
    case "SALES":
      icon = faCloudUpload
      backgroundColor = "#f7fafc"
      color = "#718096"
      break
    case "STOCK_TAKE":
      icon = faSyncAlt
      backgroundColor = "#E0FFFF"
      color = "#35CCC3"
      break
    case "DISCREPANCY_REPORT":
      icon = faExclamationTriangle
      backgroundColor = "#FEEFDD"
      color = "#F2691C"
      break
    default:
      break
  }

  return icon ? (
    <div className={styles.iconContainer}>
      <div
        className={styles.iconWrapper}
        style={{
          backgroundColor,
          color,
        }}
      >
        <FontAwesomeIcon icon={icon} size="sm" />
      </div>
      {children}
    </div>
  ) : (
    <>{children}</>
  )
}

ActionIcon.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
}

export default ActionIcon
