// extracted by mini-css-extract-plugin
export var backIcon = "Invoice-module--backIcon--qew5w";
export var camera = "Invoice-module--camera--UxXhi";
export var clickArea = "Invoice-module--clickArea--cX25l";
export var confirm = "Invoice-module--confirm--teIqv";
export var confirmContainer = "Invoice-module--confirmContainer--E7nkp";
export var confirmImg = "Invoice-module--confirmImg--8rU+j";
export var confirmPdf = "Invoice-module--confirmPdf--1K4jG";
export var container = "Invoice-module--container--P6vLD";
export var content = "Invoice-module--content--jM+yY";
export var contentConfirm = "Invoice-module--content--confirm--QC06w";
export var displayIntro = "Invoice-module--displayIntro--DNjnQ";
export var flash = "Invoice-module--flash--pCknw";
export var header = "Invoice-module--header--40b1M";
export var headerMain = "Invoice-module--headerMain--iO9LK";
export var navBar = "Invoice-module--navBar--s4dIs";
export var orderSelect = "Invoice-module--orderSelect--NoIl2";
export var orderSelectContainer = "Invoice-module--orderSelectContainer--MElcF";
export var pdfIcon = "Invoice-module--pdfIcon--qG0g6";
export var placeHolder = "Invoice-module--placeHolder--WpvgY";
export var rotate = "Invoice-module--rotate--USu2B";
export var scanPending = "Invoice-module--scanPending--AE68T";
export var scanPendingAnimation = "Invoice-module--scanPendingAnimation--8fMCE";
export var scanPendingButton = "Invoice-module--scanPendingButton--ANh1C";
export var scanPendingText = "Invoice-module--scanPendingText--RDuBC";
export var scanning = "Invoice-module--scanning--qeNrd";
export var spinning = "Invoice-module--spinning--pcVSH";
export var step2 = "Invoice-module--step2--LYxDQ";
export var step3 = "Invoice-module--step3--9y1tn";
export var step4 = "Invoice-module--step4--+pEz0";
export var title = "Invoice-module--title--Gp1Xt";
export var transparent = "Invoice-module--transparent--kOH7m";