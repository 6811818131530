import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/pro-regular-svg-icons"
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons"

interface OrganizationApiKeyAuthItemProps {
  token: string
  label: string
}

const OrganizationApiKeyAuthItem: React.FC<OrganizationApiKeyAuthItemProps> = ({
  token,
  label,
}: OrganizationApiKeyAuthItemProps) => {
  const [copied, setCopied] = useState("")

  const handleCopyClick = () => {
    navigator.clipboard.writeText(token)
    setCopied(token)
    setTimeout(() => {
      setCopied("")
    }, 1000)
  }

  return (
    <>
      <div className="p-4 border border-t-0 first:border-t gap-y-1 gap-x-8 flex justify-between w-full col-span-2">
        <div className="flex flex-col">
          <span className="font-semibold" style={{ color: "#4E4E4E" }}>
            {token}
          </span>
          <span className="text-sm text-gray-600">{label}</span>
        </div>

        <button
          type="button"
          className="button button--lightGray button--autoWidth border"
          onClick={handleCopyClick}
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>

      <div className="flex items-center col-span-1">
        {copied && (
          <div className="space-x-2 text-primaryGreenDarker">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span>Copied to clipboard</span>
          </div>
        )}
      </div>
    </>
  )
}

export default OrganizationApiKeyAuthItem
