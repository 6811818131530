import React, { useState, useContext } from "react"
import { ModalContext } from "context/modal/ModalContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showError, showWarn } from "services/toast"
import {
  faSpinnerThird,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { deleteGoEposIntegration } from "services/goepos"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import GoEposConnectModal from "../GoEposConnectModal/GoEposConnectModal"

const GoEposIntegrationButton = ({
  status = "INACTIVE",
  id = null,
  onConnectionChange,
  canConnect,
  canDisconnect,
}) => {
  const modal = useContext(ModalContext)
  const { organization } = useContext(GlobalStateContext)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [integrationId, setIntegrationId] = useState<string | null>(id) // @todo this id needs to be passed in from the integrations overview. Will probably work as soon BE is ready automatically (no change needed here)
  const [error, setError] = useState("")
  const [loading] = useState(false)

  const orgName = organization?.companyName

  // const createIntegration = async (name: string) => {
  //   const params = { name }
  //   setLoading(true)
  //   const res = await createGoEposIntegration(params)
  //   if (res?.id) {
  //     onConnectionChange && onConnectionChange()
  //   }
  //   setLoading(false)
  // }

  const startGoEposAuthorization = async () => {
    // await createIntegration(name)
    // modal.showModal(GoEposSettingsModal, {
    //   onConnectionCreated: () => onConnectionChange(),
    // })

    modal.showModal(GoEposConnectModal)
  }

  const confirmRemove = () => {
    setError("")
    if (id) {
      modal.showModal(ConfirmModal, {
        type: "danger",
        title: `Disconnect GoEpos integration?`,
        text: "Are you sure you want to disconnect this integration?",
        confirmButtonText: "Disconnect",
        onConfirm: async () => {
          const res = await deleteGoEposIntegration(id)
          if (res) {
            if (res.status && res.status > 201) {
              showError(res.message)
            } else {
              showWarn("GoEpos disconnected!")
              setIntegrationId(null)
              onConnectionChange()
            }
          }
        },
      })
    }
  }

  return status === "ACTIVE" ? (
    canDisconnect ? (
      <button
        className="text-primaryPink font-semibold font-sansSemiBold hover:bg-gray-50 px-3 bg-white border rounded border-gray-200 text-sm self-start text-left mr-3"
        style={{ height: "40px " }}
        onClick={confirmRemove}
      >
        Disconnect
      </button>
    ) : null
  ) : (
    <div className="inline-flex flex-col items-end mr-3">
      {canConnect && (
        <button
          className={`text-primaryGreen font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left`}
          disabled={loading}
          style={{ height: "40px" }}
          onClick={() => {
            if (orgName) {
              startGoEposAuthorization(orgName)
            }
          }}
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
              Connecting..
            </>
          ) : (
            <>
              Connect
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </>
          )}
        </button>
      )}
      {error && <span className="text-primaryPink text-xs">{error}</span>}
    </div>
  )
}

export default GoEposIntegrationButton
