import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Dots = ({ index, count }) => {
  const dots = () => {
    let items = []
    for (let i = 0; i < count; i++) {
      items.push(
        <li
          key={i}
          className={classNames("w-2 h-2 mx-2 rounded-full", {
            "bg-primaryPink": index === i,
            "bg-gray-200": index !== i,
          })}
        ></li>
      )
    }
    return items
  }
  return <ul className="flex items-center justify-center">{dots()}</ul>
}

Dots.propTypes = {
  index: PropTypes.number,
  count: PropTypes.number,
}

export default Dots
