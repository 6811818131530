import React, { useContext } from "react"
import { format } from "date-fns" // <- isToday
import { AsideContext } from "context/aside/AsideContext"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { ExtendedFlexiblePeriod, FlexiblePeriod } from "services/types"
import { convertToTimeZoneUTCString } from "services/helpers"

interface DateSelectionProps {
  value: FlexiblePeriod
  onChange(data: ExtendedFlexiblePeriod): void
}

const DateSelection = ({ value, onChange }: DateSelectionProps) => {
  const aside = useContext(AsideContext)
  const { user } = useContext(GlobalStateContext)

  const toggleDatePicker = () => {
    // @ts-ignore
    aside.showAside(AsideCalendar, {
      selectedPeriod: value,
      zIndex: 2000,
      headerText: "Select a period",
      onConfirm: (data: FlexiblePeriod) => {
        const dataToBroadcast: ExtendedFlexiblePeriod = {
          ...data,
          inUserTimezone: {
            from: convertToTimeZoneUTCString(data.from, user.zoneId),
            to: convertToTimeZoneUTCString(data.to, user.zoneId),
            enteredTo: convertToTimeZoneUTCString(data.enteredTo, user.zoneId),
          },
        }
        onChange(dataToBroadcast)
        aside.hideAside()
      },
    })
  }

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  return (
    <div className="flex flex-row relative w-10/12">
      <div className={`flex w-auto md:w-1/2 input-container mr-4 flex-grow`}>
        <input
          type="text"
          value={value.from ? formatDate(value.from) : ""}
          className="cursor-pointer form-control rounded"
          placeholder="From"
          readOnly
          onClick={toggleDatePicker}
        />
      </div>
      <div className={`flex w-auto md:w-1/2 input-container flex-grow`}>
        <input
          type="text"
          className="cursor-pointer form-control rounded"
          value={value.to ? formatDate(value.to) : ""}
          placeholder="To"
          readOnly
          onClick={toggleDatePicker}
        />
      </div>
    </div>
  )
}

export default DateSelection
