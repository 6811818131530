import React, { useState, useContext } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMinusCircle,
  faCommentAltPlus,
  faChevronDown,
  faTrashAlt,
  faChevronUp,
  faCommentAlt,
  faWineBottle,
  faPencil,
  faPaperclip,
} from "@fortawesome/pro-regular-svg-icons"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"

import {
  formatCurrencyValue,
  roundNumber,
  formatReason,
} from "services/helpers"
import { convertTimeStampFormat } from "services/helpers"
import ReactTooltip from "react-tooltip"

//@ts-ignore
import * as styles from "./WasteTable.module.css"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { recipeMeasures } from "services/constants"
import { isToday } from "date-fns"
import { WasteTab } from "services/waste/types"

const datesAreTheSameDay = (date1, date2) =>
  new Date(date1).toDateString() === new Date(date2).toDateString()

interface WasteTableProps {
  waste: {
    totalCost: number
    totalQty: number
    wastesPerDay: {
      id: string
      timeOfRecord: string
      reporter: string
      totalCost: number
      totalQty: number
      wasteMeasure: string
      recipeName: string
      dishName: string
      reason: string
      comment: string
      files: {}[]
    }[]
    product: { name: string; unit: string; size: number; measure: string }
    recipe: { name: string }
    dish: { name: string }
  }
  wasteGroup: { date: string }
  onEditWaste: (waste: any, instance: any) => void
  onDeleteWasteRecord: (singleWaste: any, waste: any) => void
  onDeleteWasteTotal: (waste: any) => void
  type?: WasteTab
}

const WasteTable = ({
  waste,
  onEditWaste,
  onDeleteWasteRecord,
  onDeleteWasteTotal,
  type,
  wasteGroup,
}: WasteTableProps) => {
  const [collapse, setCollapse] = useState(false)
  const currency = null
  const { organization } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Waste") as Permission

  const isRecipe = type === "recipes"
  const isDish = type === "dishes"
  const isRecipeOrDish = isRecipe || isDish

  const today = isToday(new Date(wasteGroup.date))

  return (
    <div className={styles.wasteTable}>
      <div className="pr-5 header relative flex w-full">
        <button
          className="text-md text-left text-gray-700 cursor-pointer flex"
          onClick={() => setCollapse(!collapse)}
        >
          <FontAwesomeIcon
            icon={collapse ? faChevronUp : faChevronDown}
            className="mr-2"
          />
          <span className="font-sansBold text-primaryBlue flex ">
            {isRecipe
              ? waste.recipe.name
              : isDish
              ? waste.dish.name
              : waste.product.name}
          </span>
        </button>
        <button
          className="absolute top-0 right-0"
          style={{ color: "#FC3762" }}
          disabled={!permissionObj?.permissions.delete}
          onClick={() => onDeleteWasteTotal(waste)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
      <div className="content">
        <div className="item flex py-2">
          <div className={styles.firstColumn}></div>
          <div className="flex flex-1 font-light text-xs text-gray-700">
            QTY
          </div>
          <div className="flex flex-1 font-light text-xs text-gray-700"></div>
          <div className="flex flex-1 font-light text-xs text-gray-700">
            VALUE
          </div>
          <div className="flex flex-none sm:flex-1"></div>
          <div className="flex flex-none sm:flex-1"></div>
        </div>
        <div className="item flex py-2">
          {!isRecipeOrDish ? (
            <div className={styles.firstColumn}>
              <span className="items-center text-sm text-primaryBlue font-sansBold">
                <FontAwesomeIcon icon={faWineBottle} className="mr-1" />
                {waste.product.unit && <span>{waste.product.unit}&nbsp;</span>}
                {waste.product.size && <span>{waste.product.size}&nbsp;</span>}
                {waste.product.measure && (
                  <span>{waste.product.measure}&nbsp;</span>
                )}
              </span>
            </div>
          ) : (
            <div className={styles.firstColumn}></div>
          )}
          <div className="flex flex-1">
            <span className="font-sansBold text-base">
              {roundNumber(waste.totalQty)}
            </span>
          </div>
          <div className="flex flex-none sm:flex-1"></div>
          <div className="flex flex-1">
            <span className="font-sansBold text-base">
              {currency
                ? formatCurrencyValue(waste.totalCost)
                : roundNumber(waste.totalCost)}
            </span>
          </div>
          <div className="flex flex-none sm:flex-1"></div>
          <div className="flex flex-none sm:flex-1"></div>
        </div>
        <div className={`${collapse ? "visible h-auto" : "invisible h-0"}`}>
          {waste.wastesPerDay.map((singleWaste) => {
            const isRecipeOrDishItem = !!(
              singleWaste.recipeName || singleWaste.dishName
            )

            return (
              <div
                className="item flex py-3 border-t border-gray-200 flex-col sm:flex-row sm:items-center relative"
                key={singleWaste.id}
              >
                <div className="flex flex-1">
                  <span className="font-sansBold mr-1 text-sm">
                    {convertTimeStampFormat(
                      singleWaste.timeOfRecord,
                      organization?.address.zoneId,
                      datesAreTheSameDay(
                        singleWaste.timeOfRecord,
                        wasteGroup.date
                      )
                    )}
                  </span>
                </div>
                <div className="flex flex-1 truncate">
                  <span className="text-sm truncate">
                    {singleWaste.reporter}
                  </span>
                </div>
                <div className="flex py-1 flex-1 text-sm justify-start sm:justify-between">
                  <div>
                    <span className="font-sansBold">
                      {roundNumber(singleWaste.totalQty, 3)}
                    </span>
                    {!isRecipeOrDish && (
                      <span className="font-sans">
                        {` (${roundNumber(
                          singleWaste.totalQty * waste.product.size
                        )}${
                          waste.product.measure ? waste.product.measure : ""
                        })`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex py-1 flex-1 text-sm">
                  {singleWaste.wasteMeasure
                    ? [
                        ...recipeMeasures,
                        { label: "portion", value: "portion" },
                      ].find((ms) => ms.value === singleWaste.wasteMeasure)
                        ?.label
                    : "-"}
                </div>
                <div className="flex py-1 flex-1 text-sm">
                  <span>{`${
                    currency
                      ? formatCurrencyValue(singleWaste.totalCost)
                      : roundNumber(singleWaste.totalCost)
                  }`}</span>
                </div>
                <div className="flex py-1 flex-1 text-secondaryOrange font-sansBold text-sm">
                  <span>{formatReason(singleWaste.reason)}</span>&nbsp;
                  {singleWaste.recipeName && (
                    <>
                      <span
                        className={styles.recipePill}
                        data-tip={singleWaste.recipeName}
                        data-for={"recipeNameTooltip"}
                      >
                        R
                      </span>
                      <ReactTooltip
                        place="top"
                        id={"recipeNameTooltip"}
                        type="light"
                        effect="solid"
                        border={true}
                        borderColor="#e2e8f0"
                      />
                    </>
                  )}
                </div>
                <div className="absolute right-0 sm:flex sm:flex-1 sm:justify-end sm:relative">
                  <div className="flex items-center gap-x-4">
                    <button
                      className="cursor-pointer"
                      disabled={
                        !permissionObj?.permissions.modify || isRecipeOrDishItem
                      }
                      onClick={() => onEditWaste(singleWaste, waste)}
                    >
                      {singleWaste.comment ? (
                        <>
                          <ReactTooltip
                            id={"comment_tooltip"}
                            type="light"
                            place="top"
                            effect="float"
                            border={true}
                            borderColor="#e2e8f0"
                            multiline={true}
                            clickable
                          />
                          <span
                            className="fa-layers fa-fw mt-1 mr-1"
                            data-for={"comment_tooltip"}
                            data-tip={singleWaste.comment.replace(
                              /\n/g,
                              "<br/>"
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faCommentAlt}
                              className="text-lg"
                            />
                            <FontAwesomeIcon
                              icon={faCircle}
                              transform="shrink-4 right-6 up-6"
                              className="text-white text-lg"
                            />
                            <FontAwesomeIcon
                              icon={faCircle}
                              transform="shrink-8 right-6 up-6"
                              className="text-primaryPink text-lg"
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faCommentAltPlus}
                            className="text-lg mt-1 mr-1"
                            data-tip={
                              isRecipeOrDishItem
                                ? "Go to the recipe / dish to edit the item information"
                                : ""
                            }
                            data-for={"recipeDishItemTooltip"}
                          />
                        </>
                      )}
                    </button>
                    {singleWaste?.files?.length > 0 && (
                      <button
                        type="button"
                        className="flex items-center cursor-pointer w-full"
                        disabled={!permissionObj?.permissions.modify}
                        onClick={() => onEditWaste(singleWaste, waste)}
                      >
                        <FontAwesomeIcon
                          icon={faPaperclip}
                          className="text-lg mr-1"
                        />
                        <span>{singleWaste.files.length}</span>
                      </button>
                    )}

                    <button
                      className="ml-2 cursor-pointer"
                      disabled={
                        !permissionObj?.permissions.modify || isRecipeOrDishItem
                      }
                      onClick={() => onEditWaste(singleWaste, waste)}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="text-lg"
                        data-tip={
                          isRecipeOrDishItem
                            ? "Go to the recipe / dish to edit the item information"
                            : ""
                        }
                        data-for={"recipeDishItemTooltip"}
                      />
                    </button>

                    {today && (
                      <button
                        className="cursor-pointer text-lg ml-2"
                        style={{ color: "#FC3762" }}
                        disabled={!permissionObj?.permissions.delete}
                        onClick={() => onDeleteWasteRecord(singleWaste, waste)}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <ReactTooltip
          place="top"
          id={"recipeDishItemTooltip"}
          type="light"
          effect="solid"
          border={true}
          borderColor="#e2e8f0"
        />
      </div>
    </div>
  )
}

export default WasteTable
