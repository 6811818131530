import {
  faChevronDown,
  faChevronRight,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export interface ExpandIconProps {
  expanded: boolean
  onExpand(expanded: boolean): void
  isQuerying?: boolean
  rowData: {
    children?: any[]
    _isSub: boolean
    preferred: boolean
    countOfProductsInGroup: number
  }
}

const CustomExpandIconComponent = (props: ExpandIconProps) => {
  const { expanded, onExpand, rowData, isQuerying = false } = props

  const loading = expanded && !rowData?.children?.length

  //Showing dropdown arrow on every product instance if user is quering for something, or a product is a parent by default
  const shouldDisplayDropdown =
    !rowData?._isSub &&
    rowData?.countOfProductsInGroup > 1 &&
    (rowData.preferred || isQuerying)

  return (
    <div className="w-6 flex justify-center items-center text-primaryBlue text-current">
      {shouldDisplayDropdown && (
        <button
          onClick={async () => {
            onExpand(!expanded)
          }}
        >
          <FontAwesomeIcon
            icon={
              loading ? faSpinner : expanded ? faChevronDown : faChevronRight
            }
            spin={loading}
            className="text-sm"
          />
        </button>
      )}
    </div>
  )
}
export default CustomExpandIconComponent
