// extracted by mini-css-extract-plugin
export var active = "StockTransferContent-module--active--NerDK";
export var cancelButton = "StockTransferContent-module--cancelButton--tHLUP";
export var container = "StockTransferContent-module--container--XmpWA";
export var content = "StockTransferContent-module--content--CyXux";
export var footer = "StockTransferContent-module--footer---ztCG";
export var itemLabel = "StockTransferContent-module--itemLabel--QwAwZ";
export var items = "StockTransferContent-module--items--RWkmR";
export var noProducts = "StockTransferContent-module--noProducts--lvGw6";
export var removeButton = "StockTransferContent-module--removeButton--OVj-g";
export var saveButton = "StockTransferContent-module--saveButton--0cjy+";
export var scanButton = "StockTransferContent-module--scanButton--vRLIO";
export var tabButton = "StockTransferContent-module--tabButton--ebzzE";
export var tabs = "StockTransferContent-module--tabs--vBwAj";
export var totals = "StockTransferContent-module--totals--2CApu";