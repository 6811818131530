import React, { useContext, useState } from "react"

import { wasteReasons } from "services/constants"
import { showCompatibleMeasures } from "services/measurements"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo } from "@fortawesome/pro-regular-svg-icons"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"

import TextArea from "components/forms/TextArea"
import CustomSelect from "components/forms/CustomSelect"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import * as styles from "./AddWasteForm.module.css"

import { ModalContext } from "context/modal/ModalContext"
import CalendarModal from "components/common/Calendar/CalendarModal/CalendarModal"
import { roundNumber } from "../../../services/helpers"
import FileList from "components/common/FileList/FileList"
import { updateWasteFiles } from "services/waste/waste"
import { showError } from "services/toast"

const validationSchema = Yup.object().shape(
  {
    fullQty: Yup.number().when("partialQty", {
      is: (value) => {
        return value === undefined || value === null || Number(value) === 0
      },
      then: Yup.number().required("Please fill in full quantity"),
    }),
    partialQty: Yup.string().when("fullQty", {
      is: (value) => {
        return value === undefined || value === null || value === 0
      },
      then: Yup.string().required("Please fill in a partial quantity"),
      otherwise: Yup.string().nullable(),
    }),
    wasteMeasure: Yup.string().nullable(),
    reason: Yup.string()
      .required("Please fill in a reason of the waste")
      .nullable(),
    comment: Yup.string().nullable(),
  },
  [["partialQty", "fullQty"]]
)

interface AddWasteFormProps {
  allowDateSelect: boolean
  onCancel: any
  onEdit: any
  newProduct: any
  waste: any
  wasteGroup: any
  isEdit: boolean
  onSave?: any
  globalDate?: string
  setGlobalDate?: any
}

const AddWasteForm = ({
  allowDateSelect,
  onCancel,
  onSave,
  onEdit,
  newProduct,
  waste,
  wasteGroup,
  isEdit,
  globalDate,
  setGlobalDate,
}: AddWasteFormProps) => {
  const modal = useContext(ModalContext)

  const [files, setFiles] = useState<{ fileId: string }[]>(waste.files || [])
  const [fileLoading, setFileLoading] = useState<boolean>(false)

  const initialFullQty = roundNumber(
    waste?.fullQty ? waste?.fullQty : Math.floor(waste.totalQty || 0)
  ) //Whole number, amount of individual products wasted

  const initialPartialQty =
    waste?.partialQty || waste?.totalQty
      ? roundNumber(
          waste?.partialQty
            ? waste?.partialQty
            : (waste.totalQty % 1) * newProduct.size,
          3
        )
      : undefined //in ML, KG

  const initialValues = {
    fullQty: initialFullQty || "",
    partialQty: initialPartialQty || "",
    wasteMeasure: waste?.wasteMeasure || newProduct.measure,
    reason: waste?.reason || "",
    comment: waste?.comment || "",
  }

  const { packaging, productCase } = newProduct
  const isMulti = packaging === "multiple"
  const size = isMulti ? productCase.size * newProduct.size : newProduct.size

  const saveWaste = (values, resumeScanning = false) => {
    let partialQty
    let wasteMeasure

    if (values.wasteMeasure === "%") {
      partialQty = (values.partialQty / 100) * size
      wasteMeasure = newProduct.measure
    } else {
      partialQty = values.partialQty
      wasteMeasure = values.wasteMeasure
    }

    if (values.fullQty === "") {
      values.fullQty = 0
    }

    const submitData = {
      ...values,
      fullQty: values.fullQty ? values.fullQty : 0,
      partialQty: values.partialQty ? partialQty : 0,
      wasteMeasure,
      date: globalDate,
      files: files,
    }

    onSave(resumeScanning, newProduct, submitData)
  }

  const editWaste = (values) => {
    let partialQty
    let wasteMeasure

    if (values.wasteMeasure === "%") {
      partialQty = (values.partialQty / 100) * size
      wasteMeasure = newProduct.measure
    } else {
      partialQty = values.partialQty
      wasteMeasure = values.wasteMeasure
    }

    if (values.fullQty === "") {
      values.fullQty = 0
    }

    const updatedData = {
      ...values,
      fullQty: values.fullQty ? values.fullQty : 0,
      partialQty: values.partialQty ? partialQty : 0,
      wasteMeasure,
      date: globalDate,
    }

    onEdit(newProduct, waste.id, updatedData)
  }
  const today = new Date()
  const toggleDatePicker = () => {
    // @ts-ignore
    modal.showModal(CalendarModal, {
      selectedDate: globalDate,
      disabled: { after: today },
      onConfirm: (date) => {
        setGlobalDate(date)
      },
    })
  }

  const handleFileUploaded = async (newFiles) => {
    setFileLoading(true)

    const newFileList = [...(files || []), ...newFiles]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    if (isEdit) {
      try {
        const res = await updateWasteFiles(
          wasteGroup.id,
          newProduct.barcode,
          waste.id,
          { files: newFileList }
        )

        if (res.message) {
          showError(res.message)
        }
      } catch (e: any) {
        showError(e.message || "File upload failed")
      }
    }

    setFileLoading(false)
  }

  const handleFileDeleted = async (file) => {
    setFileLoading(true)
    const newFileList = [...files.filter((f) => f.fileId !== file.fileId)]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    if (isEdit) {
      try {
        const res = await updateWasteFiles(
          wasteGroup.id,
          newProduct.barcode,
          waste.id,
          { files: newFileList }
        )

        if (res.message) {
          showError(res.message)
        }
      } catch (e: any) {
        showError(e.message || "File delete failed")
      }
    }

    setFileLoading(false)
  }

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.productName}>{newProduct.name}</h3>
        <span className={styles.productMeta}>
          {(newProduct.size || newProduct.measure) && (
            <>
              <span className="font-semibold font-sansSemiBold">
                {`${isMulti ? `${productCase.size} x` : ""} ${
                  newProduct.size
                } ${newProduct.measure}`}
              </span>
              <FontAwesomeIcon
                icon={faCircle}
                transform="shrink-10"
                className="mx-2 text-gray-500"
              />
            </>
          )}

          <span>
            {newProduct.category} - {newProduct.subCategory}
          </span>
        </span>
        {allowDateSelect && (
          <div className={`${styles.dateField} input-container mr-4`}>
            <input
              type="text"
              value={globalDate}
              className="cursor-pointer form-control rounded"
              placeholder="From"
              readOnly
              onClick={toggleDatePicker}
            />
          </div>
        )}
      </div>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue, isValid }) => {
          return (
            <div className="flex flex-col h-full overflow-auto">
              <div className={styles.content}>
                <div className={styles.summary}>
                  <Form className="flex flex-col flex-shrink-0 w-full px-5 md:px-16 md:py-2">
                    <div className="flex flex-col md:flex-row justify-items-start">
                      <div className={styles.inputCol}>
                        <label
                          htmlFor="fullQty"
                          className={styles.quantityLabel}
                        >
                          Full item count
                        </label>

                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              values.fullQty > 0 &&
                                setFieldValue(
                                  "fullQty",
                                  String(Number(values.fullQty) - 1)
                                )
                            }}
                          >
                            -
                          </button>

                          <Field
                            name="fullQty"
                            id="fullQty"
                            className={`${styles.quantityInput}`}
                            type="number"
                            min={0}
                            placeholder="XX"
                            // value={values.fullQty}
                          />

                          <button
                            type="button"
                            onClick={() => {
                              values.fullQty >= 0 &&
                                setFieldValue(
                                  "fullQty",
                                  String(Number(values.fullQty) + 1)
                                )
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {newProduct.measure && newProduct.size ? (
                        <div className={`${styles.inputCol} md:ml-10`}>
                          <label
                            htmlFor="partialQty"
                            className={styles.quantityLabel}
                          >
                            Additional quantity
                          </label>

                          <div className="form-group flex md:form-group--flex w-full">
                            <div
                              className="input-container input-container--left"
                              style={{ maxWidth: "160px" }}
                            >
                              <Field
                                name="partialQty"
                                id="partialQty"
                                type="number"
                                className={`${styles.quantityInputSecond} form-control form-control--topleft form-control--bottomleft`}
                                min={0}
                                step={0.2}
                                placeholder="XX"
                              />
                            </div>
                            <div
                              className="input-container input-container--right"
                              style={{ maxWidth: "calc(100% - 80px)" }}
                            >
                              <CustomSelect
                                showValidations={false}
                                required={false}
                                name="wasteMeasure"
                                id="wasteMeasure"
                                label="Measurement"
                                placeholder="Select measure"
                                className="form-control form-control--topright text-sm"
                                options={showCompatibleMeasures(
                                  newProduct.measure
                                )}
                                onSelectCallback={(val) => {
                                  if (val) {
                                    setFieldValue("wasteMeasure", val.value)
                                  } else {
                                    setFieldValue("wasteMeasure", "")
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center lg:px-3">
                          <span className="text-primaryPink font-sansBold">
                            To add partial quantities, size and measure need to
                            be set up for this product. Go to Products → Items
                            and complete the product set up.
                          </span>
                        </div>
                      )}
                    </div>

                    <h5 className="mb-4 mt-6 text-gray-700">Waste reason *</h5>
                    <CustomSelect
                      name="reason"
                      id="reason"
                      label="Waste reason"
                      placeholder="Select a reason"
                      isClearable={true}
                      className="form-control form-control--topright"
                      options={wasteReasons}
                      onSelectCallback={(val) => {
                        if (val) {
                          setFieldValue("reason", val.value)
                        } else {
                          setFieldValue("reason", "")
                        }
                      }}
                      blurInputOnSelect={false}
                    />

                    <h5 className="mb-4 mt-6 text-gray-700">Comments</h5>

                    <div className="form-group">
                      <div className="input-container">
                        <TextArea
                          showValidations={false}
                          name="comment"
                          className="form-control form-control--first form-control--last"
                          placeholder="Anything else to add?"
                          label="Comments"
                        />
                      </div>
                    </div>

                    <h5 className="mb-4 mt-6 text-gray-700">Files</h5>

                    <div className="form-group">
                      <div className="input-container">
                        <FileList
                          files={files}
                          isLoading={fileLoading}
                          clientKey="waste"
                          onUploaded={handleFileUploaded}
                          onDeleted={handleFileDeleted}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className={styles.formBottom}>
                <button
                  onClick={onCancel}
                  type="button"
                  className="text-primaryPink mr-auto px-6 font-semibold font-sansSemiBold button--autoWidth"
                >
                  <FontAwesomeIcon icon={faUndo} className="mr-2" />
                  Cancel
                </button>
                <button
                  onClick={
                    isEdit ? () => editWaste(values) : () => saveWaste(values)
                  }
                  disabled={!isValid}
                  type="button"
                  className={`${
                    isEdit && "button button--primaryGreen"
                  } text-primaryBlue mx-3 px-6 font-semibold font-sansSemiBold button--autoWidth`}
                >
                  Save
                </button>
                {!isEdit && (
                  <button
                    type="button"
                    disabled={!isValid}
                    onClick={() => saveWaste(values, true)}
                    className="button mx-3 button--primaryGreen button--autoWidth no-truncate"
                  >
                    Save & Next
                  </button>
                )}
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}

export default AddWasteForm
