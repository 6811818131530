import React from "react"

const CostProfitChart = ({
  cost,
  profit,
  costPercentage,
  profitPercentage,
}) => {
  return (
    <div className="flex flex-col w-full h-full">
      {profit && cost ? (
        <div className="rounded-full overflow-hidden relative h-1 bg-green-400 mb-1">
          <span
            className="bg-red-600 block position-absolute h-full left-0 duration-400 top-0 bottom-0 transition-all"
            style={{ width: `${100 - profitPercentage}%` }}
          ></span>
        </div>
      ) : (
        <div className="rounded-full overflow-hidden relative h-1 bg-gray-400 mb-1"></div>
      )}
      <div className="flex justify-between text-xs">
        <div className="flex flex-wrap items-baseline">
          <span
            className="uppercase text-gray-600 font-sansSemiBold font-semibold mr-1"
            style={{ fontSize: "0.8em" }}
          >
            Cost
          </span>
          <span className="flex items-center text-sm">
            <strong className="mr-1">
              {cost == null || isNaN(cost) ? "0" : cost}
            </strong>
            <span
              className="text-xs text-gray-600"
              style={{ fontSize: "0.8em" }}
            >
              ({costPercentage}%)
            </span>
          </span>
        </div>
        <div className="flex flex-wrap justify-end items-baseline text-right">
          <span
            className="uppercase text-gray-600 font-sansSemiBold font-semibold mr-1"
            style={{ fontSize: "0.8em" }}
          >
            Profit
          </span>
          <span className="flex items-center text-sm">
            <strong className="mr-1">
              {profit == null || isNaN(profit) ? "0" : profit}
            </strong>
            <span
              className="text-xs text-gray-600"
              style={{ fontSize: "0.8em" }}
            >
              ({profitPercentage}%)
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default CostProfitChart
