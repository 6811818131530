// extracted by mini-css-extract-plugin
export var actionBtn = "EditDish-module--actionBtn--NkbTX";
export var container = "EditDish-module--container--PcZS5";
export var dishDescription = "EditDish-module--dishDescription--3A5Yg";
export var form = "EditDish-module--form--RUURD";
export var formFeaturedImg = "EditDish-module--formFeaturedImg--euXVZ";
export var formFooter = "EditDish-module--formFooter--TQ6kd";
export var header = "EditDish-module--header--HsaWr";
export var infoSection = "EditDish-module--infoSection--Uj-lc";
export var section = "EditDish-module--section--mKlUS";
export var sectionHeading = "EditDish-module--sectionHeading--N7ZUD";
export var tabContent = "EditDish-module--tabContent--gt7nl";
export var tabs = "EditDish-module--tabs--4-aYn";
export var wrapper = "EditDish-module--wrapper--YR52r";