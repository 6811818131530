import React, { useState } from "react"
import { FieldArray } from "formik"
import OrderApprover from "../OrderApprover/OrderApprover"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { useMediaQuery } from "react-responsive"

import AsyncSelect from "components/forms/AsyncSelect"
import classNames from "classnames"

import * as styles from "./OrderApprovals.module.css"
import { getAllUsers } from "../../../services/user"
import { Approver } from "../OrderApprover/OrderApprover"

const OrderApprovals = ({ approvers, userHasModifyPermission }) => {
  const [enableAddApprover, setEnableAddApprover] = useState(false)
  const [selectedApprover, setSelectedApprover] = useState<Approver | null>(
    null
  )
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const usernames = approvers?.map((approver: Approver) => approver.username)

  const reset = () => {
    setEnableAddApprover(false)
    setSelectedApprover(null)
  }

  return (
    <div>
      <h5 className="text-xs mt-2 mb-3">
        Approvers for this supplier (1 approver is enough)
      </h5>
      <FieldArray name="orderApprovals.approvers">
        {({ push, remove }) => {
          const handleAddApprover = () => {
            if (
              !selectedApprover ||
              !selectedApprover.firstName ||
              !selectedApprover.lastName ||
              !selectedApprover.username
            ) {
              throw new Error("Invalid approver")
            }

            const { firstName, lastName, username } = selectedApprover

            const formattedApprover = {
              firstName,
              lastName,
              username,
            }
            push(formattedApprover)
            reset()
          }
          const handleRemoveApprover = (index: number) => {
            remove(index)
          }
          return (
            <div className="flex-grow ">
              {approvers?.map((approver: Approver, index: number) => (
                <OrderApprover
                  approver={approver}
                  userHasModifyPermission={userHasModifyPermission}
                  remove={() => handleRemoveApprover(index)}
                  key={approver.username}
                />
              ))}

              {userHasModifyPermission &&
                (enableAddApprover ? (
                  <div>
                    <AsyncSelect
                      promise={getAllUsers}
                      isSearchable={!isTabletOrMobile}
                      placeholder="Select an approver"
                      className={classNames(
                        "form-control form-control--first form-control--last flex-grow mr-3 inline-block",
                        styles.select
                      )}
                      blurInputOnSelect={false}
                      isClearable={true}
                      onChange={(val) => {
                        setSelectedApprover(val ? val.value : null)
                      }}
                      name={undefined}
                      label={undefined}
                      optionLabel={"username"}
                      filterOptions={(option) =>
                        !usernames.includes(option.value.username)
                      }
                    />

                    <button
                      type="button"
                      disabled={!selectedApprover}
                      className={
                        "button button--autoWidth button--primaryGreen inline-block"
                      }
                      onClick={handleAddApprover}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="button button--autoWidth button--primaryGreen"
                      onClick={() => setEnableAddApprover(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add
                      approver
                    </button>
                  </div>
                ))}
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default OrderApprovals
