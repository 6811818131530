import React from "react"
import PropTypes from "prop-types"

import { weekdays } from "services/constants"

import TimePicker from "components/forms/TimePicker"
import FilterSelect from "components/forms/FilterSelect"

import { faTrashAlt, faPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const OrderReminder = ({
  orderReminder,
  index,
  onAdd,
  onDelete,
  setFieldValue,
}) => {
  const { deliveryDay, orderDay, orderTillTime, notifyMe } = orderReminder

  return (
    <div className="flex flex-col lg:flex-row my-2 border border-gray-300 lg:border-0 p-2 lg:p-0 rounded">
      <div className="flex items-center m-1 lg:m-0" style={{ flex: 3 }}>
        <span className="flex flex-1 font-sansBold text-sm text-gray-700 lg:hidden">
          Delivery day
        </span>
        <div className="flex" style={{ flex: 2 }}>
          <FilterSelect
            options={weekdays}
            value={deliveryDay}
            onChange={(selectedDay) => {
              setFieldValue(
                `orderReminders.${index}.deliveryDay`,
                selectedDay ? selectedDay.value : ""
              )
            }}
            placeholder="Delivery day"
            isClearable={true}
            className="w-full lg:mr-2"
            menuPlacement="top"
          />
        </div>
      </div>
      <div className="flex items-center m-1 lg:m-0" style={{ flex: 4 }}>
        <span className="flex flex-1 font-sansBold text-sm text-gray-700 lg:hidden">
          Place orders by
        </span>
        <div className="flex" style={{ flex: 2 }}>
          <FilterSelect
            options={weekdays}
            value={orderDay}
            onChange={(selectedOrderDay) => {
              setFieldValue(
                `orderReminders.${index}.orderDay`,
                selectedOrderDay ? selectedOrderDay.value : ""
              )
            }}
            placeholder="Order day"
            isClearable={true}
            className="w-10/12 lg:mr-2"
            style={{ flex: 2 }}
            menuPlacement="top"
          />
          <TimePicker
            value={orderTillTime}
            onChange={(time) => {
              setFieldValue(`orderReminders.${index}.orderTillTime`, time)
            }}
          />
        </div>
      </div>
      <div className="flex flex-1 items-center justify-center mx-1 my-2 lg:m-0">
        <div className="flex flex-1 lg:justify-center">
          <div className="flex flex-1 font-sansBold">
            <span className="flex flex-1 text-sm text-gray-700 lg:hidden">
              Notify me
            </span>
            <div
              className="flex items-center lg:justify-center"
              style={{ flex: 2 }}
            >
              <input
                type="checkbox"
                onChange={() => {
                  setFieldValue(`orderReminders.${index}.notifyMe`, !notifyMe)
                }}
                className="form-checkbox text-primaryPink"
                checked={notifyMe}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 m-1 lg:m-0 justify-end lg:items-center">
        <div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              onAdd()
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              onDelete()
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-primaryPink" />
          </button>
        </div>
      </div>
    </div>
  )
}

OrderReminder.propTypes = {
  orderReminder: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
}

export default OrderReminder
