import React, { useContext } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons"
import Logo from "inline-svg/growyze-logo-primary-blue-white.svg"
import LogoIcon from "inline-svg/growyze-icon.svg"
import VenueSwitch from "components/dashboard/VenueSwitch/VenueSwitch"
import DesktopNav from "components/dashboard/DesktopNav/DesktopNav"
import classNames from "classnames/bind"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"

import * as styles from "./Sidebar.module.css"
import FreemiumUpgradeSection from "components/common/FreemiumUpgradeSection/FreemiumUpgradeSection"

const cx = classNames.bind(styles)

const Sidebar = () => {
  const { sidebarCollapsed } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const toggleSidebar = () => {
    dispatch({ type: "TOGGLE_SIDEBAR" })
  }

  return (
    <aside
      className={cx("container", {
        collapsed: sidebarCollapsed,
      })}
    >
      <header className={cx("header", { collapsed: sidebarCollapsed })}>
        {sidebarCollapsed ? (
          <LogoIcon className={styles.logoIcon} />
        ) : (
          <Link to="/dashboard">
            <Logo className={styles.headerLogo} />
          </Link>
        )}
      </header>

      <div className={styles.venueSwitch}>
        <VenueSwitch />
      </div>

      <FreemiumUpgradeSection />

      <div
        className={cx("desktopNavWrapper", {
          collapsed: sidebarCollapsed,
        })}
      >
        <DesktopNav isCollapsed={sidebarCollapsed} />
      </div>

      <div className="w-full mt-auto border-t border-primaryBlueLighter text-right">
        <button className={styles.collapseButton} onClick={toggleSidebar}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            rotation={sidebarCollapsed ? 180 : 0}
          />
        </button>
      </div>
    </aside>
  )
}

export default Sidebar
