import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ReactTooltip from "react-tooltip"

interface CheckboxProps {
  name: string
  isChecked: boolean
  onSelect: () => void
  disabled?: boolean
  infoMessage?: string
  label?: string
  colorClass?: string
}

const Checkbox = ({
  name,
  isChecked,
  disabled = false,
  onSelect,
  infoMessage,
  label,
  colorClass = "text-primaryGreen",
}: CheckboxProps) => {
  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={`flex items-center ${disabled ? "opacity-50" : ""}`}
        onClick={onSelect}
      >
        <input
          type="checkbox"
          disabled={disabled}
          className={`form-checkbox ${colorClass} mr-2`}
          checked={isChecked}
          name={name}
        />
        {label && (
          <span
            className={`font-sansSemiBold ${
              disabled ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            {...(infoMessage
              ? {
                  "data-for": `${name}-checkbox-info-tooltip`,
                  "data-tip": infoMessage,
                }
              : {})}
          >
            {label}
            {infoMessage && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-primaryBlueLighter ml-1"
              />
            )}
          </span>
        )}
      </button>
      {infoMessage && (
        <ReactTooltip
          id={`${name}-checkbox-info-tooltip`}
          type="light"
          place={"top"}
          effect="solid"
          border={true}
          borderColor="#e2e8f0"
          multiline={true}
        />
      )}
    </>
  )
}

export default Checkbox
