import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { normalizeNullValues } from "services/helpers"
import usePermissions from "hooks/usePermissions"
import FormDropdown from "components/forms/FormDropdown"
import OrganizationNotificationsEmails from "./OrganizationNotificationsEmails"

const Schema = Yup.object().shape({
  deliveriesContacts: Yup.object().shape({
    queriedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
    approvedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
    rejectedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
  }),
  invoicesContacts: Yup.object().shape({
    queriedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
    approvedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
    rejectedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
  }),
  ordersContacts: Yup.object().shape({
    placedEmails: Yup.array().of(
      Yup.string().email("Email address is invalid")
    ),
  }),
})

const OrganizationNotifications = ({ onSubmit, initialValues }) => {
  const permissionObj = usePermissions("Company settings")
  return (
    <section className="flex flex-col h-full lg:w-3/4">
      <Formik
        initialValues={normalizeNullValues(initialValues)}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className="flex flex-col mb-5">
                {/* <h2 className="text-lg text-primaryBlue">Purchasing alerts</h2> */}
                <FormDropdown buttonText="Orders" fullWidth={true}>
                  <OrganizationNotificationsEmails
                    title="Orders"
                    name={"ordersContacts.placedEmails"}
                  />
                </FormDropdown>
                <FormDropdown buttonText="Deliveries" fullWidth={true}>
                  <OrganizationNotificationsEmails
                    title="Queried"
                    name={"deliveriesContacts.queriedEmails"}
                  />
                  <OrganizationNotificationsEmails
                    title="Approved"
                    name={"deliveriesContacts.approvedEmails"}
                  />
                  <OrganizationNotificationsEmails
                    title="Rejected"
                    name={"deliveriesContacts.rejectedEmails"}
                  />
                </FormDropdown>
                <FormDropdown buttonText="Invoices" fullWidth={true}>
                  <OrganizationNotificationsEmails
                    title="Queried"
                    name={"invoicesContacts.queriedEmails"}
                  />
                  <OrganizationNotificationsEmails
                    title="Approved"
                    name={"invoicesContacts.approvedEmails"}
                  />
                  <OrganizationNotificationsEmails
                    title="Rejected"
                    name={"invoicesContacts.rejectedEmails"}
                  />
                </FormDropdown>
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className="button button--autoWidth"
                  disabled={!isValid || !permissionObj?.permissions.modify}
                >
                  Save
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </section>
  )
}

OrganizationNotifications.propTypes = {
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  initialValues: PropTypes.object,
}

export default OrganizationNotifications
