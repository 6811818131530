/* eslint-disable */
import React, { useState, useContext } from "react"
import Modal from "react-modal"
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/common/Button/Button"

//import {faSparkles} from "@fortawesome/pro-duotone-svg-icons"
//import { navigate } from "gatsby"
//import usePermissions from "hooks/usePermissions"
interface BaseModalProps {
  onRequestClose: any
  customContent?: boolean
  header?: {
    show?: boolean
    title?: string
    icon?: any
  }
  children: any
  footer?: {
    show?: boolean
    cancelText?: string
    actionTitle?: string
    action?: Function
    actionCloseModal?: boolean
    disabled?: boolean
  }
}

const footerDefaults = {
  show: true,
  cancelText: "Cancel",
  actionTitle: "Save",
  action: () => {},
  actionCloseModal: true,
  disabled: false,
}

const headerDefaults = {
  show: true,
}

const BaseModal = (props: BaseModalProps) => {
  const { onRequestClose, customContent = false, children } = props

  let { header, footer } = props
  footer = { ...footerDefaults, ...footer }
  header = { ...headerDefaults, ...header }

  const [loading, setLoading] = useState(false)

  const footerBtnAction = (e) => {
    if (footer?.actionCloseModal) onRequestClose()
    if (footer?.action) footer.action(e)
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading}
      portalClassName="RecipeGeneratoAi"
    >
      {header?.show && (
        <header className="flex lg:px-1 relative z-10 flex-col bg-white">
          <div className="flex items-center justify-between border-b ">
            <h2 className="px-4 lg:px-6 py-3 lg:py-6 text-xl text-primaryBlue">
              {header?.icon && (
                <FontAwesomeIcon className="mr-3" icon={header.icon} />
              )}
              {header?.title}
            </h2>
            <button
              className="text-gray-700 px-4 lg:px-6 py-3 text-xl"
              onClick={onRequestClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </header>
      )}
      {customContent && children}
      {!customContent && (
        <>
          <section className="flex justify-between flex-col sm:flex-row flex-grow flex-1 overflow-auto">
            <div className="w-full flex-grow p-4 lg:p-6">{children}</div>
          </section>
          {footer?.show && (
            <footer className="flex justify-between space-x-6 px-4 lg:px-6 py-3 lg:py-4 border-t font-semibold">
              <Button
                color={"white"}
                className="text-gray-700 "
                title={footer?.cancelText}
                onClick={onRequestClose}
              />

              <Button
                color="green"
                className="ml-auto"
                icon={faPlus}
                title={footer.actionTitle}
                onClick={footerBtnAction}
                disabled={footer?.disabled}
              />
            </footer>
          )}
        </>
      )}
    </Modal>
  )
}

export default BaseModal
