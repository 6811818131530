import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import Helmet from "react-helmet"
import * as styles from "./ResetPasswordConfirm.module.css"
import TextInput from "../../forms/TextInput"
import PropTypes from "prop-types"
import AuthFormWrapper from "../AuthFormWrapper/AuthFormWrapper"
import { Link } from "@reach/router"
import Logo from "../../../inline-svg/growyze-logo-primary-blue-dark.svg"
import ContactModal from "components/common/ContactModal/ContactModal"
import { ModalConsumer } from "../../../context/modal/ModalContext"
import { navigate } from "gatsby"
import { resetPasswordConfirm, isLoggedIn } from "services/auth"
import queryString from "query-string"

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, "equalTo", equalTo)

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Minimum of 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .equalTo(Yup.ref("password"), "Passwords don't match"),
})

class ResetPasswordConfirm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",
      resetSuccess: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit(data) {
    const params = {
      ...data,
      token: this.state.token,
    }

    const successResetConfirm = await resetPasswordConfirm(params)

    if (successResetConfirm.error) {
      this.setState({
        error: true,
      })
    } else {
      this.setState({
        resetSuccess: true,
      })
    }
  }

  componentDidMount() {
    if (isLoggedIn()) {
      navigate(`/dashboard`, { replace: true })
      return
    }

    const queryParams = queryString.parse(this.props.location.search)
    if (queryParams.token) {
      this.setState({ token: queryParams.token })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Password reset</title>
        </Helmet>
        <AuthFormWrapper>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={this.handleSubmit}
          >
            {({ isValid, dirty, isSubmitting }) => (
              <Form className="flex flex-col mb-4 px-6">
                <Logo className="mb-6 block mx-auto" />

                <div className={styles.welcomeContent}>
                  <h1 className="text-4xl mb-4">Password reset</h1>
                  {!this.state.resetSuccess && (
                    <p className="text-lg text-gray-700 mb-4">
                      Please enter your new password below:
                    </p>
                  )}
                </div>

                {this.state.error && (
                  <div className={styles.error}>
                    Something went wrong. Having trouble?
                    <ModalConsumer>
                      {({ showModal }) => (
                        <a
                          href="mailto:support@growyze.com"
                          className="text-primaryPink"
                          onClick={(e) => {
                            e.preventDefault()
                            showModal(ContactModal)
                          }}
                        >
                          Contact us
                        </a>
                      )}
                    </ModalConsumer>
                  </div>
                )}

                {this.state.resetSuccess ? (
                  <p className="text-lg text-gray-700 text-center mb-4">
                    Your password is reset! <br />
                    <Link className="text-primaryPink" to="/login">
                      Click here to login
                    </Link>{" "}
                    with your new credentials!
                  </p>
                ) : (
                  <>
                    <div className="form-group mt-2">
                      <div className="input-container">
                        <TextInput
                          name="password"
                          label="Password"
                          type="password"
                          autoComplete="new-password"
                          placeholder="New password"
                          className="form-control form-control--first"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-container">
                        <TextInput
                          name="confirmPassword"
                          label="Confirm password"
                          type="password"
                          autoComplete="new-password"
                          placeholder="Retype your new password"
                          className="form-control form-control--last"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting}
                      className="button mt-5"
                    >
                      Reset and Log In
                    </button>
                  </>
                )}
              </Form>
            )}
          </Formik>
          {!this.state.resetSuccess && (
            <p className="text-sm text-center text-gray-700 py-2 px-6">
              No account yet?{" "}
              <Link className="text-primaryPink" to="/signup">
                Sign Up
              </Link>
            </p>
          )}
        </AuthFormWrapper>
      </>
    )
  }
}

ResetPasswordConfirm.propTypes = {
  location: PropTypes.object,
}

export default ResetPasswordConfirm
