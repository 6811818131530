import React from "react"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt, faList } from "@fortawesome/pro-duotone-svg-icons"
import {
  faPencil,
  faSpinnerThird,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons"
import ActionsButton from "components/common/ActionsButton/ActionsButton"

import * as styles from "./StockTakeSheetHeader.module.css"
import usePermissions from "hooks/usePermissions"

const cx = classNames.bind(styles)

interface Props {
  sheet: { [key: string]: any }
  allSaved: boolean
  isSaving: boolean
  onEdit: () => {}
  onSave: () => {}
  onDelete: () => {}
  onFinalise: () => {}
}

const StockTakeSheetHeader = ({
  sheet,
  allSaved,
  isSaving = false,
  onEdit,
  onSave,
  onDelete,
}: // onFinalise,
Props) => {
  const permissionObj = usePermissions("Stocktakes")

  const options = [
    {
      key: "sheet.delete",
      title: "Delete",
      type: "danger",
      disabled: !permissionObj?.permissions.delete,
    },
  ]
  const handleActionClick = (action) => {
    switch (action.type) {
      case "sheet.edit_name":
        onEdit()
        break
      case "sheet.delete":
        onDelete()
        break
      default:
        break
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.thumbnail}>
        <FontAwesomeIcon icon={faList} />
        <span className={styles.thumbnailBadge}>
          <span>
            <FontAwesomeIcon size="xs" icon={faSyncAlt} />
          </span>
        </span>
      </div>

      <div className={styles.headerMain}>
        <h3 className={styles.ref}>
          {sheet.name || `New sheet`}
          <button
            onClick={onEdit}
            disabled={!permissionObj?.permissions.modify}
            className={cx("button--autoWidth", "editButton")}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </h3>
      </div>
      <button
        className={cx("button button--transparent button--autoWidth ml-auto", {
          "text-primaryGreenDarker": !allSaved,
          disabled: allSaved || isSaving,
        })}
        onClick={onSave}
        disabled={allSaved || isSaving || !permissionObj?.permissions.modify}
      >
        {!allSaved && (
          <FontAwesomeIcon
            icon={isSaving ? faSpinnerThird : faCheck}
            spin={isSaving}
            className="mr-2"
          />
        )}
        {allSaved ? "All saved" : isSaving ? "Saving..." : "Save now"}
      </button>
      {options.length > 0 && (
        <ActionsButton
          options={options}
          onActionClick={(action) => handleActionClick(action)}
        />
      )}
    </div>
  )
}

export default StockTakeSheetHeader
