// extracted by mini-css-extract-plugin
export var found = "GPReportItem-module--found--sak22";
export var iconWrapper = "GPReportItem-module--iconWrapper--V7-Jq";
export var imageWrapper = "GPReportItem-module--imageWrapper--eeDCl";
export var indicator = "GPReportItem-module--indicator--6II0T";
export var indicatorActual = "GPReportItem-module--indicatorActual--pAUF6";
export var indicatorExpected = "GPReportItem-module--indicatorExpected--l68Pq";
export var indicatorVariance = "GPReportItem-module--indicatorVariance--GgYj9";
export var item = "GPReportItem-module--item--KMTU7";
export var noItems = "GPReportItem-module--noItems--s89av";
export var noteOrderStatus = "GPReportItem-module--noteOrderStatus--ELATS";
export var notfound = "GPReportItem-module--notfound--NpHbv";
export var statusBulb = "GPReportItem-module--statusBulb--DwFHq";