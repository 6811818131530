import React, { useContext } from "react"
import { navigate, Link } from "@reach/router"
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import { faShoppingCart as faShoppingCartLight } from "@fortawesome/pro-light-svg-icons"
import { faRedo } from "@fortawesome/pro-light-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { OrderTemplateWithRecurrence } from "services/order-templates/types"

interface Props {
  orderTemplate: OrderTemplateWithRecurrence
}

const StandingOrderItemInfo = ({ orderTemplate }: Props) => {
  const { organization } = useContext(GlobalStateContext)

  return (
    <div className="flex w-auto items-center">
      <div
        className="w-10 h-10 flex-shrink-0 rounded-full flex items-center justify-center px-2 relative"
        style={{
          backgroundColor: "#FFE5F0",
          color: "#FC3762",
        }}
      >
        <FontAwesomeIcon icon={faShoppingCart} />
        <span
          className="absolute h-4 w-4 right-0 rounded-full flex items-center justify-center bg-primaryPink text-white border border-white text-[10px]"
          style={{
            bottom: "-2px",
            right: "-2px",
          }}
        >
          <FontAwesomeIcon icon={faRedo} style={{ fontSize: "8px" }} />
        </span>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault()
          navigate(
            `/dashboard/purchases/orders/standing-orders/${orderTemplate.id}`
          )
        }}
        style={{ minWidth: "180px", textAlign: "left" }}
        className="font-sansSemiBold flex flex-col flex-grow focus:outline-none font-semibold text-primaryBlue py-3 px-3"
        disabled={
          !!(
            orderTemplate.organizations?.length &&
            orderTemplate.organizations[0] !== organization.id
          )
        }
      >
        <p>{orderTemplate?.supplier?.name}</p>
        <p className="text-sm text-gray-700 font-light">
          <FontAwesomeIcon icon={faShoppingCartLight} />{" "}
          {`${orderTemplate.items?.length} ${
            orderTemplate.items?.length === 1 ? "item" : "items"
          }`}
        </p>
        <Link
          to={`/dashboard/purchases/suppliers/${orderTemplate?.supplier?.id}`}
          className="lg:hidden text-gray-700 font-normal font-sans text-sm truncate"
        >
          {orderTemplate.supplier?.name}
        </Link>
      </button>
    </div>
  )
}

export default StandingOrderItemInfo
