import React, { useState, useEffect, Fragment } from "react"

import ActionItem from "components/dashboard/Widgets/ListWidget/ActionItem/ActionItem"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { useDebounce } from "usehooks-ts"
// @ts-ignore
import * as styles from "./ListWidget.module.css"
import { ListItem, PageableData } from "services/types"

import Loader from "components/common/Loader/Loader"
import EmptyState from "components/common/EmptyState/EmptyState"

type WidgetData = PageableData<ListItem>

interface Params {
  getItems: (params: Object) => Promise<WidgetData>
  params: Object
  emptyStateEntityIcon?: React.ElementRef<any>
  emptyStateEntityTitle?: string
}

const initialData: WidgetData = {
  size: 10,
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
  error: undefined,
  content: [],
  empty: false,
  first: false,
  last: true,
  number: 0,
}

const ListWidget = ({
  getItems,
  params,
  emptyStateEntityIcon,
  emptyStateEntityTitle,
}: Params) => {
  const [data, setData] = useState(initialData)
  const [loadingMore, setLoadingMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const paramsDebounced = useDebounce(params, 1000)
  const getItemsForPage = (page) =>
    getItems({ ...params, page, size: data.size })

  const loadMore = () => {
    if (!data.last) {
      setLoadingMore(true)
      getItemsForPage(data.number + 1)
        .then((newData) =>
          setData({ ...newData, content: data.content.concat(newData.content) })
        )
        .then(() => setLoadingMore(false))
    }
  }
  useEffect(() => {
    setLoading(true)
    getItemsForPage(0)
      .then(setData)
      .then(() => setLoading(false))
  }, [paramsDebounced])

  const wrapComponent = (component) => (
    <div>
      <div className={styles.container}>
        <table className={styles.itemList}>
          <tbody>{component}</tbody>
        </table>
      </div>
      {!data.last && (
        <div className={styles.loadMore}>
          {loadingMore ? (
            <FontAwesomeIcon
              icon={faSpinnerThird}
              size="lg"
              className="animate-spin text-primaryGreen mr-2"
            />
          ) : (
            <button
              className={styles.loadMore}
              key="loadMore"
              onClick={loadMore}
            >
              ...
            </button>
          )}
        </div>
      )}
    </div>
  )

  if (loading) {
    return (
      <div className={styles.empty}>
        <Loader
          isLoading={true}
          style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
        >
          Loading...
        </Loader>
      </div>
    )
  } else if (data.content?.length == 0) {
    return emptyStateEntityIcon ? (
      <EmptyState
        iconElement={emptyStateEntityIcon}
        entityTitle={emptyStateEntityTitle}
      />
    ) : (
      <div className={styles.empty}>
        <img src="/images/no-data.png"></img>
      </div>
    )
  } else {
    return wrapComponent(
      data.content?.map((activity) => {
        return (
          <Fragment key={activity.id}>
            <ActionItem action={activity} />
          </Fragment>
        )
      })
    )
  }
}
export default ListWidget
