import React from "react"

interface TitleCellRowData {
  name: string
  autoGenBarcode: boolean
  barcode: string
  _isSub?: boolean
}

interface TitleCellProps {
  rowData: TitleCellRowData
  enableClick: boolean
  onClickEvent(rowData: TitleCellRowData): void
}

const TitleCell = ({ rowData, enableClick, onClickEvent }: TitleCellProps) => {
  const isSub = rowData._isSub

  return (
    <div
      className={`flex flex-col my-3 cursor-pointer ${isSub && "ml-6"}`}
      onClick={(e) => {
        if (!enableClick) {
          return
        }
        e.preventDefault()
        onClickEvent(rowData)
      }}
    >
      <span className="font-sansBold text-sm font-bold text-primaryBlue">
        {rowData.name}
      </span>
      <div className="text-xs">
        {" "}
        {rowData.autoGenBarcode ? "" : rowData.barcode}
      </div>
    </div>
  )
}

export default React.memo(TitleCell)
