import React, { useContext, useEffect, useState } from "react"
import Modal from "react-modal"
import { showError, showSuccess } from "services/toast"
import {
  createReport,
  getStockTakes,
  updateReportById,
} from "services/stock-take/stock-take"
import { getSales } from "services/sales"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import Loader from "components/common/Loader/Loader"
import AsyncSelect from "components/forms/AsyncSelect"
import CategorySelect from "components/common/CategorySelect/CategorySelect"
import {
  formatAsYearMonthDay,
  formatReportSelectOptionLabel,
} from "services/helpers"
import { Category, IconProps } from "services/types"
import {
  applyReportFiltersToCategories,
  formatCategoriesAsReportFilterCategories,
} from "services/reportHelpers"
import useCategories from "hooks/useCategories"
import Checkbox from "components/common/Checkbox/Checkbox"
import CalendarModal from "components/common/Calendar/CalendarModal/CalendarModal"
import { ModalContext } from "context/modal/ModalContext"
import { useUpdateEffect } from "react-use"
import FormDropdown from "components/forms/FormDropdown"
import ItemIcon from "components/common/OverviewItem/ItemIcon/ItemIcon"

import {
  header,
  content,
  footer,
  cancelButton,
  //@ts-ignore
} from "./ReportModal.module.css"

Modal.setAppElement("body")

interface ReportModalProps {
  endpoint:
    | "/gp-reports"
    | "/stock-take/cogs-reports"
    | "/stock-take/discrepancy-report"
  title?: string
  iconConfig: {
    mainIcon: IconProps
    subIcon?: IconProps
  }
  loadingText?: string
  currentReportId?: string
  stockTakeReportId?: string
  isUpdate?: boolean
  onCloseParentModal?: () => void
  onRequestClose?: () => void
  onCreate?: (createdReportId: string) => void
  onUpdate?: () => void
  currentReportData?: any
  insights?: false // wether this report is being generated from the 'Insights' section
  [key: string]: any
}

const ReportModal = ({
  endpoint,
  title,
  iconConfig,
  loadingText,
  stockTakeReportId,
  currentReportId,
  isUpdate = false,
  onCloseParentModal,
  onRequestClose,
  onCreate,
  onUpdate,
  currentReportData,
  insights,
  ...otherProps
}: ReportModalProps) => {
  const {
    openingStockTakeReportId,
    sales,
    filterCategories,
    theoretical,
    periodTo,
  } = currentReportData || {}

  const modal = useContext(ModalContext)

  const [loading, setLoading] = useState(false)
  const [selectedOpeningStocktake, setSelectedOpeningStocktake] = useState<{
    label: string | undefined
    value: any
  } | null>(null)
  const [selectedClosingStocktake, setSelectedClosingStocktake] = useState<{
    label: string | undefined
    value: any
  } | null>(null)
  const [selectedSalesFile, setSelectedSalesFile] = useState<{
    label: string | undefined
    value: any
  } | null>(null)

  const [endDate, setEndDate] = useState<string | undefined>(
    periodTo ? formatAsYearMonthDay(new Date(periodTo)) : undefined
  )

  const [includeTheoretical, setIncludeTheoretical] = useState(
    theoretical !== undefined && theoretical !== null ? theoretical : true
  )

  const defaultCategories = useCategories().categories
  const [categories, setCategories] = useState<Category[]>(defaultCategories)

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      setCategories(
        applyReportFiltersToCategories(
          defaultCategories,
          filterCategories,
          filterCategories ? filterCategories.includeUncategorised : true,
          filterCategories ? false : true
        )
      )
    }
    return () => {
      isMounted = false
    }
  }, [defaultCategories, filterCategories])

  const fetchFinalisedStockTakes = async (
    inputValue,
    forSelectValue: "openingStockTake" | "closingStockTake"
  ) => {
    setLoading(true)

    const params = {
      size: 999,
      sort: "completedAt,desc",
      status: "COMPLETED",
      partialName: inputValue ? inputValue : null,
    }
    const result = await getStockTakes(params)

    if (forSelectValue === "openingStockTake" && openingStockTakeReportId) {
      const openingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === openingStockTakeReportId
      )

      if (openingStockTake) {
        setSelectedOpeningStocktake({
          label: formatReportSelectOptionLabel(openingStockTake, "stocktake"),
          value: openingStockTake,
        })
      }
    }

    if (forSelectValue === "closingStockTake" && stockTakeReportId) {
      const closingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === stockTakeReportId
      )
      if (closingStockTake) {
        setSelectedClosingStocktake({
          label: formatReportSelectOptionLabel(closingStockTake, "stocktake"),
          value: closingStockTake,
        })
      }
    }

    setLoading(false)

    return result
  }

  const fetchSalesFiles = async () => {
    setLoading(true)

    const params = {
      size: 999,
      sort: "createdAt,desc",
      live: false,
    }
    const result = await getSales(params)

    const salesFile = result.content.find((sf) => sf.id === sales?.id)

    if (salesFile) {
      setSelectedSalesFile({
        label: formatReportSelectOptionLabel(salesFile, "sales"),
        value: salesFile,
      })
    }
    setLoading(false)
    return result
  }

  const toggleDatePicker = () => {
    // @ts-ignore
    modal.showModal(CalendarModal, {
      selectedDate: endDate,
      onConfirm: (date) => {
        // When a endDate date is selected, set clear the closing stocktake if that is selected
        // We cannot select both
        setEndDate(date)
        setSelectedClosingStocktake(null)
      },
    })
  }

  const onSaveDiscrepancyReport = async () => {
    setLoading(true)
    // If a category is selected return the mainCategories object for filterCategories, else send null to get all products in stocktake.
    const filteredCategories =
      formatCategoriesAsReportFilterCategories(categories)

    const bodyParams = {
      filterCategories:
        filteredCategories?.mainCategories &&
        filteredCategories?.mainCategories.length > 0
          ? filteredCategories
          : undefined,
    }

    const queryParams: any = {
      currentReportId: null,
      stockTakeReportId: null,
      openingStockTakeReportId: null,
      salesId: null,
      insights: insights,
      theoretical: includeTheoretical,
      // @ts-ignore
      endDate: endDate ? new Date(endDate).toISOString() : undefined,
    }

    if (currentReportId) {
      queryParams.currentReportId = currentReportId
    }
    // When a report is being generated based on an existing stocktake, the stocktakeReportId is passed in as a prop
    if (!insights && stockTakeReportId) {
      queryParams.stockTakeReportId = stockTakeReportId
    } else if (selectedClosingStocktake) {
      // Else, when a report is being generated from the 'Insights' section, the reportId can be selected manually
      queryParams.stockTakeReportId =
        selectedClosingStocktake.value.stockTakeReport.id
    }

    if (selectedOpeningStocktake) {
      queryParams.openingStockTakeReportId =
        selectedOpeningStocktake.value.stockTakeReport.id
    }

    if (selectedSalesFile) {
      queryParams.salesId = selectedSalesFile.value.id
    }

    const result = isUpdate
      ? await updateReportById(
          endpoint,
          queryParams.currentReportId,
          queryParams.stockTakeReportId,
          queryParams.openingStockTakeReportId,
          queryParams.salesId,
          queryParams.insights,
          queryParams.theoretical,
          queryParams.endDate,
          bodyParams
        )
      : await createReport(
          endpoint,
          queryParams.stockTakeReportId,
          queryParams.openingStockTakeReportId,
          queryParams.salesId,
          queryParams.insights,
          queryParams.theoretical,
          queryParams.endDate,
          bodyParams
        )

    setLoading(false)

    if (result.status >= 300) {
      return showError(result.message)
    } else if (result) {
      setLoading(false)
      showSuccess("Report generated!", { position: "top-center" })

      if (onCloseParentModal) {
        onCloseParentModal()
      }

      onRequestClose && onRequestClose()

      // Optional callback on new report
      !isUpdate && onCreate && onCreate(result.id)

      // Optional callback on update report
      isUpdate && onUpdate && onUpdate()
    }
  }

  /**
   * Determines whether the theoretical report should be generated.
   * If the selected closing stocktake is undefined and the stocktake report ID is undefined,
   * then the theoretical report should be generated.
   * @returns {boolean} Whether the theoretical report should be generated.
   */
  const forceTheoretical = !selectedClosingStocktake && periodTo && insights

  useUpdateEffect(() => {
    if (selectedClosingStocktake && endDate) {
      setEndDate(undefined)
    }
    if (!theoretical && forceTheoretical) {
      setIncludeTheoretical(true)
    }
  }, [selectedClosingStocktake, endDate])

  const buttonText = isUpdate ? "Update report" : "Create report"

  const buttonIcon = !isUpdate && (
    <FontAwesomeIcon icon={faPlus} className="mr-2" />
  )

  const categoryCount = categories
    ? categories.reduce((acc, item) => {
        return acc + item.subCategories.filter((subCat) => subCat.active).length
      }, 0)
    : 0

  const createReportDisabled =
    !selectedOpeningStocktake ||
    (!stockTakeReportId && !selectedClosingStocktake && !endDate)

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      portalClassName="selectOpeningStockTake"
      {...otherProps}
    >
      <div className="flex flex-col h-full">
        <div className={header}>
          <ItemIcon {...iconConfig} />
          <h2 className="text-lg">{title}</h2>
        </div>

        <div className={content}>
          <div className="my-1 px-2 w-full">
            <Loader
              isLoading={loading}
              style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
            >
              {loadingText}
            </Loader>

            <h2 className="my-2">
              {openingStockTakeReportId
                ? "Edit opening stocktake"
                : "Select opening stocktake"}
            </h2>
            <AsyncSelect
              name="Opening stocktake"
              label="Opening stocktake"
              className="w-full"
              id="openingStocktake"
              value={selectedOpeningStocktake}
              promise={(inputValue) =>
                fetchFinalisedStockTakes(inputValue, "openingStockTake")
              }
              defaultOptions
              placeholder="Opening stocktake"
              isClearable={true}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "stocktake")
              }
              onChange={(stocktake) => {
                setSelectedOpeningStocktake(stocktake)
              }}
            />

            {/* Closing stocktake can only be changed from 'Insights' section, when stockTakeReportId is not passed in from the outside */}
            {(insights || !stockTakeReportId) && (
              <div className="flex flex-wrap items-start border px-4 mt-4 rounded">
                <div className="flex-col flex-grow pb-4">
                  <h2 className="mb-2 mt-4">
                    {openingStockTakeReportId
                      ? "Edit closing stocktake"
                      : "Select closing stocktake"}
                  </h2>
                  <AsyncSelect
                    name="Closing stocktake"
                    label="Closing stocktake"
                    className="w-full"
                    id="closingStocktake"
                    value={selectedClosingStocktake}
                    promise={(inputValue) =>
                      fetchFinalisedStockTakes(inputValue, "closingStockTake")
                    }
                    placeholder="Closing stocktake"
                    defaultOptions
                    isClearable={true}
                    optionLabel={(option) =>
                      formatReportSelectOptionLabel(option, "stocktake")
                    }
                    onChange={(stocktake) => {
                      setSelectedClosingStocktake(stocktake)
                    }}
                  />
                </div>
                <span className="ml-4 self-stretch flex items-center text-gray-500 font-bold font-sansBold border-l border-r px-2">
                  OR
                </span>
                <div className="flex-col flex-grow ml-4 pb-4">
                  <h2 className="mb-2 mt-4">
                    {openingStockTakeReportId
                      ? "Edit end date"
                      : "Select end date"}
                  </h2>
                  <div className={`input-container`}>
                    <input
                      type="text"
                      value={endDate ? endDate : ""}
                      className="cursor-pointer form-control rounded"
                      placeholder="Select an end date"
                      readOnly
                      onClick={toggleDatePicker}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="mt-3 mb-1">
              <Checkbox
                name="includeTheoretical"
                disabled={forceTheoretical}
                label="Include theoretical stock"
                isChecked={includeTheoretical}
                infoMessage={
                  forceTheoretical
                    ? "All expected closing qty in this report are theoretical as no closing stocktake has been selected"
                    : "Include theoretical stock that isn't counted yet in the report"
                }
                onSelect={() => setIncludeTheoretical((prev) => !prev)}
              />
            </div>

            <h2 className="mb-2 mt-4">
              {openingStockTakeReportId
                ? "Edit sales file"
                : "Select sales file (optional)"}
            </h2>
            <AsyncSelect
              name="Sales file"
              label="Sales file"
              className="w-full"
              defaultOptions
              cacheOptions
              value={selectedSalesFile}
              promise={fetchSalesFiles}
              placeholder="Sales file"
              isClearable={true}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "sales")
              }
              onChange={(sf) => {
                setSelectedSalesFile(sf)
              }}
            />
          </div>
          <FormDropdown
            noBorder
            buttonTextSuffix={
              <span className="mb-2 ml-3 flex flex-col items-start">
                <span className="underline hover:no-underline text-primaryBlue font-semibold font-sansSemiBold">
                  Edit product categories
                </span>
                <span className="font-normal font-sans text-sm text-gray-600">
                  {categoryCount} categories selected
                </span>
              </span>
            }
            fullWidth={true}
          >
            <CategorySelect
              categories={categories}
              onSelectCategories={(selectedCategories) =>
                setCategories(selectedCategories)
              }
            />
          </FormDropdown>
        </div>
        <div className={footer}>
          <button
            type="button"
            className={cancelButton}
            onClick={onRequestClose}
          >
            Close
          </button>
          <button
            type="button"
            className="button ml-auto button--autoWidth button--primaryGreen"
            onClick={onSaveDiscrepancyReport}
            disabled={createReportDisabled}
          >
            <span>{buttonIcon}</span>
            <span>{buttonText}</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ReportModal
