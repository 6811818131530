import React, { useState, useEffect, useRef } from "react"
import { usePromise, usePrevious, useMountedState } from "react-use"
import { getProducts } from "services/products/products"
import { searchSuppliers } from "services/suppliers/suppliers"
import {
  faFilter,
  faPlus,
  faCheck,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faBan,
  faSpinnerThird,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import SearchInput from "components/forms/SearchInput"
import AsyncSelect from "components/forms/AsyncSelect"
import FilterSelect from "components/forms/FilterSelect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  productCategoriesCombined,
  productSingleUnits,
  productSearchQueryParameters,
  searchFilterSelectStyles,
} from "services/constants"

import classNames from "classnames/bind"
//@ts-ignore
import * as styles from "./NewProductSelect.module.css"
import ProductSelectItem from "../ProductSelectItem/ProductSelectItem"
import EditPrice from "../EditPrice/EditPrice"
import {
  isTouchDevice,
  setQtyInputValues,
  sumArrayValues,
} from "services/helpers"
import { ItemFromSelector, SelectedItem } from "./types"
import NewProductSelectSummary from "./NewProductSelectSummary"

const cx = classNames.bind(styles)

export const hasQuantities = (item: SelectedItem) =>
  sumArrayValues(Object.values(item.quantities)) > 0

type NewProductSelectProps = {
  [key: string]: any
}

interface SearchParams {
  page: number
  size: number
  sort: string
  supplierId?: string
  supplierName?: string
  partialProductName?: string
  partialCode?: string
  partialBarcode?: string
  partialPosId?: string
  unit?: string
  subCategory?: string
  extended: boolean
}

const NewProductSelect = ({
  onSelect,
  selectedSupplier,
  searchBySupplier,
  searchByBarcode,
  searchBySKU,
  searchByCategories,
  searchByPackaging,
  onAddItem,
  onClose,
  priceLabel,
  highlightPosId,
  multiSelect = true,
  negativeQuantitiesAllowed = false,
  allowClearAll = false,
  allowSelectAll = false,
  requiredFields = [],
  qtyInputTypes = [{ label: "Qty", shortLabel: "Q", quantityKey: "qty" }],
  enablePriceEdit = true,
  enableMultiplePackaging = true,
  qtyPicker = true,
  createNewProduct = null,
  forcePackingForProducts = {},
}: NewProductSelectProps) => {
  const _isMounted = useMountedState()
  const fromPromise = usePromise()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [queryParameter, setQueryParameter] = useState({
    label: "name",
    value: "partialProductName",
  })

  const [loadedAll, setLoadedAll] = useState(false)
  const [summaryOpen, setSummaryOpen] = useState(false)

  const [productData, setProductData] = useState<{
    content: Array<ItemFromSelector>
    totalElements: number
  }>({
    content: [],
    totalElements: 0,
  })
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const [supplier, setSupplier] = useState(
    selectedSupplier ? selectedSupplier : null
  )
  const [editItem, setEditItem] = useState<SelectedItem | undefined>(undefined)

  const previousSupplier = usePrevious(supplier)

  const [packaging, setPackaging] = useState<{ value: string } | null>(null)
  const previousPackaging = usePrevious(packaging)

  const [category, setCategory] = useState<{ value: string } | null>(null)
  const previousCategory = usePrevious(category)

  const hasFilters =
    searchBySupplier ||
    searchByBarcode ||
    searchBySKU ||
    searchByCategories ||
    searchByPackaging
  // const [selectedPackaging, setSelectedPackaging] = useState([])

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  })
  const previousPage = usePrevious(paginationData.page)

  const [selectedProducts, setSelectedProducts] = useState<Array<SelectedItem>>(
    []
  )

  const selectedProductsWithQuantities = () =>
    selectedProducts.filter(hasQuantities)

  const searchParameters = productSearchQueryParameters.filter((p) => {
    let isSKU = false
    let isBarcode = false
    if (!searchBySKU && p.value === "partialCode") {
      isSKU = true
    }
    if (!searchByBarcode && p.value === "partialBarcode") {
      isBarcode = true
    }
    return !isSKU && !isBarcode
  })

  const getFilterCount = () => {
    let count = 0
    if (supplier) {
      count++
    }
    if (packaging) {
      count++
    }
    if (category) {
      count++
    }
    return count
  }

  const filterCount = getFilterCount()

  const onSelectItem = ({ item, packaging, quantities }) => {
    const newSelectedItem = {
      ...item,
      packaging,
      quantities,
      selectedPrice:
        packaging === "multiple" ? item.productCase.price : item.price,
    }

    setSelectedProducts(
      selectedProducts
        // Clear any previous occurences of the product to avoid duplicates
        .filter((p) => p.id !== item.id)
        // Attach the selected product to the list of selected products
        .concat([newSelectedItem])
    )
    if (!multiSelect) {
      onSelect(newSelectedItem)
      resetState()
    }
  }

  const deselectItem = (item) => {
    const arrayWithoutTarget = selectedProducts.filter((i) => i.id !== item.id)
    setSelectedProducts(arrayWithoutTarget)
  }

  const onEdit = (item) => {
    setEditItem(item)
  }

  const resetState = () => {
    setSelectedProducts([])
  }

  const handlePriceChange = (item, price) => {
    const latestProducts = [...selectedProducts]
    setSelectedProducts(
      latestProducts.map((p) => {
        if (p.id === item.id) {
          return { ...p, selectedPrice: price }
        } else {
          return p
        }
      })
    )
  }

  const handlePackagingChange = (item, packaging) => {
    const latestProducts = [...selectedProducts]
    setSelectedProducts(
      latestProducts.map((p) => {
        if (p.id === item.id) {
          return {
            ...p,
            selectedPrice:
              packaging === "multiple" ? p.productCase.price : p.price,
            packaging,
          }
        } else {
          return p
        }
      })
    )
  }

  const mapContentItems = (result) => {
    return [
      ...result.content.map((item) => {
        return {
          ...item,
        }
      }),
    ]
  }

  const getFetchParams = (addAll = false) => {
    const params: SearchParams = {
      page: addAll ? 0 : paginationData.page,
      size: addAll ? 9999 : paginationData.size,
      sort: "barcode,asc",
      extended: true,
    }

    if (supplier) {
      params.supplierId = supplier.id
    }

    if (packaging) {
      params.unit = packaging.value

      if (previousPackaging && previousPackaging.value !== packaging.value) {
        params.page = 0
      }
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialProductName":
          params.partialProductName = q
          break
        case "partialCode":
          params.partialCode = q
          break
        case "partialBarcode":
          params.partialBarcode = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        default:
          params.partialProductName = q
          break
      }
    }

    if (category) {
      params.subCategory = category.value

      if (previousCategory && previousCategory.value !== category.value) {
        params.page = 0
      }
    }
    return params
  }

  const getData = async (addAll = false) => {
    if (!_isMounted()) {
      return
    }
    setLoading(true)

    const params = getFetchParams(addAll)

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getProducts(params))

    const { totalPages, error } = result

    if (!error) {
      // User wants to select all products available
      if (addAll) {
        setLoadingMore(false)

        // Make a list of all promises that are needed to load all pages of products
        const promises = new Array(totalPages).fill(null).map(async (c, i) => {
          const extraRes = getProducts({
            ...params,
            page: i,
          }).then((res) => {
            if (res && !res.error) {
              return mapContentItems(res)
            }
            return []
          })
          return extraRes
        })

        // Parse the result of alle promises to a list of combined products
        const additionalData = await Promise.all(promises).then(
          (values) =>
            values.reduce((accumulator, current) => {
              return accumulator.concat(current)
            }),
          () => []
        )

        setLoadedAll(true)
        setLoading(false)

        setProductData({
          ...result,
          content: additionalData,
        })

        // Select all products - default to single packaging, qty = 1
        setSelectedProducts((prev) => [
          ...prev,
          ...additionalData
            .filter((a) => !selectedProducts.find((b) => b.id === a.id))
            .map((item) => {
              return {
                ...item,
                packaging: "single",
                quantities: { qty: 1 },
                selectedPrice: item.price,
              }
            }),
        ])

        setPaginationData({
          page: Math.ceil(additionalData.length / 10),
          size: paginationData.size,
          totalPages: totalPages,
          totalElements: additionalData.length,
          numberOfElements: additionalData.length,
        })
      } else {
        const newContent = mapContentItems(result)

        if (paginationData.page === 0) {
          setProductData({ ...result, content: [...newContent] })
        } else {
          setProductData({
            ...result,
            content: [...productData.content, ...newContent],
          })
        }

        setPaginationData({
          ...paginationData,
          size: result.size,
          totalPages: result.totalPages,
          totalElements: result.totalElements,
          numberOfElements: result.numberOfElements,
        })

        setLoading(false)
        setLoadingMore(false)
        setLoadedAll(paginationData.page === result.totalPages - 1)

        // Fix scrollTop after products are appended
        // only on touch devices
        if (
          isTouchDevice() &&
          loadingMore &&
          lastScrollTop > 0 &&
          scrollRef.current != null
        ) {
          scrollRef.current.scrollTop = lastScrollTop
        }
      }
    }
  }

  const resetPage = () => {
    if (paginationData.page === 0) {
      return getData()
    }
    setPaginationData({
      ...paginationData,
      page: 0,
    })
  }

  const handleEndReached = () => {
    if (loading || loadingMore || loadedAll) return
    loadMore()
  }

  const loadMore = () => {
    setLoadingMore(true)
    setPaginationData({ ...paginationData, page: paginationData.page + 1 })
  }

  const showFilters = () => {
    setFiltersOpen(true)
  }

  const onScroll = () => {
    if (scrollRef.current !== null) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
      setLastScrollTop(scrollTop)

      if (scrollHeight - (scrollTop + clientHeight) < 300) {
        handleEndReached()
      }
    }
  }

  const getSelectedItem = (item) =>
    selectedProducts.find((p) => p.id === item.id)

  const isSelected = (item) => getSelectedItem(item)?.id === item.id

  const getQuantities = (item) => {
    const isSelected =
      selectedProducts.length > 0 &&
      selectedProducts.find((p) => p.id === item.id)

    if (isSelected) {
      return isSelected.quantities
    } else {
      return setQtyInputValues(qtyInputTypes, 0)
    }
  }

  const getPackaging = (item) => {
    const isSelected =
      selectedProducts.length > 0 &&
      selectedProducts.find((p) => p.id === item.id)

    if (isSelected) {
      return isSelected.packaging
    } else {
      return "single"
    }
  }

  useEffect(() => {
    if (!supplier) {
      if (!selectedSupplier) {
        getData()
      } else {
        setSupplier(selectedSupplier)
      }
    }
  }, [selectedSupplier, supplier])

  useEffect(() => {
    // Make sure this doesn't get triggered when everything is already loaded
    if (previousPage !== undefined && !loadedAll) {
      getData()
    }
  }, [paginationData.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      resetPage()
    }
  }, [supplier])

  useEffect(() => {
    if (previousPackaging || packaging) {
      getData()
    }
  }, [packaging])

  useEffect(() => {
    if (previousCategory || category) {
      resetPage()
    }
  }, [category])

  useEffect(() => {
    if (previousQ || q) {
      resetPage()
    }
  }, [previousQ, q])

  return (
    <>
      <div className={styles.container}>
        {/* Overlay */}
        {/* {filtersOverlay} */}

        {hasFilters && (
          <div className={cx("filtersOverlay", { invisible: !filtersOpen })}>
            <div className="flex justify-between py-3 border-b px-4 md:px-6 flex-shrink-0">
              <h4 className="text-primaryBlue text-lg font-semibold font-sansSemiBold">
                Filters
              </h4>
              <button onClick={() => setFiltersOpen(false)} className="text-xl">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="flex-grow overflow-auto py-3">
              {searchBySupplier && (
                <div className="py-1 px-4 md:px-6 w-full">
                  <AsyncSelect
                    name="selectedSupplier"
                    label="Supplier"
                    promise={searchSuppliers}
                    placeholder="All suppliers"
                    isDisabled={selectedSupplier || loading}
                    value={
                      selectedSupplier
                        ? {
                            value: selectedSupplier,
                            label: selectedSupplier.name,
                          }
                        : supplier
                        ? { value: supplier, label: supplier.name }
                        : null
                    }
                    isClearable={true}
                    optionLabel="name"
                    onChange={(val) => {
                      setSupplier(val ? val.value : null)
                    }}
                  />
                </div>
              )}
              {searchByCategories && (
                <div className="py-1 px-4 md:px-6 w-full">
                  <FilterSelect
                    options={productCategoriesCombined}
                    value={category}
                    getOptionLabel={(opt) => `${opt.groupLabel} - ${opt.label}`}
                    onChange={async (val) => {
                      await setCategory(val)
                    }}
                    placeholder="All categories"
                    isClearable={true}
                    isSearchable={true}
                    disabled={loading}
                    className="my-1 w-full text-sm md:text-base"
                  />
                </div>
              )}

              {searchByPackaging && (
                <div className="py-1 px-4 md:px-6 w-full">
                  <FilterSelect
                    options={productSingleUnits}
                    value={packaging}
                    onChange={async (val) => {
                      setPackaging(val)
                    }}
                    placeholder="All packaging"
                    isClearable={true}
                    disabled={loading}
                    isSearchable={true}
                    className="my-1 w-full text-sm md:text-base"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between py-3 border-t px-4 md:px-6 flex-shrink-0 relative z-30">
              <button
                onClick={() => setFiltersOpen(false)}
                className="button button--primaryGreen"
                disabled={loading}
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    spin
                    className="mr-2"
                  />
                )}
                Show {productData.totalElements} results
              </button>
            </div>
          </div>
        )}

        {/* Header */}
        <div className={cx("subHeader")}>
          <div className="my-2 px-1 w-full flex">
            <div className="relative w-full flex">
              <SearchInput
                label="Name"
                placeholder={`Product ${queryParameter.label}`}
                className="form-control rounded md:text-base"
                onSearchChange={(val) => {
                  setQ(val)
                }}
                resetVal={queryParameter}
              />
              <FilterSelect
                options={searchParameters}
                value={queryParameter && queryParameter.value}
                onChange={(val) => {
                  setQueryParameter(val)
                }}
                stylesOverride={searchFilterSelectStyles}
              />
            </div>
            {hasFilters && (
              <button
                onClick={showFilters}
                className="bg-paleBlue border relative rounded-r px-4 -ml-1 rounded-l-none"
              >
                <FontAwesomeIcon icon={faFilter} />
                {filterCount > 0 && (
                  <span className="text-center text-xs font-sansSemiBold font-semibold absolute -top-2 -right-2 bg-primaryBlue text-white w-5 h-5 rounded-full block">
                    {filterCount}
                  </span>
                )}
              </button>
            )}
            {/* 
              Note: Selecting all can cause a performance issue 
              when there are a lot of products. It might be worth looking into 
              using react-window to virtualize the scrollable list with a lot of elements  */}
            {multiSelect && allowSelectAll && (
              <button
                type="button"
                disabled={loading}
                className="button button--autoWidth no-truncate ml-2 button--primaryBlue"
                onClick={() => getData(true)}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                <span>All</span>
              </button>
            )}
            {onClose && (
              <button
                onClick={() => {
                  onClose()
                  resetState()
                }}
                className="text-xl px-3 ml-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </div>

        <div className={styles.content}>
          <div
            className="w-full flex-grow overflow-auto"
            ref={scrollRef}
            onScroll={onScroll}
          >
            {productData.content.length === 0 && (
              <div className={styles.noProducts}>
                <p className="mb-4">
                  {loading ? "Loading..." : "No products found"}
                </p>
                {onAddItem && (
                  <button
                    type="button"
                    onClick={onAddItem}
                    className="
              button button--autoWidth button--primaryGreen
              addManualButton
            "
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-3" />
                    Add item
                  </button>
                )}
              </div>
            )}
            {productData.content.map((item, index) => {
              return (
                <ProductSelectItem
                  key={`${item.id}-${index}`}
                  item={item}
                  forcePackaging={forcePackingForProducts[item.id] ?? false}
                  qtyInputTypes={qtyInputTypes}
                  isSelected={isSelected(item)}
                  negativeQuantitiesAllowed={negativeQuantitiesAllowed}
                  onSelect={({ packaging, quantities }) =>
                    onSelectItem({
                      item,
                      packaging,
                      quantities,
                    })
                  }
                  onDeSelect={() => deselectItem(item)}
                  onPackagingChange={(val) => handlePackagingChange(item, val)}
                  quantities={getQuantities(item)}
                  packaging={getPackaging(item)}
                  enableMultiplePackaging={enableMultiplePackaging}
                  requiredFields={requiredFields}
                  highlightPosId={highlightPosId}
                  qtyPicker={qtyPicker}
                  onSelectDisabled={
                    !multiSelect && selectedProducts.length >= 1 ? true : false
                  }
                />
              )
            })}
            {loadingMore && (
              <div className="w-full flex items-center justify-center px-4 py-1">
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              </div>
            )}
          </div>
        </div>
        {(multiSelect || createNewProduct) && (
          <footer className={cx("footer", { open: summaryOpen })}>
            <div
              className={cx(
                "flex px-4 lg:px-6 py-3 lg:py-4 w-full items-center justify-end",
                {
                  "border-b": summaryOpen,
                }
              )}
            >
              {multiSelect && (
                <button
                  onClick={() => setSummaryOpen(!summaryOpen)}
                  className="flex items-start flex-grow"
                >
                  <FontAwesomeIcon
                    icon={summaryOpen ? faChevronDown : faChevronUp}
                    className="mr-3 mt-1"
                  />
                  <span className="font-semibold mr-4 text-left flex flex-grow">
                    <span className="text-primaryBlue mr-2">
                      {selectedProductsWithQuantities().length}{" "}
                      {selectedProductsWithQuantities().length === 1
                        ? "product"
                        : "products"}
                    </span>
                    <span className="text-gray-600">selected</span>
                  </span>
                </button>
              )}
              {createNewProduct && (
                <button
                  onClick={createNewProduct}
                  className={cx(
                    "button button--autoWidth button--primaryGreen",
                    { "mr-4": multiSelect }
                  )}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={styles.buttonIcon}
                  />
                  <span className={styles.buttonText}>Add item</span>
                </button>
              )}
              {multiSelect && allowClearAll && (
                <button
                  type="button"
                  disabled={selectedProducts.length === 0 || loading}
                  className="button button--autoWidth button--transparent-pink"
                  onClick={() => {
                    resetState()
                  }}
                >
                  <FontAwesomeIcon className="mr-2" icon={faBan} />
                  Clear all
                </button>
              )}
              {!summaryOpen && multiSelect && (
                <button
                  type="button"
                  className="button button--autoWidth button--primaryGreen"
                  onClick={() => {
                    // Old method - Adding only products that have quantities, leaving for reference
                    // onSelect(selectedProductsWithQuantities())
                    onSelect(selectedProducts)
                    resetState()
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles.buttonIcon}
                  />
                  <span className={styles.buttonText}>Confirm</span>
                </button>
              )}
            </div>

            {summaryOpen && (
              <NewProductSelectSummary
                selectedProducts={selectedProductsWithQuantities()}
                qtyInputTypes={qtyInputTypes}
                onEdit={onEdit}
                deselectItem={deselectItem}
                priceLabel={priceLabel}
                enablePriceEdit={enablePriceEdit}
              />
            )}

            {/* Edit panel from bottom */}
            {editItem != undefined && (
              <>
                <div
                  className={cx("editOverlay", { show: editItem })}
                  onClick={() => setEditItem(undefined)}
                ></div>
                <div className={cx("editWrapper", { isOpen: editItem })}>
                  <div className="border-b py-2 px-4 text-center">
                    <h3 className="text-primaryBlue font-sansSemiBold font-semibold">
                      Edit price: {editItem.name}
                    </h3>
                  </div>
                  <EditPrice
                    item={editItem}
                    onSave={(price) => {
                      handlePriceChange(editItem, price)
                      setEditItem(undefined)
                    }}
                  />
                </div>
              </>
            )}
          </footer>
        )}
      </div>
    </>
  )
}

export default NewProductSelect
