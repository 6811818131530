const aiRecipeReducer = (state, action) => {
  switch (action.type) {
    case "ADD_AI_GENERATED_RECIPE": {
      const { recipe } = action.payload
      return {
        ...state,
        selectedAiGeneratedRecipe: recipe,
      }
    }
    default: {
      return state
    }
  }
}
export default aiRecipeReducer
