import { isBrowser } from "services/helpers"
import { post } from "./api"

/**
 * Signal to cancel requests
 */
export let controller

export const createPdf = (
  params,
  { completed = false, client = "delivery-note", fileId = null }
) => {
  controller = isBrowser() ? new AbortController() : null
  const signal = controller.signal
  return post(
    `/file-processing/pdf/create?completed=${completed}&client=${client}${
      fileId ? "&fileId=" + fileId : ""
    }`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
      signal,
    }
  )
}

export const operatePdf = (params, { client = "delivery-note" }) => {
  controller = isBrowser() ? new AbortController() : null
  const signal = controller.signal
  return post(`/file-processing/pdf/operation?client=${client}`, params, {
    headers: { "Content-Type": "multipart/form-data" },
    signal,
  })
}

export const extractDeliveryNoteDataFromPdf = (jobId, supplierName) => {
  return post(
    `/file-processing/pdf/extract-dn-data?jobId=${jobId}&supplierName=${supplierName}`
  )
}

export const extractDeliveryNoteDataFromImg = (params, supplierName) => {
  controller = isBrowser() ? new AbortController() : null
  const signal = controller.signal
  return post(
    `/file-processing/img/extract-dn-data?supplierName=${supplierName}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
      signal,
    }
  )
}

export const extractInvoiceDataFromPdf = (jobId, supplierName) => {
  return post(
    `/file-processing/pdf/extract-invoice-data?jobId=${jobId}&supplierName=${supplierName}`
  )
}

export const extractInvoiceDataFromImg = (params, supplierName) => {
  controller = isBrowser() ? new AbortController() : null
  const signal = controller.signal
  return post(
    `/file-processing/img/extract-invoice-data?supplierName=${supplierName}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
      signal,
    }
  )
}

export const contactSupport = (params) => {
  return post("/file-processing/support", params)
}

export const cancelRequest = () => controller.abort()

export const upload = (params, { client = "delivery-note" }) => {
  controller = isBrowser() ? new AbortController() : null
  const signal = controller.signal

  return post(`/file-processing/upload?client=${client}`, params, {
    headers: { "Content-Type": "multipart/form-data" },
    signal,
  })
}
