import React from "react"

const SelectAllCell = ({
  selection,
  setSelection,
  onSelectAll,
}) => {
  const multiSelectActive = selection?.length > 0
  const handleSelect = (e) => {
    if (!e.target.checked) {
      //Deselect all
      setSelection([])
    } else {
      //Select specified
      onSelectAll()
      //setSelection(products.content.filter((p) => p.preferred))
    }
  }
  return (
    <div className="mr-4">
      <input
        type="checkbox"
        value={""}
        onChange={handleSelect}
        name="multiSelect"
        className="form-checkbox text-primaryPink"
        checked={multiSelectActive}
      />
    </div>
  )
}

export default SelectAllCell
