import { get, put, remove, post } from "./api"
import { SubCategory } from "./types"

interface GetCategoriesParams {
  active?: boolean
  page?: number
  size?: number
  sort?: string
}

export interface CategoryDto {
  name: string
  desc: string | null
  active: boolean
  subCategories: SubCategory[]
}

export const getCategories = (params?: GetCategoriesParams) => {
  let defaultParams = { sort: "name,asc" }
  if (params) {
    defaultParams = {
      ...defaultParams,
      ...params,
    }
  }
  return get("/categories", defaultParams)
}

export const createCategory = (params: CategoryDto) => {
  return post("/categories", params)
}

export const updateCategory = (categoryId: string, params: CategoryDto) => {
  return put(`/categories/${categoryId}`, params)
}

export const deleteCategory = (categoryId: string) => {
  return remove(`/categories/${categoryId}`)
}
