import React, { useState, useRef } from "react"
import { Formik, Form, FieldArrayRenderProps, FieldArray } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons"

import BarcodeInput from "components/forms/BarcodeInput/BarcodeInput"
import ConfirmModal from "../ConfirmModal/ConfirmModal"

import { normalizeNullValues } from "services/helpers"
import FormikEffect from "components/forms/FormikEffect"

const Schema = Yup.object().shape({
  barcode: Yup.string().required("Barcode is required"),
})

const EditBarcodeModal = ({ data, onConfirm, ...props }) => {
  const [formData, setFormData] = useState({})
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const { barcode, barcodes } = data

  const barcodeArrayHelpersRef = useRef<FieldArrayRenderProps | null>(null)

  const initialValues = {
    barcode,
    barcodes: barcodes ? barcodes : [],
  }

  const handleDataChange = (data) => {
    setFormData(data.nextValues)
  }

  return (
    <ConfirmModal
      onConfirm={() => {
        onConfirm(formData)
      }}
      disableConfirm={!submitEnabled}
      {...props}
    >
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={normalizeNullValues(initialValues)}
        validationSchema={Schema}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <FormikEffect
                onChange={handleDataChange}
                onValidationChange={(isValid) => setSubmitEnabled(isValid)}
              />
              <Form className="flex flex-col flex-shrink-0 w-full mb-4 border-r border-b">
                <div
                  className="flex items-center border-r"
                  style={{ height: "44px" }}
                >
                  <div className="form-group w-full">
                    <BarcodeInput
                      required={true}
                      name="barcode"
                      label={"Barcode *"}
                      onBarcodeUpdate={(barcode) => {
                        setFieldValue("barcode", barcode)
                      }}
                      type="text"
                      placeholder={"Barcode *"}
                      className="form-control"
                      enableGenerateBarcode={false}
                    />
                  </div>
                  {values.barcodes.length === 0 && (
                    <div className="flex items-center h-full border-t">
                      <button
                        type="button"
                        onClick={() =>
                          barcodeArrayHelpersRef?.current?.push("")
                        }
                        className="btn bg-primaryBlueVeryLight px-2 rounded text-gray-700 font-sansBold mr-2 h-6 w-6 flex-shrink-0"
                      >
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                      </button>
                    </div>
                  )}
                </div>
                <FieldArray
                  name="barcodes"
                  render={(ArrayHelpers) => {
                    const { push, remove } = ArrayHelpers

                    const handleAddBarcode = (barcode: string) => {
                      push(barcode)
                    }
                    const handleRemoveBarcode = (index: number) => {
                      remove(index)
                    }

                    barcodeArrayHelpersRef.current = ArrayHelpers

                    const barcodes = values.barcodes || []

                    return barcodes.map((barcode: string, index: number) => {
                      const isLastItem = index === barcodes.length - 1
                      const barcodeNumber = index + 2
                      return (
                        <div
                          className="flex items-center border-r "
                          key={`barcode-${index}`}
                          style={{ height: "44px" }}
                        >
                          <div className="form-group w-full">
                            <BarcodeInput
                              required={true}
                              name={`barcodes.${index}`}
                              label={`Barcode ${barcodeNumber}`}
                              placeholder={`Barcode ${barcodeNumber}`}
                              onStatusChange={({ generate }) => {
                                setFieldValue("generateBarcode", generate)
                              }}
                              onBarcodeUpdate={(barcode) => {
                                setFieldValue(`barcodes.${index}`, barcode)
                              }}
                              type="text"
                              className="form-control"
                              enableGenerateBarcode={false}
                            />
                          </div>
                          <div className="flex items-center border-t h-full">
                            {isLastItem && (
                              <button
                                type="button"
                                onClick={() => handleAddBarcode("")}
                                className="btn bg-primaryBlueVeryLight px-2 rounded text-gray-700 font-sansBold mr-2 h-6 w-6 flex-shrink-0"
                              >
                                <FontAwesomeIcon icon={faPlus} size="sm" />
                              </button>
                            )}

                            <button
                              type="button"
                              onClick={() => handleRemoveBarcode(index)}
                              className="btn bg-primaryPinkLighter px-2 rounded text-gray-700 font-sansBold mr-2 h-6 w-6 flex-shrink-0"
                            >
                              <FontAwesomeIcon
                                icon={faMinus}
                                size="sm"
                                className="text-white"
                              />
                            </button>
                          </div>
                        </div>
                      )
                    })
                  }}
                />
              </Form>
            </>
          )
        }}
      </Formik>
    </ConfirmModal>
  )
}

export default EditBarcodeModal
