import React, { useRef, useState } from "react"
import { faChevronDown, faFilter } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useClickAway } from "react-use"

interface FiltersButtonProps {
  children: React.ReactNode
}

const FiltersButton = ({ children }: FiltersButtonProps) => {
  const [showFilters, setShowFilters] = useState(false)
  const dropdownRef = useRef(null)

  useClickAway(dropdownRef, () => {
    setShowFilters(false)
  })

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className={
          "form-button flex items-center border rounded px-5 py-3 border-gray-300 text-primaryGray space-x-2 " +
          (showFilters ? "bg-gray-300" : "bg-paleBlue")
        }
        onClick={() => setShowFilters(!showFilters)}
      >
        <FontAwesomeIcon icon={faFilter} />
        <span>Filters</span>
        <FontAwesomeIcon
          icon={faChevronDown}
          transform={{ rotate: showFilters ? 180 : 0 }}
        />
      </button>

      <div
        className={
          "absolute mt-1 border border-gray-300 bg-white p-5 rounded items-center z-50 gap-x-4 space-y-2 md:space-y-0 w-max " +
          (showFilters ? "md:flex" : "hidden")
        }
      >
        {children}
      </div>
    </div>
  )
}

export default FiltersButton
