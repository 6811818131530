import React, { useEffect, useState } from "react"
import { getSquareConnection } from "services/square"

interface SquareDetailsProps {
  id: string
  onClose?(): void
}
const SquareDetails = ({ id }: SquareDetailsProps) => {
  const [details, setDetails] = useState<any>(null)

  useEffect(() => {
    const abortController = new AbortController()
    const getDetails = async () => {
      const res = await getSquareConnection(id, {
        signal: abortController.signal,
      })
      setDetails(res)
    }

    getDetails()
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="w-full flex-grow flex flex-col overflow-auto">
      {details && (
        <div className="flex-grow flex flex-col px-4 md:px-6 py-3">
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Integration ID
          </span>
          <span className="text-gray-800 flex items-center pb-2 border-b">
            <img
              src={"/images/integrations/square-icon.svg"}
              className="w-8 h-auto mr-2"
              alt="Square details"
            />
            <span>{details?.id}</span>
          </span>
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Integration Name
          </span>
          <span className="text-gray-800 pb-2 border-b">{details?.name}</span>
          <span className="text-gray-600 font-sansSemiBold font-semibold text-xs uppercase mb-1 w-full pt-3">
            Square venue/location:
          </span>
          <span className="text-gray-800 pb-2 border-b">
            {details?.location?.name}
          </span>
        </div>
      )}
    </div>
  )
}

export default SquareDetails
