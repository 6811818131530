import React, { ReactElement, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import classNames from "classnames/bind"
//@ts-ignore
import * as styles from "./ConfirmModal.module.css"

const cx = classNames.bind(styles)

Modal.setAppElement("body")

interface ConfirmModalProps {
  onRequestClose?: () => void
  showCancel?: boolean
  showReject?: boolean
  showContinue?: boolean
  type?: string
  title: string
  text?: string
  confirmButtonText?: string
  cancelButtonText?: string
  rejectButtonText?: string
  continueButtonText?: string
  onConfirm: () => void
  onReject?: () => void
  onCancel?: () => void
  onContinue?: () => void
  disableConfirm?: boolean
  children?: ReactElement
  renderButtons?: ReactElement
}

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = ({
  onRequestClose,
  showCancel = true,
  showReject = false,
  showContinue = false,
  type = "success",
  title,
  text,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  rejectButtonText = "Reject",
  continueButtonText = "Continue",
  onConfirm,
  onReject,
  onCancel,
  onContinue,
  disableConfirm = false,
  children,
}) => {
  const confirm = useCallback(() => {
    onRequestClose && onRequestClose()
    if (onConfirm) {
      onConfirm()
    }
  }, [onRequestClose, onConfirm])

  const reject = useCallback(() => {
    onRequestClose && onRequestClose()
    if (onReject) {
      onReject()
    }
  }, [onRequestClose, onReject])

  const cancel = useCallback(() => {
    onRequestClose && onRequestClose()
    if (onCancel) {
      onCancel()
    }
  }, [onRequestClose, onCancel])

  const handleContinue = useCallback(() => {
    onRequestClose && onRequestClose()
    if (onContinue) {
      onContinue()
    }
  }, [onRequestClose, onContinue])

  return (
    <Modal isOpen onRequestClose={onRequestClose} portalClassName="confirm">
      <button
        type="button"
        onClick={onRequestClose as React.MouseEventHandler}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.welcomeContent}>
          <h1 className="text-2xl mb-4 mt-3 text-primaryBlue">
            {title || "Are you sure?"}
          </h1>
          {text && <p className="text-lg text-gray-700">{text}</p>}
        </div>
        {children}
        <div className={styles.buttons}>
          {showCancel && (
            <button
              type="button"
              onClick={cancel}
              className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
            >
              {cancelButtonText}
            </button>
          )}
          {showReject && (
            <button
              type="button"
              onClick={reject}
              className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
            >
              {rejectButtonText}
            </button>
          )}
          <button
            type="button"
            onClick={confirm}
            className={cx("button button--autoWidth", {
              "button--primaryGreen": type === "success",
              "button--primaryPink": type === "danger",
            })}
            disabled={disableConfirm}
          >
            {confirmButtonText}
          </button>
          {showContinue && (
            <button
              type="button"
              onClick={handleContinue}
              className="button button--autoWidth button--primaryGreen"
            >
              {continueButtonText}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
