// extracted by mini-css-extract-plugin
export var deliveryDate = "StockTransferHeader-module--deliveryDate---tkSb";
export var disabled = "StockTransferHeader-module--disabled--vFxCl";
export var editButton = "StockTransferHeader-module--editButton--OZLgq";
export var header = "StockTransferHeader-module--header--jpI61";
export var headerMain = "StockTransferHeader-module--headerMain--82utu";
export var iconWrapper = "StockTransferHeader-module--iconWrapper--CwKpP";
export var ref = "StockTransferHeader-module--ref--TTzk4";
export var statusBulb = "StockTransferHeader-module--statusBulb--xq650";
export var supplierName = "StockTransferHeader-module--supplierName--wttUg";
export var thumbnail = "StockTransferHeader-module--thumbnail--hvi-1";
export var thumbnailBadge = "StockTransferHeader-module--thumbnailBadge--Xd-WX";
export var thumbnailIncoming = "StockTransferHeader-module--thumbnailIncoming--0WL-7";
export var thumbnailIndicator = "StockTransferHeader-module--thumbnailIndicator--JWY7w";
export var thumbnailWrapper = "StockTransferHeader-module--thumbnailWrapper--9ZTYy";