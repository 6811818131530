import React from "react"

interface FormGroupProps {
  title: string
  labelClass?: string
  children?: any
}

const FormGroup = ({
  title,
  labelClass = "",
  children = null,
}: FormGroupProps) => {
  return (
    <>
      <div className="mb-4 form-group form-group--flex-nowrap w-full rounded">
        <div
          className={
            "form-group-label pt-1 input-container--1-4 h-auto font-semibold " +
            labelClass
          }
          style={{ height: "auto", marginBottom: "-1px", paddingTop: "10px" }}
        >
          {title}
        </div>
        <div className="form-group form-group--flex input-container--3-4">
          {children}
        </div>
      </div>
    </>
  )
}

export default FormGroup
