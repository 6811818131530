import React from "react"
import * as styles from "./SidePromote.module.css"
import Logo from "../../../inline-svg/growyze-logo-primary-blue-white.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-regular-svg-icons"

const SidePromote = () => (
  <div className={styles.container}>
    <Logo className="mb-6" />

    <h2 className="text-white text-3xl mb-3 block mx-auto max-w-lg">
      Make stock control a breeze
    </h2>
    <p className="text-xl text-white text-opacity-75 mb-8 block mx-auto max-w-lg">
      Modern. Simple. Intuitive.
    </p>
    <div className="text-center text-white text-opacity-80 overflow-hidden">
      <div className={styles.whySignup}>
        <h3 className="text-white text-left mb-4 text-xl mr-6 w-full">
          Elevate your inventory management with growyze
        </h3>
        <ul className="text-lg text-left w-full mb-3">
          <li>
            <FontAwesomeIcon
              icon={faCheck}
              className="text-primaryGreen mr-2"
            />
            Unlock our full suite of features with plans offering great value to
            suit your needs
          </li>
          <li>
            <FontAwesomeIcon
              icon={faCheck}
              className="text-primaryGreen mr-2"
            />
            Say goodbye to Excel and manual entry - embrace smart automation
          </li>
          <li>
            <FontAwesomeIcon
              icon={faCheck}
              className="text-primaryGreen mr-2"
            />
            Enjoy an intuitive platform that simplifies your stock control
            process
          </li>
        </ul>
        <div className={styles.testimonial}>
          <div className={styles.imageContainer}>
            <img
              className="w-96 rounded-lg"
              src="/images/chris.jpg"
              alt="Chris, MD @ Whistle Punks"
            />
          </div>
          <p>
            <q className="italic">
              “The platform is very user-friendly and so easy to navigate, it
              removes all the noise you find with other providers and focuses on
              what is important. <br />
              <br />
              Being able to move from pen, paper and excel documents to a
              seamless ordering and reconciliation system has been hugely
              beneficial to both venue and accounting teams improving accuracy
              whilst also reducing the amount of time spent on the tasks.”
            </q>
            <span>
              Chris, MD @ Whistle Punks (UK) [multi-site recreation centre]
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default SidePromote
