import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Link } from "@reach/router"
import * as styles from "./CookBook.module.css"
import Header from "components/dashboard/Header/Header"
import { navigate } from "gatsby"

const CookBook = ({ location, children }) => {
  const enabledTabs = [
    { route: "recipes", label: "Recipes" },
    { route: "dishes", label: "Dishes" },
  ]
  useEffect(() => {
    const { pathname } = location
    if (pathname === "/dashboard/products/cookbook") {
      navigate(`recipes`, { replace: true })
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Recipes</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Recipes and Dishes" />
        <ul className={styles.tabs}>
          {enabledTabs.map((tab) => {
            return (
              <li key={tab.route}>
                <Link
                  to={tab.route}
                  getProps={({ isCurrent }) => {
                    return {
                      className: `${styles.tab} ${
                        isCurrent ? styles.active : ""
                      }`,
                    }
                  }}
                >
                  {tab.label}
                </Link>
              </li>
            )
          })}
        </ul>

        {children}
      </div>
    </>
  )
}

CookBook.propTypes = {
  children: PropTypes.any,
}

export default CookBook
