/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { navigate, Link } from "@reach/router"
import {
  faPlusCircle,
  faCloudUpload,
  faSearch,
} from "@fortawesome/pro-light-svg-icons"
import PropTypes from "prop-types"
import { showError, showSuccess } from "services/toast"
import Header from "components/dashboard/Header/Header"
import SearchInput from "components/forms/SearchInput"
import { AsideContext } from "context/aside/AsideContext"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import EditSupplier from "components/suppliers/EditSupplier/EditSupplier"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { getSuppliers, removeSupplier } from "services/suppliers/suppliers"
import { ModalContext } from "context/modal/ModalContext"
import Helmet from "react-helmet"
import * as styles from "./Suppliers.module.css"
import usePermissions from "hooks/usePermissions"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import { isDevelopment } from "services/constants"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { Permission } from "services/types"
import { faTruck } from "@fortawesome/pro-duotone-svg-icons"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import { getSuppliersDummy } from "services/dummy"
import usePagination from "hooks/usePagination"

const Suppliers = () => {
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("Suppliers") as Permission
  const { organizationPermissions } = useContext(GlobalStateContext)
  const [supplierData, setSupplierData] = useState({
    content: [],
  })
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const pagination = usePagination(true)
  const previousPage = usePrevious(pagination.page)
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(false)
  const aside = useContext(AsideContext)

  const handleActionClick = (action, supplier) => {
    switch (action.type) {
      case "supplier.edit":
        startEditSupplier(supplier)
        break
      case "supplier.delete":
        onDelete(supplier)
        break
      case "supplier.read":
        navigate(`suppliers/${supplier.id}`)
        break
    }
  }

  const ActionCell = ({ rowData, rowIndex }) => {
    const shouldDropUp = rowIndex > 2
    const options = [
      {
        key: "supplier.read",
        title: "View supplier",
        type: "default",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "supplier.edit",
        title: "Edit",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "supplier.delete",
        title: "Delete",
        type: "danger",
        disabled:
          !permissionObj?.permissions.delete ||
          !organizationPermissions?.suppliers?.remove,
      },
    ]

    return (
      <ActionsButton
        options={options}
        onActionClick={(action) => handleActionClick(action, rowData)}
        dropDirection={shouldDropUp ? "top" : "auto"}
      />
    )
  }

  ActionCell.propTypes = {
    rowData: PropTypes.object,
    selectedSupplier: PropTypes.array,
  }

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 250,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Link
          to={rowData.id}
          className="font-sans text-sm font-semibold text-primaryBlue"
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      key: "accountNumber",
      title: "Acc num",
      dataKey: "accountNumber",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
    },
    {
      key: "contactName",
      title: "Contact details",
      width: 150,
      dataKey: "contactName",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col text-sm">
          <span className="block w-full">{rowData.contactName}</span>
        </div>
      ),
    },
    {
      key: "email",
      width: 200,
      title: "Email",
      dataKey: "email",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <a
          className="block text-sm text-primaryBlue font-normal w-full break-all"
          href={`mailto:${rowData.email}`}
        >
          {rowData.email}
        </a>
      ),
    },
    {
      key: "telephone",
      width: 60,
      title: "Phone number",
      dataKey: "telephone",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <a
          className="block text-sm text-primaryBlue font-normal w-full"
          href={`tel:${rowData.telephone}`}
        >
          {rowData.telephone}
        </a>
      ),
    },
    {
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 60,
      className: "justify-end actions",
      headerClassName: "justify-end actions",
      cellRenderer: <ActionCell selectedSupplier={supplierData.content} />,
      // style: { overflow: "visible !important" },
    },
  ]

  const startEditSupplier = (supplier) => {
    aside.showAside(EditSupplier, {
      headerText: supplier.id ? "Edit supplier" : "Create new supplier",
      initialValues: supplier,
      onClose: onAsideClose,
    })
  }

  const deleteSupplier = async (supplier) => {
    const deleted = await removeSupplier(supplier.id)

    if (deleted.message) {
      showError(deleted.message)
    } else {
      showSuccess("Supplier deleted!")
      getData()
    }
  }

  const onDelete = (supplier) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${supplier.name || "Supplier"}`,
      text: "Are you sure you want to delete this supplier?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteSupplier(supplier),
    })
  }

  const getData = async () => {
    setLoading(true)

    const params: {
      page: number
      size: number
      sort: string
      partialName?: string
    } = {
      page: pagination.page,
      size: pagination.size,
      sort: "name,asc",
    }

    if (q) {
      params.partialName = q

      if (previousQ !== q) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getSuppliers(params))

    if (result && !result.error) {
      setSupplierData({ ...supplierData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const onAsideClose = (result) => {
    if (
      result === "supplier-created" ||
      result === "suppliers-imported" ||
      result === "supplier-updated"
    ) {
      getData()
    }
  }

  const handleAddSupplierButtonClick = ({ type }) => {
    switch (type) {
      case "create":
        aside.showAside(EditSupplier, {
          onClose: onAsideClose,
          headerText: "Create supplier",
        })
        break
      case "import":
        navigate("/dashboard/purchases/suppliers/import")
        // aside.showAside(Import, { onClose: onAsideClose, type: "suppliers" })
        break
      case "browse":
        navigate("/dashboard/purchases/suppliers/marketplace")
        break
      default:
        aside.showAside(EditSupplier, {
          headerText: "Add supplier",
        })
    }
  }

  const dropdownOptions = [
    {
      key: "create",
      title: "Create new",
      icon: faPlusCircle,
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "import",
      title: "Bulk import",
      icon: faCloudUpload,
      disabled: !permissionObj?.permissions.modify,
    },
    ...(isDevelopment
      ? [
          {
            key: "browse",
            title: "Browse",
            icon: faSearch,
            disabled: !permissionObj?.permissions.modify,
          },
        ]
      : []),
  ]

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  return (
    <>
      <Helmet>
        <title>Suppliers</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Suppliers" />

        <div className={styles.subHeader}>
          <div className="my-2 flex-grow mr-4 max-w-md">
            <SearchInput
              label="Search by name"
              placeholder="Search by name"
              className="form-control rounded"
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
          </div>
          <DropdownButton
            label="Add supplier"
            options={dropdownOptions}
            onActionClick={handleAddSupplierButtonClick}
          />
        </div>

        <div className={styles.content}>
          <ExtBaseTable
            loading={loading}
            pagination={pagination}
            data={supplierData.content}
            columns={columns}
            ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
            rowHeight={50}
            title="Suppliers"
            id="suppliers"
            empty={{
              icon: faTruck,
              color: "blue",
              getDummy: () => getSuppliersDummy,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Suppliers
