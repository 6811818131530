import React from "react"
import { roundNumber } from "services/helpers"

interface PriceCellProps {
  rowData: {
    productCase: {
      price: number
    }
    price: number
    unit: string
    _selectedCase: "single" | "multiple"
  }
}

const PriceCell = ({ rowData }: PriceCellProps) => {
  const hasCase = !!rowData.productCase
  const _selectedCase = rowData._selectedCase
  const _selectedCaseIsMultiple =
    hasCase && _selectedCase && _selectedCase !== "single"

  const rawPrice =
    hasCase && _selectedCaseIsMultiple
      ? rowData.productCase.price
      : rowData.price
  const price = roundNumber(rawPrice)

  return (
    <>
      {price}
      {`${
        _selectedCaseIsMultiple
          ? " / pack"
          : rowData.unit
          ? ` / ${rowData.unit.toLowerCase()}`
          : ""
      }`}
    </>
  )
}

export default React.memo(PriceCell)
