interface CustomChartLabel {
  borderColor: string // Color to display status line/bar with
  key: string // Scoped to api's response key
  label: string // Readable to user
}

export const defaultColors = [
  "rgba(252, 55, 98, 1)",
  "rgba(62, 139, 194, 1)",
  "rgba(196, 141, 212, 1)",
  "rgba(115, 177, 222, 1)",
  "rgba(138, 85, 154, 1)",
  "rgba(86, 110, 138, 1)",
  "rgba(34, 188, 179, 1)",
  "rgba(27, 55, 125, 1)",
  "rgba(255, 111, 142, 1)",
  "rgba(29, 159, 152, 1)",
]

export const defaultStatusColors: CustomChartLabel[] = [
  { borderColor: "#F3D019", key: "draftCount", label: "Draft" },
  { borderColor: "#F2691C", key: "queriedCount", label: "Queried" },
  { borderColor: "#35CC5F", key: "approvedCount", label: "Approved" },
  { borderColor: "#1C943D", key: "completedCount", label: "Completed" },
]

export const orderStatusColors: CustomChartLabel[] = [
  { borderColor: "#272764", key: "placedCount", label: "Placed" },
  { borderColor: "#1C943D", key: "completedCount", label: "Completed" },
]

// Hide dots for points with value 0
export const calculatePointRadius = (context) => (context.raw > 0 ? 3 : 0)

export const resetSelected = (setDatasets, setselected) => {
  // Bring back default properties for dataset items
  setDatasets((prev) => {
    return [...prev].map((a, index) => {
      return {
        ...a,
        borderColor: a._borderColorOriginal,
        fill: false,
        order: index + 1,
        pointRadius: calculatePointRadius,
      }
    })
  })
  setselected(null)
}

export const createGradient = (context) => {
  if (!context.chart.chartArea || !context.dataset.borderColor) return // return if context for chart is not yet created
  const borderColor = context.dataset.borderColor
  const {
    ctx,
    chartArea: { top, bottom },
  } = context.chart
  const gradientBg = ctx.createLinearGradient(0, top, 0, bottom)
  const step1 = borderColor.replace(/[\d\\.]+\)$/g, "0.5)")
  gradientBg.addColorStop(0, step1)
  gradientBg.addColorStop(1, "rgba(255, 255, 255, 0)")
  return gradientBg
}

export const defaultsDatasetMapper = (
  e,
  index,
  _arr,
  enableGradient = false
) => ({
  ...e, // Current element
  _borderColorOriginal: e.borderColor, //Saving original color for resetting
  fill: false, // Background color for each line, Togle on active/hover
  order: index + 1, // index 0 is reserved for highlighting selected element
  backgroundColor: enableGradient ? createGradient : e.borderColor, // Gradient function, calculates gradient based on borderColor
  hidden: false, // Conditionally hiding selected border
  pointBackgroundColor: e.borderColor, //Ensuring that point rendered on map will not be transparent
})

export const getOrganizationColor = (colors, organizations, orgId) =>
  colors[organizations.findIndex((a) => a.value === orgId) % colors.length]
