// extracted by mini-css-extract-plugin
export var cellWrapper = "ImportSuppliers-module--cellWrapper--s7ZwM";
export var cellWrapperError = "ImportSuppliers-module--cellWrapperError--aZDEN";
export var container = "ImportSuppliers-module--container--xkYeV";
export var content = "ImportSuppliers-module--content--tpHAq";
export var header = "ImportSuppliers-module--header--Y9PsG";
export var headerColumns = "ImportSuppliers-module--headerColumns--o18Tf";
export var headerContent = "ImportSuppliers-module--headerContent--ysaW1";
export var headerRight = "ImportSuppliers-module--headerRight--X64oK";
export var headerTopColumn = "ImportSuppliers-module--headerTopColumn--HCTI8";
export var supplierProducts = "ImportSuppliers-module--supplierProducts--+tHad";
export var title = "ImportSuppliers-module--title--l5xaQ";