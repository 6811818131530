import { get, post, remove } from "./api"

export const createNewbridgeIntegration = (params) => {
  return post("/newbridge-pos-integrations", params)
}

export const getNewbridgeIntegrations = () => {
  return get("/newbridge-pos-integrations")
}

export const getNewbridgeIntegration = (
  newbridgeIntegrationId: string,
  config?: {}
) => {
  return get(`/newbridge-pos-integrations/${newbridgeIntegrationId}`, config)
}

export const deleteNewbridgeIntegration = (newbridgeIntegrationId: string) => {
  return remove(`/newbridge-pos-integrations/${newbridgeIntegrationId}`)
}
