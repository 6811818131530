import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import { createNewbridgeIntegration } from "services/newbridge"
import TextInput from "components/forms/TextInput"

Modal.setAppElement("body")

const Schema = Yup.object().shape({
  key: Yup.string().required(),
  secret: Yup.string().required(),
  site: Yup.object({ number: Yup.number().required() }),
})

interface NewbridgeSettingsModalProps {
  onRequestClose(): void
  onConnectionCreated(): void
  [x: string]: any
}

const SettingsFormInput = ({ field, type = "text" }) => {
  const label = field.split(".").join(" ")
  return (
    <>
      <label
        htmlFor={field}
        className="block font-semibold font-sansSemiBold text-primaryBlue my-3 w-full capitalize"
      >
        {label}
      </label>
      <div className="input-container">
        <TextInput
          type={type}
          id={field}
          name={field}
          className={`block border-gray-400 rounded form-control`}
          placeholder={`Add your Newbridge account ${label}`}
        />
      </div>
    </>
  )
}

const NewbridgeSettingsModal: React.FunctionComponent<NewbridgeSettingsModalProps> =
  ({
    onRequestClose,
    onConnectionCreated,
    ...otherProps
  }: NewbridgeSettingsModalProps) => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState<boolean>(false)
    const [connected, setConnected] = useState<boolean>(false)

    const createIntegration = async (formData) => {
      setLoading(true)
      const res = await createNewbridgeIntegration(formData)
      if (res.id) {
        setConnected(true)
        onConnectionCreated && onConnectionCreated()
      } else {
        setError(res?.message || "Something went wrong...")
      }
      setLoading(false)
    }

    return (
      <Modal
        isOpen
        shouldCloseOnOverlayClick={false}
        onRequestClose={onRequestClose}
        portalClassName="confirm"
        {...otherProps}
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="text-gray-700 absolute right-0 py-4 px-6"
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </button>
        <div className="flex flex-col items-center">
          <div className="overflow-hidden flex-grow flex flex-col w-full">
            <header className="flex items-center px-4 py-3 text-center border-b flex-shrink-0">
              <img
                className="w-14 mr-4"
                src="/images/integrations/newbridge-icon.png"
                alt="Newbridge logo"
              />
              <h2 className="text-primaryBlue text-xl mb-2">
                Connect to Newbridge
              </h2>
            </header>
            <div className="pt-3 pb-4 relative" style={{ minHeight: "7.5rem" }}>
              {connected ? (
                <>
                  <h3 className="text-primaryBlue text-xl text-center my-3">
                    Integration successful!
                  </h3>
                  <p className="text-center text-gray-700">
                    Your Newbridge account has been connected to growyze.
                  </p>
                </>
              ) : (
                <Formik
                  initialValues={{
                    key: "",
                    secret: "",
                    site: { number: "" },
                  }}
                  validationSchema={Schema}
                  onSubmit={createIntegration}
                  validateOnMount={true}
                >
                  {({ isValid }) => (
                    <Form className="px-6 flex flex-col">
                      <SettingsFormInput field={"key"} />
                      <SettingsFormInput field={"secret"} />
                      <SettingsFormInput field={"site.number"} type="number" />

                      <button
                        className="button button--autoWidth text-left mt-8 button--primaryGreen"
                        disabled={!isValid}
                      >
                        {loading ? "Connecting" : "Connect"}
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          {error && <p className="text-primaryPink mb-8">{error}</p>}
        </div>
      </Modal>
    )
  }

export default NewbridgeSettingsModal
