// extracted by mini-css-extract-plugin
export var actionBtn = "EditRecipe-module--actionBtn--G4E7E";
export var cellWrapperError = "EditRecipe-module--cellWrapperError--t-9RU";
export var container = "EditRecipe-module--container--24JXD";
export var content = "EditRecipe-module--content--FbpBR";
export var featuredImage = "EditRecipe-module--featuredImage--mo5J3";
export var fixedCellValue = "EditRecipe-module--fixedCellValue--YF8AX";
export var form = "EditRecipe-module--form--mIHjT";
export var formSearchRow = "EditRecipe-module--formSearchRow--WzNPy";
export var formSearchRowHead = "EditRecipe-module--formSearchRowHead--ziEDd";
export var header = "EditRecipe-module--header--4oDFk";
export var ingredientDetails = "EditRecipe-module--ingredientDetails--2vbgg";
export var ingredientWrapper = "EditRecipe-module--ingredientWrapper--qWTWF";
export var ingredientsCount = "EditRecipe-module--ingredientsCount--pcwcD";
export var ingredientsWrapper = "EditRecipe-module--ingredientsWrapper--M91w4";
export var inputPlaceHolder = "EditRecipe-module--inputPlaceHolder--c-6Du";
export var recipeDescription = "EditRecipe-module--recipeDescription--DlDFZ";
export var rowCell = "EditRecipe-module--rowCell--1RyJr";
export var sectionHeading = "EditRecipe-module--sectionHeading--71q8J";
export var subValueContainer = "EditRecipe-module--subValueContainer--U1535";
export var tabs = "EditRecipe-module--tabs--QEF1j";