import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { createSupportTicket } from "services/notification"
import { normalizeNullValues } from "services/helpers"
import Modal from "react-modal"
import TextInput from "../../forms/TextInput"
import FileInput from "../../forms/FileInput"
import TextArea from "../../forms/TextArea"
import PropTypes from "prop-types"
import FormData from "form-data"
import * as styles from "./SupportTicketModal.module.css"

const FILE_SIZE = 5242880 // MAX 5MB (binary bytes)

Modal.setAppElement("body")

const SupportTicketSchema = Yup.object().shape({
  subject: Yup.string().required("Subject is required"),
  file: Yup.mixed().test(
    "fileSize",
    "File size is too large, max 5MB is allowed",
    (value) => {
      return value ? value.size <= FILE_SIZE : true
    }
  ),
  message: Yup.string().required("Message is required"),
})

const SupportTicketModal = (props) => {
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const {
    onRequestClose,
    subject = "",
    message = "",
    title = 'Contact support', 
    intro = 'Are you stuck? Or do you have questions? Let us know and we will help you as soon as possible.',
    category = 'support', 
    hideFileUpload = false,
    ...otherProps
  } = props

  const initialValues = {
    file: null,
    subject: subject,
    message: message,
  }

  const onSubmit = async (params) => {
    let formData = new FormData()

    const { file, message, subject } = params

    formData.append("subject", subject)
    formData.append("message", message)
    formData.append("category", category)

    if (file) {
      formData.append("file", file)
    }

    try {
      const res = await createSupportTicket(formData)
      if (res) {
        setSubmitted(res)
      }
    } catch (e) {
      setError("Something went wrong. Please try again later.")
    }
  }

  return (
    <Modal
      isOpen
      portalClassName="contact"
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
        initialValues={normalizeNullValues(initialValues)}
        validationSchema={SupportTicketSchema}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form className={styles.form}>
            <div className={styles.welcomeContent}>
              <h1 className="text-3xl mb-4">{title}</h1>
              <p className="text-lg text-gray-700">
                {intro}
              </p>
            </div>

            {error && <div className={styles.error}>{error}</div>}

            {!isSubmitting && !submitted && (
              <>
                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      name="subject"
                      type="text"
                      className="form-control form-control--first"
                      placeholder="Subject"
                      label="Subject"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-container">
                    <TextArea
                      name="message"
                      className="form-control form-control--last"
                      placeholder="Message"
                      label="Message"
                    />
                  </div>
                </div>

                {!hideFileUpload && (<div className="form-group mt-4">
                  <FileInput
                    name="file"
                    id="file"
                    label="Attachment"
                    placeholder="Attachment (optional)"
                    isSingleFile
                  />
                </div>)}

                <button
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  className="button mt-5 mb-4"
                >
                  Send
                </button>
              </>
            )}

            {isSubmitting && !submitted && (
              <div className="text-center text-3xl text-primaryPink">
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              </div>
            )}

            {submitted && (
              <div className="text-center text-lg text-primaryGreen">
                <p>
                  Got it! Your support ticket is created:{" "}
                  <strong>{submitted.ticketID}</strong>. We&apos;ll respond
                  soon!
                </p>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

SupportTicketModal.propTypes = {
  onRequestClose: PropTypes.func,
  subject: PropTypes.string,
}

export default SupportTicketModal
