import FormFieldRepeater from "components/forms/FormFieldRepeater"
import FormGroup from "components/forms/FormGroup"
import React from "react"

type OrganizationNotificationsEmailsProps = {
  title: string
  name: string
  label?: string
  placeholder?: string
}

const OrganizationNotificationsEmails = (
  props: OrganizationNotificationsEmailsProps
) => {
  const { title, name, label = "Email", placeholder = "Email" } = props
  return (
    <FormGroup title={title}>
      <FormFieldRepeater
        name={name}
        label={label}
        placeholder={placeholder}
        required={false}
        showValidations={true}
      />
    </FormGroup>
  )
}

export default OrganizationNotificationsEmails
