import React, { useState, useEffect, useContext } from "react"
import { usePromise } from "react-use"
import {
  getGPReport,
  updateGPReport,
  updateProductOfGPReport,
  updateSubCategoryTotalsOfGPReport,
  updateCategoryTotalsOfGPReport,
  exportGPReport,
} from "services/gp-report"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartBar, faSearch } from "@fortawesome/pro-duotone-svg-icons"
import {
  faFileDownload,
  faSync,
  faEllipsisH,
  faPencil,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons"
import { roundNumber } from "services/helpers"
import { useMediaQuery } from "react-responsive"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import ReactTooltip from "react-tooltip"

import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import EditSalesModal from "components/gp-reports/EditSalesModal/EditSalesModal"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import SearchInput from "components/forms/SearchInput"
import ExpandableCategorisedProductTable from "components/common/ExpandableCategorisedProductTable/ExpandableCategorisedProductTable"
import GPReportModal from "../GPReportModal/GPReportModal"

import { ModalContext } from "context/modal/ModalContext"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

//@ts-ignore
import * as styles from "./GPReport.module.css"
import { GPReport, Permission } from "services/types"
import usePermissions from "hooks/usePermissions"
import { getExportFileName } from "services/export"
import ReportNameCell from "components/baseList/cells/ReportName"
import { isDevelopment } from "services/constants"

const cx = classNames.bind(styles)

const GPAnalysisReport = ({ gpReportId }) => {
  const [gpData, setGPData] = useState<GPReport | null>(null)
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [query, setQuery] = useState("")
  const [theoreticalStock, setTheoreticalStock] = useState<boolean>(false)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const modal = useContext(ModalContext)
  const { organization, organizationGroup } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("GP Analysis") as Permission
  const salesPermissionObj = usePermissions("Sales") as Permission

  const exportOptions = [
    ...(isTabletOrMobile
      ? [
          {
            title: "Refresh",
            key: "refresh",
            icon: faSync,
            disabled: !permissionObj?.permissions.modify,
          },
        ]
      : []),
    {
      title: "Export full report (xlsx)",
      key: "export.full.xlsx",
      disabled: !permissionObj?.permissions.read,
    },
    {
      title: "Export summary (xlsx)",
      key: "export.summary.xlsx",
      disabled: !permissionObj?.permissions.read,
    },
    {
      title: "Export summary (pdf)",
      key: "export.summary.pdf",
      disabled: !permissionObj?.permissions.read,
    },
  ]

  const Category = ({ rowData }) => {
    return (
      <div className="flex flex-col my-3 text-sm catCell">
        <span className="text-gray-700">{rowData.name}</span>
      </div>
    )
  }
  Category.propTypes = {
    rowData: PropTypes.shape({
      name: PropTypes.string,
    }),
  }

  const SalesValue = ({ rowData, keys }) => {
    const { value } = keys

    const isManuallyEdited =
      (value === "totalSoldValue" && rowData.isManuallyEditedSoldValue) ||
      (value === "totalSoldQty" && rowData.isManuallyEditedSoldQty)

    return (
      <div
        className={cx("flex justify-between my-3 mr-auto text-sm w-1/2", value)}
      >
        <span>
          {typeof rowData[value] === "number"
            ? roundNumber(rowData[value])
            : ""}
        </span>
        <button
          className="editSales"
          disabled={!salesPermissionObj?.permissions.modify}
          onClick={() => showEditSalesModal(rowData, value)}
        >
          <FontAwesomeIcon
            icon={faPencil}
            size="sm"
            className={cx("text-gray-600", {
              "text-secondaryOrange": isManuallyEdited,
            })}
          />
        </button>
      </div>
    )
  }
  SalesValue.propTypes = {
    rowData: PropTypes.object,
    keys: PropTypes.object,
  }

  const Number = ({ rowData, keys }) => {
    const { value, percent } = keys
    return (
      <div className="flex my-3 text-sm">
        <span>{roundNumber(rowData[value])}</span>
        {percent && (
          <span className="ml-1 text-primaryPinkLighter">
            {roundNumber(rowData[percent])}%
          </span>
        )}
      </div>
    )
  }
  Number.propTypes = {
    rowData: PropTypes.object,
    keys: PropTypes.object,
  }

  const Stat = ({ rowData, keys }) => {
    const { value, percent, indicatorClass } = keys

    const isManuallyEdited =
      value === "totalExpectedGP" && rowData.isManuallyEditedExpectedGP

    return (
      <div className={cx(styles.statContainer, value)}>
        <span className={cx("indicator", indicatorClass)}></span>

        <span>{roundNumber(rowData[value])}</span>

        <span className="text-gray-500 ml-1">
          {roundNumber(rowData[percent])}%
        </span>

        <button
          className="editSales"
          type="button"
          disabled={!salesPermissionObj?.permissions.modify}
          onClick={() => showEditSalesModal(rowData, value)}
        >
          <FontAwesomeIcon
            icon={faPencil}
            className={cx("text-gray-600", {
              "text-secondaryOrange": isManuallyEdited,
            })}
          />
        </button>
      </div>
    )
  }
  Stat.propTypes = {
    rowData: PropTypes.object,
    keys: PropTypes.object,
  }

  const Chart = ({ rowData }) => {
    return (
      <div className={styles.chartContainer}>
        <div
          className={cx("chartBar", "indicatorExpected")}
          style={{ height: `${rowData.totalExpectedGPPercent}%` }}
        ></div>
        <div
          className={cx("chartBar", "indicatorActual")}
          style={{ height: `${rowData.totalActualGPPercent}%` }}
        ></div>
        <div
          className={cx("chartBar", "indicatorVariance")}
          style={{ height: `${rowData.totalVarianceGPPercent}%` }}
        ></div>
      </div>
    )
  }
  Chart.propTypes = {
    rowData: PropTypes.object,
  }

  let columns = [
    {
      key: "name",
      title: "Category",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      headerClassName: "pl-6",
      cellRenderer: Category,
    },
    {
      key: "totalSoldValue",
      title: "Sales",
      width: 100,
      dataKey: "totalSoldValue",
      flexGrow: 1,
      flexShrink: 0,
      // align: "right",
      className: "text-sm p-0",
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return (
          <SalesValue rowData={rowData} keys={{ value: "totalSoldValue" }} />
        )
      },
    },
    {
      key: "totalSoldQty",
      title: "Sales Qty",
      width: 100,
      dataKey: "totalSoldQty",
      flexGrow: 1,
      flexShrink: 0,
      // align: "right",
      className: "text-sm p-0",
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return <SalesValue rowData={rowData} keys={{ value: "totalSoldQty" }} />
      },
    },
    {
      key: "totalExpectedGP",
      title: "Expected GP",
      width: 100,
      dataKey: "totalExpectedGP",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm p-0",
      headerRenderer: () => {
        return (
          <div className="flex flex-shrink-0 items-center mr-4">
            <span className={cx("indicator", "indicatorExpected")}></span>
            <p className="mr-2">Expected GP</p>
            <ReactTooltip
              id={`expectedGP`}
              type="light"
              place="top"
              effect="solid"
              border={true}
              borderColor="#e2e8f0"
            />
            <button
              data-tip={"Sales value - (Sales Qty*Cost price)"}
              data-for={`expectedGP`}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </button>
          </div>
        )
      },
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return (
          <Stat
            rowData={rowData}
            keys={{
              value: "totalExpectedGP",
              percent: "totalExpectedGPPercent",
              indicatorClass: "indicatorExpected",
            }}
          />
        )
      },
    },
    {
      key: "totalActualGP",
      title: "Actual GP",
      width: 100,
      dataKey: "totalActualGP",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm p-0",
      headerRenderer: () => {
        return (
          <div className="flex flex-shrink-0 items-center mr-4">
            <span className={cx("indicator", "indicatorActual")}></span>
            <p className="mr-2">Actual GP</p>
            <ReactTooltip
              id={`actualGP`}
              type="light"
              place="top"
              effect="solid"
              border={true}
              borderColor="#e2e8f0"
            />
            <button
              data-tip={`Sales - COGS\n\n(COGS = (Opening stock + Deliveries - Closing stock)*Cost price)`}
              data-for={`actualGP`}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </button>
          </div>
        )
      },
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return (
          <Stat
            rowData={rowData}
            keys={{
              value: "totalActualGP",
              percent: "totalActualGPPercent",
              indicatorClass: "indicatorActual",
            }}
          />
        )
      },
    },
    {
      key: "totalVarianceGP",
      title: "Variance GP",
      width: 100,
      dataKey: "totalVarianceGP",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm p-0",
      headerRenderer: () => {
        return (
          <div className="flex flex-shrink-0 items-center mr-4">
            <span className={cx("indicator", "indicatorVariance")}></span>
            Variance GP
          </div>
        )
      },
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return (
          <Stat
            rowData={rowData}
            keys={{
              value: "totalVarianceGP",
              percent: "totalVarianceGPPercent",
              indicatorClass: "indicatorVariance",
            }}
          />
        )
      },
    },
    {
      key: "stats",
      width: 100,
      dataKey: "stats",
      className: "text-sm p-0",
      flexGrow: 1,
      flexShrink: 0,
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return (
          <div
            className={cx(
              "flex items-center h-full flex-grow statsWrapper",
              styles.statsWrapper
            )}
          >
            <div className={styles.statsRow}>
              <Chart rowData={rowData} />
            </div>
          </div>
        )
      },
    },
    {
      key: "totalWasteValue",
      title: "Waste",
      width: 100,
      dataKey: "totalWasteValue",
      className: "text-sm p-0",
      flexGrow: 1,
      flexShrink: 0,
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        return (
          <Number
            rowData={rowData}
            keys={{ value: "totalWasteValue", percent: "totalWastePercent" }}
          />
        )
      },
    },
  ]

  if (!theoreticalStock) {
    columns = columns.filter((column) => column.key !== "totalActualGP")
  }

  const getData = async (regenerate = false) => {
    if (!regenerate) {
      setLoading(true)
    }

    if (regenerate && gpData) {
      const updateParams = {
        gpReportId: gpReportId,
        openingStockTakeReportId: gpData.openingStockTakeReportId,
        closingStockTakeReportId: gpData.closingStockTakeReportId,
        salesId: gpData.sales?.id,
        filterCategories: gpData.filterCategories,
      }
      await fromPromise(updateGPReport(updateParams))
    }

    const params = {
      partialProductName: query ? query : null,
      organizations: organizationGroup,
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getGPReport(gpReportId, params))

    if (result && !result.error) {
      setGPData(result)
      setLoading(false)
      if (regenerate) {
        setGenerating(false)
      }
    } else {
      setLoading(false)
      setGenerating(false)
    }
  }

  useEffect(() => {
    getData()
  }, [query])

  const regenerate = () => {
    setGenerating(true)
    getData(true)
  }

  const onEditGPReport = () => {
    modal.showModal(GPReportModal, {
      gpReportId,
      gpData,
      title: "Edit GP analysis report",
      loadingText: "Updating the GP anlysis Report",
      refreshData: getData,
    })
  }

  const handleActionClick = ({ type }) => {
    if (!gpData) return

    // fix-me
    //type shoudl be like: "export.summary.xlsx":
    const [, reportName, ext] = type.split(".")
    const fileName = getExportFileName("gp-analysis", reportName, ext, [
      new Date(gpData.periodFrom),
      new Date(gpData.periodTo),
    ])

    const params = {}
    if (gpData?.organizations[0] !== organization.id) {
      params["organizations"] = organizationGroup
    }

    switch (type) {
      case "refresh":
        regenerate()
        break
      case "export.full.xlsx":
        exportGPReport(gpReportId, fileName, ext, params, "/itemised")
        break
      case "export.summary.xlsx":
      case "export.summary.pdf":
        exportGPReport(gpReportId, fileName, ext, params, "/summarised")
        break
      default:
        break
    }
  }

  const showEditSalesModal = (data, value) => {
    modal.showModal(EditSalesModal, {
      data: data,
      type: value,
      onEdit: handleEditSales,
    })
  }

  const handleEditSales = async (data, dataLevel) => {
    let params
    switch (dataLevel) {
      case "product":
        params = {
          barcode: data.barcode,
          gpReportId,
          productPayload: {
            soldQty: data.totalSoldQty,
            soldValue: data.totalSoldValue,
          },
        }
        await updateProductOfGPReport(params)
        break
      case "subCategory":
        params = {
          category: data.category,
          gpReportId,
          subCategory: data.name,
          subCategoryPayload: {
            expectedGP: data.totalExpectedGP,
            expectedGPPercent: data.totalExpectedGPPercent,
            soldValue: data.totalSoldValue,
          },
        }
        await updateSubCategoryTotalsOfGPReport(params)
        break
      case "category":
        params = {
          category: data.name,
          categoryPayload: {
            expectedGP: data.totalExpectedGP,
            expectedGPPercent: data.totalExpectedGPPercent,
            soldValue: data.totalSoldValue,
          },
          gpReportId,
        }
        await updateCategoryTotalsOfGPReport(params)
        break
      default:
        params = null
    }

    getData()

    return
  }

  return (
    <>
      <Helmet>
        <title>GP Analysis Report</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="GP Analysis Report" />

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading report..
          </Loader>
          {gpData && (
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <div className={styles.headerTopColumn}>
                  <ReportNameCell
                    data={gpData}
                    disabled={!permissionObj?.permissions.modify}
                    iconStyles={{
                      mainIcon: {
                        backgroundColor: "#EAEAF7",
                        color: "#000055",
                      },
                      subIcon: {
                        color: "#000055",
                      },
                    }}
                    onEdit={onEditGPReport}
                    editEnabled={permissionObj?.permissions.modify}
                    mainIcon={faChartBar}
                    subIcon={faSearch}
                  />
                  {gpData.isManuallyEdited && (
                    <span className="text-xs text-orange-600 mt-2">
                      Note: some values have been manually entered
                    </span>
                  )}
                  <div className="flex flex-1 items-center">
                    <div className="my-2 w-full max-w-md">
                      <SearchInput
                        label="Search by products"
                        placeholder="Search by products"
                        className="form-control rounded"
                        onSearchChange={setQuery}
                      />
                    </div>
                    <div className={styles.headerRight}>
                      {isDevelopment && (
                        <div className="flex items-center gap-x-2 w-full ml-4">
                          <span className="whitespace-nowrap">
                            Theoretical stock
                          </span>
                          <input
                            type="checkbox"
                            checked={theoreticalStock}
                            className="form-checkbox text-primaryGreen mr-2 cursor-pointer"
                            onChange={() =>
                              setTheoreticalStock(!theoreticalStock)
                            }
                          />
                        </div>
                      )}

                      <button
                        type="button"
                        disabled={!permissionObj?.permissions.modify}
                        className="hidden md:flex items-center text-orange-600 rounded hover:text-orange-500 font-sansSemiBold font-semibold px-0 lg:px-6 py-2 flex-shrink-0"
                        onClick={regenerate}
                      >
                        <FontAwesomeIcon
                          icon={faSync}
                          spin={generating}
                          className="mr-2 block"
                        />
                        Refresh
                      </button>
                    </div>
                    <DropdownButton
                      containerClass="ml-3 md:ml-0"
                      onActionClick={handleActionClick}
                      label={<FontAwesomeIcon icon={faFileDownload} />}
                      mobileIcon={faEllipsisH}
                      options={exportOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {gpData && (
            <div className={styles.reportContent}>
              <ExpandableCategorisedProductTable
                data={gpData}
                columns={columns}
                productValueMap={(prod: any) => {
                  return {
                    name: `${prod.name ? prod.name : "-"} ${
                      prod.size
                        ? "(" + prod.size + " " + prod.measure + ")"
                        : ""
                    }`,
                    totalSoldValue: prod.soldValue,
                    totalSoldQty: prod.soldQty,
                    totalExpectedGP: prod.expectedGP,
                    totalActualGP: prod.actualGP,
                    totalVarianceGP: prod.varianceGP,
                    totalWasteValue: prod.wasteValue,
                    totalExpectedGPPercent: prod.expectedGPPercent,
                    totalActualGPPercent: prod.actualGPPercent,
                    totalVarianceGPPercent: prod.varianceGPPercent,
                    totalWastePercent: prod.wastePercent,
                  }
                }}
              />
            </div>
          )}
        </div>
        {gpData && !loading && (
          <div className={styles.bottomSummary}>
            <div className={styles.summaryItem}>
              <span className={styles.summaryItemLabel}>Total Sales</span>
              <span className={styles.summaryItemValue}>
                {roundNumber(gpData.totals.totalSoldValue)}
              </span>
            </div>

            <div className={styles.summaryItem}>
              <span className={styles.summaryItemLabel}>Total Expected GP</span>
              <span className={styles.summaryItemValue}>
                <span className={cx("indicator", "indicatorExpected")}></span>
                {roundNumber(gpData.totals.totalExpectedGP)}
                <span className="text-gray-500 ml-1">
                  {roundNumber(gpData.totals.totalExpectedGPPercent)}%
                </span>
              </span>
            </div>

            <div className={styles.summaryItem}>
              <span className={styles.summaryItemLabel}>Total Actual GP</span>
              <span className={styles.summaryItemValue}>
                <span className={cx("indicator", "indicatorActual")}></span>
                {roundNumber(gpData.totals.totalActualGP)}
                <span className="text-gray-500 ml-1">
                  {roundNumber(gpData.totals.totalActualGPPercent)}%
                </span>
              </span>
            </div>

            <div className={styles.summaryItem}>
              <span className={styles.summaryItemLabel}>Total Variance GP</span>
              <span className={styles.summaryItemValue}>
                <span className={cx("indicator", "indicatorVariance")}></span>
                {roundNumber(gpData.totals.totalVarianceGP)}
                <span className="text-gray-500 ml-1">
                  {roundNumber(gpData.totals.totalVarianceGPPercent)}%
                </span>
              </span>
            </div>

            <div className={styles.summaryItem}>
              <span className={styles.summaryItemLabel}>Total Waste</span>
              <span className={styles.summaryItemValue}>
                {roundNumber(gpData.totals.totalWasteValue)}
                <span className="text-primaryPinkLighter ml-1">
                  {roundNumber(gpData.totals.totalWastePercent)}%
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default GPAnalysisReport
