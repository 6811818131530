import React, { useState } from "react"
import PropTypes from "prop-types"
import { showError } from "services/toast"

const FileSelect = ({
  allowedTypes = [],
  maxFileSize = 5242880,
  onSelect,
  children,
  ...otherProps
}) => {
  const [, setFile] = useState(null)

  const handleFileUploadChange = (event) => {
    const file = event.currentTarget.files[0]

    if (!file) {
      return
    }
    // Max 5MB
    if (maxFileSize && file.size > maxFileSize) {
      return showError("File is too big. Maximum of 5 MB is allowed")
    }

    if (allowedTypes.length > 0 && allowedTypes.indexOf(file.type) < 0) {
      return showError("File type not allowed.")
    }

    setFile(file)
    onSelect(file)
  }

  const acceptArray =
    allowedTypes.length > 0 ? allowedTypes : "image/*,application/pdf"

  return (
    <>
      <input
        className="hidden"
        name="fileUpload"
        id="fileUpload"
        type="file"
        accept={acceptArray}
        onChange={handleFileUploadChange}
        disabled={otherProps.disabled}
      />
      <label htmlFor="fileUpload" {...otherProps}>
        {children}
      </label>
    </>
  )
}

FileSelect.propTypes = {
  allowedTypes: PropTypes.array,
  maxFileSize: PropTypes.number,
  onSelect: PropTypes.func,
  children: PropTypes.any,
}

export default FileSelect
