import { faCircle } from "@fortawesome/pro-duotone-svg-icons"
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { roundNumber } from "services/helpers"

interface ChartTogglePanelProps {
  data: any[]
  setDatasets: (prevProps) => void
  labels: any[]
  setLabels: (prevProps) => void
  renderType: "organizations" | "status"
  dataTypeName: string
}

const Difference = ({ percentage, differenceIsPositive }) => {
  return (
    <div className="text-base text-gray-600 flex flex-col">
      <span className="text-xs sm:text-base">Difference</span>
      <span
        className={`font-semibold flex items-center text-xs sm:text-sm ${
          percentage === 0 || percentage === undefined
            ? "text-gray-500"
            : percentage > 0
            ? "text-green-500"
            : "text-red-500"
        }`}
      >
        {typeof percentage === "number" && percentage !== 0 && (
          <FontAwesomeIcon
            className="mr-1"
            icon={faArrowUp}
            rotation={differenceIsPositive ? undefined : 180} // Not a fan of se  tting undefined but thats what interface expects
          />
        )}
        {percentage !== undefined
          ? Math.abs(roundNumber(percentage)) + "%"
          : "No data"}
      </span>
    </div>
  )
}

const ChartTogglePanel = (props: ChartTogglePanelProps) => {
  const {
    data,
    labels,
    renderType = "organizations",
    dataTypeName = "",
  } = props

  return (
    <ul className="h-full flex flex-col overflow-auto">
      {renderType === "organizations" &&
        data.map((a) => {
          const percentage = a?._backend?.percentage
          const differenceIsPositive = percentage > 0
          const inlineKey = a._backend?.orgId || a.key + a._borderColorOriginal

          return (
            <li key={"org-" + inlineKey} className="pr-4">
              <div className="flex border-t border-b h-20 items-center">
                <div className="flex items-center w-1/3 flex-shrink-0 overflow-hidden">
                  <OrganizationColoredLine
                    borderColor={a._borderColorOriginal}
                    label={a.label}
                  />
                </div>
                <div className="ml-2 flex space-x-2 justify-between w-full">
                  <ThisPeriod
                    dataTypeName={dataTypeName}
                    value={a._backend.totalCount}
                  />
                  <Difference
                    differenceIsPositive={differenceIsPositive}
                    percentage={percentage}
                  />
                </div>
              </div>
            </li>
          )
        })}

      {renderType === "status" &&
        labels.map((a, labelIndex) => {
          const inlineKey = a.value.label

          return (
            <li key={"status-" + inlineKey}>
              <div className="flex border-t border-b items-center justify-between py-4">
                <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full relative pr-2">
                  <div className="sm:w-1/3 flex-shrink-0 mb-3 sm:mb-0">
                    <p className="text-sm sm:text-base font-semibold">
                      {a.value.label}
                    </p>
                  </div>
                  <ul className="flex w-full justify-between sm:ml-0">
                    {props.data.map((d, i) => {
                      return (
                        <li
                          key={i}
                          className="border-l-4 flex flex-col pl-2 pr-1 flex-1 text-gray-600 text-sm flex-shrink-0"
                          style={{ borderColor: d.borderColor }}
                        >
                          <span className="">{d.label}</span>
                          <span className="font-semibold">
                            {d.data[labelIndex]}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </li>
          )
        })}
    </ul>
  )
}

function ThisPeriod({ dataTypeName = "", value }) {
  return (
    <div className="text-xs sm:text-base text-gray-600 flex flex-col">
      {dataTypeName ? (
        <span>
          <span className="capitalize">{dataTypeName}</span> this period
        </span>
      ) : (
        <span>This period</span>
      )}
      <span className="font-semibold">{value ?? "-"}</span>
    </div>
  )
}

function OrganizationColoredLine({ label, borderColor }) {
  return (
    <div className="sm:ml-4 flex flex-col w-full">
      <span className="text-xs sm:text-base font-semibold">{label}</span>
      <div className="relative flex items-center mt-2">
        <FontAwesomeIcon
          icon={faCircle}
          className="text-xs"
          style={{
            color: borderColor,
          }}
        />
        <div
          style={{
            height: 2,
            background: `linear-gradient(to right, ${borderColor}, ${borderColor} 60%, white 100%)`,
          }}
          className=" w-28 bg-black"
        ></div>
      </div>
    </div>
  )
}
export default ChartTogglePanel
