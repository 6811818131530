import React, { useState, useEffect, useRef, useContext } from "react"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { faArrowLeft, faBan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useLocation } from "@reach/router"
import classNames from "classnames/bind"
import { menuItems, isDevelopment } from "services/constants"
import VenueSwitch from "components/dashboard/VenueSwitch/VenueSwitch"
import { MenuItem, ParentMenuItem } from "services/types"
import * as styles from "./MobileNav.module.css"
import ReferralButton from "components/common/ReferralButton/ReferralButton"
import FreemiumUpgradeSection from "components/common/FreemiumUpgradeSection/FreemiumUpgradeSection"

const cx = classNames.bind(styles)

interface MobileNavItemSingleProps {
  item: ParentMenuItem
}

const MobileNavItemSingle = ({ item }: MobileNavItemSingleProps) => {
  const dispatch = useContext(GlobalDispatchContext)

  const closeMenu = () => {
    dispatch({ type: "CLOSE_MOBILE_MENU" })
  }
  return (
    <li className="mb-2">
      <Link
        tabIndex={item.disabled ? -1 : 0}
        to={item.link || "/not-found"}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          return {
            className: cx("navItem", {
              // exclude homepage dashboard from active status
              activeItem:
                isCurrent || (isPartiallyCurrent && item.link !== "./"),
              disabled: item.disabled,
            }),
          }
        }}
        onClick={closeMenu}
      >
        <span className={styles.navItemIcon}>
          <FontAwesomeIcon icon={item.icon} />
        </span>
        <span className="flex-grow">{item.name}</span>
        {item.label && (
          <span
            className="label label--pink absolute"
            style={{ right: "1.5rem" }}
          >
            {item.label}
          </span>
        )}
        {item.disabled && (
          <FontAwesomeIcon
            className="ml-auto mr-2 text-primaryPinkLighter"
            icon={faBan}
          />
        )}
      </Link>
    </li>
  )
}

const MobileNavItemCollection = ({ item }: MobileNavItemSingleProps) => {
  const [active, setActive] = useState(false)
  const [init, setInit] = useState(false)
  const location = useLocation()
  const dropdownRef = useRef(null)
  const dispatch = useContext(GlobalDispatchContext)

  const closeMenu = () => {
    dispatch({ type: "CLOSE_MOBILE_MENU" })
  }

  useEffect(() => {
    if (active && !init) {
      setInit(true)
    }
  }, [active, init])

  useEffect(() => {
    const isCurrent = location.pathname === item.link
    const isPartiallyCurrent = location.pathname.includes(item.link)

    if ((isCurrent || isPartiallyCurrent) && !init) {
      setActive(true)
    }
  }, [location, init, item])

  return (
    <li className="mb-2 relative" ref={dropdownRef}>
      <Link
        to={item.link || "not-found"}
        tabIndex={item.disabled ? -1 : 0}
        onClick={(e) => {
          e.preventDefault()
          setActive(!active)
        }}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          return {
            className: cx("navItem", {
              activeItem: isCurrent || isPartiallyCurrent,
              disabled: item.disabled,
            }),
          }
        }}
      >
        <span className={styles.navItemIcon}>
          <FontAwesomeIcon icon={item.icon} />
        </span>
        <span className="flex-grow">{item.name}</span>
        {item.label && (
          <span
            className="label label--pink absolute"
            style={{ right: "1.5rem" }}
          >
            {item.label}
          </span>
        )}
        <FontAwesomeIcon
          className="ml-auto mr-3"
          size="xs"
          icon={faChevronDown}
          // @ts-ignore
          rotation={active ? 180 : 0}
        />
      </Link>
      {active && (
        <ul className={cx("navChildren")}>
          {item.children
            ?.filter((item) => isDevelopment || item.status !== "alpha")
            .map((item, index) => (
              <li key={`${item.name}-${index}`}>
                <Link
                  to={item.link || "/not-found"}
                  tabIndex={item.disabled ? -1 : 0}
                  getProps={({ isCurrent }) => {
                    return {
                      className: cx("navChildItem", {
                        activeChildItem: isCurrent,
                        disabled: item.disabled,
                      }),
                    }
                  }}
                  onClick={closeMenu}
                >
                  <span>{item.name}</span>
                  {item.disabled && (
                    <FontAwesomeIcon
                      className="ml-auto mr-2 text-primaryPinkLighter"
                      icon={faBan}
                    />
                  )}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </li>
  )
}

const MobileNav = () => {
  const [mobileNavItems, setMobileNavItems] = useState<
    ParentMenuItem[] | MenuItem[]
  >([])
  const { mobileMenuOpen, organization } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const closeMenu = () => {
    dispatch({ type: "CLOSE_MOBILE_MENU" })
  }

  const getNavItems = async () => {
    const items = await menuItems()
    setMobileNavItems(items)
  }
  useEffect(() => {
    getNavItems()
  }, [organization])

  return (
    <>
      <div className={cx("nav", { show: mobileMenuOpen })}>
        <div className="flex items-center">
          <button
            className="mr-4 text-xl p-3 text-primaryBlue"
            onClick={closeMenu}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </div>

        <VenueSwitch />

        <FreemiumUpgradeSection theme="light" onButtonClick={closeMenu} />

        <nav className={styles.navWrapper}>
          <ul>
            {mobileNavItems.map((item, index) => {
              return item.children && item.children.length > 0 ? (
                <MobileNavItemCollection key={index} item={item} />
              ) : (
                <MobileNavItemSingle key={index} item={item} />
              )
            })}
          </ul>
        </nav>
        <div className="mt-auto mb-8 px-2">
          <ReferralButton />
        </div>
      </div>
      <div
        className={cx("overlay", { hidden: !mobileMenuOpen })}
        onClick={closeMenu}
      ></div>
    </>
  )
}

export default MobileNav
