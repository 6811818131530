import { get } from "./api"

/*----------------------------------------------------------------------------*/
/* interfaces  */
/*----------------------------------------------------------------------------*/

export interface integration {
  id: string | null
  status: "ACTIVE" | "INACTIVE"
  type: string
}

interface integrations {
  accountingIntegrations: integration[]
  posIntegrations: integration[]
}

/*----------------------------------------------------------------------------*/
/* api calls */
/*----------------------------------------------------------------------------*/

export const getIntegrations = (params = {}, config) => {
  return get(`/integrations`, params, config)
}

/*----------------------------------------------------------------------------*/
/* helper functions */
/*----------------------------------------------------------------------------*/

const allIntegrations = (integrations) => {
  return [
    ...integrations?.accountingIntegrations,
    ...integrations?.posIntegrations,
  ]
}

export const hasIntegration = (
  integrations: integrations,
  integration: string
) => {
  return allIntegrations(integrations)?.find(
    (int: integration) =>
      (int.id === integration || int.type === integration) &&
      int.status === "ACTIVE"
  )
}

export const getIntegrationId = (
  integrations: integrations,
  integrationName: string
): string | null => {
  return allIntegrations(integrations).find(
    (int) => int.type === integrationName
  )?.id
}
