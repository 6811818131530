import React from "react"
import * as styles from "./RegisterInvitedUser.module.css"
import Helmet from "react-helmet"
import { Link } from "@reach/router"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextInput from "../../forms/TextInput"
import SidePromote from "components/auth/SidePromote/SidePromote"
import ContactModal from "components/common/ContactModal/ContactModal"
import { ModalConsumer } from "../../../context/modal/ModalContext"
import AuthFormWrapper from "components/auth/AuthFormWrapper/AuthFormWrapper"
import { navigate } from "gatsby"
import { login, logout } from "services/auth"
import { registerInvitedUser } from "services/user"
import PropTypes from "prop-types"
import queryString from "query-string"

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, "equalTo", equalTo)

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .min(6, "Minimum of 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .equalTo(Yup.ref("password"), "Passwords do not match"),
})

class Signup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      emailExists: false,
      token: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit(data) {
    const params = {
      ...data,
      token: this.state.token,
    }

    const signupResult = await registerInvitedUser(params)

    if (signupResult.status === 404) {
      this.setState({
        error: signupResult.message,
      })
    } else if (signupResult.error) {
      if (signupResult.status === 409) {
        this.setState({
          emailExists: true,
        })
      } else {
        this.setState({
          emailExists: false,
          error: "Something went wrong. Please try again or contact us.",
        })
      }
    } else {
      const loginResult = await login({
        username: signupResult.username,
        password: data.password,
      })

      if (loginResult) {
        navigate(`/dashboard/welcome`, { replace: true })
      } else {
        this.setState({
          error: "Something went wrong. Please try again.",
        })
      }
    }
  }

  componentDidMount() {
    logout(() => {
      const queryParams = queryString.parse(this.props.location.search)
      if (queryParams.token) {
        this.setState({ token: queryParams.token })
      }
    })
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        <div className={styles.container}>
          <div className="hidden py-4 pl-4 bg-white w-full lg:w-1/2 lg:block">
            <SidePromote />
          </div>

          <AuthFormWrapper className={styles.formContainer}>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={this.handleSubmit}
            >
              {({ isValid, dirty, isSubmitting }) => (
                <Form className="flex flex-col mb-4 px-6">
                  <div className={styles.welcomeContent}>
                    <h1 className="text-4xl mb-4">Let&apos;s get started!</h1>
                    <p className="text-lg text-gray-700">
                      Create an account. It&apos;s free!
                    </p>
                  </div>

                  {this.state.error && (
                    <div className={styles.error}>{this.state.error}</div>
                  )}

                  {this.state.emailExists && (
                    <div className={styles.error}>
                      An account with this email address already exists. Please{" "}
                      <Link to="/login" className="text-primaryPink">
                        log in
                      </Link>{" "}
                      to your account.
                    </div>
                  )}
                  <div className="form-group form-group--flex">
                    <div className="input-container input-container--left">
                      <TextInput
                        name="firstName"
                        className="form-control form-control--topleft"
                        placeholder="First name"
                        label="First name"
                      />
                    </div>
                    <div className="input-container input-container--right">
                      <TextInput
                        name="lastName"
                        className="form-control form-control--topright"
                        placeholder="Last name"
                        label="Last name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-container">
                      <TextInput
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        className="form-control"
                        placeholder="Password"
                        label="Password"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-container">
                      <TextInput
                        name="confirmPassword"
                        type="password"
                        autoComplete="new-password"
                        className="form-control form-control--last"
                        placeholder="Confirm password"
                        label="Confirm password"
                      />
                    </div>
                  </div>

                  <button
                    disabled={!isValid || !dirty || isSubmitting}
                    type="submit"
                    className="button mt-5"
                  >
                    Make me more efficient!
                  </button>
                </Form>
              )}
            </Formik>

            <p className="text-xs text-gray-700 text-center mb-5 px-6">
              By creating an account you agree to our{" "}
              <a
                href="/docs/Growyze_Terms.pdf"
                target="_blank"
                className="text-primaryPink underline font-sans font-normal"
              >
                Terms
              </a>{" "}
              &amp;{" "}
              <a
                href="/docs/Growyze_PrivacyPolicy.pdf"
                target="_blank"
                className="text-primaryPink underline font-sans font-normal"
              >
                Privacy policy
              </a>
            </p>

            <p className="text-sm text-gray-700 text-center mb-5 px-6">
              Having trouble creating an account?{" "}
              <ModalConsumer>
                {({ showModal }) => (
                  <a
                    href="mailto:support@growyze.com"
                    className="text-primaryPink"
                    onClick={(e) => {
                      e.preventDefault()
                      showModal(ContactModal)
                    }}
                  >
                    Contact us
                  </a>
                )}
              </ModalConsumer>
            </p>
            <hr className="mb-5" />
            <p className="text-sm text-gray-700 text-center mb-5 px-6">
              Already have an account?{" "}
              <Link className="text-primaryPink" to="/login">
                Log In
              </Link>
            </p>
          </AuthFormWrapper>
        </div>
      </>
    )
  }
}

Signup.propTypes = {
  location: PropTypes.object,
}

export default Signup
