import React, { useEffect, useContext, useState } from "react"
import Sidebar from "components/dashboard/Sidebar/Sidebar"
import Bottombar from "components/dashboard/Bottombar/Bottombar"
import MobileNav from "components/dashboard/MobileNav/MobileNav"
import ErrorContainer from "components/common/ErrorContainer/ErrorContainer"
import classNames from "classnames/bind"
import { useMediaQuery } from "react-responsive"
import { showError } from "services/toast"
import { navigate } from "@reach/router"
import { GlobalStateContext } from "../../../context/global/GlobalContextProvider"
import PropTypes from "prop-types"

import * as styles from "./DashboardLayout.module.css"

const cx = classNames.bind(styles)

const DashboardLayout = ({ children, location }) => {
  const { organization, sidebarCollapsed } = useContext(GlobalStateContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [isAllowed, setIsAllowed] = useState(false)

  /**
   * Get initial current user + organization here
   */
  useEffect(() => {
    if (
      !organization &&
      !location.pathname.includes("/dashboard/welcome") &&
      !location.pathname.includes("/dashboard/account-settings") &&
      !location.pathname.includes("/dashboard/company-settings") &&
      location.pathname !== "/login"
    ) {
      showError("Please complete your profile")
      navigate("/dashboard/welcome")
    } else {
      setIsAllowed(true)
    }
  }, [])

  return (
    <div className={cx("container", { mobile: isTabletOrMobile })}>
      {isTabletOrMobile ? <MobileNav /> : <Sidebar />}

      <div
        className={cx("main", {
          collapsed: sidebarCollapsed,
          mobile: isTabletOrMobile,
        })}
      >
        <ErrorContainer>{isAllowed ? children : null}</ErrorContainer>
      </div>
      {isTabletOrMobile && <Bottombar />}
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default React.memo(DashboardLayout)
