import React from "react"

import InterfaceDefaultCellProps from "./InterfaceDefaultCellProps"

interface SelectCellProps extends InterfaceDefaultCellProps {
  selection: any
  setSelection: (newItems) => void
}

function SelectCell(props: SelectCellProps) {
  const { rowData, selection, setSelection } = props

  const selectedPosition = selection.findIndex(
    (a) => a.id === rowData.id
  )

  const isSelected = selectedPosition > -1

  return (
    <div>
      <input
        type="checkbox"
        value={""}
        onChange={async () => {
          // eslint-disable-next-line no-debugger
          //   debugger
          const latestItems = [...selection]

          if (!isSelected) {
            latestItems.push(rowData)
          } else {
            latestItems.splice(selectedPosition, 1)
          }

          setSelection(latestItems)
        }}
        name="itemSelect"
        className={`form-checkbox text-primaryPink`}
        checked={isSelected}
        // disabled={!rowData.preferred || rowData._isSub} //Products with no children are preferred by default
      />
    </div>
  )
}

export default SelectCell
