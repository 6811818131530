import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faTimes } from "@fortawesome/pro-light-svg-icons"

import * as styles from "./OrderApprover.module.css"
export interface Approver {
  firstName: string
  lastName: string
  username: string
}

const OrderApprover = ({ approver, remove, userHasModifyPermission }) => {
  const { firstName, lastName, username } = approver

  return (
    <div className={styles.container}>
      {username && (
        <div className="flex items-center w-full relative">
          <div className={styles.details}>
            {firstName} {lastName}
            <div className="text-gray-600">{username}</div>
          </div>

          {userHasModifyPermission && (
            <button
              type="button"
              className={styles.deleteButton}
              onClick={remove}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default OrderApprover
