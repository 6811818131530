import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface Props {
    products: any[]
    cutIndex: number
    setProducts : React.Dispatch<React.SetStateAction<any[]>>
}

const ProductSelection: React.FunctionComponent<Props> = ({ products, setProducts, cutIndex = -1 }) => {
  const removeProduct = (product) => {
    setProducts((prev) => {
      const temp = [...prev]
      const idx = temp.findIndex(
        (a) => a.id === product.id && a.name === product.name
      )
      idx > -1 && temp.splice(idx, 1)
      return temp
    })
  }

  return (
    <div>
      <div className="flex flex-wrap max-h-80 overflow-y-auto overflow-x-hidden px-3">
        {(cutIndex > 1 ? products.slice(0, cutIndex) : products).map((p) => (
          <button
            onClick={() => removeProduct(p)}
            className="group cursor-pointer mr-2 font-medium mb-2 text-sm bg-gray-200 rounded-md space-x-1 flex items-center overflow-hidden"
            key={p.id}
          >
            <span className="py-2 pl-2">{p.name}</span>
            <div className="bg-transparent group-hover:bg-red-300 p-1 w-6 h-full flex items-center justify-center">
              <FontAwesomeIcon icon={faTimes} className="text-red-500" />
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}

export default ProductSelection
