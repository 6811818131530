import { initialOrder } from "context/global/InitialContext"

const orderReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_NEW_ORDER": {
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          ...action.payload,
        },
      }
    }
    case "RESET_NEW_ORDER": {
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          ...initialOrder,
          _initialItems: [],
        },
      }
    }
    default: {
      return state
    }
  }
}

export default orderReducer
