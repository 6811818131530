import React from "react"
import PropTypes from "prop-types"
import TimeField from "react-simple-timefield"

const TimePicker = ({ value, onChange, ...props }) => {
  const handleTimeChange = (event, value) => {
    event.preventDefault()
    onChange(value)
  }

  const customStyles = {
    width: "70px",
    borderRadius: "5px",
    backgroundColor: "#F8F8FF",
    borderColor: "#e2e8f0",
  }

  return (
    <TimeField
      value={value}
      onChange={handleTimeChange}
      style={customStyles}
      {...props}
    />
  )
}

TimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default TimePicker
