import React, { useContext } from "react"
import classNames from "classnames/bind"

import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faCheck,
  faBan,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/pro-regular-svg-icons"

import StockTransferContentItem from "../StockTransferContentItem/StockTransferContentItem"
import StockTransferProductIncoming from "../StockTransferProductIncoming/StockTransferProductIncoming"
import usePermissions from "hooks/usePermissions"
import { calculateDifference, roundNumber, ucfirst } from "services/helpers"

import * as styles from "./StockTransferContent.module.css"
import { Permission } from "services/types"

const cx = classNames.bind(styles)

interface StockTransferContentProps {
  transfer: any
  contentTypeObj: any
  onAdd: Function
  onDelete: Function
  onSave: Function
  onSend: Function
  onCancel: Function
  onReject: Function
  onReceive: Function
  onClose: Function
  onConfirm: Function
  onRevert: Function
  onComment: Function
  onEditQty: Function
  onSelect: Function
  isOutgoing: boolean
}

const StockTransferContent = ({
  transfer,
  contentTypeObj,
  onAdd,
  onDelete,
  onSave,
  onSend,
  onCancel,
  onReject,
  onReceive,
  onClose,
  onConfirm,
  onRevert,
  isOutgoing,
  onComment,
  onEditQty,
  onSelect,
}: StockTransferContentProps) => {
  const { organization } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Transfers") as Permission

  const allowDelete = transfer.status === "DRAFT"

  const filterItemsWithSource = (items) => {
    if (isOutgoing) {
      return items.filter((item) => !item.source)
    } else {
      return items
    }
  }

  const contentItems = filterItemsWithSource(
    transfer[contentTypeObj.type] ?? []
  )

  const renderButtons = () => {
    return (
      <div className="flex">
        {transfer.status === "DRAFT" && (
          <>
            <button
              className={cx(
                "button button--autoWidth button--lightGray",
                styles.saveButton
              )}
              disabled={!permissionObj?.permissions.modify}
              onClick={() => onSave(transfer)}
            >
              Save & Close
            </button>
            <button
              className={cx("button button--autoWidth")}
              disabled={!permissionObj?.permissions.modify}
              onClick={() => onSend(transfer)}
            >
              Send transfer{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </>
        )}
        {transfer.status === "SENT" &&
          (isOutgoing ? (
            <>
              <button
                className={cx("button button--autoWidth")}
                disabled={!permissionObj?.permissions.modify}
                onClick={() => onCancel(transfer)}
              >
                Cancel transfer
                <FontAwesomeIcon icon={faBan} className="ml-2" />
              </button>
            </>
          ) : (
            <>
              <button
                className={cx("button button--autoWidth mr-5")}
                disabled={!permissionObj?.permissions.modify}
                onClick={() => onReject(transfer)}
              >
                Reject transfer
              </button>
              <button
                className={cx("button button--autoWidth button--primaryGreen")}
                disabled={!permissionObj?.permissions.modify}
                onClick={() => onReceive(transfer)}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Mark received
              </button>
            </>
          ))}
        {transfer.status === "CANCELLED" &&
          transfer.canceledByOrgId === organization.id && (
            <button
              className={cx("button button--primaryGreen button--autoWidth")}
              disabled={!permissionObj?.permissions.modify}
              onClick={() => onRevert(transfer)}
            >
              Revert transfer
              <FontAwesomeIcon icon={faArrowLeft} className="ml-2" />
            </button>
          )}
        {transfer.status === "RECEIVED" && !isOutgoing && (
          <button
            className={cx("button button--autoWidth")}
            disabled={!permissionObj?.permissions.modify}
            onClick={() => onRevert(transfer)}
          >
            Revert transfer
            <FontAwesomeIcon icon={faArrowLeft} className="ml-2" />
          </button>
        )}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <div className="flex justify-between items-center">
            <span className="font-semibold text-gray-800 text-lg">
              {isOutgoing
                ? `${ucfirst(contentTypeObj.labelPlural)} to send`
                : "Incoming items"}
            </span>
            {transfer.status === "DRAFT" && (
              <button
                className="button flex-shrink-0 button--autoWidth button--primaryGreen"
                disabled={!permissionObj?.permissions.modify}
                onClick={() => onAdd(contentTypeObj)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add{" "}
                {contentTypeObj.label}
              </button>
            )}
          </div>

          <div className={styles.items}>
            {contentItems.length > 0
              ? contentItems.map((item, index) =>
                  isOutgoing ? (
                    <StockTransferContentItem
                      key={`${item.sentProductId}-${index}`}
                      transfer={transfer}
                      item={item}
                      itemType={contentTypeObj.typeKey}
                      allowDelete={allowDelete}
                      onComment={onComment}
                      onEditQty={onEditQty}
                      onDelete={onDelete}
                    />
                  ) : (
                    <StockTransferProductIncoming
                      key={`${item.sentProductId}-${index}`}
                      transfer={transfer}
                      product={item}
                      onComment={onComment}
                      onEditQty={onEditQty}
                      onSelect={onSelect}
                      onConfirm={(data) => {
                        onConfirm(transfer, item, data)
                      }}
                    />
                  )
                )
              : `No ${contentTypeObj.labelPlural} added`}
          </div>
        </div>
        {!isOutgoing && transfer.sentTotalCost && (
          <div className={styles.totals}>
            <div className="flex flex-col flex-1">
              <div className="flex justify-end">
                <span className={styles.itemLabel}>
                  Transfer received total:
                </span>
              </div>

              <div className="flex justify-end">
                <span className={styles.itemLabel}> Transfer sent total:</span>
              </div>

              <div className="flex justify-end">
                <span className={styles.itemLabel}>Variance:</span>
              </div>
            </div>

            <div className="flex flex-col flex-1 ml-2">
              <strong className="text-primaryBlue ml-12">
                {roundNumber(transfer.receivedTotalCost)}
              </strong>
              <div className="flex">
                <strong className="text-primaryBlue ml-12">
                  {roundNumber(transfer.sentTotalCost)}
                </strong>
              </div>

              <strong className="text-primaryPink ml-12">
                {roundNumber(
                  calculateDifference(
                    transfer.sentTotalCost,
                    transfer.receivedTotalCost
                  )
                )}
              </strong>
            </div>
          </div>
        )}
      </div>

      <footer className={styles.footer}>
        <button className={styles.cancelButton} onClick={() => onClose()}>
          Close
        </button>
        {renderButtons()}
      </footer>
    </div>
  )
}

export default StockTransferContent
