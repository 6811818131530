import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
import {
  faPencil,
  faCircle,
  faCheckCircle,
  faCheck,
  faTimes,
  faCommentAlt,
  faCommentAltPlus,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/pro-regular-svg-icons"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import { useMediaQuery } from "react-responsive"

import * as styles from "./StockTransferProductIncoming.module.css"
import usePermissions from "hooks/usePermissions"
import { roundNumber } from "../../../../../services/helpers"
import { Permission } from "services/types"

interface StockTransferProductIncomingProps {
  product: any
  transfer: any
  onComment: Function
  onEditQty: Function
  onConfirm: Function
  onSelect: Function
}

const StockTransferProductIncoming = ({
  product,
  transfer,
  onComment,
  onEditQty,
  onConfirm,
  onSelect,
}: StockTransferProductIncomingProps) => {
  const [viewSent, setViewSent] = useState(false)
  const hasCase = viewSent
    ? !!product.sentQtyInCase
    : !!product.receivedQtyInCase
  const hasComments =
    !!product.senderComment ||
    !!product.receiverComment ||
    (product.source?.type === "RECIPE" &&
      !!transfer.recipes.find((recipe) => recipe.id === product.source?.refId)
        ?.senderComment) ||
    (product.source?.type === "DISH" &&
      !!transfer.dishes.find((dish) => dish.id === product.source?.refId)
        ?.senderComment)

  const permissionObj = usePermissions("Transfers") as Permission
  const icon = hasCase ? faCube : faWineBottle

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const {
    isConfirmed,
    receivedMeasure,
    receivedName,
    receivedProductId,
    receivedQty,
    receivedTotalCost,
    receivedSize,
    sentMeasure,
    sentName,
    sentProductId,
    sentQty,
    sentTotalCost,
    sentSize,
    sentUnit,
  } = product

  const productFound = !!receivedProductId
  const sendOrMissing = viewSent || !productFound

  let viewData = {
    qty: sendOrMissing ? sentQty : receivedQty,
    name: sendOrMissing ? sentName : receivedName,
    size: sendOrMissing ? sentSize : receivedSize,
    measure: sendOrMissing ? sentMeasure : receivedMeasure,
    productId: sendOrMissing ? sentProductId : receivedProductId,
    totalCost: sendOrMissing ? sentTotalCost : receivedTotalCost,
    unit: sentUnit,
  }

  const mapWhenMissing = (viewData, product) => {
    if (!viewData.productId && product.source) {
      const res = mapFromType(product.source.refId, product.source.type)
      if (res) {
        return { ...viewData, ...res }
      }
    }
    return viewData
  }

  const mapFromType = (id, type) => {
    switch (type) {
      case "RECIPE": {
        const recipe = transfer.recipes.find((r) => r.id === id)
        return {
          name: recipe.name,
          measure: recipe.sentMeasure,
          qty: recipe.sentQty,
          totalCost: recipe.sentTotalCost,
        }
      }
      case "DISH": {
        const dish = transfer.dishes.find((d) => d.id === id)
        return {
          name: dish.name,
          measure: dish.sentMeasure,
          qty: dish.sentQty,
          totalCost: dish.sentTotalCost,
        }
      }
      case "PRODUCT":
      default: {
        const product = transfer.products.find((d) => d.sentProductId === id)
        return {
          name: product.name,
          measure: product.sentMeasure,
          qty: product.sentQty,
          totalCost: product.sentTotalCost,
          size: product.sentSize,
          unit: product.sentUnit,
        }
      }
    }
  }

  const handleButtonClick = (data) => {
    switch (data) {
      case "comment":
        onComment({ ...product, name: viewData.name })
        break
      case "edit":
        onEditQty(product, viewSent ? "sentQty" : "receivedQty")
        break
      case "confirm":
        onConfirm(!isConfirmed)
        break
      default:
        return
    }
  }

  viewData = mapWhenMissing(viewData, product)

  const actions = () => {
    const options = [
      {
        key: "comment",
        title: "Comment",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "edit",
        title: "Edit",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "confirm",
        title: isConfirmed ? "Unconfirm" : "Confirm",
        type: isConfirmed ? "danger" : "success",
        disabled: !permissionObj?.permissions.modify,
      },
    ]

    return (
      <div className="pl-3 flex-shrink-0" style={{ textAlign: "right" }}>
        <ActionsButton
          options={options}
          onActionClick={(action) => handleButtonClick(action.type)}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className="flex flex-col items-center h-full">
        <button
          className={classNames(styles.viewSwitch, styles.receivedSwitch, {
            [styles.active]: !viewSent,
            [styles.notFoundSwitch]: !productFound,
          })}
          onClick={() => setViewSent(false)}
        >
          <span className={styles.viewSwitchBadge}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="xs"
              className="text-primaryGreen"
            />
          </span>
          <div className={styles.viewSwitchIndicator}>
            <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />
          </div>
        </button>
        <button
          className={classNames(styles.viewSwitch, styles.sentSwitch, {
            [styles.active]: !!viewSent,
          })}
          onClick={() => setViewSent(true)}
        >
          <span className={styles.viewSwitchBadge}>
            <FontAwesomeIcon
              icon={faArrowRight}
              size="xs"
              className="text-primaryPink"
            />
          </span>
        </button>
      </div>
      <div className={styles.inner}>
        <div className={styles.sentReceived}>
          <span
            className={classNames(styles.detail, styles.detailIndicator, {
              [styles.sent]: viewSent,
            })}
          >
            {viewSent ? "SENT" : "RECEIVED"}
          </span>
          <span>
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {viewData.qty}
          </span>
        </div>
        <div
          className={classNames("flex-grow flex-shrink ", styles.details, {
            [styles.productNotFound]: !productFound && !viewSent,
          })}
        >
          <span className={styles.name}>{viewData.name}</span>
          <div className="flex items-center">
            <span className={styles.detail}>
              {viewData.size} {viewData.measure}{" "}
              {viewData.unit ? `(${viewData.unit})` : ""}
            </span>{" "}
            {viewData.totalCost != 0 && (
              <span className="flex items-center">
                <span className="text-sm text-gray-500 inline-block ml-4 mr-1">
                  {/* x */}
                </span>{" "}
                {roundNumber(viewData.totalCost)}
              </span>
            )}
          </div>
        </div>

        <div className={classNames(styles.details, "mx-4 flex-grow")}>
          <span className="text-sm w-36 ml-auto">
            {!viewSent &&
              (productFound ? (
                <span className="text-primaryGreen">
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  {!isMobile && "Product found"}
                </span>
              ) : (
                <span className="text-primaryPink">
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />
                  {!isMobile && "Product not found"}
                  <button
                    onClick={() => onSelect(product)}
                    className={classNames(styles.notFoundButton)}
                    disabled={!permissionObj?.permissions.modify}
                  >
                    Select product
                  </button>
                </span>
              ))}
          </span>
        </div>
        {!isMobile ? (
          <>
            <button
              className={styles.actionButton}
              onClick={() => handleButtonClick("comment")}
              disabled={!permissionObj?.permissions.modify}
            >
              {hasComments && <div className={styles.thumbnailIndicator}></div>}
              <FontAwesomeIcon
                icon={hasComments ? faCommentAlt : faCommentAltPlus}
              />
            </button>
            <button
              className={styles.actionButton}
              onClick={() => handleButtonClick("edit")}
              disabled={
                viewSent || !productFound || !permissionObj?.permissions.modify
              }
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button
              className={styles.actionButton}
              onClick={() => handleButtonClick("confirm")}
              disabled={!productFound || !permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon
                icon={isConfirmed ? faCheckCircle : faCircle}
                className="text-primaryGreen"
              />
            </button>
          </>
        ) : (
          actions()
        )}
      </div>
    </div>
  )
}
export default StockTransferProductIncoming
