import React from "react"

import ExpandIcon from "../comonents/ExpandIcon"

const hasExpandIcon = (props, forceProps) => {
  const { expand = null } = props

  if (expand !== null) {
    expand.component = expand.component ?? ExpandIcon
    forceProps.components = {
      ...(props.components ?? {}),
      ...(forceProps.components ?? {}),
      ExpandIcon: (expandProps) => (
        <expand.component {...expand} {...expandProps} />
      ),
    }
  }

  return forceProps
}
export default hasExpandIcon
