import React from "react"

import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as styles from "./WasteFooter.module.css"
import usePermissions from "hooks/usePermissions"
import { faBarcodeRead } from "@fortawesome/pro-solid-svg-icons"

interface WasteFooterProps {
  closeModal: () => void
  onScanAction: (manually?: boolean, tab?: string) => void
  tab: string
}

const WasteFooter = ({ closeModal, onScanAction, tab }: WasteFooterProps) => {
  const permissionObj = usePermissions("Waste")
  return (
    <div className={styles.wasteFooter}>
      <span
        className="font-sansBold text-gray-600 cursor-pointer"
        onClick={closeModal}
      >
        Close
      </span>

      <div className="space-x-4">
        <button
          className={"button button--autoWidth button--orange"}
          disabled={!permissionObj?.permissions.modify}
          onClick={() => onScanAction(false, tab)}
        >
          <FontAwesomeIcon icon={faBarcodeRead} className="mr-2" />
          Scan product
        </button>

        <button
          type="button"
          className="button my-2 ml-auto button--autoWidth button--orange"
          disabled={!permissionObj?.permissions.modify}
          onClick={() => onScanAction(true, tab)}
        >
          <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
          <span className="hidden lg:inline">Add manually</span>
        </button>
      </div>
    </div>
  )
}

export default WasteFooter
