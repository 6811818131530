import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { getInvoices } from "services/invoices"
import { searchSuppliers } from "services/suppliers/suppliers"
import { invoiceStates, isDevelopment } from "services/constants"
import { faPlus, faFileDownload } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import FilterSelect from "components/forms/FilterSelect"
import AsyncSelect from "components/forms/AsyncSelect"
import Header from "components/dashboard/Header/Header"
import InvoiceItem from "components/invoices/InvoiceItem/InvoiceItem"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import InvoiceModal from "components/invoices/Invoice/Invoice"
import { ModalContext } from "context/modal/ModalContext"
import { useMediaQuery } from "react-responsive"
import { invoicesQueryParameters } from "services/constants"
import SearchInput from "components/forms/SearchInput"

import { getBasePath, getParams } from "../../../services/api"
import { GatewayInvoiceControllerApiFactory } from "services/typescript-fetch-client"
import * as styles from "./Invoices.module.css"

import { navigate } from "gatsby"
import usePermissions from "hooks/usePermissions"
import { handleError } from "services/helpers"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import { faFileAlt } from "@fortawesome/pro-duotone-svg-icons"
import BaseList from "components/baseList/BaseList"
import { getInvoicesDummy } from "services/dummy"
import usePagination from "hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import FiltersButton from "components/common/FiltersButton/FiltersButton"
import { useAccountingProviders } from "services/accounting"
import { Permission } from "services/types"
import { invoiceReviewObject } from "./InvoiceReviewObject"

const controller = GatewayInvoiceControllerApiFactory(
  undefined,
  undefined,
  getBasePath()
)
const Invoices = () => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)

  const permissionObj = usePermissions("Invoices") as Permission
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [invoicesData, setInvoicesData] = useState({
    content: [],
  })
  const [status, setStatus] = useState<any | null>(null)
  const previousStatus = usePrevious<any | null>(status)
  const [supplier, setSupplier] = useState<any | null>(null)
  const previousSupplier = usePrevious<any | null>(supplier)
  const [product] = useState<any | null>(null)
  const previousProduct = usePrevious<any | null>(product)
  const [queryParameter, setQueryParameter] = useState({
    label: "Product name",
    value: "partialProductName",
  })
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)
  const { providers } = useAccountingProviders()

  // const onExportInvoice = (invoice, fileType) => {}
  // const loadProductOptions = (inputValue) =>
  //   getProductByNameAndBarcode({ partialName: inputValue })

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setInvoicesData({ content: [] })
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: "dateOfIssue,desc",
    } as any

    if (supplier) {
      params.supplierId = supplier.value
      if (previousSupplier && previousSupplier.value !== supplier.value) {
        params.page = 0
      }
    }

    if (status) {
      params.status = status.value

      if (previousStatus && previousStatus.value !== status.value) {
        params.page = 0
      }
    }
    if (q) {
      switch (queryParameter.value) {
        case "partialProductName":
          params.partialProductName = q
          break
        case "partialProductCode":
          params.partialProductCode = q
          break
        case "partialBarcode":
          params.partialBarcode = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        case "invoiceNumber":
          params.invoiceNumber = q
          break
        default:
          params.partialProductName = q
          break
      }
      if (previousQ !== q) {
        params.page = 0
      }
    }

    if (product) {
      params.q = product.value
      if (previousProduct && previousProduct.value !== product.value) {
        params.page = 0
      }
    }
    // fromPromise prevents call on unmount of component

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    const result = await fromPromise(getInvoices(params))

    if (result && !result.error) {
      setInvoicesData({ ...invoicesData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const options = [
    {
      key: "create",
      title: "New Invoice",
      disabled: !permissionObj?.permissions.modify,
    },
  ]

  isDevelopment &&
    options.push({
      key: "review",
      title: "New Review Invoice",
      disabled: !permissionObj?.permissions.modify,
    })

  const handleAddItemButtonClick = ({ type }) => {
    switch (type) {
      case "create":
        dispatch({ type: "RESET_NEW_INVOICE" })
        modal.showModal(InvoiceModal, { onUpdate: () => getData() })
        break
      case "review":
        {
          controller
            .createInvoiceFromEmailUsingPOST(
              invoiceReviewObject as any,
              getParams()
            )
            .then(getData)
            .catch(handleError)
        }

        break
      default:
        dispatch({ type: "RESET_NEW_INVOICE" })
        modal.showModal(InvoiceModal, { onUpdate: () => getData() })
    }
  }

  useEffect(() => {
    if (previousStatus || status) {
      getData()
    }
  }, [status])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      getData()
    }
  }, [supplier])

  useEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  useEffect(() => {
    getData()
  }, [selectedOrganizations])

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Invoices" />

        <div className={styles.subHeader}>
          <FiltersButton>
            <FilterSelect
              options={invoiceStates}
              value={status}
              onChange={(val) => {
                setStatus(val)
              }}
              placeholder={isTabletOrMobile ? "Status" : "Status: all"}
              isClearable={true}
              className="w-56"
            />
            <AsyncSelect
              promise={searchSuppliers}
              placeholder={isTabletOrMobile ? "Supplier" : "All suppliers"}
              isClearable={true}
              optionLabel="name"
              optionValue="id"
              onChange={(val) => {
                setSupplier(val)
              }}
              className="w-56"
            />
            <LocationsSelector />
          </FiltersButton>

          <div className="flex items-center flex-grow relative my-2 order-last sm:order-none w-full sm:w-max">
            <SearchInput
              label="Search by name"
              placeholder={`Search by ${queryParameter.label}`}
              className={`form-control rounded text-sm md:text-base mr-2 md:mr-6 ${styles.searchInput}`}
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
            <FilterSelect
              options={invoicesQueryParameters}
              value={queryParameter && queryParameter.value}
              onChange={(val) => {
                setQueryParameter(val)
              }}
              stylesOverride={{
                container: (provided) => ({
                  ...provided,
                  position: "static",
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#fff",
                  borderLeft: "none",
                  borderRadius: "0 0.25rem 0.25rem 0",
                  width: "fit-content",
                  borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  boxShadow: "none",
                  zIndex: state.isFocused ? 2 : null,
                  minHeight: 45,
                  ":hover": {
                    ...provided[":hover"],
                    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "0px",
                  width: "0px",
                }),
                menu: (provided) => ({
                  ...provided,
                  left: "0px",
                }),
              }}
            />
          </div>

          <div className="ml-auto flex items-center">
            {isDevelopment ? (
              <DropdownButton
                containerClass="mr-2"
                label={
                  <>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
                    Invoice
                  </>
                }
                options={options}
                onActionClick={handleAddItemButtonClick}
                mobileIcon={faPlus}
              />
            ) : (
              <button
                className="button my-2 ml-auto mr-2 button--autoWidth button--primaryGreen"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch({ type: "RESET_NEW_INVOICE" })
                  modal.showModal(InvoiceModal, { onUpdate: () => getData() })
                }}
                disabled={!permissionObj?.permissions.modify}
              >
                <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
                <span className="hidden lg:inline">New invoice</span>
              </button>
            )}
            <button
              onClick={(e) => {
                e.preventDefault()
                navigate("/dashboard/purchases/invoices/export")
              }}
              className="text-center my-2 button button--autoWidth button--primaryGreen order-last"
              disabled={!permissionObj?.permissions.read}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <BaseList
            title="invoices"
            id="invoices"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faFileAlt,
              color: "blue",
              getDummy: () => getInvoicesDummy,
            }}
            data={invoicesData.content}
            rowRender={(invoice) => (
              <InvoiceItem
                key={invoice.id}
                invoice={invoice}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
                providers={providers}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default Invoices
