import React, { useEffect } from "react"
import queryString from "query-string"
// import { Link } from "gatsby"
import Loader from "components/common/Loader/Loader"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

/**
 * This interface can be expanded if there are going to be more services where we need a generic callback redirect for
 */
interface CallbackHandlerProps {
  callbackType: "sage"
}

const CallbackHandler = ({ callbackType }: CallbackHandlerProps) => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const paramsAsString = queryString.stringify(queryParams)

  const handleCallback = (callbackType: "sage") => {
    switch (callbackType) {
      case "sage":
        return navigate(
          `/dashboard/company-settings?tab=integrations&callback=sage&${paramsAsString}`,
          { replace: true }
        )
    }
  }

  useEffect(() => {
    if (callbackType) {
      handleCallback(callbackType)
    }
  }, [])

  return (
    <div className="w-full h-full relative z-10 flex items-start justify-center overflow-y-scroll">
      <Loader
        isLoading={true}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading..
      </Loader>
    </div>
  )
}

export default CallbackHandler
