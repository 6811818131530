import React, { useContext } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import ConfirmCheckbox from "components/common/ConfirmCheckbox/ConfirmCheckbox"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/modal/ModalContext"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCommentAlt,
  faCommentAltPlus,
  faPencil,
  faCheckCircle,
  faCircle as faCircleOutline,
} from "@fortawesome/pro-regular-svg-icons"

import { roundNumber } from "services/helpers"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import * as styles from "./ConfirmDetailsItem.module.css"

import ReactTooltip from "react-tooltip"
import usePermissions from "hooks/usePermissions"
import {
  getCasePrice,
  getCaseQty,
  getUnitPrice,
  getUnitQty,
} from "services/invoices"

const cx = classNames.bind(styles)
const ConfirmDetailsItem = ({
  product,
  onEdit,
  onComment,
  onConfirm,
  onConfirmPrice,
  hasDeliveryNote,
  ...restProps
}) => {
  const isError = product.productDiscrepancies
  const isAddedManually = hasDeliveryNote && !product.dnReceivedQty
  const permissionObj = usePermissions("Invoices")

  let hasCase = product.receivedQtyInCase || product.invoiceQtyInCase

  const hasItemDiscrepancy =
    product.productDiscrepancies &&
    product.productDiscrepancies.deltaQty !== null &&
    product.dnReceivedQty !== null

  const hasPriceDiscrepancy =
    product.productDiscrepancies &&
    product.productDiscrepancies.deltaPrice !== null &&
    product.orderPrice !== null

  const hasTotalCostDiscrepancy =
    product.productDiscrepancies &&
    product.productDiscrepancies.deltaInvoicedExpectedTotalCost !== undefined

  const modal = useContext(ModalContext)

  // const caseSizeToUnitSize = (qty) =>
  //   qty !== null ? product.orderCaseSize * qty : "?"
  // const unitSizeToCaseSize = (qty) =>
  //   qty !== null ? qty / product.orderCaseSize : "?"

  // const getUnitQty = (qty, inCase) => {
  //   let q = qty !== null ? (inCase ? caseSizeToUnitSize(qty) : qty) : "?"
  //   if (q !== "?") {
  //     q = decimalCount(q) > 3 ? parseFloat(q).toFixed(3) : q
  //   }
  //   return q
  // }

  // const getCaseQty = (qty, inCase) => {
  //   let q = qty !== null ? (inCase ? qty : unitSizeToCaseSize(qty)) : "?"
  //   if (q !== "?") {
  //     q = decimalCount(q) > 3 ? parseFloat(q).toFixed(3) : q
  //   }
  //   return q
  // }

  // const getUnitPrice = (price, inCase) => {
  //   return roundNumber(inCase ? price / product.orderCaseSize : price, 3)
  // }

  // const getCasePrice = (price, inCase) => {
  //   return roundNumber(inCase ? price : price * product.orderCaseSize, 3)
  // }

  return (
    <li
      className={cx("item", { itemError: isError })}
      key={product.name}
      {...restProps}
    >
      <div className={styles.itemWrapper}>
        <div className={styles.itemPrimary}>
          <span className={styles.itemName}>{product.description}</span>
          {isAddedManually && <span className={styles.manualBadge}>Added</span>}
        </div>
        <span className={styles.itemMeasure}>
          {product.code ? ` ${product.code} - ` : ""}
          {product.invoiceQtyInCase && product.orderCaseSize
            ? `${product.orderCaseSize} x `
            : ""}
          {product.size} {product.measure}
          {product.invoiceQtyInCase
            ? ``
            : product.unit
            ? ` (${product.unit.toLowerCase()})`
            : ""}
        </span>
        <div className="flex flex-col w-full px-2 md:px-4">
          {hasDeliveryNote && (
            <div className="flex my-1 w-full flex-col sm:flex-row sm:items-center">
              <div className="flex mr-3">
                <span className={styles.itemLabel}>Received</span>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap items-center flex-grow pr-6">
                <div
                  className={`flex rounded flex-grow ${hasCase && "border"}`}
                >
                  <>
                    {hasCase && (
                      <div
                        className={`${styles.quantityFigure} justify-between ${
                          product.receivedQtyInCase ? styles.active : ""
                        }`}
                      >
                        <div className="flex items-center">
                          <ProductIcon
                            product={product}
                            inCase={product.receivedQtyInCase}
                          ></ProductIcon>

                          <span>
                            {getCaseQty(
                              product.dnReceivedQty,
                              product.receivedQtyInCase,
                              product
                            )}
                          </span>
                          <div className="flex mx-1">
                            <span className={styles.mathSymbol}>x</span>
                          </div>

                          <div className="flex">
                            <span className="text-sm">
                              {getCasePrice(
                                product.orderPrice,
                                product.receivedQtyInCase,
                                product
                              )}
                            </span>
                          </div>
                        </div>

                        {product.receivedQtyInCase && (
                          <div className="flex w-auto justify-end">
                            <div className="flex justify-center mx-1">
                              <span className={styles.mathSymbol}>=</span>
                            </div>
                            <span className="text-sm">
                              {roundNumber(product.expectedTotalCost, 3)}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      className={`${styles.quantityFigure} justify-between ${
                        !product.receivedQtyInCase ? styles.active : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <ProductIcon
                          product={product}
                          inCase={false}
                        ></ProductIcon>
                        <span>
                          {getUnitQty(
                            product.dnReceivedQty,
                            product.receivedQtyInCase,
                            product
                          )}
                        </span>
                        <div className="flex mx-1">
                          <span className={styles.mathSymbol}>x</span>
                        </div>

                        <div className="flex">
                          <span className="text-sm">
                            {getUnitPrice(
                              product.orderPrice,
                              product.receivedQtyInCase,
                              product
                            )}
                          </span>
                        </div>
                      </div>

                      {!product.receivedQtyInCase && (
                        <div className="flex w-auto justify-end">
                          <div className="flex justify-center mx-1">
                            <span className={styles.mathSymbol}>=</span>
                          </div>
                          <span className="text-sm">
                            {roundNumber(product.expectedTotalCost, 3)}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}
          <div className="flex my-1 w-full flex-col sm:flex-row sm:items-center">
            <div className="flex mr-3">
              <span className={styles.itemLabel}>Invoiced</span>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap items-center flex-grow pr-6">
              <div className={`flex rounded flex-grow ${hasCase && "border"}`}>
                {hasCase && (
                  //Invoiced quantity, computed in case
                  <div
                    className={`${styles.quantityFigure} justify-between ${
                      product.invoiceQtyInCase ? styles.active : ""
                    }`}
                  >
                    <div className="flex items-center">
                      <ProductIcon
                        product={product}
                        inCase={true}
                      ></ProductIcon>
                      <span
                        className={
                          hasItemDiscrepancy ? styles.hasDiscrepancy : ""
                        }
                      >
                        {getCaseQty(
                          product.invoiceQty,
                          product.invoiceQtyInCase,
                          product
                        )}
                      </span>
                      <div className="flex mx-1">
                        <span className={styles.mathSymbol}>x</span>
                      </div>

                      <div className="flex">
                        <span className="text-sm">
                          {getCasePrice(
                            product.invoicePrice,
                            product.invoiceQtyInCase,
                            product
                          )}
                        </span>
                      </div>
                    </div>

                    {product.invoiceQtyInCase && (
                      <div className="flex text-sm w-auto justify-end">
                        <div className="flex justify-center mx-1">
                          <span className={styles.mathSymbol}>=</span>
                        </div>
                        <span
                          className={
                            hasTotalCostDiscrepancy ? styles.hasDiscrepancy : ""
                          }
                        >
                          {roundNumber(
                            product.invoiceQty * product.invoicePrice,
                            3
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  //Invoice quantity, computed as single
                )}
                <div
                  className={`${styles.quantityFigure} justify-between ${
                    !product.invoiceQtyInCase ? styles.active : ""
                  }`}
                >
                  <div className="flex items-center">
                    <ProductIcon product={product} inCase={false}></ProductIcon>
                    <span
                      className={
                        hasItemDiscrepancy ? styles.hasDiscrepancy : ""
                      }
                    >
                      {getUnitQty(
                        product.invoiceQty,
                        product.invoiceQtyInCase,
                        product
                      )}
                    </span>
                    <div className="flex mx-1">
                      <span className={styles.mathSymbol}>x</span>
                    </div>
                    <div className="flex text-sm">
                      <span
                        className={
                          hasPriceDiscrepancy ? styles.hasDiscrepancy : ""
                        }
                      >
                        {getUnitPrice(
                          product.invoicePrice,
                          product.invoiceQtyInCase,
                          product
                        )}
                      </span>
                      {(hasPriceDiscrepancy || product.isAcceptedPrice) && (
                        <div className={styles.acceptBox}>
                          <div className={styles.containerCheckbox}>
                            <span
                              className="bg-white"
                              data-tip={
                                product.isAcceptedPrice
                                  ? "Revert to order price"
                                  : "Accept price changes"
                                // ? "Accepted new price"
                              }
                            >
                              <ConfirmCheckbox
                                isChecked={product.isAcceptedPrice}
                                clickHandler={() => {
                                  if (product.isAcceptedPrice) {
                                    // return
                                    modal.showModal(ConfirmModal, {
                                      type: "success",
                                      title: "Revert to order price",
                                      text: "Are you sure to revert back to the order price?",
                                      confirmButtonText: "Accept",
                                      cancelButtonText: "Decline",
                                      onConfirm: () => {
                                        product.invoicePrice =
                                          product.orderPrice
                                        if (product.isAcceptedPrice) {
                                          onConfirmPrice(
                                            (product.isAcceptedPrice = false)
                                          )
                                        }
                                      },
                                    })
                                  } else {
                                    modal.showModal(ConfirmModal, {
                                      type: "success",
                                      title: "Accept new price",
                                      text: "Are you sure to accept this new price?",
                                      confirmButtonText: "Accept",
                                      cancelButtonText: "Decline",
                                      onConfirm: () => {
                                        if (!product.isAcceptedPrice) {
                                          onConfirmPrice(
                                            (product.isAcceptedPrice = true)
                                          )
                                        }
                                      },
                                    })
                                  }
                                }}
                              />
                            </span>
                            <ReactTooltip
                              place="top"
                              effect="solid"
                              backgroundColor="white"
                              textColor="#4E4E4E"
                              borderColor="#DCDCDC"
                              border={true}
                              offset={{ left: 4, top: -3 }}
                              className="font-sansBold z-50"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {!product.invoiceQtyInCase && (
                    <div className="flex text-sm w-auto justify-end">
                      <div className="flex justify-center mx-1">
                        <span className={styles.mathSymbol}>=</span>
                      </div>
                      <span
                        className={
                          hasTotalCostDiscrepancy ? styles.hasDiscrepancy : ""
                        }
                      >
                        {roundNumber(
                          product.invoiceQty * product.invoicePrice,
                          3
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <button
          className={styles.itemEdit}
          disabled={!permissionObj?.permissions.modify}
          onClick={() => onComment(product)}
        >
          {product.comment ? (
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faCommentAlt} />
              <FontAwesomeIcon
                icon={faCircle}
                transform="shrink-4 right-6 up-6"
                className="text-white"
              />
              <FontAwesomeIcon
                icon={faCircle}
                transform="shrink-8 right-6 up-6"
                className="text-primaryPink"
              />
            </span>
          ) : (
            <FontAwesomeIcon icon={faCommentAltPlus} />
          )}
        </button>
        <button
          className={styles.itemEdit}
          disabled={!permissionObj?.permissions.modify}
          onClick={() => onEdit(product)}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
        <button
          className={cx("itemConfirm", { checked: product.isConfirmed })}
          onClick={() => onConfirm(product.isConfirmed ? false : true)}
          disabled={!permissionObj?.permissions.modify}
        >
          <FontAwesomeIcon
            icon={product.isConfirmed ? faCheckCircle : faCircleOutline}
          />
          <FontAwesomeIcon
            className={styles.confirmButtonHover}
            icon={faCheckCircle}
          />
        </button>
      </div>
    </li>
  )
}

ConfirmDetailsItem.propTypes = {
  product: PropTypes.object,
  onEdit: PropTypes.func,
  onComment: PropTypes.func,
  onConfirm: PropTypes.func,
  onConfirmPrice: PropTypes.func,
  supplier: PropTypes.object,
}

export default ConfirmDetailsItem
