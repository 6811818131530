import React, { useState, useRef, useEffect, useCallback } from "react"
import Calendar from "components/common/Calendar/Calendar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditDetails.module.css"
import { DateBefore } from "react-day-picker"

export interface EditObject {
  number: string
  date: Date
  dueDate: Date | undefined
}

const EditDetails = ({
  object: invoice,
  onSave,
  isSaving,
  labels,
}: {
  object: EditObject
  onSave: (EditObject) => void
  isSaving: boolean
  labels: any
}) => {
  const inputRef: React.LegacyRef<HTMLInputElement> | null = useRef(null)
  const [number, setNumber] = useState(invoice ? invoice.number : "")
  const [date, setDate] = useState(invoice.date ? invoice.date : "")
  const [dueDate, setDueDate] = useState(invoice.dueDate ? invoice.dueDate : "")
  const beforeMatcher: DateBefore = { before: new Date(date) }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setNumber(value)
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    window.setTimeout(() => {
      if (inputRef && inputRef.current) {
        const el = inputRef.current
        if (typeof el.selectionStart == "number") {
          el.selectionStart = el.selectionEnd = el.value.length
        } else if (typeof (el as any).createTextRange != "undefined") {
          el.focus()
          const range = (el as any).createTextRange()
          range.collapse(false)
          range.select()
        }
      }
    }, 1)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={`lg:px-2 w-full ${labels.dueDate ? "" : "sm:w-1/2"}`}>
          <label className="block text-gray-700 font-semibold font-sansSemiBold mb-3 w-full">
            {labels.number}
          </label>
          <input
            ref={inputRef}
            className={`block form-control border-0 px-0 ${styles.input}`}
            onFocus={setCursorAtTheEnd}
            type="text"
            value={number || ""}
            onChange={handleInputChange}
            placeholder={`Add number`}
          />
        </div>
        <div className="lg:px-2 w-full sm:w-1/2">
          <label className="block text-gray-700 font-semibold font-sansSemiBold mb-3 w-full">
            {labels.date}
          </label>
          <Calendar onSelect={(date) => setDate(date)} selectedDate={date} />
        </div>

        {labels.dueDate && (
          <div className="lg:px-2 w-full sm:w-1/2">
            <label className="block text-gray-700 font-semibold font-sansSemiBold mb-3 w-full">
              {labels.dueDate}
            </label>
            <Calendar
              disabled={beforeMatcher}
              onSelect={(val) => setDueDate(val)}
              selectedDate={dueDate}
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <button
          onClick={() => {
            const params = {
              number: number?.trim(),
              date,
            } as any
            if (labels.dueDate) {
              params.dueDate = dueDate
            }
            onSave(params)
          }}
          type="button"
          className="button button--primaryBlue"
          disabled={isSaving}
        >
          {isSaving && (
            <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
          )}
          Save
        </button>
      </div>
    </div>
  )
}

export default EditDetails
