import React, { useEffect, useState, useContext } from "react"
import { ModalContext } from "context/modal/ModalContext"
import * as queryString from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showWarn, showSuccess } from "services/toast"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { useLocation } from "@reach/router"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import XeroOrganizationSelect from "../XeroOrganizationSelect/XeroOrganizationSelect"
import {
  createXeroIntegration,
  getXeroClientId,
  getXeroIntegrations,
  redirectToXeroAuthorization,
  removeXeroIntegration,
} from "services/xero"
import { IntegrationButtonProps } from "services/types"

const XeroIntegrationButton = ({
  id = null,
  onConnectionChange,
  canConnect,
  canDisconnect,
}: IntegrationButtonProps) => {
  const route = useLocation()
  const url = typeof window !== "undefined" ? window.location.origin : ""
  const redirect = `${url}/dashboard/company-settings?tab=integrations&callback=xero`
  const modal = useContext(ModalContext)
  const queryParams = queryString.parse(route.search)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [integrationId, setIntegrationId] = useState<string | null>(id) // @todo this id needs to be passed in from the integrations overview. Will probably work as soon BE is ready automatically (no change needed here)
  const [xeroOrganization, setXeroOrganization] = useState<string | null>(null)
  const [checkingConnection, setCheckingConnection] = useState(true)

  const startXeroAuthorization = async () => {
    try {
      const clientId = await getXeroClientId()
      setLoading(true)
      redirectToXeroAuthorization(clientId)
    } catch (e) {
      setLoading(false)
      setError("Failed to connect to Xero, please try again later")
    }
  }

  const showXeroOrganizationSelect = (integrationId) => {
    modal.showModal(XeroOrganizationSelect, {
      onClose: () => {
        setLoading(false)
        if (!xeroOrganization) {
          setError(
            "You need to select your Xero location to finish the integration"
          )
        }
      },
      integrationId: integrationId,
      onOrganizationSelected: (organization) => {
        setXeroOrganization(organization)
        showSuccess("Xero connection successful!")
        onConnectionChange()
        setLoading(false)
      },
    })
  }

  const confirmRemove = () => {
    setError("")
    if (id) {
      modal.showModal(ConfirmModal, {
        type: "danger",
        title: `Disconnect Xero integration?`,
        text: "Are you sure you want to disconnect this integration?",
        confirmButtonText: "Disconnect",
        onConfirm: async () => {
          const success = await removeXeroIntegration(id)
          if (success) {
            showWarn("Xero disconnected!")
            setIntegrationId(null)
            setXeroOrganization(null)
            onConnectionChange()
          }
        },
      })
    }
  }

  const onLoad = async () => {
    try {
      const connections = await getXeroIntegrations()

      if (connections.length > 0) {
        setIntegrationId(connections[0].id)
        setXeroOrganization(connections[0].connection)
        if (!connections[0].connection) {
          setError(
            "You need to select your Xero organization to finish the integration"
          )
        }
      } else {
        if (queryParams.callback === "xero" && queryParams.code) {
          setLoading(true)
          const params = {
            code: queryParams.code,
            organizations: null,
            redirectUrl: redirect,
          }

          const { id, connection } = await createXeroIntegration(params)
          setIntegrationId(id)
          if (!connection) {
            showXeroOrganizationSelect(id)
          }
        }
      }
      setCheckingConnection(false)
    } catch (e: any) {
      console.log(e)
      setError(e.message)
      setCheckingConnection(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  return integrationId && xeroOrganization ? (
    canDisconnect ? (
      <button
        className="text-primaryPink font-semibold font-sansSemiBold hover:bg-gray-50 px-3 bg-white border rounded border-gray-200 text-sm self-start text-left mr-3"
        style={{ height: "40px " }}
        onClick={confirmRemove}
      >
        Disconnect
      </button>
    ) : null
  ) : (
    <div className="inline-flex flex-col items-end mr-3">
      {canConnect && (
        <button
          className="text-primaryGreen font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left"
          style={{ height: "40px" }}
          disabled={loading || checkingConnection}
          onClick={() => {
            if (!xeroOrganization && integrationId) {
              showXeroOrganizationSelect(integrationId)
            } else {
              startXeroAuthorization()
            }
          }}
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
              Connecting..
            </>
          ) : !xeroOrganization && integrationId ? (
            "Set organization"
          ) : (
            <>
              Connect
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </>
          )}
        </button>
      )}
      {error && <span className="text-primaryPink text-xs">{error}</span>}
    </div>
  )
}

export default XeroIntegrationButton
