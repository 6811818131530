import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import { Formik, Form } from "formik"

import TextInput from "components/forms/TextInput"
import Loader from "components/common/Loader/Loader"

import * as styles from "./EditSalesModal.module.css"

const EditSalesModal = ({
  data,
  type,
  onEdit,
  onRequestClose,
  ...otherProps
}) => {
  const [loading, setLoading] = useState(false)

  const dataLevel = data.hasOwnProperty("subCategory")
    ? "product"
    : data.hasOwnProperty("category")
    ? "subCategory"
    : "category"

  const onEditSales = async (data) => {
    setLoading(true)
    await onEdit(formatOutput(data), dataLevel)
    setLoading(false)
    onRequestClose()
  }

  const typeLabel = {
    totalSoldValue: "sales",
    totalSoldQty: "quantity",
    totalExpectedGP: "Expected GP",
  }

  const renderFormInput = (type) => {
    switch (type) {
      case "totalSoldValue":
        return (
          <TextInput
            name="totalSoldValue"
            showValidations={false}
            type="number"
            placeholder="Enter new sales value"
            className="form-control--first form-control--last w-full max-w-xs mx-auto mb-4"
          />
        )
      case "totalSoldQty":
        return (
          <TextInput
            name="totalSoldQty"
            showValidations={false}
            type="number"
            placeholder="Enter new sales quantity"
            className="form-control--first form-control--last w-full max-w-xs mx-auto mb-4"
          />
        )
      case "totalExpectedGP":
        return (
          <>
            <div className="relative" style={{ minWidth: "300px" }}>
              <TextInput
                name="totalExpectedGP"
                showValidations={false}
                type="number"
                placeholder="Enter new expexted GP"
                className="form-control--first form-control--last w-full max-w-xs mx-auto mb-4"
                label="Expected GP"
                displayLabel={true}
              />
            </div>
            <div className="relative" style={{ minWidth: "300px" }}>
              <TextInput
                name="totalExpectedGPPercent"
                showValidations={false}
                type="number"
                placeholder="Enter new expexted GP %"
                className="form-control--first form-control--last w-full max-w-xs mx-auto mb-4"
                label="Expected GP %"
                displayLabel={true}
              />
            </div>
          </>
        )
      default:
        return <></>
    }
  }

  const formatInput = (data) => {
    const newData = {}
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        newData[key] = ""
      } else {
        newData[key] = data[key]
      }
    })
    return newData
  }

  const formatOutput = (data) => {
    const newData = {}
    Object.keys(data).forEach((key) => {
      if (
        key === "totalExpectedGP" &&
        !data[key] &&
        data.totalExpectedGPPercent
      ) {
        newData[key] === null
      } else if (data[key] === "") {
        newData[key] = 0
      } else {
        newData[key] = data[key]
      }
    })
    return newData
  }

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      {...otherProps}
      portalClassName="editSalesModal"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.welcomeContent}>
          <h1 className="text-3xl mb-4 mt-3">Edit {typeLabel[type]}</h1>
          <p className="text-lg text-gray-700 mb-4">{data.name}</p>
        </div>
        <Loader isLoading={loading}>Saving..</Loader>

        <Formik
          enableReinitialize
          initialValues={formatInput(data)}
          onSubmit={(data) => {
            onEditSales(data)
          }}
        >
          {() => (
            <Form className="flex flex-col items-center mb-6 px-6">
              {renderFormInput(type)}

              <div className={styles.buttons}>
                <button
                  type="button"
                  onClick={onRequestClose}
                  className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={"button button--autoWidth button--primaryGreen"}
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default EditSalesModal
