import React from "react"
import * as styles from "./Signup.module.css"
import Helmet from "react-helmet"
import { Link } from "@reach/router"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextInput from "../../forms/TextInput"
import SidePromote from "../SidePromote/SidePromote"
import ContactModal from "components/common/ContactModal/ContactModal"
import { ModalConsumer } from "../../../context/modal/ModalContext"
import AuthFormWrapper from "../AuthFormWrapper/AuthFormWrapper"
import { sendEvent } from "services/gtm"
import { navigate } from "gatsby"
import {
  signup,
  loginV2 as login,
  isLoggedIn,
  authenticateUser,
} from "services/auth"
import SwitchPassword from "./SwitchPassword"

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, "equalTo", equalTo)

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  username: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Minimum of 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .equalTo(Yup.ref("password"), "Passwords do not match"),
})

class Signup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      emailExists: false,
      showPassword: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  sendGtmEvent(eventName, data) {
    sendEvent(eventName, {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.username,
    })
  }

  async handleSubmit(data) {
    const signupResult = await signup(data)

    if (signupResult.error) {
      if (signupResult.status === 409) {
        this.setState({
          emailExists: true,
        })
      } else {
        this.setState({
          emailExists: false,
          error: "Something went wrong. Please try again or contact us.",
        })
      }
      return
    } else {
      // Send signup event to GTM
      this.sendGtmEvent("signup", data)

      const authenticateUserResult = await authenticateUser({
        username: data.username,
        password: data.password,
      })

      if (authenticateUserResult.orgIdToOrgName) {
        const orgIds = Object.keys(authenticateUserResult.orgIdToOrgName)

        const params = {
          activeOrgId: orgIds[0],
          sessionId: authenticateUserResult.sessionId,
        }

        const loginResult = await login(params).catch((err) =>
          this.setState({
            error: err,
          })
        )

        if (loginResult) {
          // Send signup event to GTM
          this.sendGtmEvent("loggedInAfterSignup", data)

          navigate(`/dashboard/welcome?afterSignup=true`, { replace: true })
        } else {
          this.setState({
            error: "Something went wrong. Please try again.",
          })
        }
      } else {
        this.setState({
          error: "Something went wrong. Please try again or contact us.",
        })
      }
    }
  }

  componentDidMount() {
    if (isLoggedIn()) {
      navigate(`/dashboard`, { replace: true })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        <div className="flex flex-row flex-wrap items-stretch h-full">
          <div className="hidden py-4 pl-4 bg-white w-full lg:w-1/2 lg:block">
            <SidePromote />
          </div>

          <AuthFormWrapper
            className={styles.formContainer}
            header={
              <div className="block text-center mb-6">
                <h1 className="text-center text-4xl mb-4">
                  Start your journey with growyze
                </h1>
                <p className="text-lg text-gray-700">
                  Create your account today.
                </p>
              </div>
            }
          >
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={this.handleSubmit}
            >
              {({ isValid, dirty, isSubmitting }) => (
                <Form className="mb-4 px-6">
                  {this.state.error && (
                    <div className={styles.error}>{this.state.error}</div>
                  )}

                  {this.state.emailExists && (
                    <div className={styles.error}>
                      An account with this email address already exists. Please{" "}
                      <Link to="/login" className="text-primaryPink">
                        log in
                      </Link>{" "}
                      to your account.
                    </div>
                  )}
                  <div className="form-group form-group--flex">
                    <div className="input-container input-container--left">
                      <TextInput
                        name="firstName"
                        type="text"
                        className="form-control form-control--topleft"
                        placeholder="First name"
                        label="First name"
                      />
                    </div>
                    <div className="input-container input-container--right">
                      <TextInput
                        name="lastName"
                        type="text"
                        className="form-control form-control--topright"
                        placeholder="Last name"
                        label="Last name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-container">
                      <TextInput
                        name="username"
                        autoComplete="username"
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        label="Email address"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-container validation-mark-password">
                      <TextInput
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control"
                        placeholder="Password"
                        label="Password"
                      />
                      <SwitchPassword
                        state={this.state.showPassword}
                        onChange={(state) =>
                          this.setState({ showPassword: state })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-container validation-mark-password">
                      <TextInput
                        name="confirmPassword"
                        type={this.state.showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control form-control--last mr-6"
                        placeholder="Confirm password"
                        label="Confirm password"
                      />
                      <SwitchPassword
                        state={this.state.showPassword}
                        onChange={(state) =>
                          this.setState({ showPassword: state })
                        }
                      />
                    </div>
                  </div>

                  <button
                    disabled={!isValid || !dirty || isSubmitting}
                    type="submit"
                    className="button mt-5"
                  >
                    Make me more efficient!
                  </button>
                </Form>
              )}
            </Formik>

            <p className="text-xs text-gray-700 text-center mb-5 px-6">
              By creating an account you agree to our{" "}
              <a
                href="/docs/Growyze_Terms.pdf"
                target="_blank"
                className="text-primaryPink underline font-sans font-normal"
              >
                Terms
              </a>{" "}
              &amp;{" "}
              <a
                href="/docs/Growyze_PrivacyPolicy.pdf"
                target="_blank"
                className="text-primaryPink underline font-sans font-normal"
              >
                Privacy policy
              </a>
            </p>

            <p className="text-sm text-gray-700 text-center mb-5 px-6">
              Having trouble creating an account?{" "}
              <ModalConsumer>
                {({ showModal }) => (
                  <a
                    href="mailto:support@growyze.com"
                    className="text-primaryPink"
                    onClick={(e) => {
                      e.preventDefault()
                      showModal(ContactModal)
                    }}
                  >
                    Contact us
                  </a>
                )}
              </ModalConsumer>
            </p>
            <hr className="mb-5" />
            <p className="text-sm text-gray-700 text-center mb-5 px-6">
              Already have an account?{" "}
              <Link className="text-primaryPink" to="/login">
                Log In
              </Link>
            </p>
          </AuthFormWrapper>
        </div>
      </>
    )
  }
}

export default Signup
