import React from "react"
import { Formik, Form } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import * as Yup from "yup"
import PropTypes from "prop-types"
import { normalizeNullValues } from "services/helpers"
import TextInput from "../../forms/TextInput"

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email address is invalid")
    .required("Email address is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  telephone: Yup.string()
    .matches(/^\+?[0-9\s]+$/, "Invalid phone number", {
      excludeEmptyString: true,
    })
    .nullable(),
})

const ContactDetailsForm = ({ onDataChange, onValidChange, initialValues }) => {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues(initialValues)}
      validationSchema={Schema}
    >
      {() => (
        <>
          <FormikEffect
            onChange={onDataChange}
            onValidationChange={onValidChange}
          />
          <Form className="flex flex-col mb-4">
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="firstName"
                  label="First name"
                  type="text"
                  placeholder="First name *"
                  className="form-control form-control--first"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="lastName"
                  label="Last name"
                  type="text"
                  placeholder="Last name *"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-container">
                <TextInput
                  required={true}
                  name="email"
                  label="Email address"
                  type="email"
                  placeholder="Email address *"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-container">
                <TextInput
                  name="telephone"
                  label="Telephone"
                  type="text"
                  placeholder="Telephone"
                  className="form-control form-control--last"
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

ContactDetailsForm.propTypes = {
  onDataChange: PropTypes.func,
  onValidChange: PropTypes.func,
  initialValues: PropTypes.object,
}

export default ContactDetailsForm
