import {
  faChevronDown,
  faChevronUp,
  faTrash,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"

interface FoldableProps {
  isOpen?: boolean
  initialOpen?: boolean
  headerText?: string | ""
  onDelete?: { (): void } | null
  children: React.ReactNode
}

function Foldable({
  isOpen = false,
  children,
  headerText = "Item",
  onDelete = null,
}: FoldableProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <div className="border shadow-sm rounded-md bg-white">
      {/* Header */}
      <div
        onClick={() => setOpen(!open)}
        className="w-full bg-opacity-75 cursor-pointer flex justify-between items-center px-4 py-2"
        style={{ minHeight: "50px" }}
      >
        <h3>{headerText}</h3>
        <div className="flex space-x-4 items-center">
          <div>
            {onDelete && (
              <button
                type="button"
                className="hover:bg-red-200 w-8 h-8 rounded-full"
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete()
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="text-red-500" />
              </button>
            )}
          </div>
          <FontAwesomeIcon
            className="text-primaryBlue"
            icon={open ? faChevronUp : faChevronDown}
          />
        </div>
      </div>

      {/* Body */}
      <div className={`${open ? "block border-t" : "hidden"}`}>{children}</div>
    </div>
  )
}

export default Foldable
