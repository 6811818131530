// extracted by mini-css-extract-plugin
export var actionIndicator = "StockTransfers-module--actionIndicator--IXqSn";
export var activeSwitch = "StockTransfers-module--activeSwitch--uCyc1";
export var container = "StockTransfers-module--container--48HDm";
export var content = "StockTransfers-module--content--ZOvuJ";
export var noOrders = "StockTransfers-module--noOrders--83vz3";
export var subHeader = "StockTransfers-module--subHeader--DdsF8";
export var switcher = "StockTransfers-module--switcher--OXRIu";
export var switcherButton = "StockTransfers-module--switcherButton--Q3Ppa";
export var switcherButtonIn = "StockTransfers-module--switcherButtonIn--6nHo+";
export var switcherButtonOut = "StockTransfers-module--switcherButtonOut--qRyPq";