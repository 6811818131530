import React from "react"
import Select from "react-select"

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  flexShrink: 0,

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    flexShrink: 0,
    marginRight: 10,
    height: 10,
    width: 10,
  },
})

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? dot(state.data.color) : {}),
    backgroundColor: state.isSelected ? "#FC3762" : "#ffffff",
    ":hover": {
      ...provided[":hover"],
      backgroundColor: state.isSelected ? "#FC3762" : "#f7fafc",
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 5,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px calc(1rem - 2px)",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.25rem",
    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
    backgroundColor: "#F8F8FF",
    boxShadow: "none",
    cursor: "pointer",
    zIndex: state.isFocused ? 2 : null,
    minHeight: 45,
    ":hover": {
      ...provided[":hover"],
      borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
    },
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...(data.color ? dot(data.color) : {}),
  }),
}

interface Props {
  options: any[]
  name?: string
  isPortal?: boolean
  label?: string
  value?: any
  defaultValue?: any
  placeholder?: string
  isClearable?: boolean
  isSearchable?: boolean
  disabled?: boolean
  isMulti?: boolean
  className?: string
  onChange?: (opt: any) => Promise<void> | void
  getValueCustom?: (opts: any[], value: any) => {}
  getOptionLabel?: Function
  stylesOverride?: {}
  menuPlacement?: string
}

const FilterSelect = ({
  name,
  isPortal,
  label = "",
  options,
  value,
  getValueCustom,
  stylesOverride,
  ...props
}: Props) => {
  const getValue = getValueCustom
    ? getValueCustom
    : (opts, value) => opts.find((o) => o.value === value)

  const overrideStyles = stylesOverride && {
    ...customStyles,
    ...stylesOverride,
  }

  return (
    <>
      {label != "" && (
        <label className="sr-only" htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        id={name}
        name={name}
        styles={!stylesOverride ? customStyles : overrideStyles}
        menuPortalTarget={isPortal && document.querySelector("body")}
        options={options}
        isSearchable={false}
        value={getValue(options, value)}
        {...props}
      />
    </>
  )
}

export default FilterSelect
