import React, { useState } from "react"
import { DayPicker } from "react-day-picker"

import ConfirmModal from "../../ConfirmModal/ConfirmModal"
import { formatAsYearMonthDay } from "../../../../services/helpers"
import { parseISO } from "date-fns"

const CalendarModal = ({
  selectedDate,
  onConfirm,
  modifiers,
  disabled,
  title,
  ...props
}) => {
  const [selectedDay, setSelectedDay] = useState(
    selectedDate ? formatAsYearMonthDay(new Date(selectedDate)) : undefined
  )

  const handleDayClick = (day, modifiers) => {
    if (modifiers.disabled) {
      return
    }
    setSelectedDay(formatAsYearMonthDay(day))
  }

  const calendarStyles = {
    selected: {
      color: "#FFFFFF",
      backgroundColor: "#4B4B88",
    },
  }

  return (
    <ConfirmModal
      title={title || "Select a date"}
      onConfirm={() => {
        onConfirm(selectedDay)
      }}
      {...props}
    >
      <div className="flex justify-center my-8">
        <div className="flex flex-col">
          <DayPicker
            className="DateRangePicker"
            onDayClick={handleDayClick}
            mode="single"
            defaultMonth={selectedDay ? parseISO(selectedDay) : undefined}
            selected={selectedDay ? parseISO(selectedDay) : undefined}
            modifiersStyles={calendarStyles}
            modifiers={modifiers}
            showOutsideDays
            disabled={disabled}
          />
        </div>
      </div>
    </ConfirmModal>
  )
}

export default CalendarModal
