// extracted by mini-css-extract-plugin
export var dateOfIssue = "ConfirmDetailsHeader-module--dateOfIssue--PuBvj";
export var editButton = "ConfirmDetailsHeader-module--editButton--zfeTC";
export var header = "ConfirmDetailsHeader-module--header--TF-40";
export var headerMain = "ConfirmDetailsHeader-module--headerMain--xHcsC";
export var iconWrapper = "ConfirmDetailsHeader-module--iconWrapper--2IG9k";
export var noteRef = "ConfirmDetailsHeader-module--noteRef--TKkLa";
export var noteSubDetails = "ConfirmDetailsHeader-module--noteSubDetails--0-NnO";
export var statusBulb = "ConfirmDetailsHeader-module--statusBulb--Se7Bv";
export var supplierName = "ConfirmDetailsHeader-module--supplierName--W+qCk";
export var thumbnail = "ConfirmDetailsHeader-module--thumbnail--ZdG1U";
export var thumbnailWrapper = "ConfirmDetailsHeader-module--thumbnailWrapper--ztGHG";