import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { setQtyInputValues } from "services/helpers"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import * as styles from "./ConfirmQtyModal.module.css"
import classNames from "classnames/bind"
import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
import Quantity from "components/forms/Quantity"
const cx = classNames.bind(styles)

const ConfirmQtyModal = ({
  qtyInputTypes,
  packagingMenu,
  onConfirm,
  ...props
}) => {
  const [packaging, setPackaging] = useState("single")
  const [quantities, setQuantities] = useState(
    setQtyInputValues(qtyInputTypes, 1)
  )

  const handleQuantytyChange = (qty, qtyInputType) => {
    if (qtyInputType.syncQuantityKey) {
      if (
        quantities[qtyInputType.quantityKey] ===
        quantities[qtyInputType.syncQuantityKey]
      ) {
        setQuantities({
          //If qtyInputType has syncQuantityKey and values are equal, then sync values.
          ...quantities,
          [qtyInputType.quantityKey]: qty,
          [qtyInputType.syncQuantityKey]: qty,
        })
      } else {
        setQuantities({
          ...quantities,
          [qtyInputType.quantityKey]: qty,
        })
      }
    } else {
      setQuantities({
        ...quantities,
        [qtyInputType.quantityKey]: qty,
      })
    }
  }

  return (
    <ConfirmModal
      onConfirm={() => {
        onConfirm(quantities, packaging)
      }}
      {...props}
    >
      <div className="flex justify-center my-8 border rounded">
        {packagingMenu && (
          <div className={styles.tabs}>
            <button
              className={cx("tab", { active: packaging == "single" })}
              role="button"
              onClick={() => setPackaging("single")}
            >
              <FontAwesomeIcon icon={faWineBottle} />
            </button>
            {packagingMenu.enabled && (
              <button
                className={cx("tab", { active: packaging == "multiple" })}
                role="button"
                disabled={!packagingMenu.enabled}
                onClick={() => setPackaging("multiple")}
              >
                <FontAwesomeIcon icon={faCube} />
              </button>
            )}
          </div>
        )}
        <div className="flex flex-col py-2" style={{ flexGrow: 1 }}>
          {qtyInputTypes.length > 0 &&
            qtyInputTypes.map((qtyInputType: any) => {
              return (
                <Quantity
                  displayStyle="rounded"
                  fieldName="qty"
                  value={quantities[qtyInputType.quantityKey]}
                  label={qtyInputType.label}
                  onChange={(qty) => handleQuantytyChange(qty, qtyInputType)}
                  key={qtyInputType.quantityKey}
                />
              )
            })}
        </div>
      </div>
    </ConfirmModal>
  )
}

export default ConfirmQtyModal
