import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import Discrepancy from "components/delivery-notes/Discrepancy/Discrepancy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCommentAlt,
  faCommentAltPlus,
  faPencil,
  faCheckCircle,
  faCircle as faCircleOutline,
} from "@fortawesome/pro-regular-svg-icons"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import { formatCurrencyValue, roundNumber } from "services/helpers"
import * as styles from "./ConfirmDetailsItem.module.css"
import usePermissions from "hooks/usePermissions"
import ProductIcon from "components/common/ProductIcon/ProductIcon"

const cx = classNames.bind(styles)

const ConfirmDetailsItem = ({
  product,
  onEdit,
  onComment,
  onConfirm,
  isManualFlow,
  supplier,
  ...restProps
}) => {
  const isError = product.productDiscrepancies
  const isAddedManually = !product.orderQty && !isManualFlow
  const permissionObj = usePermissions("Delivery notes")

  const hasMultipleDiscrepancies = () => {
    return (
      product.productDiscrepancies &&
      product.productDiscrepancies.deltaReceivedQty !== null &&
      product.productDiscrepancies.deltaReceivedQty !== 0 &&
      product.productDiscrepancies.deltaReceivedQty !== undefined &&
      product.productDiscrepancies.deltaReceivedOrderedQty !== null &&
      product.productDiscrepancies.deltaReceivedOrderedQty !== 0 &&
      product.productDiscrepancies.deltaReceivedOrderedQty !== undefined
    )
  }

  const hasMultipleDiscrep = hasMultipleDiscrepancies()

  return (
    <li
      className={cx("item", { itemError: isError })}
      key={product.name}
      {...restProps}
    >
      {/* <div className={styles.itemWrapper}>
        <div className={styles.itemPrimary}>
          <span className={styles.itemName}>
            {product.name}
            {isAddedManually && (
              <span className={styles.manualBadge}>Added</span>
            )}
          </span>
          <span className={styles.itemMeasure}>
            {product.code ? ` ${product.code} - ` : ""}
            {product.orderQtyInCase && product.orderCaseSize
              ? `${product.orderCaseSize} x `
              : ""}
            {product.size} {product.measure}
            {product.orderQtyInCase
              ? ``
              : product.unit
              ? `(${product.unit.toLowerCase()})`
              : ""}
          </span>
        </div>
        <div className={styles.itemAmount}>
          <span className={styles.itemLabel}>Order Qty</span>
          <span className={styles.itemQty}>
            <FontAwesomeIcon icon={orderedIcon} className="mr-2 text-xs" />
            {product.orderQty !== null ? product.orderQty : 0}
          </span>
        </div>
        <div className={styles.itemAmount}>
          <span className={styles.itemLabel}>DN Qty</span>
          <span className={styles.itemQty}>
            {product.dnQty !== null ? product.dnQty : 0}
            {product.productDiscrepancies &&
              product.productDiscrepancies.deltaDNQty !== undefined &&
              product.orderQty !== null &&
              product.dnQty !== null && (
                <Discrepancy
                  deltaQty={product.productDiscrepancies.deltaDNQty}
                />
              )}
          </span>
        </div>
        <div className={styles.itemAmount}>
          <span className={styles.itemLabel}>Received</span>
          <span className={styles.itemQty}>
            <FontAwesomeIcon icon={receivedIcon} className="mr-2 text-xs" />
            {product.receivedQty !== null ? product.receivedQty : 0}
            {product.productDiscrepancies &&
              product.productDiscrepancies.deltaReceivedQty !== undefined && (
                <Discrepancy
                  deltaQty={product.productDiscrepancies.deltaReceivedQty}
                />
              )}
          </span>
        </div>
      </div> */}

      <div className={styles.itemWrapper}>
        <div className={styles.itemPrimary}>
          <span className={styles.itemName}>{product.name}</span>
          {isAddedManually && <span className={styles.manualBadge}>Added</span>}
        </div>
        <span className={styles.itemMeasure}>
          {product.code ? ` ${product.code} - ` : ""}
          {product.orderQtyInCase && product.orderCaseSize
            ? `${product.orderCaseSize} x `
            : ""}
          {product.size} {product.measure}
          {product.orderQtyInCase
            ? ``
            : product.unit
            ? ` (${product.unit.toLowerCase()})`
            : ""}
        </span>
        <div className="flex flex-col w-full">
          {!isManualFlow && (
            <div className="flex my-1 w-full flex-col sm:flex-row sm:items-center">
              <div className="flex mr-3">
                <span className={styles.itemLabel}>Order qty</span>
              </div>
              <div className="flex items-center flex-grow">
                <div className="flex flex-1">
                  <div className="text-sm">
                    <ProductIcon
                      product={product}
                      inCase={product.orderQtyInCase || product.orderInCase}
                    />
                    <span>
                      {product.orderQty !== null ? product.orderQty : 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex my-1 w-full flex-col sm:flex-row sm:items-center">
            <div className="flex mr-3">
              <span className={styles.itemLabel}>DN qty</span>
            </div>
            <div className="flex items-center flex-grow">
              <div className="flex flex-1">
                <div className="text-sm">
                  <ProductIcon
                    product={product}
                    inCase={
                      product.receivedQtyInCase ||
                      product.orderInCase ||
                      product.orderQtyInCase
                    }
                  />
                  <span>{product.dnQty !== null ? product.dnQty : "?"}</span>
                  {product.productDiscrepancies &&
                    product.productDiscrepancies.deltaDNQty !== undefined &&
                    product.orderQty !== null &&
                    product.dnQty !== null && (
                      <Discrepancy
                        deltaQty={product.productDiscrepancies.deltaDNQty}
                      />
                    )}
                </div>
              </div>
              <div className="flex mr-3">
                <span className={styles.mathSymbol}>x</span>
              </div>
              <div className="flex flex-1 text-sm relative">
                <span>
                  {supplier.currency
                    ? formatCurrencyValue(product.price)
                    : roundNumber(product.price)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex my-1 w-full flex-col sm:flex-row sm:items-center">
            <div className="flex mr-3">
              <span className={styles.itemLabel}>Received</span>
            </div>
            <div className="flex items-center flex-grow">
              <div className="flex flex-1">
                <span className="text-sm">
                  <ProductIcon
                    product={product}
                    inCase={
                      product.receivedQtyInCase ||
                      product.orderInCase ||
                      product.orderQtyInCase
                    }
                  />
                  <span>
                    {product.receivedQty !== null ? product.receivedQty : "?"}
                  </span>
                  {product.productDiscrepancies &&
                    product.productDiscrepancies.deltaReceivedQty !==
                      undefined && (
                      <Discrepancy
                        deltaQty={product.productDiscrepancies.deltaReceivedQty}
                        type="DN"
                        showType={hasMultipleDiscrep}
                      />
                    )}
                  {product.productDiscrepancies &&
                    product.productDiscrepancies.deltaReceivedOrderedQty !==
                      undefined &&
                    product.orderQty !== null &&
                    product.dnQty !== null && (
                      <Discrepancy
                        deltaQty={
                          product.productDiscrepancies.deltaReceivedOrderedQty
                        }
                        type="Order"
                        showType={hasMultipleDiscrep}
                      />
                    )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <button
          className={styles.itemEdit}
          onClick={() => onComment(product)}
          disabled={!permissionObj?.permissions.modify}
        >
          {product.comment ? (
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faCommentAlt} />
              <FontAwesomeIcon
                icon={faCircle}
                transform="shrink-4 right-6 up-6"
                className="text-white"
              />
              <FontAwesomeIcon
                icon={faCircle}
                transform="shrink-8 right-6 up-6"
                className="text-primaryPink"
              />
            </span>
          ) : (
            <FontAwesomeIcon icon={faCommentAltPlus} />
          )}
        </button>
        <button
          className={styles.itemEdit}
          onClick={() => onEdit(product)}
          disabled={!permissionObj?.permissions.modify}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
        <button
          className={cx("itemConfirm", { checked: product.isConfirmed })}
          disabled={!permissionObj?.permissions.modify}
          onClick={() => onConfirm(product.isConfirmed ? false : true)}
        >
          <FontAwesomeIcon
            icon={product.isConfirmed ? faCheckCircle : faCircleOutline}
          />
          <FontAwesomeIcon
            className={styles.confirmButtonHover}
            icon={faCheckCircle}
          />
        </button>
      </div>
    </li>
  )
}

ConfirmDetailsItem.propTypes = {
  product: PropTypes.object,
  onEdit: PropTypes.func,
  onComment: PropTypes.func,
  onConfirm: PropTypes.func,
  isManualFlow: PropTypes.bool,
  supplier: PropTypes.object,
}

export default ConfirmDetailsItem
