import React from "react"
import {
  faUtensils,
  faWineBottle,
  faCube,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const getIcon = ({ product, inCase }) => {
  if (inCase) {
    return faCube
  } else if (product.category) {
    const catToCheck = product.category.toLowerCase().trim()
    const bottleMeasure = ["Bottle", "Can", "Keg"]

    if (
      catToCheck === "beverages" ||
      (product.unit && bottleMeasure.includes(product.unit))
    ) {
      return faWineBottle
    } else if (catToCheck === "food") {
      return faUtensils
    } else {
      return faSpinner
    }
  } else {
    return faWineBottle
  }
}

export default (params) => {
  const icon = getIcon(params)

  if (icon !== undefined) {
    return (
      <FontAwesomeIcon
        className="mx-1 text-primaryBlue text-current"
        icon={icon}
      ></FontAwesomeIcon>
    )
  } else {
    return <span className="mx-3"></span>
  }
}
