// extracted by mini-css-extract-plugin
export var backIcon = "ProductInput-module--backIcon--hqCoA";
export var camera = "ProductInput-module--camera--HhuMt";
export var clickArea = "ProductInput-module--clickArea--jZBzQ";
export var confirm = "ProductInput-module--confirm--4iluD";
export var confirmContainer = "ProductInput-module--confirmContainer--DMgQM";
export var confirmImg = "ProductInput-module--confirmImg--uW2AM";
export var container = "ProductInput-module--container--20msY";
export var content = "ProductInput-module--content--hVwBv";
export var contentConfirm = "ProductInput-module--content--confirm--oAy3I";
export var displayIntro = "ProductInput-module--displayIntro--gBvhm";
export var flash = "ProductInput-module--flash--xaTPG";
export var header = "ProductInput-module--header--9qkZU";
export var headerMain = "ProductInput-module--headerMain--rdLvH";
export var navBar = "ProductInput-module--navBar--YBHs9";
export var notFound = "ProductInput-module--notFound--UxYRs";
export var notFoundContent = "ProductInput-module--notFoundContent--yFuVX";
export var notFoundHeader = "ProductInput-module--notFoundHeader--hnePf";
export var notFoundTitle = "ProductInput-module--notFoundTitle--8gbuO";
export var placeHolder = "ProductInput-module--placeHolder--7ZF48";
export var productSelect = "ProductInput-module--productSelect--vYTye";
export var productSelectContainer = "ProductInput-module--productSelectContainer--ze2wD";
export var rotate = "ProductInput-module--rotate--8m4RR";
export var scanPending = "ProductInput-module--scanPending--iUg9E";
export var scanPendingAnimation = "ProductInput-module--scanPendingAnimation--c5AYu";
export var scanPendingButton = "ProductInput-module--scanPendingButton--zcRXk";
export var scanPendingText = "ProductInput-module--scanPendingText--Ixg9Q";
export var scanning = "ProductInput-module--scanning--GHo-N";
export var show = "ProductInput-module--show--B-qHt";
export var spinning = "ProductInput-module--spinning--rg6bG";
export var step2 = "ProductInput-module--step2--tvSK1";
export var step3 = "ProductInput-module--step3--t+Led";
export var step4 = "ProductInput-module--step4--Dp-tx";
export var title = "ProductInput-module--title--0MegD";
export var transparent = "ProductInput-module--transparent--J2PqT";