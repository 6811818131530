import React from "react"

const CornerLabel = ({
  colorClassName,
  children,
}: {
  colorClassName?: string
  children: React.ReactNode
}) => {
  return (
    <div
      className={`absolute -top-px -right-px border-b border-l border-gray-200 flex flex-row items-center bg-white shadow-sm px-2 py-1 rounded-bl-lg mb-0 text-xs space-x-2 ${
        colorClassName || "text-primaryBlueLighter"
      }`}
      style={{ margin: "1px" }}
    >
      {children}
    </div>
  )
}

export default CornerLabel
