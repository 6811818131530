import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { ProductHistoryData } from "../ProductHistory/ProductHistory"
import { faSyncAlt, faWineBottle } from "@fortawesome/pro-duotone-svg-icons"

interface ProductHistoryItemProps {
  data: ProductHistoryData
}

const ProductHistoryItem: React.FunctionComponent<ProductHistoryItemProps> = ({
  data,
}) => {
  const [open, setOpen] = useState(false)

  const convertTime = (time: string) => {
    const date = new Date(time)
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${hours}:${minutes}`
  }

  return (
    <div className="flex flex-col gap-y-4 border-t last:border-b border-collapsible p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          <button type="button" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </button>

          <div className="rounded-full bg-primaryBlueVeryLight w-12 h-12 flex items-center justify-center text-xl relative mr-2">
            <FontAwesomeIcon icon={faWineBottle} className="text-primaryBlue" />

            <div className="rounded-full w-5 h-5 flex items-center justify-center border p-3.5 bg-white absolute -bottom-2 -right-2 text-base">
              <FontAwesomeIcon icon={faSyncAlt} className="text-primaryPink" />
            </div>
          </div>

          <div className="flex flex-col">
            <span>
              Product change&nbsp;
              <span className="font-bold">({data.changes.length})</span>
            </span>

            <span className="text-sm text-black text-opacity-50">
              {data.user.firstName} {data.user.lastName}
            </span>
          </div>
        </div>

        <span className="text-sm text-black text-opacity-50">
          {convertTime(data.changedAt)}
        </span>
      </div>

      {open && (
        <div className="ml-7">
          {data.changes.map((change: string, i: number) => (
            <div
              key={i}
              className="border-t last:border-b border-collapsible px-4 py-2.5 text-sm"
            >
              {change}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ProductHistoryItem
