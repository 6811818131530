import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextInput from "components/forms/TextInput"
import CustomSelect from "components/forms/CustomSelect"
import FormikEffect from "components/forms/FormikEffect"
import { normalizeNullValues } from "services/helpers"
import { businessTypes, employeesNumberRanges } from "services/constants"

const Schema = Yup.object().shape({
  businessType: Yup.string(),
  employeesNumberRange: Yup.string(),
  website: Yup.string()
    .url("Ensure a valid url that starts with http(s)://")
    .nullable(),
})

// Component used in both views = Company / Enterprise
interface OrganizationDetailsFormProps {
  onDataChange(data: any): void
  onValidChange(isValid: boolean): void
  initialValues: any
  showCompanyNameField?: boolean
  showTelephoneField?: boolean
}

const OrganizationDetailsForm = ({
  onDataChange,
  onValidChange,
  initialValues,
  showCompanyNameField = true,
  showTelephoneField = false,
}: OrganizationDetailsFormProps) => {
  let validationSchema = Schema
  const companyNameSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),
  })

  const telephoneSchema = Yup.object().shape({
    telephone: Yup.string().required("Phone number is required"),
  })

  if (showCompanyNameField) {
    validationSchema = validationSchema.concat(companyNameSchema)
  }

  if (showTelephoneField) {
    validationSchema = validationSchema.concat(telephoneSchema)
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues(initialValues)}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <FormikEffect
            onChange={onDataChange}
            onValidationChange={onValidChange}
          />
          <Form className="flex flex-col mb-6">
            {showCompanyNameField && (
              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    required={true}
                    name="companyName"
                    label="Company name"
                    type="text"
                    placeholder={"Business name *"}
                    className="form-control form-control--first"
                  />
                </div>
              </div>
            )}
            {showTelephoneField && (
              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    required={true}
                    name="telephone"
                    label="Phone number"
                    type="text"
                    placeholder={"Phone number *"}
                    className={`form-control ${
                      !showCompanyNameField ? "form-control--first" : ""
                    }`}
                  />
                </div>
              </div>
            )}

            <div className="form-group">
              <div className="input-container">
                <CustomSelect
                  name="businessType"
                  label="Business type"
                  options={businessTypes}
                  isSearchable={false}
                  placeholder="Business type"
                  className={`form-control ${
                    !showCompanyNameField && !showTelephoneField
                      ? "form-control--first"
                      : ""
                  }`}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-container">
                <CustomSelect
                  name="employeesNumberRange"
                  label="Number of employees"
                  isSearchable={false}
                  options={employeesNumberRanges}
                  placeholder="Number of employees"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-container">
                <TextInput
                  name="website"
                  label="Website"
                  type="text"
                  placeholder="Website: https://...."
                  className="form-control form-control--last"
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default OrganizationDetailsForm
