import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Foldable from "components/common/Foldable/Foldable"
import { showError } from "services/toast"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import CombinedAddressForm from "components/common/CombinedAddressForm/CombinedAddressForm"
import {
  initialAddressStub,
  initialDeliveryAddressStub,
} from "services/constants"
import { objHasValue } from "services/helpers"

interface Props {
  initialLocations: any[]
  zoneId: string
  country: string
  onLocationsChange: (locations: any[], nextStepEnabled: Boolean) => void
  showFirstLocation?: boolean
  locationsCount: number
}

function AddLocations(props: Props) {
  const {
    locationsCount = 0,
    initialLocations = [],
    onLocationsChange,
    showFirstLocation = true,
  } = props

  const initialObject = {
    address: {
      ...initialAddressStub,
      zoneId: props.zoneId, // Forcing the zoneId to the zone assigned in company detail, this cant be changed since the option is not displayed
      country: props.country,
    },
    deliveryAddress: {
      ...initialDeliveryAddressStub,
    },
  }
  const [locations, setLocations] = useState<any[]>(
    initialLocations.length
      ? initialLocations
      : showFirstLocation
      ? [{ ...initialObject }]
      : []
  )

  const [formsValid, setFormsValid] = useState({
    0: {
      address: false,
      deliveryAddress: false,
    },
  })

  const locationsValid = (locationsToCheck) =>
    locationsToCheck.every((a, index) => {
      // Depending if delivery address is filled, this is extra validated
      const valid = objHasValue(a.deliveryAddress)
        ? formsValid[index]?.address && formsValid[index].deliveryAddress
        : formsValid[index]?.address
      return valid
    })

  const addLocation = () => {
    const temp = [...locations]
    temp.push({ ...initialObject })
    setLocations(temp)
  }

  const handleLocationDataChange = (
    locationIndex: number,
    nextValues: any,
    addressType: "address" | "deliveryAddress"
  ) => {
    const tempNextValues = { ...nextValues }
    delete tempNextValues.deliveryAddress

    const temp = [...locations]
    temp[locationIndex][addressType] = {
      ...temp[locationIndex][addressType],
      ...tempNextValues,
    }

    setLocations(temp)
  }

  const handleDeleteLocation = (locationIndex: number) => {
    if (showFirstLocation && locations.length <= 1) {
      return showError("At least 1 location must be present")
    }
    const temp = [...locations]
    temp.splice(locationIndex, 1)
    setLocations(temp)
  }

  useEffect(() => {
    const nextStepEnabled: Boolean = Boolean(locations.length) && locationsValid(locations)
    onLocationsChange(locations, nextStepEnabled)
  }, [formsValid, locations])

  return (
    <div className="flex-grow overflow-auto">
      <div>
        {locations.map((location, index) => {

          const visibleLocationIndex = ((locationsCount) + (index + 1))

          return (
            <Foldable
              headerText={
                location?.address?.companyName || "Location #" + visibleLocationIndex
              }
              isOpen={index === locations.length - 1}
              key={"Location-" + index}
              onDelete={() => handleDeleteLocation(index)}
            >
              <div className="bg-paleBlue p-5">
                <CombinedAddressForm
                  showCompanyName={true}
                  validateOnMount
                  allowNullable
                  isNullable
                  onValidChange={(valid, type) => {
                    setFormsValid({
                      ...formsValid,
                      [index]: {
                        ...formsValid[index],
                        [type]: valid,
                      },
                    })
                  }}
                  initialAddress={location.address || initialAddressStub}
                  initialDeliveryAddress={location.deliveryAddress}
                  handleLocationDataChange={(values, type) =>
                    handleLocationDataChange(index, values, type)
                  }
                />
              </div>
            </Foldable>
          )
        })}
      </div>

      <div className="flex justify-between mt-3">
        <button
          onClick={() => addLocation()}
          className="button button button--smaller button--paleBlue space-x-4"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add location / area
        </button>
      </div>
    </div>
  )
}

export default AddLocations
