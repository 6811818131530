import React, { forwardRef, useImperativeHandle, useRef } from "react"
import TextInput from "components/forms/TextInput"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import CustomSelect from "components/forms/CustomSelect"

import {
  OrderRecurrenceRes,
  RepetitionTypeEnum,
} from "services/order-templates/types"
import { DayOfWeekEnum } from "services/types"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import DateInput from "../../forms/DateInput"
import {
  findRecurrenceInterval,
  formatRepetitionTypeIntervalKey,
} from "services/order-templates/helpers"
import * as Yup from "yup"
import {
  daysOfTheWeek,
  repetitionTypes,
} from "services/order-templates/constants"
import { hours } from "services/constants"
import { set } from "date-fns"

interface Props {
  setOrderRecurrence: React.Dispatch<React.SetStateAction<OrderRecurrenceRes>>
  orderRecurrence?: OrderRecurrenceRes
}

const Schema = Yup.object().shape({
  interval: Yup.number().min(1).required("Select interval"),
  repetitionType: Yup.string().required("Select recurrence"),
  dayOfWeek: Yup.string().when("repetitionType", {
    is: RepetitionTypeEnum["WEEKLY"],
    then: Yup.string().required("Select day of week"),
  }),
  orderTime: Yup.number()
    .min(0, "Time cannot be before 00:00")
    .max(23, "Time cannot be after 23:00")
    .required("Select order time"),
  startDate: Yup.string().required("Select start date"),
  endDate: Yup.string(),
})

const StandingOrderRecurrence = forwardRef(
  ({ orderRecurrence, setOrderRecurrence }: Props, ref) => {
    const permissionObj = usePermissions("Orders") as Permission
    const formRef = useRef<FormikProps<typeof initialValues>>(null)

    const today = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    const initialValues = {
      interval: orderRecurrence?.id
        ? findRecurrenceInterval(orderRecurrence)
        : 1,
      repetitionType: orderRecurrence?.repetitionType
        ? orderRecurrence.repetitionType
        : RepetitionTypeEnum["WEEKLY"],
      dayOfWeek: orderRecurrence?.weeklyRepetition?.dayOfWeek
        ? orderRecurrence?.weeklyRepetition?.dayOfWeek
        : DayOfWeekEnum.MONDAY,
      orderTime: orderRecurrence?.orderTime
        ? //@ts-ignore swagger gen ts LocalTimeRes type
          Number(orderRecurrence?.orderTime.split(":")[0])
        : 9,
      startDate: orderRecurrence?.startDate
        ? orderRecurrence?.startDate
        : today,
      endDate: orderRecurrence?.endDate ? orderRecurrence?.endDate : undefined,
    }

    const updateOrderRecurrence = (data: typeof initialValues) => {
      if (data.repetitionType) {
        const repetitionTypeInterval = formatRepetitionTypeIntervalKey(
          data?.repetitionType
        )

        const formattedTime = Math.floor(Number(data.orderTime))

        const orderRecurrenceUpdates: OrderRecurrenceRes = {
          repetitionType: data.repetitionType,
          //@ts-ignore swagger gen ts date type
          startDate: data.startDate,
          //@ts-ignore swagger gen ts date type
          endDate: data.endDate ? data.endDate : undefined,
          //@ts-ignore swagger gen ts date type
          orderTime: `${formattedTime?.toString().padStart(2, "0")}:00`,
        }

        if (data.repetitionType === RepetitionTypeEnum.DAILY) {
          orderRecurrenceUpdates.dailyRepetition = { interval: data.interval }
        } else if (data.repetitionType === RepetitionTypeEnum.WEEKLY) {
          orderRecurrenceUpdates.weeklyRepetition = {
            interval: data.interval,
            dayOfWeek: data.dayOfWeek,
          }
        }

        if (repetitionTypeInterval) {
          setOrderRecurrence({
            ...orderRecurrence,
            ...orderRecurrenceUpdates,
          })
        }
      }
    }

    const submitFormPromise = ({
      submitForm,
      validateForm,
    }: FormikHelpers<typeof initialValues>) => {
      return new Promise<void>((resolve, reject) => {
        submitForm()
          .then(validateForm)
          .then((errors) => {
            const isValid = Object.keys(errors).length === 0
            if (isValid) {
              resolve()
            } else {
              reject()
            }
          })
          .catch((e) => {
            console.log("error: ", e)
            reject()
          })
      })
    }

    const submitForm = () => {
      if (formRef.current) {
        return submitFormPromise(formRef.current)
      }
    }

    useImperativeHandle(ref, () => ({ submitForm }))

    return (
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        onSubmit={updateOrderRecurrence}
        innerRef={formRef}
        validationSchema={Schema}
      >
        {({ touched, errors, values }) => {
          const isWeekly =
            values.repetitionType === RepetitionTypeEnum["WEEKLY"]

          return (
            <>
              <Form className="flex flex-col justify-center px-4 py-2 lg:px-8 lg:py-9 mr-auto">
                <label
                  htmlFor="interval"
                  className="mb-2 font-sansBold text-primaryBlue"
                >
                  Repeats every*
                </label>

                <div className="mb-8">
                  <div className="flex items-stretch">
                    <div className={"flex items-stretch mr-4"}>
                      <TextInput
                        required={true}
                        name="interval"
                        label="interval"
                        type="number"
                        min={1}
                        step="1"
                        disabled={!permissionObj?.permissions.modify}
                        showValidations={false}
                        className="form-control form-control--first form-control--last w-24  border-gray-300"
                      />
                    </div>

                    <div className={"flex items-stretch"}>
                      <CustomSelect
                        showValidations={false}
                        required={true}
                        name="repetitionType"
                        isDisabled={!permissionObj?.permissions.modify}
                        id="repetitionType"
                        label="Repetition type"
                        placeholder="Select recurrence"
                        className="form-control form-control--first form-control--last flex-nowrap w-44"
                        options={repetitionTypes}
                      />
                    </div>
                  </div>
                  {touched.interval && errors.interval && (
                    <div className="text-xs text-primaryPink mt-3">
                      {errors.interval}
                    </div>
                  )}
                  {touched.repetitionType && errors.repetitionType && (
                    <div className="text-xs text-primaryPink mt-3">
                      {errors.repetitionType}
                    </div>
                  )}
                </div>

                {isWeekly && (
                  <>
                    <label
                      htmlFor="dayOfWeek"
                      className="mb-2 font-sansBold text-primaryBlue"
                    >
                      On*
                    </label>
                    <div className="mb-8">
                      <CustomSelect
                        showValidations={false}
                        required={true}
                        name="dayOfWeek"
                        isDisabled={!permissionObj?.permissions.modify}
                        label="Day"
                        id="dayOfWeek"
                        placeholder="Select day"
                        className="form-control form-control--first form-control--last flex-nowrap w-72"
                        options={daysOfTheWeek}
                      />
                      {touched.dayOfWeek && errors.dayOfWeek && (
                        <div className="text-xs text-primaryPink mt-3">
                          {errors.dayOfWeek}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <label
                  htmlFor="orderTime"
                  className="mb-2 font-sansBold text-primaryBlue"
                >
                  Time (HH)*
                </label>
                <div className="mb-8">
                  <CustomSelect
                    name="orderTime"
                    label="orderTime"
                    isDisabled={!permissionObj?.permissions.modify}
                    defaultValue={values.orderTime}
                    className="form-control form-control--first form-control--last w-24"
                    options={hours}
                  ></CustomSelect>
                  {touched.orderTime && errors.orderTime && (
                    <div className="text-xs text-primaryPink mt-3">
                      {errors.orderTime}
                    </div>
                  )}
                </div>

                <DateInput
                  name="startDate"
                  label="Starting date*"
                  placeholder="Starting date"
                  disableBefore={today}
                />

                <DateInput
                  name="endDate"
                  label="Ending date (optional)"
                  placeholder="Ending date"
                  disableBefore={values?.startDate ? values?.startDate : today}
                />
              </Form>
            </>
          )
        }}
      </Formik>
    )
  }
)

export default StandingOrderRecurrence
