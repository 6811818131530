import React, { useState, useRef } from "react"
import { DayPicker, Matcher, DayContent, DayContentProps, DayModifiers } from "react-day-picker"
import { set, isBefore, format } from "date-fns"
// @ts-ignore
import * as styles from "./AsideCalendar.module.css"
import ReactTooltip from "react-tooltip"
import { FlexiblePeriod } from "services/types"

interface AsideCalendarProps {
  /**
   * period selected by user
   */
  selectedPeriod: FlexiblePeriod
  /**
   * list of days that should be highlighted
   */
  highlightDays?: Date[]
  /**
   * static label to be displayed as a tooltip on hover over highlighted days
   */
  highlightDaysLabel?: string
  /**
   * list of labels and dates used for rendering a dynamic tooltip over highlighted days
   */
  highlightDaysLabels?: { label: string; value: Date }[]
  /**
   * conditions to determine days that should not be available for selection by the user
   */
  disable?: Matcher | Matcher[] | undefined
  /**
   * user selection mode
   */
  mode?: "single" | "multiple" | "range"
  /**
   * on confirm selection callback
   */
  onConfirm(period: FlexiblePeriod): void
  /**
   * on close aside callback
   */
  onClose(): void
}

const AsideCalendar = ({
  selectedPeriod = {
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  },
  onConfirm,
  highlightDays = [],
  highlightDaysLabel = "Some event has happened here",
  highlightDaysLabels,
  disable,
  mode = "range",
}: AsideCalendarProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isSingleMode = mode === "single"
  const [period, setPeriod] = useState(selectedPeriod)
  const { from, enteredTo } = period

  const selectedDays: Date | { from: Date | undefined; to: Date | undefined } | undefined = isSingleMode
    ? from
    : { from, to: enteredTo }

  const modifiers: DayModifiers = {
    ...(from ? { start: from } : {}),
    ...(enteredTo ? { end: enteredTo } : {}),
    highlightDays: highlightDays,
  }

  const modifiersStyles = {
    highlightDays: {
      border: "2px solid #35CCC3",
      important: true,
    },
    outside: {
      border: 0,
    },
  }

  const handleDayClick = (day) => {
    const { from, to } = period

    if (isSingleMode)
      // only select from val if in single mode
      return setPeriod({
        from: set(day, { hours: 0, minutes: 0 }),
        to: undefined,
        enteredTo: undefined,
      })

    if (isSelectingFirstDay(from, to, day)) {
      setPeriod({
        from: set(day, { hours: 0, minutes: 0 }),
        to: undefined,
        enteredTo: undefined,
      })
    } else {
      setPeriod({
        ...period,
        to: day,
        enteredTo: set(day, { hours: 23, minutes: 59, seconds: 59 }),
      })
    }
  }

  const handleDayMouseEnter = (day) => {
    const { from, to } = period
    if (!isSelectingFirstDay(from, to, day)) {
      setPeriod({
        ...period,
        enteredTo: day,
      })
    }
  }

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && isBefore(day, from)
    const isRangeSelected = from && to
    return !from || isBeforeFirstDay || isRangeSelected
  }
  const ModifiedDay = (props: DayContentProps) => {
    const activeMod = props.activeModifiers
    const timeKey = `${props.date.getTime()}`

    const label = highlightDaysLabels?.find(
      (label) => label.value.toLocaleDateString() === props.date.toLocaleDateString()
    )?.label

    const tooltipLabel = label ? label : highlightDaysLabel

    return activeMod.highlightDays ? (
      <>
        <div className="w-full" data-tip={tooltipLabel} data-for={timeKey}>
          <DayContent {...props} />
        </div>
        <ReactTooltip
          id={timeKey}
          type="light"
          place={"top"}
          effect="solid"
          border={true}
          borderColor="#e2e8f0"
          multiline={true}
        />
      </>
    ) : (
      <DayContent {...props} />
    )
  }

  return (
    <div ref={ref} className="flex flex-col flex-grow overflow-hidden">
      {/* <div className="px-4 py-4 border-b md:px-6 flex items-center justify-center relative">
        <button
          type="button"
          className="p-4 text-primaryBlue absolute left-0 top-0 h-full text-center flex items-center"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h3 className="font-sansBold font-bold text-xl text-primaryBlue">
          Select a period
        </h3>
      </div> */}
      <div className="flex flex-col flex-grow flex-shrink overflow-auto">
        <DayPicker
          className={`DateRangePicker ${styles.datePicker}`}
          numberOfMonths={2}
          selected={selectedDays}
          modifiers={modifiers}
          mode={mode}
          defaultMonth={from ? from : new Date()}
          modifiersStyles={modifiersStyles}
          components={{ DayContent: ModifiedDay }}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          disabled={disable}
        />
      </div>
      <div className="p-4 flex-shrink-0 border-t text-center">
        <span className="text-gray-700 block mb-2">
          Selected:{" "}
          <span className="text-primaryBlueLighter font-sansSemiBold font-semibold">
            {period.from && format(period.from, "dd/MM/yyyy")}
            {period.to && <> - {format(period.to, "dd/MM/yyyy")}</>}
          </span>
        </span>

        <button
          type="button"
          className={`button button--smaller button--primaryGreen`}
          onClick={() => onConfirm(period)}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}
export default AsideCalendar
