// extracted by mini-css-extract-plugin
export var active = "StockTakeSheetContent-module--active--PhqrY";
export var cancelButton = "StockTakeSheetContent-module--cancelButton--fkmPn";
export var content = "StockTakeSheetContent-module--content--qfaZT";
export var contentContainer = "StockTakeSheetContent-module--contentContainer--U8pdy";
export var footer = "StockTakeSheetContent-module--footer--EiXF5";
export var noProducts = "StockTakeSheetContent-module--noProducts--TNOUu";
export var removeButton = "StockTakeSheetContent-module--removeButton--ziCX2";
export var scanButton = "StockTakeSheetContent-module--scanButton--OJpLS";
export var tabButton = "StockTakeSheetContent-module--tabButton--U7ZlV";
export var tabs = "StockTakeSheetContent-module--tabs--a6Y2Y";