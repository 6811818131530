import React from "react"

interface LabelProps {
  children?: React.ReactNode
  type?: "success" | "danger"
}

const Label = ({ children, type = "success" }: LabelProps) => {
  let classes = "bg-primaryGreen"

  switch (type) {
    case "success":
      classes = "bg-primaryGreen"
      break
    case "danger":
      classes = "bg-primaryPink"
      break
  }

  return (
    <div
      className={`font-black ${classes} text-white p-1 px-2 rounded-md text-xs`}
    >
      <span style={{ fontSize: 10 }} className="font-black">
        {children || "MAIN"}
      </span>
    </div>
  )
}

export default Label
