import React, { useContext, useEffect, useState } from "react"
import { bottomBarItems } from "services/constants"
import { Link } from "@reach/router"
import QuickActionDropdown from "components/dashboard/QuickActionDropdown/QuickActionDropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import classNames from "classnames/bind"
import { faBan } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./Bottombar.module.css"
const cx = classNames.bind(styles)

interface BottomBarItem {
  link: string
  disabled?: boolean
  name: string
  icon: IconProp
}

interface DesktopNavItemSingleProps {
  item: BottomBarItem
}

const DesktopNavItemSingle = ({ item }: DesktopNavItemSingleProps) => {
  return (
    <li>
      <Link
        to={item.link || "/not-found"}
        tabIndex={item.disabled ? -1 : 0}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          return {
            className: cx("navItem", {
              // exclude homepage dashboard from active status
              activeItem:
                isCurrent || (isPartiallyCurrent && item.link !== "./"),
              disabled: item.disabled,
            }),
          }
        }}
      >
        <span className={styles.navItemIcon}>
          <FontAwesomeIcon icon={item.icon} />
        </span>
        <span className={styles.navItemName}>{item.name}</span>
        {item.disabled && (
          <FontAwesomeIcon
            className="absolute top-3 right-1 text-primaryPinkLighter"
            icon={faBan}
          />
        )}
      </Link>
    </li>
  )
}

const Bottombar = () => {
  const [barItems, setBarItems] = useState<BottomBarItem[]>([])
  const { organization } = useContext(GlobalStateContext)
  const getBarItems = async () => {
    const items = await bottomBarItems()
    setBarItems(items)
  }
  useEffect(() => {
    getBarItems()
  }, [])
  return (
    <div className="fixed bottom-0 pb-16 z-30">
      <footer className={styles.container}>
        <nav className={styles.nav}>
          <ul>
            {barItems.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DesktopNavItemSingle item={item} />
                  {index === 1 && organization && (
                    <QuickActionDropdown mobile={true} />
                  )}
                </React.Fragment>
              )
            })}
          </ul>
        </nav>
      </footer>
    </div>
  )
}

export default Bottombar
