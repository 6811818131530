import React from "react"
import {
  faCircle,
  faCube,
  faWineBottle,
} from "@fortawesome/pro-solid-svg-icons"
import {
  productCategories,
  productCategoriesCombined,
} from "services/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//@ts-ignore
import * as styles from "./AddProductForm.module.css"

const SingleProductMeta = ({ product }) =>
  product.size || product.measure ? (
    <>
      <FontAwesomeIcon icon={faWineBottle} className="mr-2" />
      <span className="font-semibold font-sansSemiBold">
        {product.unit} {product.size} {product.measure}
      </span>
    </>
  ) : (
    <></>
  )

const MultipleProductMeta = ({ product }) => (
  <>
    <FontAwesomeIcon icon={faCube} className="mr-2" />
    <span className="font-semibold font-sansSemiBold">
      {product?.productCase?.size} x {product.size} {product.measure}
    </span>
  </>
)

export default ({ product }) => {
  const mainCategory = productCategories.find(
    (cat) => cat.value === product.category
  )
  const catList = mainCategory
    ? productCategoriesCombined.find(
        (pCat) => pCat.label === mainCategory.label
      )
    : null
  const subCat = catList?.options.find(
    (cat) => cat.value === product.subCategory
  )

  return (
    <span className={styles.productMeta}>
      {product.packaging === "multiple" ? (
        <MultipleProductMeta product={product}></MultipleProductMeta>
      ) : (
        <SingleProductMeta product={product}></SingleProductMeta>
      )}

      <FontAwesomeIcon
        icon={faCircle}
        transform="shrink-10"
        className="mx-2 text-gray-500"
      />
      <span>
        {mainCategory ? mainCategory.label : product.category}{" "}
        {subCat ? `- ${subCat.label}` : null}
      </span>
    </span>
  )
}
