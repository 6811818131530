import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PrimaryColor } from "hooks/useColors"
import React from "react"
import { ucfirst } from "services/helpers"

interface ButtonProps {
  onClick: Function
  className?: string
  mobileIconOnly?: boolean
  color: PrimaryColor | "white"
  title: string | any
  icon?: any
  [key: string]: any
}

const Button = (props: ButtonProps) => {
  const {
    onClick,
    title,
    icon,
    color = "green",
    className = "",
    mobileIconOnly = false,
    ...rest
  } = props

  const iconClass = mobileIconOnly ? "lg:mr-2" : "mr-2"
  const spanClass = mobileIconOnly ? "hidden lg:inline" : ""

  return (
    <button
      className={
        "button my-1 lg:my-2 mr-2 last:mr-0 button--autoWidth button--primary" +
        ucfirst(color) +
        " " +
        className
      }
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      {...rest}
    >
      {icon && <FontAwesomeIcon icon={icon} className={iconClass} />}
      <span className={spanClass}>{title}</span>
    </button>
  )
}

export default Button
