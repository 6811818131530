import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { AccountingProvider } from "services/accounting"

type AccountingPublishButtonProps = {
  provider: AccountingProvider
  onClick: (obj) => Function
  title: string
  alt: string
  icon?: any
}

const AccountingPublishButton = (props: AccountingPublishButtonProps) => {
  const { icon, provider, title, alt, onClick } = props
  return (
    <button
      onClick={(obj) => onClick(obj)}
      className="border border-gray-300 flex relative items-center shadow-sm bg-white text-primaryBlueLighter px-2 pr-3 py-1 rounded-md mb-0 transition-colors hover:shadow hover:border-gray-400 mr-2"
    >
      <span className="block relative">
        <img src={provider.logo} className="w-5 h-auto mr-2" alt={alt} />
      </span>
      {icon && (
        <span className="left-6 flex items-center justify-center pl-1 bg-white rounded-full w-4 h-4 absolute">
          <FontAwesomeIcon icon={icon} className={"text-green-500 text-sm"} />
        </span>
      )}
      <span className={"py-1 " + (icon ? "ml-4" : "")}>{title}</span>
    </button>
  )
}

export default AccountingPublishButton
