import { faListOl } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ServerImage from "components/common/ServerImage/ServerImage"
import React from "react"
import { productCategories } from "services/constants"
import { Recipe } from "services/dishes"

import * as styles from "./RecipeIcon.module.css"

interface RecipeIconProps {
  recipe: Recipe
  disabled?: boolean
  onClick?(params: any): void
}

const RecipeIcon = ({ recipe, onClick, disabled = false }: RecipeIconProps) => {
  const matchedCat = productCategories.find((c) => c.value === recipe.category)
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={styles.imageWrapper}
    >
      <ServerImage fileId={recipe.featuredFile?.fileId} alt={recipe.name}>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon icon={faListOl} />
        </div>
      </ServerImage>
      {matchedCat && (
        <span className={styles.catIcon}>
          <FontAwesomeIcon icon={matchedCat.icon} />
        </span>
      )}
    </button>
  )
}

export default RecipeIcon
