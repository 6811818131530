import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { getGPReports } from "services/gp-report"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import GPReportItem from "components/gp-reports/GPReportItem/GPReportItem"
import GPReportModal from "components/gp-reports/GPReportModal/GPReportModal"
import { ModalContext } from "context/modal/ModalContext"

import * as styles from "./GPReports.module.css"
import usePermissions from "hooks/usePermissions"
import { faChartBar } from "@fortawesome/pro-duotone-svg-icons"
import BaseList from "components/baseList/BaseList"
import usePagination from "hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { getGpReportsDummy } from "services/dummy"

const GPReports = () => {
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [gpReportsData, setGPReportsData] = useState({
    content: [],
  })

  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)

  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("GP Analysis")

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setGPReportsData({ content: [] })
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: "createdAt,desc",
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getGPReports(params))

    if (result && !result.error) {
      setGPReportsData({ ...gpReportsData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const openCreateReportModal = () =>
    modal.showModal(GPReportModal, {
      refreshData: getData,
    })

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getData()
  }, [selectedOrganizations])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  return (
    <>
      <Helmet>
        <title>GP Analysis Report</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="GP Analysis Report" />

        <div className={styles.subHeader}>
          <LocationsSelector />

          <button
            type="button"
            disabled={!permissionObj?.permissions.modify}
            className="button my-2 ml-auto button--autoWidth button--primaryGreen"
            onClick={() => openCreateReportModal()}
          >
            <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
            <span className="hidden lg:inline">New GP Analysis</span>
          </button>
        </div>

        <div className={styles.content}>
          <BaseList
            title="GP Reports"
            id="gp-reports"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faChartBar,
              color: "green",
              getDummy: () => getGpReportsDummy,
            }}
            data={gpReportsData.content}
            rowRender={(gpReport) => (
              <GPReportItem
                key={gpReport.id}
                item={gpReport}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default GPReports
