import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { getStockTakeSheets } from "services/stock-take/stock-take"
import { uuidv4 } from "services/helpers"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import StockTakeSheetItem from "components/stock/StockTakeSheetItem/StockTakeSheetItem"
import { GlobalDispatchContext } from "context/global/GlobalContextProvider"
import StockTakeSheetModal from "components/stock/StockTakeSheet/StockTakeSheet"

import usePermissions from "hooks/usePermissions"
import BaseList from "components/baseList/BaseList"
import { faList } from "@fortawesome/pro-duotone-svg-icons"
import { getReportSheetsDummy } from "services/dummy"
import usePagination from "hooks/usePagination"
import { useModals } from "hooks/useModals"

import * as styles from "./StockTakeSheets.module.css"

// The modals config should be placed outside the functional component
// because useModals hook uses the modals config as a dependency and
// it will cause an infinite re-render if the modals config is placed inside
// the functional component.
const modalConfig = [
  {
    id: "editStockTakeSheet",
    comp: StockTakeSheetModal,
    props: {},
  },
]

const StockTakeSheets = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [stockTakeSheetsData, setStockTakeSheetsData] = useState({
    content: [],
  })
  const permissionObj = usePermissions("Stocktakes")
  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)
  const { showModal, RenderModals } = useModals(modalConfig)

  const getData = async () => {
    setLoading(true)

    const params: { page: number; sort: string } = {
      page: pagination.page,
      sort: "createdAt,desc",
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getStockTakeSheets(params))

    if (result && !result.error) {
      setStockTakeSheetsData({ ...stockTakeSheetsData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleNewSheet = (e) => {
    e.preventDefault()
    dispatch({ type: "RESET_NEW_STOCKTAKE_SHEET" })
    dispatch({
      type: "CREATE_NEW_STOCKTAKE_SHEET",
      payload: {
        stockTakeSheetData: {
          name: "New sheet",
          key: uuidv4(),
          unsynced: false,
          products: [],
        },
      },
    })
    showModal("editStockTakeSheet", { onUpdate: () => getData() })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <div className="flex flex-col my-2 mr-2 ml-0">
            <p className="text-gray-700 text-sm md:text-base">
              Use <strong>Stocktake sheets</strong> to make a default list of
              products you need to check while doing a stocktake
            </p>
          </div>
          <button
            type="button"
            className="button my-2 ml-auto button--autoWidth button--primaryGreen flex-shrink-0"
            disabled={!permissionObj?.permissions.modify}
            onClick={handleNewSheet}
          >
            <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
            <span className="hidden lg:inline">New sheet</span>
          </button>
        </div>

        <div className={styles.content}>
          <BaseList
            className={"sheets"}
            title="Sheets"
            id="sheets"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faList,
              color: "blue",
              getDummy: () => getReportSheetsDummy,
            }}
            data={stockTakeSheetsData.content}
            rowRender={(sheet) => (
              <StockTakeSheetItem
                key={sheet.id}
                sheet={sheet}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
              />
            )}
          />
        </div>
      </div>

      <RenderModals />
    </>
  )
}

export default StockTakeSheets
