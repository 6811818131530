import React, { useState } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import ProductListWithCaseToggle from "components/products/ProductListWithCaseToggle/ProductListWithCaseToggle"
import LocationSelection from "../LocationSelection/LocationSelection"
import Select from "react-select"
import Loader from "../Loader/Loader"
import RecipeItemName from "components/recipes/RecipeItemName/RecipeItemName"
import DishItemName from "components/dishes/DishItemName/DishItemName"

interface ModalAssignLocationsProps {
  onSubmit(
    groupIds: string[],
    orgIds: string[],
    action: "assign" | "unassign"
  ): Promise<any>
  items: any[]
  type: "item" | "recipe" | "dish"
  showActionPicker?: boolean
  initialOrgs?: string[]
}

const ModalAssignLocations = (config: ModalAssignLocationsProps) => {
  const [visible, setvisible] = useState(true)
  const [action, setAction] = useState<"assign" | "unassign" | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { onSubmit, type, showActionPicker = true, initialOrgs } = config
  const currentOrgs = initialOrgs || []
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>(currentOrgs)

  /**
   * There are 2 usecases here
   * 1. Single select: the users selects only 1 item and manages its locations.
   *    - we can show the current assigned locations and can update the locations on change of select boxes
   * 2. Multiselect: the users selects multiple items and needs to ASSIGN/UNASSIGN all items in bulk from/to selected locations
   *    - we can't show the current assigned locations as this can differ per item.
   */

  const isSingleItem = config.items.length === 1

  const extraSelectorProps: any = isSingleItem
    ? {
        itemId: config.items[0].id,
      }
    : null

  if (type === "item" && isSingleItem) {
    const item = config.items[0]
    extraSelectorProps.groupId = item.groupId
  }

  const [items, setitems] = useState(
    config.items.map((a) => {
      return config.type === "item"
        ? {
            ...a,
            _isPackSelected: false,
          }
        : a
    })
  )

  const handleSubmit = () => {
    if (!action) {
      return
    }
    const groupIds = items.map((b) => b.groupId)
    setLoading(true)
    onSubmit(groupIds, selectedOrgs, action).then((response) => {
      setLoading(false)
      if (response?.status?.toString().startsWith("4")) {
        toast.error(
          response.message ||
            "Something went wrong in the process of this request"
        )
      } else if (!response?.status?.toString().startsWith("4")) {
        toast.success(response.message || "Successfully applied changes")
        closeModal()
      } else {
        closeModal()
      }
    })
  }

  const closeModal = () => {
    setvisible(false)
  }

  const selectOptions = [
    {
      label: "Assign locations",
      value: "assign",
    },
    {
      label: "Unassign locations",
      value: "unassign",
    },
  ]

  return (
    visible && (
      <Modal
        isOpen
        onRequestClose={closeModal}
        style={{
          overlay: {
            display: "flex !important",
            alignItems: "start !important",
          },
          content: {
            display: "flex",
            width: "100%",
            height: "auto !important",
            maxWidth: 800,
            bottom: "none !important",
            top: "none !important",
          },
        }}
      >
        <div className="flex flex-col h-full">
          <header className="flex flex-col px-6 py-4 border-b">
            <h3 className="text-xl sm:text-xl mb-1">Manage locations</h3>
            <p className="text-gray-600">
              Manage the locations of the following items
            </p>
          </header>
          <div className="flex justify-between flex-col sm:flex-row flex-grow flex-1 overflow-auto">
            <div className="sm:flex-1 bg-paleBlue bg-opacity-25 border-r flex flex-col overflow-auto">
              {/* Left Column -  Item List */}
              {type === "item" && (
                <ProductListWithCaseToggle items={items} setitems={setitems} />
              )}
              {type === "recipe" &&
                items.map((item) => (
                  <div className="border-b bg-white" key={item.id}>
                    <RecipeItemName recipe={item} />
                  </div>
                ))}
              {type === "dish" &&
                items.map((item) => (
                  <div className="border-b bg-white" key={item.id}>
                    <DishItemName dish={item} />
                  </div>
                ))}
            </div>
            <div className="sm:flex-1 border-t sm:border-t-0 flex flex-col space-y-3 p-4 sticky overflow-auto">
              <Loader
                isLoading={loading}
                style={{ backgroundColor: "#ffffff" }}
              >
                Processing..
              </Loader>
              {showActionPicker && (
                <div className="mb-2">
                  <h2 className="text-primaryBlue font-sansBold mb-3">
                    Action:
                  </h2>
                  <p className="text-primaryBlueLighter mb-2">
                    Please select your action first
                  </p>
                  <Select
                    options={selectOptions}
                    id="action"
                    isClearable
                    isSearchable={false}
                    placeholder="Select an action"
                    value={
                      action
                        ? selectOptions.find((o) => o.value === action)
                        : null
                    }
                    onChange={(val) => setAction(val?.value || null)}
                  />
                </div>
              )}
              <div className={action || isSingleItem ? "" : "hidden"}>
                <LocationSelection
                  itemType={type}
                  currentSelection={selectedOrgs}
                  onSelectionChange={(orgs) => setSelectedOrgs(orgs)}
                  {...extraSelectorProps}
                />
              </div>
              {selectedOrgs.length && showActionPicker && action ? (
                <div
                  className={`${
                    action === "assign"
                      ? "bg-primaryGreenPale border-primaryGreenLighter bg-opacity-50 text-primaryGreenDarker"
                      : "bg-red-500 border-red-500 bg-opacity-25 text-red-800"
                  } border-opacity-25  rounded-md p-3 border text-sm`}
                >
                  <strong>{items.length}</strong> items will be{" "}
                  <strong>{action === "unassign" ? "un" : ""}assigned </strong>
                  {action === "unassign" ? "from" : "to"} the above locations
                </div>
              ) : null}
            </div>
          </div>
          <footer className="flex justify-between space-x-6 p-3 border-t font-semibold">
            <button onClick={closeModal} className="text-gray-700">
              Cancel
            </button>
            {!isSingleItem && (
              <button
                onClick={handleSubmit}
                disabled={!selectedOrgs.length || !action || loading}
                className="button button--primaryGreen button--autoWidth"
              >
                Apply changes
              </button>
            )}
          </footer>
        </div>
      </Modal>
    )
  )
}

export default ModalAssignLocations
