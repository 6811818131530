import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { useLocation } from "@reach/router"

import { helpVideos } from "services/constants"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

const VideoModal = ({ onRequestClose, ...otherProps }) => {
  const { pathname } = useLocation()

  const renderCorrectVideo = (pathname) => {
    const video = helpVideos.find((video) => video.pathname === pathname)

    if (!video) {
      return "no source"
    }

    return video.src
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      portalClassName="videoModal"
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className="flex flex-col items-center justify-center p-6">
        <h1 className="text-xl mb-4 w-full text-left">Need help?</h1>
        <iframe
          width="100%"
          height="350px"
          src={renderCorrectVideo(pathname)}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  )
}

VideoModal.propTypes = {
  onRequestClose: PropTypes.func,
}

export default VideoModal
