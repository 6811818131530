import { get, post, remove, patch } from "./api"

export interface XeroSupplier {
  email?: string
  id: string
  name: string
}

export interface XeroLineItem {
  account: XeroAccount | undefined
  description: string
  netCost: number
}

export interface XeroAccount {
  code: string
  description?: string
  id: string
  name: string
  type: string
}

export interface XeroTaxRate {
  taxType: string
  name: string
  taxRate: number
}

export const getXeroClientId = () => {
  //   return Promise.resolve("B772389903EB4BF9B2C6C1A5BACA5D64")
  return get(`/xero-integrations/client-id`, {}, { plain: true })
}

export const redirectToXeroAuthorization = (clientId: string) => {
  const url = typeof window !== "undefined" ? window.location.origin : ""
  const redirect = `${url}/dashboard/company-settings?tab=integrations&callback=xero`
  const rootXeroUrl = "https://login.xero.com"
  const xeroAuthUrl = `${rootXeroUrl}/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirect
  )}&scope=${encodeURIComponent(
    "offline_access accounting.reports.read accounting.transactions accounting.contacts accounting.attachments accounting.settings"
  )}`

  // Redirect
  window.location.href = xeroAuthUrl
}

export const createXeroIntegration = (params) => {
  return post("/xero-integrations", params)
}

export const getXeroIntegration = (integrationId: string, config?: any) => {
  return get(`/xero-integrations/${integrationId}`, {}, config)
}

export const getXeroIntegrations = () => {
  return get(`/xero-integrations`)
}

export const getXeroConnections = (params) => {
  return get(`/xero-integrations/connections`, params)
}

export const updateXeroConnection = (
  integrationId: string,
  connectionId: string
) => {
  return patch(`/xero-integrations/${integrationId}/connection/${connectionId}`)
}

export const removeXeroIntegration = (integrationId) => {
  return remove(`/xero-integrations/${integrationId}`)
}
