import React from "react"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"
import { AsideConsumer } from "./AsideContext"
import * as styles from "./AsideRoot.module.css"
import classNames from "classnames"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AsideRoot = () => {
  return (
    <AsideConsumer>
      {({ component: Component, props, hideAside, isOpen }) => {
        return (
          <>
            <CSSTransition
              in={isOpen ? true : false}
              timeout={200}
              classNames="aside-overlay"
              unmountOnExit
            >
              <div
                className={styles.overlay}
                style={props.zIndex ? { zIndex: props.zIndex } : null}
                onClick={hideAside}
                role="button"
                aria-hidden="true"
              ></div>
            </CSSTransition>
            <CSSTransition
              in={isOpen ? true : false}
              timeout={400}
              classNames="aside"
              unmountOnExit
            >
              <div
                className={classNames(styles.aside, {
                  [styles.fullWidth]: props.fullWidth,
                })}
                style={props.zIndex ? { zIndex: props.zIndex + 1 } : null}
              >
                <header
                  className={`flex items-center justify-between w-full border-b px-6 text-primaryBlue h-16 flex-shrink-0`}
                >
                  <button
                    type="button"
                    className="mr-4 flex items-center space-x-4 hover:opacity-75 flex-shrink-0"
                    onClick={(e) => {
                      e.preventDefault()
                      /* onClose() */ typeof props.onClose === "function" &&
                        props.onClose()
                      hideAside()
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                    <h4 className="text-xl">{props.headerText || "Go back"}</h4>
                  </button>
                  {props.headerButton && props.headerButton}
                </header>
                {Component ? (
                  <Component
                    {...props}
                    onClose={(data) => {
                      hideAside()
                      if (props.onClose) {
                        props.onClose(data)
                      }
                    }}
                  />
                ) : null}
              </div>
            </CSSTransition>
          </>
        )
      }}
    </AsideConsumer>
  )
}

AsideRoot.propTypes = {
  onClose: PropTypes.func,
}

export default AsideRoot
