import React from "react"
import BaseTable, { AutoResizer, ColumnShape } from "react-base-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { useMediaQuery } from "react-responsive"

//@ts-ignore
import * as styles from "./ExpandableCategorisedProductTable.module.css"

interface ExpandableCategorisedProductTableProps {
  data: { categorisedProducts: object[] }
  columns: ColumnShape<object>[]
  productValueMap: (props: object) => object
}

const ExpandableCategorisedProductTable = ({
  data,
  columns,
  productValueMap = () => {
    return {}
  },
}: ExpandableCategorisedProductTableProps) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const assignRowClassName = (rowData) => {
    if (rowData.id.includes("subCatProd")) {
      return "subCatProdRow"
    } else if (rowData.id.includes("prod")) {
      return "prodRow"
    } else if (rowData.id.includes("subRow")) {
      return "subRow"
    } else {
      return "catRow"
    }
  }

  const formatData = (data) => {
    const allProducts = [...data.categorisedProducts]

    if (data.uncategorisedProducts) {
      allProducts.push({
        ...data.uncategorisedProducts,
      })
    }

    const formatProducts = (data, index, subIndex?) => {
      return data.products.map((prod, prodIndex) => {
        const prodValueMapObj = productValueMap(prod)
        return {
          ...prod,
          ...prodValueMapObj,
          id: `${data.id}-row-${index}-subRow-${
            !isNaN(subIndex) ? subIndex : 0
          }${
            !isNaN(subIndex) ? "-subCatProd-" + prodIndex : "-prod-" + prodIndex
          }`,
        }
      })
    }

    const rows = allProducts.map((row, index) => {
      const hasSubCats = !!row.categorisedProducts

      const subRows = hasSubCats
        ? row.categorisedProducts.map((subRow, subIndex) => {
            const subCatProducts = formatProducts(subRow, index, subIndex)
            const subCat = {
              ...subRow.categoryTotals,
              children: subCatProducts,
              category: row.categoryTotals.name,
              id: `${data.id}-row-${index}-subRow-${subIndex}`,
            }
            return subCat
          })
        : formatProducts(row, index)

      const cat = {
        ...row.categoryTotals,
        id: `${data.id}-row-${index}`,
        children: subRows,
      }
      return cat
    })

    return rows.flat()
  }

  return (
    <div className="w-full h-full flex-grow overflow-x-auto">
      <AutoResizer width={Infinity} height={Infinity}>
        {({ width, height }) => (
          /* eslint-disable */
          <BaseTable
            className={styles.expandableCategorisedTable}
            fixed={isTabletOrMobile}
            estimatedRowHeight={40}
            headerHeight={40}
            width={width}
            height={height}
            data={formatData(data)}
            columns={columns}
            ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
            rowClassName={({ rowData }) => assignRowClassName(rowData)}
            expandColumnKey={"name"}
            components={{
              ExpandIcon: (props) => {
                // eslint-disable-next-line react/prop-types
                const { expandable, expanded, onExpand } = props

                if (!expandable) {
                  return null
                }

                return (
                  <FontAwesomeIcon
                    className="cursor-pointer text-primaryBlueLighter mr-2"
                    size="sm"
                    icon={expanded ? faChevronUp : faChevronDown}
                    onClick={() => {
                      onExpand(!expanded)
                    }}
                  />
                )
              },
            }}
            expandIconProps={({ rowIndex }) => ({
              rowIndex: rowIndex,
            })}
            emptyRenderer={
              <div className={styles.noProducts}>No products found</div>
            }
          />
          /* eslint-enable */
        )}
      </AutoResizer>
    </div>
  )
}

export default ExpandableCategorisedProductTable
