import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import Dots from "components/common/Dots/Dots"
import { capitaliseFirstLetter, objHasValue } from "services/helpers"
import CombinedAddressForm from "components/common/CombinedAddressForm/CombinedAddressForm"

interface AddressDetailsProps {
  selectedAccountType: any
  address: any
  deliveryAddress: any
  onAddressChange: Function
  onDeliveryAddressChange: Function
  prevStep: Function
  nextStep: Function
  dotCount?: number
}

const AddressDetails = ({
  selectedAccountType,
  address,
  deliveryAddress,
  onAddressChange,
  onDeliveryAddressChange,
  prevStep,
  nextStep,
  dotCount,
}: AddressDetailsProps) => {
  const [addressValid, setAddressValid] = useState(false)
  const [deliveryAddressValid, setDeliveryAddressValid] = useState(false)

  const formChange = (data, type) => {
    if (type === "address") {
      onAddressChange({ nextValues: data })
    }
    if (type === "deliveryAddress") {
      onDeliveryAddressChange({ nextValues: data })
    }
  }

  const validChange = (isValid, type) => {
    if (type === "address") {
      setAddressValid(isValid)
    }
    if (type === "deliveryAddress") {
      setDeliveryAddressValid(isValid)
    }
  }

  const nextEnabled = () => {
    return objHasValue(deliveryAddress)
      ? addressValid && deliveryAddressValid
      : addressValid
  }

  return (
    <div className="flex flex-col">
      <h1 className="text-center text-4xl mb-3">
        Your{" "}
        {capitaliseFirstLetter(
          selectedAccountType.type === "enterprise"
            ? "Business"
            : selectedAccountType.type
        )}{" "}
        Address
      </h1>
      <p className="text-center text-lg mb-4 text-gray-700">
        {selectedAccountType.type === "enterprise"
          ? "For multi-site business, different addresses can be provided when adding the business locations at the next step"
          : "Please provide your address details to get the most out of your orders"}
      </p>
      <CombinedAddressForm
        onValidChange={validChange}
        selectTimeZone={true}
        initialAddress={address}
        showCompanyName={false}
        initialDeliveryAddress={deliveryAddress}
        handleLocationDataChange={formChange}
        isNullable
        selectCountry
        allowNullable
      />

      <div className="flex px-0 mt-6 justify-between">
        <button
          type="button"
          className="font-sansBold font-bold text-gray-700"
          onClick={() => prevStep()}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="md:mr-4" />
          <span className="hidden md:inline">Previous</span>
        </button>
        <Dots index={1} count={dotCount ?? 2} />
        <button
          type="button"
          className="button button--autoWidth bg-primaryPink"
          disabled={!nextEnabled()}
          onClick={() => nextStep()}
        >
          <span className="hidden md:inline">Continue</span>
          <FontAwesomeIcon icon={faArrowRight} className="md:ml-4" />
        </button>
      </div>
    </div>
  )
}

export default AddressDetails
