import { showError } from "services/toast"
import { get, post, remove } from "./api"

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT

export const _getClientId = () => {
  return get(`/clover-pos-integrations/oauth-settings`)
}

export const authorizeWithClover = async () => {
  if (typeof window === "undefined") {
    return
  }

  const { clientId } = await _getClientId()
  if (!clientId) {
    return showError("Something went wrong - client ID not found")
  }
  const url = typeof window !== "undefined" ? window.location.origin : ""
  const redirect = `${url}/dashboard/company-settings?tab=integrations&callback=clover`

  const rootCloverUrl =
    ENVIRONMENT === "production"
      ? "https://eu.clover.com"
      : "https://sandbox.dev.clover.com"
  const cloverAuthUrl = `${rootCloverUrl}/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirect
  )}`

  // Redirect
  window.location.href = cloverAuthUrl
}

export const createCloverIntegration = (params) => {
  return post("/clover-pos-integrations", params)
}

export const getCloverIntegrations = () => {
  return get("/clover-pos-integrations")
}

export const getCloverIntegration = (
  cloverIntegrationId: string,
  config?: {}
) => {
  return get(`/clover-pos-integrations/${cloverIntegrationId}`, config)
}

export const deleteCloverIntegration = (cloverIntegrationId: string) => {
  return remove(`/clover-pos-integrations/${cloverIntegrationId}`)
}
