/* eslint-disable react/prop-types */
import React, { ReactElement, Dispatch, SetStateAction } from "react"
import ReactTooltip from "react-tooltip"
import { faSync } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BodyPortal from "components/common/BodyPortal/BodyPortal"

interface Props {
  setImportData: Dispatch<SetStateAction<never[]>>
  keys: { label: string; value: string }[]
  storedKeysStatic: {}
  setStoredKeys: Dispatch<SetStateAction<{}>>
  emptyStoredKeys: {}
  setHiddenIndexes: Dispatch<SetStateAction<{}>>
  formattingHasError?: boolean
  injectTopRight?: ReactElement
  injectMiddle?: ReactElement
  removeFirstRow: boolean
  setRemoveFirstRow: Dispatch<SetStateAction<boolean>>
}

const EditableImportHeader = ({
  setImportData,
  keys,
  storedKeysStatic,
  setStoredKeys,
  emptyStoredKeys,
  setHiddenIndexes,
  formattingHasError,
  injectTopRight,
  injectMiddle,
  removeFirstRow,
  setRemoveFirstRow,
}: Props) => {
  return (
    <>
      <div className="mb-4 flex justify-between items-center w-full">
        <button
          className="text-primaryGreen flex-shrink-0 mr-4"
          onClick={() => {
            setImportData([])
            setHiddenIndexes({})
            setStoredKeys(storedKeysStatic)
          }}
        >
          <FontAwesomeIcon className="mr-2" icon={faSync} />
          Re-upload file
        </button>
        {injectTopRight}
      </div>
      {injectMiddle}
      <div className="flex justify-between">
        <div className="text-sm text-red-700">
          {emptyStoredKeys.length > 0 && (
            <p className="mb-4">
              Please assign the following fields to import:{" "}
              {emptyStoredKeys.map((key, index) => {
                const required = keys.find(
                  (requiredKey) => requiredKey.value == key
                )
                return (
                  <React.Fragment key={`stored-key-error${index}`}>
                    <b>{required?.label} </b>
                    {index < emptyStoredKeys.length - 1 ? ", " : ""}
                  </React.Fragment>
                )
              })}
            </p>
          )}

          {formattingHasError && (
            <p className="mb-4 ">
              Formatting errors found in document. Ensure header rows from the
              imported file are deleted.
            </p>
          )}
        </div>
        <BodyPortal id="removeHeaderTooltipPortal">
          <ReactTooltip
            id={"removeHeaderTooltip"}
            type="light"
            place="top"
            effect="solid"
            border={true}
            borderColor="#e2e8f0"
          />
        </BodyPortal>
        <label
          className="text-sm mb-4"
          data-tip={"Tick if the imported file has a header row"}
          data-for={"removeHeaderTooltip"}
        >
          Remove header row
          <input
            type="checkbox"
            onChange={() => {
              setRemoveFirstRow(!removeFirstRow)
            }}
            name="itemSelect"
            className="form-checkbox text-primaryPink ml-2"
            checked={removeFirstRow}
          />
        </label>
      </div>
    </>
  )
}

export default EditableImportHeader
