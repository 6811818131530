// extracted by mini-css-extract-plugin
export var acceptBox = "ConfirmDetailsItem-module--acceptBox--G5wvU";
export var actions = "ConfirmDetailsItem-module--actions--4mqbY";
export var active = "ConfirmDetailsItem-module--active--MYbwZ";
export var checked = "ConfirmDetailsItem-module--checked--BTxge";
export var confirmButtonHover = "ConfirmDetailsItem-module--confirmButtonHover--JhuEh";
export var containerCheckbox = "ConfirmDetailsItem-module--containerCheckbox--PMISZ";
export var hasDiscrepancy = "ConfirmDetailsItem-module--hasDiscrepancy--bO5lh";
export var item = "ConfirmDetailsItem-module--item--C4m3e";
export var itemAmount = "ConfirmDetailsItem-module--itemAmount--KO-gv";
export var itemConfirm = "ConfirmDetailsItem-module--itemConfirm--q1YFK";
export var itemCount = "ConfirmDetailsItem-module--itemCount--j3f61";
export var itemEdit = "ConfirmDetailsItem-module--itemEdit--CuLEE";
export var itemLabel = "ConfirmDetailsItem-module--itemLabel--WXijA";
export var itemMeasure = "ConfirmDetailsItem-module--itemMeasure--XOCZc";
export var itemName = "ConfirmDetailsItem-module--itemName--0s6NP";
export var itemPrimary = "ConfirmDetailsItem-module--itemPrimary--ZLwMJ";
export var itemQty = "ConfirmDetailsItem-module--itemQty--pAg8o";
export var itemStatus = "ConfirmDetailsItem-module--itemStatus--j9CzT";
export var itemStatusError = "ConfirmDetailsItem-module--itemStatusError--LqAvI ConfirmDetailsItem-module--itemStatus--j9CzT";
export var itemWrapper = "ConfirmDetailsItem-module--itemWrapper--N0avU";
export var manualBadge = "ConfirmDetailsItem-module--manualBadge--6oVy9";
export var mathSymbol = "ConfirmDetailsItem-module--mathSymbol--eouQ+";
export var quantityFigure = "ConfirmDetailsItem-module--quantityFigure--RdnLh";