import React from "react"
import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { convertTimeStampDate } from "services/helpers"
import { OrderTemplateWithRecurrence } from "services/order-templates/types"
import { formatRecurrenceDescription } from "services/order-templates/helpers"

interface Props {
  orderTemplate: OrderTemplateWithRecurrence
}

const StandingOrderItemFrequency = ({ orderTemplate }: Props) => {
  const startDate = orderTemplate.recurrence?.startDate
  const endDate = orderTemplate.recurrence?.endDate

  const recurrenceDescription = formatRecurrenceDescription(
    orderTemplate.recurrence
  )

  return (
    <div className="w-auto">
      <p className="font-bold text-gray-800">{recurrenceDescription}</p>
      <div className="flex text-gray-700 text-sm">
        <FontAwesomeIcon
          icon={faCalendarCheck}
          size="sm"
          className="mt-1 mr-2"
        />
        <div>
          {startDate && <div>{convertTimeStampDate(startDate)}</div>}
          {endDate && <div>{convertTimeStampDate(endDate)}</div>}
        </div>
      </div>
    </div>
  )
}

export default StandingOrderItemFrequency
