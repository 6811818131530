import React, { useRef, useState, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faCloudUpload,
  faPlus,
} from "@fortawesome/pro-light-svg-icons"
import Webcam from "react-webcam"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { base64toFile, getDeviceOrientation } from "services/helpers"
import * as styles from "./DocumentCamera.module.css"

const cx = classNames.bind(styles)

const DocumentCamera = ({
  onCapture,
  onAddManually,
  blur = false,
  accept = "image/*",
  cameraEnabled,
}) => {
  const webcamRef = useRef(null)
  const fileRef = useRef(null)
  const [notSupported, setNotSupported] = useState(false)
  const orientation = getDeviceOrientation()

  const videoConstraints = {
    width: 1920,
    height: 1080,
    aspectRatio: orientation === "PORTRAIT" ? 9 / 16 : 16 / 9,
    facingMode: "environment",
  }

  const captureFile = useCallback(
    (ev) => {
      let file = ev.target.files[0]
      // eslint-disable-next-line no-undef
      const reader = new FileReader()

      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          onCapture(reader.result, file)
        },
        false
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    [onCapture]
  )

  const capture = useCallback(async () => {
    const dataUriSrc = webcamRef.current.getScreenshot()
    const file = await base64toFile(dataUriSrc)
    onCapture(dataUriSrc, file)
  }, [webcamRef, onCapture])
  return (
    <>
      {cameraEnabled && (
        <Webcam
          audio={false}
          // eslint-disable-next-line no-undef
          height={window.innerHeight}
          ref={webcamRef}
          forceScreenshotSourceSize={true}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          // eslint-disable-next-line no-undef
          width={window.innerWidth}
          className={cx("camera", { blur: blur })}
          onUserMediaError={() => setNotSupported(true)}
          videoConstraints={videoConstraints}
        />
      )}
      <input
        type="file"
        ref={fileRef}
        accept={accept}
        style={{ visibility: "hidden" }}
        onChange={captureFile}
      />
      {notSupported && (
        <div className={styles.notSupported}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          Camera access is not supported on this device, please upload a file
          <button
            className={styles.notSupportedBtn}
            onClick={() => {
              fileRef.current.click()
            }}
          >
            <FontAwesomeIcon className="mr-3" icon={faCloudUpload} />
            Upload file
          </button>
        </div>
      )}
      {!notSupported && (
        <div className={styles.introContainer}>
          <div className={styles.intro}>
            <div className={styles.introIcon} /> Point the camera at your
            document
          </div>
        </div>
      )}

      <div className={styles.scanActions}>
        <button
          className={cx("scanAction")}
          onClick={() => {
            return onAddManually()
          }}
        >
          <FontAwesomeIcon className="mb-2" size="lg" icon={faPlus} />
          Add manually
        </button>
        <button
          aria-label="Scan"
          onClick={capture}
          className={styles.scanBtn}
        />
        <button
          onClick={() => {
            fileRef.current.click()
          }}
          className={cx("scanAction")}
        >
          <FontAwesomeIcon className="mb-2" size="lg" icon={faCloudUpload} />
          Upload file
        </button>
      </div>
    </>
  )
}

DocumentCamera.propTypes = {
  onCapture: PropTypes.func,
  onAddManually: PropTypes.func,
  blur: PropTypes.bool,
  accept: PropTypes.string,
  cameraEnabled: PropTypes.bool,
}

export default DocumentCamera
