import React, { useState, useEffect, useContext } from "react"
import { usePromise } from "react-use"
import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import { AsideContext } from "context/aside/AsideContext"
import EditSupplier from "components/suppliers/EditSupplier/EditSupplier"
import PropTypes from "prop-types"
import Products from "components/products/Products/Products"
import { getSupplierById } from "services/suppliers/suppliers"
import { countries, currencies } from "services/constants"
import Helmet from "react-helmet"
import * as styles from "./SupplierDetails.module.css"
import usePermissions from "hooks/usePermissions"

const SupplierDetails = ({ supplierId }) => {
  const [supplierData, setSupplierData] = useState({})
  const permissionObj = usePermissions("Suppliers")
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(true)
  const aside = useContext(AsideContext)

  const startEditSupplier = (supplier) => {
    aside.showAside(EditSupplier, {
      initialValues: supplier,
      headerText: supplier.id ? "Edit supplier" : "Create new supplier",
      onClose: onAsideClose,
    })
  }

  const getData = async () => {
    setLoading(true)

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getSupplierById(supplierId))

    if (result && !result.error) {
      setSupplierData(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const onAsideClose = (result) => {
    if (result === "supplier-updated") {
      getData()
    }
  }

  const filteredDeliveryDays = (deliveryDays) => {
    return Object.keys(deliveryDays)
      .map((key) => (deliveryDays[key] === true ? key : null))
      .filter((item) => item !== null)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Helmet>
        <title>Supplier details</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Supplier details" />

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading supplier..
          </Loader>
          {!loading && (
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <div className={styles.headerTopColumn}>
                  <h1 className="text-xl mb-4 mr-auto">{supplierData.name}</h1>
                  <div className={styles.headerRight}>
                    <button
                      className="button button--autoWidth button--primaryBlue"
                      onClick={(e) => {
                        e.preventDefault()
                        startEditSupplier(supplierData)
                      }}
                      disabled={!permissionObj?.permissions.modify}
                    >
                      Edit
                    </button>
                  </div>
                </div>

                <div className={styles.headerColumns}>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/5 mb-4">
                    <h2 className="mb-3 text-gray-800">Contact details</h2>
                    <span className="break-all">
                      {supplierData.contactName}
                    </span>
                    <a
                      className="break-all text-primaryBlue font-sansSemiBold font-semibold"
                      href={`mailto:${supplierData.email}`}
                    >
                      {supplierData.email}
                    </a>
                    {supplierData.telephone && (
                      <a
                        className="break-all text-primaryBlue font-sansSemiBold font-semibold"
                        href={`tel:${supplierData.telephone}`}
                      >
                        {supplierData.telephone}
                      </a>
                    )}
                    {supplierData.website && (
                      <a
                        className="text-primaryPink break-words font-sansSemiBold font-semibold"
                        href={supplierData.website}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Visit website
                      </a>
                    )}
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/5 mb-4">
                    <h2 className="mb-3 text-gray-800">Purchasing info</h2>
                    {!supplierData.ordersContact && (
                      <div className="mb-2">
                        <span>Orders: </span>
                        <div className="flex flex-col">
                          <a
                            className="text-primaryBlue break-all font-sansSemiBold font-semibold"
                            href={`mailto:${supplierData.email}`}
                          >
                            {supplierData.email}
                          </a>
                        </div>
                      </div>
                    )}

                    {supplierData.ordersContact && (
                      <div className="mb-2">
                        <span>Orders: </span>
                        <div className="flex flex-col">
                          {supplierData.ordersContact.emails.length > 0 &&
                            supplierData.ordersContact.emails.map(
                              (email, index) => {
                                return (
                                  <a
                                    className="text-primaryBlue break-all font-sansSemiBold font-semibold"
                                    href={`mailto:${email}`}
                                    key={`ordersEmail${index}`}
                                  >
                                    {email}
                                  </a>
                                )
                              }
                            )}
                          <a
                            className="text-primaryBlue font-sansSemiBold font-semibold"
                            href={`tel:${supplierData.ordersContact.telephone}`}
                          >
                            {supplierData.ordersContact.telephone}
                          </a>
                        </div>
                      </div>
                    )}

                    {!supplierData.deliveriesContact && (
                      <div className="mb-2">
                        <span>Deliveries: </span>
                        <div className="flex flex-col">
                          <a
                            className="text-primaryBlue break-all font-sansSemiBold font-semibold"
                            href={`mailto:${supplierData.email}`}
                          >
                            {supplierData.email}
                          </a>
                        </div>
                      </div>
                    )}

                    {supplierData.deliveriesContact && (
                      <div className="mb-2">
                        <span>Deliveries: </span>
                        <div className="flex flex-col">
                          {supplierData.deliveriesContact.emails.length > 0 &&
                            supplierData.deliveriesContact.emails.map(
                              (email, index) => {
                                return (
                                  <a
                                    className="text-primaryBlue break-all font-sansSemiBold font-semibold"
                                    href={`mailto:${email}`}
                                    key={`deliveriesEmail${index}`}
                                  >
                                    {email}
                                  </a>
                                )
                              }
                            )}
                          <a
                            className="text-primaryBlue font-sansSemiBold font-semibold"
                            href={`tel:${supplierData.deliveriesContact.telephone}`}
                          >
                            {supplierData.deliveriesContact.telephone}
                          </a>
                        </div>
                      </div>
                    )}

                    {!supplierData.invoicesContact && (
                      <div className="mb-2">
                        <span>Invoices: </span>
                        <div className="flex flex-col">
                          <a
                            className="text-primaryBlue break-all font-sansSemiBold font-semibold"
                            href={`mailto:${supplierData.email}`}
                          >
                            {supplierData.email}
                          </a>
                        </div>
                      </div>
                    )}

                    {supplierData.invoicesContact && (
                      <div>
                        <span>Invoices: </span>
                        <div className="flex flex-col">
                          {supplierData.invoicesContact.emails.length > 0 &&
                            supplierData.invoicesContact.emails.map(
                              (email, index) => {
                                return (
                                  <a
                                    className="text-primaryBlue break-all font-sansSemiBold font-semibold"
                                    href={`mailto:${email}`}
                                    key={`invoicesEmail${index}`}
                                  >
                                    {email}
                                  </a>
                                )
                              }
                            )}
                          <a
                            className="text-primaryBlue font-sansSemiBold font-semibold"
                            href={`tel:${supplierData.invoicesContact.telephone}`}
                          >
                            {supplierData.invoicesContact.telephone}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/5 mb-4">
                    <h2 className="mb-3 text-gray-800">Address</h2>
                    {supplierData.address && (
                      <span>{supplierData.address.addressLine1}</span>
                    )}
                    {supplierData.address &&
                    supplierData.address.addressLine2 ? (
                      <span>{supplierData.address.addressLine2}</span>
                    ) : null}
                    {supplierData.address && (
                      <span>
                        {supplierData.address.city}{" "}
                        {supplierData.address.postCode}
                      </span>
                    )}
                    <span>
                      {supplierData.address
                        ? countries.find(
                            (country) =>
                              country.value === supplierData.address.country
                          )
                          ? countries.find(
                              (country) =>
                                country.value === supplierData.address.country
                            ).label
                          : supplierData.address.country || "not defined"
                        : "not defined"}
                    </span>
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/5 mb-4">
                    <h2 className="mb-3 text-gray-800">Additional info</h2>
                    <span>
                      Currency:{" "}
                      {currencies.find((c) => c.value === supplierData.currency)
                        ? currencies.find(
                            (c) => c.value === supplierData.currency
                          ).label
                        : supplierData.currency || "not defined"}
                    </span>
                    {supplierData.deliveryDays && (
                      <span>
                        Delivery days:{" "}
                        {filteredDeliveryDays(supplierData.deliveryDays).map(
                          (day, index) => (
                            <span key={index}>
                              {day +
                                (index !==
                                filteredDeliveryDays(supplierData.deliveryDays)
                                  .length -
                                  1
                                  ? ", "
                                  : "")}
                            </span>
                          )
                        )}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/5 mb-4">
                    <h2 className="mb-3 text-gray-800">Notes</h2>
                    <span>{supplierData.notes || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.supplierProducts}>
            <h2 className="text-xl mb-4 px-4 lg:px-8">Products</h2>
            {/* <SupplierProducts
              supplierId={supplierId}
              currency={supplierData.currency}
            /> */}
            <Products
              supplierId={supplierId}
              supplierData={supplierData}
              currency={supplierData.currency}
              requestPreferredOnly={false}
            />
          </div>
        </div>
      </div>
    </>
  )
}

SupplierDetails.propTypes = {
  supplierId: PropTypes.string,
}

export default SupplierDetails
