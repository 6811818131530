import React from "react"
import PropTypes from "prop-types"

import { countCompletedSteps, calculateProgress } from "services/helpers"

import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ProgressBar from "components/common/ProgressBar/ProgressBar"

import * as styles from "./CompleteProfileWidget.module.css"

const CompleteProfileWidget = ({
  user,
  steps,
  title,
  buttonText,
  actionHandler,
  svgComponent,
}) => {
  return user ? (
    <div className={styles.container}>
      {svgComponent}
      <div className={styles.message}>
        <h2 className={styles.messageTitle}>{title}</h2>
        <ProgressBar progress={calculateProgress(steps)} />
      </div>
      <div className={styles.actions}>
        <span className="font-sansSemiBold text-primaryPink mr-5">
          {`${countCompletedSteps(steps)} steps completed`}
        </span>
        <button
          onClick={actionHandler}
          className="my-2 mr-2 button button--primaryBlue button--autoWidth flex flex-shrink-0"
        >
          {buttonText}
          <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </button>
      </div>
    </div>
  ) : null
}

CompleteProfileWidget.propTypes = {
  user: PropTypes.object,
  steps: PropTypes.array,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  actionHandler: PropTypes.func,
  svgComponent: PropTypes.element,
}

export default CompleteProfileWidget
