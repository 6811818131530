import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faPencil } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ItemIcon from "components/common/OverviewItem/ItemIcon/ItemIcon"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { format } from "date-fns"
import React, { useContext } from "react"
import { convertTimeStampFormat } from "services/helpers"

interface ReportNameCellProps {
  data: any
  mainIcon: IconDefinition
  subIcon?: IconDefinition
  editEnabled?: boolean
  onEdit?: () => void
  onClick?: (data: any) => void
  iconStyles?: {
    mainIcon?: { [key: string]: string; color: string }
    subIcon?: { [key: string]: string; color: string }
  }
  disabled?: boolean
  children?: React.ReactNode
}

/**
 * A cell component for displaying a report name with a thumbnail and a
 * detailed information section.
 *
 * @param {object} data The data object containing the report information
 * @param {Icon} mainIcon The main icon to be displayed in the thumbnail
 * @param {Icon} [subIcon] The sub icon to be displayed in the thumbnail badge
 * @param {boolean} [editEnabled] Whether the edit button is enabled or not
 * @param {function} onEdit The function to be called when the edit button is clicked
 *
 * @returns {React.ReactElement}
 */
const ReportNameCell = ({
  data,
  mainIcon,
  subIcon,
  editEnabled,
  onEdit,
  onClick,
  iconStyles,
  disabled = false,
  children,
}: ReportNameCellProps) => {
  const { organization }: { organization: any } = useContext(GlobalStateContext)
  return (
    <div className="flex items-start" style={{ minWidth: "250px" }}>
      <ItemIcon
        disabled={disabled}
        mainIcon={{
          icon: mainIcon,
          color: iconStyles?.mainIcon?.color,
          backgroundColor: iconStyles?.mainIcon?.backgroundColor,
        }}
        {...(onClick && {
          onClick: () => onClick(data),
        })}
        {...(subIcon && {
          subIcon: {
            icon: subIcon,
            color: iconStyles?.subIcon?.color,
            backgroundColor: iconStyles?.subIcon?.backgroundColor,
          },
        })}
      />

      <div className="my-2 flex-grow flex flex-shrink-0 flex-col">
        {data.name && (
          <h2
            className="text-base font-sansSemiBold font-semibold leading-none mb-0"
            {...(onClick && !disabled
              ? { role: "button", onClick: () => onClick(data) }
              : {})}
          >
            {data.name}
          </h2>
        )}

        <div className="text-gray-700">
          {(data.periodFrom || data.periodTo) && (
            <span
              className="text-xs md:text-sm mr-6"
              {...(onClick && !disabled
                ? { role: "button", onClick: () => onClick(data) }
                : {})}
            >
              Period:{" "}
              <strong>{`${format(
                new Date(data.periodFrom),
                "dd/MM/yy"
              )}`}</strong>{" "}
              -{" "}
              <strong>{`${format(new Date(data.periodTo), "dd/MM/yy")}
                    `}</strong>
            </span>
          )}
        </div>
        {children || null}
        {(data.updatedAt || data.createdAt) && (
          <span className="text-xs text-gray-600">
            {data.updatedAt
              ? `Updated at  ${convertTimeStampFormat(
                  data.updatedAt,
                  organization?.address?.zoneId
                )}`
              : `Created at ${convertTimeStampFormat(
                  data.createdAt,
                  organization?.address?.zoneId
                )}`}
          </span>
        )}
      </div>
      {editEnabled && (
        <button
          type="button"
          className="self-center"
          onClick={onEdit}
          disabled={!editEnabled && !disabled}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      )}
    </div>
  )
}

export default ReportNameCell
