// extracted by mini-css-extract-plugin
export var container = "EditOrder-module--container--gDJpU";
export var content = "EditOrder-module--content--eOfek";
export var main = "EditOrder-module--main--fm09M";
export var noOrders = "EditOrder-module--noOrders--YycFz";
export var selectedSupplier = "EditOrder-module--selectedSupplier--GQXRs";
export var sidebar = "EditOrder-module--sidebar--Yssq3";
export var sidebarContent = "EditOrder-module--sidebarContent--m+IsC";
export var sidebarFooter = "EditOrder-module--sidebarFooter--tqBm5";
export var sidebarTitle = "EditOrder-module--sidebarTitle--l3iaB";
export var subHeader = "EditOrder-module--subHeader--bblyM";
export var subHeaderStep = "EditOrder-module--subHeaderStep--ZOqIe";
export var subHeaderStepActive = "EditOrder-module--subHeaderStepActive--xsQkk";
export var supplierTooltip = "EditOrder-module--supplierTooltip--qh-2k";
export var wrapper = "EditOrder-module--wrapper--Nx9vK";