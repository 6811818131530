import { get, patch, post, remove } from "./api"

export interface SageTaxRate {
  displayedAs: string
  id: string
  name: string
  percentage: number
}

export const redirectToSageAuthorization = (clientId: string) => {
  const url = typeof window !== "undefined" ? window.location.origin : ""
  const redirect = `${url}/dashboard/callback/sage`
  const rootSageUrl = `https://www.sageone.com/oauth2/auth/central?filter=apiv3.1`
  const sageAuthUrl = `${rootSageUrl}&response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirect
  )}&scope=full_access`

  window.location.href = sageAuthUrl
}

export const getSageIntegrations = () => {
  return get(`/sage-integrations`)
}

export const createSageIntegration = (params) => {
  return post("/sage-integrations", params)
}

export const getSageClientId = () => {
  return get(`/sage-integrations/client-id`, {}, { plain: true })
}

export const removeSageIntegration = (integrationId) => {
  return remove(`/sage-integrations/${integrationId}`)
}

export const getSageIntegration = (integrationId: string, config?: any) => {
  return get(`/sage-integrations/${integrationId}`, {}, config)
}

export const getBusinessesFromSage = (params) => {
  return get(`/sage-integrations/businesses`, params)
}

export const updateSageIntegrationBusiness = (params) => {
  return patch(
    `/sage-integrations/${params.sageIntegrationId}/business/${params.businessId}`
  )
}
