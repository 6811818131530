import React, { ReactNode, useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import classNames from "classnames/bind"
import Calendar from "components/common/Calendar/Calendar"
import * as styles from "./StockTransferConfirmModal.module.css"
const cx = classNames.bind(styles)

Modal.setAppElement("body")

interface StockTransferConfirmModalProps {
  onRequestClose: Function
  showCancel?: boolean
  type?: string
  title: ReactNode | string
  text: ReactNode | string
  dateLabel: ReactNode | string
  confirmButtonText: string
  cancelButtonText: string
  onConfirm: Function
}

const StockTransferConfirmModal = ({
  onRequestClose,
  showCancel = true,
  type = "success",
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
}: StockTransferConfirmModalProps) => {
  const confirm = useCallback(
    (data) => {
      onRequestClose()
      if (onConfirm) onConfirm(data)
    },
    [onRequestClose, onConfirm]
  )

  const [date, setDate] = useState(new Date())
  const time = new Date()

  let formatDateTime

  return (
    <Modal isOpen onRequestClose={onRequestClose} portalClassName="confirm">
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.welcomeContent}>
          <h1 className="text-3xl mb-4 mt-3">{title || "Are you sure?"}</h1>
          <p className="text-lg text-gray-700">
            {text || "This action cannot be undone"}
          </p>
        </div>
        <div className={styles.date}>
          <Calendar
            onSelect={(date) => {
              setDate(date)
            }}
            selectedDate={date}
          />
          <div className={styles.buttons}>
            {showCancel && (
              <button
                type="button"
                onClick={() => onRequestClose()}
                className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
              >
                {cancelButtonText || "Cancel"}
              </button>
            )}
            <button
              type="submit"
              onClick={() => {
                formatDateTime = new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                  time.getHours(),
                  time.getMinutes()
                )
                confirm(formatDateTime)
              }}
              className={cx("button button--autoWidth", {
                "button--primaryGreen": type === "success",
                "button--primaryPink": type === "danger",
              })}
            >
              {confirmButtonText || "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StockTransferConfirmModal
