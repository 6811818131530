import { initialStockTakeSheet } from "context/global/InitialContext"

const stocktakeSheetReducer = (state, action) => {
  switch (action.type) {
    /**
     * Stocktake sheet actions
     */
    case "CREATE_NEW_STOCKTAKE_SHEET": {
      const { stockTakeSheetData } = action.payload
      return {
        ...state,
        newStockTakeSheet: stockTakeSheetData,
      }
    }

    case "RESET_NEW_STOCKTAKE_SHEET": {
      return {
        ...state,
        newStockTakeSheet: {
          ...state.newStockTakeSheet,
          ...initialStockTakeSheet,
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SHEET": {
      const { stockTakeSheetData } = action.payload
      return {
        ...state,
        newStockTakeSheet: {
          ...state.newStockTakeSheet,
          ...stockTakeSheetData,
          // products: state.newStockTakeSheet.products
          //   ? state.newStockTakeSheet.products
          //   : [],
        },
      }
    }
    default: {
      return state
    }
  }
}
export default stocktakeSheetReducer
