import React from "react"
import ReactPaginate from "react-paginate"
import * as styles from "./Pagination.module.css"
import PropTypes from "prop-types"
import { useMediaQuery } from "react-responsive"

const Pagination = ({ onPageClick, data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  return (
    <div className={styles.container}>
      <ReactPaginate
        previousLabel="Previous"
        previousClassName={"text-gray-700 hover:text-gray-900"}
        previousLinkClassName={
          "font-sans block font-light appearance-none rounded px-3 py-2 mr-2"
        }
        nextLabel={"Next"}
        nextClassName={"text-gray-700 hover:text-gray-900"}
        nextLinkClassName={
          "font-sans block font-light appearance-none rounded px-3 py-2 ml-2"
        }
        activeLinkClassName={
          "rounded-full border text-primaryBlue font-semibold font-sansSemiBold color-primaryBlue border-primaryBlue"
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        disabledClassName={"opacity-50 cursor-not-allowed"}
        pageLinkClassName={
          "font-sans block font-light appearance-none mx-1 px-3 py-1 text-gray-600"
        }
        pageCount={Math.ceil(data.totalPages)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={isTabletOrMobile ? 2 : 5}
        onPageChange={onPageClick}
        containerClassName={"flex justify-center items-center my-4"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        forcePage={data.page}
      />
      <span className={styles.meta}>
        {data.page === 0 ? data.page + 1 : data.size * data.page + 1} -{" "}
        {data.page === 0
          ? data.size * data.page + data.numberOfElements
          : data.size * data.page + data.numberOfElements}{" "}
        of {data.totalElements} items
      </span>
    </div>
  )
}

Pagination.propTypes = {
  onPageClick: PropTypes.func,
  data: PropTypes.object,
  forcePage: PropTypes.number,
}

export default Pagination
