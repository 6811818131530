import Loader from "components/common/Loader/Loader"
import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import PropTypes from "prop-types"
import { getXeroConnections, updateXeroConnection } from "services/xero"

Modal.setAppElement("body")

const XeroOrganizationSelect = ({
  integrationId,
  onOrganizationSelected,
  onRequestClose,
  ...otherProps
}) => {
  const [organizations, setOrganizations] = useState([])
  const [error, setError] = useState("")
  const [loading, setLoading] = useState("")

  const loadOrganizations = async () => {
    setLoading("Loading Xero organizations..")
    setError("")
    try {
      const { connectionIdToTenantName } = await getXeroConnections({
        xeroIntegrationId: integrationId,
      })
      const orgs = Object.keys(connectionIdToTenantName).map((k) => {
        return {
          id: k,
          name: connectionIdToTenantName[k],
        }
      })
      setOrganizations(orgs)
      setLoading("")
    } catch (e) {
      setError(
        "There was a problem getting organizations from Xero, please try again later."
      )
      setLoading("")
    }
  }

  const onSelect = async (organization) => {
    setError("")
    setLoading("Updating Xero organization..")
    try {
      await updateXeroConnection(integrationId, organization.id)
      onRequestClose()
      onOrganizationSelected(organization)
      setLoading("")
    } catch (e) {
      setError(
        "There was a problem getting organizations from Xero, please try again later."
      )
      setLoading("")
    }
  }

  useEffect(() => {
    if (!organizations.length) {
      loadOrganizations()
    }
  }, [])

  return (
    <Modal
      isOpen
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
      portalClassName="xeroOrganizationSelect"
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className="flex flex-col items-center">
        {loading ? (
          <Loader isLoading={true}>{loading}</Loader>
        ) : (
          <div className="overflow-hidden flex-grow flex flex-col">
            <header className="px-4 py-3 text-center border-b flex-shrink-0">
              <h2 className="text-primaryBlue text-2xl mb-2">
                Select your organization from Xero
              </h2>
              <p className="text-gray-700">
                To make a proper connection, please select your organization in
                Xero
              </p>
            </header>
            {organizations.length ? (
              <ul className="flex-grow overflow-auto">
                {organizations.map((l) => {
                  return (
                    <li key={l.id} className="flex flex-col w-full">
                      <button
                        type="button"
                        role="select"
                        className="border-b py-3 px-4 hover:bg-gray-100 text-xl"
                        onClick={() => onSelect(l)}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="text-xl mr-2 text-gray-300"
                        />
                        {l.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <p className="text-primaryPink">
                No organizations found in Xero.
              </p>
            )}
          </div>
        )}
        {error && <p className="text-primaryPink">{error}</p>}
      </div>
    </Modal>
  )
}

XeroOrganizationSelect.propTypes = {
  onRequestClose: PropTypes.func,
  integrationId: PropTypes.string.isRequired,
  onOrganizationSelected: PropTypes.func.isRequired,
}

export default XeroOrganizationSelect
