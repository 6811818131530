/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import AsyncSelect from "components/forms/AsyncSelect"
import BaseModal from "components/base/BaseModal"
import { faSparkles } from "@fortawesome/pro-duotone-svg-icons"
import { getProducts } from "services/products/products"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import Button from "components/common/Button/Button"
import TypingEffect from "components/common/Ai/TypingEffect"
import RadioGroup from "components/forms/RadioGroup"
import Loader from "components/common/Loader/Loader"
import { GlobalDispatchContext } from "context/global/GlobalContextProvider"
import { navigate } from "gatsby"
import { getAiRecipeSuggestions } from "services/recipes/recipes"

interface RecipeGeneratorAiModalProps {
  onRequestClose: Function
  product?: any
  [key: string]: any
}

const RecipeGeneratorAiModal = (props: RecipeGeneratorAiModalProps) => {
  const { product = null } = props
  const [recipes, setRecipes] = useState([]) as any
  const [selectedRecipe, setSelectedRecipe] = useState(null) as any
  const [recipeLines, setRecipeLines] = useState([]) as any
  const [writtenRecipes, setWrittenRecipes] = useState({})
  const [baseProduct, setBaseProduct] = useState(product)
  const [currentRecipeNum, setCurrentRecipeNum] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission

  const loadProductOptions = (inputValue) => {
    return getProducts({
      partialProductName: inputValue,
      size: 40,
      extended: true,
    })
  }

  const productFormatOptionLabel = (option) => {
    return `${option.name} ${
      option.size && option.measure ? "- " + option.size + option.measure : ""
    } - ${option.price} (${option.supplierName})`
  }
  const productDefaultValue = () => {
    return baseProduct?.name
      ? {
          label: baseProduct.name,
          value: baseProduct.name,
        }
      : true
  }

  const onProductSelected = (product) => {
    setBaseProduct(product)
  }

  const generateRecipes = () => {
    setWrittenRecipes({})
    setRecipeLines([])
    setCurrentRecipeNum(null)
    setRecipes([])
    setLoading(true)
    setErrorMessage("")
    getRecipes()
    setSelectedRecipe(null)
  }

  async function getRecipes() {
    const productName = removeInvalidCharacters(baseProduct.name)
    const params = { productName: productName }
    const response = await getAiRecipeSuggestions(params)

    if (response && response.recipes?.length > 0) {
      setRecipes(response.recipes)
    } else {
      setErrorMessage(
        "Can't generate any recipe for this product. Please try another product."
      )
    }

    setLoading(false)
  }

  const createRecipeLines = () => {
    if (recipes.length === 0) return

    const recipe: any = selectedRecipe

    const lines: any = [
      <h1 className="text-xl">{recipe.name}</h1>,
      <p>Description: {recipe.description}</p>,
      <h2 className="text-lg pt-4">Ingredients</h2>,
      ...recipe.ingredients.map((ingredient) => (
        <p className="pl-2">
          {" "}
          • {ingredient.name} - {ingredient.qty}
        </p>
      )),
      <h2 className="text-lg pt-4">Instruction</h2>,
      ...recipe.instructions.map((instruction) => (
        <p className="pl-2"> • {instruction}</p>
      )),
    ]

    setRecipeLines(lines)
  }
  const typingDone = () => {
    setWrittenRecipes({ ...writtenRecipes, ["_" + currentRecipeNum]: true })
  }

  const setRecipe = (num) => {
    setCurrentRecipeNum(num)
    setSelectedRecipe(recipes[num])
  }

  const addRecipe = () => {
    if (!selectedRecipe) return
    addGenerateRecipe(selectedRecipe)
  }

  const addGenerateRecipe = async (recipe) => {
    await dispatch({ type: "ADD_AI_GENERATED_RECIPE", payload: { recipe } })
    navigate("/dashboard/products/cookbook/recipes/new?aiGenerated=true")
  }

  const removeInvalidCharacters = (str) => {
    return str.replace(/[%?&#]/g, "")
  }

  useEffect(() => {
    createRecipeLines()
  }, [selectedRecipe])

  return (
    <BaseModal
      header={{ title: "Recipe Generator", icon: faSparkles }}
      footer={{
        actionCloseModal: true,
        actionTitle: "Add this recipe",
        action: addRecipe,
        disabled: !selectedRecipe,
      }}
      {...props}
    >
      <div className={loading ? "opacity-50" : "opacity-100"}>
        <Loader isLoading={loading}>
          <span className="italic font-normal">Generating recipes</span>
        </Loader>
        <div className="step pb-8">
          <h2 className="text-primaryBlue pb-4">
            1. Select a product as the base for this recipe
          </h2>
          <div className="flex w-full">
            <div className="mr-2 my-2 flex-grow">
              <AsyncSelect
                promise={loadProductOptions}
                id="product"
                disabled={!permissionObj?.permissions.modify}
                placeholder="Please select a product"
                isClearable={true}
                optionLabel={productFormatOptionLabel}
                optionValue="name"
                defaultValue={productDefaultValue()}
                includeMeta
                noOptionsMessage={() => "Type to search"}
                onChange={onProductSelected}
              />
            </div>

            <Button
              className="my-0"
              color="green"
              title="Generate recipe"
              icon={faSparkles}
              onClick={generateRecipes}
              disabled={!baseProduct}
            />
          </div>
        </div>

        {errorMessage && (
          <div>
            <span className="text-error">{errorMessage}</span>
          </div>
        )}

        {recipes.length > 0 && (
          <div className="step pb-8">
            <h2 className="text-primaryBlue pb-4">2. Select Recipe</h2>
            <div className="flex w-full">
              <RadioGroup
                baseClassName="radioGroup-extended"
                className="stacked"
                options={recipes.map((recipe, i) => ({
                  label: (
                    <label>
                      <b>{recipe.name}</b>
                      <br /> {recipe.description}
                    </label>
                  ),
                  value: i,
                }))}
                onChange={setRecipe}
                value={currentRecipeNum}
                name="selecteRecipe"
                activeClass="active"
              />
            </div>
          </div>
        )}

        {recipeLines.length > 0 && (
          <div className="step pb-8">
            <h2 className="text-primaryBlue pb-4">3 Your recipe</h2>
            <div className="w-full bg-gray-100 border border-gray-300 rounded p-4 text-gray-600">
              {writtenRecipes.hasOwnProperty("_" + currentRecipeNum) ? (
                <>{recipeLines}</>
              ) : (
                <TypingEffect
                  messages={recipeLines}
                  speed={5}
                  onFinished={typingDone}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default RecipeGeneratorAiModal
