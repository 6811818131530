/* eslint-disable react/prop-types */
import React, { useRef } from "react"
import BaseTable, { AutoResizer, BaseTableProps } from "react-base-table"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faGripVertical } from "@fortawesome/pro-solid-svg-icons"

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortableElementProps,
  SortableContainerProps,
} from "react-sortable-hoc"

interface SortableElementPropsWithChildren extends SortableElementProps {
  children: JSX.Element
}

interface SortableContainerPropsWithChildren extends SortableContainerProps {
  children: JSX.Element
}

const DraggableContainer: React.ComponentClass<
  SortableContainerPropsWithChildren,
  any
> = SortableContainer(({ children }) => children)
const DraggableElement: React.ComponentClass<
  SortableElementPropsWithChildren,
  any
> = SortableElement(({ children }) => children)
const DraggableHandle = SortableHandle(({ children }) => children)

const Row = ({ key, index, children, ...rest }) => (
  <DraggableElement key={key} index={index}>
    <div {...rest}>
      <DraggableHandle>
        <div className="flex items-center justify-center h-full self-center cursor-pointer w-12 flex-shrink-0">
          <FontAwesomeIcon icon={faGripVertical} className="text-primaryBlue" />
        </div>
      </DraggableHandle>
      {children}
    </div>
  </DraggableElement>
)

interface Props<TableRow> {
  /**
   * data to be displayed in the table
   */
  data: BaseTableProps<TableRow>["data"]
  /**
   * column definition for the table data
   */
  columns: BaseTableProps<TableRow>["columns"]
  /**
   * Custom renderer when the length of data is 0
   */
  emptyRenderer?: BaseTableProps["emptyRenderer"]
  /**
   * callback to set new data order
   */
  setData: (newOrderData: any) => void
}

const DraggableTable = <TableRow,>({
  data = [],
  setData,
  emptyRenderer,
  ...props
}: Props<TableRow>) => {
  const table = useRef<any>()

  const getContainer = () => {
    return table?.current?.getDOMNode().querySelector(".BaseTable__body")
  }

  const getHelperContainer = () => {
    return table?.current?.getDOMNode().querySelector(".BaseTable__table")
  }

  const rowProps = (args) => {
    return {
      tagName: Row,
      index: args.rowIndex,
    }
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newData = [...data]
    const [removed] = newData.splice(oldIndex, 1)
    newData.splice(newIndex, 0, removed)
    setData(newData)
  }

  return (
    <AutoResizer>
      {({ width, height }) => (
        <DraggableContainer
          useDragHandle
          getContainer={getContainer}
          helperContainer={getHelperContainer}
          onSortEnd={handleSortEnd}
        >
          <BaseTable
            {...props}
            width={width}
            height={height}
            ref={table}
            data={data}
            fixed={false}
            rowProps={rowProps}
            headerClassName="pl-3 "
            rowHeight={70}
            emptyRenderer={emptyRenderer}
          />
        </DraggableContainer>
      )}
    </AutoResizer>
  )
}

export default DraggableTable
